import InstagramContentTab from '../../Component/InstagramContentTab/InstagramContentTab'
import InstagramNavbar from '../../Component/InstagramNavbar/InstagramNavbar'
import OverviewCard from '../../Component/OverviewCard/overviewcard'
import Styles from './InstagramContent.module.css'
import { useContext, useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import InstagramPostDetails from '../../Component/InstagramPostDetails/InstagramPostDetails'
import InstagramReelDetails from '../../Component/InstagramReelDetails/InstagramReelDetails'
import { ContentPageSkeleton } from '../../Component/ContentPageSkeleton/ContentPageSkeleton'
import { formatNumber } from '../../Util/helper'
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup'
import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount'
import StoryDetailsPopup from '../../Component/StoryDetailsPopup/StoryDetailsPopup'
import BlogListCard from '../../Component/BlogListCard/BlogListCard'
import ActiveSocialAccountSkeleton from '../../Component/ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton'
import { UserNavigationContext } from '../../store/NavigationProvider'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import { generateDistinctColors } from '../../Util/constant'
import EditKeyword from '../../Component/EditKeyword/EditKeyword'
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent'
const InstagramContent = () => {
    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)

    const [activeTab, setactiveTab] = useState('feed')
    const [showPostDetails, setshowPostDetails] = useState(false)
    const [showReelDetails, setshowReelDetails] = useState(false)
    const [selectedPostDetails, setselectedPostDetails] = useState('')
    const [selectedReelDetails, setselectedReelDetails] = useState('')
    const [showStoryDetails, setshowStoryDetails] = useState(false)
    const [selectedStoryDetails, setselectedStoryDetails] = useState('')
    const [showKeywordPopup, setshowKeywordPopup] = useState(false)
    const [hoverFollower, sethoverFollower] = useState(false)
    const [hoverMentionComments, sethoverMentionComments] = useState(false)
    const [hoverTagInPost, sethoverTagInPost] = useState(false)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [hoverCommentGraph, sethoverCommentGraph] = useState(false)
    const [selectedCategory, setselectedCategory] = useState('')
    const [page, setpage] = useState(1)
    const limit = 5

    const captionTruncate = (str) => {
        return str?.length > 40 ? str?.substring(0, 40) + "..." : str;
    }


    useEffect(() => {

        if (instagramContext.contentPageFilterLoading) {
            instagramContext.getInstagramContentPageAnalyticaData();
            instagramContext.onChangecontentPageFilterLoading(false)
            instagramContext.getInstagramAccountPageAnalyticaData();
            instagramContext.getInstagramOverviewPageFilterPeriodData();

        }
        // eslint-disable-next-line
    }, [instagramContext.selectedPeriod])

    function getDayName(dateStr, locale) {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: 'long' });
    }

    const getHeaderCard1 = (tab) => {
        if (tab === "feed")
            return "Total Post"

        if (tab === "reel")
            return "Total Reel"

        if (tab === "story")
            return "Total Story"
    }

    const getToolTipCard1 = (tab) => {
        if (tab === "feed")
            return "The total number of feed posts you've made in the selected time-frame."

        if (tab === "reel")
            return "Your canvas for 30-second visual storytelling on Instagram. Explore, create, and share engaging clips tailored to your interests. Join a global community of creators and start your reel journey today"

        if (tab === "story")
            return "The total number of story posts you have made in the selected time-frame. This metrics is only available over the period that you have had your instagram account linked to Analytics."
    }

    const getHeaderCard2 = (tab) => {
        if (tab === "feed")
            return "Average Eng. Rate"

        if (tab === "reel")
            return "Avg Eng. Rate"

        if (tab === "story")
            return "Average Views"
    }

    const getToolTipCard2 = (tab) => {
        if (tab === "feed")
            return "Average Engagement on Instagram measures the quality of interaction your content receives, factoring in likes, comments, shares, and saves per post, normalized by follower count. Higher averages indicate more meaningful engagement, vital for fostering a loyal community and boosting visibility"

        if (tab === "reel")
            return "Instagram Reel interaction could be designed to offer users quick and helpful insights into what actions they can take when interacting with Reels. Here's a sample tooltip that is informative and engaging"

        if (tab === "story")
            return "The total number of times your photos or videos were viewed on your stories in the selected time-frame. This metrics is only available over the period that you have had your instagram account linked to Analytics."
    }

    const getHeaderCard4 = (tab) => {
        if (tab === "feed")
            return "Total Saved"

        if (tab === "reel")
            return "Total Saved"

        if (tab === "story")
            return "Profile Activity"
    }

    const getToolTipCard4 = (tab) => {
        if (tab === "feed")
            return "Save posts you love for easy access later. View your collection anytime, anywhere."

        if (tab === "reel")
            return "Save and organize posts you love for easy access later. Keep all your discoveries in one place, accessible from your profile anytime, anywhere."

        if (tab === "story")
            return "Profile Activity: Detailed insights into interactions and engagements on your profile. Includes stats like visits, clicks, and post/story reach. Helps optimize content strategy for higher engagement."
    }

    const getTotalNoOfHeader = (tab) => {
        if (tab === "feed")
            // get total number of feeds
            return instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').length

        if (tab === "reel")
            //get total number of reels
            return instagramContext.postDetails.filter(data => data.media_product_type === 'REELS').length

        if (tab === "story")
            //get total number of story
            return instagramContext?.allStoryDetails?.reduce((totalStory, story) => { return totalStory += story?.storyData?.length }, 0)
    }

    const getTotalNoOfHeaderValueCard2 = (tab) => {
        if (tab === "feed")
            return `${isFinite(parseFloat(instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] }, 0) / instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').length).toFixed(2)) ? formatNumber(parseFloat((instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] }, 0) / instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0] }, 0)) / instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').length * 100).toFixed(0)) : 0}%`

        if (tab === "reel")
            return `${isFinite(parseFloat(instagramContext.postDetails.filter(data => data.media_product_type === 'REELS').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] }, 0) / instagramContext.postDetails.filter(data => data.media_product_type === 'REELS').length).toFixed(2)) ? formatNumber(parseFloat((instagramContext.postDetails.filter(data => data.media_product_type === 'REELS').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] }, 0) / instagramContext.postDetails.filter(data => data.media_product_type === 'REELS').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0] }, 0)) / instagramContext.postDetails.filter(data => data.media_product_type === 'REELS').length * 100).toFixed(0)) : 0}%`

        if (tab === "story")
            return (instagramContext?.allStoryDetails?.map(data => data?.storyData?.reduce((totalProfileActivity, storydata) => { return totalProfileActivity += storydata?.reach?.values[0]?.value }, 0))[0] === undefined ? 0 : instagramContext?.allStoryDetails?.map(data => data?.storyData?.reduce((totalProfileActivity, storydata) => { return totalProfileActivity += storydata?.reach?.values[0]?.value }, 0))?.reduce((totalViews, data) => { return totalViews += data }, 0) / instagramContext?.allStoryDetails?.reduce((totalStory, story) => { return totalStory += story?.storyData?.length }, 0)).toFixed(2)
    }

    const getTotalNoOfHeaderValueCard4 = (tab) => {
        if (tab === "feed")
            return formatNumber(instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "saved").map(postDetails => postDetails.values[0].value)[0] }, 0))

        if (tab === "reel")
            return formatNumber(instagramContext.postDetails.filter(data => data.media_product_type === 'REELS').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "saved").map(postDetails => postDetails.values[0].value)[0] }, 0))

        if (tab === "story")
            return formatNumber(instagramContext?.allStoryDetails?.map(data => data?.storyData?.reduce((totalProfileActivity, storydata) => { return totalProfileActivity += storydata?.profile_activity?.values[0]?.value }, 0))[0] === undefined ? 0 : instagramContext?.allStoryDetails?.map(data => data?.storyData?.reduce((totalProfileActivity, storydata) => { return totalProfileActivity += storydata?.profile_activity?.values[0]?.value }, 0))[0])
    }

    const getHeaderCard3 = (tab) => {
        if (tab === "feed")
            return "Total Impressions"

        if (tab === "reel")
            return "Total Share"

        if (tab === "story")
            return "Completion rate"

    }

    const getToolTipCard3 = (tab) => {
        if (tab === "feed")
            return "Total views of your content, including repeat views, providing insights into visibility and engagement"

        if (tab === "reel")
            return "Share to Instagram instantly! Install & log in to the app for seamless posting."

        if (tab === "story")
            return "The average completion rate of your story posts, in the selected time-frame. This metrics is only available over the period that you have had your instagram account linked to Analytics."

    }

    const getHeaderValueCard3 = (tab) => {
        if (tab === "feed")
            return formatNumber(instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "impressions").map(postDetails => postDetails.values[0].value)[0] }, 0))

        if (tab === "reel")
            return formatNumber(instagramContext.postDetails.filter(data => data.media_product_type === 'Reels').reduce((totalEngagement, data) => { return totalEngagement += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "shares").map(postDetails => postDetails.values[0].value)[0] }, 0))

        if (tab === "story")
            return isFinite(parseFloat(instagramContext?.allStoryDetails?.map(data => parseFloat(data?.storyData?.reduce((totalImpression, completion) => { return totalImpression += completion?.exits?.value ? parseFloat(parseFloat(((completion?.reach?.values[0]?.value - completion?.exits?.value) / (completion?.reach?.values[0]?.value || 0)) * 100).toFixed(2)) : 0 }, 0)).toFixed(2)).reduce((totalCompetion, data) => { return totalCompetion += parseFloat(data) }, 0) / instagramContext?.allStoryDetails?.reduce((totalStory, story) => { return totalStory += story?.storyData?.length }, 0)).toFixed(0)) ? parseFloat(instagramContext?.allStoryDetails?.map(data => parseFloat(data?.storyData?.reduce((totalImpression, completion) => { return totalImpression += completion?.exits?.value ? parseFloat(parseFloat(((completion?.reach?.values[0]?.value - completion?.exits?.value) / (completion?.reach?.values[0]?.value || 0)) * 100).toFixed(2)) : 0 }, 0)).toFixed(2)).reduce((totalCompetion, data) => { return totalCompetion += parseFloat(data) }, 0) / instagramContext?.allStoryDetails?.reduce((totalStory, story) => { return totalStory += story?.storyData?.length }, 0)).toFixed(0) + "%" : 0 + "%"
    }




    const pieChartDataAge = {
        // eslint-disable-next-line
        labels: [].concat(...instagramContext.stackedDataContent.map(data => {
            const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
            // eslint-disable-next-line
            const commonElements = postId.map(item => {
                if (data.postId?.includes(item)) {
                    return data
                }
            }).filter(item => item !== undefined);
            return commonElements
        }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => data.contentCategory),
        datasets: [{
            label: ' Engagement',
            data: [].concat(...instagramContext.stackedDataContent.map(data => {
                const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {

                const likeCount = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.like_count)
                const commentCount = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.comments_count)
                return (isFinite(likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) ? (likeCount.reduce((totalLike, like) => { return totalLike += like }, 0) + commentCount.reduce((totalComment, comment) => { return totalComment += comment }, 0) / data.postId.length) : 0)
            }),
            fill: false,
            backgroundColor: [].concat(...instagramContext.stackedDataContent.map(data => {
                const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                return generateDistinctColors(1)[0]
            }),
        }
        ]
    };


    const pieChartDataLike = {
        labels: [].concat(...instagramContext.stackedDataContent.map(data => {
            const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
            // eslint-disable-next-line
            const commonElements = postId.map(item => {
                if (data.postId.includes(item)) {
                    return data
                }
            }).filter(item => item !== undefined);
            return commonElements
        }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => data.contentCategory),
        datasets: [{
            label: 'Engagement',
            data: [].concat(...instagramContext.stackedDataContent.map(data => {
                const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                const likeCount = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.like_count)

                return (isFinite(likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) ? (likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) : 0)
            }),
            fill: false,
            backgroundColor: [].concat(...instagramContext.stackedDataContent.map(data => {
                const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                return generateDistinctColors(1)[0]
            }),
        }
        ]
    };

    function filterHashtags(captionString) {
        // Create a regular expression to match hashtags
        const hashtagRegex = /#(\w+)/g;

        // Find all hashtags in the caption string
        const hashtags = captionString?.match(hashtagRegex);

        // Return an array of filtered hashtags
        return hashtags?.filter(hashtag => {
            // Remove the hash symbol from the hashtag
            const filteredHashtag = hashtag.substring(1);

            // Return the filtered hashtag if it is not empty
            return filteredHashtag.length > 0;
        });
    }
    
    const checkHashtagRankedOrNot = (data) => {
        const hashtagData = filterHashtags(data.caption)
        if (hashtagData !== undefined) {
            return instagramContext.rankedHashtagContent.filter(data => hashtagData.map(data => data.replace('#', '').toLowerCase()).includes(data.hashtag.toLowerCase()) && data.rank !== "Not found").length + "/" + hashtagData.length
        } else {
            return "0/0"
        }
    }

    const pieChartDataKeywordEngagement = {
        // eslint-disable-next-line
        labels: instagramContext?.confirmKeywordAdded,
        datasets: [{
            label: 'Engagement rate',
            data: instagramContext?.confirmKeywordAdded?.map(data => {
                const filterPostByKeyword = instagramContext.postDetails.filter(postDetails => postDetails?.caption?.includes(data))
                return isFinite(parseFloat(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0) / filterPostByKeyword.length) ? (parseFloat(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0) / filterPostByKeyword.length) : 0
            }),
            fill: false,
            backgroundColor: instagramContext?.confirmKeywordAdded?.map(() => {
                return generateDistinctColors(1)[0]
            }),
        }
        ]
    };


    const pieChartDataKeywordReach = {
        labels: instagramContext?.confirmKeywordAdded,
        datasets: [{
            label: 'Reach',
            data: instagramContext?.confirmKeywordAdded?.map(data => {
                const filterPostByKeyword = instagramContext.postDetails.filter(postDetails => postDetails?.caption?.includes(data))
                return (isFinite(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostByKeyword.length) ? filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostByKeyword.length : 0)
            }),
            fill: false,
            backgroundColor: instagramContext?.confirmKeywordAdded?.map((data, index) => {
                return pieChartDataKeywordEngagement.datasets[0].backgroundColor[index]
            }),
        }
        ]
    };

    const dataForMentionInCaption = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: 'Mention In Caption',
            data: instagramContext.followerDateArray.map(data => {
                const filteredMedia = instagramContext.mentionsData.filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.eventcreatedAt)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`) && item.isMentionInCaption === true;
                });

                return filteredMedia.length
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverFollower ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverFollower ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const dataForMentionInComments = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: 'Mention In Comments',
            data: instagramContext.followerDateArray.map(data => {
                const filteredMedia = instagramContext.mentionsData.filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.eventcreatedAt)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`) && item.isMentionInCaption === false;
                });
                return filteredMedia.length
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverMentionComments ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverMentionComments ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const dataForTagInPost = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: 'Tag In Post',
            data: instagramContext.followerDateArray.map(data => {
                const filteredMedia = instagramContext?.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.timestamp)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`);
                });
                return filteredMedia.length
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverTagInPost ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverTagInPost ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const dataForStory = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: 'Story view',
            data: instagramContext.followerDateArray.map(data => {
                const filteredMedia = instagramContext?.allStoryDetails.filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.date)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`);
                });

                return filteredMedia[0]?.storyData?.length > 0 ? filteredMedia[0]?.storyData?.reduce((totalView, data) => { return totalView += data?.reach?.values[0]?.value !== undefined ? data?.reach?.values[0]?.value : 0 }, 0) : 0
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverTagInPost ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverTagInPost ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };


    const dataForEngagement = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: 'Engagement',
            data: instagramContext.followerDateArray.map(data => {
                const filteredMedia = instagramContext?.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.timestamp)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`);
                });

                const totalEng = filteredMedia.reduce((totalEng, data) => { return totalEng += parseFloat(((instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1) !== undefined ? ((instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1) : 0) }, 0)
                return totalEng
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverTagInPost ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverTagInPost ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const dataForImpression = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: activeTab === 'reel' ? 'Reach' : 'Impressions',
            data: instagramContext.followerDateArray.map(data => {
                const filteredMedia = instagramContext?.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.timestamp)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`);
                });
                let totalEng;
                if (activeTab === 'feed') {
                    totalEng = filteredMedia.reduce((totalEng, data) => { return totalEng += (instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "impressions").map(postDetails => postDetails.values[0].value)[0]) !== undefined ? instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "impressions").map(postDetails => postDetails.values[0].value)[0] : 0 }, 0)
                } else {
                    totalEng = filteredMedia.reduce((totalEng, data) => { return totalEng += instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0] !== undefined ? instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0] : 0 }, 0)
                }
                return totalEng
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverTagInPost ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverTagInPost ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const socialIds = instagramContext.stackedDataContent?.reduce((ids, item) => {
        if (!ids.includes(item.socialId)) {
            ids.push(item.socialId);
        }
        return ids;
    }, []);


    const categories = instagramContext.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.reduce((uniqueCategories, item) => {
        if (!uniqueCategories.includes(item.contentCategory)) {
            uniqueCategories.push(item.contentCategory);
        }
        return uniqueCategories;
    }, []);

    const datasets = instagramContext.postDetails.filter(data => data.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).length ? socialIds.map((socialId) => {
        const color = generateDistinctColors(1)[0]

        return {
            label: (userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name,
            data: categories.map(category => {

                return instagramContext.stackedDataContent?.filter(item => item.socialId === socialId && item.contentCategory === category)?.length;
            }),
            backgroundColor: color,
        };
    }) : [];



    const dataDonghnut = {
        labels: instagramContext.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i)?.map(item => item.contentCategory),
        datasets: datasets,
    }

    function getTopCategoryCounts(data) {
        const categoryCounts = {};

        // Iterate over each array
        data.forEach(array => {
            // Iterate over each object in the array
            array.forEach(item => {
                // Get the socialId and contentCategory
                const socialId = item.socialId;
                const category = item.contentCategory;

                // If the socialId doesn't exist in categoryCounts, initialize it
                if (!categoryCounts[socialId]) {
                    categoryCounts[socialId] = {};
                }

                // Increment the count for the category under the socialId
                if (!categoryCounts[socialId][category]) {
                    categoryCounts[socialId][category] = 1;
                } else {
                    categoryCounts[socialId][category]++;
                }
            });
        });

        // Convert the object to an array of objects
        const result = Object.entries(categoryCounts).map(([socialId, categories]) => {
            // Find the category with the highest count
            const topCategory = Object.entries(categories).reduce((acc, [category, count]) => {
                return count > acc.count ? { category, count } : acc;
            }, { category: null, count: -1 });

            return { socialId, topCategory };
        });

        return result;
    }

    const prevPage = () => {
        if (page > 1) {
            setpage(prev => prev - 1)
        }
    }

    const nextPage = () => {
        if (page < Math.ceil(Math.ceil(instagramContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
            const postDetails = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && postDetails.media_product_type === (activeTab === 'reel' ? 'REELS' : 'FEED'))
            return postDetails.length
        })[0] / limit))) {
            setpage(prev => prev + 1)
        }
    }
    return (
        <div className={Styles.instagram_main_container} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {showKeywordPopup ? <EditKeyword close={() => setshowKeywordPopup(false)} /> : <></>}
            {showPostDetails ? <InstagramPostDetails data={selectedPostDetails} onClickClose={() => setshowPostDetails(false)} /> : <></>}
            {showReelDetails ? <InstagramReelDetails data={selectedReelDetails} onClickClose={() => setshowReelDetails(false)} /> : <></>}
            {showStoryDetails ? <StoryDetailsPopup storyItem={selectedStoryDetails} onClickClose={() => setshowStoryDetails(false)} /> : <></>}
            <div className={Styles.content_container} >

                <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.instagram_content_main_container} >
                    {instagramContext.contentPageLoading ? <ActiveSocialAccountSkeleton selected={instagramContext.selectedPeriod} /> : <ActiveSocialAccount page="instagram" disableFilter={true} loading={instagramContext.contentPageLoading} selected={instagramContext.selectedPeriod === 30 ? (instagramContext.selectedPeriod + 1) : instagramContext.selectedPeriod} onChangePeriod={(value) => { instagramContext.setselectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); instagramContext.onChangecontentPageFilterLoading(true) }} />}
                    <InstagramNavbar active="content" />
                    <InstagramContentTab loading={instagramContext.contentPageLoading} activeTab={activeTab} onchangeTab={(tab) => setactiveTab(tab)} totalPost={{ post: instagramContext?.postDetails?.filter(data => data.media_product_type === 'FEED')?.length, reel: instagramContext?.postDetails?.filter(data => data.media_product_type === 'REELS')?.length, story: instagramContext?.allStoryDetails?.reduce((totalStory, story) => { return totalStory += story?.storyData?.length }, 0) }} />
                    {instagramContext.contentPageLoading ? <ContentPageSkeleton activeTab={activeTab} /> :
                        <div className={Styles.instagram_content_container} >
                            {instagramContext.totalFollower < 100 && instagramContext.contentPageLoading === false ? <BlogListCard type="instagram" /> : <></>}
                            {activeTab !== 'keyword' && activeTab !== "hashtag" && activeTab !== "mentions" ?
                                <div className={Styles.card_container} >
                                    <OverviewCard toolTip={getToolTipCard1(activeTab)} story={true} count={formatNumber(getTotalNoOfHeader(activeTab))} percentage={0} header={getHeaderCard1(activeTab)} />
                                    <OverviewCard toolTip={getToolTipCard2(activeTab)} impression={true} count={getTotalNoOfHeaderValueCard2(activeTab)} percentage={0} header={getHeaderCard2(activeTab)} />
                                    <OverviewCard toolTip={getToolTipCard3(activeTab)} website={true} count={getHeaderValueCard3(activeTab)} percentage={0} header={getHeaderCard3(activeTab)} />
                                    <OverviewCard toolTip={getToolTipCard4(activeTab)} slides={true} count={formatNumber(getTotalNoOfHeaderValueCard4(activeTab))} percentage={0} header={getHeaderCard4(activeTab)} />
                                </div> : <></>

                            }

                            {activeTab === 'reel' || activeTab === "feed" ?
                                <div className={Styles.engagement_and_impression_container} >
                                    <LineChartCustomComponent onHoverOut={() => sethoverTagInPost(false)} onHoverIn={() => sethoverTagInPost(true)} type="line" header="Engagement" subheader="" contentArray={[]} options={{
                                        maintainAspectRatio: false,
                                        interaction: {
                                            intersect: false,
                                            mode: "x"
                                        },
                                        radius: hoverTagInPost ? 5 : 5,
                                        scales: {
                                            x: {
                                                type: 'category',
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false, // Hide dataset label
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        },
                                    }} chartData={dataForEngagement} />

                                    <LineChartCustomComponent onHoverOut={() => sethoverTagInPost(false)} onHoverIn={() => sethoverTagInPost(true)} type="line" header={activeTab === 'reel' ? "Reach" : "Impression"} subheader="" contentArray={[]} options={{
                                        maintainAspectRatio: false,
                                        interaction: {
                                            intersect: false,
                                            mode: "x"
                                        },
                                        radius: hoverTagInPost ? 5 : 5,
                                        scales: {
                                            x: {
                                                type: 'category',
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false, // Hide dataset label
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        },


                                    }} chartData={dataForImpression} />
                                </div>
                                : <></>}

                            {activeTab === 'feed' &&
                                <>
                                    <div className={Styles.post_main_container} >
                                        <div className={Styles.header_container} >
                                            <span>Post Activity</span>
                                            <div className={Styles.button_container} >

                                            </div>
                                        </div>
                                        {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                            {instagramContext?.postDetails?.filter(data => data.media_product_type === 'FEED')?.length ?
                                                <>
                                                    <div className={Styles.post_container} >
                                                        <div className={Styles.header_container} >
                                                            <span>Post</span>
                                                            <div className={Styles.postHeaderContainer} >
                                                                <span data-tooltip-id="my-tooltip" data-tooltip-content="This metric calculates the average number of interactions (likes, comments, share, etc.) received per post." >Eng Rate</span>
                                                                <span>Ranked hashtag</span>
                                                                <span>Reach</span>
                                                                <span>Impressions</span>
                                                                <span>Likes</span>
                                                                <span>Comments</span>
                                                                <span>Save</span>
                                                                <span>View</span>
                                                            </div>
                                                        </div>
                                                        <div className={Styles.border} ></div>
                                                        {instagramContext.postDetails.filter(data => data.media_product_type === 'FEED').map((data, index) => {

                                                            return (
                                                                <div style={{ width: '100%' }} key={index} >
                                                                    <div style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setshowPostDetails(true); setselectedPostDetails(data) }} className={Styles.post_item_container} >
                                                                        <div className={Styles.post_content_container} >

                                                                            <div className={Styles.post_content_img} >
                                                                                <img alt="" src={data?.children?.data[0]?.media_url === undefined ? data?.media_url : data?.children?.data[0]?.media_url} />
                                                                            </div>

                                                                            <div className={Styles.post_content} >
                                                                                <span>{captionTruncate(data?.caption !== undefined ? data?.caption : "This content has no caption")}</span>
                                                                                <p>{new Date(data.timestamp).getDate() + "/" + (new Date(data.timestamp).getMonth() + 1) + "/" + new Date(data.timestamp).getFullYear()} {new Date(data?.timestamp).toLocaleTimeString('en-US')}</p>
                                                                            </div>


                                                                        </div>
                                                                        <div className={Styles.postOtherDetails} >

                                                                            <div>{isFinite(((instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1)) ? ((instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1) : 0}%</div>
                                                                            <div onClick={(e) => { e.stopPropagation(); setshowPostDetails(true); setselectedPostDetails(data) }} >{checkHashtagRankedOrNot(data)}
                                                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M4.5 6.95996L8.5 10.96L12.5 6.95996" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </div>

                                                                            <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>

                                                                            <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "impressions").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>

                                                                            <div>{formatNumber(data.like_count || 0)}</div>
                                                                            <div>{formatNumber(data.comments_count || 0)}</div>
                                                                            <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "saved").map(postDetails => postDetails.values[0].value)[0]) || 0}</div>
                                                                            <div style={{ cursor: 'pointer' }} > <svg onClick={(e) => { e.stopPropagation(); window.open(`${data.permalink}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clipPath="url(#clip0_2171_2043)">
                                                                                    <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_2171_2043">
                                                                                        <rect width="22" height="22" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg> </div>
                                                                        </div>
                                                                    </div>
                                                                    <section className={Styles.instagram_border_of_post} ></section>
                                                                </div>
                                                            )
                                                        })

                                                        }

                                                    </div>
                                                </> :
                                                <NoDataAvailablePopup noOfPost={instagramContext?.postDetails?.filter(data => data.media_product_type === 'FEED')?.length} selectedPeriod={
                                                    // eslint-disable-next-line
                                                    instagramContext.selectedPeriod == 31 ? 30 : instagramContext.selectedPeriod} cardName="postlist" />

                                            }
                                        </> : <PremiumPopup cardName="postlist" />

                                        }


                                    </div>


                                </>
                            }


                            {activeTab === 'reel' &&
                                <div className={Styles.post_main_container} >
                                    <div className={Styles.header_container} >
                                        <span>Post Activity</span>
                                        <div className={Styles.button_container} >

                                        </div>
                                    </div>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                        {instagramContext?.postDetails?.filter(data => data.media_product_type === 'REELS')?.length ?
                                            <>
                                                <div className={Styles.post_container} >
                                                    <div className={Styles.header_container} >
                                                        <span>Posts</span>
                                                        <div className={Styles.postHeaderContainer} >
                                                            <span>Reach</span>
                                                            <span>Ranked hashtag</span>
                                                            <span data-tooltip-id="my-tooltip" data-tooltip-content="This metric calculates the average number of interactions (likes, comments, share, etc.) received per post." >Eng. Rate</span>
                                                            <span>Plays</span>
                                                            <span>Share</span>
                                                            <span>Likes</span>
                                                            <span>Comments</span>
                                                            <span>Save</span>
                                                            <span>Post Interactions</span>
                                                            <span>View</span>
                                                        </div>
                                                    </div>
                                                    <div className={Styles.border} ></div>
                                                    {instagramContext?.postDetails?.filter(data => data.media_product_type === 'REELS')?.map((data, index) => {

                                                        return (
                                                            <div style={{ width: '100%' }} key={index} >
                                                                <div style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setshowReelDetails(true); setselectedReelDetails(data) }} className={Styles.post_item_container} >
                                                                    <div className={Styles.post_content_container} >

                                                                        <div className={Styles.post_content_img} >
                                                                            <img alt="" src={data.thumbnail_url} />
                                                                        </div>

                                                                        <div className={Styles.post_content} >
                                                                            <span>{captionTruncate(data?.caption !== undefined ? data?.caption : "This content has no caption")}</span>
                                                                            <p>{new Date(data.timestamp).getDate() + "/" + (new Date(data.timestamp).getMonth() + 1) + "/" + new Date(data.timestamp).getFullYear()} {new Date(data?.timestamp).toLocaleTimeString('en-US')}</p>
                                                                        </div>


                                                                    </div>
                                                                    <div className={Styles.postOtherDetails} >
                                                                        <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>
                                                                        <div>{checkHashtagRankedOrNot(data)}
                                                                            <svg onClick={(e) => { e.stopPropagation(); setshowReelDetails(true); setselectedReelDetails(data) }} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M4.5 6.95996L8.5 10.96L12.5 6.95996" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <div>{isFinite(((instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1)) ? ((instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "reach").map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1) : 0}%</div>

                                                                        <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "plays").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>

                                                                        <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "shares").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>

                                                                        <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "likes").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>
                                                                        <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "comments").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>
                                                                        <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "saved").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>
                                                                        <div>{formatNumber(instagramContext.allPostInsights.filter(postDetails => postDetails.id === data.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] || 0)}</div>
                                                                        <div style={{ cursor: 'pointer' }} > <svg onClick={(e) => { e.stopPropagation(); window.open(`${data.permalink}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_2171_2043)">
                                                                                <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_2171_2043">
                                                                                    <rect width="22" height="22" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg> </div>
                                                                    </div>
                                                                </div>
                                                                <section className={Styles.instagram_border_of_post} ></section>
                                                            </div>
                                                        )
                                                    })

                                                    }

                                                </div>
                                            </> :
                                            <NoDataAvailablePopup noOfPost={instagramContext?.postDetails?.filter(data => data.media_product_type === 'REELS')?.length} selectedPeriod={
                                                // eslint-disable-next-line
                                                instagramContext.selectedPeriod == 31 ? 30 : instagramContext.selectedPeriod} cardName="postlist" />

                                        }
                                    </> : <PremiumPopup cardName="postlist" />

                                    }


                                </div>
                            }

                            {activeTab === 'story' &&
                                <>
                                    <div className={Styles.mentions_main_container} >
                                        <LineChartCustomComponent style={{ width: '100%' }} onHoverOut={() => sethoverTagInPost(false)} onHoverIn={() => sethoverTagInPost(true)} type="line" header="Story graph" subheader="" contentArray={[]} options={{
                                            maintainAspectRatio: false,
                                            interaction: {
                                                intersect: false,
                                                mode: "x"
                                            },
                                            radius: hoverTagInPost ? 5 : 5,
                                            scales: {
                                                x: {
                                                    type: 'category',
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    beginAtZero: true,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false, // Hide dataset label
                                                },
                                                tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                    position: 'nearest',
                                                    // Additional tooltip configuration options
                                                },
                                            },


                                        }} chartData={dataForStory} />
                                    </div>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                        <>

                                            {instagramContext?.allStoryDetails?.filter(data => data?.storyData?.length)?.length ?
                                                <div style={{ marginBottom: 150 }} className={Styles.story_container} >
                                                    {instagramContext.allStoryDetails?.map((data, index) => {

                                                        return (
                                                            <div style={{ width: '100%' }} key={index} >
                                                                {data?.storyData?.length ?
                                                                    <div key={index} className={Styles.story_container_cards} >
                                                                        <div className={Styles.story_date_container} >
                                                                            <div className={Styles.header_value_container} >
                                                                                <span>{getDayName(data.date)}</span>
                                                                                <p>{new Date(data.date)?.getDate()}th {monthNames[new Date(data.date).getMonth()]}</p>
                                                                            </div>
                                                                            <div className={Styles.header_value_container} >
                                                                                <span>{data.storyData.length}</span>
                                                                                <p>Stories</p>
                                                                            </div>
                                                                            <div className={Styles.header_value_container} >
                                                                                <span>{formatNumber(data?.storyData?.reduce((totalImpression, impression) => { return totalImpression += parseFloat(impression?.impressions?.values[0]?.value) }, 0))}</span>
                                                                                <p>Total Impressions</p>
                                                                            </div>

                                                                            <div className={Styles.header_value_container} >
                                                                                <span>{isFinite(parseFloat(data?.storyData.reduce((totalImpression, completion) => { return totalImpression += completion?.exits?.value ? parseFloat(parseFloat(((completion?.reach?.values[0]?.value - completion?.exits?.value) / (completion?.reach?.values[0]?.value)) * 100).toFixed(2)) : 0 }, 0)).toFixed(2)) ? parseFloat(data?.storyData.reduce((totalImpression, completion) => { return totalImpression += completion?.exits?.value ? parseFloat(parseFloat(((completion?.reach?.values[0]?.value - completion?.exits?.value) / (completion?.reach?.values[0]?.value)) * 100).toFixed(2)) : 0 }, 0)).toFixed(2) : 0}%</span>
                                                                                <p>Avg Completion</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={Styles.story_content_container} >
                                                                            {data?.storyData?.reverse()?.map((storyItem, storyIndex) => {

                                                                                return (
                                                                                    <div key={storyIndex} style={{ cursor: 'pointer' }} onClick={() => { setshowStoryDetails(true); setselectedStoryDetails(storyItem) }} className={Styles.story_content} >
                                                                                        <div className={Styles.story_preview} >
                                                                                            <img alt="" src={storyItem?.media_type === "VIDEO" ? storyItem?.thumbnail_url : storyItem?.thumbnail_url} />
                                                                                            <div className={Styles.no_of_view_container} >

                                                                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M1.875 8.125C4.125 3.125 10.875 3.125 13.125 8.125" stroke="black" strokeWidth="1.15" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                    <path d="M7.5 10.625C6.46444 10.625 5.625 9.78556 5.625 8.75C5.625 7.71444 6.46444 6.875 7.5 6.875C8.53556 6.875 9.375 7.71444 9.375 8.75C9.375 9.78556 8.53556 10.625 7.5 10.625Z" stroke="black" strokeWidth="1.15" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                </svg>
                                                                                                <p>{formatNumber(storyItem?.reach?.values[0]?.value || 0)}</p>
                                                                                            </div>
                                                                                            <span className={Styles.see_more_details} >see more details</span>

                                                                                        </div>



                                                                                        <div className={Styles.story_header_value_container} >
                                                                                            <span>Likes</span>
                                                                                            <p>{formatNumber(parseInt(storyItem?.total_interactions?.values[0]?.value || 0) - (parseInt(storyItem?.shares?.values[0]?.value || 0) + parseInt(storyItem?.replies?.values[0]?.value || 0)))}</p>

                                                                                        </div>
                                                                                        <div className={Styles.border} ></div>

                                                                                        <div className={Styles.story_header_value_container} >
                                                                                            <span>Shares</span>
                                                                                            <p>{formatNumber(storyItem?.shares?.values[0]?.value || 0)}</p>

                                                                                        </div>
                                                                                        <div className={Styles.border} ></div>





                                                                                        <div className={Styles.story_header_value_container} >
                                                                                            <span>Completion</span>

                                                                                            <p>{(storyItem?.exits?.value ? (isFinite(parseFloat(((storyItem?.reach?.values[0]?.value - storyItem?.exits?.value) / (storyItem?.reach?.values[0]?.value)) * 100).toFixed(2)) ? parseFloat(((storyItem?.reach?.values[0]?.value - storyItem?.exits?.value) / (storyItem?.reach?.values[0]?.value)) * 100).toFixed(2) : 0) : 0)}%</p>

                                                                                        </div>
                                                                                        <div className={Styles.border} ></div>

                                                                                        <div className={Styles.story_header_value_container} >
                                                                                            <span>Profile Visits</span>

                                                                                            <p>{formatNumber(storyItem?.profile_activity?.values[0]?.value || 0)}</p>

                                                                                        </div>

                                                                                        <div className={Styles.story_header_value_container} >
                                                                                            <span>Posted At</span>

                                                                                            <p>{new Date(storyItem?.timestamp).toLocaleTimeString('en-US')}</p>

                                                                                        </div>



                                                                                    </div>

                                                                                )
                                                                            })

                                                                            }
                                                                        </div>
                                                                    </div> : <div key={index} className={Styles.post_main_container} > <label>No story avaialble</label></div>
                                                                }
                                                                {instagramContext.storyPaginationLoading === true ? <ContentPageSkeleton activeTab={activeTab} /> : <></>

                                                                }

                                                            </div>

                                                        )
                                                    })

                                                    }
                                                    {instagramContext.stopStoryPagination ? <button onClick={() => instagramContext.storyPagination()} >Load More</button> : <></>}
                                                </div> : <div style={{ marginBottom: 150 }} className={Styles.post_main_container} >  <NoDataAvailablePopup noOfPost={instagramContext?.allStoryDetails?.filter(data => data?.storyData?.length)?.length} selectedPeriod={
                                                    // eslint-disable-next-line
                                                    instagramContext.selectedPeriod == 31 ? 30 : instagramContext.selectedPeriod} cardName="storylist" /> </div>

                                            }
                                        </> : <div style={{ marginBottom: 150 }} className={Styles.post_main_container} > <PremiumPopup cardName="storylist" /> </div>

                                    }





                                </>
                            }

                            {activeTab === 'reel' || activeTab === "feed" ?
                                <>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                        <div className={Styles.content_category_container} >
                                            <h4>Category</h4>
                                            {instagramContext?.postDetails?.filter(data => data.media_product_type === (activeTab === 'reel' ? 'REELS' : 'FEED'))?.length ?
                                                <>

                                                    <div className={Styles.header_container} >
                                                        <span>category</span>
                                                        <span>likes</span>
                                                        <span>comments</span>
                                                    </div>

                                                    {[].concat(...instagramContext.stackedDataContent.map(data => {
                                                        const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
                                                        // eslint-disable-next-line
                                                        const commonElements = postId.map(item => {
                                                            if (data?.postId?.includes(item)) {
                                                                return data
                                                            }
                                                        }).filter(item => item !== undefined);
                                                        return commonElements
                                                    }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                                                        const likeCount = instagramContext.postDetails.filter(postDetails => data.postId?.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.like_count)
                                                        const commentCount = instagramContext.postDetails.filter(postDetails => data.postId?.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.comments_count)

                                                        return (
                                                            <div className={Styles.category_content} >
                                                                <span>{data.contentCategory}</span>
                                                                <span>{likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)}</span>
                                                                <span>{commentCount.reduce((totalComment, comments) => { return totalComment += comments }, 0)}</span>
                                                            </div>
                                                        )
                                                    })

                                                    }

                                                </> :

                                                <NoDataAvailablePopup noOfPost={instagramContext?.postDetails?.filter(data => data.media_product_type === (activeTab === 'reel' ? 'REELS' : 'FEED'))?.length} selectedPeriod={
                                                    // eslint-disable-next-line
                                                    instagramContext.selectedPeriod == 31 ? 30 : instagramContext.selectedPeriod} cardName="postlist" />


                                            }
                                        </div> : <PremiumPopup cardName="postlist" />

                                    }

                                </> : <></>

                            }

                            {activeTab === 'reel' || activeTab === "feed" ?
                                <>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                        <div className={Styles.competitor_category_content_container} >
                                            <LineChartCustomComponent componentName="category" style={{ width: '48%',height:'100%' }} header={`Category concentric graph (last 30 days)`} contentArray={
                                                // const name = instagramContext.competitors.find(competitor => competitor.id === data.socialId).name
                                                getTopCategoryCounts([instagramContext.stackedDataContent]).map((data, index) => {
                                                    return {
                                                        "header": `${(userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name} ( ${data.topCategory.category} )`, indicator: dataDonghnut?.datasets[index]?.backgroundColor, type: 'solid', "value": data.topCategory.count
                                                    }
                                                })

                                            } onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(false)} type="pie" options={{
                                                maintainAspectRatio: false,
                                                scales: {
                                                    x: {
                                                        display: false,
                                                        grid: {
                                                            display: false
                                                        }
                                                    },
                                                    y: {
                                                        display: false,
                                                        grid: {
                                                            display: false
                                                        }
                                                    },
                                                },
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                        position: 'nearest',
                                                        // Additional tooltip configuration options
                                                    },
                                                }
                                            }} chartData={dataDonghnut} />

                                            <>

                                                {instagramContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                    const postDetails = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && postDetails.media_product_type === (activeTab === 'reel' ? 'REELS' : 'FEED'))
                                                    return postDetails.length
                                                })[0] > 0 ? <>
                                                    <div className={Styles.category_content_container} >
                                                        <div >
                                                            <label  >{(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.name} - {selectedCategory === '' ? instagramContext?.stackedDataContent[0]?.contentCategory : selectedCategory} Post</label>

                                                            {instagramContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                                const postDetails = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && postDetails.media_product_type === (activeTab === 'reel' ? 'REELS' : 'FEED'))
                                                                const renderPost = postDetails.slice((page - 1) * 5,(page - 1) * 5 + 5).map((postData, index) => {

                                                                    return (
                                                                        <div className={Styles.single_content_container} >
                                                                            <div className={Styles.postDetailsContainer} >
                                                                                <p className={Styles.index_header} >{index + 1}</p>
                                                                                <img alt="media" src={activeTab === "feed" ? postData?.media_url : postData?.thumbnail_url} />
                                                                                <div className={Styles.post_content_container} >
                                                                                    <h5>{captionTruncate(postData?.caption === undefined ? "-" : postData?.caption, 15)}</h5>
                                                                                    <p>{postData.like_count} Likes {postData.comments_count} Comments</p>
                                                                                </div>
                                                                            </div>
                                                                            <svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`${postData.permalink}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clipPath="url(#clip0_2171_2043)">
                                                                                    <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_2171_2043">
                                                                                        <rect width="22" height="22" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                    )
                                                                })
                                                                return renderPost
                                                            })
                                                            }
                                                        </div>
                                                        <div className={Styles.post_pagination_container} >
                                                            <svg onClick={prevPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.375 3.75L5.625 7.5L9.375 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            <span>Showing {page}-{Math.ceil(instagramContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                                const postDetails = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && postDetails.media_product_type === (activeTab === 'reel' ? 'REELS' : 'FEED'))
                                                                return postDetails.length
                                                            })[0] / limit)} posts</span>
                                                            <svg onClick={nextPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.625 3.75L9.375 7.5L5.625 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>

                                                        </div>
                                                    </div>
                                                </> : <NoDataAvailablePopup noOfPost={instagramContext.stackedDataContent.filter(data => data.contentCategory === selectedCategory).map(data => {
                                                    const postDetails = instagramContext.postDetails.filter(postDetails => data.postId.includes(postDetails.id) && postDetails.media_product_type === (activeTab === 'reel' ? 'REELS' : 'FEED'))
                                                    return postDetails.length
                                                })[0]} selectedPeriod={
                                                    // eslint-disable-next-line
                                                    instagramContext.selectedPeriod == 31 ? 30 : instagramContext.selectedPeriod} cardName="postlist" />

                                                }
                                            </>
                                        </div> : <PremiumPopup cardName="postlist" />}
                                </>
                                : <></>

                            }


                            {activeTab === 'reel' || activeTab === "feed" ?
                                <div className={Styles.category_pie_chart_container} >
                                    <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Category Like" contentArray={[].concat(...instagramContext.stackedDataContent.map(data => {
                                        const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
                                        // eslint-disable-next-line
                                        const commonElements = postId.map(item => {
                                            if (data.postId?.includes(item)) {
                                                return data
                                            }
                                        }).filter(item => item !== undefined);
                                        return commonElements
                                    }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map((data, index) => {

                                        const likeCount = instagramContext.postDetails.filter(postDetails => data.postId?.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.like_count)

                                        return { "header": data.contentCategory, type: "solid", indicator: pieChartDataLike.datasets[0].backgroundColor[index], value: `${(isFinite(likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) ? ((likeCount.reduce((totalLike, like) => { return totalLike += like }, 0))) : 0)}` }
                                    })} options={{
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        }
                                    }} chartData={pieChartDataLike} />

                                    <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Category Engagement Rate" contentArray={[].concat(...instagramContext.stackedDataContent.map(data => {
                                        const postId = instagramContext.postDetails.filter(postData => postData.media_product_type === (activeTab === 'reel' ? "REELS" : "FEED")).map(postData => postData.id)
                                        // eslint-disable-next-line
                                        const commonElements = postId.map(item => {
                                            if (data.postId?.includes(item)) {
                                                return data
                                            }
                                        }).filter(item => item !== undefined);
                                        return commonElements
                                    }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map((data, index) => {
                                        const likeCount = instagramContext.postDetails.filter(postDetails => data.postId?.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.like_count)
                                        const commentCount = instagramContext.postDetails.filter(postDetails => data.postId?.includes(postDetails.id) && (activeTab === 'reel' ? postDetails.media_product_type === "REELS" : postDetails.media_product_type === "FEED")).map(data => data.comments_count)


                                        return { "header": data.contentCategory, type: "solid", indicator: pieChartDataAge.datasets[0].backgroundColor[index], value: `${(isFinite(likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) ? (((likeCount.reduce((totalLike, like) => { return totalLike += like }, 0) + commentCount.reduce((totalComment, comment) => { return totalComment += comment }, 0)))) : 0)}` }
                                    })} options={{
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        }
                                    }} chartData={pieChartDataAge} />
                                </div> : <></>
                            }

                            {activeTab === 'keyword' ?
                                <div className={Styles.keyword_container} >
                                    <div className={Styles.header_container} >
                                        <h4>{instagramContext?.confirmKeywordAdded?.length} Keyword</h4>
                                        <button onClick={() => setshowKeywordPopup(true)} >Edit keyword</button>
                                    </div>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                        <div className={Styles.keyword_content_table_container} >
                                            <h4>Keyword content</h4>
                                            {instagramContext?.postDetails?.length ?
                                                <>
                                                    <div className={Styles.table_headers} >
                                                        <h4>Keyword</h4>
                                                        <div className={Styles.sub_header_container} >
                                                            <span>Total Post</span>
                                                            <span>Avg. Engagement rate</span>
                                                            <span>Avg. Likes</span>
                                                            <span>Avg. Comments</span>
                                                            <span>Avg. Reach</span>
                                                        </div>
                                                    </div>
                                                    {instagramContext?.confirmKeywordAdded?.map(data => {
                                                        const filterPostByKeyword = instagramContext.postDetails.filter(postData => postData?.caption?.includes(data))
                                                        return (
                                                            <>
                                                                <div className={Styles.table_content} >
                                                                    <h4>{data}</h4>
                                                                    <div className={Styles.sub_content_header} >
                                                                        <span>Reel({filterPostByKeyword.filter(postDetails => postDetails.media_product_type === "REELS").length}), feed({filterPostByKeyword.filter(postDetails => postDetails.media_product_type === "FEED").length})</span>
                                                                        <span>{(isFinite(parseFloat(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0)) ? parseFloat(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0) / filterPostByKeyword.length : 0) + "%"}</span>
                                                                        <span>{isFinite(filterPostByKeyword.reduce((totalLike, postDetails) => { return totalLike += postDetails.like_count }, 0) / filterPostByKeyword.length) ? filterPostByKeyword.reduce((totalLike, postDetails) => { return totalLike += postDetails.like_count }, 0) / filterPostByKeyword.length : 0}</span>
                                                                        <span>{isFinite(filterPostByKeyword.reduce((totalComments, postDetails) => { return totalComments += postDetails.comments_count }, 0) / filterPostByKeyword.length) ? filterPostByKeyword.reduce((totalComments, postDetails) => { return totalComments += postDetails.comments_count }, 0) / filterPostByKeyword.length : 0}</span>
                                                                        <span>{isFinite(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostByKeyword.length) ? filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostByKeyword.length : 0}</span>
                                                                    </div>
                                                                </div>
                                                                <div className={Styles.border} ></div>
                                                            </>
                                                        )
                                                    })
                                                    }
                                                </>
                                                : <NoDataAvailablePopup noOfPost={instagramContext?.postDetails?.length} selectedPeriod={
                                                    // eslint-disable-next-line
                                                    instagramContext.selectedPeriod == 31 ? 30 : instagramContext.selectedPeriod} cardName="postlist" />
                                            }



                                        </div> : <PremiumPopup cardName="postlist" />
                                    }


                                    <div className={Styles.graph_container} >
                                        <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Engagement Rate" contentArray={
                                            instagramContext?.confirmKeywordAdded?.map((data, index) => {
                                                const filterPostByKeyword = instagramContext.postDetails.filter(postDetails => postDetails?.caption?.includes(data))
                                                return { "header": data, type: "solid", indicator: pieChartDataKeywordEngagement.datasets[0].backgroundColor[index], value: isFinite(parseFloat(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0) / filterPostByKeyword.length) ? (parseFloat(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0) / filterPostByKeyword.length) : 0 + "%" }
                                            })
                                        } options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    display: false,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    display: false,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                    position: 'nearest',
                                                    // Additional tooltip configuration options
                                                },
                                            }
                                        }} chartData={pieChartDataKeywordEngagement} />

                                        <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Reach Cumulative" contentArray={instagramContext?.confirmKeywordAdded?.map((data, index) => {
                                            const filterPostByKeyword = instagramContext.postDetails.filter(postDetails => postDetails?.caption?.includes(data))
                                            return { "header": data, type: "solid", indicator: pieChartDataKeywordReach.datasets[0].backgroundColor[index], value: isFinite(filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostByKeyword.length) ? (filterPostByKeyword.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostByKeyword.length) : 0 }
                                        })} options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    display: false,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    display: false,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                    position: 'nearest',
                                                    // Additional tooltip configuration options
                                                },
                                            }
                                        }} chartData={pieChartDataKeywordReach} />

                                    </div>
                                </div> : <></>

                            }

                            {activeTab === 'hashtag' ?
                                <div className={Styles.hashtag_container} >

                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                        <div className={Styles.keyword_content_table_container} >
                                            <h4>Hashtag content</h4>
                                            {instagramContext?.rankedHashtagContent?.length ?
                                                <div style={{ width: '100%' }} >
                                                    <div className={Styles.table_headers} >
                                                        <h4>Hashtag</h4>
                                                        <div className={Styles.sub_header_container} >
                                                            <span>Total Post</span>
                                                            <span>Avg. Rank</span>
                                                            <span>Avg. Engagement rate</span>
                                                            <span>Avg. Likes</span>
                                                            <span>Avg. Comments</span>
                                                            <span>Avg. Reach</span>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <div style={{ width: '100%', overflowY: 'scroll', height: '40vh' }} >
                                                            {instagramContext.rankedHashtagContent.map(data => {
                                                                const filterPostData = instagramContext.postDetails.filter(postDetails => postDetails?.caption?.toLowerCase()?.includes("#" + data.hashtag.toLowerCase()));
                                                                const filterHashtagsRank = instagramContext.rankedHashtagContent.filter(hashtagData => hashtagData.hashtag === data.hashtag).map(data => data.rank !== "Not found" ? data.rank : null).filter(data => data !== null);
                                                                const averageRank = filterHashtagsRank.length ? filterHashtagsRank.reduce((totalRank, rank) => totalRank += rank, 0) / filterHashtagsRank.length : 0;
                                                                return {
                                                                    hashtag: data.hashtag,
                                                                    averageRank: averageRank,
                                                                    filterPostData: filterPostData
                                                                };
                                                            }).sort((a, b) => b.averageRank - a.averageRank).map(data => {
                                                                const filterPostData = instagramContext.postDetails.filter(postDetails => postDetails?.caption?.toLowerCase()?.includes("#" + data.hashtag.toLowerCase()))
                                                                const filterHashtagsRank = instagramContext.rankedHashtagContent.filter(hashtagData => hashtagData.hashtag === data.hashtag).map(data => data.rank !== "Not found" ? data.rank : null).filter(data => data !== null)
                                                                return (
                                                                    <>
                                                                        <div className={Styles.table_content} >
                                                                            <h4>{"#" + data.hashtag}</h4>
                                                                            <div className={Styles.sub_header_container} >
                                                                                <span>Reel({filterPostData.filter(postDetails => postDetails.media_product_type === "REELS").length}), feed({filterPostData.filter(postDetails => postDetails.media_product_type === "FEED").length})</span>
                                                                                <span data-tooltip-id="my-tooltip" data-tooltip-content={filterHashtagsRank.length ? filterHashtagsRank.reduce((totalRank, rank) => { return totalRank += rank }, 0) / filterPostData.length : "Hashtag rank is greater than 100"} >{filterHashtagsRank.length ? filterHashtagsRank.reduce((totalRank, rank) => { return totalRank += rank }, 0) / filterPostData.length : "NA"}</span>
                                                                                <span>{(isFinite(parseFloat(filterPostData.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostData.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0)) ? parseFloat(filterPostData.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "total_interactions")[0].values[0].value }, 0) / filterPostData.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) * 100).toFixed(0) / filterPostData.length : 0) + "%"}</span>
                                                                                <span>{isFinite(filterPostData.reduce((totalLike, postDetails) => { return totalLike += postDetails.like_count }, 0) / filterPostData.length) ? filterPostData.reduce((totalLike, postDetails) => { return totalLike += postDetails.like_count }, 0) / filterPostData.length : 0}</span>
                                                                                <span>{isFinite(filterPostData.reduce((totalComments, postDetails) => { return totalComments += postDetails.comments_count }, 0) / filterPostData.length) ? filterPostData.reduce((totalComments, postDetails) => { return totalComments += postDetails.comments_count }, 0) / filterPostData.length : 0}</span>
                                                                                <span>{isFinite(filterPostData.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostData.length) ? filterPostData.reduce((totalReach, postDetails) => { return totalReach += postDetails.res.data.filter(reachData => reachData.name === "reach")[0].values[0].value }, 0) / filterPostData.length : 0}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className={Styles.border} ></div>
                                                                    </>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                : <NoDataAvailablePopup noOfPost={instagramContext?.rankedHashtagContent?.length} selectedPeriod={
                                                    // eslint-disable-next-line
                                                    instagramContext.selectedPeriod == 31 ? 30 : instagramContext.selectedPeriod} cardName="postlist" />
                                            }



                                        </div> : <PremiumPopup cardName="postlist" />
                                    }



                                </div> : <></>

                            }

                            {activeTab === 'mentions' ?
                                <div style={{ marginBottom: 160, width: '100%', gap: 20, display: 'flex', flexDirection: 'column' }}   >
                                    <div className={Styles.mentions_main_container} >
                                        <LineChartCustomComponent onHoverOut={() => sethoverFollower(false)} onHoverIn={() => sethoverFollower(true)} type="line" header="Mentions in caption" subheader="" contentArray={[]} options={{
                                            maintainAspectRatio: false,
                                            interaction: {
                                                intersect: false,
                                                mode: "x"
                                            },
                                            radius: hoverFollower ? 5 : 5,
                                            scales: {
                                                x: {
                                                    type: 'category',
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    beginAtZero: true,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false, // Hide dataset label
                                                },
                                                tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                    position: 'nearest',
                                                    // Additional tooltip configuration options
                                                },
                                            },


                                        }} chartData={dataForMentionInCaption} />

                                        <LineChartCustomComponent onHoverOut={() => sethoverMentionComments(false)} onHoverIn={() => sethoverMentionComments(true)} type="line" header="Mentions in comment" subheader="" contentArray={[]} options={{
                                            maintainAspectRatio: false,
                                            interaction: {
                                                intersect: false,
                                                mode: "x"
                                            },
                                            radius: hoverMentionComments ? 5 : 5,
                                            scales: {
                                                x: {
                                                    type: 'category',
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    beginAtZero: true,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false, // Hide dataset label
                                                },
                                                tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                    position: 'nearest',
                                                    // Additional tooltip configuration options
                                                },
                                            },


                                        }} chartData={dataForMentionInComments} />
                                    </div>
                                    <div className={Styles.mentions_main_container} >
                                        <LineChartCustomComponent style={{ width: '100%' }} onHoverOut={() => sethoverTagInPost(false)} onHoverIn={() => sethoverTagInPost(true)} type="line" header="Tag in post" subheader="" contentArray={[]} options={{
                                            maintainAspectRatio: false,
                                            interaction: {
                                                intersect: false,
                                                mode: "x"
                                            },
                                            radius: hoverTagInPost ? 5 : 5,
                                            scales: {
                                                x: {
                                                    type: 'category',
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    beginAtZero: true,
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false, // Hide dataset label
                                                },
                                                tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                    position: 'nearest',
                                                    // Additional tooltip configuration options
                                                },
                                            },


                                        }} chartData={dataForTagInPost} />
                                    </div>
                                </div>
                                :
                                <></>}
                        </div>
                    }


                </div>
            </div>
            <Tooltip id="my-tooltip" style={{ width: '18vw', borderRadius: 10, fontFamily: 'Poppins', fontSize: 10, fontStyle: 'normal', fontWeight: "normal", lineHeight: "normal", letterSpacing: 0.36, zIndex: 12 }} />
        </div >
    )
}

export default InstagramContent