import { createContext, useContext, useState } from 'react'
import axios from 'axios'
import { url } from '../Util/constant'
import APICall from '../services/api.service'
import { regions } from '../Util/Region'
import { country } from '../Util/Country'
import { toast } from 'react-toastify'
import { seniorities } from '../Util/seniorities'
import { UserConnectedAccountContext } from '../store/UserConnectedAccount'
import { UserNavigationContext } from './NavigationProvider'
import variables from '../Util/variables'
import { CommonSocialMediaContext } from './CommonSocialMediaStore'

export const CreateLinkedinAnalyticContext = createContext({
    selectedPeriod: '',
    setSelectedPeriod: () => { },
    followerDateArray: [],
    setfollowerDateArray: () => { },
    followerPaidData: '',
    setfollowerPaidData: () => { },
    setimpressionData: () => { },
    impressionData: [],
    previousDateImpression: [],
    engagementData: [],
    setengagementData: () => { },
    organizationalEntityShareStatisticsFilter: '',
    setorganizationalEntityShareStatisticsFilter: () => { },
    impressionPercentage: '',
    setimpressionPercentage: () => { },
    engagementPercentage: '',
    setengagementPercentage: () => { },
    postDetails: [],
    setpostDetails: () => { },
    followerCountryData: '',
    setfollowerCountryData: () => { },
    OrganizationalEntityFollowerStatstics: '',
    setOrganizationalEntityFollowerStatstics: () => { },
    organizationPageStatisticsFilter: '',
    setorganizationPageStatisticsFilter: () => { },
    socialMetadata: '',
    setsocialMetadata: () => { },
    followerOrganicData: '',
    setfollowerOrganicData: () => { },
    mediaUrl: '',
    setmediaUrl: () => { },
    caption: '',
    setcaption: () => { },
    previousDatePageView: '',
    setpreviousDatePageView: () => { },
    previousDateUniqueView: '',
    setpreviousDateUniqueView: () => { },
    previousDateButtonClicks: '',
    setpreviousDateButtonClicks: () => { },
    showAllLocationFollowerModel: '',
    setshowAllLocationFollowerModel: () => { },
    pageStatisticsFilterPreviousDate: '',
    setpageStatisticsFilterPreviousDate: () => { },
    pageViewFilter: '',
    setpageViewFilter: () => { },
    uniqueViewFilter: '',
    setuniqueViewFilter: () => { },
    loading: '',

    organizationalEntityFollowerStatisticsFilter: [],
    setorganizationalEntityFollowerStatisticsFilter: () => { },
    followerCountry: [],
    setfollowerCountry: () => { },
    allFollowerCountry: [],
    setallFollowerCountry: () => { },
    allFollowerCountryData: [],
    setallFollowerCountryData: () => { },
    previousDateFollowerPercentage: '',
    setpreviousDateFollowerPercentage: () => { },
    todayDateFollower: {},
    settodayDateFollower: () => { },
    previousDateFollower: {},
    setpreviousDateFollower: () => { },
    todayDecisionMaker: '',
    settodayDecisionMaker: () => { },
    previousDecisionMaker: '',
    setpreviousDecisionMaker: () => { },
    showAllJobFunction: false,
    setshowAllJobFunction: () => { },
    showAllIndustry: false,
    setshowAllIndustry: () => { },
    count: '',
    setCount: () => { },
    setloading: () => { },
    paginationLoading: '',
    setpaginationLoading: () => { },
    accounts: '',
    accountsLoading: '',
    getAccounts: () => { },
    getLinkedinAnalyticsData: () => { },

    getLinkedinAnalyticsDataAsperSelectedPeriod: () => { },
    filterLoading: '',
    setfilterLoading: () => { },
    changeLoadingState: () => { },
    loadMore: '',
    setLoadMore: () => { },
    organizationAllFollower: '',
    visitorInsightsData: [],
    contentStart: false,
    stopPagination: false,
    onChangecontentStart: () => { },
    onChangestopPagination: () => { },

    overviewPageLoading: true,
    followerPageLoading: true,
    visitorPageLoading: true,
    contentPageLoading: true,

    getLinkedinOverviewPageAnalyticaData: () => { },
    getLinkedinFollowerPageAnalyticaData: () => { },
    getLinkedinVisitorPageAnalyticaData: () => { },
    getLinkedinContentPageAnalyticaData: () => { },

    overviewPageFilterLoading: false,
    followerPageFilterLoading: false,
    visitorPageFilterLoading: false,
    contentPageFilterLoading: false,

    onChangeOverviewFilterLoading: () => { },
    onChangeFollowerFilterLoading: () => { },
    onChangeVisitorFilterLoading: () => { },
    onChangeContentFilterLoading: () => { },

    reportWeekArray: [],
    selectedReportDate: '',
    onChangesetselectedReportDate: () => { },
    stackedDataContent: [],
    clearState: () => { }
})

export const CreateLinkedinAnalyticProvider = ({ children }) => {
    const commonSocialMediaContext = useContext(CommonSocialMediaContext)
    const userContext = useContext(UserConnectedAccountContext)

    const userAccountContext = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [paginationLoading, setpaginationLoading] = useState(false)
    const [count, setCount] = useState(100)
    const [OrganizationalEntityFollowerStatstics, setOrganizationalEntityFollowerStatstics] = useState([])
    const [organizationAllFollower, setorganizationAllFollower] = useState([])
    const [organizationPageStatisticsFilter, setorganizationPageStatisticsFilter] = useState([])
    const [socialMetadata, setsocialMetadata] = useState([])
    const [followerOrganicData, setfollowerOrganicData] = useState([])
    const [followerPaidData, setfollowerPaidData] = useState([])
    const [mediaUrl, setmediaUrl] = useState('')
    const [caption, setcaption] = useState('')
    const [mediaType, setmediaType] = useState('')
    const [previousDatePageView, setpreviousDatePageView] = useState('')
    const [previousDateUniqueView, setpreviousDateUniqueView] = useState('')
    const [previousDateButtonClicks, setpreviousDateButtonClicks] = useState('')
    const [showAllLocationFollowerModel, setshowAllLocationFollowerModel] = useState(false)
    const [followerCountry, setfollowerCountry] = useState([])
    const [followerCountryData, setfollowerCountryData] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState(31)
    const [followerDateArray, setfollowerDateArray] = useState([])
    const [impressionData, setimpressionData] = useState([])
    const [engagementData, setengagementData] = useState([])
    const [organizationalEntityShareStatisticsFilter, setorganizationalEntityShareStatisticsFilter] = useState([])
    const [impressionPercentage, setimpressionPercentage] = useState('')
    const [engagementPercentage, setengagementPercentage] = useState('')
    const [postDetails, setpostDetails] = useState([])
    const [organizationalEntityFollowerStatisticsFilter, setorganizationalEntityFollowerStatisticsFilter] = useState([])
    const [previousDateFollowerPercentage, setpreviousDateFollowerPercentage] = useState('')
    const [todayDateFollower, settodayDateFollower] = useState({})
    const [previousDateFollower, setpreviousDateFollower] = useState({})
    const [todayDecisionMaker, settodayDecisionMaker] = useState('')
    const [previousDecisionMaker, setpreviousDecisionMaker] = useState('')
    const [showAllJobFunction, setshowAllJobFunction] = useState(false)
    const [showAllIndustry, setshowAllIndustry] = useState(false)
    const [pageStatisticsFilterPreviousDate, setpageStatisticsFilterPreviousDate] = useState('')
    const [pageViewData, setpageViewData] = useState([])
    const [pageViewDataForMobile, setpageViewDataForMobile] = useState([])
    const [uniquePageViewData, setuniquePageViewData] = useState([])
    const [uniquePageViewDataMobile, setuniquePageViewDataMobile] = useState([])
    const [pageViewFilter, setpageViewFilter] = useState('All')
    const [uniqueViewFilter, setuniqueViewFilter] = useState('All')
    const [loading, setloading] = useState(true)
    const [accounts, setaccounts] = useState('')
    const [accountsLoading, setaccountsLoading] = useState(true)
    const [filterLoading, setfilterLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [previousDateImpression, setpreviousDateImpression] = useState([])
    const [overviewPageLoading, setoverviewPageLoading] = useState(false)
    const [followerPageLoading, setfollowerPageLoading] = useState(false)
    const [visitorPageLoading, setvisitorPageLoading] = useState(false)
    const [contentPageLoading, setcontentPageLoading] = useState(false)
    const [overviewPageFilterLoading, setoverviewPageFilterLoading] = useState(false)
    const [followePageFilterLoading, setfollowePageFilterLoading] = useState(false)
    const [visitorPageFilterLoading, setvisitorPageFilterLoading] = useState(false)
    const [contentPageFilterLoading, setcontentPageFilterLoading] = useState(false)
    const [visitorInsightsData, setvisitorInsightsData] = useState([])
    const [visitorCity, setvisitorCity] = useState([])
    const [visitorCityData, setvisitorCityData] = useState([])
    const [contentStart, setcontentStart] = useState(0)
    const [stopPagination, setstopPagination] = useState(false)
    const [reportWeekArray, setreportWeekArray] = useState([])
    const [selectedReportDate, setselectedReportDate] = useState('')
    const [stackedDataContent, setstackedDataContent] = useState([])
    
    const clearState=()=>{
        setstackedDataContent([])
        setreportWeekArray([])
        setvisitorCityData([])
        setvisitorCity([])
        setvisitorInsightsData([])
        setpreviousDateImpression([])
        setaccounts('')
        setuniquePageViewDataMobile([])
        setuniquePageViewData([])
        setpageViewDataForMobile([])
        setpageStatisticsFilterPreviousDate('')
        setpreviousDecisionMaker('')
        settodayDecisionMaker('')
        settodayDecisionMaker({})
        settodayDateFollower({})
        setpreviousDateFollowerPercentage('')
        setorganizationalEntityFollowerStatisticsFilter([])
        setpostDetails([])
        setengagementPercentage('')
        setimpressionPercentage('')
        setorganizationalEntityShareStatisticsFilter([])
        setengagementData([])
        setimpressionData([])
        setfollowerCountryData([])
        setfollowerCountry([])
        setpreviousDateButtonClicks('')
        setpreviousDateUniqueView('')
        setpreviousDatePageView('')
        setmediaType('')
        setcaption('')
        setmediaUrl('')
        setfollowerPaidData([])
        setfollowerOrganicData([])
        setsocialMetadata([])
        setorganizationPageStatisticsFilter([])
        setorganizationAllFollower([])
        setOrganizationalEntityFollowerStatstics([])
    }

    const onChangeselectedReportDate = (value) => {
        setselectedReportDate(value)
    }

    const onChangecontentStart = (value) => {
        setcontentStart(value)
    }

    const onChangestopPagination = (value) => {
        setstopPagination(value)
    }
    const getCurrentDateAndPreviousDate = () => {
        const token = localStorage.getItem('token')
        const inUser = userContext.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName);
        const socialId = inUser[0]?.socialId
        const workspaceId = localStorage.getItem('organizationId')
        const currentDate = new Date();

        // Calculate the date 29 days ago
        const startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - selectedPeriod);


        // Get the milliseconds for the start date
        const startDateMilliseconds = startDate.getTime();
        const endDateMilliSeconds = currentDate.getTime();

        const previousDate = new Date(startDate)
        previousDate.setDate(startDate.getDate() - selectedPeriod)
        const previousDateStartDateMiliSec = previousDate.getTime()

        // Initialize an array to store the dates
        const dateArray = [];
        const filterDateArray = [];
        // Calculate and insert 6 dates into the array
        for (let i = 0; i < (selectedPeriod); i++) {
            const newDate = new Date(startDate);
            newDate.setDate(startDate.getDate() + i); // Adjust the increment as needed
            const monthShortName = newDate.toLocaleString('default', { month: 'short' });
            const day = String(newDate.getDate()).padStart(2, '0');
            dateArray.push(`${monthShortName}-${day}`);
            const dateInMilliseconds = newDate.setHours(0, 0, 0, 0)
            filterDateArray.push(dateInMilliseconds)
        }

        const reportWeeks = [];

        currentDate.setDate(currentDate.getDate() - selectedPeriod); // Minus selectedPeriod from currentDate

        for (let i = 0; i < selectedPeriod; i += 7) { // Increment by 7 to represent weeks
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + i); // Adjust the increment as needed

            const weekStartDate = new Date(newDate);
            weekStartDate.setDate(newDate.getDate() - 6); // Get the start date of the week

            reportWeeks.push(newDate);
        }

        setselectedReportDate(reportWeeks[reportWeeks?.length - 1]); // Set selected report date to the first week


        return { dateArray: dateArray, filterDateArray: filterDateArray, startDateMilliseconds: startDateMilliseconds, endDateMilliSeconds: endDateMilliSeconds, previousDateStartDateMiliSec: previousDateStartDateMiliSec, token: token, socialId: socialId, workspaceId: workspaceId, reportWeeks: reportWeeks }

    }

    const calculatePercentage = (currentDateMetrics, previousDateMetrics) => {
        return (((currentDateMetrics - previousDateMetrics) / previousDateMetrics) * 100).toFixed(1)
    }

    const getLinkedinOverviewPageAnalyticaData = async () => {
        const { dateArray, startDateMilliseconds, endDateMilliSeconds, previousDateStartDateMiliSec, token, socialId, workspaceId, reportWeeks } = getCurrentDateAndPreviousDate()
        setfollowerDateArray(dateArray)
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {

                setoverviewPageLoading(true)
                const overviewPageData = await axios.get(`${url}analytics/v2/linkedin/overview/${workspaceId}/${socialId}?startDate=${startDateMilliseconds}&endDate=${endDateMilliSeconds}&prevStartDate=${previousDateStartDateMiliSec}&prevDateUntil=${startDateMilliseconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })

                const latestPostElement = overviewPageData.data.latestPostData.elements[0]
                const followerData = overviewPageData.data.followersDataWithDateData.elements
                const previousMonthFollowerData = overviewPageData.data.followersDataWithPreviousMonth.elements
                const currentMonthImpressionAndEngagementData = overviewPageData.data.currentMonthImpressionAndEngagementData.elements
                const previousMonthImpressionAndEngagementData = overviewPageData.data.previoiusMonthImpressionAndEngagementData.elements
                const currentMonthPageStatics = overviewPageData.data.currentMonthwebsite_clicksAndPageViewData.elements
                const previousMonthPageStatics = overviewPageData.data.previousMonthwebsite_clicksAndPageViewData.elements

                if (latestPostElement?.content?.media?.id?.includes('video')) {
                    setmediaType('video')
                }
                if (latestPostElement?.content?.media?.id?.includes('document')) {
                    setmediaType('document')
                }
                if (latestPostElement?.content?.media?.id?.includes('image')) {
                    setmediaType('image')
                }

                setmediaUrl(latestPostElement?.content?.media?.mediaUrl)

                setcaption(latestPostElement?.commentary)
                setsocialMetadata(overviewPageData?.data?.latestPostData?.elements[0])
                setOrganizationalEntityFollowerStatstics(overviewPageData.data.followersData)
                setfollowerOrganicData(followerData?.map(followerData => followerData.followerGains.organicFollowerGain))
                setfollowerPaidData(followerData?.map(followerData => followerData.followerGains.paidFollowerGain))
                setimpressionData(currentMonthImpressionAndEngagementData.map(data => data.totalShareStatistics.impressionCount))
                setengagementData(currentMonthImpressionAndEngagementData.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)))
                const currentMonthImpressions = currentMonthImpressionAndEngagementData.map(data => data.totalShareStatistics.impressionCount).reduce((totalImpression, data) => { return totalImpression += data }, 0)
                const previousMonthImpressions = previousMonthImpressionAndEngagementData.map(data => data.totalShareStatistics.impressionCount).reduce((totalImpression, data) => { return totalImpression += data }, 0)
                setimpressionPercentage(calculatePercentage(currentMonthImpressions, previousMonthImpressions))
                const currentMonthEngagement = currentMonthImpressionAndEngagementData.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)).reduce((totalEng, data) => { return totalEng += parseFloat(data) }, 0)
                const previousMonthEngagement = previousMonthImpressionAndEngagementData.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)).reduce((totalEng, data) => { return totalEng += parseFloat(data) }, 0)
                setengagementPercentage(calculatePercentage(currentMonthEngagement, previousMonthEngagement))
                setorganizationalEntityShareStatisticsFilter(overviewPageData.data.currentMonthImpressionAndEngagementData)
                setorganizationPageStatisticsFilter(overviewPageData.data.currentMonthwebsite_clicksAndPageViewData)
                setorganizationalEntityFollowerStatisticsFilter(overviewPageData.data.followersDataWithDateData)
                const filteredPageViewElements = currentMonthPageStatics?.filter(pageViews => {
                    return pageViews
                })
                setpageViewData(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.pageViews))
                setpageViewDataForMobile(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.pageViews))
                setuniquePageViewData(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.uniquePageViews))
                setuniquePageViewDataMobile(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.uniquePageViews))

                const currentMonthPageView = currentMonthPageStatics?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.pageViews
                    return totalPageView
                }, 0)

                const previousMonthPageView = previousMonthPageStatics?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.pageViews
                    return totalPageView
                }, 0)

                setpreviousDatePageView(calculatePercentage(currentMonthPageView, previousMonthPageView))

                const currentMonthUniquePageView = currentMonthPageStatics?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.uniquePageViews
                    return totalPageView
                }, 0)

                const previousMonthUniquePageView = previousMonthPageStatics?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.uniquePageViews
                    return totalPageView
                }, 0)
                setpreviousDateUniqueView(calculatePercentage(currentMonthUniquePageView, previousMonthUniquePageView))

                const currentMonthClicks = currentMonthPageStatics?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                    totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                    return totalPageView
                }, 0)

                const previousMonthClicks = previousMonthPageStatics?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                    totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                    return totalPageView
                }, 0)
                setpreviousDateButtonClicks(calculatePercentage(currentMonthClicks, previousMonthClicks))
                const currentMonthFollowerCount = followerData?.map(followerData => parseInt(followerData.followerGains.organicFollowerGain + followerData.followerGains.paidFollowerGain)).reduce((totalCurrentMonthFollower, data) => { return totalCurrentMonthFollower += data }, 0)
                const previousMonthFoloowerCount = previousMonthFollowerData?.map(followerData => parseInt(followerData.followerGains.organicFollowerGain + followerData.followerGains.paidFollowerGain)).reduce((totalCurrentMonthFollower, data) => { return totalCurrentMonthFollower += data }, 0)
                setpreviousDateFollowerPercentage(calculatePercentage(currentMonthFollowerCount, previousMonthFoloowerCount))
                const firstFiveCountries = overviewPageData.data.followersData?.slice(0, 6);

                const thirdArray = firstFiveCountries?.map(countryCode2 => {
                    const matchingCountry = country?.find(country => country?.name?.toLowerCase() === countryCode2?.geo.elements[0].displayText.toLowerCase());
                    return matchingCountry ? matchingCountry.code3 : null;
                });



                setfollowerCountry(thirdArray)

                setfollowerCountryData(firstFiveCountries?.map(data => parseInt(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)))
                setoverviewPageLoading(false)
                setoverviewPageFilterLoading(false)
                setfollowePageFilterLoading(false)
                setvisitorPageFilterLoading(false)
                setcontentPageFilterLoading(false)



                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: null,
                                    overviewPageData: overviewPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: null,
                            overviewPageData: overviewPageData.data
                        }
                    })
                }



            } catch (error) {
                toast.error('something went wrong!')
                setoverviewPageFilterLoading(false)
                setfollowePageFilterLoading(false)
                setvisitorPageFilterLoading(false)
                setcontentPageFilterLoading(false)
                setoverviewPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: error,
                                    overviewPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: error,
                            overviewPageData: null
                        }
                    })
                }
            }
        }
    }

    const getLinkedinFollowerPageAnalyticaData = async () => {
        const { dateArray, token, socialId, workspaceId } = getCurrentDateAndPreviousDate()
        setfollowerDateArray(dateArray)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                setfollowerPageLoading(true)
                const followerPageData = await axios.get(`${url}analytics/v2/linkedin/follower/${workspaceId}/${socialId}`, { headers: { "Authorization": `Bearer ${token}` } })
                setorganizationAllFollower(followerPageData.data.followersData)
                setfollowerPageLoading(false)


                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                followerPageData: {
                                    loading: false,
                                    error: null,
                                    followerPageData: followerPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {

                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        followerPageData: {
                            loading: false,
                            error: null,
                            followerPageData: followerPageData.data
                        }
                    })
                }
            } catch (error) {
                setfollowerPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                followerPageData: {
                                    loading: false,
                                    error: error,
                                    followerPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        followerPageData: {
                            loading: false,
                            error: error,
                            followerPageData: null
                        }
                    })
                }
            }
        }
    }

    const getLinkedinVisitorPageAnalyticaData = async () => {
        const { dateArray, token, socialId, workspaceId } = getCurrentDateAndPreviousDate()
        setfollowerDateArray(dateArray)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                setvisitorPageLoading(true)
                const visitorPageData = await axios.get(`${url}analytics/v2/linkedin/visitor/${workspaceId}/${socialId}`, { headers: { "Authorization": `Bearer ${token}` } })

                setvisitorInsightsData(visitorPageData.data.currentVisitorData)

                const firstFiveCity = visitorPageData.data.currentVisitorData.elements[0].pageStatisticsByRegion.map(data => { return { region: data.region, value: (data.pageStatistics.views.aboutPageViews.pageViews + data.pageStatistics.views.allDesktopPageViews.pageViews + data.pageStatistics.views.allMobilePageViews.pageViews + data.pageStatistics.views.allPageViews.pageViews + data.pageStatistics.views.careersPageViews.pageViews + data.pageStatistics.views.desktopAboutPageViews.pageViews + data.pageStatistics.views.desktopCareersPageViews.pageViews + data.pageStatistics.views.desktopInsightsPageViews.pageViews + data.pageStatistics.views.desktopJobsPageViews.pageViews + data.pageStatistics.views.desktopLifeAtPageViews.pageViews + data.pageStatistics.views.desktopOverviewPageViews.pageViews + data.pageStatistics.views.desktopPeoplePageViews.pageViews + data.pageStatistics.views.desktopProductsPageViews.pageViews + data.pageStatistics.views.insightsPageViews.pageViews + data.pageStatistics.views.jobsPageViews.pageViews + data.pageStatistics.views.lifeAtPageViews.pageViews + data.pageStatistics.views.mobileAboutPageViews.pageViews + data.pageStatistics.views.mobileCareersPageViews.pageViews + data.pageStatistics.views.mobileInsightsPageViews.pageViews + data.pageStatistics.views.mobileJobsPageViews.pageViews + data.pageStatistics.views.mobileLifeAtPageViews.pageViews + data.pageStatistics.views.mobileOverviewPageViews.pageViews + data.pageStatistics.views.mobilePeoplePageViews.pageViews + data.pageStatistics.views.mobileProductsPageViews.pageViews + data.pageStatistics.views.overviewPageViews.pageViews + data.pageStatistics.views.productsPageViews.pageViews) } }).sort((a, b) => b.value - a.value).slice(0, 6);
                const thirdArray = firstFiveCity?.map(countryCode2 => {
                    const matchingCountry = regions?.find(country => country?.urn === countryCode2.region);
                    return matchingCountry ? matchingCountry.name.value : null;
                });
                setvisitorCity(thirdArray)
                setvisitorCityData(firstFiveCity.map(data => data.value))
                setvisitorPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                visitorPageData: {
                                    loading: false,
                                    error: null,
                                    visitorPageData: visitorPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        visitorPageData: {
                            loading: false,
                            error: null,
                            visitorPageData: visitorPageData.data
                        }
                    })
                }
            } catch (error) {

                setvisitorPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                visitorPageData: {
                                    loading: false,
                                    error: true,
                                    visitorPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        visitorPageData: {
                            loading: false,
                            error: true,
                            visitorPageData: null
                        }
                    })
                }
            }
        }
    }

    const getLinkedinContentPageAnalyticaData = async () => {
        const { dateArray, token, socialId, workspaceId, startDateMilliseconds, endDateMilliSeconds } = getCurrentDateAndPreviousDate()
        setfollowerDateArray(dateArray)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                if (stopPagination === true) return

                setcontentPageLoading(true)
                const contentPageData = await axios.get(`${url}analytics/v2/linkedin/content/${workspaceId}/${socialId}?start=${contentStart}`, { headers: { "Authorization": `Bearer ${token}` } })
                if (contentPageData.data.postData.paging.links.find(data => data.rel === "next")) {
                    setcontentStart(prev => prev + 5)
                } else {
                    setstopPagination(true)
                }

                if (contentStart === 0) {
                    setpostDetails(contentPageData.data.postData)
                    let allCompetitorPostData = []
                    contentPageData.data.postData.elements.forEach(data => {
                        const postObj = { caption: data?.commentary === undefined ? 'This post has no caption' : data?.commentary, id: data.id, timestamp: data.createdAt, socialId: socialId }
                        allCompetitorPostData.push(postObj)
                    })

                    const requestBody = {
                        workspaceId: workspaceId,
                        token: token,
                        media: allCompetitorPostData,
                        startDateMilliseconds: startDateMilliseconds,
                        endDateMilliSeconds: endDateMilliSeconds
                    }
                    const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
                    const groupedData = res.data.data.reduce((acc, obj) => {
                        if (!acc[obj.contentCategory]) {
                            acc[obj.contentCategory] = { contentCategory: obj.contentCategory, socialId: obj.socialId, postId: [] };
                        }
                        acc[obj.contentCategory].postId.push(obj.postId);
                        return acc;
                    }, {});

                    const result = Object.values(groupedData);

                    setstackedDataContent(result)
                } else {
                    let newContentObj = {
                        paging: contentPageData.data.postData.paging,
                        elements: postDetails.elements.concat(...contentPageData.data.postData.elements)
                    }
                    setpostDetails(newContentObj)
                    let allCompetitorPostData = []
                    newContentObj.elements.forEach(data => {
                        const postObj = { caption: data?.commentary === undefined ? 'This post has no caption' : data?.commentary, id: data.id, timestamp: data.createdAt, socialId: socialId }
                        allCompetitorPostData.push(postObj)
                    })

                    const requestBody = {
                        workspaceId: workspaceId,
                        token: token,
                        media: allCompetitorPostData,
                        startDateMilliseconds: startDateMilliseconds,
                        endDateMilliSeconds: endDateMilliSeconds
                    }
                    const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
                    const groupedData = res.data.data.reduce((acc, obj) => {
                        if (!acc[obj.contentCategory]) {
                            acc[obj.contentCategory] = { contentCategory: obj.contentCategory, socialId: obj.socialId, postId: [] };
                        }
                        acc[obj.contentCategory].postId.push(obj.postId);
                        return acc;
                    }, {});

                    const result = Object.values(groupedData);

                    setstackedDataContent(result)
                }




                setcontentPageLoading(false)

                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                contentPageData: {
                                    loading: false,
                                    error: null,
                                    contentPageData: contentPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        contentPageData: {
                            loading: false,
                            error: null,
                            contentPageData: contentPageData.data
                        }
                    })
                }
            } catch (error) {
                console.log(error)
                setcontentPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                contentPageData: {
                                    loading: false,
                                    error: true,
                                    contentPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        contentPageData: {
                            loading: false,
                            error: true,
                            contentPageData: null
                        }
                    })
                }
            }
        }
    }

    const onChangeContentFilterLoading = (value) => {
        setcontentPageFilterLoading(value)
    }

    const onChangeFollowerFilterLoading = (value) => {
        setfollowePageFilterLoading(value)
    }

    const onChangeVisitorFilterLoading = (value) => {
        setvisitorPageFilterLoading(value)
    }

    const onChangeOverviewFilterLoading = (value) => {
        setoverviewPageFilterLoading(value)
    }

    const changeLoadMore = (value) => {
        setLoadMore(value)
    }

    const changeLoadingState = (value) => {
        setloading(value)
    }

    const getseniority = (senioritiesName) => {
        const senioritiesData = seniorities.filter(senioritiesItem => senioritiesItem.$URN === senioritiesName)
        return senioritiesData[0].name
    }

    const changeFilterPeriod = (value) => {
        setfilterLoading(value)
    }



    const getLinkedinAnalyticsData = async () => {
        try {

            if (loading === false)
                return

            const linkedinToken = userAccountContext.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
            const urn = userAccountContext.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]

            const isAccountExits = userAccountContext.accountsData.filter(data => data?.id === urn && data.selectedPeriod === selectedPeriod.toLocaleString())

            if (isAccountExits.length) {
                setloading(false)
                setfollowerDateArray(isAccountExits[0].dateArray);
                setfollowerCountry(isAccountExits[0].followerCountry)
                setfollowerCountryData(isAccountExits[0].followerCountryData)
                setfollowerOrganicData(isAccountExits[0].followerOrganicData);
                setfollowerPaidData(isAccountExits[0].followerPaidData);
                setorganizationalEntityFollowerStatisticsFilter(isAccountExits[0].organizationalEntityFollowerStatisticsFilter.data)
                setpreviousDatePageView((((isAccountExits[0]?.todayDatePageView - isAccountExits[0]?.previousDatePageView) / isAccountExits[0]?.previousDatePageView) * 100).toFixed(1))
                setpreviousDateUniqueView((((isAccountExits[0].todayDateUniqueView - isAccountExits[0].previousDateUniqueView) / isAccountExits[0].previousDateUniqueView) * 100).toFixed(1))
                setpreviousDateButtonClicks((((isAccountExits[0].todayDateButtonClick - isAccountExits[0].previousDateButtonClick) / isAccountExits[0].previousDateButtonClick) * 100).toFixed(1))
                setorganizationPageStatisticsFilter(isAccountExits[0].apiResOforganizationPageStatisticsFilter.data)
                setimpressionData(isAccountExits[0].impressionData)
                setpreviousDateImpression(isAccountExits[0]?.previousDateImpression)
                setengagementData(isAccountExits[0].engagementData)
                setimpressionPercentage((((isAccountExits[0].impressionData?.reduce((totalImpression, data) => { return totalImpression += data }, 0) - isAccountExits[0]?.previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) / isAccountExits[0]?.previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) * 100).toFixed(1))
                setengagementPercentage((((parseFloat(isAccountExits[0].engagementData?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3) - parseFloat(isAccountExits[0].previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) / parseFloat(isAccountExits[0].previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) * 100).toFixed(1))
                setOrganizationalEntityFollowerStatstics(isAccountExits[0].apiResOfOrganizationalEntityFollowerStatstics)
                setorganizationAllFollower(isAccountExits[0].organizationAllFollower.data)
                setorganizationalEntityShareStatisticsFilter(isAccountExits[0].apiResOforganizationalEntityShareStatisticsFilter.data)
                setpageStatisticsFilterPreviousDate(isAccountExits[0].apiResOforganizationPageStatisticsFilterPreviousDate.data)

                setorganizationalEntityFollowerStatisticsFilter(isAccountExits[0].apiResOforganizationalEntityFollowerStatisticsFilter.data)
                setpreviousDateFollowerPercentage((((isAccountExits[0].todaysCount - isAccountExits[0].previousCount) / isAccountExits[0].previousCount) * 100).toFixed(1))
                settodayDateFollower(isAccountExits[0].todayDateFollower)
                settodayDecisionMaker(isAccountExits[0].todayDecisionMaker)
                setpreviousDateFollower(isAccountExits[0].previousDateFollower)
                setpreviousDecisionMaker(isAccountExits[0].previousDecisionMaker)
                setpageViewData(isAccountExits[0].pageViewData)
                setpageViewDataForMobile(isAccountExits[0].pageViewDataForMobile)
                setuniquePageViewData(isAccountExits[0].uniquePageViewData)
                setuniquePageViewDataMobile(isAccountExits[0].uniquePageViewDataMobile)
                setcaption(isAccountExits[0].caption)
                setsocialMetadata(isAccountExits[0].socialMetadata)
                setpostDetails(isAccountExits[0].postDetails)

                const mediaID = isAccountExits[0].socialMetadata?.content?.media?.id



                if (mediaID !== undefined) {
                    if (mediaID.includes('video')) {
                        setmediaType('video')
                        setmediaUrl(isAccountExits[0].videoMediaDetails?.data?.results[`${isAccountExits[0].postDetails.data.elements[0].content?.media?.id}`]?.thumbnail)
                    }
                    if (mediaID.includes('document')) {
                        setmediaType('document')

                        setmediaUrl(isAccountExits[0].socialMetadata?.content?.media.mediaUrl)
                    }
                    if (mediaID.includes('image')) {
                        setmediaType('image')
                        setmediaUrl(isAccountExits[0].socialMetadata?.content?.media.mediaUrl)
                    }
                } else {
                    setmediaType('')
                    setmediaUrl('')
                }

            } else {

                setloading(true)
                const currentDate = new Date();

                // Calculate the date 29 days ago
                const startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - selectedPeriod);


                // Get the milliseconds for the start date
                const startDateMilliseconds = startDate.getTime();
                const endDateMilliSeconds = currentDate.getTime();

                const previousDate = new Date(startDate)
                previousDate.setDate(startDate.getDate() - selectedPeriod)
                const previousDateStartDateMiliSec = previousDate.getTime()

                // Initialize an array to store the dates
                const dateArray = [];
                const filterDateArray = [];
                // Calculate and insert 6 dates into the array
                for (let i = 0; i < (selectedPeriod); i++) {
                    const newDate = new Date(startDate);
                    newDate.setDate(startDate.getDate() + i); // Adjust the increment as needed
                    const monthShortName = newDate.toLocaleString('default', { month: 'short' });
                    const day = String(newDate.getDate()).padStart(2, '0');
                    dateArray.push(`${monthShortName}-${day}`);
                    const dateInMilliseconds = newDate.setHours(0, 0, 0, 0)
                    filterDateArray.push(dateInMilliseconds)
                }
                setfollowerDateArray(dateArray);

                const apiResOfPagePost = await APICall(`${url}analytics/linkedin/rest/posts?author=urn%3Ali%3Aorganization%3A${urn}&q=author&count=${count}&sortBy=LAST_MODIFIED`, {}, 1, true, linkedinToken)

                const mediaID = apiResOfPagePost?.data?.elements?.map(data => data?.content?.media?.id).filter(data => data !== undefined)

                const postId = apiResOfPagePost?.data?.elements?.map(data => { if (data?.id?.includes('ugcPost')) { return { id: data.id } } if (data?.id?.includes('share')) { return { id: data.id } } return null }).filter(data => data !== null)
                const postDocumentIds = apiResOfPagePost?.data?.elements?.map(data => { if (data?.content?.media?.id?.includes('document')) { return encodeURIComponent(data?.content?.media?.id) } return null }).filter(data => data !== null)
                const postImages = apiResOfPagePost?.data?.elements?.map(data => { if (data?.content?.media?.id?.includes('image')) { return encodeURIComponent(data?.content?.media?.id) } return null }).filter(data => data !== null)
                const videoMedia = apiResOfPagePost?.data?.elements?.map(data => { if (data?.content?.media?.id?.includes('video')) { return encodeURIComponent(data?.content?.media?.id) } return null }).filter(data => data !== null)

                const encodePostIds = postId?.map(data => encodeURIComponent(data.id))


                const [apiResOfOrganizationalEntityFollowerStatstics, apiResOforganizationalEntityFollowerStatisticsFilter, apiResOforganizationPageStatisticsFilter, apiResOforganizationPageStatisticsFilterPreviousDate, apiResOforganizationalEntityShareStatisticsFilter, apiResOforganizationalEntityShareStatisticsFilterPreviousDate, apiResOforganizationalEntityFollowerStatisticsPreviousdate, apiPostAllDetails, apiPostDocuments, apiPostImages, postAuthorNames, videoMediaDetails] = await Promise.all([
                    await APICall(`${url}analytics/linkedin/organizationalEntityFollowerStatistics?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationalEntityFollowerStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${startDateMilliseconds}&end=${endDateMilliSeconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationPageStatisticsWithFilter?q=organization&organization=urn:li:organization:${urn}&timeGranularityType=DAY&start=${startDateMilliseconds}&end=${endDateMilliSeconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationPageStatisticsWithFilter?q=organization&organization=urn:li:organization:${urn}&timeGranularityType=DAY&start=${previousDateStartDateMiliSec}&end=${startDateMilliseconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationalEntityShareStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${startDateMilliseconds}&end=${endDateMilliSeconds}`, {}, 1, true, linkedinToken),

                    await APICall(`${url}analytics/linkedin/organizationalEntityShareStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${previousDateStartDateMiliSec}&end=${startDateMilliseconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationalEntityFollowerStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${previousDateStartDateMiliSec}&end=${startDateMilliseconds}`, {}, 1, true, linkedinToken),
                    postId.length ? await APICall(`${url}analytics/linkedin/rest/getPostDetailsById?q=organizationalEntity&organizationalEntity=urn%3Ali%3Aorganization%3A${urn}`, {
                        ugcPost: postId
                    }, 2, true, linkedinToken) : [],
                    postDocumentIds.length ? await APICall(`${url}analytics/linkedin/rest/batchDocuments`, {
                        ids: `List(${postDocumentIds})`
                    }, 2, true, linkedinToken) : [],
                    postImages.length ? await APICall(`${url}analytics/linkedin/rest/batchImages`, {
                        ids: `List(${postImages})`
                    }, 2, true, linkedinToken) : [],

                    encodePostIds.length ? await APICall(`${url}analytics/linkedin/rest/ugcPostsDetails`, {
                        ids: `List(${encodePostIds})`,
                        projection: "(results*(created(actor~)))"
                    }, 2, true, linkedinToken) : [],
                    videoMedia.length ? await APICall(`${url}analytics/linkedin/rest/batchVideos/`, {
                        ids: `List(${videoMedia})`
                    }, 2, true, linkedinToken) : [],

                ])



                const countryPromise = apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry.map(async (data) => {
                    const countryDetails = await APICall(`${url}analytics/linkedin/rest/getCountryDetails?query=${encodeURIComponent(data.geo)}`, {}, 1, true, linkedinToken)

                    return {
                        ...data,
                        geo: countryDetails.data
                    }
                })




                const resolvedWebhookData = await Promise.all(countryPromise);




                if (variables.leftSideBarState === urn) {





                    setloading(false)

                    const firstFiveCountries = resolvedWebhookData?.slice(0, 6);

                    const thirdArray = firstFiveCountries?.map(countryCode2 => {
                        const matchingCountry = country?.find(country => country?.name?.toLowerCase() === countryCode2?.geo.elements[0].displayText.toLowerCase());
                        return matchingCountry ? matchingCountry.code3 : null;
                    });

                    setfollowerCountry(thirdArray)

                    setfollowerCountryData(firstFiveCountries?.map(data => parseInt(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)))


                    const filteredElements = apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.filter(followerData => {

                        return followerData
                    });

                    setfollowerOrganicData(filteredElements?.map(followerData => followerData.followerGains.organicFollowerGain));
                    setfollowerPaidData(filteredElements?.map(followerData => followerData.followerGains.paidFollowerGain));
                    setorganizationalEntityFollowerStatisticsFilter(apiResOforganizationalEntityFollowerStatisticsFilter.data)

                    const todayDatePageView = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.pageViews
                        return totalPageView
                    }, 0)

                    const previousDatePageView = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.pageViews
                        return totalPageView
                    }, 0)

                    setpreviousDatePageView((((todayDatePageView - previousDatePageView) / previousDatePageView) * 100).toFixed(1))

                    const todayDateUniqueView = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.uniquePageViews
                        return totalPageView
                    }, 0)

                    const previousDateUniqueView = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.uniquePageViews
                        return totalPageView
                    }, 0)
                    setpreviousDateUniqueView((((todayDateUniqueView - previousDateUniqueView) / previousDateUniqueView) * 100).toFixed(1))

                    const todayDateButtonClick = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                        totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                        return totalPageView
                    }, 0)

                    const previousDateButtonClick = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                        totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                        return totalPageView
                    }, 0)
                    setpreviousDateButtonClicks((((todayDateButtonClick - previousDateButtonClick) / previousDateButtonClick) * 100).toFixed(1))

                    setorganizationPageStatisticsFilter(apiResOforganizationPageStatisticsFilter.data)

                    const filteredElementsOfShareStaticstics = apiResOforganizationalEntityShareStatisticsFilter.data.elements?.filter(followerData => {
                        // Check if the timeRange.start is in the filterDateArray
                        return followerData
                    });

                    setimpressionData(filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.impressionCount))

                    setengagementData(filteredElementsOfShareStaticstics?.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)))
                    const filteredElementsOfShareStaticsticsPreviousDate = apiResOforganizationalEntityShareStatisticsFilterPreviousDate.data.elements?.filter(followerData => {
                        // Check if the timeRange.start is in the filterDateArray
                        return followerData
                    });

                    const previousDateImpression = filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.impressionCount)
                    const previousDateEngagemntRate = filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.engagement)
                    setpreviousDateImpression(previousDateImpression)
                    setimpressionPercentage((((filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.impressionCount)?.reduce((totalImpression, data) => { return totalImpression += data }, 0) - previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) / previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) * 100).toFixed(1))


                    setengagementPercentage((((parseFloat(filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.engagement)?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3) - parseFloat(previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) / parseFloat(previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) * 100).toFixed(1))
                    setOrganizationalEntityFollowerStatstics(resolvedWebhookData)
                    setorganizationAllFollower(apiResOfOrganizationalEntityFollowerStatstics.data)


                    setorganizationalEntityShareStatisticsFilter(apiResOforganizationalEntityShareStatisticsFilter.data)




                    setpageStatisticsFilterPreviousDate(apiResOforganizationPageStatisticsFilterPreviousDate.data)


                    setorganizationalEntityFollowerStatisticsFilter(apiResOforganizationalEntityFollowerStatisticsFilter.data)
                    const todaysCount = apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.reduce((totalFollower, data) => {
                        totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                        return totalFollower
                    }, 0)

                    const previousCount = apiResOforganizationalEntityFollowerStatisticsPreviousdate.data.elements?.reduce((totalFollower, data) => {
                        totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                        return totalFollower
                    }, 0)
                    setpreviousDateFollowerPercentage((((todaysCount - previousCount) / previousCount) * 100).toFixed(1))
                    settodayDateFollower(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0])
                    const filter = ['Owner', 'Director', 'CXO', 'VP', 'Partner']
                    settodayDecisionMaker(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority))).reduce((totalDecisionMaker, data) => {
                        return totalDecisionMaker += data.followerCounts.organicFollowerCount
                    }, 0))

                    setpreviousDateFollower(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0])
                    setpreviousDecisionMaker(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority)))?.reduce((totalDecisionMaker, data) => {
                        return totalDecisionMaker += data.followerCounts.organicFollowerCount
                    }, 0))
                    const filteredPageViewElements = apiResOforganizationPageStatisticsFilter.data?.elements?.filter(pageViews => {
                        // Check if the timeRange.start is in the filterDateArray
                        return pageViews
                    })
                    setpageViewData(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.pageViews))
                    setpageViewDataForMobile(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.pageViews))
                    setuniquePageViewData(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.uniquePageViews))
                    setuniquePageViewDataMobile(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.uniquePageViews))




                    apiResOfPagePost.data.elements?.forEach(data => {

                        const filterPost = apiPostAllDetails.data.elements?.filter(item => (item.ugcPost !== undefined ? item.ugcPost : item.share) === data.id)
                        if (filterPost?.length) {

                            data.uniqueImpressionsCount = filterPost[0].totalShareStatistics.uniqueImpressionsCount
                            data.shareCount = filterPost[0].totalShareStatistics.shareCount
                            data.engagement = filterPost[0].totalShareStatistics.engagement
                            data.likeCount = filterPost[0].totalShareStatistics.likeCount
                            data.impressionCount = filterPost[0].totalShareStatistics.impressionCount
                            data.commentCount = filterPost[0].totalShareStatistics.commentCount
                            data.clickCount = filterPost[0].totalShareStatistics.clickCount
                            data.authorName = postAuthorNames?.data?.results[`${data.id}`]?.created['actor~']?.firstName?.localized?.en_US + ' ' + postAuthorNames?.data?.results[`${data.id}`]?.created['actor~']?.lastName?.localized?.en_US
                        }
                        if (data?.content?.media?.id?.includes('video')) {

                            data.content.media.mediaUrl = videoMediaDetails?.data?.results[`${data?.content?.media?.id}`]?.thumbnail
                        }

                        if (data?.content?.media?.id?.includes('document')) {

                            data.content.media.mediaUrl = apiPostDocuments?.data?.results[`${data?.content?.media?.id}`]?.downloadUrl
                        }
                        if (data?.content?.media?.id?.includes('image')) {

                            data.content.media.mediaUrl = apiPostImages?.data?.results[`${data?.content?.media?.id}`]?.downloadUrl
                        }
                    })

                    setcaption(apiResOfPagePost.data.elements?.map(data => data.commentary)[0])
                    if (mediaID[0] === undefined) {
                        setmediaType('')
                        setmediaUrl('')
                    }
                    if (mediaID[0]?.includes('video')) {
                        setmediaType('video')
                        setmediaUrl(videoMediaDetails?.data?.results[`${apiResOfPagePost.data.elements[0].content?.media?.id}`]?.thumbnail)
                    }
                    if (mediaID[0]?.includes('document')) {
                        setmediaType('document')
                        setmediaUrl(apiResOfPagePost?.data?.elements?.map(data => data?.content?.media?.mediaUrl)[0])
                    }
                    if (mediaID[0]?.includes('image')) {
                        setmediaType('image')
                        setmediaUrl(apiResOfPagePost?.data?.elements?.map(data => data?.content?.media?.mediaUrl)[0])
                    }

                    setsocialMetadata(apiResOfPagePost.data.elements[0])
                    const filterPost = apiResOfPagePost.data.elements.filter(data =>
                        data.createdAt >= startDateMilliseconds && data.createdAt <= endDateMilliSeconds)
                    const postResponseObj = {
                        paging: apiResOfPagePost.data.paging,
                        elements: filterPost
                    }
                    setpostDetails(postResponseObj)



                    const obj = {
                        id: urn,
                        selectedPeriod: selectedPeriod.toString(),
                        dateArray: dateArray,
                        followerCountry: thirdArray,
                        followerCountryData: firstFiveCountries?.map(data => parseInt(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)),
                        followerOrganicData: filteredElements?.map(followerData => followerData.followerGains.organicFollowerGain),
                        followerPaidData: filteredElements?.map(followerData => followerData.followerGains.paidFollowerGain),
                        organizationalEntityFollowerStatisticsFilter: organizationalEntityFollowerStatisticsFilter,
                        todayDatePageView: todayDatePageView,
                        previousDatePageView: previousDatePageView,
                        todayDateUniqueView: todayDateUniqueView,
                        previousDateUniqueView: previousDateUniqueView,
                        todayDateButtonClick: todayDateButtonClick,
                        previousDateButtonClick: previousDateButtonClick,
                        apiResOforganizationPageStatisticsFilter: apiResOforganizationPageStatisticsFilter,
                        impressionData: filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.impressionCount),
                        engagementData: filteredElementsOfShareStaticstics?.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)),
                        previousDateImpression: filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.impressionCount),
                        previousDateEngagemntRate: filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.engagement),
                        apiResOfOrganizationalEntityFollowerStatstics: resolvedWebhookData,
                        organizationAllFollower: apiResOfOrganizationalEntityFollowerStatstics,
                        apiResOforganizationalEntityShareStatisticsFilter: apiResOforganizationalEntityShareStatisticsFilter,
                        apiResOforganizationPageStatisticsFilterPreviousDate: apiResOforganizationPageStatisticsFilterPreviousDate,

                        apiResOforganizationalEntityFollowerStatisticsFilter: apiResOforganizationalEntityFollowerStatisticsFilter,
                        todaysCount: apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.reduce((totalFollower, data) => {
                            totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                            return totalFollower
                        }, 0),
                        previousCount: apiResOforganizationalEntityFollowerStatisticsPreviousdate.data.elements?.reduce((totalFollower, data) => {
                            totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                            return totalFollower
                        }, 0),
                        todayDateFollower: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0],
                        todayDecisionMaker: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority))).reduce((totalDecisionMaker, data) => {
                            return totalDecisionMaker += data.followerCounts.organicFollowerCount
                        }, 0),
                        previousDateFollower: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0],
                        previousDecisionMaker: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority)))?.reduce((totalDecisionMaker, data) => {
                            return totalDecisionMaker += data.followerCounts.organicFollowerCount
                        }, 0),
                        pageViewData: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.pageViews),
                        pageViewDataForMobile: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.pageViews),
                        uniquePageViewData: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.uniquePageViews),
                        uniquePageViewDataMobile: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.uniquePageViews),
                        caption: apiResOfPagePost.data.elements?.map(data => data.commentary)[0],
                        socialMetadata: apiResOfPagePost.data.elements[0],
                        postDetails: postResponseObj,
                        videoMediaDetails: videoMediaDetails
                    }



                    userAccountContext.handleaccountsData(obj)
                } else {
                    setloading(false)

                    const firstFiveCountries = resolvedWebhookData?.slice(0, 6);

                    const thirdArray = firstFiveCountries?.map(countryCode2 => {
                        const matchingCountry = country?.find(country => country?.name?.toLowerCase() === countryCode2?.geo.elements[0].displayText.toLowerCase());
                        return matchingCountry ? matchingCountry.code3 : null;
                    });

                    const filteredElements = apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.filter(followerData => {

                        return followerData
                    });

                    const todayDatePageView = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.pageViews
                        return totalPageView
                    }, 0)

                    const previousDatePageView = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.pageViews
                        return totalPageView
                    }, 0)

                    const todayDateUniqueView = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.uniquePageViews
                        return totalPageView
                    }, 0)

                    const previousDateUniqueView = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                        totalPageView += data1.allPageViews?.uniquePageViews
                        return totalPageView
                    }, 0)

                    const todayDateButtonClick = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                        totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                        return totalPageView
                    }, 0)

                    const previousDateButtonClick = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                        totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                        return totalPageView
                    }, 0)

                    const filteredElementsOfShareStaticstics = apiResOforganizationalEntityShareStatisticsFilter.data.elements?.filter(followerData => {
                        // Check if the timeRange.start is in the filterDateArray
                        return followerData
                    });

                    const filter = ['Owner', 'Director', 'CXO', 'VP', 'Partner']

                    const filteredPageViewElements = apiResOforganizationPageStatisticsFilter.data?.elements?.filter(pageViews => {
                        // Check if the timeRange.start is in the filterDateArray
                        return pageViews
                    })

                    const filteredElementsOfShareStaticsticsPreviousDate = apiResOforganizationalEntityShareStatisticsFilterPreviousDate.data.elements?.filter(followerData => {
                        // Check if the timeRange.start is in the filterDateArray
                        return followerData
                    });

                    apiResOfPagePost.data.elements?.forEach(data => {

                        const filterPost = apiPostAllDetails.data.elements?.filter(item => (item.ugcPost !== undefined ? item.ugcPost : item.share) === data.id)
                        if (filterPost?.length) {

                            data.uniqueImpressionsCount = filterPost[0].totalShareStatistics.uniqueImpressionsCount
                            data.shareCount = filterPost[0].totalShareStatistics.shareCount
                            data.engagement = filterPost[0].totalShareStatistics.engagement
                            data.likeCount = filterPost[0].totalShareStatistics.likeCount
                            data.impressionCount = filterPost[0].totalShareStatistics.impressionCount
                            data.commentCount = filterPost[0].totalShareStatistics.commentCount
                            data.clickCount = filterPost[0].totalShareStatistics.clickCount
                            data.authorName = postAuthorNames?.data?.results[`${data.id}`]?.created['actor~']?.firstName?.localized?.en_US + ' ' + postAuthorNames?.data?.results[`${data.id}`]?.created['actor~']?.lastName?.localized?.en_US
                        }
                        if (data?.content?.media?.id?.includes('video')) {

                            data.content.media.mediaUrl = videoMediaDetails?.data?.results[`${data?.content?.media?.id}`]?.thumbnail
                        }

                        if (data?.content?.media?.id?.includes('document')) {

                            data.content.media.mediaUrl = apiPostDocuments?.data?.results[`${data?.content?.media?.id}`]?.downloadUrl
                        }
                        if (data?.content?.media?.id?.includes('image')) {

                            data.content.media.mediaUrl = apiPostImages?.data?.results[`${data?.content?.media?.id}`]?.downloadUrl
                        }
                    })

                    const filterPost = apiResOfPagePost.data.elements.filter(data =>
                        data.createdAt >= startDateMilliseconds && data.createdAt <= endDateMilliSeconds)
                    const postResponseObj = {
                        paging: apiResOfPagePost.data.paging,
                        elements: filterPost
                    }
                    const obj = {
                        id: urn,
                        selectedPeriod: selectedPeriod.toString(),
                        dateArray: dateArray,
                        followerCountry: thirdArray,
                        followerCountryData: firstFiveCountries?.map(data => parseInt(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)),
                        followerOrganicData: filteredElements?.map(followerData => followerData.followerGains.organicFollowerGain),
                        followerPaidData: filteredElements?.map(followerData => followerData.followerGains.paidFollowerGain),
                        organizationalEntityFollowerStatisticsFilter: organizationalEntityFollowerStatisticsFilter,
                        todayDatePageView: todayDatePageView,
                        previousDatePageView: previousDatePageView,
                        todayDateUniqueView: todayDateUniqueView,
                        previousDateUniqueView: previousDateUniqueView,
                        todayDateButtonClick: todayDateButtonClick,
                        previousDateButtonClick: previousDateButtonClick,
                        apiResOforganizationPageStatisticsFilter: apiResOforganizationPageStatisticsFilter,
                        impressionData: filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.impressionCount),
                        engagementData: filteredElementsOfShareStaticstics?.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)),
                        previousDateImpression: filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.impressionCount),
                        previousDateEngagemntRate: filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.engagement),
                        apiResOfOrganizationalEntityFollowerStatstics: resolvedWebhookData,
                        organizationAllFollower: apiResOfOrganizationalEntityFollowerStatstics,
                        apiResOforganizationalEntityShareStatisticsFilter: apiResOforganizationalEntityShareStatisticsFilter,
                        apiResOforganizationPageStatisticsFilterPreviousDate: apiResOforganizationPageStatisticsFilterPreviousDate,

                        apiResOforganizationalEntityFollowerStatisticsFilter: apiResOforganizationalEntityFollowerStatisticsFilter,
                        todaysCount: apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.reduce((totalFollower, data) => {
                            totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                            return totalFollower
                        }, 0),
                        previousCount: apiResOforganizationalEntityFollowerStatisticsPreviousdate.data.elements?.reduce((totalFollower, data) => {
                            totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                            return totalFollower
                        }, 0),
                        todayDateFollower: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0],
                        todayDecisionMaker: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority))).reduce((totalDecisionMaker, data) => {
                            return totalDecisionMaker += data.followerCounts.organicFollowerCount
                        }, 0),
                        previousDateFollower: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0],
                        previousDecisionMaker: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority)))?.reduce((totalDecisionMaker, data) => {
                            return totalDecisionMaker += data.followerCounts.organicFollowerCount
                        }, 0),
                        pageViewData: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.pageViews),
                        pageViewDataForMobile: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.pageViews),
                        uniquePageViewData: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.uniquePageViews),
                        uniquePageViewDataMobile: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.uniquePageViews),
                        caption: apiResOfPagePost.data.elements?.map(data => data.commentary)[0],
                        socialMetadata: apiResOfPagePost.data.elements[0],
                        postDetails: postResponseObj,
                        videoMediaDetails: videoMediaDetails
                    }

                    userAccountContext.handleaccountsData(obj)
                }



            }
        } catch (error) {
            toast.error('Something went to wrong')
            setloading(false)
        }
    }

    const getLinkedinAnalyticsDataAsperSelectedPeriod = async () => {
        try {

            if (filterLoading === false) return

            const linkedinToken = userAccountContext.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
            const urn = userAccountContext.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]

            const isAccountExits = userAccountContext.accountsData.filter(data => data?.id === urn && selectedPeriod.toString() === data.selectedPeriod)
            if (isAccountExits.length) {

                setfollowerDateArray(isAccountExits[0].dateArray);
                setfollowerCountry(isAccountExits[0].followerCountry)
                setfollowerCountryData(isAccountExits[0].followerCountryData)
                setfollowerOrganicData(isAccountExits[0].followerOrganicData);
                setfollowerPaidData(isAccountExits[0].followerPaidData);
                setorganizationalEntityFollowerStatisticsFilter(isAccountExits[0].organizationalEntityFollowerStatisticsFilter.data)
                setpreviousDatePageView((((isAccountExits[0]?.todayDatePageView - isAccountExits[0]?.previousDatePageView) / isAccountExits[0]?.previousDatePageView) * 100).toFixed(1))
                setpreviousDateUniqueView((((isAccountExits[0].todayDateUniqueView - isAccountExits[0].previousDateUniqueView) / isAccountExits[0].previousDateUniqueView) * 100).toFixed(1))
                setpreviousDateButtonClicks((((isAccountExits[0].todayDateButtonClick - isAccountExits[0].previousDateButtonClick) / isAccountExits[0].previousDateButtonClick) * 100).toFixed(1))
                setorganizationPageStatisticsFilter(isAccountExits[0].apiResOforganizationPageStatisticsFilter.data)
                setimpressionData(isAccountExits[0].impressionData)
                setengagementData(isAccountExits[0].engagementData)
                setpreviousDateImpression(isAccountExits[0]?.previousDateImpression)
                setimpressionPercentage((((isAccountExits[0].impressionData?.reduce((totalImpression, data) => { return totalImpression += data }, 0) - isAccountExits[0]?.previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) / isAccountExits[0]?.previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) * 100).toFixed(1))
                setengagementPercentage((((parseFloat(isAccountExits[0].engagementData?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3) - parseFloat(isAccountExits[0].previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) / parseFloat(isAccountExits[0].previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) * 100).toFixed(1))
                setOrganizationalEntityFollowerStatstics(isAccountExits[0].apiResOfOrganizationalEntityFollowerStatstics)
                setorganizationAllFollower(isAccountExits[0].organizationAllFollower.data)
                setorganizationalEntityShareStatisticsFilter(isAccountExits[0].apiResOforganizationalEntityShareStatisticsFilter.data)
                setpageStatisticsFilterPreviousDate(isAccountExits[0].apiResOforganizationPageStatisticsFilterPreviousDate.data)

                setorganizationalEntityFollowerStatisticsFilter(isAccountExits[0].apiResOforganizationalEntityFollowerStatisticsFilter.data)
                setpreviousDateFollowerPercentage((((isAccountExits[0].todaysCount - isAccountExits[0].previousCount) / isAccountExits[0].previousCount) * 100).toFixed(1))
                settodayDateFollower(isAccountExits[0].todayDateFollower)
                settodayDecisionMaker(isAccountExits[0].todayDecisionMaker)
                setpreviousDateFollower(isAccountExits[0].previousDateFollower)
                setpreviousDecisionMaker(isAccountExits[0].previousDecisionMaker)
                setpageViewData(isAccountExits[0].pageViewData)
                setpageViewDataForMobile(isAccountExits[0].pageViewDataForMobile)
                setuniquePageViewData(isAccountExits[0].uniquePageViewData)
                setuniquePageViewDataMobile(isAccountExits[0].uniquePageViewDataMobile)
                setcaption(isAccountExits[0].caption)
                setsocialMetadata(isAccountExits[0].socialMetadata)
                setpostDetails(isAccountExits[0].postDetails)

                const mediaID = isAccountExits[0].socialMetadata?.content?.media?.id


                if (mediaID?.length) {
                    if (mediaID.includes('video')) {
                        setmediaType('video')
                        setmediaUrl(isAccountExits[0].videoMediaDetails?.data?.results[`${isAccountExits[0].postDetails.data.elements[0].content?.media?.id}`]?.thumbnail)
                    }
                    if (mediaID.includes('document')) {
                        setmediaType('document')

                        setmediaUrl(isAccountExits[0].socialMetadata?.content?.media.mediaUrl)
                    }
                    if (mediaID.includes('image')) {
                        setmediaType('image')
                        setmediaUrl(isAccountExits[0].socialMetadata?.content?.media.mediaUrl)
                    }
                } else {
                    setmediaType('')
                    setmediaUrl('')
                }
                setloading(false)
                setpaginationLoading(false)
                setfilterLoading(false)

            } else {



                setloading(true)
                const currentDate = new Date();

                // Calculate the date 29 days ago
                const startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - selectedPeriod);

                // Get the milliseconds for the start date
                const startDateMilliseconds = startDate.getTime();
                const endDateMilliSeconds = currentDate.getTime();

                const previousDate = new Date(startDate)
                previousDate.setDate(startDate.getDate() - selectedPeriod)
                const previousDateStartDateMiliSec = previousDate.getTime()

                // Initialize an array to store the dates
                const dateArray = [];
                const filterDateArray = [];
                // Calculate and insert 6 dates into the array
                for (let i = 0; i < (selectedPeriod); i++) {
                    const newDate = new Date(startDate);
                    newDate.setDate(startDate.getDate() + i); // Adjust the increment as needed
                    const monthShortName = newDate.toLocaleString('default', { month: 'short' });
                    const day = String(newDate.getDate()).padStart(2, '0');
                    dateArray.push(`${monthShortName}-${day}`);
                    const dateInMilliseconds = newDate.setHours(0, 0, 0, 0)
                    filterDateArray.push(dateInMilliseconds)
                }
                setfollowerDateArray(dateArray);

                const apiResOfPagePost = await APICall(`${url}analytics/linkedin/rest/posts?author=urn%3Ali%3Aorganization%3A${urn}&q=author&count=${count}&sortBy=LAST_MODIFIED`, {}, 1, true, linkedinToken)

                const mediaID = apiResOfPagePost.data.elements?.map(data => data?.content?.media?.id)
                const postId = apiResOfPagePost.data.elements?.map(data => { if (data?.id?.includes('ugcPost')) { return { id: data.id } } if (data?.id?.includes('share')) { return { id: data.id } } return null }).filter(data => data !== null)
                const postDocumentIds = apiResOfPagePost.data.elements?.map(data => { if (data?.content?.media?.id?.includes('document')) { return encodeURIComponent(data?.content?.media.id) } return null }).filter(data => data !== null)
                const postImages = apiResOfPagePost.data.elements?.map(data => { if (data?.content?.media?.id?.includes('image')) { return encodeURIComponent(data?.content?.media?.id) } return null }).filter(data => data !== null)
                const videoMedia = apiResOfPagePost?.data?.elements?.map(data => { if (data?.content?.media?.id?.includes('video')) { return encodeURIComponent(data?.content?.media?.id) } return null }).filter(data => data !== null)

                const encodePostIds = postId?.map(data => encodeURIComponent(data.id))

                const [apiResOfOrganizationalEntityFollowerStatstics, apiResOforganizationalEntityFollowerStatisticsFilter, apiResOforganizationPageStatisticsFilter, apiResOforganizationPageStatisticsFilterPreviousDate, apiResOforganizationalEntityShareStatisticsFilter, apiResOforganizationalEntityShareStatisticsFilterPreviousDate, apiResOforganizationalEntityFollowerStatisticsPreviousdate, apiPostAllDetails, apiPostDocuments, apiPostImages, postAuthorNames, videoMediaDetails] = await Promise.all([
                    await APICall(`${url}analytics/linkedin/organizationalEntityFollowerStatistics?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationalEntityFollowerStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${startDateMilliseconds}&end=${endDateMilliSeconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationPageStatisticsWithFilter?q=organization&organization=urn:li:organization:${urn}&timeGranularityType=DAY&start=${startDateMilliseconds}&end=${endDateMilliSeconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationPageStatisticsWithFilter?q=organization&organization=urn:li:organization:${urn}&timeGranularityType=DAY&start=${previousDateStartDateMiliSec}&end=${startDateMilliseconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationalEntityShareStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${startDateMilliseconds}&end=${endDateMilliSeconds}`, {}, 1, true, linkedinToken),

                    await APICall(`${url}analytics/linkedin/organizationalEntityShareStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${previousDateStartDateMiliSec}&end=${startDateMilliseconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/organizationalEntityFollowerStatisticsFilter?q=organizationalEntity&organizationalEntity=urn:li:organization:${urn}&timeGranularityType=DAY&start=${previousDateStartDateMiliSec}&end=${startDateMilliseconds}`, {}, 1, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/rest/getPostDetailsById?q=organizationalEntity&organizationalEntity=urn%3Ali%3Aorganization%3A${urn}`, {
                        ugcPost: postId
                    }, 2, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/rest/batchDocuments`, {
                        ids: `List(${postDocumentIds})`
                    }, 2, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/rest/batchImages`, {
                        ids: `List(${postImages})`
                    }, 2, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/rest/ugcPostsDetails`, {
                        ids: `List(${encodePostIds})`,
                        projection: "(results*(created(actor~)))"
                    }, 2, true, linkedinToken),
                    await APICall(`${url}analytics/linkedin/rest/batchVideos/`, {
                        ids: `List(${videoMedia})`
                    }, 2, true, linkedinToken)
                ])




                const countryPromise = apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry.map(async (data) => {
                    const countryDetails = await APICall(`${url}analytics/linkedin/rest/getCountryDetails?query=${encodeURIComponent(data.geo)}`, {}, 1, true, linkedinToken)

                    return {
                        ...data,
                        geo: countryDetails.data
                    }
                })

                const resolvedWebhookData = await Promise.all(countryPromise);

                const firstFiveCountries = resolvedWebhookData?.slice(0, 6);

                const thirdArray = firstFiveCountries?.map(countryCode2 => {
                    const matchingCountry = country?.find(country => country?.name?.toLowerCase() === countryCode2?.geo.elements[0].displayText.toLowerCase());
                    return matchingCountry ? matchingCountry.code3 : null;
                })
                setfollowerCountry(thirdArray)

                setfollowerCountryData(firstFiveCountries?.map(data => parseInt(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)))


                const filteredElements = apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.filter(followerData => {

                    return followerData
                });


                setfollowerOrganicData(filteredElements?.map(followerData => followerData.followerGains.organicFollowerGain));
                setfollowerPaidData(filteredElements?.map(followerData => followerData.followerGains.paidFollowerGain));
                setorganizationalEntityFollowerStatisticsFilter(apiResOforganizationalEntityFollowerStatisticsFilter.data)

                const todayDatePageView = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.pageViews
                    return totalPageView
                }, 0)

                const previousDatePageView = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.pageViews
                    return totalPageView
                }, 0)

                setpreviousDatePageView((((todayDatePageView - previousDatePageView) / previousDatePageView) * 100).toFixed(1))

                const todayDateUniqueView = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.uniquePageViews
                    return totalPageView
                }, 0)

                const previousDateUniqueView = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((totalPageView, data1) => {
                    totalPageView += data1.allPageViews?.uniquePageViews
                    return totalPageView
                }, 0)
                setpreviousDateUniqueView((((todayDateUniqueView - previousDateUniqueView) / previousDateUniqueView) * 100).toFixed(1))

                const todayDateButtonClick = apiResOforganizationPageStatisticsFilter.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                    totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                    return totalPageView
                }, 0)

                const previousDateButtonClick = apiResOforganizationPageStatisticsFilterPreviousDate.data.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics?.clicks)?.reduce((totalPageView, data1) => {
                    totalPageView += parseInt(data1.mobileCustomButtonClickCounts[0]?.clicks + data1.desktopCustomButtonClickCounts[0]?.clicks)
                    return totalPageView
                }, 0)
                setpreviousDateButtonClicks((((todayDateButtonClick - previousDateButtonClick) / previousDateButtonClick) * 100).toFixed(1))

                setorganizationPageStatisticsFilter(apiResOforganizationPageStatisticsFilter.data)

                const filteredElementsOfShareStaticstics = apiResOforganizationalEntityShareStatisticsFilter.data.elements?.filter(followerData => {
                    // Check if the timeRange.start is in the filterDateArray
                    return followerData
                });

                setimpressionData(filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.impressionCount))
                setengagementData(filteredElementsOfShareStaticstics?.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)))
                const filteredElementsOfShareStaticsticsPreviousDate = apiResOforganizationalEntityShareStatisticsFilterPreviousDate.data.elements?.filter(followerData => {
                    // Check if the timeRange.start is in the filterDateArray
                    return followerData
                });
                const previousDateImpression = filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.impressionCount)
                const previousDateEngagemntRate = filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.engagement)
                setpreviousDateImpression(previousDateImpression)
                setimpressionPercentage((((filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.impressionCount)?.reduce((totalImpression, data) => { return totalImpression += data }, 0) - previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) / previousDateImpression?.reduce((totalPreviousDateImpression, data) => { return totalPreviousDateImpression += data }, 0)) * 100).toFixed(1))
                setengagementPercentage((((parseFloat(filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.engagement)?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3) - parseFloat(previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) / parseFloat(previousDateEngagemntRate?.reduce((totalPreviousDateEngagement, data) => { return totalPreviousDateEngagement += data }, 0)).toFixed(3)) * 100).toFixed(1))
                setOrganizationalEntityFollowerStatstics(resolvedWebhookData)
                setorganizationAllFollower(apiResOfOrganizationalEntityFollowerStatstics.data)

                setorganizationalEntityShareStatisticsFilter(apiResOforganizationalEntityShareStatisticsFilter.data)




                setpageStatisticsFilterPreviousDate(apiResOforganizationPageStatisticsFilterPreviousDate.data)


                setorganizationalEntityFollowerStatisticsFilter(apiResOforganizationalEntityFollowerStatisticsFilter.data)
                const todaysCount = apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.reduce((totalFollower, data) => {
                    totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                    return totalFollower
                }, 0)

                const previousCount = apiResOforganizationalEntityFollowerStatisticsPreviousdate.data.elements?.reduce((totalFollower, data) => {
                    totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                    return totalFollower
                }, 0)
                setpreviousDateFollowerPercentage((((todaysCount - previousCount) / previousCount) * 100).toFixed(1))
                settodayDateFollower(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0])
                const filter = ['Owner', 'Director', 'CXO', 'VP', 'Partner']
                settodayDecisionMaker(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority))).reduce((totalDecisionMaker, data) => {
                    return totalDecisionMaker += data.followerCounts.organicFollowerCount
                }, 0))

                setpreviousDateFollower(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0])
                setpreviousDecisionMaker(apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority)))?.reduce((totalDecisionMaker, data) => {
                    return totalDecisionMaker += data.followerCounts.organicFollowerCount
                }, 0))
                const filteredPageViewElements = apiResOforganizationPageStatisticsFilter.data?.elements?.filter(pageViews => {
                    // Check if the timeRange.start is in the filterDateArray
                    return pageViews
                })
                setpageViewData(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.pageViews))
                setpageViewDataForMobile(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.pageViews))
                setuniquePageViewData(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.uniquePageViews))
                setuniquePageViewDataMobile(filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.uniquePageViews))




                apiResOfPagePost.data.elements?.forEach(data => {

                    const filterPost = apiPostAllDetails.data.elements?.filter(item => (item.ugcPost !== undefined ? item.ugcPost : item.share) === data.id)
                    if (filterPost?.length) {

                        data.uniqueImpressionsCount = filterPost[0].totalShareStatistics.uniqueImpressionsCount
                        data.shareCount = filterPost[0].totalShareStatistics.shareCount
                        data.engagement = filterPost[0].totalShareStatistics.engagement
                        data.likeCount = filterPost[0].totalShareStatistics.likeCount
                        data.impressionCount = filterPost[0].totalShareStatistics.impressionCount
                        data.commentCount = filterPost[0].totalShareStatistics.commentCount
                        data.clickCount = filterPost[0].totalShareStatistics.clickCount
                        data.authorName = postAuthorNames?.data?.results[`${data.id}`]?.created['actor~']?.firstName?.localized.en_US + ' ' + postAuthorNames?.data?.results[`${data.id}`]?.created['actor~']?.lastName?.localized?.en_US
                    }
                    if (data?.content?.media?.id?.includes('video')) {
                        data.content.media.mediaUrl = videoMediaDetails?.data?.results[`${data?.content?.media?.id}`]?.thumbnail
                    }

                    if (data.content?.media?.id?.includes('document')) {

                        data.content.media.mediaUrl = apiPostDocuments?.data?.results[`${data?.content?.media?.id}`]?.downloadUrl
                    }
                    if (data?.content?.media?.id?.includes('image')) {

                        data.content.media.mediaUrl = apiPostImages?.data?.results[`${data?.content?.media?.id}`]?.downloadUrl
                    }
                })

                setcaption(apiResOfPagePost.data.elements?.map(data => data.commentary)[0])
                if (mediaID[0]?.includes('video')) {
                    setmediaType('video')
                    setmediaUrl(videoMediaDetails?.data?.results[`${apiResOfPagePost.data.elements[0].content?.media?.id}`]?.thumbnail)
                }
                if (mediaID[0]?.includes('document')) {
                    setmediaType('document')
                    setmediaUrl(apiResOfPagePost.data.elements?.map(data => data?.content?.media?.mediaUrl)[0])
                }
                if (mediaID[0]?.includes('image')) {
                    setmediaType('image')
                    setmediaUrl(apiResOfPagePost.data.elements?.map(data => data?.content?.media?.mediaUrl)[0])
                }

                setsocialMetadata(apiResOfPagePost.data.elements[0])
                const filterPost = apiResOfPagePost.data.elements.filter(data =>
                    data.createdAt >= startDateMilliseconds && data.createdAt <= endDateMilliSeconds)
                const postResponseObj = {
                    paging: apiResOfPagePost.data.paging,
                    elements: filterPost
                }
                setpostDetails(postResponseObj)
                setloading(false)
                setfilterLoading(false)





                const obj = {
                    id: urn,
                    selectedPeriod: selectedPeriod.toString(),
                    dateArray: dateArray,
                    followerCountry: thirdArray,
                    followerCountryData: firstFiveCountries?.map(data => parseInt(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)),
                    followerOrganicData: filteredElements?.map(followerData => followerData.followerGains.organicFollowerGain),
                    followerPaidData: filteredElements?.map(followerData => followerData.followerGains.paidFollowerGain),
                    organizationalEntityFollowerStatisticsFilter: organizationalEntityFollowerStatisticsFilter,
                    todayDatePageView: todayDatePageView,
                    previousDatePageView: previousDatePageView,
                    todayDateUniqueView: todayDateUniqueView,
                    previousDateUniqueView: previousDateUniqueView,
                    todayDateButtonClick: todayDateButtonClick,
                    previousDateButtonClick: previousDateButtonClick,
                    apiResOforganizationPageStatisticsFilter: apiResOforganizationPageStatisticsFilter,
                    impressionData: filteredElementsOfShareStaticstics?.map(data => data.totalShareStatistics.impressionCount),
                    engagementData: filteredElementsOfShareStaticstics?.map(data => parseFloat(data.totalShareStatistics.engagement * 100).toFixed(1)),
                    previousDateImpression: filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.impressionCount),
                    previousDateEngagemntRate: filteredElementsOfShareStaticsticsPreviousDate?.map(data => data.totalShareStatistics.engagement),
                    apiResOfOrganizationalEntityFollowerStatstics: resolvedWebhookData,
                    organizationAllFollower: apiResOfOrganizationalEntityFollowerStatstics,
                    apiResOforganizationalEntityShareStatisticsFilter: apiResOforganizationalEntityShareStatisticsFilter,
                    apiResOforganizationPageStatisticsFilterPreviousDate: apiResOforganizationPageStatisticsFilterPreviousDate,

                    apiResOforganizationalEntityFollowerStatisticsFilter: apiResOforganizationalEntityFollowerStatisticsFilter,
                    todaysCount: apiResOforganizationalEntityFollowerStatisticsFilter.data.elements?.reduce((totalFollower, data) => {
                        totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                        return totalFollower
                    }, 0),
                    previousCount: apiResOforganizationalEntityFollowerStatisticsPreviousdate.data.elements?.reduce((totalFollower, data) => {
                        totalFollower += (data.followerGains.organicFollowerGain + data.followerGains.paidFollowerGain)
                        return totalFollower
                    }, 0),
                    todayDateFollower: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0],
                    todayDecisionMaker: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority))).reduce((totalDecisionMaker, data) => {
                        return totalDecisionMaker += data.followerCounts.organicFollowerCount
                    }, 0),
                    previousDateFollower: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsByGeoCountry?.slice(0, 1)?.map(data => data.followerCounts)[0],
                    previousDecisionMaker: apiResOfOrganizationalEntityFollowerStatstics.data.elements[0].followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority)))?.reduce((totalDecisionMaker, data) => {
                        return totalDecisionMaker += data.followerCounts.organicFollowerCount
                    }, 0),
                    pageViewData: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.pageViews),
                    pageViewDataForMobile: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.pageViews),
                    uniquePageViewData: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allDesktopPageViews.uniquePageViews),
                    uniquePageViewDataMobile: filteredPageViewElements?.map(pageViewData => pageViewData.totalPageStatistics.views.allMobilePageViews.uniquePageViews),
                    caption: apiResOfPagePost.data.elements?.map(data => data.commentary)[0],
                    socialMetadata: apiResOfPagePost.data.elements[0],
                    postDetails: postResponseObj,
                    videoMediaDetails: videoMediaDetails
                }
                userAccountContext.handleaccountsData(obj)
            }

        } catch (error) {

            setfilterLoading(false)
            setloading(false)
        }
    }

    const getAccounts = async () => {
        try {
            setaccountsLoading(true)
            const workspaceId = localStorage.getItem('organizationId')
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };

            const res = await axios.get(url + `accounts/${workspaceId}`, config)
            res.data.forEach(element => {
                element.route = element.accountType === "instagram" ? '/instagram' : element.accountType === "linkedinpage" ? "/" : element.accountType === "pinterest" ? '/pinterest' : element.accountType === 'facebook' ? '/facebook' : ''
                element.app = element.accountType
            })


            setaccounts(res.data)
            setaccountsLoading(false)
            return res.data
        } catch (error) {
            setaccountsLoading(false)
        }
    }

    const changePaginationLoading = (value) => {
        setpaginationLoading(value)
    }

    const changeCount = (value) => {
        setCount(value)
    }

    const changepageViewFilter = (value) => {
        setpageViewFilter(value)
    }

    const changeuniqueViewFilter = (value) => {
        setuniqueViewFilter(value)
    }

    const changepageViewData = (value) => {
        setpageViewData(value)
    }

    const changepageViewDataForMobile = (value) => {
        setpageViewDataForMobile(value)
    }

    const changeuniquePageViewData = (value) => {
        setuniquePageViewData(value)
    }

    const changeuniquePageViewDataMobile = (value) => {
        setuniquePageViewDataMobile(value)
    }

    const changepageStatisticsFilterPreviousDate = (value) => {
        setpageStatisticsFilterPreviousDate(value)
    }

    const changetodayDateFollower = (value) => {
        settodayDateFollower(value)
    }

    const changepreviousDateFollowerPercentage = (value) => {
        setpreviousDateFollowerPercentage(value)
    }

    const changepreviousDateFollower = (value) => {
        setpreviousDateFollower(value)
    }

    const changetodayDecisionMaker = (value) => {
        settodayDecisionMaker(value)
    }

    const changepreviousDecisionMaker = (value) => {
        setpreviousDecisionMaker(value)
    }

    const changeshowAllJobFunction = (value) => {
        setshowAllJobFunction(value)
    }

    const changeshowAllIndustry = (value) => {
        setshowAllIndustry(value)
    }

    const changeorganizationalEntityFollowerStatisticsFilter = (value) => {
        setorganizationalEntityFollowerStatisticsFilter(value)
    }

    const changefollowerCountryData = (value) => {
        setfollowerCountryData(value)
    }

    const changefollowerCountry = (value) => {
        setfollowerCountry(value)
    }

    const changepreviousDatePageView = (value) => {
        setpreviousDatePageView(value)
    }

    const changepreviousDateUniqueView = (value) => {
        setpreviousDateUniqueView(value)
    }

    const changepreviousDateButtonClicks = (value) => {
        setpreviousDateButtonClicks(value)
    }

    const changeshowAllLocationFollowerModel = (value) => {
        setshowAllLocationFollowerModel(value)
    }

    const changecaption = (value) => {
        setcaption(value)
    }

    const changemediaType = (value) => {
        setmediaType(value)
    }
    const changemediaUrl = (value) => {
        setmediaUrl(value)
    }
    const changefollowerPaidData = (value) => {
        setfollowerPaidData(value)
    }

    const changefollowerOrganicData = (value) => {
        setfollowerOrganicData(value)
    }

    const changesocialMetadata = (value) => {
        setsocialMetadata(value)
    }

    const changeorganizationPageStatisticsFilter = (value) => {
        setorganizationPageStatisticsFilter(value)
    }

    const changeOrganizationalEntityFollowerStatstics = (value) => {
        setOrganizationalEntityFollowerStatstics(value)
    }

    const changeSelectedPeriod = (value) => {
        setSelectedPeriod(value)
    }

    const changefollowerDateArray = (value) => {
        setfollowerDateArray(value)
    }

    const changeimpressionData = (value) => {
        setimpressionData(value)
    }

    const changeengagementData = (value) => {
        setengagementData(value)
    }

    const changeorganizationalEntityShareStatisticsFilter = (value) => {
        setorganizationalEntityShareStatisticsFilter(value)
    }

    const changeimpressionPercentageData = (value) => {
        setimpressionPercentage(value)
    }

    const changeengagementPercentageData = (value) => {
        setengagementPercentage(value)
    }

    const changePostDetails = (value) => {
        setpostDetails(value)
    }

    const changeLoading = (value) => {
        setloading(value)
    }





    const context = {
        getLinkedinAnalyticsData: getLinkedinAnalyticsData,

        accounts: accounts,
        accountsLoading: accountsLoading,
        getAccounts: getAccounts,
        pageViewFilter: pageViewFilter,
        setpageViewFilter: changepageViewFilter,
        uniqueViewFilter: uniqueViewFilter,
        setuniqueViewFilter: changeuniqueViewFilter,
        pageViewData: pageViewData,
        setpageViewData: changepageViewData,
        pageViewDataForMobile: pageViewDataForMobile,
        setpageViewDataForMobile: changepageViewDataForMobile,
        uniquePageViewData: uniquePageViewData,
        setuniquePageViewData: changeuniquePageViewData,
        uniquePageViewDataMobile: uniquePageViewDataMobile,
        setuniquePageViewDataMobile: changeuniquePageViewDataMobile,
        pageStatisticsFilterPreviousDate: pageStatisticsFilterPreviousDate,
        setpageStatisticsFilterPreviousDate: changepageStatisticsFilterPreviousDate,
        todayDateFollower: todayDateFollower,
        settodayDateFollower: changetodayDateFollower,
        previousDateFollowerPercentage: previousDateFollowerPercentage,
        setpreviousDateFollowerPercentage: changepreviousDateFollowerPercentage,
        previousDateFollower: previousDateFollower,
        setpreviousDateFollower: changepreviousDateFollower,
        todayDecisionMaker: todayDecisionMaker,
        settodayDecisionMaker: changetodayDecisionMaker,
        previousDecisionMaker: previousDecisionMaker,
        setpreviousDecisionMaker: changepreviousDecisionMaker,
        showAllJobFunction: showAllJobFunction,
        setshowAllJobFunction: changeshowAllJobFunction,
        showAllIndustry: showAllIndustry,
        setshowAllIndustry: changeshowAllIndustry,
        organizationalEntityFollowerStatisticsFilter: organizationalEntityFollowerStatisticsFilter,
        setorganizationalEntityFollowerStatisticsFilter: changeorganizationalEntityFollowerStatisticsFilter,
        followerCountryData: followerCountryData,
        setfollowerCountryData: changefollowerCountryData,
        followerCountry: followerCountry,
        setfollowerCountry: changefollowerCountry,
        previousDatePageView: previousDatePageView,
        setpreviousDatePageView: changepreviousDatePageView,
        previousDateUniqueView: previousDateUniqueView,
        setpreviousDateUniqueView: changepreviousDateUniqueView,
        previousDateButtonClicks: previousDateButtonClicks,
        setpreviousDateButtonClicks: changepreviousDateButtonClicks,
        showAllLocationFollowerModel: showAllLocationFollowerModel,
        setshowAllLocationFollowerModel: changeshowAllLocationFollowerModel,
        mediaUrl: mediaUrl,
        setmediaUrl: changemediaUrl,
        mediaType: mediaType,
        setmediaType: changemediaType,
        caption: caption,
        setcaption: changecaption,
        OrganizationalEntityFollowerStatstics: OrganizationalEntityFollowerStatstics,
        setOrganizationalEntityFollowerStatstics: changeOrganizationalEntityFollowerStatstics,
        organizationPageStatisticsFilter: organizationPageStatisticsFilter,
        setorganizationPageStatisticsFilter: changeorganizationPageStatisticsFilter,
        followerOrganicData: followerOrganicData,
        setfollowerOrganicData: changefollowerOrganicData,
        followerPaidData: followerPaidData,
        setfollowerPaidData: changefollowerPaidData,
        socialMetadata: socialMetadata,
        setsocialMetadata: changesocialMetadata,
        selectedPeriod: selectedPeriod,
        setSelectedPeriod: changeSelectedPeriod,
        followerDateArray: followerDateArray,
        setfollowerDateArray: changefollowerDateArray,
        impressionData: impressionData,
        setimpressionData: changeimpressionData,
        engagementData: engagementData,
        setengagementData: changeengagementData,
        organizationalEntityShareStatisticsFilter: organizationalEntityShareStatisticsFilter,
        setorganizationalEntityShareStatisticsFilter: changeorganizationalEntityShareStatisticsFilter,
        impressionPercentage: impressionPercentage,
        setimpressionPercentage: changeimpressionPercentageData,
        engagementPercentage: engagementPercentage,
        setengagementPercentage: changeengagementPercentageData,
        postDetails: postDetails,
        setpostDetails: changePostDetails,
        count: count,
        setCount: changeCount,
        loading: loading,
        setloading: changeLoading,
        paginationLoading: paginationLoading,
        setpaginationLoading: changePaginationLoading,
        getLinkedinAnalyticsDataAsperSelectedPeriod: getLinkedinAnalyticsDataAsperSelectedPeriod,
        filterLoading: filterLoading,
        setfilterLoading: changeFilterPeriod,
        changeLoadingState: changeLoadingState,
        loadMore: loadMore,
        setLoadMore: changeLoadMore,
        organizationAllFollower: organizationAllFollower,
        visitorInsightsData: visitorInsightsData,
        visitorCityData: visitorCityData,
        visitorCity: visitorCity,
        previousDateImpression: previousDateImpression,
        overviewPageLoading: overviewPageLoading,
        followerPageLoading: followerPageLoading,
        visitorPageLoading: visitorPageLoading,
        contentPageLoading: contentPageLoading,
        overviewPageFilterLoading: overviewPageFilterLoading,
        followerPageFilterLoading: followePageFilterLoading,
        visitorPageFilterLoading: visitorPageFilterLoading,
        contentPageFilterLoading: contentPageFilterLoading,
        contentStart: contentStart,
        stopPagination: stopPagination,
        onChangecontentStart: onChangecontentStart,
        onChangestopPagination: onChangestopPagination,
        getLinkedinOverviewPageAnalyticaData: getLinkedinOverviewPageAnalyticaData,
        getLinkedinContentPageAnalyticaData: getLinkedinContentPageAnalyticaData,
        getLinkedinFollowerPageAnalyticaData: getLinkedinFollowerPageAnalyticaData,
        getLinkedinVisitorPageAnalyticaData: getLinkedinVisitorPageAnalyticaData,
        onChangeContentFilterLoading: onChangeContentFilterLoading,
        onChangeFollowerFilterLoading: onChangeFollowerFilterLoading,
        onChangeVisitorFilterLoading: onChangeVisitorFilterLoading,
        onChangeOverviewFilterLoading: onChangeOverviewFilterLoading,
        reportWeekArray: reportWeekArray,
        selectedReportDate: selectedReportDate,
        onChangesetselectedReportDate: onChangeselectedReportDate,
        stackedDataContent: stackedDataContent,
        clearState: clearState
    }

    return (
        <CreateLinkedinAnalyticContext.Provider value={context} >{children}</CreateLinkedinAnalyticContext.Provider>
    )

}

