import axios from "axios";
/**
 * Methods
 * 1: GET;
 * 2: POST;
 * 3: PATCH;
 * 4: DELETE;
 * 5: PUT
 */
const APICall = (url, data = {}, method = 1, authorization = true,token) => {
    return new Promise(async (resolve, reject) => {
      try {
        
        const finalURL = `${url}`;
        const headers = authorization ? {headers:{ "Authorization":`Bearer ${token}`}} : {};
        let response;
        if (method === 1) {
          response = await axios.get(url,headers);
        } else if (method === 2) {
          response = await axios.post(finalURL, data, headers);
        } else if (method === 3) {
          response = await axios.patch(finalURL, data, headers);
        } else if (method === 4) {
          response = await axios.delete(finalURL, headers);
        } else if (method === 5) {
          response = await axios.put(finalURL, data, headers);
        }
        return resolve(response);
      } catch (error) {
        if (
          error.toString().includes("status code 401") &&
          localStorage.accessToken
        ) {
        
        }
        reject(error);
      }
    });
  };
  export default APICall;
  
  