import Styles from './CompetitorsSkeleton.module.css'
import GraphSkeleton from '../GraphSkeleton/GraphSkeleton'
export const CompetitorsSkeleton = () => {
    return (
        <div className={Styles.instagram_competitors_main_container} >

            <div className={Styles.container} >

                <div className={Styles.post_main_container_of_pages} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>

                    </div>

                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} > </span>
                                <span className={Styles.skeleton} > </span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} > </span>
                                <span className={Styles.skeleton} > </span>


                            </div>

                        </div>
                        {[1, 2, 3].map(data => {

                            return (
                                <div className={Styles.content_container_of_page} >

                                    <div className={Styles.post_content_container} >
                                        <div className={Styles.post_content_img} >
                                            <img alt="" className={Styles.skeleton} />
                                        </div>

                                        <div className={Styles.post_content} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>
                                    </div>
                                    <div className={Styles.postHeaderContainer} >
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton}  ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>


                                    </div>
                                </div>
                            )
                        })
                        }

                    </div>


                </div>

                <div className={Styles.post_main_container_of_content_metrics} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>

                    </div>
                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} > </span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>

                            </div>
                        </div>
                        {[1, 2, 3].map(data => {

                            return (
                                <div className={Styles.content_container_of_page} >
                                    <div className={Styles.post_content_container} >
                                        <div className={Styles.post_content_img} >
                                            <img alt="" className={Styles.skeleton} />
                                        </div>

                                        <div className={Styles.post_content} >
                                            <span className={Styles.skeleton}  ></span>

                                        </div>
                                    </div>
                                    <div className={Styles.postHeaderContainer} >
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                    </div>
                                </div>
                            )
                        })

                        }

                    </div>
                </div>
                <div className={Styles.post_graph_container} >
                    {/*graph skeleton */}
                    <GraphSkeleton Style={{ width: '98%' }} />
                </div>
                <div className={Styles.graph_skeleton_container} >
                    <GraphSkeleton />
                    <div className={Styles.post_main_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.button_container} >


                            </div>
                        </div>
                        <div className={Styles.post_container} >
                            <div className={Styles.header_container} >
                                <span className={Styles.skeleton} ></span>
                                <div className={Styles.postHeaderContainer} >
                                    <span className={Styles.skeleton} ></span>
                                </div>
                            </div>

                            {[1, 2, 3].map(() => {

                                return (
                                    <div className={Styles.post_item_container} >
                                        <div className={Styles.post_content_container} >
                                            <div className={Styles.post_content_img} >
                                                <img alt="" className={Styles.skeleton} />
                                            </div>
                                            <div className={Styles.post_content} >
                                                <span className={Styles.skeleton} ></span>
                                                <p className={Styles.skeleton} ></p>
                                            </div>
                                        </div>
                                        <div className={Styles.postOtherDetails} >
                                            <div className={Styles.skeleton} ></div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>

                <div className={Styles.post_graph_container} >
                    {/*graph skeleton */}
                    <GraphSkeleton Style={{ width: '98%' }} />
                </div>

                <div className={Styles.like_comment_container} >
                    {/*graph skeleton */}
                    <GraphSkeleton />

                    {/*graph skeleton */}
                    <GraphSkeleton />
                </div>



                <div className={Styles.post_main_container_of_best_performance} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>

                    </div>
                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                            </div>
                        </div>

                        {[1, 2, 3].map(data => {
                            return (
                                <div className={Styles.content_container_of_page} >
                                    <div className={Styles.post_content_container} >
                                        <div className={Styles.post_content_img} >
                                            <img alt="" className={Styles.skeleton} />
                                        </div>

                                        <div className={Styles.post_content} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>
                                    </div>
                                    <div className={Styles.postHeaderContainer} >
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                        <span className={Styles.skeleton} ></span>
                                    </div>
                                </div>
                            )
                        })

                        }



                    </div>
                </div>

                <div style={{ marginBottom: 110 }} className={Styles.post_main_container_of_best_performance} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>

                    </div>
                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton}></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton}></span>
                                <span className={Styles.skeleton}></span>

                            </div>
                        </div>

                        {
                            [1, 2, 3].map(data => {
                                return (
                                    <div className={Styles.content_container_of_page} >
                                        <div className={Styles.post_content_container} >
                                            <div className={Styles.post_content_img} >
                                                <img alt="" className={Styles.skeleton} />
                                            </div>

                                            <div className={Styles.post_content} >
                                                <span className={Styles.skeleton} ></span>
                                                <p className={Styles.skeleton} ></p>
                                            </div>
                                        </div>
                                        <div className={Styles.postHeaderContainer} >
                                            <span className={Styles.skeleton} ></span>
                                            <span className={Styles.skeleton} ></span>
                                            <span className={Styles.skeleton} ></span>
                                            <span className={Styles.skeleton}></span>
                                            <span className={Styles.skeleton} ></span>
                                            <span className={Styles.skeleton} ></span>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>




            </div>
        </div>
    )
}