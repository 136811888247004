import { formatNumber } from '../../Util/helper';
import Styles from './LinkedinPostDetails.module.css'
const LinkedinPostDetails = (props) => {
    const commentary = props.data.commentary.replace(/\{hashtag\|\\#\|([\w\s]+)\}/g, '#$1');

    function hashtag(text) {
        var repl = text.replace(/#(\w+)/g, '<a href="#">#$1</a>');
        return repl;
    }

    return (
        <div className={Styles.linked_post_details_model_container} >

            <div className={Styles.container} >
                <div className={Styles.header_container} >
                    <span>Post</span>
                    {/* Corss SVG Icon */}
                    <svg style={{ cursor: 'pointer' }} onClick={() => props.onClickClose()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg>
                </div>
                <div className={Styles.chart_container} >
                    <div className={Styles.post_content_container} >
                        <div className={Styles.image_container} >
                            {props?.data?.content?.media?.id?.includes('document') ? <object data={props.data.content.media.mediaUrl} type="application/pdf" width="100%" height="100%">
                                <p>Alternative text - include a link <a href={props.data.content.media.mediaUrl}>to the PDF!</a></p>
                            </object> : <></>
                            }
                            {props?.data?.content?.media?.id?.includes('image') ?
                                <img src={props.data.content.media.mediaUrl} alt="" /> : <></>
                            }
                            {props?.data?.content?.media?.id?.includes('video') ?
                                <img src={props.data.content.media.mediaUrl} alt="" /> : <></>
                            }
                        </div>
                        <div className={Styles.content_container} >
                            <div className={Styles.header_value_container} >
                                <span>Likes</span>
                                <span>{formatNumber(props.data.likeCount || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Comments</span>
                                <span>{formatNumber(props.data.commentCount || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Engagement Rate</span>
                                <span>{isFinite(parseFloat(props.data.engagement).toFixed(2)) ? parseFloat(props.data.engagement).toFixed(2) : 0}%</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Impressions</span>
                                <span>{formatNumber( props.data.impressionCount || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Shares</span>
                                <span>{formatNumber( props.data.shareCount || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Author Name</span>
                                <span>{props.data.authorName !== undefined ?props.data.authorName : "-"}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Created Date</span>
                                <span>{new Date(props.data.createdAt).getFullYear() + "-" + (new Date(props.data.createdAt).getMonth() + 1) + "-" + new Date(props.data.createdAt).getDate()} {new Date(props.data.createdAt).toLocaleTimeString('en-US')}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            

                        </div>



                    </div>
                    <div  className={Styles.commentary_container} style={{ whiteSpace: 'break-spaces', pointerEvents: 'none' }} dangerouslySetInnerHTML={{ __html: hashtag(commentary.replace(/\\_/g, '_')) }} ></div>
                </div>
            </div>
        </div>
    )
}

export default LinkedinPostDetails