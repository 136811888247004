import React from 'react'
import Styles from './AdsLibrarySkeleton.module.css'

const AdsLibrarySkeletion = () => {
    const cards = [1, 2, 3, 4, 5, 6]
    return (
        <div className={Styles.skeleton_ads_library}>
            {cards.map((data, index) => {
                return (
                    <div className={`${Styles.ad_card} ${Styles.skeleton}`} key={index}>
                        <div className={Styles.add_info}>
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                        </div>

                        <div className={Styles.visit_ad_container}>
                            <div className={`${Styles.info} ${Styles.skeleton}`} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default AdsLibrarySkeletion