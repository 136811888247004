import Navbar from '../../Component/Navbar/navbar'
import OverviewCard from '../../Component/OverviewCard/overviewcard'
import AllFollowerLocationModel from '../../Component/AllFollowerLocationModel/AllFollowerLocationModel'
import Styles from './LinkedinOverview.module.css'

import { useEffect, useContext, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { createNavigationContext } from '../../store/navigation'
import LinkedinOverviewSkeleton from '../../Component/LinkedinOverviewSkeleton/LinkedinOverviewSkeleton'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import { CreateLinkedinAnalyticContext } from '../../store/linkedinAnalytic'
import { captionTruncate, formatNumber } from '../../Util/helper';
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup';
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount';
import BlogListCard from '../../Component/BlogListCard/BlogListCard';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount';
import { UserNavigationContext } from '../../store/NavigationProvider';
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const LinkedinOverview = () => {
    const context = useContext(createNavigationContext)
    const accounts = useContext(UserConnectedAccountContext)
    const linkedinContext = useContext(CreateLinkedinAnalyticContext)
    const userNavigation = useContext(UserNavigationContext)

    const [hoverFollowers, sethoverFollowers] = useState(false)
    const [hoverImpressions, sethoverImpressions] = useState(false)
    const [hoverEngagementRate, sethoverEngagementRate] = useState(false)
    const data = {
        labels: linkedinContext.followerDateArray.slice(1, linkedinContext.followerDateArray.length),
        datasets: [{
            label: 'Follower oraganic count',
            data: linkedinContext.followerOrganicData,
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverFollowers ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverFollowers ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        },
        {
            label: 'Follower sponred',
            data: linkedinContext.followerPaidData,
            lineTension: 0.5,
            borderColor: '#ff8a25',
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(255, 117, 37,0.25)"
            },
            borderDash: [4, 3],
            pointBorderColor: hoverFollowers ? 'rgba(255, 117, 37, 1)' : 'rgba(255, 117, 37, 0)',
            pointBackgroundColor: hoverFollowers ? 'rgba(255, 117, 37, 0.5)' : 'rgba(255, 117, 37, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };




    const impressionChartData = {
        labels: linkedinContext.followerDateArray,
        datasets: [{
            label: 'Impression',
            data: linkedinContext.impressionData,
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverImpressions ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverImpressions ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    const engagementChartData = {
        labels: linkedinContext.followerDateArray,
        datasets: [{
            label: 'Engagement',
            data: linkedinContext.engagementData,
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverEngagementRate ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverEngagementRate ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }






    const calculateTotalFollowers = () => {

        const totaleasymcqquestion = linkedinContext.OrganizationalEntityFollowerStatstics?.map(followers => followers.followerCounts).reduce((easymcqquestion, question) => {

            easymcqquestion += parseInt(question.organicFollowerCount + question.paidFollowerCount)

            return easymcqquestion
        }, 0)


        return totaleasymcqquestion
    }





    const calculatePageView = () => {
        const totaleasymcqquestion = linkedinContext.organizationPageStatisticsFilter?.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((easymcqquestion, question) => {
            easymcqquestion += parseInt(question.allPageViews?.pageViews)

            return easymcqquestion
        }, 0)
        return totaleasymcqquestion

    }

    const calculateUniqueVisitor = () => {
        const totaleasymcqquestion = linkedinContext.organizationPageStatisticsFilter?.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.views).reduce((easymcqquestion, question) => {
            easymcqquestion += parseInt(question.allPageViews?.uniquePageViews)

            return easymcqquestion
        }, 0)
        return totaleasymcqquestion


    }

    const calculateButtonClick = () => {
        const totaleasymcqquestion = linkedinContext.organizationPageStatisticsFilter?.elements?.map(totalPageStatistics => totalPageStatistics.totalPageStatistics.clicks).reduce((clickCount, clicksData) => {

            clickCount += parseInt(clicksData?.mobileCustomButtonClickCounts[0]?.clicks + clicksData?.desktopCustomButtonClickCounts[0]?.clicks)
            return clickCount
        }, 0)

        return totaleasymcqquestion
    }

    useEffect(() => {
        if (linkedinContext.overviewPageFilterLoading) {
            linkedinContext.getLinkedinOverviewPageAnalyticaData()
        }
        // eslint-disable-next-line 
    }, [linkedinContext.selectedPeriod])



    const navigation = (value) => {
        context.setActive(value)
    }

    const calculateNewFollower = () => {
        const totaleasymcqquestion = linkedinContext.organizationalEntityFollowerStatisticsFilter?.elements?.map(followers => followers.followerGains).reduce((easymcqquestion, question) => {

            easymcqquestion += question.organicFollowerGain

            return easymcqquestion
        }, 0)

        return totaleasymcqquestion
    }

    const barChartData = {
        labels: linkedinContext.followerCountry,
        datasets: [{
            label: 'Follower location',
            data: linkedinContext.followerCountryData,
            fill: false,
            backgroundColor: '#0078FF',
            tension: 0.1
        },

        ]
    };




    return (
        <div className={Styles.linkedin_overview_container}>
            {(accounts.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {linkedinContext.showAllLocationFollowerModel ? <AllFollowerLocationModel title="Followers Location" totalFollower={calculateTotalFollowers()} data={linkedinContext?.OrganizationalEntityFollowerStatstics} onClickClose={() => linkedinContext.setshowAllLocationFollowerModel(false)} /> : <></>}
            {linkedinContext.overviewPageLoading ? <LinkedinOverviewSkeleton selected={linkedinContext.selectedPeriod} /> :


                <div className={Styles.overview_container} >

                    <div style={(accounts.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.overview_content_main_container} >
                        <ActiveSocialAccount page="linkedinpage" disableFilter={true} loading={linkedinContext.overviewPageLoading} selected={linkedinContext.selectedPeriod} onChangePeriod={(value) => { linkedinContext.setSelectedPeriod(value); accounts.getSocialAccountPostCount(accounts.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); linkedinContext.onChangeOverviewFilterLoading(true) }} />

                        <Navbar active="overview" />
                        <div className={Styles.overview_content_container} >
                            {calculateTotalFollowers() < 100 ? <BlogListCard type="linkedin" /> : <></>}
                            <div className={Styles.card_container} >
                                <OverviewCard toolTip="Total Followers on LinkedIn refers to the cumulative number of individuals who have chosen to follow your LinkedIn profile. This metric provides insight into the reach and influence of your professional presence on the platform." data={true} count={formatNumber(calculateTotalFollowers() || 0)} percentage={isFinite(((((calculateTotalFollowers() - calculateNewFollower()) - calculateTotalFollowers()) / calculateTotalFollowers()) * 100).toFixed(1)) ? ((((calculateTotalFollowers() - calculateNewFollower()) - calculateTotalFollowers()) / calculateTotalFollowers()) * 100).toFixed(1) : 0} header="Total Followers" />
                                <OverviewCard toolTip="Page Views on LinkedIn: This metric represents the total number of times your LinkedIn profile or content has been viewed by other users." people={true} count={formatNumber(calculatePageView() || 0)} percentage={isFinite(linkedinContext.previousDatePageView) ? linkedinContext.previousDatePageView : 0} header="Page Views" />
                                <OverviewCard toolTip="Unique Visitors: This metric on LinkedIn represents the number of distinct individuals who have visited your profile within a specific time frame." profile={true} count={formatNumber(calculateUniqueVisitor() || 0)} percentage={isFinite(linkedinContext.previousDateUniqueView) ? linkedinContext.previousDateUniqueView : 0} header="Unique Visitors" />
                                <OverviewCard toolTip="Tooltip: Increase engagement with your LinkedIn profile by encouraging visitors to take action with a 'Button Clicks' feature." website={true} count={formatNumber(calculateButtonClick() || 0)} percentage={isFinite(linkedinContext.previousDateButtonClicks) ? linkedinContext.previousDateButtonClicks : 0} header="Button Click" />
                            </div>

                            <div className={Styles.latest_pin_and_followers_container} >
                                <div>

                                    <div className={Styles.header_container} >
                                        <label> Latest Post</label>
                                        <span onClick={() => navigation('content')} style={{ cursor: 'pointer' }} >Show All <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                            <path d="M6.125 11.25L9.875 7.5L6.125 3.75" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></span>
                                    </div>

                                    {linkedinContext.socialMetadata ?
                                        <>

                                            <div className={Styles.latest_post_container} >
                                                <div className={Styles.img_container} >
                                                    {linkedinContext?.mediaType === 'document' ? <object data={linkedinContext.mediaUrl} type="application/pdf" width="100%" height="100%">
                                                        <p>Alternative text - include a link <a href={linkedinContext.mediaUrl}>to the PDF!</a></p>
                                                    </object> : <></>
                                                    }
                                                    {linkedinContext?.mediaType === 'image' ?
                                                        <img src={linkedinContext.mediaUrl} alt="" /> : <></>
                                                    }
                                                    {linkedinContext?.mediaType === 'video' ?
                                                        <img src={linkedinContext.mediaUrl} alt="" /> : <></>
                                                    }
                                                </div>
                                                <div className={Styles.content_container} >
                                                    <div className={Styles.header_value_container} >
                                                        <p>Likes</p>
                                                        <span>{(linkedinContext.socialMetadata?.likeCount !== undefined ? formatNumber(linkedinContext.socialMetadata?.likeCount || 0) : 0)}</span>
                                                    </div>

                                                    <div className={Styles.header_value_container} >
                                                        <p>Comments</p>
                                                        <span>{formatNumber(linkedinContext.socialMetadata?.commentCount || 0)}</span>
                                                    </div>


                                                    <div className={Styles.header_value_container} >
                                                        <p>Engagement Rate</p>
                                                        <span>{(parseFloat(linkedinContext.socialMetadata.engagement || 0).toFixed(2))}% </span>
                                                    </div>




                                                    <div className={Styles.header_value_container} >
                                                        <p>Impressions</p>
                                                        <span>{formatNumber(linkedinContext.socialMetadata.impressionCount || 0)}</span>
                                                    </div>



                                                    <div className={Styles.header_value_container} >
                                                        <p>Shares</p>
                                                        <span>{formatNumber(linkedinContext.socialMetadata.shareCount || 0)}</span>
                                                    </div>

                                                    <div className={Styles.header_value_container} >
                                                        <p>Author Name</p>
                                                        <span>{linkedinContext?.socialMetadata?.authorName !== undefined ? linkedinContext?.socialMetadata?.authorName : "-"}</span>
                                                    </div>


                                                    <div className={Styles.header_value_container} >
                                                        <p>Created at</p>
                                                        <span>{new Date(linkedinContext.socialMetadata.createdAt).getFullYear() + "-" + (new Date(linkedinContext.socialMetadata.createdAt).getMonth() + 1) + "-" + new Date(linkedinContext.socialMetadata.createdAt).getDate()}</span>
                                                    </div>


                                                    {/* <div className={Styles.header_value_container} >
                                            <span>Reach</span>
                                            <span>NA</span>
                                        </div>
                                        <label className={Styles.border_of_post} ></label>
                                        
                                        <div className={Styles.header_value_container} >
                                            <span>Saves</span>
                                            <span>NA</span>
                                        </div>
                                        <label className={Styles.border_of_post} ></label> */}

                                                </div>

                                            </div>

                                            <p className={Styles.caption_container} >{captionTruncate(linkedinContext.caption, 413)}</p>

                                        </> : <NoDataAvailablePopup cardName="latestpost" />

                                    }

                                </div>

                                <LineChartCustomComponent onHoverOut={() => sethoverFollowers(false)} onHoverIn={() => sethoverFollowers(true)} type="line" header="Followers" subheader="" contentArray={[{ "header": "Total Followers", value: formatNumber(calculateTotalFollowers() || 0) }, {
                                    "header": "Followers Change", value: formatNumber(linkedinContext.organizationalEntityFollowerStatisticsFilter?.elements?.reduce((newFollower, followerData) => {
                                        newFollower += parseInt(followerData.followerGains.organicFollowerGain + followerData.followerGains.paidFollowerGain)
                                        return newFollower
                                    }, 0) || 0)
                                }, {
                                    "header": "Growth Rate", value: `${isFinite((((linkedinContext.organizationalEntityFollowerStatisticsFilter?.elements?.reduce((newFollower, followerData) => {
                                        newFollower += parseInt(followerData.followerGains.organicFollowerGain + followerData.followerGains.paidFollowerGain)
                                        return newFollower
                                    }, 0)) / calculateTotalFollowers()) * 100).toFixed(1)) ? (((linkedinContext.organizationalEntityFollowerStatisticsFilter?.elements?.reduce((newFollower, followerData) => {
                                        newFollower += parseInt(followerData.followerGains.organicFollowerGain + followerData.followerGains.paidFollowerGain)
                                        return newFollower
                                    }, 0)) / calculateTotalFollowers()) * 100).toFixed(1) : 0}%`
                                }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverFollowers ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={data} />



                            </div>

                            <div className={Styles.followers_location_container} >

                                <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} onClickSubHeader={() => linkedinContext.setshowAllLocationFollowerModel(true)} type="bar" header="Followers Location" subheader="Show All" contentArray={[{ "header": "Major Country Followers", value: formatNumber(linkedinContext?.followerCountryData[0] || 0) }, { "header": "Total Major Countries", value: formatNumber(linkedinContext.OrganizationalEntityFollowerStatstics?.length || 0) }, { "header": "Top 5 Countries Contribution", value: formatNumber(linkedinContext.followerCountryData.reduce((top5Follower, data) => { return top5Follower += data }, 0) || 0) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    scales: {
                                        x: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={barChartData} />



                                <LineChartCustomComponent onHoverOut={() => sethoverImpressions(false)} onHoverIn={() => sethoverImpressions(true)} type="line" header="Impressions" subheader="" contentArray={[{ "header": "Total Impression", value: formatNumber(linkedinContext.impressionData?.reduce((totalImpression, data) => { return totalImpression += data }, 0) || 0) }, { "header": "Highest Impression", value: formatNumber(Math.max(...linkedinContext.impressionData).toFixed(0) || 0) }, { "header": "Lowest Impression", value: formatNumber(Math.min(...linkedinContext.impressionData).toFixed(0) || 0) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverImpressions ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={impressionChartData} />


                            </div>



                            <div className={Styles.impressions_and_engagement_rate_container} >
                                <LineChartCustomComponent onHoverOut={() => sethoverEngagementRate(false)} onHoverIn={() => sethoverEngagementRate(true)} type="line" header="Engagement Rate" subheader="" contentArray={[{ "header": "Average Engagement Rate", value: (isFinite(((parseFloat(linkedinContext.engagementData.reduce((totalEngagement, data) => { return totalEngagement += parseFloat(data) }, 0)) / linkedinContext.engagementData.length).toFixed(2))) ? ((parseFloat(linkedinContext.engagementData.reduce((totalEngagement, data) => { return totalEngagement += parseFloat(data) }, 0)) / linkedinContext.engagementData.length).toFixed(2)) : 0) + "%" }, { "header": "Highest Engagement", value: formatNumber(Math.max(...linkedinContext.engagementData).toFixed(0) || 0) }, { "header": "Lowest Engagement", value: formatNumber(Math.min(...linkedinContext.engagementData).toFixed(0) || 0) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverEngagementRate ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={engagementChartData} />

                                <div style={{ backgroundColor: '#f8f8f8' }} ></div>
                            </div>


                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

export default LinkedinOverview