import Styles from './AllAudienceNoData.module.css'
import Face from '../../assets/face.svg'
const AllAudienceNoData = () => {
    const getBackGroundColor = (online_follower_percentage) => {
        if (online_follower_percentage >= 0 && online_follower_percentage <= 15)
            return '#e6f2ff'

        if (online_follower_percentage >= 16 && online_follower_percentage <= 30)
            return '#99c9ff';

        if (online_follower_percentage >= 31 && online_follower_percentage <= 45)
            return '#4da1ff'

        if (online_follower_percentage >= 46 && online_follower_percentage <= 60)
            return '#0078ff'

        if (online_follower_percentage >= 61 && online_follower_percentage <= 75)
            return '#0060cc';

        if (online_follower_percentage >= 76 && online_follower_percentage <= 90)
            return '#004899'

        if (online_follower_percentage >= 91 && online_follower_percentage <= 100)
            return '#003066'

    }
    return (
        <div className={Styles.audience_all_activity_no_data_Container} >

            <img src={Face} alt="" />
            <h4 className={Styles.header} >
                Not enough data available

            </h4>
            <a href="https://www.cache.creatosaurus.io/" className={Styles.post_new_content_for_analysis_button}   >Post new content for analysis</a>
            {[1, 2, 3, 4, 5, 6, 7,].map((data,outerIndex) => {
                return (
                    <div key={outerIndex} className={Styles.audience_activity_card} >
                        <div className={Styles.day_name_container} >
                            <span style={{color:'black'}} >Sunday</span>

                            <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line y1="1" x2="10" y2="1" stroke="#808080" />
                            </svg>

                        </div>

                        <div className={Styles.activity_value_container} >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((values, index) => {
                                
                                return <div key={index} className={Styles.value_header_container} >
                                    {/* eslint eqeqeq: 0 */}
                                    <div style={{ backgroundColor: getBackGroundColor(40), border: (parseFloat(values/2).toFixed(0)) == index ?'3px solid #ff43ca' : 'none' }} className={Styles.value_container} >

                                    </div>
                                   {outerIndex === 6 ? <span style={{color:'#000'}} >12 - 2</span> :<></>} 

                                </div>
                            })

                            }

                        </div>
                    </div>
                )
            })

            }

        </div>
    )
}

export default AllAudienceNoData