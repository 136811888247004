import Styles from './AudienceAcivityNoData.module.css'
import Face from '../../assets/face.svg'
const AudienceAcivityNoData = () => {


    const getBackGroundColor = (online_follower_percentage) => {
        if (online_follower_percentage >= 0 && online_follower_percentage <= 15)
            return '#e6f2ff'

        if (online_follower_percentage >= 16 && online_follower_percentage <= 30)
            return '#99c9ff';

        if (online_follower_percentage >= 31 && online_follower_percentage <= 45)
            return '#4da1ff'

        if (online_follower_percentage >= 46 && online_follower_percentage <= 60)
            return '#0078ff'

        if (online_follower_percentage >= 61 && online_follower_percentage <= 75)
            return '#0060cc';

        if (online_follower_percentage >= 76 && online_follower_percentage <= 90)
            return '#004899'

        if (online_follower_percentage >= 91 && online_follower_percentage <= 100)
            return '#003066'

    }

    return (
        <div className={Styles.audience_activity_main_container} >
            <img src={Face} alt="" />
            <h4 className={Styles.header} >
                Not enough data available

            </h4>
            <a href="https://www.cache.creatosaurus.io/" className={Styles.post_new_content_for_analysis_button}   >Post new content for analysis</a>

            <div className={Styles.audience_activity_of_no_data} >

                <div className={Styles.audience_activity_card_container} >

                    <>


                        <div className={Styles.audience_activity_card} >
                            <div className={Styles.day_name_container} >
                                <div className={Styles.header_of_date} >8 March, Friday -
                                    Based on last week's activity, the best time to post today is <b> between 8-10.</b></div>


                            </div>

                            <div className={Styles.activity_value_container} >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((values, index) => {

                                    return <div key={index} className={Styles.value_header_container} >
                                        <div style={{ backgroundColor: getBackGroundColor(45), border: `${index === 4 ? '3px solid #ff43ca' : 'none'}` }} className={Styles.value_container} >
                                            <div className={Styles.hover_card_container} >
                                                <label>Total: <b>22</b> followers</label>

                                            </div>
                                        </div>
                                        <svg width="1" height="5" viewBox="0 0 1 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="0.25" y1="0.5" x2="0.25" y2="4.5" stroke="#808080" strokeWidth="0.5" />
                                        </svg>
                                        <span>12 - 2</span>

                                    </div>
                                })

                                }

                            </div>
                        </div>


                    </>


                </div>



            </div>
        </div>


    )
}

export default AudienceAcivityNoData