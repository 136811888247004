import React, { useContext, useState, useEffect } from 'react'
import Style from './PinterestContent.module.css'
import { CreatePinterestAnalyticContext } from '../../store/pinterestAnalytics';
import OverviewCard from '../../Component/OverviewCard/overviewcard';
import LinkedinPostSkeleton from '../../Component/LinkedinPostSkeleton/LinkedinPostSkeleton'
import PinterestPostDetails from '../../Component/PinterestPostDetails/PinterestPostDetails';
import PinterestBoardDetails from '../../Component/PinterestBoardDetails/PinterestBoardDetails';
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup';
import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount';
import PinterestNavbar from '../../Component/PinterestNavbar/PinterestNavbar';
import { PinterestContentSkeleton } from '../../Component/PinterestContent/PinterestContent';
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount';
import BlogListCard from '../../Component/BlogListCard/BlogListCard';
import ActiveSocialAccountSkeleton from '../../Component/ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton';
import { formatNumber } from '../../Util/helper';
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent';
import { generateDistinctColors } from '../../Util/constant';
import { UserNavigationContext } from '../../store/NavigationProvider';
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent';

const PinterestContent = () => {
    const context = useContext(CreatePinterestAnalyticContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [isPinViewActive, setIsPinViewActive] = useState(false)
    const [activePin, setActivePin] = useState(null)
    const [isBoardViewActive, setIsBoardViewActive] = useState(false)
    const [activeBoard, setActiveBoard] = useState(null)
    const [hoverEngagementRate, sethoverEngagementRate] = useState(false)
    const [hoverPinClicks, sethoverPinClicks] = useState(false)
    const [hoverCommentGraph, sethoverCommentGraph] = useState(false)
    const [selectedCategory, setselectedCategory] = useState('')
    const [page, setpage] = useState(1)
    const limit = 5

    const captionTruncate = (str) => {
        return str?.length > 40 ? str?.substring(0, 40) + "..." : str;
    }

    const calculateBoardStats = (pins) => {
        const stats = {
            engagement: 0,
            impression: 0,
            outboundClicks: 0,
            pinClicks: 0,
            save: 0,
        };

        for (const data of pins) {

            const allTimeMetrics = data?.pin_metrics?.all_time !== null ? data?.pin_metrics?.all_time : {} || data?.pin_metrics['90d'] !== null ? data?.pin_metrics['90d'] : {};
            stats.engagement += allTimeMetrics?.save + allTimeMetrics?.outbound_click + allTimeMetrics?.outbound_click + allTimeMetrics?.pin_click;
            stats.impression += allTimeMetrics?.impression;
            stats.outboundClicks += allTimeMetrics?.outbound_click;
            stats.pinClicks += allTimeMetrics?.pin_click;
            stats.save += allTimeMetrics?.save;
        }

        return stats;
    };

    function getTopCategoryCounts(data) {
        const categoryCounts = {};

        // Iterate over each array
        data.forEach(array => {
            // Iterate over each object in the array
            array.forEach(item => {
                // Get the socialId and contentCategory
                const socialId = item.socialId;
                const category = item.contentCategory;

                // If the socialId doesn't exist in categoryCounts, initialize it
                if (!categoryCounts[socialId]) {
                    categoryCounts[socialId] = {};
                }

                // Increment the count for the category under the socialId
                if (!categoryCounts[socialId][category]) {
                    categoryCounts[socialId][category] = 1;
                } else {
                    categoryCounts[socialId][category]++;
                }
            });
        });

        // Convert the object to an array of objects
        const result = Object.entries(categoryCounts).map(([socialId, categories]) => {
            // Find the category with the highest count
            const topCategory = Object.entries(categories).reduce((acc, [category, count]) => {
                return count > acc.count ? { category, count } : acc;
            }, { category: null, count: -1 });

            return { socialId, topCategory };
        });

        return result;
    }

    const closePinModel = () => {
        setIsPinViewActive(!isPinViewActive)
    }

    const closeBoardModel = () => {
        setIsBoardViewActive(!isBoardViewActive)
    }

    useEffect(() => {


        if (context.filterLoading) {
            context.getPinterestContentPageAnalyticaData()
            context.getPinterestOverviewPageAnalyticaData()
        }
        // eslint-disable-next-line 
    }, [context.selectedPeriod])

    // NOTE: engagement rate chart data
    const engagementRateChartData = {
        labels: context.engagementRate.date,
        datasets: [{
            label: 'Engagement',
            data: context.engagementRate.points,
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverEngagementRate ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverEngagementRate ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    // NOTE: pin click chart data
    const pinClickChartData = {
        labels: context.pinClick.date,
        datasets: [{
            label: 'Engagement',
            data: context.pinClick.points,
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverPinClicks ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverPinClicks ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    const socialIds = context.stackedDataContent?.reduce((ids, item) => {
        if (!ids.includes(item.socialId)) {
            ids.push(item.socialId);
        }
        return ids;
    }, []);


    const categories = context.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.reduce((uniqueCategories, item) => {
        if (!uniqueCategories.includes(item.contentCategory)) {
            uniqueCategories.push(item.contentCategory);
        }
        return uniqueCategories;
    }, []);

    const datasets = socialIds.map((socialId) => {
        const color = generateDistinctColors(1)[0]

        return {
            label: (userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name,
            data: categories.map(category => {

                return context.stackedDataContent?.filter(item => item.socialId === socialId && item.contentCategory === category)?.length;
            }),
            backgroundColor: color,
        };
    });

    const dataForCategory = {
        labels: context.stackedDataContent?.filter(data => socialIds.includes(data?.socialId))?.filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i)?.map(item => item.contentCategory),
        datasets: datasets
    }

    const dataDonghnut = {
        labels: context.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i)?.map(item => item.contentCategory),
        datasets: datasets,
    }

    const prevPage = () => {
        if (page > 1) {
            setpage(prev => prev - 1)
        }
    }

    const nextPage = () => {
        if (page < Math.ceil(Math.ceil(context.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? context?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
            const postDetails = context.allPins.filter(postDetails => data.postId.includes(postDetails.id))
            return postDetails.length
        })[0] / limit))) {
            setpage(prev => prev + 1)
        }
    }

    return (
        <div className={Style.pinterest_content_container}>
            {isPinViewActive ? <PinterestPostDetails close={closePinModel} pin={activePin} /> : null}
            {isBoardViewActive ? <PinterestBoardDetails close={closeBoardModel} board={activeBoard} calculateBoardStats={calculateBoardStats} /> : null}

            <div className={Style.overview_container}>
                {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
                <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Style.overview_content_main_container}>
                    {context.contentPageLoading ? <ActiveSocialAccountSkeleton selected={context.selectedPeriod} /> : <ActiveSocialAccount page="pinterest" disableFilter={true} loading={context.contentPageLoading} selected={context.selectedPeriod} onChangePeriod={(value) => { context.handleSelectedPeriod(value); context.handleFilterLoading(true) }} />}

                    <PinterestNavbar active="content" />
                    {context.contentPageLoading ? <PinterestContentSkeleton activeTab="content" /> :
                        <div className={Style.overview_content_container}>
                            {context.accountInfo?.follower_count < 100 && context.contentPageLoading === false ? <BlogListCard type="pinterest" /> : <></>}
                            <div className={Style.card_container} >
                                <OverviewCard toolTip=" Measure of user interactions on social media (e.g., likes, shares, comments, views). High rates show content resonance, driving conversations and community building. Key for gauging content performance and audience interest." data={true} count={formatNumber(context.totalStats.ENGAGEMENT)} percentage={0} header="Total Engagement" />
                                <OverviewCard toolTip="Displays of your pin on Pinterest, indicating visibility and potential audience reach, without measuring direct engagement." people={true} count={formatNumber(context.totalStats.IMPRESSION)} percentage={0} header="Total Impression" />
                                <OverviewCard toolTip="Metric measuring in seconds duration viewers watch a video on Pinterest. Crucial for creators & marketers, indicates content engagement. Higher time = more captivating content, helps gauge performance, understand preferences, & enhance engagement strategies." profile={true} count={context?.totalStats?.VIDEO_AVG_WATCH_TIME?.toFixed(2)} percentage={0} header="Video Avg Watch Time" />
                                <OverviewCard toolTip="This icon indicates a link that will take you outside of Pinterest. Click to explore the original webpage where you can find more details, make purchases, or get additional content related to the pin." website={true} count={formatNumber(context.totalStats.OUTBOUND_CLICK)} percentage={0} header="Outbound Click" />
                            </div>

                            {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                <div className={Style.competitor_category_content_container} >
                                    <LineChartCustomComponent componentName="category" style={{ width: '48%' }} header={`Category concentric graph (last 30 days)`} contentArray={
                                        // const name = instagramContext.competitors.find(competitor => competitor.id === data.socialId).name
                                        getTopCategoryCounts([context.stackedDataContent]).map((data, index) => {
                                            return {
                                                "header": `${(userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name} ( ${data.topCategory.category} )`, indicator: dataDonghnut?.datasets[index]?.backgroundColor, type: 'solid', "value": data.topCategory.count
                                            }
                                        })

                                    } onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(false)} type="pie" options={{
                                        maintainAspectRatio: false,
                                        onClick: function (e, i) {
                                            setpage(1)
                                            setselectedCategory(dataDonghnut.labels[i[0].index])
                                        },
                                        scales: {
                                            x: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        }
                                    }} chartData={dataDonghnut} />
                                    {context.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? context?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {

                                        const postDetails = context.allPins.filter(postDetails => data.postId.includes(postDetails.id))
                                        return postDetails.length
                                    })[0] > 0 ?
                                        <div className={Style.category_content_container} >
                                            <div>
                                                <label>{(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.name} - {selectedCategory === '' ? context?.stackedDataContent[0]?.contentCategory : selectedCategory}Post</label>

                                                {context.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? context?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {

                                                    const postDetails = context.allPins.filter(postDetails => data.postId.includes(postDetails.id))

                                                    const renderPost = postDetails.slice((page - 1) * 5, (page - 1) * 5 + 5).map((postData, index) => {
                                                        return (
                                                            <div className={Style.single_content_container} >
                                                                <div className={Style.postDetailsContainer} >
                                                                    <p className={Style.index_header} >{index + 1}</p>
                                                                    {postData.media.media_type === "image" ? <img alt="url" src={postData.media?.images["1200x"]?.url} /> : <img alt="url" src={postData?.media?.cover_image_url} />}
                                                                    <div className={Style.post_content_container} >
                                                                        <h5>{captionTruncate(postData?.description === undefined ? "_" : postData?.description, 15)}</h5>
                                                                        <p>{formatNumber(postData?.pin_metrics?.all_time?.impression||0)} Impression {formatNumber(postData?.pin_metrics?.all_time?.outbound_click||0)} Outbound Click</p>
                                                                    </div>
                                                                </div>
                                                                <svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`https://in.pinterest.com/pin/${postData.id}/`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_2171_2043)">
                                                                        <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_2171_2043">
                                                                            <rect width="22" height="22" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                        )
                                                    })
                                                    return renderPost
                                                })
                                                }
                                            </div>
                                            <div className={Style.post_pagination_container} >
                                                <svg onClick={prevPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.375 3.75L5.625 7.5L9.375 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Showing {page}-{Math.ceil(context.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? context?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                    const postDetails = context.allPins.filter(postDetails => data.postId.includes(postDetails.id))
                                                    return postDetails.length
                                                })[0] / limit)} posts</span>
                                                <svg onClick={nextPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.625 3.75L9.375 7.5L5.625 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </div>

                                        </div> : <NoDataAvailablePopup noOfPost={context.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? context?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {

                                            const postDetails = context.allPins.filter(postDetails => data.postId.includes(postDetails.id))
                                            return postDetails.length
                                        })[0]} selectedPeriod={// eslint-disable-next-line
                                            context.selectedPeriod == 31 ? 30 : context.selectedPeriod} cardName="postlist" />
                                    }

                                </div> : <PremiumPopup cardName="postlist" />
                            }


                            <div className={Style.reach_and_profile_activity}>
                                <LineChartCustomComponent onHoverOut={() => sethoverEngagementRate(false)} onHoverIn={() => sethoverEngagementRate(true)} type="line" header="Engagement Rate" subheader="" contentArray={[{
                                    "header": "Average Engagement Rate", value: context.totalStats.ENGAGEMENT_RATE?.toFixed(2) + "%"
                                }, { "header": "Highest Engagement Rate", value: Math.max(...context.engagementRate.points).toFixed(2) + "%" }, { "header": "Lowest Engagement Rate", value: Math.min(...context.engagementRate.points) + "%" }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverEngagementRate ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },

                                }} chartData={engagementRateChartData} />
                                <LineChartCustomComponent onHoverOut={() => sethoverPinClicks(false)} onHoverIn={() => sethoverPinClicks(true)} type="line" header="Pin Clicks" subheader="" contentArray={[{
                                    "header": "Pin Click Rate", value: context.totalStats.PIN_CLICK_RATE?.toFixed(2) + "%"
                                }, { "header": "Highest Pin Click", value: formatNumber(Math.max(...context.pinClick.points)) }, { "header": "Lowest Outbound Click", value: formatNumber(Math.min(...context.pinClick.points)) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverPinClicks ? 5 : 5,

                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },

                                }} chartData={pinClickChartData} />
                            </div>

                            <div className={Style.post_graph_container} >
                                <LineChartCustomComponent componentName="category" style={{ width: '98%' }} header={`Category graph (last 30 days)`} contentArray={
                                    getTopCategoryCounts([context.stackedDataContent]).map((data, index) => {
                                        return {
                                            "header": `${(userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name} ( ${data.topCategory.category} )`, indicator: dataDonghnut?.datasets[index]?.backgroundColor, type: 'solid', "value": data.topCategory.count
                                        }
                                    })
                                } onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(false)} type="bar" options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverCommentGraph ? 5 : 5,
                                    scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true,
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={dataForCategory} />
                            </div>


                            <div className={Style.post_main_container} style={{
                                marginBottom: "0px"
                            }}>
                                <div className={Style.header_container} >
                                    <span>Pin Activity</span>
                                    <div className={Style.button_container} >
                                        {/* <button onClick={prevCount} >Prev</button> */}
                                        <button onClick={() => {
                                            context.loadMorePins()
                                        }}>Load more</button>
                                    </div>
                                </div>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                    {context?.allPins?.length ?
                                        <>
                                            <div className={Style.post_container} >
                                                <div className={Style.header_container} >
                                                    <span>Pin</span>
                                                    <div className={Style.postHeaderContainer} >
                                                        <span>Impressions</span>
                                                        <span>Engagement</span>
                                                        <span>Outbound Click</span>
                                                        <span>Click Through</span>

                                                        <span>Pin Click</span>
                                                        <span>User Follow</span>
                                                        <span>Save</span>

                                                    </div>
                                                </div>
                                                <div className={Style.border} ></div>
                                                {context.allPins?.map((data, index) => {
                                                    return (
                                                        <div style={{ width: '100%' }} key={index} >
                                                            <div style={{ cursor: 'pointer' }} className={Style.post_item_container} key={index} onClick={() => {
                                                                setIsPinViewActive(true)
                                                                setActivePin(data)
                                                            }}>
                                                                <div className={Style.post_content_container} >

                                                                    <div className={Style.post_content_img} >
                                                                        {data.media.media_type === "image" ? <img alt="url" src={data.media?.images["1200x"]?.url} /> : <img alt="url" src={data?.media?.cover_image_url} />}

                                                                    </div>

                                                                    <div className={Style.post_content} >
                                                                        <span>{captionTruncate(data?.description || "")}</span>
                                                                        <p>{new Date(data?.created_at).getDate() + "/" + (new Date(data?.created_at).getMonth() + 1) + "/" + new Date(data?.created_at).getFullYear()} {new Date(data?.created_at).toLocaleTimeString('en-US')}</p>
                                                                    </div>


                                                                </div>
                                                                <div className={Style.postOtherDetails} >
                                                                    <div>{formatNumber(data?.pin_metrics?.all_time?.impression || 0)}</div>
                                                                    <div>{formatNumber(Number(data?.pin_metrics?.all_time?.pin_click || 0) + Number(data?.pin_metrics?.all_time?.outbound_click || 0) + Number(data?.pin_metrics?.all_time?.save || 0))}</div>
                                                                    <div>{formatNumber(data?.pin_metrics?.all_time?.outbound_click || 0)}</div>

                                                                    <div>{formatNumber(data?.pin_metrics?.all_time?.clickthrough || 0)}</div>

                                                                    <div>{formatNumber(data?.pin_metrics?.all_time?.pin_click || 0)}</div>
                                                                    <div>{formatNumber(data?.pin_metrics?.all_time?.user_follow || 0)}</div>
                                                                    <div>{formatNumber(data?.pin_metrics?.all_time?.save || 0)}</div>
                                                                </div>
                                                            </div>
                                                            <section className={Style.border_of_pinterest} ></section>
                                                        </div>
                                                    )
                                                })}


                                                {context.paginationLoading ?
                                                    <>
                                                        <LinkedinPostSkeleton />
                                                    </> : <></>}
                                            </div>
                                        </> : <NoDataAvailablePopup noOfPost={context?.allPins?.length} selectedPeriod={
                                            // eslint-disable-next-line
                                            context.selectedPeriod == 31 ? 30 : context.selectedPeriod} cardName="postlist" />

                                    }
                                </> : <PremiumPopup cardName="postlist" />

                                }


                            </div>

                            <div className={Style.post_main_container} style={{
                                marginBottom: "0px"
                            }}>
                                <div className={Style.header_container} >
                                    <span>Boards Activity</span>
                                    <div className={Style.button_container} >
                                        {/* <button onClick={prevCount} >Prev</button> */}
                                        <button onClick={() => {
                                            context.loadMoreBoards()
                                        }}>Load more</button>
                                    </div>
                                </div>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                    {context?.boards?.length ? <>
                                        <div className={Style.post_container} >
                                            <div className={Style.header_container} >
                                                <span>Board</span>
                                                <div className={Style.postHeaderContainer} >
                                                    <span>Impressions</span>
                                                    <span>Engagement</span>
                                                    <span>Outbound Click</span>

                                                    <span>Pin Click</span>
                                                    <span>Pin Count</span>
                                                    <span>Follower Count</span>
                                                    <span>Save</span>
                                                </div>
                                            </div>
                                            <div className={Style.border} ></div>
                                            {context.boards.map((data, index) => {

                                                return (
                                                    <div key={index} style={{ width: '100%' }} >
                                                        <div style={{ cursor: 'pointer' }} className={Style.post_item_container} key={index} onClick={() => {
                                                            setIsBoardViewActive(true)
                                                            setActiveBoard(data)
                                                        }}>
                                                            <div className={Style.post_content_container} >

                                                                <div className={Style.post_content_img} >
                                                                    <img alt="image_cover_url" src={data?.media?.image_cover_url} />
                                                                </div>

                                                                <div className={Style.post_content} >
                                                                    <span>{captionTruncate(data?.name || "")}</span>
                                                                    <p>{new Date(data?.created_at).getDate() + "/" + (new Date(data?.created_at).getMonth() + 1) + "/" + new Date(data?.created_at).getFullYear()} {new Date(data?.created_at).toLocaleTimeString('en-US')}</p>
                                                                </div>


                                                            </div>
                                                            <div className={Style.postOtherDetails} >
                                                                <div>{isNaN(calculateBoardStats(data?.pins)?.impression) ? "0" : formatNumber(calculateBoardStats(data?.pins)?.impression)}</div>
                                                                <div>{isNaN(calculateBoardStats(data?.pins)?.engagement) ? "0" : formatNumber(calculateBoardStats(data?.pins)?.engagement)}</div>
                                                                <div>{isNaN(calculateBoardStats(data?.pins)?.outboundClicks) ? "0" : formatNumber(calculateBoardStats(data?.pins)?.outboundClicks)}</div>

                                                                <div>{isNaN(calculateBoardStats(data?.pins)?.pinClicks) ? "0" : formatNumber(calculateBoardStats(data?.pins)?.pinClicks)}</div>
                                                                <div>{isNaN(data?.pin_count) ? "-" : formatNumber(data?.pin_count)}</div>
                                                                <div>{isNaN(data?.follower_count) ? "-" : formatNumber(data?.follower_count)}</div>
                                                                <div>{isNaN(calculateBoardStats(data?.pins)?.save) ? "0" : formatNumber(calculateBoardStats(data?.pins)?.save)}</div>
                                                            </div>
                                                        </div>
                                                        <section className={Style.border_of_pinterest} ></section>
                                                    </div>
                                                )
                                            })}


                                            {context.boardsLoading ?
                                                <>
                                                    <LinkedinPostSkeleton />
                                                </> : <></>}
                                        </div>
                                    </> : <NoDataAvailablePopup noOfPost={context?.boards?.length} selectedPeriod={
                                        // eslint-disable-next-line
                                        context.selectedPeriod == 31 ? 30 : context.selectedPeriod} cardName="postlist" />

                                    }
                                </> : <PremiumPopup cardName="postlist" />

                                }



                            </div>

                            <div className={Style.post_main_container} style={{
                                marginBottom: "0px"
                            }}>
                                <div className={Style.header_container} >
                                    <span>Top Pin</span>
                                    <div className={Style.select_container} >
                                        <select onChange={(e) => context.getTopPins(e.target.value)}>

                                            <option value="ENGAGEMENT">Engagement</option>
                                            <option value="IMPRESSION" >Impression</option>
                                            <option value="OUTBOUND_CLICK" >Outbound Click</option>
                                            <option value="PIN_CLICK">Pin Click</option>
                                            <option value="SAVE">Save</option>
                                        </select>
                                    </div>
                                </div>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                    {context?.topPins?.length ? <>
                                        <div className={Style.post_container} >
                                            <div className={Style.header_container} >
                                                <span>Post</span>
                                                <div className={Style.postHeaderContainer} >
                                                    <span>Engagement</span>
                                                    <span>Impressions</span>
                                                    <span>Outbound Click</span>
                                                    <span>Click Through</span>

                                                    <span>Pin Click</span>
                                                    <span>Close Up</span>
                                                    <span>Save</span>

                                                </div>
                                            </div>
                                            <div className={Style.border} ></div>



                                            {context.topPinsLoading ? <LinkedinPostSkeleton /> : context.topPins.map((data, index) => {

                                                return (
                                                    <div key={index} style={{ width: '100%' }}>
                                                        <div style={{ cursor: 'pointer' }} className={Style.post_item_container} key={index} onClick={() => {
                                                            setIsPinViewActive(true)
                                                            setActivePin(data)
                                                        }} >
                                                            <div className={Style.post_content_container} >

                                                                <div className={Style.post_content_img} >
                                                                    {data?.media?.media_type === "image" ? <img alt="" src={data.media?.images["1200x"]?.url} /> : <img alt="url" src={data.media?.cover_image_url} />}

                                                                </div>

                                                                <div className={Style.post_content} >
                                                                    <span>{captionTruncate(data?.description)}</span>
                                                                    <p>{new Date(data.created_at).getDate() + "/" + (new Date(data.created_at).getMonth() + 1) + "/" + new Date(data.created_at).getFullYear()} {new Date(data?.created_at).toLocaleTimeString('en-US')}</p>
                                                                </div>


                                                            </div>
                                                            <div className={Style.postOtherDetails} >
                                                                <div>{formatNumber(data.pin_metrics.IMPRESSION || 0)}</div>
                                                                <div>{formatNumber(Number(data.pin_metrics.PIN_CLICK || 0) + Number(data.pin_metrics.OUTBOUND_CLICK || 0) + Number(data.pin_metrics.SAVE || 0))}</div>
                                                                <div>{formatNumber(data.pin_metrics.OUTBOUND_CLICK || 0)}</div>

                                                                <div>{formatNumber(data.pin_metrics.CLICKTHROUGH || 0)}</div>

                                                                <div>{formatNumber(data.pin_metrics.PIN_CLICK || 0)}</div>
                                                                <div>{formatNumber(data.pin_metrics.CLOSEUP || 0)}</div>
                                                                <div>{formatNumber(data.pin_metrics.SAVE || 0)}</div>
                                                            </div>
                                                        </div>
                                                        <section className={Style.border_of_pinterest} ></section>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </> : <NoDataAvailablePopup noOfPost={context?.topPins?.length} selectedPeriod={
                                        // eslint-disable-next-line
                                        context.selectedPeriod == 31 ? 30 : context.selectedPeriod} cardName="postlist" />

                                    }
                                </> : <PremiumPopup cardName="postlist" />

                                }


                            </div>

                            <div style={{ marginBottom: "120px" }} className={Style.post_main_container} >
                                <div className={Style.header_container} >
                                    <span>Top Video Pin</span>
                                    <div className={Style.select_container} >
                                        <select onChange={(e) => context.getTopVideoPins(e.target.value)}>
                                            <option value="IMPRESSION" >Impression</option>
                                            <option value="OUTBOUND_CLICK" >Outbound Click</option>
                                            <option value="VIDEO_AVG_WATCH_TIME">Average watch time</option>
                                            <option value="SAVE">Save</option>
                                            <option value="VIDEO_START">Video Start</option>
                                        </select>
                                    </div>
                                </div>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                    {context?.topVideoPins?.length ? <>
                                        <div className={Style.post_container} >
                                            <div className={Style.header_container} >
                                                <span>Pin</span>
                                                <div className={Style.postHeaderContainer} >
                                                    <span>Impressions</span>
                                                    <span>Engagement</span>
                                                    <span>Outbound Click</span>
                                                    <span>Click Through</span>

                                                    <span>Pin Click</span>
                                                    <span>Close Up</span>
                                                    <span>Save</span>

                                                </div>
                                            </div>
                                            <div className={Style.border} ></div>



                                            {context.topVideoPinsLoading ? <LinkedinPostSkeleton /> : context.topVideoPins.map((data, index) => {
                                                return (
                                                    <div key={index} style={{ width: '100%' }}>
                                                        <div style={{ cursor: 'pointer' }} className={Style.post_item_container} key={index} onClick={() => {
                                                            setIsPinViewActive(true)
                                                            setActivePin(data)
                                                        }}>
                                                            <div className={Style.post_content_container} >

                                                                <div className={Style.post_content_img} >
                                                                    {data.media?.media_type === "image" ? <img alt="url" src={data.media?.images["1200x"]?.url} /> : <img alt="url" src={data.media?.cover_image_url} />}

                                                                </div>

                                                                <div className={Style.post_content} >
                                                                    <span>{captionTruncate(data?.description || "")}</span>
                                                                    <p>{new Date(data.created_at).getDate() + "/" + (new Date(data.created_at).getMonth() + 1) + "/" + new Date(data.created_at).getFullYear()} {new Date(data?.created_at).toLocaleTimeString('en-US')}</p>
                                                                </div>


                                                            </div>
                                                            <div className={Style.postOtherDetails} >
                                                                <div>{formatNumber(data.pin_metrics.IMPRESSION || 0)}</div>
                                                                <div>{formatNumber(Number(data.pin_metrics.PIN_CLICK || 0) + Number(data.pin_metrics.OUTBOUND_CLICK || 0) + Number(data.pin_metrics.SAVE || 0))}</div>
                                                                <div>{formatNumber(data.pin_metrics.OUTBOUND_CLICK || 0)}</div>

                                                                <div>{formatNumber(data.pin_metrics.CLICKTHROUGH || 0)}</div>

                                                                <div>{formatNumber(data.pin_metrics.PIN_CLICK || 0)}</div>
                                                                <div>{formatNumber(data.pin_metrics.CLOSEUP || 0)}</div>
                                                                <div>{formatNumber(data.pin_metrics.SAVE || 0)}</div>
                                                            </div>
                                                        </div>
                                                        <section className={Style.border_of_pinterest} ></section>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </> : <NoDataAvailablePopup noOfPost={context?.topVideoPins?.length} selectedPeriod={
                                        // eslint-disable-next-line
                                        context.selectedPeriod == 31 ? 30 : context.selectedPeriod} cardName="postlist" />

                                    }
                                </> : <PremiumPopup cardName="postlist" />

                                }


                            </div>
                        </div>

                    }

                </div>



            </div>
        </div>
    );
}

export default PinterestContent;