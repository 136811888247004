import { useContext, useEffect } from "react"
import LeftSidePanel from "../../Component/LeftSidePanel/leftsidepanel"
import RightSidePanel from "../../Component/RightSidePanel/RightSidePanel"
import TopBar from "../../Component/TopBar/topbar"
import Styles from './Home.module.css'
import { UserConnectedAccountContext } from "../../store/UserConnectedAccount"

const Home =()=>{
    
    const userData = useContext(UserConnectedAccountContext)
    useEffect(()=>{
        
        userData.getInfo()
        userData.getAllPostOfGhost()
        
        // eslint-disable-next-line
    },[])

    return(
        <div  >  
            <TopBar/>
            <div className={Styles.home_main_container} >
                <LeftSidePanel/>
                <RightSidePanel/>
            </div>
        </div>
    )
}

export default Home