import { useContext, useState } from 'react'
import Styles from './CompetitorList.module.css'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import ProfileImage from '../../assets/Profile.jpeg'
import { captionTruncate } from '../../Util/helper'
import { toast } from 'react-toastify'
import { UserNavigationContext } from '../../store/NavigationProvider'

const CompetitorList = (props) => {

    const [competitorAdded, setCompetitorAdded] = useState(false);
    // eslint-disable-next-line
    const [hide, setHide] = useState(false)

    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const userData = useContext(UserNavigationContext)
    const [isButtonEnable, setisButtonEnable] = useState(false)



    const checkCompetitorsExits = () => {
        try {
            instagramContext.setaddCompetitorsLoader(true)
            const competitorExits = instagramContext?.competitors?.some(data => { return data.business_discovery.username.toLowerCase() === instagramContext.searchList[0].business_discovery.username.toLowerCase() })
            if (competitorExits) {
                toast('already competitor added')

                instagramContext.setaddCompetitorsLoader(false)


            } else {

                if (instagramContext?.competitors?.length < 10) {
                    instagramContext.addPageToCompetitors(instagramContext.searchList[0])
                    setCompetitorAdded(true);
                    instagramContext.setaddCompetitorsLoader(false)
                    setisButtonEnable(true)

                } else {
                    toast('please add number of competitors less than 10')

                    instagramContext.setaddCompetitorsLoader(false)

                }
            }

        } catch (error) {
            toast.error('Something went to wrong')

            instagramContext.setaddCompetitorsLoader(false)

        }
    }

    const saveInstagramCompetitors = () => {
        try {
            if (competitorAdded || hide) {
                instagramContext.saveInstagramCompetitors(competitorAdded, hide)
            }
            props.close()
        } catch (error) {
            toast.error('Something went to wrong')
        }
    }

    const onClickDelete = (name) => {
        instagramContext.deleteCompetitors(name)
        setCompetitorAdded(true)
        setisButtonEnable(true)
    }

    const onClickHide = (name, status) => {
        instagramContext.hideCompetitors(name, status)
        setHide(!hide)
        setisButtonEnable(true)
    }


    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Call your new function here
            instagramContext.searchCompetitors(instagramContext.search)
        }
    };

    const getCompetitorImage = (id) => {

        return instagramContext?.competitorsData?.filter(data => data?.business_discovery?.id === id)[0]?.business_discovery?.profile_picture_url
    }



    return (
        <div className={Styles.competitor_list_container} >
            <div className={Styles.competitor_list} >
                <div className={Styles.header_container} >
                    <label>Competitors List</label>

                    <svg onClick={() => props.onClickCancel()} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.41309 15.0874L11.0007 10.4997L15.5883 15.0874M15.5883 5.91211L10.9998 10.4997L6.41309 5.91211" stroke="#808080" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>


                </div>

                <div className={Styles.paragraph} >Edit your Competitors list to benchmark your Page’s performance against your competitors on Instagram.</div>

                <div className={Styles.remaining_competitor_count_header} >{10 - instagramContext.competitors.length} Pages left to add</div>

                <div className={Styles.search_bar_container} >
                    <svg onClick={() => instagramContext.searchCompetitors(instagramContext.search)} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M8.5 8.5L11 11" stroke="#808080" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M1 5.28571C1 7.65264 2.91878 9.57143 5.28571 9.57143C6.47121 9.57143 7.54436 9.09007 8.32021 8.31214C9.09343 7.53693 9.57143 6.46714 9.57143 5.28571C9.57143 2.91878 7.65264 1 5.28571 1C2.91878 1 1 2.91878 1 5.28571Z" stroke="#808080" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <input value={instagramContext.search} type="text" onKeyPress={handleEnterKeyPress} onChange={(e) => {
                        const newSearchValue = e.target.value;
                        instagramContext.setsearch(newSearchValue);
                        if (newSearchValue === "") {
                            // Clear search results here, or ensure your search context does this when an empty string is set
                            instagramContext.setsearchList([]) // This is a hypothetical function you might need to implement
                        }
                    }} placeholder='Search by username' />

                    {instagramContext.search !== "" ?
                        <svg onClick={() => { instagramContext.setsearch(''); instagramContext.setsearchList([]) }} style={{ cursor: 'pointer' }} width="10" height="10" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.25 3.75L1.99998 2.00001M1.99998 2.00001L3.75005 0.25M1.99998 2.00001L0.25 0.25M1.99998 2.00001L3.75005 3.75" stroke="#404040" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> : <></>
                    }


                </div>
                {instagramContext.searchCompetitorLoading ? <div style={{ justifyContent: 'center', alignItems: 'center' }} className={Styles.search_competitors_list} >
                    <div className={Styles.loader} ></div>

                </div> :
                    <>
                        {instagramContext.showSearchList && instagramContext.search !== "" && instagramContext.searchList.length ?
                            <>
                                <div className={Styles.search_competitors_list} >
                                    {instagramContext?.searchList?.map(data => {
                                        return (
                                            <div onClick={() => checkCompetitorsExits()} className={Styles.search_list_item} >
                                                <div className={Styles.profile_and_name_container} >
                                                    <div className={Styles.profile_container} >
                                                        <img alt="profile" src={data?.business_discovery?.profile_picture_url === undefined ? ProfileImage : data.business_discovery.profile_picture_url} onError={(e) => e.target.src = ProfileImage} />
                                                    </div>
                                                    <div className={Styles.business_name} >{captionTruncate(data?.business_discovery?.username, 20)}</div>
                                                </div>
                                                {instagramContext.competitors.some(data1 => data1.business_discovery.id === data.business_discovery.id) ?
                                                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.333008" width="10" height="10" rx="2" fill="#0078FF" />
                                                        <path d="M2.41602 5.41699L4.08268 7.08366L8.24935 2.91699" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    : <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" stroke="#0078FF" />
                                                    </svg>

                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                            </>
                            :
                            <div style={isButtonEnable?{}:{height:200}} className={Styles.competitor_list_item_container} >
                                {instagramContext?.competitors?.map(data => {
                                    return (
                                        <div className={Styles.competitor_list_item} >
                                            <div className={Styles.profile_and_name_container} >
                                                <div className={Styles.profile_container} >

                                                    <img

                                                        alt="profile" src={getCompetitorImage(data?.business_discovery?.id) === undefined ? data.business_discovery.profile_picture_url !== undefined ? data.business_discovery.profile_picture_url : ProfileImage : getCompetitorImage(data?.business_discovery?.id)} onError={(e) => e.target.src = ProfileImage} />
                                                </div>
                                                <div className={Styles.business_name} >{captionTruncate(data?.business_discovery?.name, 20)}</div>
                                            </div>
                                            <div className={Styles.icon_container} >
                                                {data?.business_discovery?.username !== userData.activeSocialMediaName ?
                                                    <div onClick={() => onClickDelete(data?.business_discovery?.username)} className={Styles.delete_icon_container} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                            <path d="M3.5 10.5C3.225 10.5 2.98958 10.4021 2.79375 10.2063C2.59792 10.0104 2.5 9.775 2.5 9.5V3H2V2H4.5V1.5H7.5V2H10V3H9.5V9.5C9.5 9.775 9.40208 10.0104 9.20625 10.2063C9.01042 10.4021 8.775 10.5 8.5 10.5H3.5ZM8.5 3H3.5V9.5H8.5V3ZM4.5 8.5H5.5V4H4.5V8.5ZM6.5 8.5H7.5V4H6.5V8.5Z" fill="#C4C4C4" />
                                                        </svg>
                                                    </div> : <>

                                                    </>
                                                }

                                                {data.hideView ? <div style={{ cursor: 'pointer' }} onClick={() => onClickHide(data?.business_discovery?.username, false)} className={Styles.view_icon_container} >
                                                    <svg width="15" height="15" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.2294 4.2463C13.3952 4.45302 13.487 4.72159 13.487 5.00006C13.487 5.27853 13.3952 5.5471 13.2294 5.75382C12.1794 7.02509 9.78936 9.5001 6.99936 9.5001C4.20936 9.5001 1.81936 7.02509 0.769358 5.75382C0.603518 5.5471 0.511719 5.27853 0.511719 5.00006C0.511719 4.72159 0.603518 4.45302 0.769358 4.2463C1.81936 2.97503 4.20936 0.5 6.99936 0.5C9.78936 0.5 12.1794 2.97503 13.2294 4.2463Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M7 7C8.10457 7 9 6.10457 9 5C9 3.89543 8.10457 3 7 3C5.89543 3 5 3.89543 5 5C5 6.10457 5.89543 7 7 7Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />

                                                        <line x1="14" y1="0" x2="0" y2="10" stroke="black" stroke-width="1.5" />
                                                    </svg>
                                                </div> :

                                                    <div style={{ cursor: 'pointer' }} onClick={() => onClickHide(data?.business_discovery?.username, true)} className={Styles.view_icon_container} >
                                                        <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_2171_2043)">
                                                                <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_2171_2043">
                                                                    <rect width="22" height="22" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>

                                                }

                                            </div>



                                        </div>
                                    )
                                })
                                }
                            </div>
                        }
                    </>

                }




                {isButtonEnable ?
                    <div className={Styles.bottom_button_container} >
                        <button onClick={() => props.onClickCancel()} >Cancel</button>
                        <button onClick={() => saveInstagramCompetitors()} >Save</button>
                    </div> :
                   <></>

                }


            </div>


        </div>
    )
}

export default CompetitorList