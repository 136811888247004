import Styles from './InstagramContentTab.module.css'
const InstagramContentTab = ({ activeTab, onchangeTab, totalPost, loading }) => {

    return (
        <div className={Styles.instagram_content_tab_container} >
            <div className={Styles.tab_main_container} >
                <div onClick={() => onchangeTab('feed')} className={Styles.tabs_container}>
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.125 5.25V13.25C13.125 13.3495 13.0855 13.4448 13.0152 13.5152C12.9448 13.5855 12.8495 13.625 12.75 13.625H4.75C4.65054 13.625 4.55516 13.5855 4.48483 13.5152C4.41451 13.4448 4.375 13.3495 4.375 13.25V5.25C4.375 5.15054 4.41451 5.05516 4.48483 4.98483C4.55516 4.91451 4.65054 4.875 4.75 4.875H12.75C12.8495 4.875 12.9448 4.91451 13.0152 4.98483C13.0855 5.05516 13.125 5.15054 13.125 5.25Z" stroke={activeTab === 'feed' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.25 3H2.875C2.77554 3 2.68016 3.03951 2.60983 3.10983C2.53951 3.18016 2.5 3.27554 2.5 3.375V11.75M4.375 11L7.7775 9.875L13.125 11.75M10.3125 8.625C10.0639 8.625 9.8254 8.52623 9.64959 8.35041C9.47377 8.1746 9.375 7.93614 9.375 7.6875C9.375 7.43886 9.47377 7.2004 9.64959 7.02459C9.8254 6.84877 10.0639 6.75 10.3125 6.75C10.5611 6.75 10.7996 6.84877 10.9754 7.02459C11.1512 7.2004 11.25 7.43886 11.25 7.6875C11.25 7.93614 11.1512 8.1746 10.9754 8.35041C10.7996 8.52623 10.5611 8.625 10.3125 8.625Z" stroke={activeTab === 'feed' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <span style={activeTab === 'feed' ? { color: '#0078FF' } : { color: '#333333' }} >Feed</span>

                </div>

                <div onClick={() => onchangeTab('reel')} className={Styles.tabs_container}>
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 1.75C4.04813 1.75 1.25 4.54813 1.25 8C1.25 11.4519 4.04813 14.25 7.5 14.25C10.9519 14.25 13.75 11.4519 13.75 8C13.75 4.54813 10.9519 1.75 7.5 1.75Z" stroke={activeTab === 'reel' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.66895 11.9662C2.66895 11.9662 4.06207 10.1875 7.49957 10.1875C10.9371 10.1875 12.3308 11.9662 12.3308 11.9662M7.49957 8C7.99685 8 8.47377 7.80246 8.8254 7.45083C9.17703 7.09919 9.37457 6.62228 9.37457 6.125C9.37457 5.62772 9.17703 5.15081 8.8254 4.79917C8.47377 4.44754 7.99685 4.25 7.49957 4.25C7.00229 4.25 6.52538 4.44754 6.17374 4.79917C5.82211 5.15081 5.62457 5.62772 5.62457 6.125C5.62457 6.62228 5.82211 7.09919 6.17374 7.45083C6.52538 7.80246 7.00229 8 7.49957 8Z" stroke={activeTab === 'reel' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>


                    <span style={activeTab === 'reel' ? { color: '#0078FF' } : { color: '#333333' }} >Reel</span>

                </div>

                <div onClick={() => onchangeTab('story')} className={Styles.tabs_container}>
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.625 13.625H9.375M5.625 13.625V10.5M5.625 13.625H2.25C2.15054 13.625 2.05516 13.5855 1.98483 13.5152C1.91451 13.4448 1.875 13.3495 1.875 13.25V10.875C1.875 10.7755 1.91451 10.6802 1.98483 10.6098C2.05516 10.5395 2.15054 10.5 2.25 10.5H5.625M9.375 13.625V6.125M9.375 13.625H12.75C12.8495 13.625 12.9448 13.5855 13.0152 13.5152C13.0855 13.4448 13.125 13.3495 13.125 13.25V2.75C13.125 2.65054 13.0855 2.55516 13.0152 2.48483C12.9448 2.41451 12.8495 2.375 12.75 2.375H9.75C9.65054 2.375 9.55516 2.41451 9.48483 2.48483C9.41451 2.55516 9.375 2.65054 9.375 2.75V6.125M5.625 10.5V6.5C5.625 6.40054 5.66451 6.30516 5.73483 6.23483C5.80516 6.16451 5.90054 6.125 6 6.125H9.375" stroke={activeTab === 'story' ? "#0078FF" : "#333333"} strokeWidth="1.25" />
                    </svg>

                    <span style={activeTab === 'story' ? { color: '#0078FF' } : { color: '#333333' }} >Story</span>

                </div>
                <div onClick={() => onchangeTab('keyword')} className={Styles.tabs_container}>
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.625 13.625H9.375M5.625 13.625V10.5M5.625 13.625H2.25C2.15054 13.625 2.05516 13.5855 1.98483 13.5152C1.91451 13.4448 1.875 13.3495 1.875 13.25V10.875C1.875 10.7755 1.91451 10.6802 1.98483 10.6098C2.05516 10.5395 2.15054 10.5 2.25 10.5H5.625M9.375 13.625V6.125M9.375 13.625H12.75C12.8495 13.625 12.9448 13.5855 13.0152 13.5152C13.0855 13.4448 13.125 13.3495 13.125 13.25V2.75C13.125 2.65054 13.0855 2.55516 13.0152 2.48483C12.9448 2.41451 12.8495 2.375 12.75 2.375H9.75C9.65054 2.375 9.55516 2.41451 9.48483 2.48483C9.41451 2.55516 9.375 2.65054 9.375 2.75V6.125M5.625 10.5V6.5C5.625 6.40054 5.66451 6.30516 5.73483 6.23483C5.80516 6.16451 5.90054 6.125 6 6.125H9.375" stroke={activeTab === 'keyword' ? "#0078FF" : "#333333"} strokeWidth="1.25" />
                    </svg>

                    <span style={activeTab === 'keyword' ? { color: '#0078FF' } : { color: '#333333' }} >Keyword</span>

                </div>

                <div onClick={() => onchangeTab('hashtag')} className={Styles.tabs_container}>

                    <svg width="24px" height="24px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M10 3L6 21" stroke={activeTab === 'hashtag' ? "#0078FF" : "#333333"} stroke-width="1.5" stroke-linecap="round"></path><path d="M20.5 16H2.5" stroke={activeTab === 'hashtag' ? "#0078FF" : "#333333"} stroke-width="1.5" stroke-linecap="round"></path><path d="M22 7H4" stroke={activeTab === 'hashtag' ? "#0078FF" : "#333333"} stroke-width="1.5" stroke-linecap="round"></path><path d="M18 3L14 21" stroke={activeTab === 'hashtag' ? "#0078FF" : "#333333"} stroke-width="1.5" stroke-linecap="round"></path></svg>

                    <span style={activeTab === 'hashtag' ? { color: '#0078FF' } : { color: '#333333' }} >Hashtag</span>

                </div>

                <div onClick={() => onchangeTab('mentions')} className={Styles.tabs_container}>
                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="4" stroke={activeTab === 'mentions' ? "#0078FF" : "#333333"} stroke-width="2" />
                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C14.2516 22 16.3295 21.2558 18.001 20" stroke={activeTab === 'mentions' ? "#0078FF" : "#333333"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16 8V12C16 13 16.6 15 19 15C21.4 15 22 13 22 12" stroke={activeTab === 'mentions' ? "#0078FF" : "#333333"} stroke-width="2" stroke-linecap="round" />
                    </svg>
                    <span style={activeTab === 'mentions' ? { color: '#0078FF' } : { color: '#333333' }} >Mentions</span>

                </div>
            </div>
            {loading ? <div className={`${Styles.total_post_header_skeleton} ${Styles.skeleton}`} ></div> : <div className={Styles.total_post_header} >Total ({totalPost.post} Posts & {totalPost.reel} Reels & {totalPost.story} Stories)</div>

            }

        </div>
    )
}

export default InstagramContentTab