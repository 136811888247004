import { useContext } from 'react'
import { staff } from '../../Util/Staff'
import { formatNumber } from '../../Util/helper'
import { industry } from '../../Util/industry'
import { jobFunction } from '../../Util/jobFunction'
import { seniorities } from '../../Util/seniorities'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import NoDataAvailablePopup from '../NoDataAvailablePopup/NoDataAvailablePopup'
import PremiumPopup from '../PremiumPopup/PremiumPopup'
import Styles from './ProgressBarCustomComponent.module.css'

const ProgressBarCustomComponent = ({ page, header, subHeader, progressBarArray, totalFollowers, type, onClickShowAll }) => {
    const userData = useContext(UserConnectedAccountContext)
    const getJobFunctionName = (functionName) => {
        const jobFunctionData = jobFunction.filter(jobFunctionItem => jobFunctionItem.$URN === functionName)
        return jobFunctionData[0].name
    }


    const getIndustryName = (industryName) => {
        const industryFunctionData = industry.filter(industryItem => industryItem.$URN === industryName)
        return industryFunctionData[0]?.name === undefined ? "Other" : industryFunctionData[0]?.name
    }

    const getseniority = (senioritiesName) => {
        const senioritiesData = seniorities.filter(senioritiesItem => senioritiesItem.$URN === senioritiesName)
        return senioritiesData[0].name
    }

    const getStaff = (senioritiesName) => {
        const senioritiesData = staff.filter(senioritiesItem => senioritiesItem.$URN === senioritiesName)
        return senioritiesData[0]?.name
    }

    const onCLickShowAll = () => {
        if (progressBarArray?.length) {
            onClickShowAll()
        } else {
            return
        }
    }

    const getLabelAndValue = (data) => {
        if (type === "job function") {
            return `${getJobFunctionName(data.function)} - ${data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount} (${(((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)})%`
        }
        if (type === "industry") {
            return `${getIndustryName(data.industry)} - ${data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount} (${(((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)})%`
        }
        if (type === "company size") {
            return `${getStaff(data.staffCountRange)} - ${data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount} (${(((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)})%`
        }
        if (type === "seniority") {
            return `${getseniority(data.seniority)} - ${data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount} (${(((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)})%`
        }
        if (type === "top language") {
            return `${data.language} - (${data.country}) - ${data.value} - ${(((data.value) / totalFollowers) * 100).toFixed(2)}%`
        }
        if (type === "top city") {
            return `${data.country}  - ${data.value} - ${(((data.value) / totalFollowers) * 100).toFixed(2)}%`
        }
    }



    return <>
        <section className={Styles.progress_bar_custom_componenet} >
            <section className={Styles.header_container} >
                <label>{header}</label>
                {subHeader ? <span onClick={onCLickShowAll} style={{ cursor: 'pointer' }} >
                    Show All
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path d="M5.625 11.25L9.375 7.5L5.625 3.75" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span> : <></>
                }

            </section>
            {page === "instagram" ?
                <>
                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                        <>

                            {progressBarArray?.length ?
                                <>
                                    {progressBarArray?.map((data,index) => {
                                        return (
                                            <section key={index} className={Styles.meter_bar_container} >
                                                {type === "top city" ?
                                                    <>


                                                        <span>{data.country} - <p>{formatNumber(data.value)} ({isFinite((((data.value) / totalFollowers) * 100).toFixed(2)) ? (((data.value) / totalFollowers) * 100).toFixed(2) : 0}%) </p> </span>
                                                        <meter data-tooltip-id="my-tooltip1" data-tooltip-content={getLabelAndValue(data)} id="disk_d" value={((data.value) / totalFollowers).toFixed(2) * 10}>100%</meter>
                                                    </> : <></>}
                                                {type === "top language" ?
                                                    <>


                                                        <span>{data.language} <label>({(data.country)})</label> - <p>{formatNumber(data.value)} ({isFinite((((data.value) / totalFollowers) * 100).toFixed(2)) ? (((data.value) / totalFollowers) * 100).toFixed(2) : 0}%) </p> </span>
                                                        <meter data-tooltip-id="my-tooltip1" data-tooltip-content={getLabelAndValue(data)} id="disk_d" value={((data.value) / totalFollowers).toFixed(2) * 10}>100%</meter>
                                                    </> : <></>}

                                            </section>
                                        )
                                    })

                                    }
                                </>
                                : <NoDataAvailablePopup cardName="progressbar" />

                            }

                        </> : <PremiumPopup cardName="progressbar" />
                    }


                </>
                :
                <>
                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                        <>
                            {progressBarArray.length ?
                                <>
                                    {progressBarArray?.map((data,index) => {
                                        
                                        return (
                                            <section key={index} className={Styles.meter_bar_container} >
                                                <>
                                                    {type === "job function" ? <span>{getJobFunctionName(data.function)} - <p>{formatNumber(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)} ({isFinite((((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)) ? (((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2): 0}%) </p> </span> : <></>}
                                                    {type === "industry" ? <span>{getIndustryName(data.industry)} - <p>{formatNumber(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)} ({isFinite((((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)) ? (((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2) : 0}%) </p> </span> : <></>}
                                                    {type === "company size" ? <span>{getStaff(data.staffCountRange)} - <p>{formatNumber(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)} ({isFinite((((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)) ? (((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2): 0}%) </p> </span> : <></>}
                                                    {type === "seniority" ? <span>{getseniority(data.seniority)} - <p>{formatNumber(data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount)} ({isFinite((((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2)) ? (((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) * 100) / totalFollowers).toFixed(2) : 0 }%) </p> </span> : <></>}

                                                    <meter data-tooltip-id="my-tooltip1" data-tooltip-content={getLabelAndValue(data)} value={((data.followerCounts.organicFollowerCount + data.followerCounts.paidFollowerCount) / totalFollowers).toFixed(2) * 10}>100%</meter>
                                                </>
                                            </section>
                                        )
                                    })}
                                </> : <NoDataAvailablePopup cardName="progressbar" />

                            }
                        </> :
                        <PremiumPopup cardName="progressbar" />
                    }


                </>
            }



        </section>

    </>
}

export default ProgressBarCustomComponent