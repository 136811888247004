import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line, Bar, Pie } from 'react-chartjs-2';
import Styles from './LineChartCustomComponent.module.css'
import NoDataAvailablePopup from '../NoDataAvailablePopup/NoDataAvailablePopup';
import { useContext } from 'react';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount';
import PremiumPopup from '../PremiumPopup/PremiumPopup';

const LineChartCustomComponent = ({ style, type, header, subheader, contentArray, onChangePostFilter, onClickSubHeader, options, chartData, selectBox, onChangeFilter, onHoverIn, onHoverOut, selectedValue, noOfPost, selectedPeriod, pageName, componentName }) => {
    const customVerticalLinePlugin = {
        id: 'customVerticalLine',
        afterDraw: function (chart, args, options) {
            if (chart.config.type !== 'pie') {
                if (chart.tooltip?._active && chart.tooltip._active.length) {
                    const ctx = chart.ctx;
                    const x = chart.tooltip._active[0].element.x;
                    const topY = chart.scales.y.top;
                    const bottomY = chart.scales.y.bottom;

                    // Draw vertical line
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = 'rgb(48, 48, 48)';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        }
    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        customVerticalLinePlugin
    )


    const userData = useContext(UserConnectedAccountContext)

    const onCLickShowAll = () => {
        if (type === "pie" ? chartData?.datasets[0]?.data[0] !== 0 : chartData?.datasets[0]?.data) {
            onClickSubHeader()
        } else {
            return
        }
    }

    return (

        <section onMouseEnter={() => onHoverIn()} onMouseLeave={() => onHoverOut()} style={style ? style : {}} className={Styles.line_chart_custom_component_container} >


            <>
                <section className={Styles.header_container1} >
                    <label>{header}</label>
                    {subheader ?
                        <span onClick={onCLickShowAll} style={{ cursor: 'pointer' }} >Show All
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                <path d="M6.125 11.25L9.875 7.5L6.125 3.75" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span> : <></>
                    }

                    {selectBox ? <select value={selectedValue} onChange={(e) => onChangeFilter(e.target.value)} >
                        <option value="All" >All</option>
                        <option value="Home" >Home</option>
                        <option value="About" >About</option>
                        <option value="Insights" >Insights</option>
                        <option value="People" >People</option>
                        <option value="Careers" >Careers</option>
                        <option value="Products" >Products</option>
                        <option value="Jobs" >Jobs</option>
                        <option value="LifeAt" >LifeAt</option>
                    </select> :

                        <>
                            {
                                selectBox === false ?
                                    <select onChange={(e) => { onChangePostFilter(e.target.value) }} >
                                        <option value='' >All</option>
                                        <option value="REELS" >Reel</option>
                                        <option value="IMAGE" >Image</option>
                                        <option value="CAROUSEL_ALBUM" >Carousel</option>
                                    </select> : <></>
                            }

                        </>

                    }

                </section>
                <>
                    {userData?.userData?.workspace?.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0]?.planData?.planId?.planName !== "free" ?
                        <>

                            {(type === "pie" ? chartData?.datasets[0]?.data?.some(data => data !== 0) && chartData?.datasets[0]?.data?.filter(data => data !== undefined)?.length : chartData?.datasets?.some(data => data?.data?.length !== 0)) ?
                                <>
                                    <section style={type === "pie" ? { height: 200, justifyContent: 'center', display: 'flex' } : componentName === "category" ? { height: 400 } : {}} className={Styles.follower_chart_container} >
                                        {type === "line" ? <Line data={chartData} options={options} /> : <></>}
                                        {type === "bar" ? <Bar data={chartData} options={options} /> : <></>}
                                        {type === "pie" ? <Pie data={chartData} options={options} /> : <></>}

                                    </section>
                                    {contentArray?.map((data, index) => {
                                        return <section key={index} >
                                            <section className={Styles.oragnic_container} >
                                                <span>{data.header} {data?.indicator ? <p style={{ borderBottomWidth: 2, borderBottomStyle: data.type, borderBottomColor: data.indicator }} ></p> : <></>} </span>
                                                <p>{data.value}</p>
                                            </section>
                                            {index !== (contentArray?.length - 1) ?
                                                <label className={Styles.border} ></label> : <></>
                                            }

                                        </section>
                                    })

                                    }
                                </> :
                                <NoDataAvailablePopup noOfPost={noOfPost} selectedPeriod={selectedPeriod === 31 ? 30 : selectedPeriod} cardName={pageName} />


                            }
                        </>
                        : <PremiumPopup />}

                </>

            </>



        </section>
    )
}

export default LineChartCustomComponent

