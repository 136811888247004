import { useContext } from 'react'
import ActiveSocialAccountSkeleton from '../ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton'
import FacebookNavbar from '../FacebookNavbar/FacebookNavbar'
import GraphSkeleton from '../GraphSkeleton/GraphSkeleton'
import InstagramNavbar from '../InstagramNavbar/InstagramNavbar'
import Styles from './InstagramOverviewSkeleton.module.css'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics'
const InstagramOverviewSkeleton = ({name}) => {
    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const facebookContext = useContext(CreateFacebookAnalyticContext)
    return (
        <div className={Styles.instagram_overview_skeleton_container} >
            <div className={Styles.overview_container} >
                <div className={Styles.overview_content_main_container} >
                    <ActiveSocialAccountSkeleton selected={name === "facebook" ? facebookContext.selectedPeriod :instagramContext.selectedPeriod} />
                    {name === "facebook" ? <FacebookNavbar active="overview" selected="31" />:
                    <InstagramNavbar active="overview" selected="31" />
                    }
                    
                    <div className={Styles.overview_content_container} >
                        <div className={Styles.card_container} >
                            {[1, 2, 3, 4].map((data,index) => {
                                return <div key={index}  >
                                    <div className={Styles.card_content} key={data}>
                                        <div className={`${Styles.svg_container} ${Styles.skeleton}`} ></div>
                                        <div className={Styles.card_content_header} >
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                        </div>

                                        <div className={`${Styles.percentage_container} ${Styles.skeleton}`} >

                                        </div>

                                    </div>


                                </div>
                            })

                            }
                        </div>

                        <div className={Styles.latest_pin_and_followers_container} >
                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>

                                <div className={Styles.latest_post_container} >
                                    <div className={`${Styles.img_container} ${Styles.skeleton}`} >


                                    </div>
                                    <div className={Styles.content_container} >
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                    </div>

                                </div>
                                <p className={Styles.skeleton} ></p>
                                


                            </div>

                            <GraphSkeleton />
                        </div>

                        <div className={Styles.audience_activity_container} >
                            <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                            </div>
                            <div className={`${Styles.date_and_activity_container} ${Styles.skeleton}`} >

                            </div>
                        </div>

                        <div className={Styles.reach_and_profile_activity} >
                            <GraphSkeleton />
                            <GraphSkeleton />
                        </div>




                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstagramOverviewSkeleton