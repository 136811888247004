import { useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Styles from './AccountConnectCommonFlow.module.css'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import { captionTruncate } from '../../Util/helper'
import { UserNavigationContext } from '../../store/NavigationProvider'
import { backend_url, socialInboxUrl, url } from '../../Util/constant'
import axios from 'axios'
import ProfileImage from '../../assets/Profile.jpeg'
import { toast } from 'react-toastify';
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import { CreateLinkedinAnalyticContext } from '../../store/linkedinAnalytic'
import { CreatePinterestAnalyticContext } from '../../store/pinterestAnalytics'
import LoadingBall from '../LoadingBall/LoadingBall';
import variables from '../../Util/variables';

const AccountConnectCommonFlow = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const [saveLoading, setsaveLoading] = useState(false)
    const [deleteLoading, setdeleteLoading] = useState(false)
    const [accountsToConnect, setAccountsToConnect] = useState([])
    const accountContext = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const facebookContext = useContext(CreateFacebookAnalyticContext)
    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const linkedinContext = useContext(CreateLinkedinAnalyticContext)
    const pinterestContext = useContext(CreatePinterestAnalyticContext)
    const [showAccount, setshowAccount] = useState(false)
    const [selectedAccount, setselectedAccount] = useState({})
    const [syncInfoLoading, setSyncInfoLoading] = useState(false)
    const [selectForDelete, setselectForDelete] = useState("")

    const getSocialAccountFromBackend = async (id, type) => {
        try {
            setloading(true)
            let workspaceId = localStorage.getItem('organizationId')
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const res = await axios.get(`${url}accounts/details/${id}/${workspaceId}/${type}`, config)


            let resOfAccount = await axios.get(url + `accounts/${workspaceId}`, config)
            resOfAccount.data = resOfAccount.data.filter(data => data.accountType === "linkedinpage" || data.accountType === "instagram" || data.accountType === "facebookpage" || data.accountType === "pinterest")


            const alreadyConnectedAccount = resOfAccount.data.map(data => ({ name: data.name, type: data.accountType }))



            const userConnectedAccount = alreadyConnectedAccount.length ? res.data.filter(data => {
                return alreadyConnectedAccount.some(connectedAccount =>
                    connectedAccount.name !== data.name && connectedAccount.type !== data.accountType
                );
            }).map(data => {
                if (alreadyConnectedAccount.some(connectedAccount =>

                    connectedAccount.name === data.name && connectedAccount.type === data.accountType
                )) {
                    data.active = true
                    data.disabledCheckBox = true

                }
                return data
            }) : res.data.map(data => {
                if (alreadyConnectedAccount.some(connectedAccount =>
                    connectedAccount.name === data.name && connectedAccount.type === data.accountType
                )) {
                    data.active = true
                    data.disabledCheckBox = true

                }
                return data
            });

            setloading(false)
            setAccountsToConnect(userConnectedAccount)

            accountContext.handleAccountConnectionLoading(false)
            userNavigation.setisConnectAccountPopup(true)
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
        }
    }

    useEffect(() => {

        const { type, code } = queryString.parse(location.search);
        if (type === "linkedinpage") {
            getSocialAccountFromBackend(code, type);

        }
        if (type === "pinterest") {
            getSocialAccountFromBackend(code, type);

        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const subscribeToLinkedInEvents = async (data) => {
        await axios.post(url + 'linkedin/subscribe/events', {
            linkedInProfileId: data.linkedInProfileId,
            pageId: data.socialId,
            accountToken: data.accessToken
        });
    };

    const subscribeToFacebookEvents = async (data) => {
        const url = `https://graph.facebook.com/v17.0/${data.socialId}/subscribed_apps?subscribed_fields=feed,messages,mention&access_token=${data.accessToken}`;
        await axios.post(url);
    };

    const subscribeToInstagramEvents = async (data) => {
        const url = `https://graph.facebook.com/v17.0/${data.facebookPageId}/subscribed_apps?subscribed_fields=feed,messages,mention&access_token=${data.accessToken}`;
        await axios.post(url);
    };
    const accounts = [
        {
            name: 'Instagram Business',
            accountType: 'instagram',
            icon: <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#E1306C" />
                <path d="M26.948 13.3054C26.937 12.5484 26.797 11.7974 26.529 11.0894C26.06 9.88045 25.105 8.92445 23.896 8.45645C23.197 8.19345 22.458 8.05245 21.71 8.03645C20.748 7.99345 20.443 7.98145 18.001 7.98145C15.559 7.98145 15.246 7.98145 14.291 8.03645C13.544 8.05245 12.805 8.19345 12.106 8.45645C10.897 8.92445 9.94105 9.88045 9.47305 11.0894C9.21005 11.7884 9.06805 12.5274 9.05405 13.2744C9.01105 14.2374 8.99805 14.5424 8.99805 16.9844C8.99805 19.4264 8.99805 19.7384 9.05405 20.6944C9.06905 21.4424 9.21005 22.1804 9.47305 22.8814C9.94205 24.0894 10.897 25.0454 12.107 25.5134C12.803 25.7854 13.542 25.9394 14.292 25.9634C15.255 26.0064 15.56 26.0194 18.002 26.0194C20.444 26.0194 20.757 26.0194 21.712 25.9634C22.459 25.9484 23.198 25.8074 23.898 25.5444C25.107 25.0754 26.062 24.1194 26.531 22.9114C26.794 22.2114 26.935 21.4734 26.95 20.7244C26.993 19.7624 27.006 19.4574 27.006 17.0144C27.004 14.5724 27.004 14.2624 26.948 13.3054ZM17.995 21.6024C15.441 21.6024 13.372 19.5334 13.372 16.9794C13.372 14.4254 15.441 12.3564 17.995 12.3564C20.547 12.3564 22.618 14.4254 22.618 16.9794C22.618 19.5334 20.547 21.6024 17.995 21.6024ZM22.802 13.2634C22.205 13.2634 21.724 12.7814 21.724 12.1854C21.724 11.5894 22.205 11.1074 22.802 11.1074C23.397 11.1074 23.879 11.5894 23.879 12.1854C23.879 12.7814 23.397 13.2634 22.802 13.2634Z" fill="white" />
                <path d="M17.9952 19.9826C19.6537 19.9826 20.9982 18.6381 20.9982 16.9796C20.9982 15.3211 19.6537 13.9766 17.9952 13.9766C16.3367 13.9766 14.9922 15.3211 14.9922 16.9796C14.9922 18.6381 16.3367 19.9826 17.9952 19.9826Z" fill="white" />
            </svg>
        },
        {
            name: 'Facebook Page',
            accountType: 'facebookpage',
            icon: <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#4267B2" />
                <path d="M19.616 26.9976V19.1918H22.2494L22.6408 16.1357H19.616V14.189C19.616 13.3071 19.8617 12.7033 21.1275 12.7033H22.7313V9.97852C21.9513 9.89471 21.1665 9.85471 20.3817 9.85756C18.0541 9.85756 16.456 11.2785 16.456 13.8871V16.1299H13.8398V19.1861H16.4617V26.9976H19.616Z" fill="white" />
            </svg>
        },
        {
            name: 'Linkedin Page',
            accountType: 'linkedinpage',
            icon: <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#0072B1" />
                <path d="M11.923 11.846C12.985 11.846 13.846 10.985 13.846 9.92299C13.846 8.86095 12.985 8 11.923 8C10.861 8 10 8.86095 10 9.92299C10 10.985 10.861 11.846 11.923 11.846Z" fill="white" />
                <path d="M15.6617 13.3027V23.9714H18.9742V18.6955C18.9742 17.3034 19.2362 15.9552 20.9623 15.9552C22.6647 15.9552 22.6858 17.5468 22.6858 18.7834V23.9723H26V18.1216C26 15.2477 25.3813 13.0391 22.0222 13.0391C20.4095 13.0391 19.3284 13.9241 18.8864 14.7617H18.8415V13.3027H15.6617ZM10.2637 13.3027H13.5814V23.9714H10.2637V13.3027Z" fill="white" />
            </svg>
        },
        {
            name: 'Pinterest',
            accountType: 'pinterest',
            icon: <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#D7143A" />
                <path d="M24.1227 10.152C22.7015 8.76429 20.7348 8 18.5848 8C15.3006 8 13.2806 9.36932 12.1644 10.518C10.7888 11.9336 10 13.8132 10 15.675C10 18.0126 10.9613 19.8068 12.5711 20.4743C12.6792 20.5193 12.7879 20.542 12.8945 20.542C13.2342 20.542 13.5033 20.316 13.5965 19.9534C13.6508 19.7454 13.7767 19.2323 13.8315 19.0095C13.9487 18.5696 13.854 18.358 13.5984 18.0517C13.1328 17.4913 12.916 16.8287 12.916 15.9664C12.916 13.4049 14.7912 10.6826 18.2666 10.6826C21.0243 10.6826 22.7373 12.2768 22.7373 14.843C22.7373 16.4624 22.3944 17.9621 21.7715 19.066C21.3386 19.8331 20.5775 20.7475 19.409 20.7475C18.9038 20.7475 18.4499 20.5364 18.1635 20.1683C17.893 19.8203 17.8038 19.3709 17.9126 18.9024C18.0355 18.3732 18.2031 17.8211 18.3654 17.2874C18.6612 16.3126 18.9409 15.3919 18.9409 14.6574C18.9409 13.401 18.1815 12.5567 17.0514 12.5567C15.6152 12.5567 14.49 14.0405 14.49 15.9346C14.49 16.8635 14.7328 17.5583 14.8426 17.8251C14.6617 18.6048 13.5864 23.2404 13.3824 24.1145C13.2645 24.6248 12.554 28.6552 13.73 28.9766C15.0512 29.3376 16.2321 25.4123 16.3524 24.9688C16.4498 24.608 16.7906 23.244 16.9995 22.4055C17.6373 23.0304 18.6643 23.4528 19.6635 23.4528C21.5473 23.4528 23.2414 22.5906 24.4339 21.0251C25.5903 19.5067 26.2273 17.3904 26.2273 15.0663C26.2272 13.2493 25.4601 11.4581 24.1227 10.152Z" fill="white" />
            </svg>
        },

    ]

    const callLogin = (value, connectionType, socialId) => {

        let scopes = [{
            connectionType: "instagram",
            scope: ['public_profile', 'pages_show_list', 'pages_read_engagement', 'instagram_content_publish', 'instagram_basic', 'instagram_manage_messages', 'pages_manage_metadata', 'instagram_manage_comments', 'read_insights', 'instagram_manage_insights', 'pages_messaging', 'business_management']
        }, {
            connectionType: "facebookpage",
            scope: ['public_profile', 'pages_show_list', 'pages_read_engagement', 'pages_manage_posts', 'pages_messaging', 'pages_manage_metadata', 'pages_read_user_content', 'pages_manage_engagement', 'read_insights', 'business_management']
        }]

        let data = scopes.filter(data => data.connectionType === value)

        window.FB.login((response) => {
            const facebookUser = response.authResponse;

            getTheLoneLivedAccessToken(facebookUser, value, connectionType, socialId);
        }, {
            scope: data[0].scope,
        })
    }

    const getTheLoneLivedAccessToken = async (facebookUser, value, connectionType, socialId) => {
        try {
            let user = facebookUser;
            let res = await axios.get(`https://graph.facebook.com/v17.0/oauth/access_token?  
                    grant_type=fb_exchange_token&          
                    client_id=957071665735231&
                    client_secret=769f5b546f0d0698f17d861bfa19d3a4&
                    fb_exchange_token=${user.accessToken}`);

            user.accessToken = res.data.access_token;

            // check which type of data we have to show to user
            if (value === 'facebookpage') {

                connectToPages(user, connectionType, socialId);
            } else if (value === 'instagram') {
                connectToInstagram(user, connectionType, socialId);
            }
        } catch (error) {
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
        }
    }

    const connectToPages = async (facebookUser, connectionType, socialId) => {
        try {

            const res = await axios.get(`https://graph.facebook.com/${facebookUser.userID}/accounts?fields=name,access_token,picture,is_owned&limit=100&access_token=${facebookUser.accessToken}`)
            const pages = res.data.data

            const filterPages = pages.map((data) => {
                let obj = {
                    accountType: "facebookpage",
                    facebookUserId: facebookUser.userID,
                    socialId: data.id,
                    profileURL: data.picture.data.url,
                    name: data.name,
                    accessToken: data.access_token,
                    admin: data.is_owned,
                    refreshToken: null,
                    active: false,
                    accessTokenExpiresIn: 5184000,
                    refreshTokenExpiresIn: null,
                    accessTokenCreatedAt: Date.now() / 1000,
                    isAccountTokensExpired: false,
                };
                return obj;
            });

            const userCanPostAccounts = filterPages.filter((data) => data.admin === true);
            if (connectionType === "reauthorize") {
                let token = localStorage.getItem("token")
                const config = { headers: { Authorization: `Bearer ${token}` } };
                let workspaceId = localStorage.getItem("organizationId")

                const reauthorizeAccount = userCanPostAccounts.find((data) => data.socialId === socialId)
                const response = await axios.post(`${url}accounts/update/info`, {
                    workspaceId: workspaceId,
                    account: reauthorizeAccount
                }, config)

                userNavigation.setisConnectAccountPopup(false)
                toast.success(response.data.message)


            } else {

                const alreadyConnectedAccount = accountContext.accounts.map(data => data.name)

                const userCanPostAccounts = filterPages.filter((data) => data.admin === true).map(data => {
                    if (alreadyConnectedAccount?.includes(data.name)) {
                        data.active = true
                        data.disabledCheckBox = true
                    }
                    return data
                });



                setAccountsToConnect(userCanPostAccounts)
                accountContext.handleAccountConnectionLoading(false)
                userNavigation.setisConnectAccountPopup(true)
            }
            setloading(false)
        } catch (error) {
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
        }
    }

    const connectToInstagram = async (facebookUser, connectionType, socialId) => {
        try {

            const res = await axios.get(`https://graph.facebook.com/${facebookUser.userID}/accounts?fields=name,access_token,picture,is_owned&limit=100&access_token=${facebookUser.accessToken}`);

            const instagramPages = res.data.data;

            for (const data of instagramPages) {
                const response = await axios.get(`https://graph.facebook.com/v17.0/${data.id}?fields=instagram_business_account&access_token=${data.access_token}`);
                if (response.data.instagram_business_account !== undefined) {
                    data.instagram_id = response.data.instagram_business_account.id;
                }
            }

            const filterThePagesHasInstagramId = instagramPages.filter((data) => data.instagram_id !== undefined);

            let filterInstagram = filterThePagesHasInstagramId.map(async (data) => {
                let res = await axios.get(`https://graph.facebook.com/v17.0/${data.instagram_id}?fields=profile_picture_url,username&access_token=${data.access_token}`);

                let errorMsg = '';

                if (errorMsg === '(#10) The user is not an Instagram Business') {
                    let obj = {
                        accountType: "instagram",
                        facebookUserId: facebookUser.userID,
                        socialId: data.instagram_id,
                        profileURL: res.data.profile_picture_url,
                        name: res.data.username,
                        accessToken: data.access_token,
                        refreshToken: null,
                        active: false,
                        refreshTokenExpiresIn: null,
                        accessTokenCreatedAt: Date.now() / 1000,
                        accessTokenExpiresIn: 5184000,
                        isAccountTokensExpired: false,
                        instagramAccountType: 'creator',
                        facebookPageId: data.id
                    }
                    return obj;
                } else {
                    let obj = {
                        accountType: "instagram",
                        facebookUserId: facebookUser.userID,
                        socialId: data.instagram_id,
                        profileURL: res.data.profile_picture_url,
                        name: res.data.username,
                        accessToken: data.access_token,
                        refreshToken: null,
                        active: false,
                        refreshTokenExpiresIn: null,
                        accessTokenCreatedAt: Date.now() / 1000,
                        accessTokenExpiresIn: 5184000,
                        isAccountTokensExpired: false,
                        instagramAccountType: 'business',
                        facebookPageId: data.id
                    }

                    return obj;
                }
            });

            if (connectionType === "reauthorize") {
                filterInstagram = await Promise.all(filterInstagram);
                const filterInstaArray = filterInstagram.filter((value) => Object.keys(value).length !== 0);
                const reauthorizeAccount = filterInstaArray.find((data) => data.socialId === socialId)
                let token = localStorage.getItem("token")
                const config = { headers: { Authorization: `Bearer ${token}` } };
                let workspaceId = localStorage.getItem("organizationId")
                const response = await axios.post(`${url}accounts/update/info`, {
                    workspaceId: workspaceId,
                    account: reauthorizeAccount
                }, config)
                userNavigation.setisConnectAccountPopup(false)
                toast.success(response.data.message)

            } else {

                filterInstagram = await Promise.all(filterInstagram);

                const alreadyConnectedAccount = accountContext.accounts.map(data => data.name)

                // remove the null accounts from the list
                filterInstagram = filterInstagram.filter(data => data !== null).map(data => {
                    if (alreadyConnectedAccount?.includes(data.name)) {
                        data.active = true
                        data.disabledCheckBox = true
                    }
                    return data
                })

                setAccountsToConnect(filterInstagram)
                accountContext.handleAccountConnectionLoading(false)
                userNavigation.setisConnectAccountPopup(true)
            }
            setloading(false)
        } catch (error) {
           // toast.error('Something went to wrong')
        }
    };

    const login = async (value, connectionType, socialId) => {
        try {

            if (parseInt(accountContext.userData.featureFactoryData.socialAccounts) === 0) return toast.error("Your account connect limit has been reached, please upgrade to connect more accounts.")
            setloading(true)
            if (value === "pinterest") {


                let workspaceId = localStorage.getItem('organizationId')
                window.open(`${url}pinterest/login/${workspaceId}/analytics`, '_self')
            } else if (value === "linkedinpage") {


                let workspaceId = localStorage.getItem('organizationId')
                window.open(`${url}linkedin/login/${workspaceId}/page/analytics`, '_self')
            } else {


                window.FB.getLoginStatus(response => {

                    if (response.status === 'connected') {

                        window.FB.logout(() => {
                            callLogin(value, connectionType, socialId)
                        })
                    } else {
                        callLogin(value, connectionType, socialId)
                    }
                })
            }
        } catch (error) {
            toast.error(error.response.data.error)
        }
    }

    const toggle = (i) => {
        const alreadyConnectedAccount = accountContext.accounts.map(data => ({ name: data.name, type: data.accountType }))
        let filterData = accountsToConnect.map((data, index) => {
            if (index === i) {
                if (!alreadyConnectedAccount.some(connectedAccount =>
                    connectedAccount.name === data.name && connectedAccount.type === data.accountType
                )) {
                    data.active = !data.active
                } else {
                    toast.error('you have added already that account!')
                }
            }
            return data
        })
        setAccountsToConnect(filterData)
    }

    const checkActiveAccountIsThere = () => {

        let data = accountsToConnect.filter(data => data.active === true && data.hide !== true)

        if (data.length === 0) return false
        return true
    }



    const saveTheInstagramData = async () => {
        try {
            if (checkActiveAccountIsThere() === false) return
            setsaveLoading(true);
            const workspaceId = localStorage.getItem('organizationId');
            const alreadyConnectedAccouns = accountContext.accounts.map(data => data.name)
            let userSelectedAccounts = accountsToConnect?.filter(data => data.active === true && data.hide !== true && !(alreadyConnectedAccouns.includes(data.name)))

            userSelectedAccounts = userSelectedAccounts.map(data => {
                data.active = true
                return data
            })
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }
            await axios.post("https://api.app.creatosaurus.io/creatosaurus/userfeaturefactory/cache/remove/credit/account", {
                id: workspaceId,
                accountsToConnect: userSelectedAccounts.length
            }, config)
            let res = await axios.post(`https://api.cache.creatosaurus.io/cache/accounts/create`, {
                workspaceId: workspaceId,
                accounts: userSelectedAccounts
            }, config)

            if (userSelectedAccounts[0].accountType !== "pinterest") {
                await axios.post(socialInboxUrl + `inbox/accounts/create`, {
                    workspaceId: workspaceId,
                    accounts: userSelectedAccounts
                })
            }

            res.data.accounts = res?.data?.accounts?.filter(data => data.accountType === "linkedinpage" || data.accountType === "instagram" || data.accountType === "facebookpage" || data.accountType === "pinterest")

            const subscribeToEvents = async (data) => {
                if (data.accountType === 'linkedinpage') {
                    await subscribeToLinkedInEvents(data);
                } else if (data.accountType === 'facebookpage') {
                    await subscribeToFacebookEvents(data);
                } else if (data.accountType === 'instagram') {
                    await subscribeToInstagramEvents(data);
                }

            };

            await Promise.all(userSelectedAccounts.map(subscribeToEvents));


            let connectedAccounts = res?.data?.accounts?.filter(data => !alreadyConnectedAccouns.includes(data.name))
            const newArray = [...connectedAccounts, ...accountContext.accounts]
            accountContext.handleUserAccount(newArray)
            const resOfUserData = await axios.get(backend_url + '/user/info', config);

            accountContext.handleUserData(resOfUserData.data)
            setsaveLoading(false);
            userNavigation.setisConnectAccountPopup(false)




            const isSelectedAccountExits = res.data.accounts.filter(data => data.name === userNavigation.activeSocialMediaName && data.accountType === userNavigation.activeRightSideSocialMedia)
            if (isSelectedAccountExits.length === 0) {
                userNavigation.setactiveLeftSideSocialMedia(0)
                userNavigation.setactiveRightSideSocialMedia(newArray[0]?.accountType)
                variables.leftSideBarState = res.data.accounts[0].socialId
                userNavigation.changeSocialMediaName(newArray[0]?.name)
            }
            navigate('/')
        } catch (error) {
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
            setloading(false);
            setsaveLoading(false)

        }
    };

    const deleteUserConnectedAccounts = async (data) => {
        try {
            setselectForDelete(data.socialId)
            setdeleteLoading(true)
            const workspaceId = localStorage.getItem('organizationId');
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }

            const accountFeatureData = await axios.post(backend_url + "userfeaturefactory/cache/add/credit/account", {
                id: workspaceId
            }, config)

            accountContext.updateUsersDataAccountLimit(accountFeatureData.data.accountLimitToConnect)




            let res = await axios.delete(`https://api.cache.creatosaurus.io/cache/accounts/delete/${workspaceId}/${data.socialId}`, config)

            await axios.delete(socialInboxUrl + `inbox/accounts/delete/${workspaceId}/${data.socialId}`)

            const isAccountExits = accountContext.accountsData.filter(cachingData => cachingData?.id !== data?.socialId?.toString())

            accountContext.changeaccountsData(isAccountExits)

            if (data.accountType === "instagram") {
                await axios.delete(`https://api.cache.creatosaurus.io/cache/analytics/instagram/deleteCompetitor/${workspaceId}/${data.socialId}`)

            }
            res.data.accounts = res?.data?.accounts?.filter(data => data.accountType === "linkedinpage" || data.accountType === "instagram" || data.accountType === "facebookpage" || data.accountType === "pinterest")
            if (res.data.accounts.length === 0) {
                userNavigation.setisShowConnectAccountPopup(false)
                userNavigation.setisConnectAccountPopup(false)

                userNavigation.setactiveLeftSideSocialMedia('')
                userNavigation.setactiveRightSideSocialMedia('dashboard')
                variables.leftSideBarState = null
                userNavigation.changeSocialMediaName('')
            } else {
                const isAccountExits = accountContext.accountsData.filter(data => data?.id === res.data.accounts[0].socialId)

                if (isAccountExits.length) {

                } else {
                    if (res.data.accounts[0].accountType === "facebookpage") {

                        facebookContext.changeLoadingState(true)
                        facebookContext.handleSelectedPeriod(31)
                    }
                    if (res.data.accounts[0].accountType === "linkedinpage") {
                        linkedinContext.changeLoadingState(true)
                        linkedinContext.setSelectedPeriod(31)
                    }
                    if (res.data.accounts[0].accountType === "instagram") {

                        instagramContext.changeLoadingState(true)
                        instagramContext.setselectedPeriod(30)
                    }
                    if (res.data.accounts[0].accountType === "pinterest") {
                        pinterestContext.changeLoadingState(true)
                        pinterestContext.handleSelectedPeriod('31')
                    }
                }
                const isSelectedAccountExits = res.data.accounts.filter(data => data.socialId === variables.leftSideBarState && data.accountType === userNavigation.activeRightSideSocialMedia)

                if (isSelectedAccountExits.length === 0) {
                    userNavigation.setactiveLeftSideSocialMedia(0)
                    userNavigation.setactiveRightSideSocialMedia(res.data.accounts[0].accountType)
                    variables.leftSideBarState = res.data.accounts[0].socialId
                    userNavigation.changeSocialMediaName(res.data.accounts[0].name)
                } else {
                    userNavigation.setactiveLeftSideSocialMedia(0)
                    userNavigation.setactiveRightSideSocialMedia(isSelectedAccountExits[0].accountType)
                    variables.leftSideBarState = isSelectedAccountExits[0].socialId
                    userNavigation.changeSocialMediaName(isSelectedAccountExits[0].name)
                }
            }

            accountContext.setaccounts(accountContext.accounts.filter(connected => connected.socialId !== data.socialId))




            setdeleteLoading(false)
            toast('Account removed successfully!')

        } catch (error) {
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
            setdeleteLoading(false)
        }
    }

    const close = () => {

        userNavigation.setisShowConnectAccountPopup(false)
        userNavigation.setisConnectAccountPopup(false)

        const { type } = queryString.parse(location.search);
        if (type === "linkedinpage") {
            navigate('/')
        }
        if (type === "pinterest") {
            navigate('/')
        }
    }

    const reauthorize = async (accountType, socialId) => {
        let workspaceId = localStorage.getItem('organizationId')
        if (accountType === "instagram") {
            login('instagram', 'reauthorize', socialId);
        } else if (accountType === "facebookpage") {
            login('facebookpage', 'reauthorize', socialId);
        } else if (accountType === "linkedinpage") {
            window.open(`${url}linkedin/reauthorize/${workspaceId}/linkedinpage/analytics`, '_self')
        } else if (accountType === "pinterest") {
            window.open(`${url}pinterest/reauthorize/${workspaceId}/analytics`, '_self')
        }
    }

    const syncInfo = async (accountType) => {
        setSyncInfoLoading(true)
        let workspaceId = localStorage.getItem('organizationId')
        try {
            if (accountType === "instagram") {
                const response = await axios.post(`${url}instagram/sync/info/${selectedAccount.socialId}/${workspaceId}`, {
                    accessToken: selectedAccount.accessToken
                })
                if (response.status === 200) {

                    setselectedAccount(response.data)
                    toast.success("Instagram info sync successfully.")
                    setSyncInfoLoading(false)
                }
            } else if (accountType === "facebookpage") {
                const response = await axios.post(`${url}facebook/sync/info/${selectedAccount.socialId}/${workspaceId}`, {
                    accessToken: selectedAccount.accessToken,
                    accountType: accountType
                })
                if (response.status === 200) {

                    setselectedAccount(response.data)
                    toast.success(`${accountType === "facebookpage" ? "Facebook Page" : "Facebook Group"} info sync successfully.`)
                    setSyncInfoLoading(false)
                }
            } else if (accountType === "linkedinpage") {
                const response = await axios.post(`${url}linkedin/sync/info/${selectedAccount.socialId}/${workspaceId}`, {
                    accessToken: selectedAccount.accessToken,
                    accountType: accountType
                })
                if (response.status === 200) {

                    setselectedAccount(response.data)
                    toast.success(`${accountType === "linkedinpage" ? "LinkedIn Page" : "LinkedIn Profile"} info sync successfully.`)
                    setSyncInfoLoading(false)
                }
            } else if (accountType === "pinterest") {
                const response = await axios.post(`${url}pinterest/sync/info/${selectedAccount.socialId}/${workspaceId}`, {
                    accessToken: selectedAccount.accessToken
                })
                if (response.status === 200) {

                    setselectedAccount(response.data)
                    toast.success("Pinterest info sync successfully.")
                    setSyncInfoLoading(false)
                }
            }
        } catch (error) {
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
        }


    }



    return (
        <div className={Styles.account_connecy_common_flow_main_container} >
            {loading ? <LoadingBall /> :
                <>
                    <div className={Styles.container} >


                        <div className={Styles.center_panel} >

                            {userNavigation.isConnectAccountPopup ?
                                <>


                                    <div className={Styles.select_to_connect} >
                                        <div className={Styles.header} >
                                            <div>
                                                <svg onClick={() => close()} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14 8.00016H2M2 8.00016L7.66667 2.3335M2 8.00016L7.66667 13.6668" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                Select To Connect
                                            </div>

                                            <button style={accountsToConnect.filter(data => data.active === true && data?.disabledCheckBox !== true).length === 0 ? { backgroundColor: '#e5e5e5', color: '#ffffff' } : {}} disabled={accountsToConnect.filter(data => data.active === true && data?.disabledCheckBox !== true).length === 0 ? true : false} onClick={() => saveTheInstagramData()} >
                                                {saveLoading ? <span></span> : "Save & Connect"}
                                            </button>



                                        </div>

                                        <div className={Styles.header_of_account_connect} >
                                            <div className={Styles.header_container_of_select} >
                                                <div>Sr. No.</div>
                                                <div>Account</div>
                                            </div>


                                            <div>Select</div>
                                        </div>
                                        <div style={{ overflow: 'scroll', width: '100%', height: '65vh' }} >
                                            {accountsToConnect.map((data, index) => {
                                                return (
                                                    <div style={{ width: '100%' }} key={index} >
                                                        <div style={{ cursor: 'pointer' }} onClick={() => { data.disabledCheckBox ? toast('already added that account') : toggle(index) }} className={Styles.content_value_container} >
                                                            <div className={Styles.content_container_select} >
                                                                <div>{index + 1}</div>
                                                                <div>
                                                                    <span>
                                                                        <img src={data.profileURL === undefined ? ProfileImage : data.profileURL} onError={(e) => e.target.src = ProfileImage} alt="" />
                                                                        {captionTruncate(data.name, 25)}
                                                                        {data.accountType === "linkedinpage" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="17.5" cy="17.5" r="17.5" fill="#0072B1" />
                                                                            <path d="M11.923 11.846C12.985 11.846 13.846 10.985 13.846 9.92299C13.846 8.86095 12.985 8 11.923 8C10.861 8 10 8.86095 10 9.92299C10 10.985 10.861 11.846 11.923 11.846Z" fill="white" />
                                                                            <path d="M15.6617 13.3027V23.9714H18.9742V18.6955C18.9742 17.3034 19.2362 15.9552 20.9623 15.9552C22.6647 15.9552 22.6858 17.5468 22.6858 18.7834V23.9723H26V18.1216C26 15.2477 25.3813 13.0391 22.0222 13.0391C20.4095 13.0391 19.3284 13.9241 18.8864 14.7617H18.8415V13.3027H15.6617ZM10.2637 13.3027H13.5814V23.9714H10.2637V13.3027Z" fill="white" />
                                                                        </svg>

                                                                        }

                                                                        {data.accountType === "instagram" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="17.5" cy="17.5" r="17.5" fill="#E1306C" />
                                                                            <path d="M26.948 13.3054C26.937 12.5484 26.797 11.7974 26.529 11.0894C26.06 9.88045 25.105 8.92445 23.896 8.45645C23.197 8.19345 22.458 8.05245 21.71 8.03645C20.748 7.99345 20.443 7.98145 18.001 7.98145C15.559 7.98145 15.246 7.98145 14.291 8.03645C13.544 8.05245 12.805 8.19345 12.106 8.45645C10.897 8.92445 9.94105 9.88045 9.47305 11.0894C9.21005 11.7884 9.06805 12.5274 9.05405 13.2744C9.01105 14.2374 8.99805 14.5424 8.99805 16.9844C8.99805 19.4264 8.99805 19.7384 9.05405 20.6944C9.06905 21.4424 9.21005 22.1804 9.47305 22.8814C9.94205 24.0894 10.897 25.0454 12.107 25.5134C12.803 25.7854 13.542 25.9394 14.292 25.9634C15.255 26.0064 15.56 26.0194 18.002 26.0194C20.444 26.0194 20.757 26.0194 21.712 25.9634C22.459 25.9484 23.198 25.8074 23.898 25.5444C25.107 25.0754 26.062 24.1194 26.531 22.9114C26.794 22.2114 26.935 21.4734 26.95 20.7244C26.993 19.7624 27.006 19.4574 27.006 17.0144C27.004 14.5724 27.004 14.2624 26.948 13.3054ZM17.995 21.6024C15.441 21.6024 13.372 19.5334 13.372 16.9794C13.372 14.4254 15.441 12.3564 17.995 12.3564C20.547 12.3564 22.618 14.4254 22.618 16.9794C22.618 19.5334 20.547 21.6024 17.995 21.6024ZM22.802 13.2634C22.205 13.2634 21.724 12.7814 21.724 12.1854C21.724 11.5894 22.205 11.1074 22.802 11.1074C23.397 11.1074 23.879 11.5894 23.879 12.1854C23.879 12.7814 23.397 13.2634 22.802 13.2634Z" fill="white" />
                                                                            <path d="M17.9952 19.9826C19.6537 19.9826 20.9982 18.6381 20.9982 16.9796C20.9982 15.3211 19.6537 13.9766 17.9952 13.9766C16.3367 13.9766 14.9922 15.3211 14.9922 16.9796C14.9922 18.6381 16.3367 19.9826 17.9952 19.9826Z" fill="white" />
                                                                        </svg>

                                                                        }

                                                                        {data.accountType === "twitter" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="17.5" cy="17.5" r="17.5" fill="#1DA1F2" />
                                                                            <path d="M24.4289 14.4807C24.4402 14.6339 24.4402 14.7861 24.4402 14.9384C24.4402 19.5977 20.8939 24.9668 14.4127 24.9668C12.416 24.9668 10.561 24.3884 9 23.3839C9.2835 23.4163 9.5565 23.4276 9.85137 23.4276C11.499 23.4276 13.0154 22.8711 14.2272 21.9217C12.6776 21.8894 11.3791 20.8744 10.9311 19.4779C11.149 19.5102 11.3677 19.5321 11.597 19.5321C11.9129 19.5321 12.2305 19.4884 12.5254 19.4122C10.9092 19.085 9.69912 17.6666 9.69912 15.9534V15.9096C10.169 16.1712 10.7141 16.3349 11.2916 16.3567C10.3422 15.725 9.72012 14.6444 9.72012 13.422C9.72012 12.7675 9.89425 12.1672 10.1996 11.644C11.9347 13.7816 14.5431 15.179 17.4674 15.3321C17.4131 15.0696 17.3799 14.7975 17.3799 14.5245C17.3799 12.582 18.9514 11 20.9044 11C21.9194 11 22.8355 11.4252 23.4795 12.113C24.2757 11.9599 25.0387 11.665 25.716 11.2616C25.4544 12.0797 24.897 12.7675 24.1664 13.2041C24.876 13.1271 25.5637 12.9311 26.1955 12.6581C25.716 13.3564 25.1166 13.9785 24.4289 14.4807Z" fill="white" />
                                                                        </svg>

                                                                        }

                                                                        {data.accountType === "facebookpage" &&
                                                                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <circle cx="17.5" cy="17.5" r="17.5" fill="#4267B2" />
                                                                                <path d="M19.616 26.9976V19.1918H22.2494L22.6408 16.1357H19.616V14.189C19.616 13.3071 19.8617 12.7033 21.1275 12.7033H22.7313V9.97852C21.9513 9.89471 21.1665 9.85471 20.3817 9.85756C18.0541 9.85756 16.456 11.2785 16.456 13.8871V16.1299H13.8398V19.1861H16.4617V26.9976H19.616Z" fill="white" />
                                                                            </svg>
                                                                        }

                                                                        {data.accountType === "pinterest" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="17.5" cy="17.5" r="17.5" fill="#D7143A" />
                                                                            <path d="M24.1227 10.152C22.7015 8.76429 20.7348 8 18.5848 8C15.3006 8 13.2806 9.36932 12.1644 10.518C10.7888 11.9336 10 13.8132 10 15.675C10 18.0126 10.9613 19.8068 12.5711 20.4743C12.6792 20.5193 12.7879 20.542 12.8945 20.542C13.2342 20.542 13.5033 20.316 13.5965 19.9534C13.6508 19.7454 13.7767 19.2323 13.8315 19.0095C13.9487 18.5696 13.854 18.358 13.5984 18.0517C13.1328 17.4913 12.916 16.8287 12.916 15.9664C12.916 13.4049 14.7912 10.6826 18.2666 10.6826C21.0243 10.6826 22.7373 12.2768 22.7373 14.843C22.7373 16.4624 22.3944 17.9621 21.7715 19.066C21.3386 19.8331 20.5775 20.7475 19.409 20.7475C18.9038 20.7475 18.4499 20.5364 18.1635 20.1683C17.893 19.8203 17.8038 19.3709 17.9126 18.9024C18.0355 18.3732 18.2031 17.8211 18.3654 17.2874C18.6612 16.3126 18.9409 15.3919 18.9409 14.6574C18.9409 13.401 18.1815 12.5567 17.0514 12.5567C15.6152 12.5567 14.49 14.0405 14.49 15.9346C14.49 16.8635 14.7328 17.5583 14.8426 17.8251C14.6617 18.6048 13.5864 23.2404 13.3824 24.1145C13.2645 24.6248 12.554 28.6552 13.73 28.9766C15.0512 29.3376 16.2321 25.4123 16.3524 24.9688C16.4498 24.608 16.7906 23.244 16.9995 22.4055C17.6373 23.0304 18.6643 23.4528 19.6635 23.4528C21.5473 23.4528 23.2414 22.5906 24.4339 21.0251C25.5903 19.5067 26.2273 17.3904 26.2273 15.0663C26.2272 13.2493 25.4601 11.4581 24.1227 10.152Z" fill="white" />
                                                                        </svg>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>


                                                            <div>
                                                                {data.active ? <svg style={{ cursor: 'pointer' }} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="0.5" width="14" height="14" rx="2" fill="#0078FF" />
                                                                    <path d="M3.41699 7.5835L5.75033 9.91683L11.5837 4.0835" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg> :
                                                                    <svg style={{ cursor: 'pointer' }} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="0.5" width="14" height="14" rx="2" fill="#E5E5E5" />
                                                                        <path d="M3.41699 7.5835L5.75033 9.91683L11.5837 4.0835" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>



                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={Styles.border_of_connect_flow} ></div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>







                                    </div>



                                </>
                                :
                                <>
                                    {showAccount ? <div className={Styles.setting_panel_container} >
                                        <div className={Styles.header} >
                                            <svg style={{ cursor: 'pointer' }} onClick={() => { setshowAccount(false); setselectedAccount({}) }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 8.00016H2M2 8.00016L7.66667 2.3335M2 8.00016L7.66667 13.6668" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className={Styles.profile} >
                                                <img alt="profile" onError={(e) => { e.target.src = ProfileImage }} src={selectedAccount.profileURL === undefined ? ProfileImage : selectedAccount.profileURL} />
                                                {selectedAccount.accountType === "linkedinpage" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#0072B1" />
                                                    <path d="M11.923 11.846C12.985 11.846 13.846 10.985 13.846 9.92299C13.846 8.86095 12.985 8 11.923 8C10.861 8 10 8.86095 10 9.92299C10 10.985 10.861 11.846 11.923 11.846Z" fill="white" />
                                                    <path d="M15.6617 13.3027V23.9714H18.9742V18.6955C18.9742 17.3034 19.2362 15.9552 20.9623 15.9552C22.6647 15.9552 22.6858 17.5468 22.6858 18.7834V23.9723H26V18.1216C26 15.2477 25.3813 13.0391 22.0222 13.0391C20.4095 13.0391 19.3284 13.9241 18.8864 14.7617H18.8415V13.3027H15.6617ZM10.2637 13.3027H13.5814V23.9714H10.2637V13.3027Z" fill="white" />
                                                </svg>

                                                }

                                                {selectedAccount.accountType === "instagram" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#E1306C" />
                                                    <path d="M26.948 13.3054C26.937 12.5484 26.797 11.7974 26.529 11.0894C26.06 9.88045 25.105 8.92445 23.896 8.45645C23.197 8.19345 22.458 8.05245 21.71 8.03645C20.748 7.99345 20.443 7.98145 18.001 7.98145C15.559 7.98145 15.246 7.98145 14.291 8.03645C13.544 8.05245 12.805 8.19345 12.106 8.45645C10.897 8.92445 9.94105 9.88045 9.47305 11.0894C9.21005 11.7884 9.06805 12.5274 9.05405 13.2744C9.01105 14.2374 8.99805 14.5424 8.99805 16.9844C8.99805 19.4264 8.99805 19.7384 9.05405 20.6944C9.06905 21.4424 9.21005 22.1804 9.47305 22.8814C9.94205 24.0894 10.897 25.0454 12.107 25.5134C12.803 25.7854 13.542 25.9394 14.292 25.9634C15.255 26.0064 15.56 26.0194 18.002 26.0194C20.444 26.0194 20.757 26.0194 21.712 25.9634C22.459 25.9484 23.198 25.8074 23.898 25.5444C25.107 25.0754 26.062 24.1194 26.531 22.9114C26.794 22.2114 26.935 21.4734 26.95 20.7244C26.993 19.7624 27.006 19.4574 27.006 17.0144C27.004 14.5724 27.004 14.2624 26.948 13.3054ZM17.995 21.6024C15.441 21.6024 13.372 19.5334 13.372 16.9794C13.372 14.4254 15.441 12.3564 17.995 12.3564C20.547 12.3564 22.618 14.4254 22.618 16.9794C22.618 19.5334 20.547 21.6024 17.995 21.6024ZM22.802 13.2634C22.205 13.2634 21.724 12.7814 21.724 12.1854C21.724 11.5894 22.205 11.1074 22.802 11.1074C23.397 11.1074 23.879 11.5894 23.879 12.1854C23.879 12.7814 23.397 13.2634 22.802 13.2634Z" fill="white" />
                                                    <path d="M17.9952 19.9826C19.6537 19.9826 20.9982 18.6381 20.9982 16.9796C20.9982 15.3211 19.6537 13.9766 17.9952 13.9766C16.3367 13.9766 14.9922 15.3211 14.9922 16.9796C14.9922 18.6381 16.3367 19.9826 17.9952 19.9826Z" fill="white" />
                                                </svg>

                                                }

                                                {selectedAccount.accountType === "twitter" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#1DA1F2" />
                                                    <path d="M24.4289 14.4807C24.4402 14.6339 24.4402 14.7861 24.4402 14.9384C24.4402 19.5977 20.8939 24.9668 14.4127 24.9668C12.416 24.9668 10.561 24.3884 9 23.3839C9.2835 23.4163 9.5565 23.4276 9.85137 23.4276C11.499 23.4276 13.0154 22.8711 14.2272 21.9217C12.6776 21.8894 11.3791 20.8744 10.9311 19.4779C11.149 19.5102 11.3677 19.5321 11.597 19.5321C11.9129 19.5321 12.2305 19.4884 12.5254 19.4122C10.9092 19.085 9.69912 17.6666 9.69912 15.9534V15.9096C10.169 16.1712 10.7141 16.3349 11.2916 16.3567C10.3422 15.725 9.72012 14.6444 9.72012 13.422C9.72012 12.7675 9.89425 12.1672 10.1996 11.644C11.9347 13.7816 14.5431 15.179 17.4674 15.3321C17.4131 15.0696 17.3799 14.7975 17.3799 14.5245C17.3799 12.582 18.9514 11 20.9044 11C21.9194 11 22.8355 11.4252 23.4795 12.113C24.2757 11.9599 25.0387 11.665 25.716 11.2616C25.4544 12.0797 24.897 12.7675 24.1664 13.2041C24.876 13.1271 25.5637 12.9311 26.1955 12.6581C25.716 13.3564 25.1166 13.9785 24.4289 14.4807Z" fill="white" />
                                                </svg>

                                                }

                                                {selectedAccount.accountType === "facebookpage" &&
                                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="17.5" cy="17.5" r="17.5" fill="#4267B2" />
                                                        <path d="M19.616 26.9976V19.1918H22.2494L22.6408 16.1357H19.616V14.189C19.616 13.3071 19.8617 12.7033 21.1275 12.7033H22.7313V9.97852C21.9513 9.89471 21.1665 9.85471 20.3817 9.85756C18.0541 9.85756 16.456 11.2785 16.456 13.8871V16.1299H13.8398V19.1861H16.4617V26.9976H19.616Z" fill="white" />
                                                    </svg>
                                                }

                                                {selectedAccount.accountType === "pinterest" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#D7143A" />
                                                    <path d="M24.1227 10.152C22.7015 8.76429 20.7348 8 18.5848 8C15.3006 8 13.2806 9.36932 12.1644 10.518C10.7888 11.9336 10 13.8132 10 15.675C10 18.0126 10.9613 19.8068 12.5711 20.4743C12.6792 20.5193 12.7879 20.542 12.8945 20.542C13.2342 20.542 13.5033 20.316 13.5965 19.9534C13.6508 19.7454 13.7767 19.2323 13.8315 19.0095C13.9487 18.5696 13.854 18.358 13.5984 18.0517C13.1328 17.4913 12.916 16.8287 12.916 15.9664C12.916 13.4049 14.7912 10.6826 18.2666 10.6826C21.0243 10.6826 22.7373 12.2768 22.7373 14.843C22.7373 16.4624 22.3944 17.9621 21.7715 19.066C21.3386 19.8331 20.5775 20.7475 19.409 20.7475C18.9038 20.7475 18.4499 20.5364 18.1635 20.1683C17.893 19.8203 17.8038 19.3709 17.9126 18.9024C18.0355 18.3732 18.2031 17.8211 18.3654 17.2874C18.6612 16.3126 18.9409 15.3919 18.9409 14.6574C18.9409 13.401 18.1815 12.5567 17.0514 12.5567C15.6152 12.5567 14.49 14.0405 14.49 15.9346C14.49 16.8635 14.7328 17.5583 14.8426 17.8251C14.6617 18.6048 13.5864 23.2404 13.3824 24.1145C13.2645 24.6248 12.554 28.6552 13.73 28.9766C15.0512 29.3376 16.2321 25.4123 16.3524 24.9688C16.4498 24.608 16.7906 23.244 16.9995 22.4055C17.6373 23.0304 18.6643 23.4528 19.6635 23.4528C21.5473 23.4528 23.2414 22.5906 24.4339 21.0251C25.5903 19.5067 26.2273 17.3904 26.2273 15.0663C26.2272 13.2493 25.4601 11.4581 24.1227 10.152Z" fill="white" />
                                                </svg>
                                                }
                                            </div>

                                            <h4>{selectedAccount.name}</h4>
                                        </div>

                                        <div className={Styles.profile_header_container} >
                                            <div className={Styles.profile_header} >
                                                <span>Profile picture</span>
                                                <p>The name and profile picture can be modified through the respective social media platform</p>
                                            </div>
                                            <div className={Styles.social_account_profile_container} >
                                                <img onError={(e) => { e.target.src = ProfileImage }} src={selectedAccount.profileURL === undefined ? ProfileImage : selectedAccount.profileURL} alt="profile" />
                                            </div>

                                        </div>

                                        <div className={Styles.profile_header_container} >
                                            <div className={Styles.profile_header} >
                                                <span>Reauthorize</span>
                                                <p>Did Creatosaurus - Analytics lose connection to this social account? Reauthorise and restore.</p>
                                            </div>

                                            <button onClick={() => reauthorize(selectedAccount.accountType, selectedAccount.socialId)} >Reauthorize</button>
                                        </div>

                                        <div className={Styles.profile_header_container} >
                                            <div className={Styles.profile_header} >
                                                <span>Sync info</span>
                                                <p>Sync this account's profile picture, and name in Creatosaurus - Analytics</p>
                                            </div>

                                            <button onClick={() => syncInfo(selectedAccount.accountType)} >{syncInfoLoading ? "Loading..." : "Sync Info"}</button>
                                        </div>

                                        <div className={Styles.remove_social_account} >
                                            <div className={Styles.remove_social_account_header} >
                                                <span>Remove social account</span>
                                                <p>This will delete from Creatosaurus - Analytics all the data related to it, such as scheduled posts, analytics, and other settings.</p>
                                            </div>

                                            <button onClick={() => { deleteUserConnectedAccounts(selectedAccount); setshowAccount(false); setselectedAccount(false); }} >Remove</button>
                                        </div>
                                    </div> :

                                        <div className={Styles.connected_account_container} >
                                            <div className={Styles.header_container} >
                                                <h4>Your Connected Social Accounts</h4>
                                            </div>

                                            {accountContext.accounts.length ?
                                                <>

                                                    <div className={Styles.header} >
                                                        <div>Sr. No.</div>
                                                        <div>Account</div>
                                                        <div>Status</div>
                                                        <div>Settings</div>
                                                        <div>Remove</div>
                                                    </div>
                                                    <div style={{ overflow: 'scroll', width: '100%', height: '65vh' }} >
                                                        {accountContext.accounts.map((data, index) => {
                                                            
                                                            return (
                                                                <div key={index} style={{ width: '100%' }} >
                                                                    <div className={Styles.content_value} >

                                                                        <div>{index + 1}</div>
                                                                        <div>
                                                                            <div  >
                                                                                <img src={data.profileURL === undefined ? ProfileImage : data.profileURL} alt="" onError={(e) => e.target.src = ProfileImage} />
                                                                                {data.accountType === "linkedinpage" && <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#0072B1" />
                                                                                    <path d="M11.923 11.846C12.985 11.846 13.846 10.985 13.846 9.92299C13.846 8.86095 12.985 8 11.923 8C10.861 8 10 8.86095 10 9.92299C10 10.985 10.861 11.846 11.923 11.846Z" fill="white" />
                                                                                    <path d="M15.6617 13.3027V23.9714H18.9742V18.6955C18.9742 17.3034 19.2362 15.9552 20.9623 15.9552C22.6647 15.9552 22.6858 17.5468 22.6858 18.7834V23.9723H26V18.1216C26 15.2477 25.3813 13.0391 22.0222 13.0391C20.4095 13.0391 19.3284 13.9241 18.8864 14.7617H18.8415V13.3027H15.6617ZM10.2637 13.3027H13.5814V23.9714H10.2637V13.3027Z" fill="white" />
                                                                                </svg>

                                                                                }

                                                                                {data.accountType === "instagram" && <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#E1306C" />
                                                                                    <path d="M26.948 13.3054C26.937 12.5484 26.797 11.7974 26.529 11.0894C26.06 9.88045 25.105 8.92445 23.896 8.45645C23.197 8.19345 22.458 8.05245 21.71 8.03645C20.748 7.99345 20.443 7.98145 18.001 7.98145C15.559 7.98145 15.246 7.98145 14.291 8.03645C13.544 8.05245 12.805 8.19345 12.106 8.45645C10.897 8.92445 9.94105 9.88045 9.47305 11.0894C9.21005 11.7884 9.06805 12.5274 9.05405 13.2744C9.01105 14.2374 8.99805 14.5424 8.99805 16.9844C8.99805 19.4264 8.99805 19.7384 9.05405 20.6944C9.06905 21.4424 9.21005 22.1804 9.47305 22.8814C9.94205 24.0894 10.897 25.0454 12.107 25.5134C12.803 25.7854 13.542 25.9394 14.292 25.9634C15.255 26.0064 15.56 26.0194 18.002 26.0194C20.444 26.0194 20.757 26.0194 21.712 25.9634C22.459 25.9484 23.198 25.8074 23.898 25.5444C25.107 25.0754 26.062 24.1194 26.531 22.9114C26.794 22.2114 26.935 21.4734 26.95 20.7244C26.993 19.7624 27.006 19.4574 27.006 17.0144C27.004 14.5724 27.004 14.2624 26.948 13.3054ZM17.995 21.6024C15.441 21.6024 13.372 19.5334 13.372 16.9794C13.372 14.4254 15.441 12.3564 17.995 12.3564C20.547 12.3564 22.618 14.4254 22.618 16.9794C22.618 19.5334 20.547 21.6024 17.995 21.6024ZM22.802 13.2634C22.205 13.2634 21.724 12.7814 21.724 12.1854C21.724 11.5894 22.205 11.1074 22.802 11.1074C23.397 11.1074 23.879 11.5894 23.879 12.1854C23.879 12.7814 23.397 13.2634 22.802 13.2634Z" fill="white" />
                                                                                    <path d="M17.9952 19.9826C19.6537 19.9826 20.9982 18.6381 20.9982 16.9796C20.9982 15.3211 19.6537 13.9766 17.9952 13.9766C16.3367 13.9766 14.9922 15.3211 14.9922 16.9796C14.9922 18.6381 16.3367 19.9826 17.9952 19.9826Z" fill="white" />
                                                                                </svg>

                                                                                }

                                                                                {data.accountType === "twitter" && <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#1DA1F2" />
                                                                                    <path d="M24.4289 14.4807C24.4402 14.6339 24.4402 14.7861 24.4402 14.9384C24.4402 19.5977 20.8939 24.9668 14.4127 24.9668C12.416 24.9668 10.561 24.3884 9 23.3839C9.2835 23.4163 9.5565 23.4276 9.85137 23.4276C11.499 23.4276 13.0154 22.8711 14.2272 21.9217C12.6776 21.8894 11.3791 20.8744 10.9311 19.4779C11.149 19.5102 11.3677 19.5321 11.597 19.5321C11.9129 19.5321 12.2305 19.4884 12.5254 19.4122C10.9092 19.085 9.69912 17.6666 9.69912 15.9534V15.9096C10.169 16.1712 10.7141 16.3349 11.2916 16.3567C10.3422 15.725 9.72012 14.6444 9.72012 13.422C9.72012 12.7675 9.89425 12.1672 10.1996 11.644C11.9347 13.7816 14.5431 15.179 17.4674 15.3321C17.4131 15.0696 17.3799 14.7975 17.3799 14.5245C17.3799 12.582 18.9514 11 20.9044 11C21.9194 11 22.8355 11.4252 23.4795 12.113C24.2757 11.9599 25.0387 11.665 25.716 11.2616C25.4544 12.0797 24.897 12.7675 24.1664 13.2041C24.876 13.1271 25.5637 12.9311 26.1955 12.6581C25.716 13.3564 25.1166 13.9785 24.4289 14.4807Z" fill="white" />
                                                                                </svg>

                                                                                }

                                                                                {data.accountType === "facebookpage" &&
                                                                                    <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <circle cx="17.5" cy="17.5" r="17.5" fill="#4267B2" />
                                                                                        <path d="M19.616 26.9976V19.1918H22.2494L22.6408 16.1357H19.616V14.189C19.616 13.3071 19.8617 12.7033 21.1275 12.7033H22.7313V9.97852C21.9513 9.89471 21.1665 9.85471 20.3817 9.85756C18.0541 9.85756 16.456 11.2785 16.456 13.8871V16.1299H13.8398V19.1861H16.4617V26.9976H19.616Z" fill="white" />
                                                                                    </svg>
                                                                                }

                                                                                {data.accountType === "pinterest" && <svg width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="17.5" cy="17.5" r="17.5" fill="#D7143A" />
                                                                                    <path d="M24.1227 10.152C22.7015 8.76429 20.7348 8 18.5848 8C15.3006 8 13.2806 9.36932 12.1644 10.518C10.7888 11.9336 10 13.8132 10 15.675C10 18.0126 10.9613 19.8068 12.5711 20.4743C12.6792 20.5193 12.7879 20.542 12.8945 20.542C13.2342 20.542 13.5033 20.316 13.5965 19.9534C13.6508 19.7454 13.7767 19.2323 13.8315 19.0095C13.9487 18.5696 13.854 18.358 13.5984 18.0517C13.1328 17.4913 12.916 16.8287 12.916 15.9664C12.916 13.4049 14.7912 10.6826 18.2666 10.6826C21.0243 10.6826 22.7373 12.2768 22.7373 14.843C22.7373 16.4624 22.3944 17.9621 21.7715 19.066C21.3386 19.8331 20.5775 20.7475 19.409 20.7475C18.9038 20.7475 18.4499 20.5364 18.1635 20.1683C17.893 19.8203 17.8038 19.3709 17.9126 18.9024C18.0355 18.3732 18.2031 17.8211 18.3654 17.2874C18.6612 16.3126 18.9409 15.3919 18.9409 14.6574C18.9409 13.401 18.1815 12.5567 17.0514 12.5567C15.6152 12.5567 14.49 14.0405 14.49 15.9346C14.49 16.8635 14.7328 17.5583 14.8426 17.8251C14.6617 18.6048 13.5864 23.2404 13.3824 24.1145C13.2645 24.6248 12.554 28.6552 13.73 28.9766C15.0512 29.3376 16.2321 25.4123 16.3524 24.9688C16.4498 24.608 16.7906 23.244 16.9995 22.4055C17.6373 23.0304 18.6643 23.4528 19.6635 23.4528C21.5473 23.4528 23.2414 22.5906 24.4339 21.0251C25.5903 19.5067 26.2273 17.3904 26.2273 15.0663C26.2272 13.2493 25.4601 11.4581 24.1227 10.152Z" fill="white" />
                                                                                </svg>
                                                                                }
                                                                            </div>



                                                                            {captionTruncate(data?.name, 12)}

                                                                        </div>
                                                                        
                                                                        {data.isAccountTokensExpired ? <div onClick={() => reauthorize(data.accountType, data.socialId)} className={Styles.column3} >Reauthorize</div> : <>{data.active ? <div  >Active</div> : <div onClick={() => reauthorize(data.accountType, data.socialId)} className={Styles.column3} >Reauthorize</div>} </>
                                                                        }
                                                                        <div>

                                                                            <svg onClick={() => { setshowAccount(true); setselectedAccount(data) }} className={Styles.setting_icon} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clipPath="url(#clip0_12116_1355)">
                                                                                    <path d="M8 10C9.1046 10 10 9.1046 10 8C10 6.8954 9.1046 6 8 6C6.8954 6 6 6.8954 6 8C6 9.1046 6.8954 10 8 10Z" stroke="#333333" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M13.0813 6.93043L12.3495 5.16336L13.333 4.00016L11.9997 2.66683L10.8428 3.65546L9.03821 2.91332L8.62321 1.3335H7.32034L6.89907 2.93425L5.13595 3.67747L3.99967 2.66683L2.66634 4.00016L3.63525 5.19273L2.91467 6.96436L1.33301 7.3335V8.66683L2.93375 9.10383L3.67684 10.8666L2.66634 12.0002L3.99967 13.3335L5.19378 12.3604L6.93101 13.075L7.33301 14.6668H8.66634L9.06934 13.0756L10.8364 12.3438C11.1309 12.5544 11.9997 13.3335 11.9997 13.3335L13.333 12.0002L12.3436 10.8331L13.0756 9.0655L14.6663 8.65163L14.6663 7.3335L13.0813 6.93043Z" stroke="#333333" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_12116_1355">
                                                                                        <rect width="16" height="16" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                        <div>
                                                                            {deleteLoading && selectForDelete === data.socialId ? <span></span> :
                                                                                <svg onClick={() => deleteUserConnectedAccounts(data)} className={Styles.delete_icon} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M15.3337 8L14.0037 15.5642C13.8916 16.2017 13.3378 16.6667 12.6905 16.6667H7.31015C6.66285 16.6667 6.10906 16.2017 5.99697 15.5642L4.66699 8" stroke="#333333" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M16 6.00016H12.25M12.25 6.00016V4.66683C12.25 3.93045 11.6531 3.3335 10.9167 3.3335H9.08333C8.34695 3.3335 7.75 3.93045 7.75 4.66683V6.00016M12.25 6.00016H7.75M4 6.00016H7.75" stroke="#333333" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </svg>
                                                                            }

                                                                        </div>


                                                                    </div>
                                                                    <div className={Styles.border_of_connect_flow} ></div>
                                                                </div>
                                                            )
                                                        })

                                                        }
                                                    </div>

                                                </> :
                                                <span className={Styles.no_account_available_header} >No accounts connected.</span>
                                            }



                                        </div>

                                    }
                                </>

                            }

                        </div>

                        <div className={Styles.right_panel} >

                            <div className={Styles.header_container} >
                                <h4>Connect New</h4>
                            </div>

                            {accounts.map((data, index) => {
                                return (
                                    <div key={index} onClick={() => { setshowAccount(false); setselectedAccount({}); login(data.accountType) }} className={Styles.account_list_item_container} >

                                        {data.icon}
                                        <span>{data.name}</span>

                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>

                    <div className={Styles.button_container} >
                        <button className={Styles.close_container} onClick={() => close()}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0007 9.99973L14.3695 14.3686M5.63184 14.3686L10.0007 9.99973L5.63184 14.3686ZM14.3695 5.63086L10.0007 9.99973L14.3695 5.63086ZM10.0007 9.99973L5.63184 5.63086L10.0007 9.99973Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </>
            }


        </div>
    )
}

export default AccountConnectCommonFlow