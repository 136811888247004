export const backend_url = 'https://api.app.creatosaurus.io/creatosaurus/'

//export const url = 'http://localhost:4006/cache/'
export const url = 'https://api.cache.creatosaurus.io/cache/'

export const socialInboxUrl = "https://api.inbox.creatosaurus.io/"

function generateRandomColor() {
    // Generate a random hexadecimal color code
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export function generateDistinctColors(numColors) {
    const colors = [];

    for (let i = 0; i < numColors; i++) {
        let color = generateRandomColor();

        // Ensure the generated color is distinct from existing colors
        while (colors.includes(color)) {
            color = generateRandomColor();
        }

        colors.push(color);
    }

    return colors;
}



