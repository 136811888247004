import React from 'react'
import Face from '../../assets/face.svg'
import Styles from './PremiumPopup.module.css'
import AudienceActivity from '../../assets/AudienceActivity.svg'
import LatestPost from '../../assets/latestPost.png'
import PostList from '../../assets/postList.png'
import StoryList from '../../assets/story.png'
import ProgressBar from '../../assets/progress.svg'
const PremiumPopup=(props)=>{
    const getBackGroundImageAsPerCardName=()=>{
        if(props.cardName === "audience"){
            return `url(${AudienceActivity})`
        }
        if(props.cardName === "latestpost"){
            return `url(${LatestPost})`
        }
        if(props.cardName === "postlist"){
            return `url(${PostList})`
        }
        if(props.cardName === "storylist"){
            return `url(${StoryList})`
        }
        if(props.cardName === "progressbar"){
            return `url(${ProgressBar})`
        }
    }
    return(
        <section className={Styles.premium_popup} >
            <section style={{backgroundImage:getBackGroundImageAsPerCardName()} } className={Styles.blur_background}></section>
            <img src={Face} alt="" />
            {props.cardName === "audience" ?
            <div className={Styles.header} >Analytics not included in your plan</div>:
            <span className={Styles.header} >Analytics not included in your plan</span>
            }
            
            <button onClick={()=>window.open('https://www.creatosaurus.io/pricing','_target')} >Upgrade your plan now</button>
            
        </section>
        
    )
}

export default PremiumPopup