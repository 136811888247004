
import GraphSkeleton from '../GraphSkeleton/GraphSkeleton'
import Styles from './PinterestContent.module.css'
export const PinterestContentSkeleton = ({ activeTab }) => {
    return (
        <div className={Styles.pinterest_content_skeleton_container} >
            <div className={Styles.card_container} >
                {[1, 2, 3, 4].map(() => {
                    return <div  >
                        <div className={Styles.card_content} >
                            <div className={`${Styles.svg_container} ${Styles.skeleton}`} ></div>
                            <div className={Styles.card_content_header} >
                                <div className={Styles.skeleton} ></div>
                                <div className={Styles.skeleton} ></div>
                            </div>

                            <div className={`${Styles.percentage_container} ${Styles.skeleton}`} >

                            </div>

                        </div>


                    </div>
                })

                }
            </div>

            <div className={Styles.graph_skeleton_container} >
                <GraphSkeleton />
                <div className={Styles.post_main_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.button_container} >


                        </div>
                    </div>
                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} ></span>
                            </div>
                        </div>

                        {[1, 2, 3].map(() => {

                            return (
                                <div className={Styles.post_item_container} >
                                    <div className={Styles.post_content_container} >
                                        <div className={Styles.post_content_img} >
                                            <img alt="" className={Styles.skeleton} />
                                        </div>
                                        <div className={Styles.post_content} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>
                                    </div>
                                    <div className={Styles.postOtherDetails} >
                                        <div className={Styles.skeleton} ></div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>

            <div className={Styles.graph_skeleton_container} >
                <GraphSkeleton />
                <GraphSkeleton />
            </div>

            <div className={Styles.post_graph_container} >
                {/*graph skeleton */}
                <GraphSkeleton Style={{ width: '98%' }} />
            </div>

            <div className={Styles.post_main_container} >
                <div className={Styles.header_container} >
                    <span className={Styles.skeleton} ></span>
                    <div className={Styles.button_container} >
                        {/* <button onClick={prevCount} >Prev</button> */}
                        <button className={Styles.skeleton} ></button>
                    </div>
                </div>
                <div className={Styles.post_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.postHeaderContainer} >
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} > </span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                        </div>
                    </div>

                    {[1, 2, 3].map(() => {

                        return (
                            <div className={Styles.post_item_container} >
                                <div className={Styles.post_content_container} >

                                    <div className={Styles.post_content_img} >
                                        <img alt="" className={Styles.skeleton} />
                                    </div>

                                    <div className={Styles.post_content} >
                                        <span className={Styles.skeleton} ></span>
                                        <p className={Styles.skeleton} ></p>
                                    </div>


                                </div>
                                <div className={Styles.postOtherDetails} >
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} > </div>
                                </div>
                            </div>
                        )
                    })

                    }

                </div>
            </div>

            <div className={Styles.post_main_container} >
                <div className={Styles.header_container} >
                    <span className={Styles.skeleton} ></span>
                    <div className={Styles.button_container} >
                        {/* <button onClick={prevCount} >Prev</button> */}
                        <button className={Styles.skeleton} ></button>
                    </div>
                </div>
                <div className={Styles.post_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.postHeaderContainer} >
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} > </span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                        </div>
                    </div>

                    {[1, 2, 3].map(() => {

                        return (
                            <div className={Styles.post_item_container} >
                                <div className={Styles.post_content_container} >

                                    <div className={Styles.post_content_img} >
                                        <img alt="" className={Styles.skeleton} />
                                    </div>

                                    <div className={Styles.post_content} >
                                        <span className={Styles.skeleton} ></span>
                                        <p className={Styles.skeleton} ></p>
                                    </div>


                                </div>
                                <div className={Styles.postOtherDetails} >
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} > </div>
                                </div>
                            </div>
                        )
                    })

                    }

                </div>
            </div>

            <div className={Styles.post_main_container} >
                <div className={Styles.header_container} >
                    <span className={Styles.skeleton} ></span>
                    <div className={Styles.button_container} >
                        {/* <button onClick={prevCount} >Prev</button> */}
                        <button className={Styles.skeleton} ></button>
                    </div>
                </div>
                <div className={Styles.post_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.postHeaderContainer} >
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} > </span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                        </div>
                    </div>

                    {[1, 2, 3].map(() => {

                        return (
                            <div className={Styles.post_item_container} >
                                <div className={Styles.post_content_container} >

                                    <div className={Styles.post_content_img} >
                                        <img alt="" className={Styles.skeleton} />
                                    </div>

                                    <div className={Styles.post_content} >
                                        <span className={Styles.skeleton} ></span>
                                        <p className={Styles.skeleton} ></p>
                                    </div>


                                </div>
                                <div className={Styles.postOtherDetails} >
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} > </div>
                                </div>
                            </div>
                        )
                    })

                    }

                </div>
            </div>






            <div style={{ marginBottom: 100 }} className={Styles.post_main_container} >
                <div className={Styles.header_container} >
                    <span className={Styles.skeleton} ></span>
                    <div className={Styles.button_container} >
                        {/* <button onClick={prevCount} >Prev</button> */}
                        <button className={Styles.skeleton} ></button>
                    </div>
                </div>
                <div className={Styles.post_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.postHeaderContainer} >
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} > </span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                            <span className={Styles.skeleton} ></span>
                        </div>
                    </div>
                    {[1, 2, 3].map(() => {

                        return (
                            <div className={Styles.post_item_container} >
                                <div className={Styles.post_content_container} >

                                    <div className={Styles.post_content_img} >
                                        <img alt="" className={Styles.skeleton} />
                                    </div>

                                    <div className={Styles.post_content} >
                                        <span className={Styles.skeleton} ></span>
                                        <p className={Styles.skeleton} ></p>
                                    </div>


                                </div>
                                <div className={Styles.postOtherDetails} >
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>

                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} > </div>
                                </div>
                            </div>
                        )
                    })

                    }

                </div>
            </div>






        </div>
    )
}