import React from 'react'
import Face from '../../assets/face.svg'
import Styles from './NoDataAvailablePopup.module.css'
import AudienceActivity from '../../assets/AudienceActivity.png'
import LatestPost from '../../assets/latestPost.png'
import PostList from '../../assets/postList.png'
import StoryList from '../../assets/story.png'
import ProgressBar from '../../assets/progress.svg'
import LineChartOfPost from '../../assets/linechartOfPost.png'
const NoDataAvailablePopup=({cardName,noOfPost,selectedPeriod})=>{
   
    const getBackGroundImageAsPerCardName=()=>{
        
        if(cardName === "lineChartPost"){
            return `url(${LineChartOfPost})`
        }
        if(cardName === "audience"){
            return `url(${AudienceActivity})`
        }
        if(cardName === "latestpost"){
            return `url(${LatestPost})`
        }
        if(cardName === "postlist"){
            return `url(${PostList})`
        }
        if(cardName === "storylist"){
            return `url(${StoryList})`
        }
        if(cardName === "progressbar"){
            return `url(${ProgressBar})`
        }
    }
    return(
        <section  className={Styles.no_data_available_popup} >
            <section style={{backgroundImage:getBackGroundImageAsPerCardName()} } className={Styles.blur_background}></section>
            <img src={Face} alt="" />
            <h4 className={Styles.header} >
            {noOfPost === 0 ? `You have not posted anything in last ${selectedPeriod} days.`:"Not enough data available"}    
                
            </h4>
            <a href="https://www.cache.creatosaurus.io/" className={Styles.post_new_content_for_analysis_button}   >Post new content for analysis</a>
            
        </section>
        
    )
}

export default NoDataAvailablePopup