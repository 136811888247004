import Styles from './LinkedinVisitorsSkeleton.module.css'
import {
    Chart as ChartJS,
    registerables
} from 'chart.js'
import Navbar from '../../Component/Navbar/navbar';
import ActiveSocialAccountSkeleton from '../ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton';
import { useContext } from 'react';
import { CreateLinkedinAnalyticContext } from '../../store/linkedinAnalytic';
ChartJS.register(
    ...registerables
)

const LinkedinVisitorsSkeleton = () => {
    const linkedinContext = useContext(CreateLinkedinAnalyticContext)
    return (
        <div className={Styles.linkedin_visitors_page_container} >


            <div className={Styles.visitors_container} >
                
                <div className={Styles.overview_content_main_container} >
                    <ActiveSocialAccountSkeleton selected={linkedinContext.selectedPeriod} />
                    <Navbar selected="31" active="visitors" />
                    <div className={Styles.overview_content_container} >
                        <div className={Styles.card_container} >
                            {[1, 2, 3, 4].map(() => {
                                return <div  >
                                    <div className={Styles.card_content} >
                                        <div className={`${Styles.svg_container} ${Styles.skeleton}`} ></div>
                                        <div className={Styles.card_content_header} >
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                        </div>

                                        <div className={`${Styles.percentage_container} ${Styles.skeleton}`} >

                                        </div>

                                    </div>


                                </div>
                            })

                            }

                        </div>

                        <div className={Styles.page_views_and_unique_visitors_container} >
                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>
                                <div className={`${Styles.line_chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                

                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                

                                <div className={`${Styles.oragnic_container} ${Styles.skeleton} `} >

                                </div>
                                
                            </div>

                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>
                                <div className={`${Styles.line_chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                               

                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                

                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                               
                            </div>


                        </div>

                        <div className={Styles.followers_location_container} >
                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>

                                <div className={`${Styles.line_chart_container} ${Styles.skeleton}`} ></div>

                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                

                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                               


                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                



                            </div>


                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>


                                {[1, 2, 3, 4, 5, 6, 7,8,9].map(() => {
                                    return (
                                        <div className={`${Styles.meter_bar_container} ${Styles.skeleton}`} >

                                        </div>
                                    )
                                })
                                }
                            </div>



                        </div>

                        <div className={Styles.page_views_and_unique_visitors_container} >

                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>

                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                                    return (
                                        <div className={`${Styles.meter_bar_container} ${Styles.skeleton}`} >

                                        </div>
                                    )
                                })
                                }

                            </div>

                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>


                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                                    return (
                                        <div className={`${Styles.meter_bar_container} ${Styles.skeleton}`} >

                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>

                        <div style={{ marginBottom: 120 }} className={Styles.page_views_and_unique_visitors_container} >

                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>


                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
                                    return (
                                        <div className={`${Styles.meter_bar_container} ${Styles.skeleton}`} >

                                        </div>
                                    )
                                })
                                }

                            </div>


                        </div>



                    </div>
                </div>


            </div>

        </div>
    )
}

export default LinkedinVisitorsSkeleton