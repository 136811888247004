import { createContext, useState } from 'react'
import axios from 'axios'
import { backend_url } from '../Util/constant'

export const createNavigationContext = createContext({
    
    active: 'overview',
    setActive: () => { },
    getInfo:() =>{},
    userData:null
})

export const CreateNavigationProvider = ({ children }) => {
    const [activeNav, setactiveNav] = useState('overview')
    const [userData,setuserData]=useState(null)

    

    const changeActiveNav = (nav) => {
        setactiveNav(nav)
    }

    const getInfo = async() => {
        try{
            if(userData !== null) return

        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const res = await axios.get(backend_url + '/user/info', config);
        setuserData(res.data)

        localStorage.setItem('organizationId', res.data.activeWorkspace._id);
        localStorage.setItem('organizationName', res.data.activeWorkspace.workspace_name);
        
        }catch(error){
            return error
        }
    }

    const context = {
        userData: userData,
        active: activeNav,
        setActive: changeActiveNav,
        getInfo:getInfo
    }

    return (
        <createNavigationContext.Provider value={context} >{children}</createNavigationContext.Provider>
    )

}

