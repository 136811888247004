import { Routes, Route, BrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./Page/ProctedRoutes";
import Home from "./Page/Home/Home";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShareReport from "./Page/ShareReport/ShareReport";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ProtectedRoutes> <Home/> </ProtectedRoutes>} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
