import { useContext } from 'react'
import { UserNavigationContext } from '../../store/NavigationProvider'
import Styles from './ActiveSocialAccount.module.css'
import ProfileImage from '../../assets/Profile.jpeg'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'

const ActiveSocialAccount = ({ onChangePeriod, selected, disableFilter, loading,page }) => {
    const userNavigation = useContext(UserNavigationContext)
    const accountContext = useContext(UserConnectedAccountContext)
    
    return (
        <div className={Styles.active_social_account_card_container} >
            <div className={Styles.account_container} >
                <div className={Styles.account_profile_container} >
                    <img alt="" onError={(e) => e.target.src = ProfileImage} src={(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.profileURL === undefined ? ProfileImage : (accountContext?.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.profileURL} />
                    {(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.accountType === "linkedinpage" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#0072B1" />
                        <path d="M11.923 11.846C12.985 11.846 13.846 10.985 13.846 9.92299C13.846 8.86095 12.985 8 11.923 8C10.861 8 10 8.86095 10 9.92299C10 10.985 10.861 11.846 11.923 11.846Z" fill="white" />
                        <path d="M15.6617 13.3027V23.9714H18.9742V18.6955C18.9742 17.3034 19.2362 15.9552 20.9623 15.9552C22.6647 15.9552 22.6858 17.5468 22.6858 18.7834V23.9723H26V18.1216C26 15.2477 25.3813 13.0391 22.0222 13.0391C20.4095 13.0391 19.3284 13.9241 18.8864 14.7617H18.8415V13.3027H15.6617ZM10.2637 13.3027H13.5814V23.9714H10.2637V13.3027Z" fill="white" />
                    </svg>

                    }

                    {(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.accountType === "instagram" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#E1306C" />
                        <path d="M26.948 13.3054C26.937 12.5484 26.797 11.7974 26.529 11.0894C26.06 9.88045 25.105 8.92445 23.896 8.45645C23.197 8.19345 22.458 8.05245 21.71 8.03645C20.748 7.99345 20.443 7.98145 18.001 7.98145C15.559 7.98145 15.246 7.98145 14.291 8.03645C13.544 8.05245 12.805 8.19345 12.106 8.45645C10.897 8.92445 9.94105 9.88045 9.47305 11.0894C9.21005 11.7884 9.06805 12.5274 9.05405 13.2744C9.01105 14.2374 8.99805 14.5424 8.99805 16.9844C8.99805 19.4264 8.99805 19.7384 9.05405 20.6944C9.06905 21.4424 9.21005 22.1804 9.47305 22.8814C9.94205 24.0894 10.897 25.0454 12.107 25.5134C12.803 25.7854 13.542 25.9394 14.292 25.9634C15.255 26.0064 15.56 26.0194 18.002 26.0194C20.444 26.0194 20.757 26.0194 21.712 25.9634C22.459 25.9484 23.198 25.8074 23.898 25.5444C25.107 25.0754 26.062 24.1194 26.531 22.9114C26.794 22.2114 26.935 21.4734 26.95 20.7244C26.993 19.7624 27.006 19.4574 27.006 17.0144C27.004 14.5724 27.004 14.2624 26.948 13.3054ZM17.995 21.6024C15.441 21.6024 13.372 19.5334 13.372 16.9794C13.372 14.4254 15.441 12.3564 17.995 12.3564C20.547 12.3564 22.618 14.4254 22.618 16.9794C22.618 19.5334 20.547 21.6024 17.995 21.6024ZM22.802 13.2634C22.205 13.2634 21.724 12.7814 21.724 12.1854C21.724 11.5894 22.205 11.1074 22.802 11.1074C23.397 11.1074 23.879 11.5894 23.879 12.1854C23.879 12.7814 23.397 13.2634 22.802 13.2634Z" fill="white" />
                        <path d="M17.9952 19.9826C19.6537 19.9826 20.9982 18.6381 20.9982 16.9796C20.9982 15.3211 19.6537 13.9766 17.9952 13.9766C16.3367 13.9766 14.9922 15.3211 14.9922 16.9796C14.9922 18.6381 16.3367 19.9826 17.9952 19.9826Z" fill="white" />
                    </svg>

                    }

                    {(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.accountType === "twitter" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#1DA1F2" />
                        <path d="M24.4289 14.4807C24.4402 14.6339 24.4402 14.7861 24.4402 14.9384C24.4402 19.5977 20.8939 24.9668 14.4127 24.9668C12.416 24.9668 10.561 24.3884 9 23.3839C9.2835 23.4163 9.5565 23.4276 9.85137 23.4276C11.499 23.4276 13.0154 22.8711 14.2272 21.9217C12.6776 21.8894 11.3791 20.8744 10.9311 19.4779C11.149 19.5102 11.3677 19.5321 11.597 19.5321C11.9129 19.5321 12.2305 19.4884 12.5254 19.4122C10.9092 19.085 9.69912 17.6666 9.69912 15.9534V15.9096C10.169 16.1712 10.7141 16.3349 11.2916 16.3567C10.3422 15.725 9.72012 14.6444 9.72012 13.422C9.72012 12.7675 9.89425 12.1672 10.1996 11.644C11.9347 13.7816 14.5431 15.179 17.4674 15.3321C17.4131 15.0696 17.3799 14.7975 17.3799 14.5245C17.3799 12.582 18.9514 11 20.9044 11C21.9194 11 22.8355 11.4252 23.4795 12.113C24.2757 11.9599 25.0387 11.665 25.716 11.2616C25.4544 12.0797 24.897 12.7675 24.1664 13.2041C24.876 13.1271 25.5637 12.9311 26.1955 12.6581C25.716 13.3564 25.1166 13.9785 24.4289 14.4807Z" fill="white" />
                    </svg>

                    }

                    {(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.accountType === "facebookpage" &&
                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17.5" cy="17.5" r="17.5" fill="#4267B2" />
                            <path d="M19.616 26.9976V19.1918H22.2494L22.6408 16.1357H19.616V14.189C19.616 13.3071 19.8617 12.7033 21.1275 12.7033H22.7313V9.97852C21.9513 9.89471 21.1665 9.85471 20.3817 9.85756C18.0541 9.85756 16.456 11.2785 16.456 13.8871V16.1299H13.8398V19.1861H16.4617V26.9976H19.616Z" fill="white" />
                        </svg>
                    }

                    {(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.accountType === "pinterest" && <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#D7143A" />
                        <path d="M24.1227 10.152C22.7015 8.76429 20.7348 8 18.5848 8C15.3006 8 13.2806 9.36932 12.1644 10.518C10.7888 11.9336 10 13.8132 10 15.675C10 18.0126 10.9613 19.8068 12.5711 20.4743C12.6792 20.5193 12.7879 20.542 12.8945 20.542C13.2342 20.542 13.5033 20.316 13.5965 19.9534C13.6508 19.7454 13.7767 19.2323 13.8315 19.0095C13.9487 18.5696 13.854 18.358 13.5984 18.0517C13.1328 17.4913 12.916 16.8287 12.916 15.9664C12.916 13.4049 14.7912 10.6826 18.2666 10.6826C21.0243 10.6826 22.7373 12.2768 22.7373 14.843C22.7373 16.4624 22.3944 17.9621 21.7715 19.066C21.3386 19.8331 20.5775 20.7475 19.409 20.7475C18.9038 20.7475 18.4499 20.5364 18.1635 20.1683C17.893 19.8203 17.8038 19.3709 17.9126 18.9024C18.0355 18.3732 18.2031 17.8211 18.3654 17.2874C18.6612 16.3126 18.9409 15.3919 18.9409 14.6574C18.9409 13.401 18.1815 12.5567 17.0514 12.5567C15.6152 12.5567 14.49 14.0405 14.49 15.9346C14.49 16.8635 14.7328 17.5583 14.8426 17.8251C14.6617 18.6048 13.5864 23.2404 13.3824 24.1145C13.2645 24.6248 12.554 28.6552 13.73 28.9766C15.0512 29.3376 16.2321 25.4123 16.3524 24.9688C16.4498 24.608 16.7906 23.244 16.9995 22.4055C17.6373 23.0304 18.6643 23.4528 19.6635 23.4528C21.5473 23.4528 23.2414 22.5906 24.4339 21.0251C25.5903 19.5067 26.2273 17.3904 26.2273 15.0663C26.2272 13.2493 25.4601 11.4581 24.1227 10.152Z" fill="white" />
                    </svg>
                    }
                </div>

                <h4>{(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.name}</h4>
               
            </div>

            <div className={Styles.account_post_count_container} >
                <span>{accountContext?.socialAccountCount?.scheduleCount}&nbsp;Scheduled</span>
                <span>{accountContext?.socialAccountCount?.historyCount}&nbsp;Published</span>
                <span>{accountContext?.socialAccountCount?.failedCount}&nbsp;Failed</span>
            </div>
            {disableFilter === false || loading ? <div className={Styles.need_help_container} >
                <select style={{display:'none'}} onChange={(e) => onChangePeriod(e.target.value)} value={selected} >

                    <option value={page === "facebookpage"  || page === "linkedinpage" || page ==="pinterest" ? "31" : "30"} >last 30 days</option>
                    <option value="60" >last 60 days</option>
                    <option value="90" >last 90 days</option>
                </select>

            </div> :
                <div className={Styles.need_help_container} >
                    <select onChange={(e) => onChangePeriod(e.target.value)} value={selected} >

                        <option value={page === "facebookpage"  || page === "linkedinpage" || page ==="pinterest" ? "31" : "30"} >last 30 days</option>
                        <option value="60" >last 60 days</option>
                        <option value="90" >last 90 days</option>
                    </select>

                </div>
            }


        </div>
    )
}

export default ActiveSocialAccount