import { useContext, useState } from 'react'
import Styles from './FacebookAccount.module.css'
import { InstagramAccountSkeleton } from '../../Component/InstagramAccountSkeleton/InstagramAccountSkeleton'
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics'
import FacebookNavbar from '../../Component/FacebookNavbar/FacebookNavbar'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import ProgressBarCustomComponent from '../../Component/ProgressBarCustomComponent/ProgressBarCustomComponent'
import AllFollowerLocationModel from '../../Component/AllFollowerLocationModel/AllFollowerLocationModel'
import { country } from '../../Util/Country'
import { isoLanguageCodes } from '../../Util/LanguageCode'
import { formatNumber } from '../../Util/helper'

import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount'
import BlogListCard from '../../Component/BlogListCard/BlogListCard'
import ActiveSocialAccountSkeleton from '../../Component/ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton'
import { UserNavigationContext } from '../../store/NavigationProvider'

import AllAudienceNoData from '../../Component/AllAudienceNoData/AllAudienceNoData'
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent'

const FacebookAccount = () => {
    const facebookContext = useContext(CreateFacebookAnalyticContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [isFollowerLocation, setisFollowerLocation] = useState(false)
    const [isTopCity, setisTopCity] = useState(false)
    const [isTopLanguage, setisTopLanguage] = useState(false)


    const sorter = {
        "Sunday": 0, // << if sunday is first day of week
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6,
    }



    const data = facebookContext?.audienceStats?.filter(data => data.name === "page_fans_country")?.map(data => data?.values[0]?.value)[0];

    // Convert the data into an array of objects for easier sorting

    const dataArray = (data !== undefined ? Object?.keys(data)?.length ? Object?.entries(data)?.map(([country, value]) => ({ country, value })) : [] : [])

    // Sort the array in descending order based on the 'value' property
    dataArray?.sort((a, b) => b?.value - a?.value);

    // Get the top 5 countries
    const top5Countries = dataArray?.slice(0, 5);


    const dataOfCity = facebookContext?.audienceStats?.filter(data => data.name === "page_fans_city")?.map(data => data?.values[0]?.value)[0];

    // Convert the data into an array of objects for easier sorting
    const dataArrayCity = (dataOfCity !== undefined ? Object?.keys(dataOfCity)?.length ? Object?.entries(dataOfCity)?.map(([country, value]) => ({ country, value })) : [] : [])

    // Sort the array in descending order based on the 'value' property
    dataArrayCity?.sort((a, b) => b?.value - a?.value);

    // Get the top 5 countries
    const top5City = dataArrayCity?.slice(0, 7);

    const dataOfLanguage = facebookContext?.audienceStats?.filter(data => data.name === "page_fans_locale")?.map(data => data?.values[0]?.value)[0];

    // Convert the data into an array of objects for easier sorting
    const dataArrayLanguage = (dataOfLanguage !== undefined ? Object?.keys(dataOfLanguage)?.length ? Object?.entries(dataOfLanguage)?.map(([country, value]) => ({ country, value })) : [] : [])

    // Sort the array in descending order based on the 'value' property
    dataArrayLanguage?.sort((a, b) => b?.value - a?.value);





    dataArrayLanguage?.forEach(data => {

        const languageCode = data.country.split('_')[0]
        const countryCode = data.country.split('_')[1]

        // Assuming you have a 'isoLanguages' object like the one I provided earlier.
        const language = isoLanguageCodes[languageCode];
        const countryData = country.filter(data => data.code2 === countryCode).map(data => data.name)[0];
        data.country = countryData
        data.language = language


    })

    const top5Language = dataArrayLanguage?.slice(0, 6);

    const dateOfGender = facebookContext?.audienceStats?.filter(data => data.name === "page_fans_gender_age")?.map(data => data?.values[0]?.value)[0];

    const result = [];

    for (const key in dateOfGender) {
        const [gender, age] = key?.split('.');
        const existingEntry = result?.find(item => item.label === age);

        if (existingEntry) {
            existingEntry[`${gender}_count`] = dateOfGender[key];
        } else {
            const newItem = {
                label: age,
                [`${gender}_count`]: dateOfGender[key]
            };
            result?.push(newItem);
        }
    }


    const getCountryCode = (code) => {
        return country?.filter(data => data.code2 === code)?.map(data => data.code3)[0]
    }




    const barChartData = {
        labels: top5Countries?.map(data => getCountryCode(data.country)),
        datasets: [{
            label: 'Follower location',
            data: top5Countries?.map(data => data?.value),
            fill: false,
            backgroundColor: '#0078FF',
        },

        ]
    };

    function getDayName(dateStr, locale) {
        var date = new Date(dateStr);
        return date?.toLocaleDateString(locale, { weekday: 'long' });
    }

    const barChartDataAge = {
        labels: result?.map(data => data.label),
        datasets: [{
            label: 'Female Follower Count',
            data: result?.map(data => data.F_count),
            fill: false,
            backgroundColor: ' #FF43CA',
        },
        {
            label: 'Male Follower Count',
            data: result?.map(data => data.M_count),
            fill: false,
            backgroundColor: '#0078FF',
        },
        {
            //U_count
            label: 'Others Follower Count',
            data: result?.map(data => data.U_count),
            fill: false,
            backgroundColor: '#98AEBC',
        }
        ]
    };



    const pieChartDataAge = {
        labels: ['Female', 'Male', 'Others'],
        datasets: [{
            label: 'Follower Count',
            data: [result?.map(data => data.F_count || 0).reduce((totalFollower, data) => { return totalFollower += data }, 0), result.map(data => data.M_count).reduce((totalFollower, data) => { return totalFollower += data }, 0), result.map(data => data.U_count || 0).reduce((totalFollower, data) => { return totalFollower += data }, 0)],
            fill: false,
            backgroundColor: [' #FF43CA', '#0078FF', '#98AEBC'],
        }
        ]
    };

    const getBackGroundColor = (online_follower_percentage) => {
        if (online_follower_percentage >= 0 && online_follower_percentage <= 15)
            return '#e6f2ff'

        if (online_follower_percentage >= 16 && online_follower_percentage <= 30)
            return '#99c9ff';

        if (online_follower_percentage >= 31 && online_follower_percentage <= 45)
            return '#4da1ff'

        if (online_follower_percentage >= 46 && online_follower_percentage <= 60)
            return '#0078ff'

        if (online_follower_percentage >= 61 && online_follower_percentage <= 75)
            return '#0060cc';

        if (online_follower_percentage >= 76 && online_follower_percentage <= 90)
            return '#004899'

        if (online_follower_percentage >= 91 && online_follower_percentage <= 100)
            return '#003066'

    }




    function getRangeValues(input, range, color) {
        const { value } = input;
        const [startHour, endHour] = range?.split('-').map(Number);

        const output = [];
        for (let i = startHour; i < endHour; i++) {
            const key = `${i}-${i + 1}`;
            const val = value[i];
            output.push({ [key]: val });
        }

        return output.map((data, index) => {

            return (
                <span key={index} style={{ display: 'flex', alignItems: 'center', gap: 5 }}><span style={{ backgroundColor: color }} className={Styles.circle} ></span> {Object.keys(data)[0] === "0-1" ? "12-1" : Object.keys(data)}: {Object.values(data)[0] || 0} followers <br /></span>
            )
        });
    }




    return (
        <div className={Styles.facebook_account_main_container} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {isFollowerLocation ? <AllFollowerLocationModel page="facebook" title="Likes Location" totalFollower={facebookContext.overviewStats?.likes} data={dataArray} onClickClose={() => setisFollowerLocation(false)} /> : <></>}
            {isTopCity ? <AllFollowerLocationModel page="facebook" title="Top cities" totalFollower={facebookContext.overviewStats?.likes} data={dataArrayCity} onClickClose={() => setisTopCity(false)} /> : <></>}
            {isTopLanguage ? <AllFollowerLocationModel page="facebook" title="Top Language" totalFollower={facebookContext.overviewStats?.likes || 0} data={dataArrayLanguage} onClickClose={() => setisTopLanguage(false)} /> : <></>}
            <div className={Styles.account_container} >
                
                <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.account_content_container} >

                    {facebookContext.accountPageLoading ? <ActiveSocialAccountSkeleton selected={facebookContext.selectedPeriod} /> : <ActiveSocialAccount page="facebookpage" disableFilter={false} loading={facebookContext.accountPageLoading} selected={facebookContext.selectedPeriod === 30 ? (facebookContext.selectedPeriod + 1) : facebookContext.selectedPeriod} onChangePeriod={(value) => { facebookContext.handleSelectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "facebookpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); facebookContext.setfilterPeriod(true) }} />}

                    <FacebookNavbar hideFilter="true" active="account" />
                    {facebookContext.accountPageLoading ? <InstagramAccountSkeleton /> :


                        <div className={Styles.account_content_card_container} >
                            {facebookContext.overviewStats?.likes < 100 && facebookContext.accountPageLoading === false ? <BlogListCard type="facebook" /> : <></>}

                            <div className={Styles.audience_activity} >
                                <label>Audience Activity</label>
                                <span>Gain insight into the times your followers are active online in order to optimize your posting schedule.</span>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                    <div className={Styles.audience_activity_card_container} >

                                        {facebookContext?.onlineFansLastWeek?.sort(function sortByDay(a, b) {
                                            let day1 = getDayName(a.date);
                                            let day2 = getDayName(b.date);
                                            return sorter[day1] - sorter[day2];
                                        })?.length ? <>
                                            {facebookContext?.onlineFansLastWeek?.sort(function sortByDay(a, b) {
                                                let day1 = getDayName(a.date);
                                                let day2 = getDayName(b.date);
                                                return sorter[day1] - sorter[day2];
                                            })?.map((data, index) => {

                                                return (
                                                    <div key={index} className={Styles.audience_activity_card} >
                                                        <div className={Styles.day_name_container} >
                                                            <span>{getDayName(data.date)}</span>

                                                            <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line y1="1" x2="10" y2="1" stroke="#808080" />
                                                            </svg>

                                                        </div>

                                                        <div className={Styles.activity_value_container} >
                                                            {data?.value?.map((values) => {

                                                                return <div className={Styles.value_header_container} >
                                                                    <div style={{ backgroundColor: getBackGroundColor(parseFloat((Object?.values(values) / facebookContext?.overviewStats?.likes) * 100).toFixed(0)), border: `${Math.max(...data?.value?.map(valueItem => Object?.values(valueItem))) === Object?.values(values)[0] ? '3px solid #ff43ca' : 'none'}` }} className={Styles.value_container} >
                                                                        <div className={Styles.hover_card_container} >
                                                                            <label>Total: <b>{Object?.values(values)}</b> followers</label>
                                                                            <div className={Styles.hover_sub_container} >

                                                                                <p>{getRangeValues(facebookContext.onlineFansResponse.filter(item => item.end_time === data.date)[0], Object?.keys(values)[0] === '12-2' ? '0-2' : Object?.keys(values)[0], getBackGroundColor(parseFloat((Object?.values(values) / facebookContext?.overviewStats?.likes) * 100).toFixed(0)))}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {index === facebookContext.onlineFansLastWeek.length - 1 ? <span>{Object?.keys(values)}</span> : <></>}

                                                                </div>
                                                            })

                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })

                                            }
                                        </> : <AllAudienceNoData />

                                        }


                                    </div>
                                </> : <PremiumPopup cardName="audience" />

                                }


                            </div>

                            <div className={Styles.follower_location_and_top_cities_container} >
                                <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} onClickSubHeader={() => setisFollowerLocation(true)} type="bar" header="Likes Location" subheader="Show All" contentArray={[{ "header": "Major Country Likes", value: formatNumber(top5Countries[0]?.value) }, { "header": "Total Major Countries", value: data?.length ? formatNumber(Object?.keys(data)?.length) : 0 }, { "header": "Top 5 Countries Contribution", value: formatNumber(top5Countries?.reduce((totalFollower, data) => { return totalFollower += data?.value }, 0)) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: 0,
                                    scales: {
                                        x: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={barChartData} />

                                <ProgressBarCustomComponent subHeader="true" onClickShowAll={() => setisTopCity(true)} page="instagram" header="Top Cities" type="top city" progressBarArray={top5City} totalFollowers={facebookContext.overviewStats?.likes} />
                            </div>

                            <div className={Styles.follower_top_language_and_age_container} >
                                <ProgressBarCustomComponent subHeader="Show all" onClickShowAll={() => setisTopLanguage(true)} page="instagram" header="Top Language" type="top language" progressBarArray={top5Language} totalFollowers={facebookContext.overviewStats?.likes} />
                                <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="bar" header="Age" contentArray={[{ "header": "Male", type: "solid", indicator: "#0078FF", value: `${formatNumber(result?.filter(data => data?.hasOwnProperty('M_count'))?.map(data => data.M_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0))} (${parseFloat((result?.filter(data => data?.hasOwnProperty('M_count'))?.map(data => data.M_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0) / facebookContext.overviewStats?.likes) * 100).toFixed(2)}%)` }, { "header": "Female", type: "solid", indicator: "#FF43CA", value: `${formatNumber(result?.filter(data => data?.hasOwnProperty('F_count'))?.map(data => data.F_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0))} (${parseFloat((result?.filter(data => data.hasOwnProperty('F_count'))?.map(data => data.F_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0) / facebookContext.overviewStats?.likes) * 100).toFixed(2)}%)` }, { "header": "Others", type: "solid", indicator: "#98AEBC", value: `${formatNumber(result?.filter(data => data?.hasOwnProperty('U_count'))?.map(data => data.U_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0))} (${parseFloat((result?.filter(data => data.hasOwnProperty('U_count'))?.map(data => data.U_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0) / facebookContext.overviewStats?.likes) * 100).toFixed(2)}%)` }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: 0,
                                    scales: {
                                        x: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={barChartDataAge} />
                            </div>

                            <div className={Styles.gender_pie_chart_container} >
                                <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Gender" contentArray={[{ "header": "Male", type: "solid", indicator: "#0078FF", value: `${parseFloat((result?.filter(data => data?.hasOwnProperty('M_count')).map(data => data.M_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0) / facebookContext.overviewStats?.likes) * 100).toFixed(2)}%` }, { "header": "Female", type: "solid", indicator: "#FF43CA", value: `${parseFloat((result?.filter(data => data?.hasOwnProperty('F_count'))?.map(data => data.F_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0) / facebookContext.overviewStats?.likes) * 100).toFixed(2)}%` }, { "header": "Others", type: "solid", indicator: "#98AEBC", value: `${parseFloat((result?.filter(data => data?.hasOwnProperty('U_count'))?.map(data => data.U_count)?.reduce((totalFollower, data) => { return totalFollower += data }, 0) / facebookContext.overviewStats?.likes) * 100).toFixed(2)}%` }]} options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            display: false,
                                            grid: {
                                                display: false
                                            },

                                        },
                                        y: {
                                            display: false,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={pieChartDataAge} />
                                <div style={{ backgroundColor: '#f8f8f8' }} ></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FacebookAccount