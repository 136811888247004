import Styles from './LinkedinContentSkeleton.module.css'
import {
    Chart as ChartJS,
    registerables
} from 'chart.js'
import Navbar from '../../Component/Navbar/navbar';
import ActiveSocialAccountSkeleton from '../ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton';
import { useContext } from 'react';
import { CreateLinkedinAnalyticContext } from '../../store/linkedinAnalytic';
import GraphSkeleton from '../GraphSkeleton/GraphSkeleton';
ChartJS.register(
    ...registerables
)

const LinkedinContentSkeleton = () => {

    const linkedinContext = useContext(CreateLinkedinAnalyticContext)
    return (
        <div className={Styles.linkedin_visitors_page_container} >


            <div className={Styles.visitors_container} >

                <div className={Styles.overview_content_main_container} >
                    <ActiveSocialAccountSkeleton selected={linkedinContext.selectedPeriod} />
                    <Navbar selected="31" active="content" />
                    <div className={Styles.overview_content_container} >

                        <div className={Styles.card_container} >
                            {[1, 2, 3, 4].map(() => {
                                return <div  >
                                    <div className={Styles.card_content} >
                                        <div className={`${Styles.svg_container} ${Styles.skeleton}`} ></div>
                                        <div className={Styles.card_content_header} >
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                        </div>

                                        <div className={`${Styles.percentage_container} ${Styles.skeleton}`} >

                                        </div>

                                    </div>


                                </div>
                            })

                            }
                        </div>

                        <div className={Styles.page_views_and_unique_visitors_container} >
                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>

                                <div className={` ${Styles.chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>

                            </div>

                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>

                                <div className={` ${Styles.chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>

                            </div>


                        </div>


                        <div className={Styles.page_views_and_unique_visitors_container} >
                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>

                                <div className={` ${Styles.chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>

                            </div>

                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>

                                <div className={` ${Styles.chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>

                            </div>


                        </div>

                        <div className={Styles.post_container} >
                            <div className={Styles.header_container} >
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} > </span>
                            </div>
                            {[1, 2, 3]?.map((data) => {

                                return (
                                    <div className={Styles.post_item_container} >
                                        <div className={Styles.post_content_container} >
                                            <div className={`${Styles.post_content_img} ${Styles.skeleton}`} >


                                            </div>

                                            <div className={Styles.post_content} >
                                                <span className={Styles.skeleton} ></span>
                                                <p className={Styles.skeleton} ></p>
                                            </div>
                                        </div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>

                                    </div>
                                )
                            })

                            }
                        </div>

                        <div className={Styles.post_container} >
                            <div className={Styles.header_container} >
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} > </span>
                            </div>
                            {[1, 2, 3]?.map((data) => {

                                return (
                                    <div className={Styles.post_item_container} >
                                        <div className={Styles.post_content_container} >
                                            <div className={`${Styles.post_content_img} ${Styles.skeleton}`} >


                                            </div>

                                            <div className={Styles.post_content} >
                                                <span className={Styles.skeleton} ></span>
                                                <p className={Styles.skeleton} ></p>
                                            </div>
                                        </div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>

                                    </div>
                                )
                            })

                            }





                        </div>

                        <div className={Styles.graph_skeleton_container} >
                            <GraphSkeleton />
                            <div className={Styles.post_main_container} >
                                <div className={Styles.header_container} >
                                    <span className={Styles.skeleton} ></span>
                                    <div className={Styles.button_container} >


                                    </div>
                                </div>
                                <div className={Styles.post_container} >
                                    <div className={Styles.header_container} >
                                        <span className={Styles.skeleton} ></span>
                                        <div className={Styles.postHeaderContainer} >
                                            <span className={Styles.skeleton} ></span>
                                        </div>
                                    </div>

                                    {[1, 2, 3].map(() => {

                                        return (
                                            <div className={Styles.post_item_container} >
                                                <div className={Styles.post_content_container} >
                                                    <div className={Styles.post_content_img} >
                                                        <img alt="" className={Styles.skeleton} />
                                                    </div>
                                                    <div className={Styles.post_content} >
                                                        <span className={Styles.skeleton} ></span>
                                                        <p className={Styles.skeleton} ></p>
                                                    </div>
                                                </div>
                                                <div className={Styles.postOtherDetails} >
                                                    <div className={Styles.skeleton} ></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{marginBottom:120}} className={Styles.page_views_and_unique_visitors_container} >
                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>

                                <div className={` ${Styles.chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>

                            </div>

                            <div>
                                <div className={`${Styles.header} ${Styles.skeleton}`} >

                                </div>

                                <div className={` ${Styles.chart_container} ${Styles.skeleton}`} ></div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>

                            </div>


                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default LinkedinContentSkeleton