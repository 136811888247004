import { useContext } from 'react'
import Styles from './Dashboard.module.css'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import InstagramOverviewSkeleton from '../../Component/InstagramOverviewSkeleton/InstagramOverviewSkeleton'
import InstagramNavbar from '../../Component/InstagramNavbar/InstagramNavbar'
import OverviewCard from '../../Component/OverviewCard/overviewcard'
import Next from '../../assets/nextArrow.svg'
import Previous from '../../assets/backArrow.svg'
import ProfileImage from '../../assets/Profile.jpeg'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import ConnectAccountSocialPopup from '../../Component/ConnectAccountSocialPopup/ConnectAccountSocialPopup'
export const Dashboard = () => {
    const accountContext = useContext(UserConnectedAccountContext)
    
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const data = {
        labels: ['6-jan', '7-jan', '8-jan', '9-jan', '10-jan'],
        datasets: [{
            label: 'Follower oraganic count',
            data: [1, 5, 8, 2, 0],
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',

        }
        ]
    };

    const options = {
        interaction: {
            intersect: false,
            mode: "x"
        },
        radius: 0,
        scales: {
            x: {
                type: 'category',
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false
                }
            },
        },
        plugins: {
            legend: {
                display: false, // Hide dataset label
            },
        },
    };

    function getDayName(dateStr, locale) {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: 'long' });
    }


    
   

    const impressionChartData = {
        labels: ['6-jan', '7-jan', '8-jan', '9-jan', '10-jan'],
        datasets: [{
            label: 'Reach count',
            data: [22,400,6,33,77],
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',

        },
        {
            label: 'Impression count',
            data: [1,40,6,31,0],
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#89CFF0',
            borderDash: [4, 3]
        }
        ]
    }

    const profileActivityChartData = {
        labels: ['6-jan', '7-jan', '8-jan', '9-jan', '10-jan'],
        datasets: [{
            label: 'Profile Activity',
            data: [12,100,60,13,27],
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',

        },
        {
            label: 'Website Clicks',
            data: [52,400,61,33,77],
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#89CFF0',
            borderDash: [4, 3]
        }
        ]
    }

    return (
        <div className={Styles.dashboard_main_container} >
            

            
            {accountContext.accountsLoading ? <InstagramOverviewSkeleton /> :
                <div className={Styles.overview_container} >
                    <ConnectAccountSocialPopup  />
                    <div className={Styles.overview_content_main_container} >
                        <InstagramNavbar active="overview" />

                        <div className={Styles.overview_content_container} >
                            <div className={Styles.card_container} >
                                <OverviewCard data={true} count={0} percentage={0} header="Follower Change" />
                                <OverviewCard data={true} count={0} percentage={0} header="People Reached" />
                                <OverviewCard data={true} count={0} percentage={0} header="Profile Visits" />
                                <OverviewCard data={true} count={0} percentage={0} header="Website Clicks" />
                            </div>

                            <div className={Styles.latest_pin_and_followers_container} >
                                <div>
                                    <div className={Styles.header_container} >
                                        <label> Latest Post</label>
                                        <span style={{ cursor: 'pointer' }} >See all</span>
                                    </div>

                                    <div className={Styles.latest_post_container} >
                                        <div className={Styles.img_container} >
                                            <img alt="" src={ProfileImage} />
                                            <div className={Styles.button_container} >
                                                <button  > <img src={Previous} alt="previous" />  </button>
                                                <button  > <img src={Next} alt="next" /> </button>
                                            </div>


                                        </div>

                                        <div className={Styles.content_container} >
                                            <div className={Styles.header_value_container} >
                                                <span>Likes</span>
                                                <span>0</span>
                                            </div>

                                            <div className={Styles.header_value_container} >
                                                <span>Comments</span>
                                                <span>0</span>
                                            </div>

                                            <div className={Styles.header_value_container} >
                                                <span>Engagement Rate</span>
                                                <span>0</span>
                                            </div>

                                            <div className={Styles.header_value_container} >
                                                <span>Saves</span>
                                                <span>0</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <LineChartCustomComponent onHoverOut={() => { return }} onHoverIn={() =>{ return }} type="line" header="Followers" subheader="" contentArray={[{ "header": "Total Followers", value: 0 }, {
                                    "header": "Followers Change", value: 0
                                }, {
                                    "header": "Growth Rate", value: 0
                                }]} options={options} chartData={data} />
                            </div>

                            <div className={Styles.audience_activity_container} >
                                <div className={Styles.header_container} >
                                    <label>Audience Activity</label>
                                    <button  >See all</button>
                                </div>

                                <div className={Styles.date_and_activity_container} >
                                     
                                    <label>{new Date()?.getDate()} {monthNames[new Date().getMonth()]}, {getDayName(new Date())} -<br />Based on last week's activity, the best time to post today is <b>between 1-2.</b>
                                    </label>
                                    <div className={Styles.activity_container} >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((data) => {
                                            return (
                                                <div className={Styles.activity_item} >
                                                    <div style={{ backgroundColor: '#0078FF' }} className={Styles.activity} >

                                                    </div>
                                                    <svg width="1" height="5" viewBox="0 0 1 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <line x1="0.25" y1="0.5" x2="0.25" y2="4.5" stroke="#808080" strokeWidth="0.5" />
                                                    </svg>
                                                    <label>{data}</label>

                                                </div>
                                            )

                                        })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={Styles.reach_and_profile_activity} >
                                <LineChartCustomComponent onHoverOut={() => { return }} onHoverIn={() =>{ return }} type="line" header="Reach & Impressions" subheader="" contentArray={[{ "header": "Impressions",type:"solid" ,indicator: "#89CFF0", value:  0 }, {
                                    "header": "People Reached", type:"dashed",indicator: "#0078FF", value: 0
                                }]} options={options} chartData={impressionChartData} />

                                <LineChartCustomComponent onHoverOut={() => { return }} onHoverIn={() =>{ return }} type="line" header="Profile Activity" subheader="" contentArray={[{ "header": "Profile Views", type:"solid",indicator: "#0078FF", value: 0 }, {
                                    "header": "Website Clicks", type:"dashed",indicator: "#89CFF0", value: 0
                                }]} options={options} chartData={profileActivityChartData} />
                            </div>

                        </div>


                    </div>

                </div>
            }
        </div>
    )
}