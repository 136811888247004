import { useEffect, useContext } from 'react'
import InstagramOverview from '../InstagramOverview/InstagramOverview'
import Styles from './Instagram.module.css'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import InstagramContent from '../InstagramContent/InstagramContent'
import { createNavigationContext } from '../../store/navigation'
import InstagramAccount from '../InstagramAccount/InstagramAccount'
import { InstagramCompetitors } from '../InstagramCompetitors/InstagramCompetitors'
import { UserNavigationContext } from '../../store/NavigationProvider'
import Report from '../Report/Report'
import AdsLibrary from '../AdsLibrary/AdsLibrary'
const Instagram = () => {

    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const contextOfNavbar = useContext(createNavigationContext)
    const userNavigation = useContext(UserNavigationContext)
    useEffect(() => {

        contextOfNavbar.getInfo()
        //instagramContext.getInstagramAnalyticsData()
        instagramContext.getInstagramOverviewPageAnalyticaData()
        instagramContext.getInstagramContentPageAnalyticaData()
        instagramContext.getInstagramAccountPageAnalyticaData()
        instagramContext.getInstagramCompetitorPageAnalyticaData()
        // commonSocialMediaContext.getInstagramOverviewPageAnalyticaData()
        // eslint-disable-next-line
    }, [userNavigation.activeSocialMediaName])




    return (
        <div className={Styles.linkedin_overview_container} >
            {contextOfNavbar.active === 'overview' && <InstagramOverview />}
            {contextOfNavbar.active === 'content' && <InstagramContent />}
            {contextOfNavbar.active === 'account' && <InstagramAccount />}
            {contextOfNavbar.active === 'competition' && <InstagramCompetitors />}
            {contextOfNavbar.active === 'ads' && <AdsLibrary page='instagram' />}
            {contextOfNavbar.active === 'reports' && <Report/>}
        </div>
    )
}

export default Instagram