
import Styles from './FacebookCompetitor.module.css'
import { useContext, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { captionTruncate, formatNumber } from '../../Util/helper'
import { CompetitorsSkeleton } from '../../Component/CompetitorsSkeleton/CompetitorsSkeleton'
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup'
import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import CompetitorPostDetails from '../../Component/CompetitorPostDetails/CompetitorPostDetails'
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount'
import BlogListCard from '../../Component/BlogListCard/BlogListCard'
import ActiveSocialAccountSkeleton from '../../Component/ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton'
import { UserNavigationContext } from '../../store/NavigationProvider'
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics'
import FacebookCompetitorList from '../../Component/FacebookCompetitorList/FacebookCompetitorList'
import FacebookNavbar from '../../Component/FacebookNavbar/FacebookNavbar'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import verify from '../../assets/facebook_verified.png'
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent'
import PostDetailsByDate from '../../Component/PostDetailsByDate/PostDetailsByDate'
export const FacebookCompetitor = () => {
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)

    const instagramContext = useContext(CreateFacebookAnalyticContext)
    const [editCompetitor, seteditCompetitor] = useState(false)
    const [showPostDetails, setshowPostDetails] = useState(false)
    const [selectedPostDetails, setselectedPostDetails] = useState('')
    const [hoverPostGraph, sethoverPostGraph] = useState(false)
    const [hoverLikeGraph, sethoverLikeGraph] = useState(false)
    const [hoverCommentGraph, sethoverCommentGraph] = useState(false)
    const [selectedCategory, setselectedCategory] = useState('')
    const [page, setpage] = useState(1)
    const [selectedBusinessAccount, setselectedBusinessAccount] = useState('')
    const [posts, setposts] = useState([])
    const limit = 5

    function hexToRgb(hex) {

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }



    const lineChartData = {
        labels: instagramContext.selectedPeriod !== 30 ? instagramContext.dateArray?.slice(-29) : instagramContext.dateArray,
        datasets: instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map((data) => {
            const color = instagramContext.borderColorArray?.filter(competitor => competitor.name === data?.id)[0]?.color
            return {
                label: `${data?.name}`,
                data: instagramContext.lineChartData?.filter(data1 => data1.name === data?.id)?.map(data1 => { return data1.allPost })[0],
                lineTension: 0.5,
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b},0.25)`
                },
                borderColor: color,
                pointBorderColor: hoverPostGraph ? `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 1)` : `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0)`,
                pointBackgroundColor: hoverPostGraph ? `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0.5)` : `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0)`,
                pointHoverBackgroundColor: '#ff43ca',
                pointHoverBorderColor: '#ff43ca'
            }
        }
        )
    };

    const barChartData = {
        labels: instagramContext.selectedPeriod !== 30 ? instagramContext.dateArray?.slice(-29) : instagramContext.dateArray,
        datasets: instagramContext?.competitorsData?.flat()?.filter(data => (data.hideView !== true))?.map((data, index) => {
            const color = instagramContext.borderColorArray?.filter(competitor => competitor.name === data?.id)[0]?.color
            return {

                label: `${data?.name}`,
                data: instagramContext.lineChartData?.filter(data1 => data1.name === data?.id)?.map(data1 => { return data1.allPostLike })[0],
                lineTension: 0.5,
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b},0.25)`
                },
                borderColor: color,
                pointBorderColor: hoverLikeGraph ? `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 1)` : `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0)`,
                pointBackgroundColor: hoverLikeGraph ? `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0.5)` : `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0)`,
                pointHoverBackgroundColor: '#ff43ca',
                pointHoverBorderColor: '#ff43ca'

            }
        })
    };

    const barChartDataComment = {
        labels: instagramContext.selectedPeriod !== 30 ? instagramContext.dateArray?.slice(-29) : instagramContext.dateArray,
        datasets: instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map((data, index) => {
            const color = instagramContext.borderColorArray?.filter(competitor => competitor.name === data?.id)[0]?.color

            return {
                label: `${data?.name}`,
                data: instagramContext.lineChartData?.filter(data1 => data1.name === data?.id)?.map(data1 => { return data1.allPostComment })[0],
                lineTension: 0.5,
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b},0.25)`
                },
                borderColor: color,
                pointBorderColor: hoverCommentGraph ? `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 1)` : `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0)`,
                pointBackgroundColor: hoverCommentGraph ? `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0.5)` : `rgba(${hexToRgb(color)?.r},${hexToRgb(color)?.g},${hexToRgb(color)?.b}, 0)`,
                pointHoverBackgroundColor: '#ff43ca',
                pointHoverBorderColor: '#ff43ca'
            }
        })
    };
    const filterCompetitors = instagramContext.competitorsData?.filter(data => data?.hideView !== true).map(data => data?.id)

    const socialIds = instagramContext.stackedDataCompetitor?.filter(data => filterCompetitors.includes(data.socialId))?.reduce((ids, item) => {
        if (!ids.includes(item.socialId)) {
            ids.push(item.socialId);
        }
        return ids;
    }, []);


    const categories = instagramContext.stackedDataCompetitor?.filter(data => filterCompetitors?.includes(data.socialId))?.reduce((uniqueCategories, item) => {
        if (!uniqueCategories.includes(item.contentCategory)) {
            uniqueCategories.push(item.contentCategory);
        }
        return uniqueCategories;
    }, []);

    const datasets = socialIds.map((socialId) => {
        const color = instagramContext.borderColorArray.filter(competitor => competitor.name === instagramContext?.competitors?.find(data => data.id === socialId)?.id)[0]?.color

        return {
            label: `${instagramContext?.competitors?.find(data => data.id === socialId)?.name}`,
            data: categories.map(category => {

                return instagramContext.stackedDataCompetitor?.filter(item => item.socialId === socialId && item.contentCategory === category)?.length;
            }),
            backgroundColor: color,
        };
    });

    const dataForCategory = {
        labels: instagramContext.stackedDataCompetitor?.filter(data => filterCompetitors?.includes(data.socialId))?.filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i)?.map(item => item.contentCategory),
        datasets: datasets
    }

    const dataDonghnut = {
        labels: instagramContext.stackedDataCompetitor?.filter(data => filterCompetitors.includes(data?.socialId))?.filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i)?.map(item => item.contentCategory),
        datasets: datasets,
    }

    const onClickCancel = () => {
        if (instagramContext.competitors?.length <= 10) {
            if (instagramContext?.competitors?.length > instagramContext.competitorsData?.length) {

                const checkCompetitorsExits = instagramContext?.competitorsData?.map(data => data?.id)
                const newAddedCompetitor = instagramContext?.competitors?.map(data => (
                    checkCompetitorsExits?.includes(data?.id) ? data : null
                )
                )

                instagramContext.handleCompetitor(newAddedCompetitor?.filter(data => data !== null))
            } else {

                const checkCompetitorsExits = instagramContext?.competitors?.map(data => data?.id)

                const newAddedCompetitor = instagramContext?.competitorsData?.map(data => (
                    !(checkCompetitorsExits?.includes(data?.id)) ? data : null
                )
                )

                if (newAddedCompetitor?.filter(data => data !== null).length > 0) {
                    instagramContext.changeCompetitors(newAddedCompetitor?.filter(data => data !== null)[0])
                }
            }
        }
        seteditCompetitor(false)
    }



    const close = () => {
        seteditCompetitor(false)
    }

    const calculateAverageEngagementPerPost = (id) => {
        // eslint-disable-next-line
        return isFinite((instagramContext?.competitorsData?.map((data) => {
            if (data?.id === id) {
                return data?.posts?.data?.reduce((totalEngagment, data) => {

                    return totalEngagment += ((data?.likes.summary.total_count ? data?.likes.summary.total_count : 0) + (data?.comments.summary.total_count ? data?.comments.summary.total_count : 0))
                }, 0)

            }
        })?.filter(data => data !== undefined)[0] / instagramContext?.competitorsData?.reduce((totalAverage, data) => {
            if (data?.id === id) {

                totalAverage += data?.posts?.data?.length
            }
            return totalAverage
        }, 0)).toFixed(2)) ?
            // eslint-disable-next-line
            (instagramContext?.competitorsData?.map((data) => {
                if (data?.id === id) {
                    return data?.posts?.data?.reduce((totalEngagment, data) => {

                        return totalEngagment += ((data?.likes.summary.total_count ? data?.likes.summary.total_count : 0) + (data?.comments.summary.total_count ? data?.comments.summary.total_count : 0))
                    }, 0)

                }
            })?.filter(data => data !== undefined)[0] / instagramContext?.competitorsData?.reduce((totalAverage, data) => {
                if (data?.id === id) {

                    totalAverage += data?.posts?.data?.length
                }
                return totalAverage
            }, 0)).toFixed(0) : 0
    }



    const calculatePostPerWeek = (id) => {
        let postsPerWeek = 0;
        instagramContext?.competitorsData?.forEach(data => {

            if (data?.id === id) {
                data?.posts?.data?.forEach(() => {

                    if (!postsPerWeek) {
                        postsPerWeek = 1;
                    } else {
                        postsPerWeek++;
                    }
                });
            }
        })
        return (postsPerWeek / 7).toFixed(0);
    }

    function extractHashtags(caption, separator = '') {
        if (caption === undefined) return "-";

        // Regular expression to find hashtags
        const hashtagRegex = /#(\w+)/g;

        // Use match() to find all matches in the caption
        const hashtags = caption?.match(hashtagRegex);

        // If there are hashtags, join them into a single string using the separator
        // Otherwise, return "-"
        return hashtags ? hashtags?.join(separator) : "-";
    }

    function getTopCategoryCounts(data) {
        const categoryCounts = {};

        // Iterate over each array
        data.forEach(array => {
            // Iterate over each object in the array
            array.forEach(item => {
                // Get the socialId and contentCategory
                const socialId = item.socialId;
                const category = item.contentCategory;

                // If the socialId doesn't exist in categoryCounts, initialize it
                if (!categoryCounts[socialId]) {
                    categoryCounts[socialId] = {};
                }

                // Increment the count for the category under the socialId
                if (!categoryCounts[socialId][category]) {
                    categoryCounts[socialId][category] = 1;
                } else {
                    categoryCounts[socialId][category]++;
                }
            });
        });

        // Convert the object to an array of objects
        const result = Object.entries(categoryCounts).map(([socialId, categories]) => {
            // Find the category with the highest count
            const topCategory = Object.entries(categories).reduce((acc, [category, count]) => {
                return count > acc.count ? { category, count } : acc;
            }, { category: null, count: -1 });

            return { socialId, topCategory };
        });

        return result;
    }

    const prevPage = () => {
        if (page > 1) {
            setpage(prev => prev - 1)
        }
    }

    const nextPage = () => {
        if (page < Math.ceil(Math.ceil(instagramContext.stackedDataCompetitor.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataCompetitor[0]?.contentCategory : selectedCategory)).map(data => {

            const postDetails = [].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                return data?.posts?.data
            })).find(postData => postData.id === data.postId)
            return postDetails
        }).filter(data => data !== undefined).length / limit))) {
            setpage(prev => prev + 1)
        }
    }

    return (
        <div className={Styles.instagram_competitors_main_container} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {editCompetitor ? <FacebookCompetitorList  onClickCancel={() => onClickCancel()} close={() => close()} />
                : <></>
            }
            {posts.length ? <PostDetailsByDate type="facebook" posts={posts} onClickClose={() => setposts([])} /> : <></>
            }
            {instagramContext.competitorPageLoading ? <ActiveSocialAccountSkeleton selected={instagramContext.selectedPeriod} /> : <ActiveSocialAccount page="facebook" disableFilter={false} loading={instagramContext.competitorPageLoading} selected={instagramContext.selectedPeriod === 30 ? (instagramContext.selectedPeriod + 1) : instagramContext.selectedPeriod} onChangePeriod={(value) => { instagramContext.setselectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts?.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); instagramContext.setfilterLoading(true) }} />}

            <FacebookNavbar active="competition" />

            <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.container} >

                {showPostDetails ? <CompetitorPostDetails page="facebookcompetitor" data={selectedPostDetails} onClickClose={() => setshowPostDetails(false)} /> : <></>

                }
                {instagramContext.totalFollower < 100 && instagramContext.competitorPageLoading === false ? <BlogListCard type="instagram" /> : <></>}
                {userData.userData.workspace?.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                    <div className={Styles.search_bar_container} >
                        <div className={Styles.no_of_competitors} >
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.9062 13.5L10.0781 11.6875L8.70312 13.0625L8.26562 12.625C8.02604 12.3854 7.90625 12.0885 7.90625 11.7344C7.90625 11.3802 8.02604 11.0833 8.26562 10.8438L10.9062 8.20312C11.1458 7.96354 11.4427 7.84375 11.7969 7.84375C12.151 7.84375 12.4479 7.96354 12.6875 8.20312L13.125 8.64062L11.75 10.0156L13.5625 11.8438C13.6875 11.9688 13.75 12.1146 13.75 12.2812C13.75 12.4479 13.6875 12.5938 13.5625 12.7188L12.7812 13.5C12.6562 13.625 12.5104 13.6875 12.3438 13.6875C12.1771 13.6875 12.0312 13.625 11.9062 13.5ZM13.75 3.6875L6.65625 10.7812L6.73438 10.8438C6.97396 11.0833 7.09375 11.3802 7.09375 11.7344C7.09375 12.0885 6.97396 12.3854 6.73438 12.625L6.29688 13.0625L4.92188 11.6875L3.09375 13.5C2.96875 13.625 2.82292 13.6875 2.65625 13.6875C2.48958 13.6875 2.34375 13.625 2.21875 13.5L1.4375 12.7188C1.3125 12.5938 1.25 12.4479 1.25 12.2812C1.25 12.1146 1.3125 11.9688 1.4375 11.8438L3.25 10.0156L1.875 8.64062L2.3125 8.20312C2.55208 7.96354 2.84896 7.84375 3.20312 7.84375C3.55729 7.84375 3.85417 7.96354 4.09375 8.20312L4.15625 8.28125L11.25 1.1875H13.75V3.6875ZM4.34375 6.78125L1.25 3.6875V1.1875H3.75L6.84375 4.28125L5.95312 5.15625L3.23438 2.4375H2.5V3.17188L5.21875 5.89062L4.34375 6.78125ZM5.78125 9.89062L12.5 3.17188V2.4375H11.7656L5.04688 9.15625L5.78125 9.89062Z" fill="#333333" />
                            </svg>
                            <label>{instagramContext?.competitors?.length} Competitors Comparison</label>

                        </div>

                        <button onClick={() => { instagramContext.competitorPageLoading || instagramContext.saveCompetitorsLoading ? seteditCompetitor(false) : seteditCompetitor(true) }} >Edit Competition </button>

                    </div>
                    : <></>}
                {instagramContext.competitorPageLoading || instagramContext.saveCompetitorsLoading ? <CompetitorsSkeleton /> :

                    <>
                        <div className={Styles.post_main_container_of_pages} >
                            <div className={Styles.header_container} >
                                <span>Page Metrics</span>

                            </div>
                            {userData.userData.workspace?.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>

                                {instagramContext?.competitorsData?.filter(data => (data.hideView !== true)).length ? <>
                                    <div className={Styles.post_container} >
                                        <div className={Styles.header_container} >
                                            <span>Page</span>
                                            <div className={Styles.postHeaderContainer} >
                                                <span>Total Followers</span>

                                                <span>Website</span>
                                                <span>Fan Count</span>
                                                <span>Visit Profile</span>
                                            </div>

                                        </div>
                                        {instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map((data, index) => {

                                            return (
                                                <div key={index} style={{ width: '100%' }} >
                                                    <div className={Styles.content_container_of_page} >

                                                        <div className={Styles.post_content_container} >
                                                            <div className={Styles.post_content_img} >

                                                                <img alt="" src={data?.picture?.data?.url} />
                                                            </div>

                                                            <div className={Styles.post_content} >
                                                                <span  >{data?.name} {data.is_verified ?
                                                                    <img src={verify} style={{ width: '10px', height: '10px' }} alt="" /> : <></>
                                                                }</span>
                                                                <p>{data?.about}</p>
                                                            </div>
                                                        </div>
                                                        <div className={Styles.postHeaderContainer} >
                                                            <span>{data?.followers_count ? formatNumber(data?.followers_count) : 0}</span>

                                                            <span style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`${data?.website}`, '_blank') }} >{(data?.website ? data?.website : "-")}</span>
                                                            <span>{data?.fan_count ? formatNumber(data?.fan_count) : 0}</span>
                                                            <span><svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`https://www.facebook.com/${data?.id}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_2171_2043)">
                                                                    <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2171_2043">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></span>


                                                        </div>
                                                    </div>
                                                    <div className={Styles.border} ></div>
                                                </div>


                                            )
                                        })
                                        }

                                    </div>

                                </> : <NoDataAvailablePopup cardName="postlist" />

                                }
                            </> : <PremiumPopup cardName="postlist" />

                            }




                        </div>

                        <div className={Styles.post_main_container_of_content_metrics} >
                            <div className={Styles.header_container} >
                                <span>Content Metrics (last 30 days)</span>

                            </div>
                            {userData.userData.workspace?.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>

                                <div className={Styles.post_container} >
                                    {
                                        [].concat(...instagramContext?.competitorsData?.map(data => {
                                            return data?.posts?.data
                                        }))?.filter(data => data !== undefined && data.hideView !== true)?.length ?
                                            <>
                                                <div className={Styles.header_container} >
                                                    <span>Post</span>
                                                    <div className={Styles.postHeaderContainer} >
                                                        <span  >Total Post <svg data-tooltip-id="my-tooltip" data-tooltip-content="total post count of last 30 days" width="10px" height="10px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M12 11.5V16.5" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 7.51L12.01 7.49889" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> </span>
                                                        <span>Post/Week</span>
                                                        <span data-tooltip-id="my-tooltip" data-tooltip-content="This metric calculates the average number of interactions (likes, comments, etc.) received per post divided by number of post in last 30 days." >Avg Interaction/Post</span>

                                                    </div>
                                                </div>
                                                {[...instagramContext?.competitorsData]?.filter(data => (data.hideView !== true))?.map((data, index) => {

                                                    return (
                                                        <div key={index} style={{ width: '100%' }}>
                                                            <div className={Styles.content_container_of_page} >
                                                                <div className={Styles.post_content_container} >
                                                                    <div className={Styles.post_content_img} >
                                                                        <img alt="" src={data?.picture?.data?.url} />
                                                                    </div>

                                                                    <div className={Styles.post_content} >
                                                                        <span  >{data?.name}</span>

                                                                    </div>
                                                                </div>
                                                                <div className={Styles.postHeaderContainer} >
                                                                    <span>{formatNumber(data?.posts?.data?.length || 0)}</span>
                                                                    <span>{formatNumber(calculatePostPerWeek(data?.id) || 0)}</span>
                                                                    <span>{calculateAverageEngagementPerPost(data?.id)}</span>
                                                                </div>
                                                            </div>
                                                            <div className={Styles.border} ></div>
                                                        </div>
                                                    )
                                                })

                                                }
                                            </> : <NoDataAvailablePopup noOfPost={[].concat(...instagramContext?.competitorsData?.map(data => {
                                                return data?.posts?.data
                                            }))?.filter(data => data !== undefined && data.hideView !== true)?.length} selectedPeriod={30} cardName="postlist" />
                                    }


                                </div>
                            </> : <PremiumPopup cardName="postlist" />

                            }

                        </div>

                        <div className={Styles.post_graph_container} >
                            <LineChartCustomComponent componentName="category" style={{ width: '98%' }} header={`Category graph (last 30 days)`} contentArray={getTopCategoryCounts(instagramContext.competitors?.filter(data => filterCompetitors.includes(data.id))?.map(competitorData => {
                                const categoriesData = instagramContext.stackedDataCompetitor.filter(categories => categories.socialId === competitorData.id)
                                return categoriesData
                            }))?.map(data => {
                                // const name = instagramContext.competitors.find(competitor => competitor.id === data.socialId).name
                                const color = instagramContext.borderColorArray.filter(competitor => competitor.name === data.socialId)[0]?.color
                                return {
                                    "header": `${instagramContext.competitors.find(competitor => competitor.id === data.socialId).name} ( ${data.topCategory.category} )`, indicator: color, type: 'solid', "value": data.topCategory.count
                                }
                            })} onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(false)} type="bar" options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                radius: hoverCommentGraph ? 5 : 5,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                }
                            }} chartData={dataForCategory} />
                        </div>

                        {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                            <>
                                <div className={Styles.competitor_category_content_container} >
                                    <LineChartCustomComponent componentName="category" style={{ width: '48%' }} header={`Category concentric graph (last 30 days)`} contentArray={getTopCategoryCounts(instagramContext.competitors?.filter(data => filterCompetitors.includes(data.id))?.map(competitorData => {
                                        const categoriesData = instagramContext.stackedDataCompetitor.filter(categories => categories.socialId === competitorData.id)
                                        return categoriesData
                                    }))?.map(data => {
                                        // const name = instagramContext.competitors.find(competitor => competitor.id === data.socialId).name
                                        const color = instagramContext.borderColorArray.filter(competitor => competitor.name === data.socialId)[0]?.color
                                        return {
                                            "header": `${instagramContext.competitors.find(competitor => competitor.id === data.socialId).name} ( ${data.topCategory.category} )`, indicator: color, type: 'solid', "value": data.topCategory.count
                                        }
                                    })} onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(false)} type="pie" options={{
                                        maintainAspectRatio: false,
                                        onClick: function (e, i) {
                                            setpage(1)
                                            setselectedBusinessAccount(dataDonghnut.datasets[i[0].datasetIndex].label)
                                            setselectedCategory(dataDonghnut.labels[i[0].index])
                                        },
                                        scales: {
                                            x: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        }
                                    }} chartData={dataDonghnut} />
                                    {instagramContext.stackedDataCompetitor.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataCompetitor[0]?.contentCategory : selectedCategory)).map(data => {
                                        const postDetails = [].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                            return data?.posts?.data
                                        })).find(postData => postData.id === data.postId)
                                        return postDetails
                                    }).filter(data => data !== undefined).length > 0 ? <>
                                        <div className={Styles.category_content_container} >
                                            <div>
                                                <label>{selectedBusinessAccount === '' ? dataDonghnut?.datasets[0]?.label : selectedBusinessAccount} - {selectedCategory === '' ? instagramContext?.stackedDataCompetitor[0]?.contentCategory : selectedCategory} Post</label>
                                                <div className={Styles.single_content_container} >
                                                    <span>Post</span>
                                                    <span>Visit</span>
                                                </div>

                                                {instagramContext.stackedDataCompetitor.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataCompetitor[0]?.contentCategory : selectedCategory)).slice((page - 1) * 5, (page - 1) * 5 + 5).map((data, index) => {

                                                    const postDetails = [].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map((data) => {
                                                        return data?.posts?.data
                                                    })).find(postData => postData.id === data.postId)
                                                    return (
                                                        <div className={Styles.single_content_container} >
                                                            <div className={Styles.postDetailsContainer} >
                                                                <p className={Styles.index_header} >{index + 1}</p>
                                                                <img alt="media" src={postDetails?.full_picture} />
                                                                <div className={Styles.post_content_container} >
                                                                    <h5>{captionTruncate(postDetails?.message === undefined ? "-" : postDetails?.message, 15)}</h5>
                                                                    <p>{postDetails?.likes?.summary?.total_count || 0} Likes {postDetails?.comments?.summary?.total_count || 0} Comments</p>
                                                                </div>
                                                            </div>
                                                            <svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`${postDetails.permalink_url}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_2171_2043)">
                                                                    <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2171_2043">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            <div className={Styles.post_pagination_container} >
                                                <svg onClick={prevPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.375 3.75L5.625 7.5L9.375 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Showing {page}-{Math.ceil(instagramContext.stackedDataCompetitor.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataCompetitor[0]?.contentCategory : selectedCategory)).map(data => {

                                                    const postDetails = [].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                                        return data?.posts?.data
                                                    })).find(postData => postData.id === data.postId)
                                                    return postDetails
                                                }).filter(data => data !== undefined).length / limit)} posts</span>
                                                <svg onClick={nextPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.625 3.75L9.375 7.5L5.625 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </div>
                                        </div>
                                    </> : <NoDataAvailablePopup noOfPost={instagramContext.stackedDataCompetitor.filter(data => data.contentCategory === (selectedCategory === '' ? instagramContext?.stackedDataCompetitor[0]?.contentCategory : selectedCategory)).map(data => {

                                        const postDetails = [].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                            return data?.posts?.data
                                        })).find(postData => postData.id === data.postId)
                                        return postDetails
                                    }).filter(data => data !== undefined).length} selectedPeriod={30} cardName="postlist" />

                                    }

                                </div>
                            </> : <PremiumPopup cardName="postlist" />
                        }


                        <div className={Styles.post_graph_container} >
                            <LineChartCustomComponent pageName="lineChartPost" noOfPost={[].concat(...instagramContext?.competitorsData?.map(data => {
                                return data?.posts?.data
                            }))?.filter(data => data !== undefined)?.length} selectedPeriod={30} onHoverOut={() => sethoverPostGraph(false)} onHoverIn={() => sethoverPostGraph(true)} style={{ width: '98%' }} type="line" header={`Post graph (last 30 days)`} contentArray={instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                const color = instagramContext.borderColorArray?.filter(competitor => competitor?.name === data?.id)[0]?.color
                                return { "header": data?.name, type: "solid", indicator: color, "value": formatNumber(data?.posts?.data?.length) }
                            })} options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                onClick: function (e, i) {
                                    const posts = instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                        return data?.posts?.data.filter(postData => `${new Date(postData.created_time).toLocaleString('default', { month: 'short' })}-${new Date(postData.created_time).getDate()}` === lineChartData?.labels[i[0]?.index])
                                    }).flat()
                                  
                                    setposts(posts)
                                },
                                radius: hoverPostGraph ? 5 : 5,
                                scales: {
                                    x: {

                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {

                                        grid: {
                                            display: false
                                        }
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                }
                            }} chartData={lineChartData} />
                        </div>




                        <div className={Styles.like_comment_container} >
                            <LineChartCustomComponent noOfPost={[].concat(...instagramContext?.competitorsData?.map(data => {
                                return data?.posts?.data
                            }))?.filter(data => data !== undefined)?.length} selectedPeriod={30} onHoverOut={() => sethoverLikeGraph(false)} onHoverIn={() => sethoverLikeGraph(true)} type="line" header={`Like graph (last 30 days)`} contentArray={instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                const color = instagramContext.borderColorArray?.filter(competitor => competitor.name === data?.id)[0]?.color
                                return { "header": data?.name, type: "solid", indicator: color, "value": formatNumber(data?.posts?.data?.reduce((totalLikes, data) => { return totalLikes += (data.likes.summary.total_count !== undefined ? data.likes.summary.total_count : 0) }, 0)) }
                            })} options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                onClick: function (e, i) {
                                    const posts = instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                        return data?.posts?.data.filter(postData => `${new Date(postData.created_time).toLocaleString('default', { month: 'short' })}-${new Date(postData.created_time).getDate()}` === lineChartData?.labels[i[0]?.index])
                                    }).flat()
                                    setposts(posts)
                                },
                                radius: hoverLikeGraph ? 5 : 5,
                                scales: {
                                    x: {

                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {

                                        grid: {
                                            display: false
                                        }
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                }
                            }} chartData={barChartData} />


                            <LineChartCustomComponent noOfPost={[].concat(...instagramContext?.competitorsData?.map(data => {
                                return data?.posts?.data
                            }))?.filter(data => data !== undefined)?.length} selectedPeriod={30} onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(true)} type="line" header={`Comment graph (last 30 days)`} contentArray={instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                const color = instagramContext.borderColorArray?.filter(competitor => competitor.name === data?.id)[0]?.color
                                return { "header": data?.name, type: "solid", indicator: color, "value": formatNumber(data?.posts?.data?.reduce((totalLikes, data) => { return totalLikes += (data.comments.summary.total_count !== undefined ? data.comments.summary.total_count : 0) }, 0)) }
                            })} options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                onClick: function (e, i) {

                                    const posts = instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                        return data?.posts?.data.filter(postData => `${new Date(postData.created_time).toLocaleString('default', { month: 'short' })}-${new Date(postData.created_time).getDate()}` === lineChartData?.labels[i[0]?.index])
                                    }).flat()
                                    setposts(posts)
                                },
                                radius: hoverCommentGraph ? 5 : 5,
                                scales: {
                                    x: {

                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {

                                        grid: {
                                            display: false
                                        }
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                }
                            }} chartData={barChartDataComment} />
                        </div>

                        <div className={Styles.post_main_container_of_best_performance} >
                            <div className={Styles.header_container} >
                                <span>Best Performing Posts (last 30 days)</span>

                            </div>
                            {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                {[].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                    return data?.posts?.data?.sort((a, b) => {
                                        const aTotal = a.likes.summary.total_count + b.likes.summary.total_count;
                                        const bTotal = a.comments.summary.total_count + b.comments.summary.total_count;
                                        return bTotal - aTotal;
                                    })?.slice(0, 3)
                                }))?.sort((a, b) => b.likes.summary.total_count - a.likes.summary.total_count)?.filter(data => data !== undefined)?.length ?
                                    <div className={Styles.post_container} >
                                        <div className={Styles.header_container} >
                                            <span>Post</span>
                                            <div className={Styles.postHeaderContainer} >
                                                <span>Like</span>
                                                <span>Comments</span>
                                                <span  >Hashtag</span>
                                                <span>Page</span>
                                                <span>Visit</span>
                                            </div>
                                        </div>

                                        {[].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                            return data?.posts?.data?.sort((a, b) => {
                                                const aTotal = a.likes.summary.total_count + b.likes.summary.total_count;
                                                const bTotal = a.comments.summary.total_count + b.comments.summary.total_count;
                                                return bTotal - aTotal;
                                            })?.slice(0, 3)
                                        }))?.sort((a, b) => b.likes.summary.total_count - a.likes.summary.total_count)?.filter(data => data !== undefined)?.map((data, index) => {
                                            const id = instagramContext.competitorsData.filter(competitorData => {
                                                const allPost = competitorData?.posts?.data?.map(postId => postId?.id)
                                                return allPost?.includes(data?.id) ? competitorData.name : null
                                            })[0]?.id
                                            const profile_picture_url = instagramContext?.competitorsData?.filter(competitorData => competitorData?.id === id)[0]?.picture?.data?.url
                                            const name = instagramContext.competitorsData.filter(competitorData => {
                                                const allPost = competitorData?.posts?.data?.map(postId => postId?.id)
                                                return allPost?.includes(data?.id) ? competitorData.name : null
                                            })[0]?.name
                                            return (
                                                <div style={{ width: '100%' }} key={index} >
                                                    <div style={{ cursor: 'pointer' }} onClick={() => { setshowPostDetails(true); setselectedPostDetails(data) }} className={Styles.content_container_of_page} >
                                                        <div className={Styles.post_content_container} >
                                                            <img className={Styles.competitor_profile} alt="" src={profile_picture_url} />
                                                            <div className={Styles.post_content_img} >
                                                                <img alt="" src={data?.full_picture} />

                                                            </div>

                                                            <div className={Styles.post_content} >
                                                                <span  >{captionTruncate(data?.message, 20)}</span>
                                                                <p>{new Date(data?.created_time).getDate() + "/" + (new Date(data?.created_time).getMonth() + 1) + "/" + new Date(data?.created_time).getFullYear()}</p>
                                                            </div>
                                                        </div>
                                                        <div className={Styles.postHeaderContainer} >
                                                            <span>{data?.likes.summary.total_count ? formatNumber(data?.likes.summary.total_count) : 0}</span>
                                                            <span>{data?.comments.summary.total_count ? formatNumber(data?.comments.summary.total_count) : 0}</span>
                                                            <span  >{captionTruncate(extractHashtags(data?.message), 15)}</span>
                                                            <span>{name}</span>
                                                            <span><svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`${data.permalink_url}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_2171_2043)">
                                                                    <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2171_2043">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></span>
                                                        </div>
                                                    </div>
                                                    <div className={Styles.border} ></div>
                                                </div>
                                            )
                                        })

                                        }



                                    </div> : <NoDataAvailablePopup noOfPost={[].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                        return data?.posts?.data?.sort((a, b) => {
                                            const aTotal = a.likes.summary.total_count + b.likes.summary.total_count;
                                            const bTotal = a.comments.summary.total_count + b.comments.summary.total_count;
                                            return bTotal - aTotal;
                                        })?.slice(0, 3)
                                    }))?.sort((a, b) => b.likes.summary.total_count - a.likes.summary.total_count)?.filter(data => data !== undefined)?.length} selectedPeriod={30} cardName="postlist" />
                                }
                            </> : <PremiumPopup cardName="postlist" />

                            }


                        </div>

                        <div style={{ marginBottom: 110 }} className={Styles.post_main_container_of_best_performance} >
                            <div className={Styles.header_container} >
                                <span>Least Performing Posts (last 30 days)</span>
                            </div>
                            {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                {[].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                    return data?.posts?.data?.sort((a, b) => {
                                        const aTotal = a.likes.summary.total_count + b.likes.summary.total_count;
                                        const bTotal = a.comments.summary.total_count + b.comments.summary.total_count;
                                        return aTotal - bTotal;
                                    })?.slice(0, 3)
                                }))?.sort((a, b) => a.likes.summary.total_count - b.likes.summary.total_count)?.filter(data => data !== undefined)?.length ?
                                    <div className={Styles.post_container} >
                                        <div className={Styles.header_container} >
                                            <span>Post</span>
                                            <div className={Styles.postHeaderContainer} >
                                                <span>Like</span>
                                                <span>Comments</span>
                                                <span  >Hashtag</span>
                                                <span>Page</span>
                                                <span>Visit</span>

                                            </div>
                                        </div>

                                        {
                                            [].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true))?.map(data => {
                                                return data?.posts?.data?.sort((a, b) => {
                                                    const aTotal = a.likes.summary.total_count + b.likes.summary.total_count;
                                                    const bTotal = a.comments.summary.total_count + b.comments.summary.total_count;
                                                    return aTotal - bTotal;
                                                })?.slice(0, 3)
                                            }))?.sort((a, b) => a.likes.summary.total_count - b.likes.summary.total_count)?.filter(data => data !== undefined)?.map((data, index) => {

                                                const id = instagramContext.competitorsData.filter(competitorData => {
                                                    const allPost = competitorData?.posts?.data?.map(postId => postId?.id)
                                                    return allPost?.includes(data?.id) ? competitorData.name : null
                                                })[0]?.id
                                                const profile_picture_url = instagramContext?.competitorsData?.filter(competitorData => competitorData?.id === id)[0]?.picture?.data?.url
                                                const name = instagramContext.competitorsData.filter(competitorData => {
                                                    const allPost = competitorData?.posts?.data?.map(postId => postId?.id)
                                                    return allPost?.includes(data?.id) ? competitorData.name : null
                                                })[0]?.name

                                                return (
                                                    <div key={index} style={{ width: '100%' }} >
                                                        <div style={{ cursor: 'pointer' }} onClick={() => { setshowPostDetails(true); setselectedPostDetails(data) }} className={Styles.content_container_of_page} >
                                                            <div className={Styles.post_content_container} >
                                                                <img className={Styles.competitor_profile} alt="" src={profile_picture_url} />
                                                                <div className={Styles.post_content_img} >
                                                                    <img alt="" src={data?.full_picture} />

                                                                </div>

                                                                <div className={Styles.post_content} >
                                                                    <span  >{captionTruncate(data?.message, 20)}</span>
                                                                    <p>{new Date(data?.created_time).getDate() + "/" + (new Date(data?.created_time).getMonth() + 1) + "/" + new Date(data?.created_time).getFullYear()}</p>
                                                                </div>
                                                            </div>
                                                            <div className={Styles.postHeaderContainer} >
                                                                <span>{data?.likes.summary.total_count ? formatNumber(data?.likes.summary.total_count) : 0}</span>
                                                                <span>{data?.comments.summary.total_count ? formatNumber(data?.likes.summary.total_count) : 0}</span>

                                                                <span  >{captionTruncate(extractHashtags(data?.message), 20)}</span>
                                                                <span>{name}</span>
                                                                <span><svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`${data.permalink_url}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_2171_2043)">
                                                                        <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_2171_2043">
                                                                            <rect width="22" height="22" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg></span>
                                                            </div>
                                                        </div>
                                                        <div className={Styles.border} ></div>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div> : <NoDataAvailablePopup noOfPost={[].concat(...instagramContext?.competitorsData?.filter(data => (data.hideView !== true)).map(data => {
                                        return data?.posts?.data?.sort((a, b) => {
                                            const aTotal = a.likes.summary.total_count + b.likes.summary.total_count;
                                            const bTotal = a.comments.summary.total_count + b.comments.summary.total_count;
                                            return aTotal - bTotal;
                                        })?.slice(0, 3)
                                    }))?.sort((a, b) => a.likes.summary.total_count - b.likes.summary.total_count)?.filter(data => data !== undefined)?.length} selectedPeriod={30} cardName="postlist" />
                                }

                            </> : <PremiumPopup cardName="postlist" />

                            }


                        </div>





                    </>

                }







            </div>
            <Tooltip id="my-tooltip" style={{ width: '18vw', borderRadius: 10, fontFamily: 'Poppins', fontSize: 10, fontStyle: 'normal', fontWeight: "normal", lineHeight: "normal", letterSpacing: 0.36, zIndex: 12 }} />
        </div>
    )
}