import React, { useContext, useEffect } from "react";
import { createNavigationContext } from '../../store/navigation'
import LinkedinOverview from "../LinkedinOverview/LinkedinOverview";
import LinkedinFollowerPage from "../LinkedinFollowerPage/LinkedinFollowerPage";
import LinkedinVisitors from "../LinkedinVisitors/LinkedinVisitors";
import LinkedinContent from "../LinkedinContent/LinkedinContent";
import Styles from './linkedin.module.css'
import { CreateLinkedinAnalyticContext } from "../../store/linkedinAnalytic";
import { UserNavigationContext } from "../../store/NavigationProvider";
import Report from "../LinkedinReport/Report";
const Linkedin = () => {
    const context = useContext(createNavigationContext)
    const linkedinContext = useContext(CreateLinkedinAnalyticContext)
    const navbarContext = useContext(createNavigationContext)
    const userNavigation = useContext(UserNavigationContext)
    

   
    useEffect(() => {
        navbarContext.getInfo()
        //linkedinContext.getLinkedinAnalyticsData()
        linkedinContext.getLinkedinOverviewPageAnalyticaData()
        linkedinContext.getLinkedinFollowerPageAnalyticaData()
        linkedinContext.getLinkedinVisitorPageAnalyticaData()
        linkedinContext.getLinkedinContentPageAnalyticaData()
        
        // eslint-disable-next-line
    }, [userNavigation.activeSocialMediaName])

    
    

    return (
        <div className={Styles.linkedin_overview_container} >
            {context.active === 'overview' && <LinkedinOverview />}
            {context.active === 'followers' && <LinkedinFollowerPage />}
            {context.active === 'visitors' && <LinkedinVisitors />}
            {context.active === 'content' && <LinkedinContent />}
            {context.active === 'reports' && <Report/> }
        </div>
    )
}

export default Linkedin