import React from 'react'
import './LoadingBall.css'

const LoadingBall = () => {
    return (
        <div className='loader-container-component'>
            <div className='center'>
                <div className='loader-component'>
                    <div className='ball'></div>
                    <div className='ball'></div>
                    <div className='ball'></div>
                </div>
                <span>Please don't close or refresh the page.</span>
            </div>
        </div>
    )
}

export default LoadingBall