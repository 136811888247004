import React, { useContext, useEffect, useState } from 'react'
import Styles from './AdsLibrary.module.css'
import InstagramNavbar from '../../Component/InstagramNavbar/InstagramNavbar'
import { UserNavigationContext } from '../../store/NavigationProvider'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import ActiveSocialAccountSkeleton from '../../Component/ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton'
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount'
import axios from 'axios'
import { url } from '../../Util/constant'
import AdsLibrarySkeletion from '../../Component/AdsLibrarySkeleton/AdsLibrarySkeletion'
import AdsFilterPopup from '../../Component/AdsFiltersPopup/AdsFilterPopup'
import { toast } from 'react-toastify'
import FacebookNavbar from '../../Component/FacebookNavbar/FacebookNavbar'
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import verify from '../../assets/facebook_verified.png'
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent'

const AdsLibrary = ({ page }) => {
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const facebookContent = useContext(CreateFacebookAnalyticContext)
    const accountContext = useContext(UserConnectedAccountContext)
    // eslint-disable-next-line
    const [search, setSearch] = useState('')
    const [ads, setAds] = useState([])
    const [loading, setLoading] = useState(false)
    const [isFilterActive, setIsFilterActive] = useState(false)
    const [filters, setFilters] = useState({
        country: 'IN',
        platform: page === "instagram" ? 'INSTAGRAM' : 'FACEBOOK',
        status: 'INACTIVE',
        language: 'en',
        mediaType: 'ALL',
        cursor: ''
    });
    // eslint-disable-next-line
    const [isSearchComplete, setIsSearchComplete] = useState(false)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [searchResult, setsearchResult] = useState([])
    // eslint-disable-next-line
    const [afterCursor, setafterCursor] = useState('')
    const [selectedSearch, setselectedSearch] = useState('')

    const searchPage = async (text) => {
        if (text === "") return;

        try {
            let workspaceId = localStorage.getItem('organizationId');
            const socialId = accountContext.accounts?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.socialId;
            const accountType = page === "instagram" ? "instagram" : "facebookpage";

            const searchResult = await axios.get(`${url}analytics/v2/facebook/searchPage/${workspaceId}/${socialId}/${text}/${accountType}`);

            setsearchResult(searchResult.data.pageInfo);
            setIsSearchComplete(true);
        } catch (error) {
            console.log(error);

        }
    };


    const getAds = async (loadMore) => {
        try {
            if (loadMore) {
                setLoadMoreLoading(true)
            } else {
                setLoading(true)
            }

            const response = await axios.get(`${url}analytics/ads/library`, {
                params: {
                    pageId: selectedSearch,
                    ...filters
                }
            });
            if (loadMore) {
                setAds([...ads, ...response.data.ads])
            } else {
                setAds(response.data.ads)
            }
            if (response.data.ads.length > 0) {
                setFilters({ ...filters, cursor: response.data.cursor })
            } else {
                setFilters({ ...filters, cursor: '' })
            }

            if (loadMore) {
                setLoadMoreLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.log(error)

            setLoading(false)
            setLoadMoreLoading(false)
        }
    }

    const handleFilterPopup = () => {
        setIsFilterActive(false)
    }

    const copyAdUrl = (url) => {
        navigator.clipboard.writeText(url)
        toast.success('Ad url copied!')
    }

    const getIcons = (type) => {
        if (type === 'facebook') {
            return <svg width="14px" height="14px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11 21C11 18 11 15 11 12C11 9.8125 11.5 8 15 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 13H11H15" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        } else if (type === 'instagram') {
            return <svg width="14px" height="14px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z" stroke="#000000" stroke-width="1.5"></path><path d="M17.5 6.51L17.51 6.49889" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        } else if (type === 'messenger') {
            return <svg width="14px" height="14px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M7 12L17 12" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7 8L13 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 20.2895V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V15C21 16.1046 20.1046 17 19 17H7.96125C7.35368 17 6.77906 17.2762 6.39951 17.7506L4.06852 20.6643C3.71421 21.1072 3 20.8567 3 20.2895Z" stroke="#000000" stroke-width="1.5"></path></svg>
        } else if (type === 'audience_network') {
            return <svg width="14px" height="14px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 14V14C13 11.2386 15.2386 9 18 9V9C20.7614 9 23 11.2386 23 14V14.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        } else if (type === "whatsapp") {
            return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" /></svg>
        }
    }

    useEffect(() => {
        if (selectedSearch !== "") {
            getAds()
        }
        // eslint-disable-next-line 
    }, [selectedSearch])

    const handleOnSearch = (string) => {

        setSearch(string)
        searchPage(string)
        if (string === "") {
            setsearchResult([]); setIsSearchComplete(false)
            return
        }
    }



    const onClickSearchResult = (data) => {
        setsearchResult([]);
        setIsSearchComplete(false)
        setafterCursor('');
        setselectedSearch(data.id);
    }
    return (
        <div className={Styles.ads_main_container} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {isFilterActive ? <AdsFilterPopup close={handleFilterPopup} filters={filters} setFilters={setFilters} getAds={getAds} /> : null}
            {page === "instagram" ? instagramContext.competitorPageLoading ? <ActiveSocialAccountSkeleton selected={instagramContext.selectedPeriod} /> : <ActiveSocialAccount page="instagram" disableFilter={false} loading={instagramContext.competitorPageLoading} selected={instagramContext.selectedPeriod === 30 ? (instagramContext.selectedPeriod + 1) : instagramContext.selectedPeriod} onChangePeriod={(value) => { instagramContext.setselectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); instagramContext.setfilterLoading(true) }} /> : facebookContent.contentPageLoading ? <ActiveSocialAccountSkeleton selected={facebookContent.selectedPeriod} /> : <ActiveSocialAccount page="facebookpage" loading={facebookContent.contentPageLoading} selected={facebookContent.selectedPeriod === 30 ? (facebookContent.selectedPeriod + 1) : facebookContent.selectedPeriod} onChangePeriod={(value) => { facebookContent.handleSelectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "facebookpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); facebookContent.onChangecontentPageFilterLoading(true) }} />}

            {page === "instagram" ? <InstagramNavbar active="ads" /> : <FacebookNavbar active="ads" />}
            <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} >
            <div className={Styles.search_bar_container} >
                <div className={Styles.input_box} >
                    <div style={{ width: 400 }}>

                        <ReactSearchAutocomplete
                            styling={{ height: "30px", boxShadow: 'none' }}
                            placeholder="search page"
                            showNoResults={false}
                            items={searchResult}
                            onSearch={handleOnSearch}
                            onClear={() => { setsearchResult([]); setSearch(''); setselectedSearch(''); setafterCursor(''); setIsSearchComplete(false) }}
                            maxResults={0}
                        />
                    </div>

                </div>
                <button onClick={() => {
                    setIsFilterActive(true)
                }}>
                    <svg width="18px" height="18px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M3.99961 3H19.9997C20.552 3 20.9997 3.44764 20.9997 3.99987L20.9999 5.58569C21 5.85097 20.8946 6.10538 20.707 6.29295L14.2925 12.7071C14.105 12.8946 13.9996 13.149 13.9996 13.4142L13.9996 19.7192C13.9996 20.3698 13.3882 20.8472 12.7571 20.6894L10.7571 20.1894C10.3119 20.0781 9.99961 19.6781 9.99961 19.2192L9.99961 13.4142C9.99961 13.149 9.89425 12.8946 9.70672 12.7071L3.2925 6.29289C3.10496 6.10536 2.99961 5.851 2.99961 5.58579V4C2.99961 3.44772 3.44732 3 3.99961 3Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                    Filters
                </button>

                {isSearchComplete && searchResult.length === 0 && search !== '' ? <div className={Styles.search_result_container}  >
                    <span style={{ textAlign: 'center' }} >No data found!</span>
                </div> : <></>

                }
                {searchResult.length > 0 ?
                    <div className={Styles.search_result_container} >
                        {searchResult.map(data => {
                            return (
                                <div onClick={() => onClickSearchResult(data)} className={Styles.search_list} >
                                    <div className={Styles.profile_details_container} >
                                        <img alt="img" src={data?.picture?.data?.url} />
                                        <label>{data.name} <span>{data?.location?.city ? "(" + data?.location?.city + ")" : ""}</span></label>
                                        {data.is_verified ?
                                            <img src={verify} style={{ width: '10px', height: '10px' }} alt="" /> : <></>
                                        }
                                    </div>
                                    <svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`https://www.facebook.com/${data?.id}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2171_2043)">
                                            <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2171_2043">
                                                <rect width="22" height="22" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                            )
                        })
                        }

                    </div> : <></>
                }
            </div>
            <div className={Styles.container}>
                {loading ? <AdsLibrarySkeletion /> : ads.length === 0 && selectedSearch !== '' ? <div className={Styles.no_ads_container}>
                    <span>Sorry, no results were found.</span>
                </div> :
                    <div>
                        <div className={Styles.ads_container}>
                            {ads.map((data, index) => {
                                return (
                                    <div className={Styles.ad_card} key={index}>
                                        <div className={Styles.header}>
                                            <div className={Styles.top_container}>
                                                <div className={Styles.left}>
                                                    <span>{filters.status}</span>
                                                    <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="2.5" cy="2.5" r="2.5" fill={filters.status === "ACTIVE" ? '#00ECC2' : '#ff0000'} />
                                                    </svg>
                                                </div>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                    copyAdUrl(`https://www.facebook.com/ads/library/?id=${data.id}`)
                                                }}>
                                                    <path d="M8.75 7.4985C8.75 5.94119 7.30187 4.375 5.53571 4.375C5.32651 4.375 4.6369 4.37501 4.46429 4.37501C2.68909 4.37501 1.25 5.77345 1.25 7.4985C1.25 8.985 2.31858 10.229 3.75 10.5446C3.97972 10.5953 4.21879 10.622 4.46429 10.622" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.25 7.49851C6.25 9.05582 7.69813 10.622 9.46431 10.622C9.6735 10.622 10.3631 10.622 10.5357 10.622C12.3109 10.622 13.75 9.22357 13.75 7.49851C13.75 6.01201 12.6814 4.76803 11.25 4.4524C11.0203 4.40174 10.7812 4.375 10.5357 4.375" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className={Styles.bottom_border} />
                                        </div>
                                        <div className={Styles.add_info}>
                                            <div className={Styles.info}>
                                                <div className={Styles.information}>
                                                    <span>Duration</span>
                                                    <p>{data.ad_delivery_start_time} - {data.ad_delivery_stop_time || 'Ongoing'}</p>
                                                </div>
                                                <div className={Styles.information}>
                                                    <span>Gender:</span>
                                                    <p>{data.target_gender || 'N/A'}</p>
                                                </div>
                                                <div className={Styles.information}>
                                                    <span>Age:</span>
                                                    <p>{data.target_ages !== undefined ? `${data?.target_ages[0] || 'N/A'} to ${data?.target_ages[1] || 'N/A'}` : 'N/A'}</p>
                                                </div>
                                                <div className={Styles.information}>
                                                    <span>Ad Versions:</span>
                                                    <p>{data?.ad_creative_bodies?.length || 1}</p>
                                                </div>
                                                <div className={Styles.information}>
                                                    <span>Platforms:</span>
                                                    {data.publisher_platforms.map((data) => {
                                                        return getIcons(data)
                                                    })}
                                                </div>
                                                <div className={Styles.information}>
                                                    <span>Title:</span>
                                                    <p>{data?.ad_creative_link_titles?.length > 0 ? `${data.ad_creative_link_titles[0].slice(0, 40)}...` : 'N/A'}</p>
                                                </div>
                                                <div className={Styles.information}>
                                                    <span>Caption:</span>
                                                    <p>{data?.ad_creative_bodies?.length > 0 ? `${data.ad_creative_bodies[0]}` : 'N/A'}</p>
                                                </div>
                                            </div>
                                            <div className={Styles.visit_ad_container}>
                                                <button onClick={() => {
                                                    window.open(`https://www.facebook.com/ads/library/?id=${data.id}`)
                                                }}>Visit Ad</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        {filters.cursor !== "" ? <div className={Styles.load_more_container}>
                            <button onClick={() => {
                                getAds(true)
                            }} className={Styles.load_more}>{loadMoreLoading ? 'Loading...' : 'Load More'}</button>
                        </div>
                            : null}
                    </div>}
            </div>
            </div>
        </div>
    )
}

export default AdsLibrary
