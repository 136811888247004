import { createContext, useState } from 'react'

export const CommonSocialMediaContext = createContext({
    socialMediaAnalyticsData: [],
    changeSocialMediaAnalyticsData: () =>{ },
    concateSocialMediaAnalyticsData: () =>{ },
})

export const CommonSocialMediaProvider = ({ children }) => {
    const [socialMediaAnalyticsData, setsocialMediaAnalyticsData] = useState([])
  
    const changeSocialMediaAnalyticsData=(value)=>{
        setsocialMediaAnalyticsData(value)
    }

    const concateSocialMediaAnalyticsData=(value)=>{
        setsocialMediaAnalyticsData((prev)=>[...prev,value])
    }
    
    const context = {
        socialMediaAnalyticsData: socialMediaAnalyticsData,
        changeSocialMediaAnalyticsData: changeSocialMediaAnalyticsData,
        concateSocialMediaAnalyticsData: concateSocialMediaAnalyticsData
    }

    return (
        <CommonSocialMediaContext.Provider value={context} >{children}</CommonSocialMediaContext.Provider>
    )
}