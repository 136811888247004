import React from 'react'
import Styles from './PinterestPostDetails.module.css'
import { formatNumber } from '../../Util/helper'


const PinterestPostDetails = (props) => {

    let stats = {
        impression: props?.pin?.pin_metrics?.all_time === undefined ? props?.pin?.pin_metrics?.IMPRESSION : props?.pin?.pin_metrics?.all_time?.impression,
        save: props?.pin?.pin_metrics?.all_time === undefined ? props?.pin?.pin_metrics?.SAVE : props?.pin?.pin_metrics?.all_time?.save,
        outbound_clicks: props?.pin?.pin_metrics?.all_time === undefined ? props?.pin?.pin_metrics?.OUTBOUND_CLICK : props?.pin?.pin_metrics?.all_time?.outbound_click,
        engagement: props?.pin?.pin_metrics?.all_time === undefined ? props?.pin?.pin_metrics?.ENGAGEMENT : Number(props?.pin?.pin_metrics?.all_time?.pin_click) + Number(props?.pin?.pin_metrics?.all_time?.outbound_click) + Number(props?.pin?.pin_metrics?.all_time?.save),
        clickthrough: props?.pin?.pin_metrics?.all_time === undefined ? props?.pin?.pin_metrics?.CLICKTHROUGH : props?.pin?.pin_metrics?.all_time?.clickthrough,
        pin_clicks: props?.pin?.pin_metrics?.all_time === undefined ? props?.pin?.pin_metrics?.PIN_CLICK : props?.pin?.pin_metrics?.all_time?.pin_click,
    }

    return (
        <div className={Styles.pinterest_post_details_model_container} >
            <div className={Styles.container} >
                <div className={Styles.header_container} >
                    <span>Pin</span>
                    {/* Corss SVG Icon */}
                    <svg style={{ cursor: 'pointer' }} onClick={() => props.close()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg>
                </div>
                <div className={Styles.chart_container} >
                    <div className={Styles.post_content_container} >
                        <div className={Styles.image_container} >
                            {props.pin.media.media_type === "image" ? <img alt="" src={props.pin.media.images["1200x"].url} /> : <img alt="" src={props.pin.media.cover_image_url} />}
                        </div>
                        <div className={Styles.content_container} >
                            <div className={Styles.header_value_container} >
                                <span>Impression</span>
                                <span>{formatNumber( stats.impression || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Outbound Clicks</span>
                                <span>{formatNumber( stats.outbound_clicks || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Engagement</span>
                                <span>{formatNumber( stats.engagement || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Click Through</span>
                                <span>{formatNumber( stats.clickthrough || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Save</span>
                                <span>{formatNumber( stats.save || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Pin Clicks</span>
                                <span>{formatNumber( stats.pin_clicks || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Created Date</span>
                                <span>{new Date(props.pin.created_at).getFullYear() + "-" + (new Date(props.pin.created_at).getMonth() + 1) + "-" + new Date(props.pin.created_at).getDate()} {new Date(props.pin?.created_at).toLocaleTimeString('en-US')}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Creative Type</span>
                                <span>{props.pin.creative_type !== undefined ? props.pin.creative_type: "-" }</span>
                            </div>
                        </div>



                    </div>
                    <div className={Styles.commentary_container} style={{ whiteSpace: 'break-spaces' }}  >
                        {props.pin.description !== undefined ? props.pin.description : "This pin has no description" }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PinterestPostDetails