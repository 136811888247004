import { formatNumber } from '../../Util/helper'
import Styles from './StoryDetailsPopup.module.css'
const StoryDetailsPopup = ({ storyItem, onClickClose }) => {
    return (
        <div className={Styles.instagram_story_details_popup} >

            <div className={Styles.container} >
                <div className={Styles.header_container} >
                    <span>Story</span>
                    {/* Corss SVG Icon */}
                    <svg style={{ cursor: 'pointer' }} onClick={() => onClickClose()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg>
                </div>

                <div className={Styles.chart_container} >
                    <div className={Styles.post_content_container} >
                        <div className={Styles.image_container} >
                            <img alt="" src={storyItem?.media_type === "VIDEO" ? storyItem?.thumbnail_url : storyItem?.thumbnail_url} />

                        </div>
                        <div className={Styles.content_container} >
                            <div className={Styles.header_value_container} >
                                <span>Account reached</span>
                                <span>{formatNumber(storyItem?.reach?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Account engaged</span>
                                <span>{formatNumber(storyItem?.engagement?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Profile Activity</span>
                                <span>{formatNumber(storyItem?.profile_activity?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Impression</span>
                                <span>{formatNumber(storyItem?.impressions?.values[0]?.value || 0)}</span>
                            </div>

                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Views</span>
                                <span>{formatNumber(storyItem?.reach?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Engagement</span>
                                <span>{formatNumber(storyItem?.replies?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Story interactions</span>
                                <span>{formatNumber(storyItem?.total_interactions?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Likes</span>
                                <span>{formatNumber(parseInt(storyItem?.total_interactions?.values[0]?.value || 0) - (parseInt(storyItem?.shares?.values[0]?.value || 0) + parseInt(storyItem?.replies?.values[0]?.value || 0)))}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Shares</span>
                                <span>{formatNumber(storyItem?.shares?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Replies</span>
                                <span>{formatNumber(storyItem?.replies?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Navigation</span>
                                <span>{formatNumber(storyItem?.navigation?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Tap Forwards</span>
                                <span>{formatNumber(storyItem?.taps_forward?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Tap Backwards</span>
                                <span>{formatNumber(storyItem?.taps_back?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Exits</span>
                                <span>{formatNumber(storyItem?.exits?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Next story</span>
                                <span>{formatNumber(parseInt(storyItem?.navigation?.values[0]?.value || 0) - (parseInt(storyItem?.taps_forward?.value || 0) + parseInt(storyItem?.exits?.value || 0)))}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>


                            <div className={Styles.header_value_container} >
                                <span>Completion</span>
                                <span>{isFinite((parseFloat(((storyItem?.reach?.values[0]?.value - storyItem?.exits?.value) / (storyItem?.reach?.values[0]?.value)) * 100).toFixed(2))) ? (parseFloat(((storyItem?.reach?.values[0]?.value - storyItem?.exits?.value) / (storyItem?.reach?.values[0]?.value)) * 100).toFixed(2)) : 0}%</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Profile Visits</span>
                                <span>{formatNumber(storyItem?.profile_activity?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Follows</span>
                                <span>{formatNumber(storyItem?.follows?.values[0]?.value || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                            <div className={Styles.header_value_container} >
                                <span>Created At</span>
                                <span>{new Date(storyItem?.timestamp).toLocaleTimeString('en-US')}</span>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default StoryDetailsPopup