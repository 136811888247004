import { useContext, useEffect, useState } from 'react'
import Styles from './BlogListCard.module.css'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'

const BlogListCard = ({ type }) => {
    const userData = useContext(UserConnectedAccountContext)
    
    const [index, setindex] = useState(0)
    
    useEffect(()=>{
        // Start the interval
        const interval = setInterval(() => {
            onClickNext();
        }, 5000);

        // Clear the interval on component unmount or after a certain time
        return () => clearInterval(interval);
        // eslint-disable-next-line
    },[])


    const onClickNext = () => {
        if (type === "linkedin") {
            
            setindex(prev => (prev < userData.linkedinBlog.length - 1 ? prev + 1:0))
           
        }
        if (type === "instagram") {
            
            setindex(prev => (prev < userData.instagramBlog.length - 1 ? prev + 1:0))
            
        }
        if (type === "pinterest") {
            
            setindex(prev => (prev < userData.pinterestBlog.length - 1 ? prev + 1:0))
            
        }
        if (type === "facebook") {
            
            setindex(prev => (prev < userData.facebookBlog.length - 1 ? prev + 1: 0))
            
        }
    }

    const getBlogAsPerType = () => {
        if (type === "linkedin") {
            return userData?.linkedinBlog[index]?.title
        }
        if (type === "instagram") {
            return userData?.pinterestBlog[index]?.title
        }
        if (type === "pinterest") {
            return userData?.pinterestBlog[index]?.title
        }
        if (type === "facebook") {
            return userData?.facebookBlog[index]?.title
        }
    }

    const getBlogAsPerTypeImage = () => {
        if (type === "linkedin") {
            return userData?.linkedinBlog[index]?.feature_image
        }
        if (type === "instagram") {
            return userData?.pinterestBlog[index]?.feature_image
        }
        if (type === "pinterest") {
            return userData?.pinterestBlog[index]?.feature_image
        }
        if (type === "facebook") {
            return userData?.facebookBlog[index]?.feature_image
        }
    }

    const getBlogAsPerTypeSlug = () => {
        if (type === "linkedin") {
            return userData.linkedinBlog[index]?.slug
        }
        if (type === "instagram") {
            return userData.pinterestBlog[index]?.slug
        }
        if (type === "pinterest") {
            return userData.pinterestBlog[index]?.slug
        }
        if (type === "facebook") {
            return userData.facebookBlog[index]?.slug
        }
    }
    return (
        <div className={Styles.blog_list_card_container} >
            <div className={Styles.follower_container} >
                <h4>With more followers we can show you more metrics{" "}</h4>

                <span>
                    Once your account is 100 followers or more we can show you even
                    more analysis. Read how to grow your audience at <a style={{ cursor: 'pointer' }} href='https://www.creatosaurus.io/blog' >Creatosaurus Library.</a>
                </span>
            </div>

            <div className={Styles.blog_card} >
                <span className={Styles.line} ></span>
                <div className={Styles.blog_container} >
                    <img
                        alt="blogimage"
                        src={getBlogAsPerTypeImage()}
                    />

                    <a style={{textDecoration:'none'}} href={`https://www.creatosaurus.io/blog/${getBlogAsPerTypeSlug()}`} className={Styles.blog_content_container} >

                        <span style={{textDecoration:'none'}} >
                            {getBlogAsPerType()}
                        </span>
                        <button style={{textDecoration:'none'}} >Read more</button>
                    </a>

                    <svg
                        className="frame"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: 'pointer' }}
                        onClick={() => onClickNext()}
                    >
                        <path
                            d="M6.88965 9.99967H13.5563M13.5563 9.99967L10.6396 7.08301M13.5563 9.99967L10.6396 12.9163"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10.223 18.3337C14.8253 18.3337 18.5563 14.6027 18.5563 10.0003C18.5563 5.39795 14.8253 1.66699 10.223 1.66699C5.62061 1.66699 1.88965 5.39795 1.88965 10.0003C1.88965 14.6027 5.62061 18.3337 10.223 18.3337Z"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default BlogListCard