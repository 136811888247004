import { captionTruncate, formatNumber } from '../../Util/helper';
import Styles from './CompetitorPostDetails.module.css'
const CompetitorPostDetails = (props) => {
    return (
        <div className={Styles.instagram_competitor_post_details_container} >
            {props.page !== "facebookcompetitor" ?


                <div className={Styles.container} >
                    <div className={Styles.header_container} >
                        <span>Post</span>
                        {/* Corss SVG Icon */}
                        <div>
                            <p onClick={() => window.open(`${props?.data?.permalink}`, '_blank')} >Visit post</p>
                            <svg style={{ cursor: 'pointer' }} onClick={() => props.onClickClose()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                                <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                            </svg>
                        </div>
                    </div>



                    <div className={Styles.chart_container} >
                        <div className={Styles.post_content_container} >
                            <div className={Styles.image_container} >
                                {props?.data?.media_type === 'CAROUSEL_ALBUM' ? <img alt="" src={props?.data?.media_url} /> : <></>}
                                {props?.data?.media_type === "VIDEO" ? <img alt="" src={props?.data?.thumbnail_url} /> : <></>}
                                {props?.data?.media_type === "IMAGE" ? <img alt="" src={props?.data?.media_url} /> : <></>}

                            </div>
                            <div className={Styles.content_container} >
                                <div className={Styles.header_value_container} >
                                    <span>Type</span>
                                    <span>{props?.data?.media_type === "CAROUSEL_ALBUM" ? "CAROUSEL" : props?.data?.media_type}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Likes</span>
                                    <span>{formatNumber(props?.data?.like_count)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Comments</span>
                                    <span>{formatNumber(props?.data?.comments_count)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Page</span>
                                    <span>{props?.data?.username || "-"}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Created At</span>
                                    <span>{new Date(props?.data?.timestamp).getFullYear() + "-" + (new Date(props?.data?.timestamp).getMonth() + 1) + "-" + new Date(props?.data?.timestamp).getDate() || "-"}</span>
                                </div>

                            </div>

                        </div>

                        {props?.data?.caption !== undefined ? <p  >{captionTruncate(props?.data?.caption)}</p> : <p>This content has no caption</p>}
                    </div>

                </div> :
                <div className={Styles.container} >
                    <div className={Styles.header_container} >
                        <span>Post</span>
                        {/* Corss SVG Icon */}
                        <div>
                            <p onClick={() => window.open(`${props?.data?.permalink_url}`, '_blank')} >Visit post</p>
                            <svg style={{ cursor: 'pointer' }} onClick={() => props.onClickClose()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                                <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                            </svg>
                        </div>
                    </div>



                    <div className={Styles.chart_container} >
                        <div className={Styles.post_content_container} >
                            <div className={Styles.image_container} >
                                <img alt="" src={props?.data?.full_picture} />

                            </div>
                            <div className={Styles.content_container} >
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Likes</span>
                                    <span>{formatNumber(props?.data?.likes.summary.total_count)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Comments</span>
                                    <span>{formatNumber(props?.data?.likes.summary.total_count)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Created At</span>
                                    <span>{new Date(props?.data?.created_time).getFullYear() + "-" + (new Date(props?.data?.created_time).getMonth() + 1) + "-" + new Date(props?.data?.created_time).getDate() || "-"}</span>
                                </div>

                            </div>

                        </div>

                        {props?.data?.message !== undefined ? <p  >{captionTruncate(props?.data?.message)}</p> : <p>This content has no caption</p>}
                    </div>
                </div>
            }
        </div>
    )
}

export default CompetitorPostDetails