import Styles from './GraphSkeleton.module.css'
const GraphSkeleton = ({ Style, hideHeader }) => {
    return (
        <section style={Style ? Style : {}} className={Styles.graph_skeleton} >
            {hideHeader ? <></> : <section className={`${Styles.header_container} ${Styles.skeleton}`} >

            </section>}

            <section className={`${Styles.follower_chart_container} ${Styles.skeleton}`} >

            </section>

            <section className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

            </section>



            <section className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

            </section>



            <section className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

            </section>

        </section>
    )
}

export default GraphSkeleton