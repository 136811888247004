export const regions = [
    {
        "name": {
            "value": "Abilene, Texas"
        },
        "country": "urn:li:country:us",
        "id": 4,
        "urn": "urn:li:region:4",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Greater Boston"
        },
        "country": "urn:li:country:us",
        "id": 7,
        "urn": "urn:li:region:7",
        "states": [
            "urn:li:state:(urn:li:country:us,MA)",
            "urn:li:state:(urn:li:country:us,NH)",
            "urn:li:state:(urn:li:country:us,ME)",
            "urn:li:state:(urn:li:country:us,CT)",
            "urn:li:state:(urn:li:country:us,VT)"
        ]
    },
    {
        "name": {
            "value": "Albany, Georgia"
        },
        "country": "urn:li:country:us",
        "id": 12,
        "urn": "urn:li:region:12",
        "states": [
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Greater Chicago"
        },
        "country": "urn:li:country:us",
        "id": 14,
        "urn": "urn:li:region:14",
        "states": [
            "urn:li:state:(urn:li:country:us,IL)",
            "urn:li:state:(urn:li:country:us,IN)",
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "Albany, New York"
        },
        "country": "urn:li:country:us",
        "id": 16,
        "urn": "urn:li:region:16",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,VT)",
            "urn:li:state:(urn:li:country:us,MA)"
        ]
    },
    {
        "name": {
            "value": "Albuquerque, New Mexico"
        },
        "country": "urn:li:country:us",
        "id": 20,
        "urn": "urn:li:region:20",
        "states": [
            "urn:li:state:(urn:li:country:us,NM)",
            "urn:li:state:(urn:li:country:us,CO)",
            "urn:li:state:(urn:li:country:us,AZ)"
        ]
    },
    {
        "name": {
            "value": "Cincinnati, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 21,
        "urn": "urn:li:region:21",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Alexandria, Louisiana"
        },
        "country": "urn:li:country:us",
        "id": 22,
        "urn": "urn:li:region:22",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)",
            "urn:li:state:(urn:li:country:us,MS)",
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Allentown, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 24,
        "urn": "urn:li:region:24",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Altoona, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 27,
        "urn": "urn:li:region:27",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Cleveland/Akron, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 28,
        "urn": "urn:li:region:28",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Dallas/Fort Worth"
        },
        "country": "urn:li:country:us",
        "id": 31,
        "urn": "urn:li:region:31",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,OK)"
        ]
    },
    {
        "name": {
            "value": "Amarillo, Texas"
        },
        "country": "urn:li:country:us",
        "id": 32,
        "urn": "urn:li:region:32",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,NM)",
            "urn:li:state:(urn:li:country:us,OK)",
            "urn:li:state:(urn:li:country:us,KS)",
            "urn:li:state:(urn:li:country:us,CO)"
        ]
    },
    {
        "name": {
            "value": "Greater Denver"
        },
        "country": "urn:li:country:us",
        "id": 34,
        "urn": "urn:li:region:34",
        "states": [
            "urn:li:state:(urn:li:country:us,CO)",
            "urn:li:state:(urn:li:country:us,NE)",
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Greater Detroit"
        },
        "country": "urn:li:country:us",
        "id": 35,
        "urn": "urn:li:region:35",
        "states": [
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Anchorage, Alaska"
        },
        "country": "urn:li:country:us",
        "id": 38,
        "urn": "urn:li:region:38",
        "states": [
            "urn:li:state:(urn:li:country:us,AK)"
        ]
    },
    {
        "name": {
            "value": "Houston, Texas"
        },
        "country": "urn:li:country:us",
        "id": 42,
        "urn": "urn:li:region:42",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Anniston, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 45,
        "urn": "urn:li:region:45",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Oshkosh, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 46,
        "urn": "urn:li:region:46",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "Asheville, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 48,
        "urn": "urn:li:region:48",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)",
            "urn:li:state:(urn:li:country:us,TN)"
        ]
    },
    {
        "name": {
            "value": "Greater Los Angeles"
        },
        "country": "urn:li:country:us",
        "id": 49,
        "urn": "urn:li:region:49",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)",
            "urn:li:state:(urn:li:country:us,AZ)"
        ]
    },
    {
        "name": {
            "value": "Athens, Georgia"
        },
        "country": "urn:li:country:us",
        "id": 50,
        "urn": "urn:li:region:50",
        "states": [
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Orange County, California"
        },
        "country": "urn:li:country:us",
        "id": 51,
        "urn": "urn:li:region:51",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Greater Atlanta"
        },
        "country": "urn:li:country:us",
        "id": 52,
        "urn": "urn:li:region:52",
        "states": [
            "urn:li:state:(urn:li:country:us,GA)",
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Miami/Fort Lauderdale"
        },
        "country": "urn:li:country:us",
        "id": 56,
        "urn": "urn:li:region:56",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Auburn, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 58,
        "urn": "urn:li:region:58",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Augusta, Georgia"
        },
        "country": "urn:li:country:us",
        "id": 60,
        "urn": "urn:li:region:60",
        "states": [
            "urn:li:state:(urn:li:country:us,GA)",
            "urn:li:state:(urn:li:country:us,SC)"
        ]
    },
    {
        "name": {
            "value": "Greater Milwaukee"
        },
        "country": "urn:li:country:us",
        "id": 63,
        "urn": "urn:li:region:63",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)",
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Austin, Texas"
        },
        "country": "urn:li:country:us",
        "id": 64,
        "urn": "urn:li:region:64",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Bakersfield, California"
        },
        "country": "urn:li:country:us",
        "id": 68,
        "urn": "urn:li:region:68",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Greater New York City"
        },
        "country": "urn:li:country:us",
        "id": 70,
        "urn": "urn:li:region:70",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,NJ)",
            "urn:li:state:(urn:li:country:us,CT)",
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,MA)"
        ]
    },
    {
        "name": {
            "value": "Bangor, Maine"
        },
        "country": "urn:li:country:us",
        "id": 73,
        "urn": "urn:li:region:73",
        "states": [
            "urn:li:state:(urn:li:country:us,ME)"
        ]
    },
    {
        "name": {
            "value": "Barnstable/Yarmouth, Massachusetts"
        },
        "country": "urn:li:country:us",
        "id": 74,
        "urn": "urn:li:region:74",
        "states": [
            "urn:li:state:(urn:li:country:us,MA)"
        ]
    },
    {
        "name": {
            "value": "Baton Rouge, Louisiana"
        },
        "country": "urn:li:country:us",
        "id": 76,
        "urn": "urn:li:region:76",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)",
            "urn:li:state:(urn:li:country:us,MS)"
        ]
    },
    {
        "name": {
            "value": "Greater Philadelphia"
        },
        "country": "urn:li:country:us",
        "id": 77,
        "urn": "urn:li:region:77",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,NJ)",
            "urn:li:state:(urn:li:country:us,DE)",
            "urn:li:state:(urn:li:country:us,MD)"
        ]
    },
    {
        "name": {
            "value": "Portland, Oregon"
        },
        "country": "urn:li:country:us",
        "id": 79,
        "urn": "urn:li:region:79",
        "states": [
            "urn:li:state:(urn:li:country:us,OR)",
            "urn:li:state:(urn:li:country:us,WA)"
        ]
    },
    {
        "name": {
            "value": "Sacramento, California"
        },
        "country": "urn:li:country:us",
        "id": 82,
        "urn": "urn:li:region:82",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)",
            "urn:li:state:(urn:li:country:us,NV)"
        ]
    },
    {
        "name": {
            "value": "Beaumont/Port Arthur, Texas"
        },
        "country": "urn:li:country:us",
        "id": 83,
        "urn": "urn:li:region:83",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "San Francisco Bay"
        },
        "country": "urn:li:country:us",
        "id": 84,
        "urn": "urn:li:region:84",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Bellingham, Washington"
        },
        "country": "urn:li:country:us",
        "id": 86,
        "urn": "urn:li:region:86",
        "states": [
            "urn:li:state:(urn:li:country:us,WA)",
            "urn:li:state:(urn:li:country:us,AK)"
        ]
    },
    {
        "name": {
            "value": "Benton Harbor, Michigan"
        },
        "country": "urn:li:country:us",
        "id": 87,
        "urn": "urn:li:region:87",
        "states": [
            "urn:li:state:(urn:li:country:us,MI)",
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Billings, Montana"
        },
        "country": "urn:li:country:us",
        "id": 88,
        "urn": "urn:li:region:88",
        "states": [
            "urn:li:state:(urn:li:country:us,MT)",
            "urn:li:state:(urn:li:country:us,WY)"
        ]
    },
    {
        "name": {
            "value": "Greater Seattle"
        },
        "country": "urn:li:country:us",
        "id": 91,
        "urn": "urn:li:region:91",
        "states": [
            "urn:li:state:(urn:li:country:us,WA)"
        ]
    },
    {
        "name": {
            "value": "Biloxi, Mississippi"
        },
        "country": "urn:li:country:us",
        "id": 92,
        "urn": "urn:li:region:92",
        "states": [
            "urn:li:state:(urn:li:country:us,MS)"
        ]
    },
    {
        "name": {
            "value": "Ithaca, New York"
        },
        "country": "urn:li:country:us",
        "id": 96,
        "urn": "urn:li:region:96",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Washington D.C. Metro"
        },
        "country": "urn:li:country:us",
        "id": 97,
        "urn": "urn:li:region:97",
        "states": [
            "urn:li:state:(urn:li:country:us,VA)",
            "urn:li:state:(urn:li:country:us,MD)",
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Birmingham, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 100,
        "urn": "urn:li:region:100",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Bismarck, North Dakota"
        },
        "country": "urn:li:country:us",
        "id": 101,
        "urn": "urn:li:region:101",
        "states": [
            "urn:li:state:(urn:li:country:us,ND)",
            "urn:li:state:(urn:li:country:us,SD)",
            "urn:li:state:(urn:li:country:us,MT)"
        ]
    },
    {
        "name": {
            "value": "Bloomington, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 102,
        "urn": "urn:li:region:102",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Bloomington/Normal, Illinois"
        },
        "country": "urn:li:country:us",
        "id": 104,
        "urn": "urn:li:region:104",
        "states": [
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Boise, Idaho"
        },
        "country": "urn:li:country:us",
        "id": 108,
        "urn": "urn:li:region:108",
        "states": [
            "urn:li:state:(urn:li:country:us,ID)",
            "urn:li:state:(urn:li:country:us,OR)",
            "urn:li:state:(urn:li:country:us,NV)"
        ]
    },
    {
        "name": {
            "value": "Brownsville, Texas"
        },
        "country": "urn:li:country:us",
        "id": 124,
        "urn": "urn:li:region:124",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Bryan/College Station, Texas"
        },
        "country": "urn:li:country:us",
        "id": 126,
        "urn": "urn:li:region:126",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Buffalo/Niagara, New York"
        },
        "country": "urn:li:country:us",
        "id": 128,
        "urn": "urn:li:region:128",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)"
        ]
    },
    {
        "name": {
            "value": "Burlington, Vermont"
        },
        "country": "urn:li:country:us",
        "id": 130,
        "urn": "urn:li:region:130",
        "states": [
            "urn:li:state:(urn:li:country:us,VT)",
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,NH)"
        ]
    },
    {
        "name": {
            "value": "Canton, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 132,
        "urn": "urn:li:region:132",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)"
        ]
    },
    {
        "name": {
            "value": "Casper, Wyoming"
        },
        "country": "urn:li:country:us",
        "id": 135,
        "urn": "urn:li:region:135",
        "states": [
            "urn:li:state:(urn:li:country:us,WY)"
        ]
    },
    {
        "name": {
            "value": "Cedar Rapids, Iowa"
        },
        "country": "urn:li:country:us",
        "id": 136,
        "urn": "urn:li:region:136",
        "states": [
            "urn:li:state:(urn:li:country:us,IA)"
        ]
    },
    {
        "name": {
            "value": "Urbana-Champaign, Illinois"
        },
        "country": "urn:li:country:us",
        "id": 140,
        "urn": "urn:li:region:140",
        "states": [
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Charleston, South Carolina"
        },
        "country": "urn:li:country:us",
        "id": 144,
        "urn": "urn:li:region:144",
        "states": [
            "urn:li:state:(urn:li:country:us,SC)"
        ]
    },
    {
        "name": {
            "value": "Charleston, West Virginia"
        },
        "country": "urn:li:country:us",
        "id": 148,
        "urn": "urn:li:region:148",
        "states": [
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Charlotte, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 152,
        "urn": "urn:li:region:152",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)",
            "urn:li:state:(urn:li:country:us,SC)"
        ]
    },
    {
        "name": {
            "value": "Charlottesville, Virginia"
        },
        "country": "urn:li:country:us",
        "id": 154,
        "urn": "urn:li:region:154",
        "states": [
            "urn:li:state:(urn:li:country:us,VA)",
            "urn:li:state:(urn:li:country:us,WV)"
        ]
    },
    {
        "name": {
            "value": "Chattanooga, Tennessee"
        },
        "country": "urn:li:country:us",
        "id": 156,
        "urn": "urn:li:region:156",
        "states": [
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,GA)",
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Cheyenne, Wyoming"
        },
        "country": "urn:li:country:us",
        "id": 158,
        "urn": "urn:li:region:158",
        "states": [
            "urn:li:state:(urn:li:country:us,WY)",
            "urn:li:state:(urn:li:country:us,NE)"
        ]
    },
    {
        "name": {
            "value": "Chico, California"
        },
        "country": "urn:li:country:us",
        "id": 162,
        "urn": "urn:li:region:162",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Clarksville, Tennessee"
        },
        "country": "urn:li:country:us",
        "id": 166,
        "urn": "urn:li:region:166",
        "states": [
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Colorado Springs, Colorado"
        },
        "country": "urn:li:country:us",
        "id": 172,
        "urn": "urn:li:region:172",
        "states": [
            "urn:li:state:(urn:li:country:us,CO)",
            "urn:li:state:(urn:li:country:us,CO)",
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Columbia, Missouri"
        },
        "country": "urn:li:country:us",
        "id": 174,
        "urn": "urn:li:region:174",
        "states": [
            "urn:li:state:(urn:li:country:us,MO)"
        ]
    },
    {
        "name": {
            "value": "Columbia, South Carolina"
        },
        "country": "urn:li:country:us",
        "id": 176,
        "urn": "urn:li:region:176",
        "states": [
            "urn:li:state:(urn:li:country:us,SC)"
        ]
    },
    {
        "name": {
            "value": "Columbus, Georgia"
        },
        "country": "urn:li:country:us",
        "id": 180,
        "urn": "urn:li:region:180",
        "states": [
            "urn:li:state:(urn:li:country:us,GA)",
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Columbus, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 184,
        "urn": "urn:li:region:184",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)"
        ]
    },
    {
        "name": {
            "value": "Corpus Christi, Texas"
        },
        "country": "urn:li:country:us",
        "id": 188,
        "urn": "urn:li:region:188",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Corvallis, Oregon"
        },
        "country": "urn:li:country:us",
        "id": 189,
        "urn": "urn:li:region:189",
        "states": [
            "urn:li:state:(urn:li:country:us,OR)"
        ]
    },
    {
        "name": {
            "value": "Cumberland, Maryland"
        },
        "country": "urn:li:country:us",
        "id": 190,
        "urn": "urn:li:region:190",
        "states": [
            "urn:li:state:(urn:li:country:us,MD)",
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,VA)"
        ]
    },
    {
        "name": {
            "value": "Danville, Virginia"
        },
        "country": "urn:li:country:us",
        "id": 195,
        "urn": "urn:li:region:195",
        "states": [
            "urn:li:state:(urn:li:country:us,VA)",
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Davenport, Iowa"
        },
        "country": "urn:li:country:us",
        "id": 196,
        "urn": "urn:li:region:196",
        "states": [
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,IL)",
            "urn:li:state:(urn:li:country:us,MO)"
        ]
    },
    {
        "name": {
            "value": "Dayton, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 200,
        "urn": "urn:li:region:200",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Daytona Beach, Florida"
        },
        "country": "urn:li:country:us",
        "id": 202,
        "urn": "urn:li:region:202",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Decatur, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 203,
        "urn": "urn:li:region:203",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Decatur, Illinois"
        },
        "country": "urn:li:country:us",
        "id": 204,
        "urn": "urn:li:region:204",
        "states": [
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Des Moines, Iowa"
        },
        "country": "urn:li:country:us",
        "id": 212,
        "urn": "urn:li:region:212",
        "states": [
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,MO)"
        ]
    },
    {
        "name": {
            "value": "Dothan, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 218,
        "urn": "urn:li:region:218",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,GA)",
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Dover, Delaware"
        },
        "country": "urn:li:country:us",
        "id": 219,
        "urn": "urn:li:region:219",
        "states": [
            "urn:li:state:(urn:li:country:us,DE)",
            "urn:li:state:(urn:li:country:us,MD)"
        ]
    },
    {
        "name": {
            "value": "Dubuque, Iowa"
        },
        "country": "urn:li:country:us",
        "id": 220,
        "urn": "urn:li:region:220",
        "states": [
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,WI)",
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Duluth, Minnesota"
        },
        "country": "urn:li:country:us",
        "id": 224,
        "urn": "urn:li:region:224",
        "states": [
            "urn:li:state:(urn:li:country:us,MN)",
            "urn:li:state:(urn:li:country:us,WI)",
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Eau Claire, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 229,
        "urn": "urn:li:region:229",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "El Paso, Texas"
        },
        "country": "urn:li:country:us",
        "id": 232,
        "urn": "urn:li:region:232",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,NM)"
        ]
    },
    {
        "name": {
            "value": "Elkhart, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 233,
        "urn": "urn:li:region:233",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)",
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Elmira, New York"
        },
        "country": "urn:li:country:us",
        "id": 234,
        "urn": "urn:li:region:234",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Enid, Oklahoma"
        },
        "country": "urn:li:country:us",
        "id": 235,
        "urn": "urn:li:region:235",
        "states": [
            "urn:li:state:(urn:li:country:us,OK)",
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Erie, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 236,
        "urn": "urn:li:region:236",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Eugene, Oregon"
        },
        "country": "urn:li:country:us",
        "id": 240,
        "urn": "urn:li:region:240",
        "states": [
            "urn:li:state:(urn:li:country:us,OR)"
        ]
    },
    {
        "name": {
            "value": "Evansville, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 244,
        "urn": "urn:li:region:244",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)",
            "urn:li:state:(urn:li:country:us,IL)",
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Fargo, North Dakota"
        },
        "country": "urn:li:country:us",
        "id": 252,
        "urn": "urn:li:region:252",
        "states": [
            "urn:li:state:(urn:li:country:us,ND)",
            "urn:li:state:(urn:li:country:us,MN)",
            "urn:li:state:(urn:li:country:us,SD)"
        ]
    },
    {
        "name": {
            "value": "Fayetteville, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 256,
        "urn": "urn:li:region:256",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Fayetteville, Arkansas"
        },
        "country": "urn:li:country:us",
        "id": 258,
        "urn": "urn:li:region:258",
        "states": [
            "urn:li:state:(urn:li:country:us,AR)",
            "urn:li:state:(urn:li:country:us,OK)",
            "urn:li:state:(urn:li:country:us,MO)"
        ]
    },
    {
        "name": {
            "value": "Flagstaff, Arizona"
        },
        "country": "urn:li:country:us",
        "id": 262,
        "urn": "urn:li:region:262",
        "states": [
            "urn:li:state:(urn:li:country:us,AZ)",
            "urn:li:state:(urn:li:country:us,UT)",
            "urn:li:state:(urn:li:country:us,NM)"
        ]
    },
    {
        "name": {
            "value": "Florence, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 265,
        "urn": "urn:li:region:265",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,MS)",
            "urn:li:state:(urn:li:country:us,TN)"
        ]
    },
    {
        "name": {
            "value": "Florence, South Carolina"
        },
        "country": "urn:li:country:us",
        "id": 266,
        "urn": "urn:li:region:266",
        "states": [
            "urn:li:state:(urn:li:country:us,SC)"
        ]
    },
    {
        "name": {
            "value": "Fort Collins, Colorado"
        },
        "country": "urn:li:country:us",
        "id": 267,
        "urn": "urn:li:region:267",
        "states": [
            "urn:li:state:(urn:li:country:us,CO)",
            "urn:li:state:(urn:li:country:us,WY)"
        ]
    },
    {
        "name": {
            "value": "Fort Myers, Florida"
        },
        "country": "urn:li:country:us",
        "id": 270,
        "urn": "urn:li:region:270",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Fort Pierce, Florida"
        },
        "country": "urn:li:country:us",
        "id": 271,
        "urn": "urn:li:region:271",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Fort Smith, Arkansas"
        },
        "country": "urn:li:country:us",
        "id": 272,
        "urn": "urn:li:region:272",
        "states": [
            "urn:li:state:(urn:li:country:us,AR)",
            "urn:li:state:(urn:li:country:us,OK)"
        ]
    },
    {
        "name": {
            "value": "Destin/Fort Walton Beach, Florida"
        },
        "country": "urn:li:country:us",
        "id": 275,
        "urn": "urn:li:region:275",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)",
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Fort Wayne, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 276,
        "urn": "urn:li:region:276",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)",
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Fresno, California"
        },
        "country": "urn:li:country:us",
        "id": 284,
        "urn": "urn:li:region:284",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)",
            "urn:li:state:(urn:li:country:us,NV)"
        ]
    },
    {
        "name": {
            "value": "Gadsden, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 288,
        "urn": "urn:li:region:288",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Gainesville, Florida"
        },
        "country": "urn:li:country:us",
        "id": 290,
        "urn": "urn:li:region:290",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Glens Falls, New York"
        },
        "country": "urn:li:country:us",
        "id": 292,
        "urn": "urn:li:region:292",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,VT)",
            "urn:li:state:(urn:li:country:us,NH)"
        ]
    },
    {
        "name": {
            "value": "Goldsboro, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 294,
        "urn": "urn:li:region:294",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Grand Forks, North Dakota"
        },
        "country": "urn:li:country:us",
        "id": 296,
        "urn": "urn:li:region:296",
        "states": [
            "urn:li:state:(urn:li:country:us,ND)",
            "urn:li:state:(urn:li:country:us,MN)"
        ]
    },
    {
        "name": {
            "value": "Grand Junction, Colorado"
        },
        "country": "urn:li:country:us",
        "id": 298,
        "urn": "urn:li:region:298",
        "states": [
            "urn:li:state:(urn:li:country:us,CO)",
            "urn:li:state:(urn:li:country:us,UT)"
        ]
    },
    {
        "name": {
            "value": "Greater Grand Rapids, Michigan"
        },
        "country": "urn:li:country:us",
        "id": 300,
        "urn": "urn:li:region:300",
        "states": [
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Great Falls, Montana"
        },
        "country": "urn:li:country:us",
        "id": 304,
        "urn": "urn:li:region:304",
        "states": [
            "urn:li:state:(urn:li:country:us,MT)"
        ]
    },
    {
        "name": {
            "value": "Green Bay, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 308,
        "urn": "urn:li:region:308",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)",
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Greensboro/Winston-Salem, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 312,
        "urn": "urn:li:region:312",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)",
            "urn:li:state:(urn:li:country:us,VA)"
        ]
    },
    {
        "name": {
            "value": "Greenville, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 315,
        "urn": "urn:li:region:315",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Greenville, South Carolina"
        },
        "country": "urn:li:country:us",
        "id": 316,
        "urn": "urn:li:region:316",
        "states": [
            "urn:li:state:(urn:li:country:us,SC)",
            "urn:li:state:(urn:li:country:us,NC)",
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Harrisburg, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 324,
        "urn": "urn:li:region:324",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Hartford, Connecticut"
        },
        "country": "urn:li:country:us",
        "id": 327,
        "urn": "urn:li:region:327",
        "states": [
            "urn:li:state:(urn:li:country:us,CT)",
            "urn:li:state:(urn:li:country:us,MA)"
        ]
    },
    {
        "name": {
            "value": "Hattiesburg, Mississippi"
        },
        "country": "urn:li:country:us",
        "id": 328,
        "urn": "urn:li:region:328",
        "states": [
            "urn:li:state:(urn:li:country:us,MS)",
            "urn:li:state:(urn:li:country:us,LA)"
        ]
    },
    {
        "name": {
            "value": "Hickory/Lenoir, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 329,
        "urn": "urn:li:region:329",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)",
            "urn:li:state:(urn:li:country:us,VA)"
        ]
    },
    {
        "name": {
            "value": "Hawaiian Islands"
        },
        "country": "urn:li:country:us",
        "id": 332,
        "urn": "urn:li:region:332",
        "states": [
            "urn:li:state:(urn:li:country:us,HI)"
        ]
    },
    {
        "name": {
            "value": "Houma, Louisiana"
        },
        "country": "urn:li:country:us",
        "id": 335,
        "urn": "urn:li:region:335",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)"
        ]
    },
    {
        "name": {
            "value": "Huntington, West Virginia"
        },
        "country": "urn:li:country:us",
        "id": 340,
        "urn": "urn:li:region:340",
        "states": [
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,VA)",
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,OH)"
        ]
    },
    {
        "name": {
            "value": "Huntsville, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 344,
        "urn": "urn:li:region:344",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,TN)"
        ]
    },
    {
        "name": {
            "value": "Indianapolis, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 348,
        "urn": "urn:li:region:348",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)",
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Iowa City, Iowa"
        },
        "country": "urn:li:country:us",
        "id": 350,
        "urn": "urn:li:region:350",
        "states": [
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,MO)"
        ]
    },
    {
        "name": {
            "value": "Jackson, Michigan"
        },
        "country": "urn:li:country:us",
        "id": 352,
        "urn": "urn:li:region:352",
        "states": [
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Jackson, Mississippi"
        },
        "country": "urn:li:country:us",
        "id": 356,
        "urn": "urn:li:region:356",
        "states": [
            "urn:li:state:(urn:li:country:us,MS)",
            "urn:li:state:(urn:li:country:us,LA)"
        ]
    },
    {
        "name": {
            "value": "Jackson, Tennessee"
        },
        "country": "urn:li:country:us",
        "id": 358,
        "urn": "urn:li:region:358",
        "states": [
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,MO)",
            "urn:li:state:(urn:li:country:us,MS)"
        ]
    },
    {
        "name": {
            "value": "Jacksonville, Florida"
        },
        "country": "urn:li:country:us",
        "id": 359,
        "urn": "urn:li:region:359",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)",
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Jacksonville, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 360,
        "urn": "urn:li:region:360",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Jamestown, New York"
        },
        "country": "urn:li:country:us",
        "id": 361,
        "urn": "urn:li:region:361",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Janesville/Beloit, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 362,
        "urn": "urn:li:region:362",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "Johnson City, Tennessee"
        },
        "country": "urn:li:country:us",
        "id": 366,
        "urn": "urn:li:region:366",
        "states": [
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,VA)",
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Johnstown, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 368,
        "urn": "urn:li:region:368",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,MD)"
        ]
    },
    {
        "name": {
            "value": "Jonesboro, Arkansas"
        },
        "country": "urn:li:country:us",
        "id": 370,
        "urn": "urn:li:region:370",
        "states": [
            "urn:li:state:(urn:li:country:us,AR)",
            "urn:li:state:(urn:li:country:us,MO)"
        ]
    },
    {
        "name": {
            "value": "Joplin, Missouri"
        },
        "country": "urn:li:country:us",
        "id": 371,
        "urn": "urn:li:region:371",
        "states": [
            "urn:li:state:(urn:li:country:us,MO)",
            "urn:li:state:(urn:li:country:us,KS)",
            "urn:li:state:(urn:li:country:us,OK)"
        ]
    },
    {
        "name": {
            "value": "Kalamazoo, Michigan"
        },
        "country": "urn:li:country:us",
        "id": 372,
        "urn": "urn:li:region:372",
        "states": [
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Kansas City, Missouri"
        },
        "country": "urn:li:country:us",
        "id": 376,
        "urn": "urn:li:region:376",
        "states": [
            "urn:li:state:(urn:li:country:us,MO)",
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Killeen/Temple, Texas"
        },
        "country": "urn:li:country:us",
        "id": 381,
        "urn": "urn:li:region:381",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Knoxville, Tennessee"
        },
        "country": "urn:li:country:us",
        "id": 384,
        "urn": "urn:li:region:384",
        "states": [
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Kokomo, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 385,
        "urn": "urn:li:region:385",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "La Crosse, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 387,
        "urn": "urn:li:region:387",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)",
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,MN)"
        ]
    },
    {
        "name": {
            "value": "Lafayette, Louisiana"
        },
        "country": "urn:li:country:us",
        "id": 388,
        "urn": "urn:li:region:388",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)"
        ]
    },
    {
        "name": {
            "value": "Lafayette, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 392,
        "urn": "urn:li:region:392",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)",
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Lake Charles, Louisiana"
        },
        "country": "urn:li:country:us",
        "id": 396,
        "urn": "urn:li:region:396",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)",
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Lakeland, Florida"
        },
        "country": "urn:li:country:us",
        "id": 398,
        "urn": "urn:li:region:398",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Lancaster, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 400,
        "urn": "urn:li:region:400",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,MD)"
        ]
    },
    {
        "name": {
            "value": "Lansing, Michigan"
        },
        "country": "urn:li:country:us",
        "id": 404,
        "urn": "urn:li:region:404",
        "states": [
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Laredo, Texas"
        },
        "country": "urn:li:country:us",
        "id": 408,
        "urn": "urn:li:region:408",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Las Cruces, New Mexico"
        },
        "country": "urn:li:country:us",
        "id": 410,
        "urn": "urn:li:region:410",
        "states": [
            "urn:li:state:(urn:li:country:us,NM)"
        ]
    },
    {
        "name": {
            "value": "Las Vegas, Nevada"
        },
        "country": "urn:li:country:us",
        "id": 412,
        "urn": "urn:li:region:412",
        "states": [
            "urn:li:state:(urn:li:country:us,NV)",
            "urn:li:state:(urn:li:country:us,AZ)",
            "urn:li:state:(urn:li:country:us,UT)",
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Lawrence, Kansas"
        },
        "country": "urn:li:country:us",
        "id": 415,
        "urn": "urn:li:region:415",
        "states": [
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Lawton, Oklahoma"
        },
        "country": "urn:li:country:us",
        "id": 420,
        "urn": "urn:li:region:420",
        "states": [
            "urn:li:state:(urn:li:country:us,OK)",
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Lewiston/Auburn, Maine"
        },
        "country": "urn:li:country:us",
        "id": 424,
        "urn": "urn:li:region:424",
        "states": [
            "urn:li:state:(urn:li:country:us,ME)",
            "urn:li:state:(urn:li:country:us,NH)"
        ]
    },
    {
        "name": {
            "value": "Lexington, Kentucky"
        },
        "country": "urn:li:country:us",
        "id": 428,
        "urn": "urn:li:region:428",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Lima, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 432,
        "urn": "urn:li:region:432",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)"
        ]
    },
    {
        "name": {
            "value": "Lincoln, Nebraska"
        },
        "country": "urn:li:country:us",
        "id": 436,
        "urn": "urn:li:region:436",
        "states": [
            "urn:li:state:(urn:li:country:us,NE)",
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Little Rock, Arkansas"
        },
        "country": "urn:li:country:us",
        "id": 440,
        "urn": "urn:li:region:440",
        "states": [
            "urn:li:state:(urn:li:country:us,AR)"
        ]
    },
    {
        "name": {
            "value": "Longview, Texas"
        },
        "country": "urn:li:country:us",
        "id": 442,
        "urn": "urn:li:region:442",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Louisville, Kentucky"
        },
        "country": "urn:li:country:us",
        "id": 452,
        "urn": "urn:li:region:452",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Lubbock, Texas"
        },
        "country": "urn:li:country:us",
        "id": 460,
        "urn": "urn:li:region:460",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,NM)"
        ]
    },
    {
        "name": {
            "value": "Lynchburg, Virginia"
        },
        "country": "urn:li:country:us",
        "id": 464,
        "urn": "urn:li:region:464",
        "states": [
            "urn:li:state:(urn:li:country:us,VA)"
        ]
    },
    {
        "name": {
            "value": "Macon, Georgia"
        },
        "country": "urn:li:country:us",
        "id": 468,
        "urn": "urn:li:region:468",
        "states": [
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Madison, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 472,
        "urn": "urn:li:region:472",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "Mansfield, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 480,
        "urn": "urn:li:region:480",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)"
        ]
    },
    {
        "name": {
            "value": "McAllen, Texas"
        },
        "country": "urn:li:country:us",
        "id": 488,
        "urn": "urn:li:region:488",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Medford, Oregon"
        },
        "country": "urn:li:country:us",
        "id": 489,
        "urn": "urn:li:region:489",
        "states": [
            "urn:li:state:(urn:li:country:us,OR)",
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Melbourne, Florida"
        },
        "country": "urn:li:country:us",
        "id": 490,
        "urn": "urn:li:region:490",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Greater Memphis"
        },
        "country": "urn:li:country:us",
        "id": 492,
        "urn": "urn:li:region:492",
        "states": [
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,MS)",
            "urn:li:state:(urn:li:country:us,AR)"
        ]
    },
    {
        "name": {
            "value": "Merced, California"
        },
        "country": "urn:li:country:us",
        "id": 494,
        "urn": "urn:li:region:494",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Greater Minneapolis-St. Paul"
        },
        "country": "urn:li:country:us",
        "id": 512,
        "urn": "urn:li:region:512",
        "states": [
            "urn:li:state:(urn:li:country:us,MN)",
            "urn:li:state:(urn:li:country:us,WI)",
            "urn:li:state:(urn:li:country:us,IA)"
        ]
    },
    {
        "name": {
            "value": "Missoula, Montana"
        },
        "country": "urn:li:country:us",
        "id": 514,
        "urn": "urn:li:region:514",
        "states": [
            "urn:li:state:(urn:li:country:us,MT)",
            "urn:li:state:(urn:li:country:us,ID)"
        ]
    },
    {
        "name": {
            "value": "Mobile, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 516,
        "urn": "urn:li:region:516",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,MS)"
        ]
    },
    {
        "name": {
            "value": "Modesto, California"
        },
        "country": "urn:li:country:us",
        "id": 517,
        "urn": "urn:li:region:517",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Monroe, Louisiana"
        },
        "country": "urn:li:country:us",
        "id": 520,
        "urn": "urn:li:region:520",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)",
            "urn:li:state:(urn:li:country:us,AR)"
        ]
    },
    {
        "name": {
            "value": "Montgomery, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 524,
        "urn": "urn:li:region:524",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Muncie, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 528,
        "urn": "urn:li:region:528",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Myrtle Beach, South Carolina"
        },
        "country": "urn:li:country:us",
        "id": 533,
        "urn": "urn:li:region:533",
        "states": [
            "urn:li:state:(urn:li:country:us,SC)",
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Naples, Florida"
        },
        "country": "urn:li:country:us",
        "id": 534,
        "urn": "urn:li:region:534",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Greater Nashville, TN"
        },
        "country": "urn:li:country:us",
        "id": 536,
        "urn": "urn:li:region:536",
        "states": [
            "urn:li:state:(urn:li:country:us,TN)",
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "New London/Norwich, Connecticut"
        },
        "country": "urn:li:country:us",
        "id": 552,
        "urn": "urn:li:region:552",
        "states": [
            "urn:li:state:(urn:li:country:us,CT)",
            "urn:li:state:(urn:li:country:us,RI)"
        ]
    },
    {
        "name": {
            "value": "Greater New Orleans"
        },
        "country": "urn:li:country:us",
        "id": 556,
        "urn": "urn:li:region:556",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)",
            "urn:li:state:(urn:li:country:us,MS)"
        ]
    },
    {
        "name": {
            "value": "Norfolk, Virginia"
        },
        "country": "urn:li:country:us",
        "id": 572,
        "urn": "urn:li:region:572",
        "states": [
            "urn:li:state:(urn:li:country:us,VA)",
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Ocala, Florida"
        },
        "country": "urn:li:country:us",
        "id": 579,
        "urn": "urn:li:region:579",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Odessa/Midland, Texas"
        },
        "country": "urn:li:country:us",
        "id": 580,
        "urn": "urn:li:region:580",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,NM)"
        ]
    },
    {
        "name": {
            "value": "Oklahoma City, Oklahoma"
        },
        "country": "urn:li:country:us",
        "id": 588,
        "urn": "urn:li:region:588",
        "states": [
            "urn:li:state:(urn:li:country:us,OK)",
            "urn:li:state:(urn:li:country:us,OK)"
        ]
    },
    {
        "name": {
            "value": "Greater Omaha"
        },
        "country": "urn:li:country:us",
        "id": 592,
        "urn": "urn:li:region:592",
        "states": [
            "urn:li:state:(urn:li:country:us,NE)",
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,MO)"
        ]
    },
    {
        "name": {
            "value": "Orlando, Florida"
        },
        "country": "urn:li:country:us",
        "id": 596,
        "urn": "urn:li:region:596",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Owensboro, Kentucky"
        },
        "country": "urn:li:country:us",
        "id": 599,
        "urn": "urn:li:region:599",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)",
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Panama City, Florida"
        },
        "country": "urn:li:country:us",
        "id": 601,
        "urn": "urn:li:region:601",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Parkersburg, West Virginia"
        },
        "country": "urn:li:country:us",
        "id": 602,
        "urn": "urn:li:region:602",
        "states": [
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,OH)"
        ]
    },
    {
        "name": {
            "value": "Pensacola, Florida"
        },
        "country": "urn:li:country:us",
        "id": 608,
        "urn": "urn:li:region:608",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)",
            "urn:li:state:(urn:li:country:us,AL)"
        ]
    },
    {
        "name": {
            "value": "Peoria, Illinois"
        },
        "country": "urn:li:country:us",
        "id": 612,
        "urn": "urn:li:region:612",
        "states": [
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Phoenix, Arizona"
        },
        "country": "urn:li:country:us",
        "id": 620,
        "urn": "urn:li:region:620",
        "states": [
            "urn:li:state:(urn:li:country:us,AZ)"
        ]
    },
    {
        "name": {
            "value": "Pine Bluff, Arkansas"
        },
        "country": "urn:li:country:us",
        "id": 624,
        "urn": "urn:li:region:624",
        "states": [
            "urn:li:state:(urn:li:country:us,AR)",
            "urn:li:state:(urn:li:country:us,MS)"
        ]
    },
    {
        "name": {
            "value": "Greater Pittsburgh"
        },
        "country": "urn:li:country:us",
        "id": 628,
        "urn": "urn:li:region:628",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,MD)"
        ]
    },
    {
        "name": {
            "value": "Pittsfield, Massachusetts"
        },
        "country": "urn:li:country:us",
        "id": 632,
        "urn": "urn:li:region:632",
        "states": [
            "urn:li:state:(urn:li:country:us,MA)",
            "urn:li:state:(urn:li:country:us,VT)",
            "urn:li:state:(urn:li:country:us,NY)"
        ]
    },
    {
        "name": {
            "value": "Pocatello, Idaho"
        },
        "country": "urn:li:country:us",
        "id": 634,
        "urn": "urn:li:region:634",
        "states": [
            "urn:li:state:(urn:li:country:us,ID)",
            "urn:li:state:(urn:li:country:us,WY)",
            "urn:li:state:(urn:li:country:us,UT)",
            "urn:li:state:(urn:li:country:us,MT)"
        ]
    },
    {
        "name": {
            "value": "Portland, Maine"
        },
        "country": "urn:li:country:us",
        "id": 640,
        "urn": "urn:li:region:640",
        "states": [
            "urn:li:state:(urn:li:country:us,ME)",
            "urn:li:state:(urn:li:country:us,NH)"
        ]
    },
    {
        "name": {
            "value": "Providence, Rhode Island"
        },
        "country": "urn:li:country:us",
        "id": 648,
        "urn": "urn:li:region:648",
        "states": [
            "urn:li:state:(urn:li:country:us,RI)",
            "urn:li:state:(urn:li:country:us,MA)",
            "urn:li:state:(urn:li:country:us,CT)"
        ]
    },
    {
        "name": {
            "value": "Provo, Utah"
        },
        "country": "urn:li:country:us",
        "id": 652,
        "urn": "urn:li:region:652",
        "states": [
            "urn:li:state:(urn:li:country:us,UT)"
        ]
    },
    {
        "name": {
            "value": "Pueblo, Colorado"
        },
        "country": "urn:li:country:us",
        "id": 656,
        "urn": "urn:li:region:656",
        "states": [
            "urn:li:state:(urn:li:country:us,CO)",
            "urn:li:state:(urn:li:country:us,NM)",
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Punta Gorda, Florida"
        },
        "country": "urn:li:country:us",
        "id": 658,
        "urn": "urn:li:region:658",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Raleigh-Durham, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 664,
        "urn": "urn:li:region:664",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)",
            "urn:li:state:(urn:li:country:us,VA)"
        ]
    },
    {
        "name": {
            "value": "Rapid City, South Dakota"
        },
        "country": "urn:li:country:us",
        "id": 666,
        "urn": "urn:li:region:666",
        "states": [
            "urn:li:state:(urn:li:country:us,SD)",
            "urn:li:state:(urn:li:country:us,NE)",
            "urn:li:state:(urn:li:country:us,WY)",
            "urn:li:state:(urn:li:country:us,MT)"
        ]
    },
    {
        "name": {
            "value": "Reading, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 668,
        "urn": "urn:li:region:668",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Redding, California"
        },
        "country": "urn:li:country:us",
        "id": 669,
        "urn": "urn:li:region:669",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Reno, Nevada"
        },
        "country": "urn:li:country:us",
        "id": 672,
        "urn": "urn:li:region:672",
        "states": [
            "urn:li:state:(urn:li:country:us,NV)",
            "urn:li:state:(urn:li:country:us,CA)",
            "urn:li:state:(urn:li:country:us,OR)"
        ]
    },
    {
        "name": {
            "value": "Richland/Kennewick/Pasco, Washington"
        },
        "country": "urn:li:country:us",
        "id": 674,
        "urn": "urn:li:region:674",
        "states": [
            "urn:li:state:(urn:li:country:us,WA)",
            "urn:li:state:(urn:li:country:us,OR)",
            "urn:li:state:(urn:li:country:us,ID)"
        ]
    },
    {
        "name": {
            "value": "Richmond, Virginia"
        },
        "country": "urn:li:country:us",
        "id": 676,
        "urn": "urn:li:region:676",
        "states": [
            "urn:li:state:(urn:li:country:us,VA)"
        ]
    },
    {
        "name": {
            "value": "Roanoke, Virginia"
        },
        "country": "urn:li:country:us",
        "id": 680,
        "urn": "urn:li:region:680",
        "states": [
            "urn:li:state:(urn:li:country:us,VA)",
            "urn:li:state:(urn:li:country:us,WV)"
        ]
    },
    {
        "name": {
            "value": "Rochester, Minnesota"
        },
        "country": "urn:li:country:us",
        "id": 682,
        "urn": "urn:li:region:682",
        "states": [
            "urn:li:state:(urn:li:country:us,MN)",
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "Rochester, New York"
        },
        "country": "urn:li:country:us",
        "id": 684,
        "urn": "urn:li:region:684",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Rockford, Illinois"
        },
        "country": "urn:li:country:us",
        "id": 688,
        "urn": "urn:li:region:688",
        "states": [
            "urn:li:state:(urn:li:country:us,IL)",
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "Rocky Mount, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 689,
        "urn": "urn:li:region:689",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Saginaw, Michigan"
        },
        "country": "urn:li:country:us",
        "id": 696,
        "urn": "urn:li:region:696",
        "states": [
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "St. Cloud, Minnesota"
        },
        "country": "urn:li:country:us",
        "id": 698,
        "urn": "urn:li:region:698",
        "states": [
            "urn:li:state:(urn:li:country:us,MN)",
            "urn:li:state:(urn:li:country:us,SD)"
        ]
    },
    {
        "name": {
            "value": "St. Joseph, Missouri"
        },
        "country": "urn:li:country:us",
        "id": 700,
        "urn": "urn:li:region:700",
        "states": [
            "urn:li:state:(urn:li:country:us,MO)",
            "urn:li:state:(urn:li:country:us,KS)",
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,NE)"
        ]
    },
    {
        "name": {
            "value": "Greater St. Louis"
        },
        "country": "urn:li:country:us",
        "id": 704,
        "urn": "urn:li:region:704",
        "states": [
            "urn:li:state:(urn:li:country:us,MO)",
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Salinas, California"
        },
        "country": "urn:li:country:us",
        "id": 712,
        "urn": "urn:li:region:712",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Greater Salt Lake City"
        },
        "country": "urn:li:country:us",
        "id": 716,
        "urn": "urn:li:region:716",
        "states": [
            "urn:li:state:(urn:li:country:us,UT)",
            "urn:li:state:(urn:li:country:us,WY)",
            "urn:li:state:(urn:li:country:us,NV)"
        ]
    },
    {
        "name": {
            "value": "San Angelo, Texas"
        },
        "country": "urn:li:country:us",
        "id": 720,
        "urn": "urn:li:region:720",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "San Antonio, Texas"
        },
        "country": "urn:li:country:us",
        "id": 724,
        "urn": "urn:li:region:724",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Greater San Diego"
        },
        "country": "urn:li:country:us",
        "id": 732,
        "urn": "urn:li:region:732",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "San Luis Obispo, California"
        },
        "country": "urn:li:country:us",
        "id": 746,
        "urn": "urn:li:region:746",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Santa Barbara, California"
        },
        "country": "urn:li:country:us",
        "id": 748,
        "urn": "urn:li:region:748",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Santa Fe, New Mexico"
        },
        "country": "urn:li:country:us",
        "id": 749,
        "urn": "urn:li:region:749",
        "states": [
            "urn:li:state:(urn:li:country:us,NM)",
            "urn:li:state:(urn:li:country:us,CO)"
        ]
    },
    {
        "name": {
            "value": "Sarasota, Florida"
        },
        "country": "urn:li:country:us",
        "id": 751,
        "urn": "urn:li:region:751",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Savannah, Georgia"
        },
        "country": "urn:li:country:us",
        "id": 752,
        "urn": "urn:li:region:752",
        "states": [
            "urn:li:state:(urn:li:country:us,GA)",
            "urn:li:state:(urn:li:country:us,SC)"
        ]
    },
    {
        "name": {
            "value": "Scranton, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 756,
        "urn": "urn:li:region:756",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,NY)"
        ]
    },
    {
        "name": {
            "value": "Sharon, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 761,
        "urn": "urn:li:region:761",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Sheboygan, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 762,
        "urn": "urn:li:region:762",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)"
        ]
    },
    {
        "name": {
            "value": "Sherman/Denison, Texas"
        },
        "country": "urn:li:country:us",
        "id": 764,
        "urn": "urn:li:region:764",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,OK)"
        ]
    },
    {
        "name": {
            "value": "Shreveport, Louisiana"
        },
        "country": "urn:li:country:us",
        "id": 768,
        "urn": "urn:li:region:768",
        "states": [
            "urn:li:state:(urn:li:country:us,LA)",
            "urn:li:state:(urn:li:country:us,AR)",
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Sioux City, Iowa"
        },
        "country": "urn:li:country:us",
        "id": 772,
        "urn": "urn:li:region:772",
        "states": [
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,NE)",
            "urn:li:state:(urn:li:country:us,SD)"
        ]
    },
    {
        "name": {
            "value": "Sioux Falls, South Dakota"
        },
        "country": "urn:li:country:us",
        "id": 776,
        "urn": "urn:li:region:776",
        "states": [
            "urn:li:state:(urn:li:country:us,SD)",
            "urn:li:state:(urn:li:country:us,MN)",
            "urn:li:state:(urn:li:country:us,IA)",
            "urn:li:state:(urn:li:country:us,NE)"
        ]
    },
    {
        "name": {
            "value": "South Bend, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 780,
        "urn": "urn:li:region:780",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)"
        ]
    },
    {
        "name": {
            "value": "Spokane, Washington"
        },
        "country": "urn:li:country:us",
        "id": 784,
        "urn": "urn:li:region:784",
        "states": [
            "urn:li:state:(urn:li:country:us,WA)",
            "urn:li:state:(urn:li:country:us,ID)",
            "urn:li:state:(urn:li:country:us,MT)"
        ]
    },
    {
        "name": {
            "value": "Springfield, Illinois"
        },
        "country": "urn:li:country:us",
        "id": 788,
        "urn": "urn:li:region:788",
        "states": [
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Springfield, Missouri"
        },
        "country": "urn:li:country:us",
        "id": 792,
        "urn": "urn:li:region:792",
        "states": [
            "urn:li:state:(urn:li:country:us,MO)",
            "urn:li:state:(urn:li:country:us,AR)"
        ]
    },
    {
        "name": {
            "value": "Springfield, Massachusetts"
        },
        "country": "urn:li:country:us",
        "id": 800,
        "urn": "urn:li:region:800",
        "states": [
            "urn:li:state:(urn:li:country:us,MA)",
            "urn:li:state:(urn:li:country:us,VT)",
            "urn:li:state:(urn:li:country:us,NH)"
        ]
    },
    {
        "name": {
            "value": "State College, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 805,
        "urn": "urn:li:region:805",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Steubenville, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 808,
        "urn": "urn:li:region:808",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Stockton, California"
        },
        "country": "urn:li:country:us",
        "id": 812,
        "urn": "urn:li:region:812",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Sumter, South Carolina"
        },
        "country": "urn:li:country:us",
        "id": 814,
        "urn": "urn:li:region:814",
        "states": [
            "urn:li:state:(urn:li:country:us,SC)"
        ]
    },
    {
        "name": {
            "value": "Syracuse, New York"
        },
        "country": "urn:li:country:us",
        "id": 816,
        "urn": "urn:li:region:816",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)"
        ]
    },
    {
        "name": {
            "value": "Tallahassee, Florida"
        },
        "country": "urn:li:country:us",
        "id": 824,
        "urn": "urn:li:region:824",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)",
            "urn:li:state:(urn:li:country:us,GA)"
        ]
    },
    {
        "name": {
            "value": "Tampa/St. Petersburg, Florida"
        },
        "country": "urn:li:country:us",
        "id": 828,
        "urn": "urn:li:region:828",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Terre Haute, Indiana"
        },
        "country": "urn:li:country:us",
        "id": 832,
        "urn": "urn:li:region:832",
        "states": [
            "urn:li:state:(urn:li:country:us,IN)",
            "urn:li:state:(urn:li:country:us,IL)"
        ]
    },
    {
        "name": {
            "value": "Texarkana, Texas"
        },
        "country": "urn:li:country:us",
        "id": 836,
        "urn": "urn:li:region:836",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,AR)",
            "urn:li:state:(urn:li:country:us,OK)"
        ]
    },
    {
        "name": {
            "value": "Toledo, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 840,
        "urn": "urn:li:region:840",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "Topeka, Kansas"
        },
        "country": "urn:li:country:us",
        "id": 844,
        "urn": "urn:li:region:844",
        "states": [
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Tucson, Arizona"
        },
        "country": "urn:li:country:us",
        "id": 852,
        "urn": "urn:li:region:852",
        "states": [
            "urn:li:state:(urn:li:country:us,AZ)",
            "urn:li:state:(urn:li:country:us,NM)"
        ]
    },
    {
        "name": {
            "value": "Tulsa, Oklahoma"
        },
        "country": "urn:li:country:us",
        "id": 856,
        "urn": "urn:li:region:856",
        "states": [
            "urn:li:state:(urn:li:country:us,OK)",
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Tuscaloosa, Alabama"
        },
        "country": "urn:li:country:us",
        "id": 860,
        "urn": "urn:li:region:860",
        "states": [
            "urn:li:state:(urn:li:country:us,AL)",
            "urn:li:state:(urn:li:country:us,MS)"
        ]
    },
    {
        "name": {
            "value": "Tyler, Texas"
        },
        "country": "urn:li:country:us",
        "id": 864,
        "urn": "urn:li:region:864",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Utica, New York"
        },
        "country": "urn:li:country:us",
        "id": 868,
        "urn": "urn:li:region:868",
        "states": [
            "urn:li:state:(urn:li:country:us,NY)"
        ]
    },
    {
        "name": {
            "value": "Victoria, Texas"
        },
        "country": "urn:li:country:us",
        "id": 875,
        "urn": "urn:li:region:875",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Visalia, California"
        },
        "country": "urn:li:country:us",
        "id": 878,
        "urn": "urn:li:region:878",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Waco, Texas"
        },
        "country": "urn:li:country:us",
        "id": 880,
        "urn": "urn:li:region:880",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)"
        ]
    },
    {
        "name": {
            "value": "Waterloo, Iowa"
        },
        "country": "urn:li:country:us",
        "id": 892,
        "urn": "urn:li:region:892",
        "states": [
            "urn:li:state:(urn:li:country:us,IA)"
        ]
    },
    {
        "name": {
            "value": "Wausau, Wisconsin"
        },
        "country": "urn:li:country:us",
        "id": 894,
        "urn": "urn:li:region:894",
        "states": [
            "urn:li:state:(urn:li:country:us,WI)",
            "urn:li:state:(urn:li:country:us,MI)"
        ]
    },
    {
        "name": {
            "value": "West Palm Beach, Florida"
        },
        "country": "urn:li:country:us",
        "id": 896,
        "urn": "urn:li:region:896",
        "states": [
            "urn:li:state:(urn:li:country:us,FL)"
        ]
    },
    {
        "name": {
            "value": "Wheeling, West Virginia"
        },
        "country": "urn:li:country:us",
        "id": 900,
        "urn": "urn:li:region:900",
        "states": [
            "urn:li:state:(urn:li:country:us,WV)",
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Wichita, Kansas"
        },
        "country": "urn:li:country:us",
        "id": 904,
        "urn": "urn:li:region:904",
        "states": [
            "urn:li:state:(urn:li:country:us,KS)"
        ]
    },
    {
        "name": {
            "value": "Wichita Falls, Texas"
        },
        "country": "urn:li:country:us",
        "id": 908,
        "urn": "urn:li:region:908",
        "states": [
            "urn:li:state:(urn:li:country:us,TX)",
            "urn:li:state:(urn:li:country:us,OK)"
        ]
    },
    {
        "name": {
            "value": "Williamsport, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 914,
        "urn": "urn:li:region:914",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Wilmington, North Carolina"
        },
        "country": "urn:li:country:us",
        "id": 920,
        "urn": "urn:li:region:920",
        "states": [
            "urn:li:state:(urn:li:country:us,NC)"
        ]
    },
    {
        "name": {
            "value": "Yakima, Washington"
        },
        "country": "urn:li:country:us",
        "id": 926,
        "urn": "urn:li:region:926",
        "states": [
            "urn:li:state:(urn:li:country:us,WA)",
            "urn:li:state:(urn:li:country:us,OR)"
        ]
    },
    {
        "name": {
            "value": "York, Pennsylvania"
        },
        "country": "urn:li:country:us",
        "id": 928,
        "urn": "urn:li:region:928",
        "states": [
            "urn:li:state:(urn:li:country:us,PA)",
            "urn:li:state:(urn:li:country:us,MD)"
        ]
    },
    {
        "name": {
            "value": "Youngstown, Ohio"
        },
        "country": "urn:li:country:us",
        "id": 932,
        "urn": "urn:li:region:932",
        "states": [
            "urn:li:state:(urn:li:country:us,OH)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Yuba City, California"
        },
        "country": "urn:li:country:us",
        "id": 934,
        "urn": "urn:li:region:934",
        "states": [
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "Yuma, Arizona"
        },
        "country": "urn:li:country:us",
        "id": 936,
        "urn": "urn:li:region:936",
        "states": [
            "urn:li:state:(urn:li:country:us,AZ)",
            "urn:li:state:(urn:li:country:us,CA)"
        ]
    },
    {
        "name": {
            "value": "US Military Posts in Europe"
        },
        "country": "urn:li:country:us",
        "id": 982,
        "urn": "urn:li:region:982",
        "states": []
    },
    {
        "name": {
            "value": "US Military Posts in Central America"
        },
        "country": "urn:li:country:us",
        "id": 983,
        "urn": "urn:li:region:983",
        "states": []
    },
    {
        "name": {
            "value": "US Military Posts in the Pacific"
        },
        "country": "urn:li:country:us",
        "id": 984,
        "urn": "urn:li:region:984",
        "states": []
    },
    {
        "name": {
            "value": "Hong Kong"
        },
        "country": "urn:li:country:hk",
        "id": 1129,
        "urn": "urn:li:region:1129",
        "states": []
    },
    {
        "name": {
            "value": "Aberdeen, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4542,
        "urn": "urn:li:region:4542",
        "states": []
    },
    {
        "name": {
            "value": "St Albans, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4543,
        "urn": "urn:li:region:4543",
        "states": []
    },
    {
        "name": {
            "value": "Birmingham, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4544,
        "urn": "urn:li:region:4544",
        "states": []
    },
    {
        "name": {
            "value": "Bath, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4545,
        "urn": "urn:li:region:4545",
        "states": []
    },
    {
        "name": {
            "value": "Blackburn, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4546,
        "urn": "urn:li:region:4546",
        "states": []
    },
    {
        "name": {
            "value": "Bradford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4547,
        "urn": "urn:li:region:4547",
        "states": []
    },
    {
        "name": {
            "value": "Bournemouth, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4548,
        "urn": "urn:li:region:4548",
        "states": []
    },
    {
        "name": {
            "value": "Bolton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4549,
        "urn": "urn:li:region:4549",
        "states": []
    },
    {
        "name": {
            "value": "Brighton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4550,
        "urn": "urn:li:region:4550",
        "states": []
    },
    {
        "name": {
            "value": "Bromley, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4551,
        "urn": "urn:li:region:4551",
        "states": []
    },
    {
        "name": {
            "value": "Bristol, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4552,
        "urn": "urn:li:region:4552",
        "states": []
    },
    {
        "name": {
            "value": "Belfast, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4553,
        "urn": "urn:li:region:4553",
        "states": []
    },
    {
        "name": {
            "value": "Carlisle, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4554,
        "urn": "urn:li:region:4554",
        "states": []
    },
    {
        "name": {
            "value": "Cambridge, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4555,
        "urn": "urn:li:region:4555",
        "states": []
    },
    {
        "name": {
            "value": "Cardiff, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4556,
        "urn": "urn:li:region:4556",
        "states": []
    },
    {
        "name": {
            "value": "Chester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4557,
        "urn": "urn:li:region:4557",
        "states": []
    },
    {
        "name": {
            "value": "Chelmsford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4558,
        "urn": "urn:li:region:4558",
        "states": []
    },
    {
        "name": {
            "value": "Colchester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4559,
        "urn": "urn:li:region:4559",
        "states": []
    },
    {
        "name": {
            "value": "Croydon, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4560,
        "urn": "urn:li:region:4560",
        "states": []
    },
    {
        "name": {
            "value": "Canterbury, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4561,
        "urn": "urn:li:region:4561",
        "states": []
    },
    {
        "name": {
            "value": "Coventry, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4562,
        "urn": "urn:li:region:4562",
        "states": []
    },
    {
        "name": {
            "value": "Crewe, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4563,
        "urn": "urn:li:region:4563",
        "states": []
    },
    {
        "name": {
            "value": "Dartford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4564,
        "urn": "urn:li:region:4564",
        "states": []
    },
    {
        "name": {
            "value": "Dundee, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4565,
        "urn": "urn:li:region:4565",
        "states": []
    },
    {
        "name": {
            "value": "Derby, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4566,
        "urn": "urn:li:region:4566",
        "states": []
    },
    {
        "name": {
            "value": "Dumfries, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4567,
        "urn": "urn:li:region:4567",
        "states": []
    },
    {
        "name": {
            "value": "Durham, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4568,
        "urn": "urn:li:region:4568",
        "states": []
    },
    {
        "name": {
            "value": "Darlington, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4569,
        "urn": "urn:li:region:4569",
        "states": []
    },
    {
        "name": {
            "value": "Doncaster, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4570,
        "urn": "urn:li:region:4570",
        "states": []
    },
    {
        "name": {
            "value": "Dorchester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4571,
        "urn": "urn:li:region:4571",
        "states": []
    },
    {
        "name": {
            "value": "Dudley, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4572,
        "urn": "urn:li:region:4572",
        "states": []
    },
    {
        "name": {
            "value": "London, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4573,
        "urn": "urn:li:region:4573",
        "states": []
    },
    {
        "name": {
            "value": "Edinburgh, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4574,
        "urn": "urn:li:region:4574",
        "states": []
    },
    {
        "name": {
            "value": "Enfield, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4575,
        "urn": "urn:li:region:4575",
        "states": []
    },
    {
        "name": {
            "value": "Exeter, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4576,
        "urn": "urn:li:region:4576",
        "states": []
    },
    {
        "name": {
            "value": "Falkirk, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4577,
        "urn": "urn:li:region:4577",
        "states": []
    },
    {
        "name": {
            "value": "Blackpool, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4578,
        "urn": "urn:li:region:4578",
        "states": []
    },
    {
        "name": {
            "value": "Glasgow, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4579,
        "urn": "urn:li:region:4579",
        "states": []
    },
    {
        "name": {
            "value": "Gloucester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4580,
        "urn": "urn:li:region:4580",
        "states": []
    },
    {
        "name": {
            "value": "Guernsey, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4581,
        "urn": "urn:li:region:4581",
        "states": []
    },
    {
        "name": {
            "value": "Guildford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4582,
        "urn": "urn:li:region:4582",
        "states": []
    },
    {
        "name": {
            "value": "Harrow, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4583,
        "urn": "urn:li:region:4583",
        "states": []
    },
    {
        "name": {
            "value": "Huddersfield, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4584,
        "urn": "urn:li:region:4584",
        "states": []
    },
    {
        "name": {
            "value": "Harrogate, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4585,
        "urn": "urn:li:region:4585",
        "states": []
    },
    {
        "name": {
            "value": "Hemel Hempstead, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4586,
        "urn": "urn:li:region:4586",
        "states": []
    },
    {
        "name": {
            "value": "Hereford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4587,
        "urn": "urn:li:region:4587",
        "states": []
    },
    {
        "name": {
            "value": "Outer Hebrides, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4588,
        "urn": "urn:li:region:4588",
        "states": []
    },
    {
        "name": {
            "value": "Hull, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4589,
        "urn": "urn:li:region:4589",
        "states": []
    },
    {
        "name": {
            "value": "Halifax, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4590,
        "urn": "urn:li:region:4590",
        "states": []
    },
    {
        "name": {
            "value": "Isle of Man, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4591,
        "urn": "urn:li:region:4591",
        "states": []
    },
    {
        "name": {
            "value": "Ilford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4592,
        "urn": "urn:li:region:4592",
        "states": []
    },
    {
        "name": {
            "value": "Ipswich, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4593,
        "urn": "urn:li:region:4593",
        "states": []
    },
    {
        "name": {
            "value": "Inverness, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4594,
        "urn": "urn:li:region:4594",
        "states": []
    },
    {
        "name": {
            "value": "Jersey, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4595,
        "urn": "urn:li:region:4595",
        "states": []
    },
    {
        "name": {
            "value": "Kilmarnock, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4596,
        "urn": "urn:li:region:4596",
        "states": []
    },
    {
        "name": {
            "value": "Kingston upon Thames, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4597,
        "urn": "urn:li:region:4597",
        "states": []
    },
    {
        "name": {
            "value": "Kirkwall, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4598,
        "urn": "urn:li:region:4598",
        "states": []
    },
    {
        "name": {
            "value": "Kirkcaldy, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4599,
        "urn": "urn:li:region:4599",
        "states": []
    },
    {
        "name": {
            "value": "Liverpool, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4600,
        "urn": "urn:li:region:4600",
        "states": []
    },
    {
        "name": {
            "value": "Lancaster, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4601,
        "urn": "urn:li:region:4601",
        "states": []
    },
    {
        "name": {
            "value": "Llandrindod Wells, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4602,
        "urn": "urn:li:region:4602",
        "states": []
    },
    {
        "name": {
            "value": "Leicester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4603,
        "urn": "urn:li:region:4603",
        "states": []
    },
    {
        "name": {
            "value": "Llandudno, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4604,
        "urn": "urn:li:region:4604",
        "states": []
    },
    {
        "name": {
            "value": "Lincoln, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4605,
        "urn": "urn:li:region:4605",
        "states": []
    },
    {
        "name": {
            "value": "Leeds, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4606,
        "urn": "urn:li:region:4606",
        "states": []
    },
    {
        "name": {
            "value": "Luton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4607,
        "urn": "urn:li:region:4607",
        "states": []
    },
    {
        "name": {
            "value": "Manchester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4608,
        "urn": "urn:li:region:4608",
        "states": []
    },
    {
        "name": {
            "value": "Rochester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4609,
        "urn": "urn:li:region:4609",
        "states": []
    },
    {
        "name": {
            "value": "Milton Keynes, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4610,
        "urn": "urn:li:region:4610",
        "states": []
    },
    {
        "name": {
            "value": "Motherwell, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4611,
        "urn": "urn:li:region:4611",
        "states": []
    },
    {
        "name": {
            "value": "Newcastle upon Tyne, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4612,
        "urn": "urn:li:region:4612",
        "states": []
    },
    {
        "name": {
            "value": "Nottingham, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4613,
        "urn": "urn:li:region:4613",
        "states": []
    },
    {
        "name": {
            "value": "Northampton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4614,
        "urn": "urn:li:region:4614",
        "states": []
    },
    {
        "name": {
            "value": "Newport, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4615,
        "urn": "urn:li:region:4615",
        "states": []
    },
    {
        "name": {
            "value": "Norwich, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4616,
        "urn": "urn:li:region:4616",
        "states": []
    },
    {
        "name": {
            "value": "Oldham, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4617,
        "urn": "urn:li:region:4617",
        "states": []
    },
    {
        "name": {
            "value": "Oxford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4618,
        "urn": "urn:li:region:4618",
        "states": []
    },
    {
        "name": {
            "value": "Paisley, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4619,
        "urn": "urn:li:region:4619",
        "states": []
    },
    {
        "name": {
            "value": "Peterborough, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4620,
        "urn": "urn:li:region:4620",
        "states": []
    },
    {
        "name": {
            "value": "Perth, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4621,
        "urn": "urn:li:region:4621",
        "states": []
    },
    {
        "name": {
            "value": "Plymouth, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4622,
        "urn": "urn:li:region:4622",
        "states": []
    },
    {
        "name": {
            "value": "Portsmouth, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4623,
        "urn": "urn:li:region:4623",
        "states": []
    },
    {
        "name": {
            "value": "Preston, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4624,
        "urn": "urn:li:region:4624",
        "states": []
    },
    {
        "name": {
            "value": "Reading, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4625,
        "urn": "urn:li:region:4625",
        "states": []
    },
    {
        "name": {
            "value": "Redhill, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4626,
        "urn": "urn:li:region:4626",
        "states": []
    },
    {
        "name": {
            "value": "Romford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4627,
        "urn": "urn:li:region:4627",
        "states": []
    },
    {
        "name": {
            "value": "Sheffield, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4628,
        "urn": "urn:li:region:4628",
        "states": []
    },
    {
        "name": {
            "value": "Swansea, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4629,
        "urn": "urn:li:region:4629",
        "states": []
    },
    {
        "name": {
            "value": "Stevenage, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4630,
        "urn": "urn:li:region:4630",
        "states": []
    },
    {
        "name": {
            "value": "Stockport, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4631,
        "urn": "urn:li:region:4631",
        "states": []
    },
    {
        "name": {
            "value": "Slough, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4632,
        "urn": "urn:li:region:4632",
        "states": []
    },
    {
        "name": {
            "value": "Sutton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4633,
        "urn": "urn:li:region:4633",
        "states": []
    },
    {
        "name": {
            "value": "Swindon, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4634,
        "urn": "urn:li:region:4634",
        "states": []
    },
    {
        "name": {
            "value": "Southampton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4635,
        "urn": "urn:li:region:4635",
        "states": []
    },
    {
        "name": {
            "value": "Salisbury, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4636,
        "urn": "urn:li:region:4636",
        "states": []
    },
    {
        "name": {
            "value": "Sunderland, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4637,
        "urn": "urn:li:region:4637",
        "states": []
    },
    {
        "name": {
            "value": "Southend on Sea, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4638,
        "urn": "urn:li:region:4638",
        "states": []
    },
    {
        "name": {
            "value": "Stoke-on-Trent, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4639,
        "urn": "urn:li:region:4639",
        "states": []
    },
    {
        "name": {
            "value": "Shrewsbury, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4640,
        "urn": "urn:li:region:4640",
        "states": []
    },
    {
        "name": {
            "value": "Taunton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4641,
        "urn": "urn:li:region:4641",
        "states": []
    },
    {
        "name": {
            "value": "Galashiels, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4642,
        "urn": "urn:li:region:4642",
        "states": []
    },
    {
        "name": {
            "value": "Telford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4643,
        "urn": "urn:li:region:4643",
        "states": []
    },
    {
        "name": {
            "value": "Tonbridge, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4644,
        "urn": "urn:li:region:4644",
        "states": []
    },
    {
        "name": {
            "value": "Torquay, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4645,
        "urn": "urn:li:region:4645",
        "states": []
    },
    {
        "name": {
            "value": "Truro, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4646,
        "urn": "urn:li:region:4646",
        "states": []
    },
    {
        "name": {
            "value": "Cleveland, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4647,
        "urn": "urn:li:region:4647",
        "states": []
    },
    {
        "name": {
            "value": "Twickenham, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4648,
        "urn": "urn:li:region:4648",
        "states": []
    },
    {
        "name": {
            "value": "Southall, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4649,
        "urn": "urn:li:region:4649",
        "states": []
    },
    {
        "name": {
            "value": "Warrington, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4650,
        "urn": "urn:li:region:4650",
        "states": []
    },
    {
        "name": {
            "value": "Watford, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4651,
        "urn": "urn:li:region:4651",
        "states": []
    },
    {
        "name": {
            "value": "Wakefield, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4652,
        "urn": "urn:li:region:4652",
        "states": []
    },
    {
        "name": {
            "value": "Wigan, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4653,
        "urn": "urn:li:region:4653",
        "states": []
    },
    {
        "name": {
            "value": "Worcester, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4654,
        "urn": "urn:li:region:4654",
        "states": []
    },
    {
        "name": {
            "value": "Walsall, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4655,
        "urn": "urn:li:region:4655",
        "states": []
    },
    {
        "name": {
            "value": "Wolverhampton, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4656,
        "urn": "urn:li:region:4656",
        "states": []
    },
    {
        "name": {
            "value": "York, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4657,
        "urn": "urn:li:region:4657",
        "states": []
    },
    {
        "name": {
            "value": "Lerwick, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 4658,
        "urn": "urn:li:region:4658",
        "states": []
    },
    {
        "name": {
            "value": "Montreal, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4863,
        "urn": "urn:li:region:4863",
        "states": [
            "urn:li:state:(urn:li:country:ca,QC)"
        ]
    },
    {
        "name": {
            "value": "Ontario, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4864,
        "urn": "urn:li:region:4864",
        "states": [
            "urn:li:state:(urn:li:country:ca,ON)"
        ]
    },
    {
        "name": {
            "value": "Kitchener, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4865,
        "urn": "urn:li:region:4865",
        "states": [
            "urn:li:state:(urn:li:country:ca,ON)"
        ]
    },
    {
        "name": {
            "value": "Winnipeg, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4866,
        "urn": "urn:li:region:4866",
        "states": [
            "urn:li:state:(urn:li:country:ca,MB)",
            "urn:li:state:(urn:li:country:ca,QC)"
        ]
    },
    {
        "name": {
            "value": "Prince Edward Island, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4867,
        "urn": "urn:li:region:4867",
        "states": [
            "urn:li:state:(urn:li:country:ca,PE)"
        ]
    },
    {
        "name": {
            "value": "New Brunswick, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4868,
        "urn": "urn:li:region:4868",
        "states": [
            "urn:li:state:(urn:li:country:ca,NB)"
        ]
    },
    {
        "name": {
            "value": "London, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4869,
        "urn": "urn:li:region:4869",
        "states": [
            "urn:li:state:(urn:li:country:ca,ON)"
        ]
    },
    {
        "name": {
            "value": "Yukon, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4870,
        "urn": "urn:li:region:4870",
        "states": [
            "urn:li:state:(urn:li:country:ca,YT)"
        ]
    },
    {
        "name": {
            "value": "Alberta, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4871,
        "urn": "urn:li:region:4871",
        "states": [
            "urn:li:state:(urn:li:country:ca,AB)"
        ]
    },
    {
        "name": {
            "value": "Edmonton, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4872,
        "urn": "urn:li:region:4872",
        "states": [
            "urn:li:state:(urn:li:country:ca,AB)"
        ]
    },
    {
        "name": {
            "value": "British Columbia, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4873,
        "urn": "urn:li:region:4873",
        "states": [
            "urn:li:state:(urn:li:country:ca,BC)"
        ]
    },
    {
        "name": {
            "value": "Halifax, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4874,
        "urn": "urn:li:region:4874",
        "states": [
            "urn:li:state:(urn:li:country:ca,NS)"
        ]
    },
    {
        "name": {
            "value": "Quebec, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4875,
        "urn": "urn:li:region:4875",
        "states": [
            "urn:li:state:(urn:li:country:ca,QC)"
        ]
    },
    {
        "name": {
            "value": "Toronto, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4876,
        "urn": "urn:li:region:4876",
        "states": [
            "urn:li:state:(urn:li:country:ca,ON)"
        ]
    },
    {
        "name": {
            "value": "Manitoba, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4877,
        "urn": "urn:li:region:4877",
        "states": [
            "urn:li:state:(urn:li:country:ca,MB)"
        ]
    },
    {
        "name": {
            "value": "Saskatchewan, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4878,
        "urn": "urn:li:region:4878",
        "states": [
            "urn:li:state:(urn:li:country:ca,SK)"
        ]
    },
    {
        "name": {
            "value": "Newfoundland And Labrador, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4879,
        "urn": "urn:li:region:4879",
        "states": [
            "urn:li:state:(urn:li:country:ca,NL)"
        ]
    },
    {
        "name": {
            "value": "Vancouver, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4880,
        "urn": "urn:li:region:4880",
        "states": [
            "urn:li:state:(urn:li:country:ca,BC)"
        ]
    },
    {
        "name": {
            "value": "Nova Scotia, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4881,
        "urn": "urn:li:region:4881",
        "states": [
            "urn:li:state:(urn:li:country:ca,NS)"
        ]
    },
    {
        "name": {
            "value": "Calgary, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4882,
        "urn": "urn:li:region:4882",
        "states": [
            "urn:li:state:(urn:li:country:ca,AB)"
        ]
    },
    {
        "name": {
            "value": "Nunavut, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4883,
        "urn": "urn:li:region:4883",
        "states": [
            "urn:li:state:(urn:li:country:ca,NU)"
        ]
    },
    {
        "name": {
            "value": "Ottawa, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4884,
        "urn": "urn:li:region:4884",
        "states": [
            "urn:li:state:(urn:li:country:ca,ON)",
            "urn:li:state:(urn:li:country:ca,QC)"
        ]
    },
    {
        "name": {
            "value": "Northwest Territories, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 4885,
        "urn": "urn:li:region:4885",
        "states": [
            "urn:li:state:(urn:li:country:ca,NT)"
        ]
    },
    {
        "name": {
            "value": "Adelaide, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4886,
        "urn": "urn:li:region:4886",
        "states": [
            "urn:li:state:(urn:li:country:au,SA)"
        ]
    },
    {
        "name": {
            "value": "Albury, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4887,
        "urn": "urn:li:region:4887",
        "states": [
            "urn:li:state:(urn:li:country:au,VIC)",
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Australian Capital Territory, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4888,
        "urn": "urn:li:region:4888",
        "states": []
    },
    {
        "name": {
            "value": "Ballarat, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4889,
        "urn": "urn:li:region:4889",
        "states": [
            "urn:li:state:(urn:li:country:au,VIC)"
        ]
    },
    {
        "name": {
            "value": "Bendigo, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4890,
        "urn": "urn:li:region:4890",
        "states": [
            "urn:li:state:(urn:li:country:au,VIC)"
        ]
    },
    {
        "name": {
            "value": "Bundaberg, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4891,
        "urn": "urn:li:region:4891",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "Cairns, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4892,
        "urn": "urn:li:region:4892",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "Canberra, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4893,
        "urn": "urn:li:region:4893",
        "states": [
            "urn:li:state:(urn:li:country:au,ACT)",
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Coffs Harbour, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4894,
        "urn": "urn:li:region:4894",
        "states": [
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Darwin, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4895,
        "urn": "urn:li:region:4895",
        "states": [
            "urn:li:state:(urn:li:country:au,NT)"
        ]
    },
    {
        "name": {
            "value": "Geelong West, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4896,
        "urn": "urn:li:region:4896",
        "states": [
            "urn:li:state:(urn:li:country:au,VIC)"
        ]
    },
    {
        "name": {
            "value": "Launceston, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4897,
        "urn": "urn:li:region:4897",
        "states": [
            "urn:li:state:(urn:li:country:au,TAS)"
        ]
    },
    {
        "name": {
            "value": "Mackay, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4898,
        "urn": "urn:li:region:4898",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "Mandurah, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4899,
        "urn": "urn:li:region:4899",
        "states": [
            "urn:li:state:(urn:li:country:au,WA)"
        ]
    },
    {
        "name": {
            "value": "Melbourne, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4900,
        "urn": "urn:li:region:4900",
        "states": [
            "urn:li:state:(urn:li:country:au,VIC)",
            "urn:li:state:(urn:li:country:au,NT)"
        ]
    },
    {
        "name": {
            "value": "New South Wales, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4901,
        "urn": "urn:li:region:4901",
        "states": [
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Newcastle, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4902,
        "urn": "urn:li:region:4902",
        "states": [
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Northern Territory, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4903,
        "urn": "urn:li:region:4903",
        "states": [
            "urn:li:state:(urn:li:country:au,NT)"
        ]
    },
    {
        "name": {
            "value": "Nowra, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4904,
        "urn": "urn:li:region:4904",
        "states": [
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Perth, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4905,
        "urn": "urn:li:region:4905",
        "states": [
            "urn:li:state:(urn:li:country:au,WA)"
        ]
    },
    {
        "name": {
            "value": "Queensland, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4906,
        "urn": "urn:li:region:4906",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "Rockhampton, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4907,
        "urn": "urn:li:region:4907",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "South Australia, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4908,
        "urn": "urn:li:region:4908",
        "states": [
            "urn:li:state:(urn:li:country:au,SA)",
            "urn:li:state:(urn:li:country:au,NT)"
        ]
    },
    {
        "name": {
            "value": "Brisbane, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4909,
        "urn": "urn:li:region:4909",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "Sydney, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4910,
        "urn": "urn:li:region:4910",
        "states": [
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Tasmania, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4911,
        "urn": "urn:li:region:4911",
        "states": [
            "urn:li:state:(urn:li:country:au,TAS)"
        ]
    },
    {
        "name": {
            "value": "Toowoomba, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4912,
        "urn": "urn:li:region:4912",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "Townsville, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4913,
        "urn": "urn:li:region:4913",
        "states": [
            "urn:li:state:(urn:li:country:au,QLD)"
        ]
    },
    {
        "name": {
            "value": "Victoria, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4914,
        "urn": "urn:li:region:4914",
        "states": [
            "urn:li:state:(urn:li:country:au,VIC)",
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Wagga Wagga, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4915,
        "urn": "urn:li:region:4915",
        "states": [
            "urn:li:state:(urn:li:country:au,NSW)"
        ]
    },
    {
        "name": {
            "value": "Western Australia, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4916,
        "urn": "urn:li:region:4916",
        "states": [
            "urn:li:state:(urn:li:country:au,WA)"
        ]
    },
    {
        "name": {
            "value": "Wollongong, Australia"
        },
        "country": "urn:li:country:au",
        "id": 4917,
        "urn": "urn:li:region:4917",
        "states": [
            "urn:li:state:(urn:li:country:au,NSW)",
            "urn:li:state:(urn:li:country:au,ACT)"
        ]
    },
    {
        "name": {
            "value": "Antwerp, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4918,
        "urn": "urn:li:region:4918",
        "states": [
            "urn:li:state:(urn:li:country:be,VLG)"
        ]
    },
    {
        "name": {
            "value": "Bruges, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4919,
        "urn": "urn:li:region:4919",
        "states": [
            "urn:li:state:(urn:li:country:be,VLG)"
        ]
    },
    {
        "name": {
            "value": "Brussels, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4920,
        "urn": "urn:li:region:4920",
        "states": [
            "urn:li:state:(urn:li:country:be,VLG)",
            "urn:li:state:(urn:li:country:be,WAL)",
            "urn:li:state:(urn:li:country:be,BRU)"
        ]
    },
    {
        "name": {
            "value": "Charleroi, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4921,
        "urn": "urn:li:region:4921",
        "states": [
            "urn:li:state:(urn:li:country:be,WAL)"
        ]
    },
    {
        "name": {
            "value": "Gent, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4922,
        "urn": "urn:li:region:4922",
        "states": [
            "urn:li:state:(urn:li:country:be,VLG)"
        ]
    },
    {
        "name": {
            "value": "Liège, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4923,
        "urn": "urn:li:region:4923",
        "states": [
            "urn:li:state:(urn:li:country:be,WAL)"
        ]
    },
    {
        "name": {
            "value": "Luxembourg, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4924,
        "urn": "urn:li:region:4924",
        "states": [
            "urn:li:state:(urn:li:country:be,WAL)"
        ]
    },
    {
        "name": {
            "value": "Namur, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4925,
        "urn": "urn:li:region:4925",
        "states": [
            "urn:li:state:(urn:li:country:be,WAL)"
        ]
    },
    {
        "name": {
            "value": "Seraing, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4926,
        "urn": "urn:li:region:4926",
        "states": []
    },
    {
        "name": {
            "value": "Verviers, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 4927,
        "urn": "urn:li:region:4927",
        "states": []
    },
    {
        "name": {
            "value": "Basel, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4928,
        "urn": "urn:li:region:4928",
        "states": [
            "urn:li:state:(urn:li:country:ch,BE)",
            "urn:li:state:(urn:li:country:ch,SO)",
            "urn:li:state:(urn:li:country:ch,BL)",
            "urn:li:state:(urn:li:country:ch,BS)",
            "urn:li:state:(urn:li:country:ch,AG)",
            "urn:li:state:(urn:li:country:ch,FR)"
        ]
    },
    {
        "name": {
            "value": "Bern, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4929,
        "urn": "urn:li:region:4929",
        "states": [
            "urn:li:state:(urn:li:country:ch,VS)",
            "urn:li:state:(urn:li:country:ch,FR)",
            "urn:li:state:(urn:li:country:ch,BE)"
        ]
    },
    {
        "name": {
            "value": "Geneva, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4930,
        "urn": "urn:li:region:4930",
        "states": [
            "urn:li:state:(urn:li:country:ch,GE)",
            "urn:li:state:(urn:li:country:ch,FR)"
        ]
    },
    {
        "name": {
            "value": "Graubunden, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4931,
        "urn": "urn:li:region:4931",
        "states": [
            "urn:li:state:(urn:li:country:ch,GR)"
        ]
    },
    {
        "name": {
            "value": "Lausanne, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4932,
        "urn": "urn:li:region:4932",
        "states": [
            "urn:li:state:(urn:li:country:ch,VD)",
            "urn:li:state:(urn:li:country:ch,FR)"
        ]
    },
    {
        "name": {
            "value": "Lucerne, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4933,
        "urn": "urn:li:region:4933",
        "states": [
            "urn:li:state:(urn:li:country:ch,LU)",
            "urn:li:state:(urn:li:country:ch,TI)",
            "urn:li:state:(urn:li:country:ch,UR)"
        ]
    },
    {
        "name": {
            "value": "Sankt Gallen, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4934,
        "urn": "urn:li:region:4934",
        "states": [
            "urn:li:state:(urn:li:country:ch,SG)",
            "urn:li:state:(urn:li:country:ch,AR)",
            "urn:li:state:(urn:li:country:ch,AI)"
        ]
    },
    {
        "name": {
            "value": "Ticino, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4935,
        "urn": "urn:li:region:4935",
        "states": [
            "urn:li:state:(urn:li:country:ch,TI)"
        ]
    },
    {
        "name": {
            "value": "Valais, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4936,
        "urn": "urn:li:region:4936",
        "states": []
    },
    {
        "name": {
            "value": "Winterthur, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4937,
        "urn": "urn:li:region:4937",
        "states": [
            "urn:li:state:(urn:li:country:ch,SG)",
            "urn:li:state:(urn:li:country:ch,TG)",
            "urn:li:state:(urn:li:country:ch,AR)",
            "urn:li:state:(urn:li:country:ch,AI)",
            "urn:li:state:(urn:li:country:ch,GL)"
        ]
    },
    {
        "name": {
            "value": "Zürich, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 4938,
        "urn": "urn:li:region:4938",
        "states": [
            "urn:li:state:(urn:li:country:ch,ZH)",
            "urn:li:state:(urn:li:country:ch,AG)",
            "urn:li:state:(urn:li:country:ch,TG)",
            "urn:li:state:(urn:li:country:ch,SG)",
            "urn:li:state:(urn:li:country:ch,SZ)",
            "urn:li:state:(urn:li:country:ch,SH)",
            "urn:li:state:(urn:li:country:ch,SO)",
            "urn:li:state:(urn:li:country:ch,BL)",
            "urn:li:state:(urn:li:country:ch,UR)",
            "urn:li:state:(urn:li:country:ch,ZG)",
            "urn:li:state:(urn:li:country:ch,OW)",
            "urn:li:state:(urn:li:country:ch,NW)",
            "urn:li:state:(urn:li:country:ch,BE)",
            "urn:li:state:(urn:li:country:ch,AR)"
        ]
    },
    {
        "name": {
            "value": "Aalen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4939,
        "urn": "urn:li:region:4939",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Augsburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4940,
        "urn": "urn:li:region:4940",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)",
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Bamberg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4941,
        "urn": "urn:li:region:4941",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Bayern, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4942,
        "urn": "urn:li:region:4942",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Bayreuth, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4943,
        "urn": "urn:li:region:4943",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Berlin, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4944,
        "urn": "urn:li:region:4944",
        "states": [
            "urn:li:state:(urn:li:country:de,BE)",
            "urn:li:state:(urn:li:country:de,BB)",
            "urn:li:state:(urn:li:country:de,ST)"
        ]
    },
    {
        "name": {
            "value": "Bielefeld, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4945,
        "urn": "urn:li:region:4945",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)",
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,HE)"
        ]
    },
    {
        "name": {
            "value": "Bochum, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4946,
        "urn": "urn:li:region:4946",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Bonn, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4947,
        "urn": "urn:li:region:4947",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Brandenburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4948,
        "urn": "urn:li:region:4948",
        "states": [
            "urn:li:state:(urn:li:country:de,BB)",
            "urn:li:state:(urn:li:country:de,ST)"
        ]
    },
    {
        "name": {
            "value": "Braunschweig, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4949,
        "urn": "urn:li:region:4949",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,ST)",
            "urn:li:state:(urn:li:country:de,TH)"
        ]
    },
    {
        "name": {
            "value": "Bremen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4950,
        "urn": "urn:li:region:4950",
        "states": [
            "urn:li:state:(urn:li:country:de,HB)",
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Bremerhaven, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4951,
        "urn": "urn:li:region:4951",
        "states": [
            "urn:li:state:(urn:li:country:de,SH)",
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,HH)"
        ]
    },
    {
        "name": {
            "value": "Chemnitz, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4952,
        "urn": "urn:li:region:4952",
        "states": [
            "urn:li:state:(urn:li:country:de,SN)"
        ]
    },
    {
        "name": {
            "value": "Cologne, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4953,
        "urn": "urn:li:region:4953",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)",
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Cottbus, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4954,
        "urn": "urn:li:region:4954",
        "states": [
            "urn:li:state:(urn:li:country:de,BB)",
            "urn:li:state:(urn:li:country:de,SN)"
        ]
    },
    {
        "name": {
            "value": "Cuxhaven, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4955,
        "urn": "urn:li:region:4955",
        "states": [
            "urn:li:state:(urn:li:country:de,SH)"
        ]
    },
    {
        "name": {
            "value": "Darmstadt, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4956,
        "urn": "urn:li:region:4956",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Dortmund, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4957,
        "urn": "urn:li:region:4957",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)",
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Dresden, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4958,
        "urn": "urn:li:region:4958",
        "states": [
            "urn:li:state:(urn:li:country:de,SN)",
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Duisburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4959,
        "urn": "urn:li:region:4959",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Düsseldorf, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4960,
        "urn": "urn:li:region:4960",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Emden, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4961,
        "urn": "urn:li:region:4961",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Erfurt, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4962,
        "urn": "urn:li:region:4962",
        "states": [
            "urn:li:state:(urn:li:country:de,TH)",
            "urn:li:state:(urn:li:country:de,BY)",
            "urn:li:state:(urn:li:country:de,ST)"
        ]
    },
    {
        "name": {
            "value": "Erlangen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4963,
        "urn": "urn:li:region:4963",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)",
            "urn:li:state:(urn:li:country:de,TH)"
        ]
    },
    {
        "name": {
            "value": "Essen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4964,
        "urn": "urn:li:region:4964",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Flensburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4965,
        "urn": "urn:li:region:4965",
        "states": [
            "urn:li:state:(urn:li:country:de,SH)"
        ]
    },
    {
        "name": {
            "value": "Frankfurt Am Main, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4966,
        "urn": "urn:li:region:4966",
        "states": [
            "urn:li:state:(urn:li:country:de,HE)",
            "urn:li:state:(urn:li:country:de,RP)",
            "urn:li:state:(urn:li:country:de,BY)",
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Frankfurt, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4967,
        "urn": "urn:li:region:4967",
        "states": [
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Freiburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4968,
        "urn": "urn:li:region:4968",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Fulda, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4969,
        "urn": "urn:li:region:4969",
        "states": [
            "urn:li:state:(urn:li:country:de,HE)",
            "urn:li:state:(urn:li:country:de,TH)",
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Fürth, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4970,
        "urn": "urn:li:region:4970",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Gera, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4971,
        "urn": "urn:li:region:4971",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)",
            "urn:li:state:(urn:li:country:de,SN)"
        ]
    },
    {
        "name": {
            "value": "Greifswald, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4972,
        "urn": "urn:li:region:4972",
        "states": [
            "urn:li:state:(urn:li:country:de,MV)"
        ]
    },
    {
        "name": {
            "value": "Görlitz, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4973,
        "urn": "urn:li:region:4973",
        "states": [
            "urn:li:state:(urn:li:country:de,SN)"
        ]
    },
    {
        "name": {
            "value": "Göttingen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4974,
        "urn": "urn:li:region:4974",
        "states": [
            "urn:li:state:(urn:li:country:de,TH)",
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Hagen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4975,
        "urn": "urn:li:region:4975",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)",
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Halle-Neustadt, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4976,
        "urn": "urn:li:region:4976",
        "states": [
            "urn:li:state:(urn:li:country:de,ST)",
            "urn:li:state:(urn:li:country:de,TH)"
        ]
    },
    {
        "name": {
            "value": "Hamburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4977,
        "urn": "urn:li:region:4977",
        "states": [
            "urn:li:state:(urn:li:country:de,HH)",
            "urn:li:state:(urn:li:country:de,SH)",
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,MV)"
        ]
    },
    {
        "name": {
            "value": "Hamm, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4978,
        "urn": "urn:li:region:4978",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Hanau Am Main, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4979,
        "urn": "urn:li:region:4979",
        "states": [
            "urn:li:state:(urn:li:country:de,HE)"
        ]
    },
    {
        "name": {
            "value": "Hannover, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4980,
        "urn": "urn:li:region:4980",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Heidelberg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4981,
        "urn": "urn:li:region:4981",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)",
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Heilbronn, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4982,
        "urn": "urn:li:region:4982",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Jena, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4983,
        "urn": "urn:li:region:4983",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)",
            "urn:li:state:(urn:li:country:de,SN)",
            "urn:li:state:(urn:li:country:de,TH)"
        ]
    },
    {
        "name": {
            "value": "Karlsruhe, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4984,
        "urn": "urn:li:region:4984",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)",
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Kassel, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4985,
        "urn": "urn:li:region:4985",
        "states": [
            "urn:li:state:(urn:li:country:de,HE)",
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,TH)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Kempten (Allgäu), Germany"
        },
        "country": "urn:li:country:de",
        "id": 4986,
        "urn": "urn:li:region:4986",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Kiel, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4987,
        "urn": "urn:li:region:4987",
        "states": [
            "urn:li:state:(urn:li:country:de,SH)"
        ]
    },
    {
        "name": {
            "value": "Koblenz, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4988,
        "urn": "urn:li:region:4988",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Konstanz, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4989,
        "urn": "urn:li:region:4989",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)",
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Kreisfreie Stadt Aachen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4990,
        "urn": "urn:li:region:4990",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Landkreis Ostprignitz-Ruppin, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4991,
        "urn": "urn:li:region:4991",
        "states": [
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Landkreis Prignitz, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4992,
        "urn": "urn:li:region:4992",
        "states": [
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Landkreis Uckermark, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4993,
        "urn": "urn:li:region:4993",
        "states": [
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Landshut, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4994,
        "urn": "urn:li:region:4994",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Leipzig, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4995,
        "urn": "urn:li:region:4995",
        "states": [
            "urn:li:state:(urn:li:country:de,SN)",
            "urn:li:state:(urn:li:country:de,ST)",
            "urn:li:state:(urn:li:country:de,TH)"
        ]
    },
    {
        "name": {
            "value": "Lübeck, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4996,
        "urn": "urn:li:region:4996",
        "states": [
            "urn:li:state:(urn:li:country:de,MV)",
            "urn:li:state:(urn:li:country:de,SH)",
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Mainz, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4997,
        "urn": "urn:li:region:4997",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Mannheim, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4998,
        "urn": "urn:li:region:4998",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)",
            "urn:li:state:(urn:li:country:de,BW)",
            "urn:li:state:(urn:li:country:de,HE)"
        ]
    },
    {
        "name": {
            "value": "Mecklenburg-Vorpommern, Germany"
        },
        "country": "urn:li:country:de",
        "id": 4999,
        "urn": "urn:li:region:4999",
        "states": [
            "urn:li:state:(urn:li:country:de,MV)"
        ]
    },
    {
        "name": {
            "value": "Munich, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5000,
        "urn": "urn:li:region:5000",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Mönchengladbach, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5001,
        "urn": "urn:li:region:5001",
        "states": [
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Münster, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5002,
        "urn": "urn:li:region:5002",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Neubrandenburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5003,
        "urn": "urn:li:region:5003",
        "states": [
            "urn:li:state:(urn:li:country:de,MV)",
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Neumünster, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5004,
        "urn": "urn:li:region:5004",
        "states": [
            "urn:li:state:(urn:li:country:de,SH)",
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Neustadt, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5005,
        "urn": "urn:li:region:5005",
        "states": [
            "urn:li:state:(urn:li:country:de,ST)",
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Nordhorn, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5006,
        "urn": "urn:li:region:5006",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Nürnberg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5007,
        "urn": "urn:li:region:5007",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Offenbach, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5008,
        "urn": "urn:li:region:5008",
        "states": [
            "urn:li:state:(urn:li:country:de,HE)"
        ]
    },
    {
        "name": {
            "value": "Oldenburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5009,
        "urn": "urn:li:region:5009",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Osnabrück, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5010,
        "urn": "urn:li:region:5010",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Paderborn, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5011,
        "urn": "urn:li:region:5011",
        "states": [
            "urn:li:state:(urn:li:country:de,HE)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Passau, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5012,
        "urn": "urn:li:region:5012",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Pforzheim, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5013,
        "urn": "urn:li:region:5013",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Plauen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5014,
        "urn": "urn:li:region:5014",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Potsdam, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5015,
        "urn": "urn:li:region:5015",
        "states": [
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Regensburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5016,
        "urn": "urn:li:region:5016",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Reutlingen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5017,
        "urn": "urn:li:region:5017",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Rheine, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5018,
        "urn": "urn:li:region:5018",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)"
        ]
    },
    {
        "name": {
            "value": "Rosenheim, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5019,
        "urn": "urn:li:region:5019",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Rostock, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5020,
        "urn": "urn:li:region:5020",
        "states": [
            "urn:li:state:(urn:li:country:de,MV)"
        ]
    },
    {
        "name": {
            "value": "Saarbrücken, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5021,
        "urn": "urn:li:region:5021",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)",
            "urn:li:state:(urn:li:country:de,SL)"
        ]
    },
    {
        "name": {
            "value": "Schleswig-Holstein, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5022,
        "urn": "urn:li:region:5022",
        "states": [
            "urn:li:state:(urn:li:country:de,SH)"
        ]
    },
    {
        "name": {
            "value": "Schwerin, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5023,
        "urn": "urn:li:region:5023",
        "states": [
            "urn:li:state:(urn:li:country:de,MV)",
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,BB)"
        ]
    },
    {
        "name": {
            "value": "Siegen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5024,
        "urn": "urn:li:region:5024",
        "states": [
            "urn:li:state:(urn:li:country:de,HE)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Stralsund, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5025,
        "urn": "urn:li:region:5025",
        "states": [
            "urn:li:state:(urn:li:country:de,MV)"
        ]
    },
    {
        "name": {
            "value": "Stuttgart, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5026,
        "urn": "urn:li:region:5026",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Trier, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5027,
        "urn": "urn:li:region:5027",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Tübingen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5028,
        "urn": "urn:li:region:5028",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Ulm, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5029,
        "urn": "urn:li:region:5029",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)",
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Villingen-Schwenningen, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5030,
        "urn": "urn:li:region:5030",
        "states": [
            "urn:li:state:(urn:li:country:de,BW)"
        ]
    },
    {
        "name": {
            "value": "Wahren, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5031,
        "urn": "urn:li:region:5031",
        "states": [
            "urn:li:state:(urn:li:country:de,ST)",
            "urn:li:state:(urn:li:country:de,SN)"
        ]
    },
    {
        "name": {
            "value": "Wiesbaden, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5032,
        "urn": "urn:li:region:5032",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)",
            "urn:li:state:(urn:li:country:de,HE)",
            "urn:li:state:(urn:li:country:de,NW)"
        ]
    },
    {
        "name": {
            "value": "Wilhelmshaven, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5033,
        "urn": "urn:li:region:5033",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,SH)"
        ]
    },
    {
        "name": {
            "value": "Wolfsburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5034,
        "urn": "urn:li:region:5034",
        "states": [
            "urn:li:state:(urn:li:country:de,NI)",
            "urn:li:state:(urn:li:country:de,ST)"
        ]
    },
    {
        "name": {
            "value": "Wuppertal, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5035,
        "urn": "urn:li:region:5035",
        "states": [
            "urn:li:state:(urn:li:country:de,RP)"
        ]
    },
    {
        "name": {
            "value": "Würzburg, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5036,
        "urn": "urn:li:region:5036",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)",
            "urn:li:state:(urn:li:country:de,BW)",
            "urn:li:state:(urn:li:country:de,HE)"
        ]
    },
    {
        "name": {
            "value": "Zwickau, Germany"
        },
        "country": "urn:li:country:de",
        "id": 5037,
        "urn": "urn:li:region:5037",
        "states": [
            "urn:li:state:(urn:li:country:de,BY)"
        ]
    },
    {
        "name": {
            "value": "Copenhagen, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5038,
        "urn": "urn:li:region:5038",
        "states": [
            "urn:li:state:(urn:li:country:dk,1084)"
        ]
    },
    {
        "name": {
            "value": "Esbjerg, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5039,
        "urn": "urn:li:region:5039",
        "states": []
    },
    {
        "name": {
            "value": "Kolding, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5040,
        "urn": "urn:li:region:5040",
        "states": []
    },
    {
        "name": {
            "value": "Odense, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5041,
        "urn": "urn:li:region:5041",
        "states": []
    },
    {
        "name": {
            "value": "Randers, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5042,
        "urn": "urn:li:region:5042",
        "states": []
    },
    {
        "name": {
            "value": "Vejle, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5043,
        "urn": "urn:li:region:5043",
        "states": []
    },
    {
        "name": {
            "value": "Ålborg, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5044,
        "urn": "urn:li:region:5044",
        "states": []
    },
    {
        "name": {
            "value": "Århus, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 5045,
        "urn": "urn:li:region:5045",
        "states": []
    },
    {
        "name": {
            "value": "A Coruña, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5046,
        "urn": "urn:li:region:5046",
        "states": [
            "urn:li:state:(urn:li:country:es,GA)"
        ]
    },
    {
        "name": {
            "value": "Alacant, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5047,
        "urn": "urn:li:region:5047",
        "states": [
            "urn:li:state:(urn:li:country:es,VC)"
        ]
    },
    {
        "name": {
            "value": "Albacete, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5048,
        "urn": "urn:li:region:5048",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)",
            "urn:li:state:(urn:li:country:es,VC)",
            "urn:li:state:(urn:li:country:es,MC)"
        ]
    },
    {
        "name": {
            "value": "Albacete, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5049,
        "urn": "urn:li:region:5049",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Alcalá De Henares, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5050,
        "urn": "urn:li:region:5050",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Alcobendas, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5051,
        "urn": "urn:li:region:5051",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Alcorcón, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5052,
        "urn": "urn:li:region:5052",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Algeciras, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5053,
        "urn": "urn:li:region:5053",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Almería, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5054,
        "urn": "urn:li:region:5054",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Aragon, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5055,
        "urn": "urn:li:region:5055",
        "states": [
            "urn:li:state:(urn:li:country:es,AR)"
        ]
    },
    {
        "name": {
            "value": "Arona, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5056,
        "urn": "urn:li:region:5056",
        "states": [
            "urn:li:state:(urn:li:country:es,CN)"
        ]
    },
    {
        "name": {
            "value": "Arrecife, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5057,
        "urn": "urn:li:region:5057",
        "states": [
            "urn:li:state:(urn:li:country:es,CN)"
        ]
    },
    {
        "name": {
            "value": "Asturias, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5058,
        "urn": "urn:li:region:5058",
        "states": [
            "urn:li:state:(urn:li:country:es,AS)"
        ]
    },
    {
        "name": {
            "value": "Avilés, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5059,
        "urn": "urn:li:region:5059",
        "states": [
            "urn:li:state:(urn:li:country:es,AS)"
        ]
    },
    {
        "name": {
            "value": "Badajoz, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5060,
        "urn": "urn:li:region:5060",
        "states": [
            "urn:li:state:(urn:li:country:es,EX)"
        ]
    },
    {
        "name": {
            "value": "Badajoz, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5061,
        "urn": "urn:li:region:5061",
        "states": [
            "urn:li:state:(urn:li:country:es,EX)"
        ]
    },
    {
        "name": {
            "value": "Badalona, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5062,
        "urn": "urn:li:region:5062",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Baleares, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5063,
        "urn": "urn:li:region:5063",
        "states": [
            "urn:li:state:(urn:li:country:es,IB)"
        ]
    },
    {
        "name": {
            "value": "Barcelona, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5064,
        "urn": "urn:li:region:5064",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Bilbao, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5065,
        "urn": "urn:li:region:5065",
        "states": [
            "urn:li:state:(urn:li:country:es,PV)",
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,CB)",
            "urn:li:state:(urn:li:country:es,RI)",
            "urn:li:state:(urn:li:country:es,NC)"
        ]
    },
    {
        "name": {
            "value": "Burgos, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5066,
        "urn": "urn:li:region:5066",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Burgos, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5067,
        "urn": "urn:li:region:5067",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Cartagena, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5068,
        "urn": "urn:li:region:5068",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)",
            "urn:li:state:(urn:li:country:es,MC)"
        ]
    },
    {
        "name": {
            "value": "Castellón, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5069,
        "urn": "urn:li:region:5069",
        "states": [
            "urn:li:state:(urn:li:country:es,VC)"
        ]
    },
    {
        "name": {
            "value": "Castilla - La Mancha, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5070,
        "urn": "urn:li:region:5070",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Ceuta, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5071,
        "urn": "urn:li:region:5071",
        "states": [
            "urn:li:state:(urn:li:country:es,CE)"
        ]
    },
    {
        "name": {
            "value": "Ciudad Real, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5072,
        "urn": "urn:li:region:5072",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Ciudad Real, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5073,
        "urn": "urn:li:region:5073",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Cl, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5074,
        "urn": "urn:li:region:5074",
        "states": []
    },
    {
        "name": {
            "value": "Cuenca, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5075,
        "urn": "urn:li:region:5075",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Cáceres, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5076,
        "urn": "urn:li:region:5076",
        "states": [
            "urn:li:state:(urn:li:country:es,EX)"
        ]
    },
    {
        "name": {
            "value": "Cáceres, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5077,
        "urn": "urn:li:region:5077",
        "states": [
            "urn:li:state:(urn:li:country:es,EX)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Cádiz, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5078,
        "urn": "urn:li:region:5078",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Córdoba, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5079,
        "urn": "urn:li:region:5079",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)",
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Córdoba, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5080,
        "urn": "urn:li:region:5080",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Elx / Elche, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5081,
        "urn": "urn:li:region:5081",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Fuenlabrada, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5082,
        "urn": "urn:li:region:5082",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Ga, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5083,
        "urn": "urn:li:region:5083",
        "states": [
            "urn:li:state:(urn:li:country:es,GA)"
        ]
    },
    {
        "name": {
            "value": "Gijón, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5084,
        "urn": "urn:li:region:5084",
        "states": [
            "urn:li:state:(urn:li:country:es,AS)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Girona, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5085,
        "urn": "urn:li:region:5085",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Granada, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5086,
        "urn": "urn:li:region:5086",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Granada, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5087,
        "urn": "urn:li:region:5087",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Granollers, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5088,
        "urn": "urn:li:region:5088",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Groa De Murviedro, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5089,
        "urn": "urn:li:region:5089",
        "states": [
            "urn:li:state:(urn:li:country:es,VC)",
            "urn:li:state:(urn:li:country:es,AR)"
        ]
    },
    {
        "name": {
            "value": "Guadalajara, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5090,
        "urn": "urn:li:region:5090",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Guadalajara, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5091,
        "urn": "urn:li:region:5091",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Huelva, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5092,
        "urn": "urn:li:region:5092",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Huelva, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5093,
        "urn": "urn:li:region:5093",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Huesca, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5094,
        "urn": "urn:li:region:5094",
        "states": [
            "urn:li:state:(urn:li:country:es,AR)"
        ]
    },
    {
        "name": {
            "value": "Illes Balears, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5095,
        "urn": "urn:li:region:5095",
        "states": [
            "urn:li:state:(urn:li:country:es,IB)"
        ]
    },
    {
        "name": {
            "value": "Pamplona, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5096,
        "urn": "urn:li:region:5096",
        "states": [
            "urn:li:state:(urn:li:country:es,NC)",
            "urn:li:state:(urn:li:country:es,PV)",
            "urn:li:state:(urn:li:country:es,AR)",
            "urn:li:state:(urn:li:country:es,RI)"
        ]
    },
    {
        "name": {
            "value": "Jaén, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5097,
        "urn": "urn:li:region:5097",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)",
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Jaén, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5098,
        "urn": "urn:li:region:5098",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Jerez De La Frontera, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5099,
        "urn": "urn:li:region:5099",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "L’Hospitalet De Llobregat, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5100,
        "urn": "urn:li:region:5100",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "La Rioja, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5101,
        "urn": "urn:li:region:5101",
        "states": [
            "urn:li:state:(urn:li:country:es,RI)"
        ]
    },
    {
        "name": {
            "value": "Las Palmas De Gran Canaria, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5102,
        "urn": "urn:li:region:5102",
        "states": [
            "urn:li:state:(urn:li:country:es,CN)"
        ]
    },
    {
        "name": {
            "value": "Las Palmas, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5103,
        "urn": "urn:li:region:5103",
        "states": [
            "urn:li:state:(urn:li:country:es,CN)"
        ]
    },
    {
        "name": {
            "value": "Las Rozas De Madrid, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5104,
        "urn": "urn:li:region:5104",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Leganés, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5105,
        "urn": "urn:li:region:5105",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "León, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5106,
        "urn": "urn:li:region:5106",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "León, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5107,
        "urn": "urn:li:region:5107",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Linares, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5108,
        "urn": "urn:li:region:5108",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)",
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Lleida, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5109,
        "urn": "urn:li:region:5109",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)",
            "urn:li:state:(urn:li:country:es,AR)"
        ]
    },
    {
        "name": {
            "value": "Logroño, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5110,
        "urn": "urn:li:region:5110",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,RI)",
            "urn:li:state:(urn:li:country:es,NC)"
        ]
    },
    {
        "name": {
            "value": "Lorca, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5111,
        "urn": "urn:li:region:5111",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)",
            "urn:li:state:(urn:li:country:es,MC)"
        ]
    },
    {
        "name": {
            "value": "Lugo, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5112,
        "urn": "urn:li:region:5112",
        "states": [
            "urn:li:state:(urn:li:country:es,GA)",
            "urn:li:state:(urn:li:country:es,AS)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Madrid, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5113,
        "urn": "urn:li:region:5113",
        "states": [
            "urn:li:state:(urn:li:country:es,MD)",
            "urn:li:state:(urn:li:country:es,CM)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Manresa, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5114,
        "urn": "urn:li:region:5114",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Marbella, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5115,
        "urn": "urn:li:region:5115",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Mataró, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5116,
        "urn": "urn:li:region:5116",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Melilla, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5117,
        "urn": "urn:li:region:5117",
        "states": [
            "urn:li:state:(urn:li:country:es,ML)"
        ]
    },
    {
        "name": {
            "value": "Murcia, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5118,
        "urn": "urn:li:region:5118",
        "states": [
            "urn:li:state:(urn:li:country:es,MC)",
            "urn:li:state:(urn:li:country:es,VC)",
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Málaga, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5119,
        "urn": "urn:li:region:5119",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)"
        ]
    },
    {
        "name": {
            "value": "Mérida, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5120,
        "urn": "urn:li:region:5120",
        "states": [
            "urn:li:state:(urn:li:country:es,EX)"
        ]
    },
    {
        "name": {
            "value": "Móstoles, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5121,
        "urn": "urn:li:region:5121",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)",
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,MD)"
        ]
    },
    {
        "name": {
            "value": "Navarra, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5122,
        "urn": "urn:li:region:5122",
        "states": [
            "urn:li:state:(urn:li:country:es,NC)"
        ]
    },
    {
        "name": {
            "value": "Ourense, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5123,
        "urn": "urn:li:region:5123",
        "states": [
            "urn:li:state:(urn:li:country:es,GA)"
        ]
    },
    {
        "name": {
            "value": "Oviedo, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5124,
        "urn": "urn:li:region:5124",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,AS)"
        ]
    },
    {
        "name": {
            "value": "Palencia, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5125,
        "urn": "urn:li:region:5125",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Palencia, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5126,
        "urn": "urn:li:region:5126",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Palma, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5127,
        "urn": "urn:li:region:5127",
        "states": [
            "urn:li:state:(urn:li:country:es,IB)"
        ]
    },
    {
        "name": {
            "value": "Parla, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5128,
        "urn": "urn:li:region:5128",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Paterna, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5129,
        "urn": "urn:li:region:5129",
        "states": [
            "urn:li:state:(urn:li:country:es,AR)",
            "urn:li:state:(urn:li:country:es,VC)"
        ]
    },
    {
        "name": {
            "value": "Ponferrada, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5130,
        "urn": "urn:li:region:5130",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,GA)",
            "urn:li:state:(urn:li:country:es,AS)"
        ]
    },
    {
        "name": {
            "value": "Pozuelo De Alarcón, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5131,
        "urn": "urn:li:region:5131",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Reus, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5132,
        "urn": "urn:li:region:5132",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Sabadell, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5133,
        "urn": "urn:li:region:5133",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Salamanca, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5134,
        "urn": "urn:li:region:5134",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,EX)"
        ]
    },
    {
        "name": {
            "value": "Salamanca, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5135,
        "urn": "urn:li:region:5135",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz De Tenerife, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5136,
        "urn": "urn:li:region:5136",
        "states": [
            "urn:li:state:(urn:li:country:es,CN)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz De Tenerife, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5137,
        "urn": "urn:li:region:5137",
        "states": [
            "urn:li:state:(urn:li:country:es,CN)"
        ]
    },
    {
        "name": {
            "value": "Santander, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5138,
        "urn": "urn:li:region:5138",
        "states": [
            "urn:li:state:(urn:li:country:es,CB)",
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,AS)"
        ]
    },
    {
        "name": {
            "value": "Santiago De Compostela, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5139,
        "urn": "urn:li:region:5139",
        "states": [
            "urn:li:state:(urn:li:country:es,GA)"
        ]
    },
    {
        "name": {
            "value": "Segovia, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5140,
        "urn": "urn:li:region:5140",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Segovia, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5141,
        "urn": "urn:li:region:5141",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Sevilla, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5142,
        "urn": "urn:li:region:5142",
        "states": [
            "urn:li:state:(urn:li:country:es,AN)",
            "urn:li:state:(urn:li:country:es,EX)"
        ]
    },
    {
        "name": {
            "value": "Soria, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5143,
        "urn": "urn:li:region:5143",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Talavera De La Reina, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5144,
        "urn": "urn:li:region:5144",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)",
            "urn:li:state:(urn:li:country:es,CL)",
            "urn:li:state:(urn:li:country:es,EX)"
        ]
    },
    {
        "name": {
            "value": "Tarragona, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5145,
        "urn": "urn:li:region:5145",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Tarragona, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5146,
        "urn": "urn:li:region:5146",
        "states": [
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Teruel, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5147,
        "urn": "urn:li:region:5147",
        "states": [
            "urn:li:state:(urn:li:country:es,AR)"
        ]
    },
    {
        "name": {
            "value": "Toledo, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5148,
        "urn": "urn:li:region:5148",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Toledo, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5149,
        "urn": "urn:li:region:5149",
        "states": [
            "urn:li:state:(urn:li:country:es,CM)"
        ]
    },
    {
        "name": {
            "value": "Torrelavega, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5150,
        "urn": "urn:li:region:5150",
        "states": [
            "urn:li:state:(urn:li:country:es,CB)",
            "urn:li:state:(urn:li:country:es,AS)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Torrent, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5151,
        "urn": "urn:li:region:5151",
        "states": [
            "urn:li:state:(urn:li:country:es,VC)"
        ]
    },
    {
        "name": {
            "value": "Valencia, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5152,
        "urn": "urn:li:region:5152",
        "states": [
            "urn:li:state:(urn:li:country:es,VC)",
            "urn:li:state:(urn:li:country:es,AR)"
        ]
    },
    {
        "name": {
            "value": "Valencia, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5153,
        "urn": "urn:li:region:5153",
        "states": [
            "urn:li:state:(urn:li:country:es,VC)"
        ]
    },
    {
        "name": {
            "value": "Valladolid, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5154,
        "urn": "urn:li:region:5154",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Vc, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5155,
        "urn": "urn:li:region:5155",
        "states": []
    },
    {
        "name": {
            "value": "Vigo, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5156,
        "urn": "urn:li:region:5156",
        "states": [
            "urn:li:state:(urn:li:country:es,GA)"
        ]
    },
    {
        "name": {
            "value": "Vitoria-Gasteiz, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5157,
        "urn": "urn:li:region:5157",
        "states": [
            "urn:li:state:(urn:li:country:es,RI)",
            "urn:li:state:(urn:li:country:es,NC)",
            "urn:li:state:(urn:li:country:es,PV)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Zamora, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5158,
        "urn": "urn:li:region:5158",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Zamora, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5159,
        "urn": "urn:li:region:5159",
        "states": [
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Zaragoza, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5160,
        "urn": "urn:li:region:5160",
        "states": [
            "urn:li:state:(urn:li:country:es,AR)",
            "urn:li:state:(urn:li:country:es,NC)",
            "urn:li:state:(urn:li:country:es,CT)"
        ]
    },
    {
        "name": {
            "value": "Zaragoza, Spain"
        },
        "country": "urn:li:country:es",
        "id": 5161,
        "urn": "urn:li:region:5161",
        "states": [
            "urn:li:state:(urn:li:country:es,AR)",
            "urn:li:state:(urn:li:country:es,CL)"
        ]
    },
    {
        "name": {
            "value": "Aix-En-Provence, France"
        },
        "country": "urn:li:country:fr",
        "id": 5162,
        "urn": "urn:li:region:5162",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)",
            "urn:li:state:(urn:li:country:fr,B9)"
        ]
    },
    {
        "name": {
            "value": "Ajaccio, France"
        },
        "country": "urn:li:country:fr",
        "id": 5163,
        "urn": "urn:li:region:5163",
        "states": [
            "urn:li:state:(urn:li:country:fr,A5)"
        ]
    },
    {
        "name": {
            "value": "Albi, France"
        },
        "country": "urn:li:country:fr",
        "id": 5164,
        "urn": "urn:li:region:5164",
        "states": [
            "urn:li:state:(urn:li:country:fr,B3)",
            "urn:li:state:(urn:li:country:fr,A9)",
            "urn:li:state:(urn:li:country:fr,98)"
        ]
    },
    {
        "name": {
            "value": "Amiens, France"
        },
        "country": "urn:li:country:fr",
        "id": 5165,
        "urn": "urn:li:region:5165",
        "states": [
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,B4)",
            "urn:li:state:(urn:li:country:fr,A7)"
        ]
    },
    {
        "name": {
            "value": "Angers, France"
        },
        "country": "urn:li:country:fr",
        "id": 5166,
        "urn": "urn:li:region:5166",
        "states": [
            "urn:li:state:(urn:li:country:fr,B5)",
            "urn:li:state:(urn:li:country:fr,B7)",
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Aquitaine, France"
        },
        "country": "urn:li:country:fr",
        "id": 5167,
        "urn": "urn:li:region:5167",
        "states": [
            "urn:li:state:(urn:li:country:fr,97)"
        ]
    },
    {
        "name": {
            "value": "Aulnay-Sous-Bois, France"
        },
        "country": "urn:li:country:fr",
        "id": 5168,
        "urn": "urn:li:region:5168",
        "states": [
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,A8)"
        ]
    },
    {
        "name": {
            "value": "Auvergne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5169,
        "urn": "urn:li:region:5169",
        "states": [
            "urn:li:state:(urn:li:country:fr,98)",
            "urn:li:state:(urn:li:country:fr,B9)"
        ]
    },
    {
        "name": {
            "value": "Avignon, France"
        },
        "country": "urn:li:country:fr",
        "id": 5170,
        "urn": "urn:li:region:5170",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)",
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "Basse-Normandie, France"
        },
        "country": "urn:li:country:fr",
        "id": 5171,
        "urn": "urn:li:region:5171",
        "states": [
            "urn:li:state:(urn:li:country:fr,99)"
        ]
    },
    {
        "name": {
            "value": "Besançon, France"
        },
        "country": "urn:li:country:fr",
        "id": 5172,
        "urn": "urn:li:region:5172",
        "states": [
            "urn:li:state:(urn:li:country:fr,A6)",
            "urn:li:state:(urn:li:country:fr,B2)",
            "urn:li:state:(urn:li:country:fr,A4)"
        ]
    },
    {
        "name": {
            "value": "Blois, France"
        },
        "country": "urn:li:country:fr",
        "id": 5173,
        "urn": "urn:li:region:5173",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Bordeaux, France"
        },
        "country": "urn:li:country:fr",
        "id": 5174,
        "urn": "urn:li:region:5174",
        "states": [
            "urn:li:state:(urn:li:country:fr,97)",
            "urn:li:state:(urn:li:country:fr,B7)"
        ]
    },
    {
        "name": {
            "value": "Boulogne-Billancourt, France"
        },
        "country": "urn:li:country:fr",
        "id": 5175,
        "urn": "urn:li:region:5175",
        "states": [
            "urn:li:state:(urn:li:country:fr,A8)",
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,A7)"
        ]
    },
    {
        "name": {
            "value": "Bourges, France"
        },
        "country": "urn:li:country:fr",
        "id": 5176,
        "urn": "urn:li:region:5176",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,A1)",
            "urn:li:state:(urn:li:country:fr,98)",
            "urn:li:state:(urn:li:country:fr,B1)"
        ]
    },
    {
        "name": {
            "value": "Bourgogne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5177,
        "urn": "urn:li:region:5177",
        "states": [
            "urn:li:state:(urn:li:country:fr,A1)",
            "urn:li:state:(urn:li:country:fr,98)"
        ]
    },
    {
        "name": {
            "value": "Brest, France"
        },
        "country": "urn:li:country:fr",
        "id": 5178,
        "urn": "urn:li:region:5178",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)"
        ]
    },
    {
        "name": {
            "value": "Bretagne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5179,
        "urn": "urn:li:region:5179",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)"
        ]
    },
    {
        "name": {
            "value": "Brive-La-Gaillarde, France"
        },
        "country": "urn:li:country:fr",
        "id": 5180,
        "urn": "urn:li:region:5180",
        "states": [
            "urn:li:state:(urn:li:country:fr,B1)",
            "urn:li:state:(urn:li:country:fr,B3)",
            "urn:li:state:(urn:li:country:fr,97)",
            "urn:li:state:(urn:li:country:fr,98)"
        ]
    },
    {
        "name": {
            "value": "Béziers, France"
        },
        "country": "urn:li:country:fr",
        "id": 5181,
        "urn": "urn:li:region:5181",
        "states": [
            "urn:li:state:(urn:li:country:fr,A9)",
            "urn:li:state:(urn:li:country:fr,B3)"
        ]
    },
    {
        "name": {
            "value": "Caen, France"
        },
        "country": "urn:li:country:fr",
        "id": 5182,
        "urn": "urn:li:region:5182",
        "states": [
            "urn:li:state:(urn:li:country:fr,99)",
            "urn:li:state:(urn:li:country:fr,B5)"
        ]
    },
    {
        "name": {
            "value": "Calais, France"
        },
        "country": "urn:li:country:fr",
        "id": 5183,
        "urn": "urn:li:region:5183",
        "states": [
            "urn:li:state:(urn:li:country:fr,B4)"
        ]
    },
    {
        "name": {
            "value": "Cannes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5184,
        "urn": "urn:li:region:5184",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "Centre, France"
        },
        "country": "urn:li:country:fr",
        "id": 5185,
        "urn": "urn:li:region:5185",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,A8)",
            "urn:li:state:(urn:li:country:fr,A1)"
        ]
    },
    {
        "name": {
            "value": "Chambéry, France"
        },
        "country": "urn:li:country:fr",
        "id": 5186,
        "urn": "urn:li:region:5186",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)",
            "urn:li:state:(urn:li:country:fr,A6)"
        ]
    },
    {
        "name": {
            "value": "Champagne-Ardenne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5187,
        "urn": "urn:li:region:5187",
        "states": [
            "urn:li:state:(urn:li:country:fr,A4)",
            "urn:li:state:(urn:li:country:fr,B2)"
        ]
    },
    {
        "name": {
            "value": "Champigny-Sur-Marne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5188,
        "urn": "urn:li:region:5188",
        "states": [
            "urn:li:state:(urn:li:country:fr,A1)",
            "urn:li:state:(urn:li:country:fr,A8)"
        ]
    },
    {
        "name": {
            "value": "Charleville-Mézières, France"
        },
        "country": "urn:li:country:fr",
        "id": 5189,
        "urn": "urn:li:region:5189",
        "states": [
            "urn:li:state:(urn:li:country:fr,A4)",
            "urn:li:state:(urn:li:country:fr,B2)",
            "urn:li:state:(urn:li:country:fr,B4)"
        ]
    },
    {
        "name": {
            "value": "Châlons-Sur-Marne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5190,
        "urn": "urn:li:region:5190",
        "states": [
            "urn:li:state:(urn:li:country:fr,B2)",
            "urn:li:state:(urn:li:country:fr,A4)"
        ]
    },
    {
        "name": {
            "value": "Châteauroux, France"
        },
        "country": "urn:li:country:fr",
        "id": 5191,
        "urn": "urn:li:region:5191",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,B1)"
        ]
    },
    {
        "name": {
            "value": "Clermont-Ferrand, France"
        },
        "country": "urn:li:country:fr",
        "id": 5192,
        "urn": "urn:li:region:5192",
        "states": [
            "urn:li:state:(urn:li:country:fr,98)",
            "urn:li:state:(urn:li:country:fr,B1)",
            "urn:li:state:(urn:li:country:fr,B9)"
        ]
    },
    {
        "name": {
            "value": "Colmar, France"
        },
        "country": "urn:li:country:fr",
        "id": 5193,
        "urn": "urn:li:region:5193",
        "states": [
            "urn:li:state:(urn:li:country:fr,B2)",
            "urn:li:state:(urn:li:country:fr,C1)"
        ]
    },
    {
        "name": {
            "value": "Corse, France"
        },
        "country": "urn:li:country:fr",
        "id": 5194,
        "urn": "urn:li:region:5194",
        "states": [
            "urn:li:state:(urn:li:country:fr,A5)"
        ]
    },
    {
        "name": {
            "value": "Créteil, France"
        },
        "country": "urn:li:country:fr",
        "id": 5195,
        "urn": "urn:li:region:5195",
        "states": [
            "urn:li:state:(urn:li:country:fr,A8)",
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Dijon, France"
        },
        "country": "urn:li:country:fr",
        "id": 5196,
        "urn": "urn:li:region:5196",
        "states": [
            "urn:li:state:(urn:li:country:fr,A1)",
            "urn:li:state:(urn:li:country:fr,A6)",
            "urn:li:state:(urn:li:country:fr,A4)"
        ]
    },
    {
        "name": {
            "value": "Franche-Comte, France"
        },
        "country": "urn:li:country:fr",
        "id": 5197,
        "urn": "urn:li:region:5197",
        "states": [
            "urn:li:state:(urn:li:country:fr,A6)",
            "urn:li:state:(urn:li:country:fr,B9)"
        ]
    },
    {
        "name": {
            "value": "Fréjus, France"
        },
        "country": "urn:li:country:fr",
        "id": 5198,
        "urn": "urn:li:region:5198",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "Grenoble, France"
        },
        "country": "urn:li:country:fr",
        "id": 5199,
        "urn": "urn:li:region:5199",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)",
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "La Rochelle, France"
        },
        "country": "urn:li:country:fr",
        "id": 5200,
        "urn": "urn:li:region:5200",
        "states": [
            "urn:li:state:(urn:li:country:fr,B7)",
            "urn:li:state:(urn:li:country:fr,B5)",
            "urn:li:state:(urn:li:country:fr,97)"
        ]
    },
    {
        "name": {
            "value": "Languedoc-Roussillon, France"
        },
        "country": "urn:li:country:fr",
        "id": 5201,
        "urn": "urn:li:region:5201",
        "states": [
            "urn:li:state:(urn:li:country:fr,A9)",
            "urn:li:state:(urn:li:country:fr,B9)"
        ]
    },
    {
        "name": {
            "value": "Laval, France"
        },
        "country": "urn:li:country:fr",
        "id": 5202,
        "urn": "urn:li:region:5202",
        "states": [
            "urn:li:state:(urn:li:country:fr,B5)",
            "urn:li:state:(urn:li:country:fr,A2)",
            "urn:li:state:(urn:li:country:fr,99)"
        ]
    },
    {
        "name": {
            "value": "Le Havre, France"
        },
        "country": "urn:li:country:fr",
        "id": 5203,
        "urn": "urn:li:region:5203",
        "states": [
            "urn:li:state:(urn:li:country:fr,99)",
            "urn:li:state:(urn:li:country:fr,A7)"
        ]
    },
    {
        "name": {
            "value": "Le Mans, France"
        },
        "country": "urn:li:country:fr",
        "id": 5204,
        "urn": "urn:li:region:5204",
        "states": [
            "urn:li:state:(urn:li:country:fr,B5)",
            "urn:li:state:(urn:li:country:fr,99)",
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Lille, France"
        },
        "country": "urn:li:country:fr",
        "id": 5205,
        "urn": "urn:li:region:5205",
        "states": [
            "urn:li:state:(urn:li:country:fr,B4)",
            "urn:li:state:(urn:li:country:fr,B6)"
        ]
    },
    {
        "name": {
            "value": "Limoges, France"
        },
        "country": "urn:li:country:fr",
        "id": 5206,
        "urn": "urn:li:region:5206",
        "states": [
            "urn:li:state:(urn:li:country:fr,B1)",
            "urn:li:state:(urn:li:country:fr,97)",
            "urn:li:state:(urn:li:country:fr,B7)",
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Limousin, France"
        },
        "country": "urn:li:country:fr",
        "id": 5207,
        "urn": "urn:li:region:5207",
        "states": [
            "urn:li:state:(urn:li:country:fr,B1)",
            "urn:li:state:(urn:li:country:fr,98)"
        ]
    },
    {
        "name": {
            "value": "Lorient, France"
        },
        "country": "urn:li:country:fr",
        "id": 5208,
        "urn": "urn:li:region:5208",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)"
        ]
    },
    {
        "name": {
            "value": "Lorraine, France"
        },
        "country": "urn:li:country:fr",
        "id": 5209,
        "urn": "urn:li:region:5209",
        "states": [
            "urn:li:state:(urn:li:country:fr,B2)",
            "urn:li:state:(urn:li:country:fr,C1)"
        ]
    },
    {
        "name": {
            "value": "Lyon, France"
        },
        "country": "urn:li:country:fr",
        "id": 5210,
        "urn": "urn:li:region:5210",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)",
            "urn:li:state:(urn:li:country:fr,A1)",
            "urn:li:state:(urn:li:country:fr,98)"
        ]
    },
    {
        "name": {
            "value": "Marseille, France"
        },
        "country": "urn:li:country:fr",
        "id": 5211,
        "urn": "urn:li:region:5211",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "Meaux, France"
        },
        "country": "urn:li:country:fr",
        "id": 5212,
        "urn": "urn:li:region:5212",
        "states": [
            "urn:li:state:(urn:li:country:fr,A4)",
            "urn:li:state:(urn:li:country:fr,A8)",
            "urn:li:state:(urn:li:country:fr,A1)"
        ]
    },
    {
        "name": {
            "value": "Metz, France"
        },
        "country": "urn:li:country:fr",
        "id": 5213,
        "urn": "urn:li:region:5213",
        "states": [
            "urn:li:state:(urn:li:country:fr,B2)"
        ]
    },
    {
        "name": {
            "value": "Midi-Pyrenees, France"
        },
        "country": "urn:li:country:fr",
        "id": 5214,
        "urn": "urn:li:region:5214",
        "states": [
            "urn:li:state:(urn:li:country:fr,B3)"
        ]
    },
    {
        "name": {
            "value": "Montauban, France"
        },
        "country": "urn:li:country:fr",
        "id": 5215,
        "urn": "urn:li:region:5215",
        "states": [
            "urn:li:state:(urn:li:country:fr,97)",
            "urn:li:state:(urn:li:country:fr,B3)"
        ]
    },
    {
        "name": {
            "value": "Montpellier, France"
        },
        "country": "urn:li:country:fr",
        "id": 5216,
        "urn": "urn:li:region:5216",
        "states": [
            "urn:li:state:(urn:li:country:fr,A9)",
            "urn:li:state:(urn:li:country:fr,B8)",
            "urn:li:state:(urn:li:country:fr,B3)"
        ]
    },
    {
        "name": {
            "value": "Montreuil, France"
        },
        "country": "urn:li:country:fr",
        "id": 5217,
        "urn": "urn:li:region:5217",
        "states": [
            "urn:li:state:(urn:li:country:fr,A8)",
            "urn:li:state:(urn:li:country:fr,B6)"
        ]
    },
    {
        "name": {
            "value": "Mulhouse, France"
        },
        "country": "urn:li:country:fr",
        "id": 5218,
        "urn": "urn:li:region:5218",
        "states": [
            "urn:li:state:(urn:li:country:fr,C1)",
            "urn:li:state:(urn:li:country:fr,A6)",
            "urn:li:state:(urn:li:country:fr,B2)"
        ]
    },
    {
        "name": {
            "value": "Nancy, France"
        },
        "country": "urn:li:country:fr",
        "id": 5219,
        "urn": "urn:li:region:5219",
        "states": [
            "urn:li:state:(urn:li:country:fr,B2)",
            "urn:li:state:(urn:li:country:fr,A6)"
        ]
    },
    {
        "name": {
            "value": "Nantes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5220,
        "urn": "urn:li:region:5220",
        "states": [
            "urn:li:state:(urn:li:country:fr,B5)",
            "urn:li:state:(urn:li:country:fr,A2)"
        ]
    },
    {
        "name": {
            "value": "Nice, France"
        },
        "country": "urn:li:country:fr",
        "id": 5221,
        "urn": "urn:li:region:5221",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "Niort, France"
        },
        "country": "urn:li:country:fr",
        "id": 5222,
        "urn": "urn:li:region:5222",
        "states": [
            "urn:li:state:(urn:li:country:fr,B7)"
        ]
    },
    {
        "name": {
            "value": "Noisy-Le-Grand, France"
        },
        "country": "urn:li:country:fr",
        "id": 5223,
        "urn": "urn:li:region:5223",
        "states": [
            "urn:li:state:(urn:li:country:fr,A8)",
            "urn:li:state:(urn:li:country:fr,A4)"
        ]
    },
    {
        "name": {
            "value": "Nord - Pas-De-Calais, France"
        },
        "country": "urn:li:country:fr",
        "id": 5224,
        "urn": "urn:li:region:5224",
        "states": [
            "urn:li:state:(urn:li:country:fr,B4)"
        ]
    },
    {
        "name": {
            "value": "Nîmes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5225,
        "urn": "urn:li:region:5225",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)",
            "urn:li:state:(urn:li:country:fr,A9)",
            "urn:li:state:(urn:li:country:fr,B9)"
        ]
    },
    {
        "name": {
            "value": "Orléans, France"
        },
        "country": "urn:li:country:fr",
        "id": 5226,
        "urn": "urn:li:region:5226",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Paris, France"
        },
        "country": "urn:li:country:fr",
        "id": 5227,
        "urn": "urn:li:region:5227",
        "states": [
            "urn:li:state:(urn:li:country:fr,A8)",
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,A7)"
        ]
    },
    {
        "name": {
            "value": "Pau, France"
        },
        "country": "urn:li:country:fr",
        "id": 5228,
        "urn": "urn:li:region:5228",
        "states": [
            "urn:li:state:(urn:li:country:fr,97)",
            "urn:li:state:(urn:li:country:fr,B3)"
        ]
    },
    {
        "name": {
            "value": "Perpignan (Perpinyà), France"
        },
        "country": "urn:li:country:fr",
        "id": 5229,
        "urn": "urn:li:region:5229",
        "states": [
            "urn:li:state:(urn:li:country:fr,A9)",
            "urn:li:state:(urn:li:country:fr,B3)"
        ]
    },
    {
        "name": {
            "value": "Pessac, France"
        },
        "country": "urn:li:country:fr",
        "id": 5230,
        "urn": "urn:li:region:5230",
        "states": [
            "urn:li:state:(urn:li:country:fr,97)"
        ]
    },
    {
        "name": {
            "value": "Picardie, France"
        },
        "country": "urn:li:country:fr",
        "id": 5231,
        "urn": "urn:li:region:5231",
        "states": [
            "urn:li:state:(urn:li:country:fr,A7)",
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,B4)"
        ]
    },
    {
        "name": {
            "value": "Poitiers, France"
        },
        "country": "urn:li:country:fr",
        "id": 5232,
        "urn": "urn:li:region:5232",
        "states": [
            "urn:li:state:(urn:li:country:fr,B7)",
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Poitou-Charentes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5233,
        "urn": "urn:li:region:5233",
        "states": [
            "urn:li:state:(urn:li:country:fr,B7)",
            "urn:li:state:(urn:li:country:fr,97)"
        ]
    },
    {
        "name": {
            "value": "Provence-Alpes-Cote D’Azur, France"
        },
        "country": "urn:li:country:fr",
        "id": 5234,
        "urn": "urn:li:region:5234",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "Quimper, France"
        },
        "country": "urn:li:country:fr",
        "id": 5235,
        "urn": "urn:li:region:5235",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)"
        ]
    },
    {
        "name": {
            "value": "Reims, France"
        },
        "country": "urn:li:country:fr",
        "id": 5236,
        "urn": "urn:li:region:5236",
        "states": [
            "urn:li:state:(urn:li:country:fr,A4)",
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,A8)"
        ]
    },
    {
        "name": {
            "value": "Rennes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5237,
        "urn": "urn:li:region:5237",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)",
            "urn:li:state:(urn:li:country:fr,99)",
            "urn:li:state:(urn:li:country:fr,B5)"
        ]
    },
    {
        "name": {
            "value": "Rhone-Alpes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5238,
        "urn": "urn:li:region:5238",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)",
            "urn:li:state:(urn:li:country:fr,A6)"
        ]
    },
    {
        "name": {
            "value": "Rouen, France"
        },
        "country": "urn:li:country:fr",
        "id": 5239,
        "urn": "urn:li:region:5239",
        "states": [
            "urn:li:state:(urn:li:country:fr,A7)",
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,A8)"
        ]
    },
    {
        "name": {
            "value": "Saint-Brieuc, France"
        },
        "country": "urn:li:country:fr",
        "id": 5240,
        "urn": "urn:li:region:5240",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)"
        ]
    },
    {
        "name": {
            "value": "Saint-Denis, France"
        },
        "country": "urn:li:country:fr",
        "id": 5241,
        "urn": "urn:li:region:5241",
        "states": [
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,A8)"
        ]
    },
    {
        "name": {
            "value": "Saint-Malo, France"
        },
        "country": "urn:li:country:fr",
        "id": 5242,
        "urn": "urn:li:region:5242",
        "states": [
            "urn:li:state:(urn:li:country:fr,99)"
        ]
    },
    {
        "name": {
            "value": "Saint-Nazaire, France"
        },
        "country": "urn:li:country:fr",
        "id": 5243,
        "urn": "urn:li:region:5243",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)",
            "urn:li:state:(urn:li:country:fr,B5)"
        ]
    },
    {
        "name": {
            "value": "Saint-Quentin, France"
        },
        "country": "urn:li:country:fr",
        "id": 5244,
        "urn": "urn:li:region:5244",
        "states": [
            "urn:li:state:(urn:li:country:fr,B6)",
            "urn:li:state:(urn:li:country:fr,B4)"
        ]
    },
    {
        "name": {
            "value": "Saint-Étienne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5245,
        "urn": "urn:li:region:5245",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)",
            "urn:li:state:(urn:li:country:fr,98)"
        ]
    },
    {
        "name": {
            "value": "Strasbourg, France"
        },
        "country": "urn:li:country:fr",
        "id": 5246,
        "urn": "urn:li:region:5246",
        "states": [
            "urn:li:state:(urn:li:country:fr,C1)",
            "urn:li:state:(urn:li:country:fr,B2)"
        ]
    },
    {
        "name": {
            "value": "Tarbes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5247,
        "urn": "urn:li:region:5247",
        "states": [
            "urn:li:state:(urn:li:country:fr,B3)",
            "urn:li:state:(urn:li:country:fr,97)"
        ]
    },
    {
        "name": {
            "value": "Toulon, France"
        },
        "country": "urn:li:country:fr",
        "id": 5248,
        "urn": "urn:li:region:5248",
        "states": [
            "urn:li:state:(urn:li:country:fr,B8)"
        ]
    },
    {
        "name": {
            "value": "Toulouse, France"
        },
        "country": "urn:li:country:fr",
        "id": 5249,
        "urn": "urn:li:region:5249",
        "states": [
            "urn:li:state:(urn:li:country:fr,B3)",
            "urn:li:state:(urn:li:country:fr,A9)"
        ]
    },
    {
        "name": {
            "value": "Tours, France"
        },
        "country": "urn:li:country:fr",
        "id": 5250,
        "urn": "urn:li:region:5250",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,B7)"
        ]
    },
    {
        "name": {
            "value": "Troyes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5251,
        "urn": "urn:li:region:5251",
        "states": [
            "urn:li:state:(urn:li:country:fr,A4)",
            "urn:li:state:(urn:li:country:fr,A1)",
            "urn:li:state:(urn:li:country:fr,A8)"
        ]
    },
    {
        "name": {
            "value": "Valence, France"
        },
        "country": "urn:li:country:fr",
        "id": 5252,
        "urn": "urn:li:region:5252",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)"
        ]
    },
    {
        "name": {
            "value": "Vannes, France"
        },
        "country": "urn:li:country:fr",
        "id": 5253,
        "urn": "urn:li:region:5253",
        "states": [
            "urn:li:state:(urn:li:country:fr,A2)"
        ]
    },
    {
        "name": {
            "value": "Versailles, France"
        },
        "country": "urn:li:country:fr",
        "id": 5254,
        "urn": "urn:li:region:5254",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)"
        ]
    },
    {
        "name": {
            "value": "Villeurbanne, France"
        },
        "country": "urn:li:country:fr",
        "id": 5255,
        "urn": "urn:li:region:5255",
        "states": [
            "urn:li:state:(urn:li:country:fr,B9)",
            "urn:li:state:(urn:li:country:fr,A1)"
        ]
    },
    {
        "name": {
            "value": "Évreux, France"
        },
        "country": "urn:li:country:fr",
        "id": 5256,
        "urn": "urn:li:region:5256",
        "states": [
            "urn:li:state:(urn:li:country:fr,A3)",
            "urn:li:state:(urn:li:country:fr,A7)",
            "urn:li:state:(urn:li:country:fr,99)"
        ]
    },
    {
        "name": {
            "value": "Ahmadābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5260,
        "urn": "urn:li:region:5260",
        "states": []
    },
    {
        "name": {
            "value": "Allahābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5263,
        "urn": "urn:li:region:5263",
        "states": []
    },
    {
        "name": {
            "value": "Alīgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 5265,
        "urn": "urn:li:region:5265",
        "states": []
    },
    {
        "name": {
            "value": "Ambikāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5266,
        "urn": "urn:li:region:5266",
        "states": []
    },
    {
        "name": {
            "value": "Amrāvati, India"
        },
        "country": "urn:li:country:in",
        "id": 5269,
        "urn": "urn:li:region:5269",
        "states": []
    },
    {
        "name": {
            "value": "Andaman And Nicobar, India"
        },
        "country": "urn:li:country:in",
        "id": 5271,
        "urn": "urn:li:region:5271",
        "states": []
    },
    {
        "name": {
            "value": "Arunachal Pradesh, India"
        },
        "country": "urn:li:country:in",
        "id": 5273,
        "urn": "urn:li:region:5273",
        "states": []
    },
    {
        "name": {
            "value": "Bada Barabīl, India"
        },
        "country": "urn:li:country:in",
        "id": 5275,
        "urn": "urn:li:region:5275",
        "states": []
    },
    {
        "name": {
            "value": "Balāngīr, India"
        },
        "country": "urn:li:country:in",
        "id": 5280,
        "urn": "urn:li:region:5280",
        "states": []
    },
    {
        "name": {
            "value": "Bengaluru, India"
        },
        "country": "urn:li:country:in",
        "id": 5281,
        "urn": "urn:li:region:5281",
        "states": []
    },
    {
        "name": {
            "value": "Barddhamān, India"
        },
        "country": "urn:li:country:in",
        "id": 5282,
        "urn": "urn:li:region:5282",
        "states": []
    },
    {
        "name": {
            "value": "Belagavi, India"
        },
        "country": "urn:li:country:in",
        "id": 5285,
        "urn": "urn:li:region:5285",
        "states": []
    },
    {
        "name": {
            "value": "Ballari, India"
        },
        "country": "urn:li:country:in",
        "id": 5286,
        "urn": "urn:li:region:5286",
        "states": []
    },
    {
        "name": {
            "value": "Benares, India"
        },
        "country": "urn:li:country:in",
        "id": 5287,
        "urn": "urn:li:region:5287",
        "states": []
    },
    {
        "name": {
            "value": "Beāwar, India"
        },
        "country": "urn:li:country:in",
        "id": 5288,
        "urn": "urn:li:region:5288",
        "states": []
    },
    {
        "name": {
            "value": "Bhadrāvati, India"
        },
        "country": "urn:li:country:in",
        "id": 5290,
        "urn": "urn:li:region:5290",
        "states": []
    },
    {
        "name": {
            "value": "Bharūch, India"
        },
        "country": "urn:li:country:in",
        "id": 5292,
        "urn": "urn:li:region:5292",
        "states": []
    },
    {
        "name": {
            "value": "Bhawānipatna, India"
        },
        "country": "urn:li:country:in",
        "id": 5294,
        "urn": "urn:li:region:5294",
        "states": []
    },
    {
        "name": {
            "value": "Bhiwāni, India"
        },
        "country": "urn:li:country:in",
        "id": 5296,
        "urn": "urn:li:region:5296",
        "states": []
    },
    {
        "name": {
            "value": "Bhopāl, India"
        },
        "country": "urn:li:country:in",
        "id": 5297,
        "urn": "urn:li:region:5297",
        "states": []
    },
    {
        "name": {
            "value": "Bhāgalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5300,
        "urn": "urn:li:region:5300",
        "states": []
    },
    {
        "name": {
            "value": "Bhātpāra, India"
        },
        "country": "urn:li:country:in",
        "id": 5301,
        "urn": "urn:li:region:5301",
        "states": []
    },
    {
        "name": {
            "value": "Bhātāpāra, India"
        },
        "country": "urn:li:country:in",
        "id": 5302,
        "urn": "urn:li:region:5302",
        "states": []
    },
    {
        "name": {
            "value": "Bhāvnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 5303,
        "urn": "urn:li:region:5303",
        "states": []
    },
    {
        "name": {
            "value": "Bhīlwāra, India"
        },
        "country": "urn:li:country:in",
        "id": 5304,
        "urn": "urn:li:region:5304",
        "states": []
    },
    {
        "name": {
            "value": "Bihar, India"
        },
        "country": "urn:li:country:in",
        "id": 5305,
        "urn": "urn:li:region:5305",
        "states": []
    },
    {
        "name": {
            "value": "Bijāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5306,
        "urn": "urn:li:region:5306",
        "states": []
    },
    {
        "name": {
            "value": "Bilāspur, India"
        },
        "country": "urn:li:country:in",
        "id": 5307,
        "urn": "urn:li:region:5307",
        "states": []
    },
    {
        "name": {
            "value": "Bokāro, India"
        },
        "country": "urn:li:country:in",
        "id": 5309,
        "urn": "urn:li:region:5309",
        "states": []
    },
    {
        "name": {
            "value": "Botād, India"
        },
        "country": "urn:li:country:in",
        "id": 5312,
        "urn": "urn:li:region:5312",
        "states": []
    },
    {
        "name": {
            "value": "Burhānpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5315,
        "urn": "urn:li:region:5315",
        "states": []
    },
    {
        "name": {
            "value": "Bāgalkot, India"
        },
        "country": "urn:li:country:in",
        "id": 5316,
        "urn": "urn:li:region:5316",
        "states": []
    },
    {
        "name": {
            "value": "Bālotra, India"
        },
        "country": "urn:li:country:in",
        "id": 5317,
        "urn": "urn:li:region:5317",
        "states": []
    },
    {
        "name": {
            "value": "Bālāghāt, India"
        },
        "country": "urn:li:country:in",
        "id": 5318,
        "urn": "urn:li:region:5318",
        "states": []
    },
    {
        "name": {
            "value": "Bānswāra, India"
        },
        "country": "urn:li:country:in",
        "id": 5320,
        "urn": "urn:li:region:5320",
        "states": []
    },
    {
        "name": {
            "value": "Bāramūla, India"
        },
        "country": "urn:li:country:in",
        "id": 5321,
        "urn": "urn:li:region:5321",
        "states": []
    },
    {
        "name": {
            "value": "Bārmer, India"
        },
        "country": "urn:li:country:in",
        "id": 5322,
        "urn": "urn:li:region:5322",
        "states": []
    },
    {
        "name": {
            "value": "Bārsi, India"
        },
        "country": "urn:li:country:in",
        "id": 5323,
        "urn": "urn:li:region:5323",
        "states": []
    },
    {
        "name": {
            "value": "Bārān, India"
        },
        "country": "urn:li:country:in",
        "id": 5324,
        "urn": "urn:li:region:5324",
        "states": []
    },
    {
        "name": {
            "value": "Bīdar, India"
        },
        "country": "urn:li:country:in",
        "id": 5325,
        "urn": "urn:li:region:5325",
        "states": []
    },
    {
        "name": {
            "value": "Bīkāner, India"
        },
        "country": "urn:li:country:in",
        "id": 5326,
        "urn": "urn:li:region:5326",
        "states": []
    },
    {
        "name": {
            "value": "Chandīgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 5330,
        "urn": "urn:li:region:5330",
        "states": []
    },
    {
        "name": {
            "value": "Chattisgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 5331,
        "urn": "urn:li:region:5331",
        "states": []
    },
    {
        "name": {
            "value": "Chhindwāra, India"
        },
        "country": "urn:li:country:in",
        "id": 5333,
        "urn": "urn:li:region:5333",
        "states": []
    },
    {
        "name": {
            "value": "Chāībāsa, India"
        },
        "country": "urn:li:country:in",
        "id": 5335,
        "urn": "urn:li:region:5335",
        "states": []
    },
    {
        "name": {
            "value": "Chūru, India"
        },
        "country": "urn:li:country:in",
        "id": 5336,
        "urn": "urn:li:region:5336",
        "states": []
    },
    {
        "name": {
            "value": "Dehra Dūn, India"
        },
        "country": "urn:li:country:in",
        "id": 5341,
        "urn": "urn:li:region:5341",
        "states": []
    },
    {
        "name": {
            "value": "Delhi, India"
        },
        "country": "urn:li:country:in",
        "id": 5343,
        "urn": "urn:li:region:5343",
        "states": []
    },
    {
        "name": {
            "value": "Dewās, India"
        },
        "country": "urn:li:country:in",
        "id": 5344,
        "urn": "urn:li:region:5344",
        "states": []
    },
    {
        "name": {
            "value": "Dhanbād, India"
        },
        "country": "urn:li:country:in",
        "id": 5346,
        "urn": "urn:li:region:5346",
        "states": []
    },
    {
        "name": {
            "value": "Dhenkānāl, India"
        },
        "country": "urn:li:country:in",
        "id": 5347,
        "urn": "urn:li:region:5347",
        "states": []
    },
    {
        "name": {
            "value": "Dhār, India"
        },
        "country": "urn:li:country:in",
        "id": 5349,
        "urn": "urn:li:region:5349",
        "states": []
    },
    {
        "name": {
            "value": "Dimāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5351,
        "urn": "urn:li:region:5351",
        "states": []
    },
    {
        "name": {
            "value": "Durgāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5353,
        "urn": "urn:li:region:5353",
        "states": []
    },
    {
        "name": {
            "value": "Dāhod, India"
        },
        "country": "urn:li:country:in",
        "id": 5354,
        "urn": "urn:li:region:5354",
        "states": []
    },
    {
        "name": {
            "value": "Dāltenganj, India"
        },
        "country": "urn:li:country:in",
        "id": 5355,
        "urn": "urn:li:region:5355",
        "states": []
    },
    {
        "name": {
            "value": "Elūru, India"
        },
        "country": "urn:li:country:in",
        "id": 5356,
        "urn": "urn:li:region:5356",
        "states": []
    },
    {
        "name": {
            "value": "Etāwah, India"
        },
        "country": "urn:li:country:in",
        "id": 5357,
        "urn": "urn:li:region:5357",
        "states": []
    },
    {
        "name": {
            "value": "Faizābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5358,
        "urn": "urn:li:region:5358",
        "states": []
    },
    {
        "name": {
            "value": "Farrukhābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5359,
        "urn": "urn:li:region:5359",
        "states": []
    },
    {
        "name": {
            "value": "Fīrozpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5361,
        "urn": "urn:li:region:5361",
        "states": []
    },
    {
        "name": {
            "value": "Fīrozābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5362,
        "urn": "urn:li:region:5362",
        "states": []
    },
    {
        "name": {
            "value": "Gangānagar, India"
        },
        "country": "urn:li:country:in",
        "id": 5363,
        "urn": "urn:li:region:5363",
        "states": []
    },
    {
        "name": {
            "value": "Gangāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5364,
        "urn": "urn:li:region:5364",
        "states": []
    },
    {
        "name": {
            "value": "Gangāwati, India"
        },
        "country": "urn:li:country:in",
        "id": 5365,
        "urn": "urn:li:region:5365",
        "states": []
    },
    {
        "name": {
            "value": "Ghāziābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5367,
        "urn": "urn:li:region:5367",
        "states": []
    },
    {
        "name": {
            "value": "Gujarat, India"
        },
        "country": "urn:li:country:in",
        "id": 5371,
        "urn": "urn:li:region:5371",
        "states": []
    },
    {
        "name": {
            "value": "Kalburgi, India"
        },
        "country": "urn:li:country:in",
        "id": 5372,
        "urn": "urn:li:region:5372",
        "states": []
    },
    {
        "name": {
            "value": "Guntūr, India"
        },
        "country": "urn:li:country:in",
        "id": 5374,
        "urn": "urn:li:region:5374",
        "states": []
    },
    {
        "name": {
            "value": "Guwāhāti, India"
        },
        "country": "urn:li:country:in",
        "id": 5375,
        "urn": "urn:li:region:5375",
        "states": []
    },
    {
        "name": {
            "value": "Haridwār, India"
        },
        "country": "urn:li:country:in",
        "id": 5378,
        "urn": "urn:li:region:5378",
        "states": []
    },
    {
        "name": {
            "value": "Hazārībāg, India"
        },
        "country": "urn:li:country:in",
        "id": 5380,
        "urn": "urn:li:region:5380",
        "states": []
    },
    {
        "name": {
            "value": "Himachal Pradesh, India"
        },
        "country": "urn:li:country:in",
        "id": 5381,
        "urn": "urn:li:region:5381",
        "states": []
    },
    {
        "name": {
            "value": "Hisār, India"
        },
        "country": "urn:li:country:in",
        "id": 5385,
        "urn": "urn:li:region:5385",
        "states": []
    },
    {
        "name": {
            "value": "Hoshangābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5386,
        "urn": "urn:li:region:5386",
        "states": []
    },
    {
        "name": {
            "value": "Hoshiārpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5387,
        "urn": "urn:li:region:5387",
        "states": []
    },
    {
        "name": {
            "value": "Hyderābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5390,
        "urn": "urn:li:region:5390",
        "states": []
    },
    {
        "name": {
            "value": "Hāora, India"
        },
        "country": "urn:li:country:in",
        "id": 5391,
        "urn": "urn:li:region:5391",
        "states": []
    },
    {
        "name": {
            "value": "Imphāl, India"
        },
        "country": "urn:li:country:in",
        "id": 5393,
        "urn": "urn:li:region:5393",
        "states": []
    },
    {
        "name": {
            "value": "Ingrāj Bāzār, India"
        },
        "country": "urn:li:country:in",
        "id": 5395,
        "urn": "urn:li:region:5395",
        "states": []
    },
    {
        "name": {
            "value": "Itārsi, India"
        },
        "country": "urn:li:country:in",
        "id": 5396,
        "urn": "urn:li:region:5396",
        "states": []
    },
    {
        "name": {
            "value": "Jagtiāl, India"
        },
        "country": "urn:li:country:in",
        "id": 5399,
        "urn": "urn:li:region:5399",
        "states": []
    },
    {
        "name": {
            "value": "Jalpāiguri, India"
        },
        "country": "urn:li:country:in",
        "id": 5403,
        "urn": "urn:li:region:5403",
        "states": []
    },
    {
        "name": {
            "value": "Jammu And Kashmir, India"
        },
        "country": "urn:li:country:in",
        "id": 5404,
        "urn": "urn:li:region:5404",
        "states": []
    },
    {
        "name": {
            "value": "Jatani, India"
        },
        "country": "urn:li:country:in",
        "id": 5407,
        "urn": "urn:li:region:5407",
        "states": []
    },
    {
        "name": {
            "value": "Jharkhand, India"
        },
        "country": "urn:li:country:in",
        "id": 5410,
        "urn": "urn:li:region:5410",
        "states": []
    },
    {
        "name": {
            "value": "Jhunjhunūn, India"
        },
        "country": "urn:li:country:in",
        "id": 5411,
        "urn": "urn:li:region:5411",
        "states": []
    },
    {
        "name": {
            "value": "Jhālāwār, India"
        },
        "country": "urn:li:country:in",
        "id": 5412,
        "urn": "urn:li:region:5412",
        "states": []
    },
    {
        "name": {
            "value": "Jhānsi, India"
        },
        "country": "urn:li:country:in",
        "id": 5413,
        "urn": "urn:li:region:5413",
        "states": []
    },
    {
        "name": {
            "value": "Jhārsuguda, India"
        },
        "country": "urn:li:country:in",
        "id": 5414,
        "urn": "urn:li:region:5414",
        "states": []
    },
    {
        "name": {
            "value": "Jorhāt, India"
        },
        "country": "urn:li:country:in",
        "id": 5416,
        "urn": "urn:li:region:5416",
        "states": []
    },
    {
        "name": {
            "value": "Jālgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 5417,
        "urn": "urn:li:region:5417",
        "states": []
    },
    {
        "name": {
            "value": "Jālna, India"
        },
        "country": "urn:li:country:in",
        "id": 5418,
        "urn": "urn:li:region:5418",
        "states": []
    },
    {
        "name": {
            "value": "Jāmnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 5419,
        "urn": "urn:li:region:5419",
        "states": []
    },
    {
        "name": {
            "value": "Jūnāgadh, India"
        },
        "country": "urn:li:country:in",
        "id": 5420,
        "urn": "urn:li:region:5420",
        "states": []
    },
    {
        "name": {
            "value": "Karnataka, India"
        },
        "country": "urn:li:country:in",
        "id": 5421,
        "urn": "urn:li:region:5421",
        "states": []
    },
    {
        "name": {
            "value": "Karīmganj, India"
        },
        "country": "urn:li:country:in",
        "id": 5422,
        "urn": "urn:li:region:5422",
        "states": []
    },
    {
        "name": {
            "value": "Karīmnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 5423,
        "urn": "urn:li:region:5423",
        "states": []
    },
    {
        "name": {
            "value": "Katihār, India"
        },
        "country": "urn:li:country:in",
        "id": 5424,
        "urn": "urn:li:region:5424",
        "states": []
    },
    {
        "name": {
            "value": "Koch Bihār, India"
        },
        "country": "urn:li:country:in",
        "id": 5428,
        "urn": "urn:li:region:5428",
        "states": []
    },
    {
        "name": {
            "value": "Kohīma, India"
        },
        "country": "urn:li:country:in",
        "id": 5429,
        "urn": "urn:li:region:5429",
        "states": []
    },
    {
        "name": {
            "value": "Kolhāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5430,
        "urn": "urn:li:region:5430",
        "states": []
    },
    {
        "name": {
            "value": "Kolār, India"
        },
        "country": "urn:li:country:in",
        "id": 5431,
        "urn": "urn:li:region:5431",
        "states": []
    },
    {
        "name": {
            "value": "Kānpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5435,
        "urn": "urn:li:region:5435",
        "states": []
    },
    {
        "name": {
            "value": "Kārwār, India"
        },
        "country": "urn:li:country:in",
        "id": 5436,
        "urn": "urn:li:region:5436",
        "states": []
    },
    {
        "name": {
            "value": "Lohārdaga, India"
        },
        "country": "urn:li:country:in",
        "id": 5438,
        "urn": "urn:li:region:5438",
        "states": []
    },
    {
        "name": {
            "value": "Ludhiāna, India"
        },
        "country": "urn:li:country:in",
        "id": 5440,
        "urn": "urn:li:region:5440",
        "states": []
    },
    {
        "name": {
            "value": "Lātūr, India"
        },
        "country": "urn:li:country:in",
        "id": 5442,
        "urn": "urn:li:region:5442",
        "states": []
    },
    {
        "name": {
            "value": "Madhya Pradesh, India"
        },
        "country": "urn:li:country:in",
        "id": 5443,
        "urn": "urn:li:region:5443",
        "states": []
    },
    {
        "name": {
            "value": "Mahbūbnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 5446,
        "urn": "urn:li:region:5446",
        "states": []
    },
    {
        "name": {
            "value": "Mahāsamund, India"
        },
        "country": "urn:li:country:in",
        "id": 5447,
        "urn": "urn:li:region:5447",
        "states": []
    },
    {
        "name": {
            "value": "Makrāna, India"
        },
        "country": "urn:li:country:in",
        "id": 5448,
        "urn": "urn:li:region:5448",
        "states": []
    },
    {
        "name": {
            "value": "Morena, India"
        },
        "country": "urn:li:country:in",
        "id": 5457,
        "urn": "urn:li:region:5457",
        "states": []
    },
    {
        "name": {
            "value": "Morādābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5458,
        "urn": "urn:li:region:5458",
        "states": []
    },
    {
        "name": {
            "value": "Murwāra, India"
        },
        "country": "urn:li:country:in",
        "id": 5459,
        "urn": "urn:li:region:5459",
        "states": []
    },
    {
        "name": {
            "value": "Mysuru, India"
        },
        "country": "urn:li:country:in",
        "id": 5461,
        "urn": "urn:li:region:5461",
        "states": []
    },
    {
        "name": {
            "value": "Mālegaon, India"
        },
        "country": "urn:li:country:in",
        "id": 5462,
        "urn": "urn:li:region:5462",
        "states": []
    },
    {
        "name": {
            "value": "Nadiād, India"
        },
        "country": "urn:li:country:in",
        "id": 5463,
        "urn": "urn:li:region:5463",
        "states": []
    },
    {
        "name": {
            "value": "Nandurbār, India"
        },
        "country": "urn:li:country:in",
        "id": 5465,
        "urn": "urn:li:region:5465",
        "states": []
    },
    {
        "name": {
            "value": "Nandyāl, India"
        },
        "country": "urn:li:country:in",
        "id": 5466,
        "urn": "urn:li:region:5466",
        "states": []
    },
    {
        "name": {
            "value": "Nangi, India"
        },
        "country": "urn:li:country:in",
        "id": 5467,
        "urn": "urn:li:region:5467",
        "states": []
    },
    {
        "name": {
            "value": "Nizāmābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5470,
        "urn": "urn:li:region:5470",
        "states": []
    },
    {
        "name": {
            "value": "Nāgaur, India"
        },
        "country": "urn:li:country:in",
        "id": 5472,
        "urn": "urn:li:region:5472",
        "states": []
    },
    {
        "name": {
            "value": "Nāgpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5473,
        "urn": "urn:li:region:5473",
        "states": []
    },
    {
        "name": {
            "value": "Nāsik, India"
        },
        "country": "urn:li:country:in",
        "id": 5474,
        "urn": "urn:li:region:5474",
        "states": []
    },
    {
        "name": {
            "value": "Orisa, India"
        },
        "country": "urn:li:country:in",
        "id": 5477,
        "urn": "urn:li:region:5477",
        "states": []
    },
    {
        "name": {
            "value": "Pathānkot, India"
        },
        "country": "urn:li:country:in",
        "id": 5481,
        "urn": "urn:li:region:5481",
        "states": []
    },
    {
        "name": {
            "value": "Patiāla, India"
        },
        "country": "urn:li:country:in",
        "id": 5482,
        "urn": "urn:li:region:5482",
        "states": []
    },
    {
        "name": {
            "value": "Puducherry, India"
        },
        "country": "urn:li:country:in",
        "id": 5483,
        "urn": "urn:li:region:5483",
        "states": []
    },
    {
        "name": {
            "value": "Proddatūr, India"
        },
        "country": "urn:li:country:in",
        "id": 5486,
        "urn": "urn:li:region:5486",
        "states": []
    },
    {
        "name": {
            "value": "Punāsa, India"
        },
        "country": "urn:li:country:in",
        "id": 5489,
        "urn": "urn:li:region:5489",
        "states": []
    },
    {
        "name": {
            "value": "Pālanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5490,
        "urn": "urn:li:region:5490",
        "states": []
    },
    {
        "name": {
            "value": "Pāli, India"
        },
        "country": "urn:li:country:in",
        "id": 5491,
        "urn": "urn:li:region:5491",
        "states": []
    },
    {
        "name": {
            "value": "Pāloncha, India"
        },
        "country": "urn:li:country:in",
        "id": 5492,
        "urn": "urn:li:region:5492",
        "states": []
    },
    {
        "name": {
            "value": "Pānīpat, India"
        },
        "country": "urn:li:country:in",
        "id": 5493,
        "urn": "urn:li:region:5493",
        "states": []
    },
    {
        "name": {
            "value": "Pātan, India"
        },
        "country": "urn:li:country:in",
        "id": 5494,
        "urn": "urn:li:region:5494",
        "states": []
    },
    {
        "name": {
            "value": "Pūrnia, India"
        },
        "country": "urn:li:country:in",
        "id": 5495,
        "urn": "urn:li:region:5495",
        "states": []
    },
    {
        "name": {
            "value": "Rajasthan, India"
        },
        "country": "urn:li:country:in",
        "id": 5498,
        "urn": "urn:li:region:5498",
        "states": []
    },
    {
        "name": {
            "value": "Ratlām, India"
        },
        "country": "urn:li:country:in",
        "id": 5499,
        "urn": "urn:li:region:5499",
        "states": []
    },
    {
        "name": {
            "value": "Ratnāgiri, India"
        },
        "country": "urn:li:country:in",
        "id": 5500,
        "urn": "urn:li:region:5500",
        "states": []
    },
    {
        "name": {
            "value": "Renukūt, India"
        },
        "country": "urn:li:country:in",
        "id": 5501,
        "urn": "urn:li:region:5501",
        "states": []
    },
    {
        "name": {
            "value": "Rāe Bareli, India"
        },
        "country": "urn:li:country:in",
        "id": 5505,
        "urn": "urn:li:region:5505",
        "states": []
    },
    {
        "name": {
            "value": "Rāichūr, India"
        },
        "country": "urn:li:country:in",
        "id": 5506,
        "urn": "urn:li:region:5506",
        "states": []
    },
    {
        "name": {
            "value": "Rāiganj, India"
        },
        "country": "urn:li:country:in",
        "id": 5507,
        "urn": "urn:li:region:5507",
        "states": []
    },
    {
        "name": {
            "value": "Rāj Nāndgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 5508,
        "urn": "urn:li:region:5508",
        "states": []
    },
    {
        "name": {
            "value": "Rājahmundry, India"
        },
        "country": "urn:li:country:in",
        "id": 5509,
        "urn": "urn:li:region:5509",
        "states": []
    },
    {
        "name": {
            "value": "Rājkot, India"
        },
        "country": "urn:li:country:in",
        "id": 5510,
        "urn": "urn:li:region:5510",
        "states": []
    },
    {
        "name": {
            "value": "Rāmpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5512,
        "urn": "urn:li:region:5512",
        "states": []
    },
    {
        "name": {
            "value": "Rānībennur, India"
        },
        "country": "urn:li:country:in",
        "id": 5513,
        "urn": "urn:li:region:5513",
        "states": []
    },
    {
        "name": {
            "value": "Sahāranpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5514,
        "urn": "urn:li:region:5514",
        "states": []
    },
    {
        "name": {
            "value": "Sardārshahr, India"
        },
        "country": "urn:li:country:in",
        "id": 5517,
        "urn": "urn:li:region:5517",
        "states": []
    },
    {
        "name": {
            "value": "Seonī, India"
        },
        "country": "urn:li:country:in",
        "id": 5519,
        "urn": "urn:li:region:5519",
        "states": []
    },
    {
        "name": {
            "value": "Shivamoga, India"
        },
        "country": "urn:li:country:in",
        "id": 5524,
        "urn": "urn:li:region:5524",
        "states": []
    },
    {
        "name": {
            "value": "Shivpurī, India"
        },
        "country": "urn:li:country:in",
        "id": 5526,
        "urn": "urn:li:region:5526",
        "states": []
    },
    {
        "name": {
            "value": "Shāhjahānpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5527,
        "urn": "urn:li:region:5527",
        "states": []
    },
    {
        "name": {
            "value": "Shājāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5528,
        "urn": "urn:li:region:5528",
        "states": []
    },
    {
        "name": {
            "value": "Sibsāgar, India"
        },
        "country": "urn:li:country:in",
        "id": 5529,
        "urn": "urn:li:region:5529",
        "states": []
    },
    {
        "name": {
            "value": "Solāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5533,
        "urn": "urn:li:region:5533",
        "states": []
    },
    {
        "name": {
            "value": "Srīkākulam, India"
        },
        "country": "urn:li:country:in",
        "id": 5534,
        "urn": "urn:li:region:5534",
        "states": []
    },
    {
        "name": {
            "value": "Srīnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 5535,
        "urn": "urn:li:region:5535",
        "states": []
    },
    {
        "name": {
            "value": "Sātāra, India"
        },
        "country": "urn:li:country:in",
        "id": 5538,
        "urn": "urn:li:region:5538",
        "states": []
    },
    {
        "name": {
            "value": "Sīkar, India"
        },
        "country": "urn:li:country:in",
        "id": 5539,
        "urn": "urn:li:region:5539",
        "states": []
    },
    {
        "name": {
            "value": "Sītāpur, India"
        },
        "country": "urn:li:country:in",
        "id": 5540,
        "urn": "urn:li:region:5540",
        "states": []
    },
    {
        "name": {
            "value": "Sūrat, India"
        },
        "country": "urn:li:country:in",
        "id": 5541,
        "urn": "urn:li:region:5541",
        "states": []
    },
    {
        "name": {
            "value": "Thanjāvūr, India"
        },
        "country": "urn:li:country:in",
        "id": 5544,
        "urn": "urn:li:region:5544",
        "states": []
    },
    {
        "name": {
            "value": "Tiruchchirāppalli, India"
        },
        "country": "urn:li:country:in",
        "id": 5547,
        "urn": "urn:li:region:5547",
        "states": []
    },
    {
        "name": {
            "value": "Tiruvannāmalai, India"
        },
        "country": "urn:li:country:in",
        "id": 5550,
        "urn": "urn:li:region:5550",
        "states": []
    },
    {
        "name": {
            "value": "Tripura, India"
        },
        "country": "urn:li:country:in",
        "id": 5552,
        "urn": "urn:li:region:5552",
        "states": []
    },
    {
        "name": {
            "value": "Tumkūr, India"
        },
        "country": "urn:li:country:in",
        "id": 5553,
        "urn": "urn:li:region:5553",
        "states": []
    },
    {
        "name": {
            "value": "Uttaranchal, India"
        },
        "country": "urn:li:country:in",
        "id": 5559,
        "urn": "urn:li:region:5559",
        "states": []
    },
    {
        "name": {
            "value": "Verāval, India"
        },
        "country": "urn:li:country:in",
        "id": 5562,
        "urn": "urn:li:region:5562",
        "states": []
    },
    {
        "name": {
            "value": "Vijayawāda, India"
        },
        "country": "urn:li:country:in",
        "id": 5564,
        "urn": "urn:li:region:5564",
        "states": []
    },
    {
        "name": {
            "value": "Virār, India"
        },
        "country": "urn:li:country:in",
        "id": 5565,
        "urn": "urn:li:region:5565",
        "states": []
    },
    {
        "name": {
            "value": "Vishākhapatnam, India"
        },
        "country": "urn:li:country:in",
        "id": 5566,
        "urn": "urn:li:region:5566",
        "states": []
    },
    {
        "name": {
            "value": "West Bengal, India"
        },
        "country": "urn:li:country:in",
        "id": 5569,
        "urn": "urn:li:region:5569",
        "states": []
    },
    {
        "name": {
            "value": "Wāpi, India"
        },
        "country": "urn:li:country:in",
        "id": 5570,
        "urn": "urn:li:region:5570",
        "states": []
    },
    {
        "name": {
            "value": "Yavatmāl, India"
        },
        "country": "urn:li:country:in",
        "id": 5571,
        "urn": "urn:li:region:5571",
        "states": []
    },
    {
        "name": {
            "value": "Ābu Road, India"
        },
        "country": "urn:li:country:in",
        "id": 5572,
        "urn": "urn:li:region:5572",
        "states": []
    },
    {
        "name": {
            "value": "Ādilābād, India"
        },
        "country": "urn:li:country:in",
        "id": 5573,
        "urn": "urn:li:region:5573",
        "states": []
    },
    {
        "name": {
            "value": "Āgra, India"
        },
        "country": "urn:li:country:in",
        "id": 5574,
        "urn": "urn:li:region:5574",
        "states": []
    },
    {
        "name": {
            "value": "Āsansol, India"
        },
        "country": "urn:li:country:in",
        "id": 5575,
        "urn": "urn:li:region:5575",
        "states": []
    },
    {
        "name": {
            "value": "Āvadi, India"
        },
        "country": "urn:li:country:in",
        "id": 5576,
        "urn": "urn:li:region:5576",
        "states": []
    },
    {
        "name": {
            "value": "Āīzawl, India"
        },
        "country": "urn:li:country:in",
        "id": 5577,
        "urn": "urn:li:region:5577",
        "states": []
    },
    {
        "name": {
            "value": "Altamura, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5578,
        "urn": "urn:li:region:5578",
        "states": []
    },
    {
        "name": {
            "value": "Andria, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5579,
        "urn": "urn:li:region:5579",
        "states": []
    },
    {
        "name": {
            "value": "Arezzo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5580,
        "urn": "urn:li:region:5580",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Asti, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5581,
        "urn": "urn:li:region:5581",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)"
        ]
    },
    {
        "name": {
            "value": "Aversa, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5582,
        "urn": "urn:li:region:5582",
        "states": []
    },
    {
        "name": {
            "value": "Bari, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5583,
        "urn": "urn:li:region:5583",
        "states": [
            "urn:li:state:(urn:li:country:it,PU)"
        ]
    },
    {
        "name": {
            "value": "Battipaglia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5584,
        "urn": "urn:li:region:5584",
        "states": []
    },
    {
        "name": {
            "value": "Benevento, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5585,
        "urn": "urn:li:region:5585",
        "states": [
            "urn:li:state:(urn:li:country:it,CM)"
        ]
    },
    {
        "name": {
            "value": "Bergamo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5586,
        "urn": "urn:li:region:5586",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Bologna, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5587,
        "urn": "urn:li:region:5587",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Bolzano, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5588,
        "urn": "urn:li:region:5588",
        "states": [
            "urn:li:state:(urn:li:country:it,TT)"
        ]
    },
    {
        "name": {
            "value": "Brescia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5589,
        "urn": "urn:li:region:5589",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Cagliari, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5590,
        "urn": "urn:li:region:5590",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Caltanissetta, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5591,
        "urn": "urn:li:region:5591",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Caserta, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5592,
        "urn": "urn:li:region:5592",
        "states": [
            "urn:li:state:(urn:li:country:it,CM)"
        ]
    },
    {
        "name": {
            "value": "Catania, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5593,
        "urn": "urn:li:region:5593",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Catanzaro, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5594,
        "urn": "urn:li:region:5594",
        "states": [
            "urn:li:state:(urn:li:country:it,CI)"
        ]
    },
    {
        "name": {
            "value": "Chieti, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5595,
        "urn": "urn:li:region:5595",
        "states": [
            "urn:li:state:(urn:li:country:it,AB)"
        ]
    },
    {
        "name": {
            "value": "Como, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5596,
        "urn": "urn:li:region:5596",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Cosenza, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5597,
        "urn": "urn:li:region:5597",
        "states": [
            "urn:li:state:(urn:li:country:it,CI)"
        ]
    },
    {
        "name": {
            "value": "Cuneo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5598,
        "urn": "urn:li:region:5598",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)",
            "urn:li:state:(urn:li:country:it,LG)"
        ]
    },
    {
        "name": {
            "value": "Florence, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5599,
        "urn": "urn:li:region:5599",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Foggia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5600,
        "urn": "urn:li:region:5600",
        "states": [
            "urn:li:state:(urn:li:country:it,PU)"
        ]
    },
    {
        "name": {
            "value": "Gela, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5601,
        "urn": "urn:li:region:5601",
        "states": []
    },
    {
        "name": {
            "value": "Genoa, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5602,
        "urn": "urn:li:region:5602",
        "states": [
            "urn:li:state:(urn:li:country:it,LG)"
        ]
    },
    {
        "name": {
            "value": "Giugliano In Campania, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5603,
        "urn": "urn:li:region:5603",
        "states": []
    },
    {
        "name": {
            "value": "Grosseto, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5604,
        "urn": "urn:li:region:5604",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Gidonia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5605,
        "urn": "urn:li:region:5605",
        "states": []
    },
    {
        "name": {
            "value": "L’Aquila, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5606,
        "urn": "urn:li:region:5606",
        "states": [
            "urn:li:state:(urn:li:country:it,AB)",
            "urn:li:state:(urn:li:country:it,LZ)"
        ]
    },
    {
        "name": {
            "value": "La Spezia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5607,
        "urn": "urn:li:region:5607",
        "states": [
            "urn:li:state:(urn:li:country:it,LG)"
        ]
    },
    {
        "name": {
            "value": "Latina, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5608,
        "urn": "urn:li:region:5608",
        "states": [
            "urn:li:state:(urn:li:country:it,LZ)"
        ]
    },
    {
        "name": {
            "value": "Lazio, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5609,
        "urn": "urn:li:region:5609",
        "states": []
    },
    {
        "name": {
            "value": "Lecce, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5610,
        "urn": "urn:li:region:5610",
        "states": [
            "urn:li:state:(urn:li:country:it,PU)"
        ]
    },
    {
        "name": {
            "value": "Livorno, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5611,
        "urn": "urn:li:region:5611",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Lombardia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5612,
        "urn": "urn:li:region:5612",
        "states": []
    },
    {
        "name": {
            "value": "Marsala, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5613,
        "urn": "urn:li:region:5613",
        "states": []
    },
    {
        "name": {
            "value": "Matera, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5614,
        "urn": "urn:li:region:5614",
        "states": [
            "urn:li:state:(urn:li:country:it,BC)"
        ]
    },
    {
        "name": {
            "value": "Messina, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5615,
        "urn": "urn:li:region:5615",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Milan, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5616,
        "urn": "urn:li:region:5616",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Modena, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5617,
        "urn": "urn:li:region:5617",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Monza and Brianza, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5618,
        "urn": "urn:li:region:5618",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Naples, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5619,
        "urn": "urn:li:region:5619",
        "states": [
            "urn:li:state:(urn:li:country:it,CM)"
        ]
    },
    {
        "name": {
            "value": "Nicastro, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5620,
        "urn": "urn:li:region:5620",
        "states": []
    },
    {
        "name": {
            "value": "Novara, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5621,
        "urn": "urn:li:region:5621",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)"
        ]
    },
    {
        "name": {
            "value": "Padova, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5622,
        "urn": "urn:li:region:5622",
        "states": [
            "urn:li:state:(urn:li:country:it,VN)"
        ]
    },
    {
        "name": {
            "value": "Palermo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5623,
        "urn": "urn:li:region:5623",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Parma, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5624,
        "urn": "urn:li:region:5624",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Perugia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5625,
        "urn": "urn:li:region:5625",
        "states": [
            "urn:li:state:(urn:li:country:it,UM)"
        ]
    },
    {
        "name": {
            "value": "Pescara, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5626,
        "urn": "urn:li:region:5626",
        "states": [
            "urn:li:state:(urn:li:country:it,AB)"
        ]
    },
    {
        "name": {
            "value": "Piemonte, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5627,
        "urn": "urn:li:region:5627",
        "states": []
    },
    {
        "name": {
            "value": "Pinocchio Di Ancona, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5628,
        "urn": "urn:li:region:5628",
        "states": []
    },
    {
        "name": {
            "value": "Pordenone, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5629,
        "urn": "urn:li:region:5629",
        "states": [
            "urn:li:state:(urn:li:country:it,FV)"
        ]
    },
    {
        "name": {
            "value": "Potenza, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5630,
        "urn": "urn:li:region:5630",
        "states": [
            "urn:li:state:(urn:li:country:it,BC)",
            "urn:li:state:(urn:li:country:it,CM)"
        ]
    },
    {
        "name": {
            "value": "Prato, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5631,
        "urn": "urn:li:region:5631",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Ravenna, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5632,
        "urn": "urn:li:region:5632",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Reggio Calabria, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5633,
        "urn": "urn:li:region:5633",
        "states": [
            "urn:li:state:(urn:li:country:it,CI)"
        ]
    },
    {
        "name": {
            "value": "Rimini, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5634,
        "urn": "urn:li:region:5634",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Rivoli, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5635,
        "urn": "urn:li:region:5635",
        "states": []
    },
    {
        "name": {
            "value": "Rome, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5636,
        "urn": "urn:li:region:5636",
        "states": [
            "urn:li:state:(urn:li:country:it,LZ)"
        ]
    },
    {
        "name": {
            "value": "Taranto, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5637,
        "urn": "urn:li:region:5637",
        "states": [
            "urn:li:state:(urn:li:country:it,PU)"
        ]
    },
    {
        "name": {
            "value": "San Remo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5638,
        "urn": "urn:li:region:5638",
        "states": []
    },
    {
        "name": {
            "value": "San Severo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5639,
        "urn": "urn:li:region:5639",
        "states": []
    },
    {
        "name": {
            "value": "Sardegna, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5640,
        "urn": "urn:li:region:5640",
        "states": []
    },
    {
        "name": {
            "value": "Sassari, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5641,
        "urn": "urn:li:region:5641",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Savona, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5642,
        "urn": "urn:li:region:5642",
        "states": [
            "urn:li:state:(urn:li:country:it,LG)"
        ]
    },
    {
        "name": {
            "value": "Sicilia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5643,
        "urn": "urn:li:region:5643",
        "states": []
    },
    {
        "name": {
            "value": "Siracusa, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5644,
        "urn": "urn:li:region:5644",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Salerno, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5645,
        "urn": "urn:li:region:5645",
        "states": [
            "urn:li:state:(urn:li:country:it,CM)"
        ]
    },
    {
        "name": {
            "value": "Terni, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5646,
        "urn": "urn:li:region:5646",
        "states": [
            "urn:li:state:(urn:li:country:it,UM)"
        ]
    },
    {
        "name": {
            "value": "Toscana, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5647,
        "urn": "urn:li:region:5647",
        "states": []
    },
    {
        "name": {
            "value": "Trentino-Alto Adige, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5648,
        "urn": "urn:li:region:5648",
        "states": []
    },
    {
        "name": {
            "value": "Trento, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5649,
        "urn": "urn:li:region:5649",
        "states": [
            "urn:li:state:(urn:li:country:it,TT)"
        ]
    },
    {
        "name": {
            "value": "Treviso, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5650,
        "urn": "urn:li:region:5650",
        "states": [
            "urn:li:state:(urn:li:country:it,VN)"
        ]
    },
    {
        "name": {
            "value": "Trieste, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5651,
        "urn": "urn:li:region:5651",
        "states": [
            "urn:li:state:(urn:li:country:it,FV)"
        ]
    },
    {
        "name": {
            "value": "Turin, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5652,
        "urn": "urn:li:region:5652",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)"
        ]
    },
    {
        "name": {
            "value": "Udine, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5653,
        "urn": "urn:li:region:5653",
        "states": [
            "urn:li:state:(urn:li:country:it,FV)"
        ]
    },
    {
        "name": {
            "value": "Valle D’Aosta, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5654,
        "urn": "urn:li:region:5654",
        "states": []
    },
    {
        "name": {
            "value": "Varese, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5655,
        "urn": "urn:li:region:5655",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Veneto, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5656,
        "urn": "urn:li:region:5656",
        "states": []
    },
    {
        "name": {
            "value": "Venice, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5657,
        "urn": "urn:li:region:5657",
        "states": [
            "urn:li:state:(urn:li:country:it,VN)"
        ]
    },
    {
        "name": {
            "value": "Verona, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5658,
        "urn": "urn:li:region:5658",
        "states": [
            "urn:li:state:(urn:li:country:it,VN)"
        ]
    },
    {
        "name": {
            "value": "Vicenza, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5659,
        "urn": "urn:li:region:5659",
        "states": [
            "urn:li:state:(urn:li:country:it,VN)"
        ]
    },
    {
        "name": {
            "value": "Viterbo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 5660,
        "urn": "urn:li:region:5660",
        "states": [
            "urn:li:state:(urn:li:country:it,LZ)"
        ]
    },
    {
        "name": {
            "value": "De Velden, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5661,
        "urn": "urn:li:region:5661",
        "states": []
    },
    {
        "name": {
            "value": "Alkmaar, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5662,
        "urn": "urn:li:region:5662",
        "states": []
    },
    {
        "name": {
            "value": "Almere Stad, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5663,
        "urn": "urn:li:region:5663",
        "states": [
            "urn:li:state:(urn:li:country:nl,02)",
            "urn:li:state:(urn:li:country:nl,16)",
            "urn:li:state:(urn:li:country:nl,07)",
            "urn:li:state:(urn:li:country:nl,09)",
            "urn:li:state:(urn:li:country:nl,03)"
        ]
    },
    {
        "name": {
            "value": "Amsterdam, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5664,
        "urn": "urn:li:region:5664",
        "states": [
            "urn:li:state:(urn:li:country:nl,07)",
            "urn:li:state:(urn:li:country:nl,11)",
            "urn:li:state:(urn:li:country:nl,09)",
            "urn:li:state:(urn:li:country:nl,16)"
        ]
    },
    {
        "name": {
            "value": "Apeldoorn, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5665,
        "urn": "urn:li:region:5665",
        "states": [
            "urn:li:state:(urn:li:country:nl,03)",
            "urn:li:state:(urn:li:country:nl,15)",
            "urn:li:state:(urn:li:country:nl,02)",
            "urn:li:state:(urn:li:country:nl,16)",
            "urn:li:state:(urn:li:country:nl,09)"
        ]
    },
    {
        "name": {
            "value": "Bergen Op Zoom, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5666,
        "urn": "urn:li:region:5666",
        "states": []
    },
    {
        "name": {
            "value": "Delft, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5667,
        "urn": "urn:li:region:5667",
        "states": []
    },
    {
        "name": {
            "value": "Eindhoven, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5668,
        "urn": "urn:li:region:5668",
        "states": [
            "urn:li:state:(urn:li:country:nl,06)",
            "urn:li:state:(urn:li:country:nl,05)",
            "urn:li:state:(urn:li:country:nl,03)"
        ]
    },
    {
        "name": {
            "value": "Enschede, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5669,
        "urn": "urn:li:region:5669",
        "states": [
            "urn:li:state:(urn:li:country:nl,15)",
            "urn:li:state:(urn:li:country:nl,03)",
            "urn:li:state:(urn:li:country:nl,01)"
        ]
    },
    {
        "name": {
            "value": "Friesland, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5670,
        "urn": "urn:li:region:5670",
        "states": []
    },
    {
        "name": {
            "value": "Ge, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5671,
        "urn": "urn:li:region:5671",
        "states": []
    },
    {
        "name": {
            "value": "Gr, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5672,
        "urn": "urn:li:region:5672",
        "states": []
    },
    {
        "name": {
            "value": "Groningen, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5673,
        "urn": "urn:li:region:5673",
        "states": [
            "urn:li:state:(urn:li:country:nl,04)",
            "urn:li:state:(urn:li:country:nl,02)",
            "urn:li:state:(urn:li:country:nl,01)",
            "urn:li:state:(urn:li:country:nl,15)"
        ]
    },
    {
        "name": {
            "value": "Groningen, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5674,
        "urn": "urn:li:region:5674",
        "states": []
    },
    {
        "name": {
            "value": "Haarlem, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5675,
        "urn": "urn:li:region:5675",
        "states": []
    },
    {
        "name": {
            "value": "Leeuwarden, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5676,
        "urn": "urn:li:region:5676",
        "states": []
    },
    {
        "name": {
            "value": "Li, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5677,
        "urn": "urn:li:region:5677",
        "states": []
    },
    {
        "name": {
            "value": "Nb, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5679,
        "urn": "urn:li:region:5679",
        "states": []
    },
    {
        "name": {
            "value": "Nh, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5680,
        "urn": "urn:li:region:5680",
        "states": []
    },
    {
        "name": {
            "value": "Nijmegen, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5681,
        "urn": "urn:li:region:5681",
        "states": [
            "urn:li:state:(urn:li:country:nl,03)",
            "urn:li:state:(urn:li:country:nl,05)",
            "urn:li:state:(urn:li:country:nl,06)",
            "urn:li:state:(urn:li:country:nl,09)",
            "urn:li:state:(urn:li:country:nl,11)"
        ]
    },
    {
        "name": {
            "value": "Noord Holland, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5682,
        "urn": "urn:li:region:5682",
        "states": []
    },
    {
        "name": {
            "value": "Ov, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5683,
        "urn": "urn:li:region:5683",
        "states": []
    },
    {
        "name": {
            "value": "Overijssel, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5684,
        "urn": "urn:li:region:5684",
        "states": []
    },
    {
        "name": {
            "value": "Roosendaal, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5685,
        "urn": "urn:li:region:5685",
        "states": []
    },
    {
        "name": {
            "value": "Spijkenisse, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5686,
        "urn": "urn:li:region:5686",
        "states": []
    },
    {
        "name": {
            "value": "Terneuzen, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5687,
        "urn": "urn:li:region:5687",
        "states": []
    },
    {
        "name": {
            "value": "The Hague, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5688,
        "urn": "urn:li:region:5688",
        "states": [
            "urn:li:state:(urn:li:country:nl,11)",
            "urn:li:state:(urn:li:country:nl,10)",
            "urn:li:state:(urn:li:country:nl,06)",
            "urn:li:state:(urn:li:country:nl,07)",
            "urn:li:state:(urn:li:country:nl,09)"
        ]
    },
    {
        "name": {
            "value": "Ut, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5689,
        "urn": "urn:li:region:5689",
        "states": []
    },
    {
        "name": {
            "value": "Utrecht, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5690,
        "urn": "urn:li:region:5690",
        "states": [
            "urn:li:state:(urn:li:country:nl,09)",
            "urn:li:state:(urn:li:country:nl,03)",
            "urn:li:state:(urn:li:country:nl,11)",
            "urn:li:state:(urn:li:country:nl,07)",
            "urn:li:state:(urn:li:country:nl,06)",
            "urn:li:state:(urn:li:country:nl,15)"
        ]
    },
    {
        "name": {
            "value": "Vlaardingen, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5691,
        "urn": "urn:li:region:5691",
        "states": []
    },
    {
        "name": {
            "value": "Zaanstad, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5692,
        "urn": "urn:li:region:5692",
        "states": []
    },
    {
        "name": {
            "value": "Zh, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5693,
        "urn": "urn:li:region:5693",
        "states": []
    },
    {
        "name": {
            "value": "Zwolle, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5694,
        "urn": "urn:li:region:5694",
        "states": [
            "urn:li:state:(urn:li:country:nl,15)",
            "urn:li:state:(urn:li:country:nl,01)",
            "urn:li:state:(urn:li:country:nl,16)",
            "urn:li:state:(urn:li:country:nl,03)",
            "urn:li:state:(urn:li:country:nl,04)"
        ]
    },
    {
        "name": {
            "value": "Akershus, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5695,
        "urn": "urn:li:region:5695",
        "states": [
            "urn:li:state:(urn:li:country:no,02)"
        ]
    },
    {
        "name": {
            "value": "Aust-Agder County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5696,
        "urn": "urn:li:region:5696",
        "states": [
            "urn:li:state:(urn:li:country:no,09)"
        ]
    },
    {
        "name": {
            "value": "Bergen, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5697,
        "urn": "urn:li:region:5697",
        "states": [
            "urn:li:state:(urn:li:country:no,12)",
            "urn:li:state:(urn:li:country:no,14)"
        ]
    },
    {
        "name": {
            "value": "Bjornoya, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5698,
        "urn": "urn:li:region:5698",
        "states": [
            "urn:li:state:(urn:li:country:no,19)"
        ]
    },
    {
        "name": {
            "value": "Buskerud County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5699,
        "urn": "urn:li:region:5699",
        "states": [
            "urn:li:state:(urn:li:country:no,06)"
        ]
    },
    {
        "name": {
            "value": "Drammen, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5700,
        "urn": "urn:li:region:5700",
        "states": [
            "urn:li:state:(urn:li:country:no,07)",
            "urn:li:state:(urn:li:country:no,06)",
            "urn:li:state:(urn:li:country:no,08)",
            "urn:li:state:(urn:li:country:no,01)"
        ]
    },
    {
        "name": {
            "value": "Finnmark County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5701,
        "urn": "urn:li:region:5701",
        "states": [
            "urn:li:state:(urn:li:country:no,20)"
        ]
    },
    {
        "name": {
            "value": "Hedmark County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5702,
        "urn": "urn:li:region:5702",
        "states": [
            "urn:li:state:(urn:li:country:no,04)",
            "urn:li:state:(urn:li:country:no,05)"
        ]
    },
    {
        "name": {
            "value": "Hopen, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5703,
        "urn": "urn:li:region:5703",
        "states": []
    },
    {
        "name": {
            "value": "Hordaland County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5704,
        "urn": "urn:li:region:5704",
        "states": [
            "urn:li:state:(urn:li:country:no,12)"
        ]
    },
    {
        "name": {
            "value": "Jan Mayen, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5705,
        "urn": "urn:li:region:5705",
        "states": [
            "urn:li:state:(urn:li:country:no,18)"
        ]
    },
    {
        "name": {
            "value": "Kristiansand, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5706,
        "urn": "urn:li:region:5706",
        "states": [
            "urn:li:state:(urn:li:country:no,10)",
            "urn:li:state:(urn:li:country:no,09)"
        ]
    },
    {
        "name": {
            "value": "More og Romsdal County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5707,
        "urn": "urn:li:region:5707",
        "states": [
            "urn:li:state:(urn:li:country:no,15)"
        ]
    },
    {
        "name": {
            "value": "Nord-Trondelag County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5708,
        "urn": "urn:li:region:5708",
        "states": [
            "urn:li:state:(urn:li:country:no,17)"
        ]
    },
    {
        "name": {
            "value": "Nordland County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5709,
        "urn": "urn:li:region:5709",
        "states": [
            "urn:li:state:(urn:li:country:no,18)"
        ]
    },
    {
        "name": {
            "value": "Ostfold County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5710,
        "urn": "urn:li:region:5710",
        "states": [
            "urn:li:state:(urn:li:country:no,01)"
        ]
    },
    {
        "name": {
            "value": "Oppland County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5711,
        "urn": "urn:li:region:5711",
        "states": [
            "urn:li:state:(urn:li:country:no,05)"
        ]
    },
    {
        "name": {
            "value": "Oslo, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5712,
        "urn": "urn:li:region:5712",
        "states": [
            "urn:li:state:(urn:li:country:no,03)",
            "urn:li:state:(urn:li:country:no,02)",
            "urn:li:state:(urn:li:country:no,01)",
            "urn:li:state:(urn:li:country:no,06)",
            "urn:li:state:(urn:li:country:no,07)",
            "urn:li:state:(urn:li:country:no,05)"
        ]
    },
    {
        "name": {
            "value": "Rogaland County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5713,
        "urn": "urn:li:region:5713",
        "states": [
            "urn:li:state:(urn:li:country:no,11)",
            "urn:li:state:(urn:li:country:no,09)"
        ]
    },
    {
        "name": {
            "value": "Sor-Trondelag County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5714,
        "urn": "urn:li:region:5714",
        "states": [
            "urn:li:state:(urn:li:country:no,16)"
        ]
    },
    {
        "name": {
            "value": "Sogn og Fjordane County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5715,
        "urn": "urn:li:region:5715",
        "states": [
            "urn:li:state:(urn:li:country:no,14)"
        ]
    },
    {
        "name": {
            "value": "Spitsbergen, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5716,
        "urn": "urn:li:region:5716",
        "states": []
    },
    {
        "name": {
            "value": "Stavanger, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5717,
        "urn": "urn:li:region:5717",
        "states": [
            "urn:li:state:(urn:li:country:no,11)",
            "urn:li:state:(urn:li:country:no,12)"
        ]
    },
    {
        "name": {
            "value": "Telemark County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5718,
        "urn": "urn:li:region:5718",
        "states": [
            "urn:li:state:(urn:li:country:no,08)"
        ]
    },
    {
        "name": {
            "value": "Troms County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5719,
        "urn": "urn:li:region:5719",
        "states": [
            "urn:li:state:(urn:li:country:no,19)",
            "urn:li:state:(urn:li:country:no,18)"
        ]
    },
    {
        "name": {
            "value": "Tromso, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5720,
        "urn": "urn:li:region:5720",
        "states": [
            "urn:li:state:(urn:li:country:no,19)"
        ]
    },
    {
        "name": {
            "value": "Trondheim, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5721,
        "urn": "urn:li:region:5721",
        "states": [
            "urn:li:state:(urn:li:country:no,16)",
            "urn:li:state:(urn:li:country:no,17)"
        ]
    },
    {
        "name": {
            "value": "Vest-Agder County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5722,
        "urn": "urn:li:region:5722",
        "states": [
            "urn:li:state:(urn:li:country:no,10)"
        ]
    },
    {
        "name": {
            "value": "Vestfold County, Norway"
        },
        "country": "urn:li:country:no",
        "id": 5723,
        "urn": "urn:li:region:5723",
        "states": [
            "urn:li:state:(urn:li:country:no,07)"
        ]
    },
    {
        "name": {
            "value": "Biała Podlaska, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5724,
        "urn": "urn:li:region:5724",
        "states": []
    },
    {
        "name": {
            "value": "Białystok, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5725,
        "urn": "urn:li:region:5725",
        "states": []
    },
    {
        "name": {
            "value": "Bielsko-Biała, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5726,
        "urn": "urn:li:region:5726",
        "states": []
    },
    {
        "name": {
            "value": "Brodnicki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5727,
        "urn": "urn:li:region:5727",
        "states": []
    },
    {
        "name": {
            "value": "Bydgoszcz, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5728,
        "urn": "urn:li:region:5728",
        "states": []
    },
    {
        "name": {
            "value": "Chełm, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5729,
        "urn": "urn:li:region:5729",
        "states": []
    },
    {
        "name": {
            "value": "Chojnicki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5730,
        "urn": "urn:li:region:5730",
        "states": []
    },
    {
        "name": {
            "value": "Czarnkowsko-Trzcianecki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5731,
        "urn": "urn:li:region:5731",
        "states": []
    },
    {
        "name": {
            "value": "Częstochowa, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5732,
        "urn": "urn:li:region:5732",
        "states": []
    },
    {
        "name": {
            "value": "Człuchowski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5733,
        "urn": "urn:li:region:5733",
        "states": []
    },
    {
        "name": {
            "value": "Drawski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5734,
        "urn": "urn:li:region:5734",
        "states": []
    },
    {
        "name": {
            "value": "Elblag, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5735,
        "urn": "urn:li:region:5735",
        "states": []
    },
    {
        "name": {
            "value": "Ełk, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5736,
        "urn": "urn:li:region:5736",
        "states": []
    },
    {
        "name": {
            "value": "Gdańsk, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5737,
        "urn": "urn:li:region:5737",
        "states": []
    },
    {
        "name": {
            "value": "Gdynia, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5738,
        "urn": "urn:li:region:5738",
        "states": []
    },
    {
        "name": {
            "value": "Giżycki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5739,
        "urn": "urn:li:region:5739",
        "states": []
    },
    {
        "name": {
            "value": "Gliwice, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5740,
        "urn": "urn:li:region:5740",
        "states": []
    },
    {
        "name": {
            "value": "Gorzów Wielkopolski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5741,
        "urn": "urn:li:region:5741",
        "states": []
    },
    {
        "name": {
            "value": "Grudziądz, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5742,
        "urn": "urn:li:region:5742",
        "states": []
    },
    {
        "name": {
            "value": "Gryficki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5743,
        "urn": "urn:li:region:5743",
        "states": []
    },
    {
        "name": {
            "value": "Głogów, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5744,
        "urn": "urn:li:region:5744",
        "states": []
    },
    {
        "name": {
            "value": "Hajnowski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5745,
        "urn": "urn:li:region:5745",
        "states": []
    },
    {
        "name": {
            "value": "Hrubieszowski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5746,
        "urn": "urn:li:region:5746",
        "states": []
    },
    {
        "name": {
            "value": "Iławski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5747,
        "urn": "urn:li:region:5747",
        "states": []
    },
    {
        "name": {
            "value": "Jelenia Góra, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5748,
        "urn": "urn:li:region:5748",
        "states": []
    },
    {
        "name": {
            "value": "Kalisz, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5749,
        "urn": "urn:li:region:5749",
        "states": []
    },
    {
        "name": {
            "value": "Kartuski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5750,
        "urn": "urn:li:region:5750",
        "states": []
    },
    {
        "name": {
            "value": "Katowice, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5751,
        "urn": "urn:li:region:5751",
        "states": []
    },
    {
        "name": {
            "value": "Kielce, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5752,
        "urn": "urn:li:region:5752",
        "states": []
    },
    {
        "name": {
            "value": "Koszalin, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5753,
        "urn": "urn:li:region:5753",
        "states": []
    },
    {
        "name": {
            "value": "Kołobrzeski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5754,
        "urn": "urn:li:region:5754",
        "states": []
    },
    {
        "name": {
            "value": "Kościerski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5755,
        "urn": "urn:li:region:5755",
        "states": []
    },
    {
        "name": {
            "value": "Kraków, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5756,
        "urn": "urn:li:region:5756",
        "states": []
    },
    {
        "name": {
            "value": "Krośnieński, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5757,
        "urn": "urn:li:region:5757",
        "states": []
    },
    {
        "name": {
            "value": "Kętrzyński, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5758,
        "urn": "urn:li:region:5758",
        "states": []
    },
    {
        "name": {
            "value": "Legionowo, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5759,
        "urn": "urn:li:region:5759",
        "states": []
    },
    {
        "name": {
            "value": "Legnica, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5760,
        "urn": "urn:li:region:5760",
        "states": []
    },
    {
        "name": {
            "value": "Leszno, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5761,
        "urn": "urn:li:region:5761",
        "states": []
    },
    {
        "name": {
            "value": "Lubin, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5762,
        "urn": "urn:li:region:5762",
        "states": []
    },
    {
        "name": {
            "value": "Lublin, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5763,
        "urn": "urn:li:region:5763",
        "states": []
    },
    {
        "name": {
            "value": "Międzychodzki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5764,
        "urn": "urn:li:region:5764",
        "states": []
    },
    {
        "name": {
            "value": "Mrągowski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5765,
        "urn": "urn:li:region:5765",
        "states": []
    },
    {
        "name": {
            "value": "Mławski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5766,
        "urn": "urn:li:region:5766",
        "states": []
    },
    {
        "name": {
            "value": "Nowomiejski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5767,
        "urn": "urn:li:region:5767",
        "states": []
    },
    {
        "name": {
            "value": "Nowy Sącz, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5768,
        "urn": "urn:li:region:5768",
        "states": []
    },
    {
        "name": {
            "value": "Olsztyn, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5769,
        "urn": "urn:li:region:5769",
        "states": []
    },
    {
        "name": {
            "value": "Opole, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5770,
        "urn": "urn:li:region:5770",
        "states": []
    },
    {
        "name": {
            "value": "Ostrowiec Świętokrzyski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5771,
        "urn": "urn:li:region:5771",
        "states": []
    },
    {
        "name": {
            "value": "Ostrołęka, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5772,
        "urn": "urn:li:region:5772",
        "states": []
    },
    {
        "name": {
            "value": "Piotrków Trybunalski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5773,
        "urn": "urn:li:region:5773",
        "states": []
    },
    {
        "name": {
            "value": "Piła, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5774,
        "urn": "urn:li:region:5774",
        "states": []
    },
    {
        "name": {
            "value": "Poznań, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5775,
        "urn": "urn:li:region:5775",
        "states": []
    },
    {
        "name": {
            "value": "Przemyśl, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5776,
        "urn": "urn:li:region:5776",
        "states": []
    },
    {
        "name": {
            "value": "Płock, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5777,
        "urn": "urn:li:region:5777",
        "states": []
    },
    {
        "name": {
            "value": "Radom, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5778,
        "urn": "urn:li:region:5778",
        "states": []
    },
    {
        "name": {
            "value": "Rzeszów, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5779,
        "urn": "urn:li:region:5779",
        "states": []
    },
    {
        "name": {
            "value": "Siedlce, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5780,
        "urn": "urn:li:region:5780",
        "states": []
    },
    {
        "name": {
            "value": "Stargard Szczeciński, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5781,
        "urn": "urn:li:region:5781",
        "states": []
    },
    {
        "name": {
            "value": "Suwałki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5782,
        "urn": "urn:li:region:5782",
        "states": []
    },
    {
        "name": {
            "value": "Szczecin Pogodno, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5783,
        "urn": "urn:li:region:5783",
        "states": []
    },
    {
        "name": {
            "value": "Słubicki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5784,
        "urn": "urn:li:region:5784",
        "states": []
    },
    {
        "name": {
            "value": "Słupsk, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5785,
        "urn": "urn:li:region:5785",
        "states": []
    },
    {
        "name": {
            "value": "Tarnów, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5786,
        "urn": "urn:li:region:5786",
        "states": []
    },
    {
        "name": {
            "value": "Tatrzański, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5787,
        "urn": "urn:li:region:5787",
        "states": []
    },
    {
        "name": {
            "value": "Tomaszów Mazowiecki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5788,
        "urn": "urn:li:region:5788",
        "states": []
    },
    {
        "name": {
            "value": "Toruń, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5789,
        "urn": "urn:li:region:5789",
        "states": []
    },
    {
        "name": {
            "value": "Warsaw, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5790,
        "urn": "urn:li:region:5790",
        "states": []
    },
    {
        "name": {
            "value": "Wałbrzych, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5791,
        "urn": "urn:li:region:5791",
        "states": []
    },
    {
        "name": {
            "value": "Wejherowski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5792,
        "urn": "urn:li:region:5792",
        "states": []
    },
    {
        "name": {
            "value": "Wrocław, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5793,
        "urn": "urn:li:region:5793",
        "states": []
    },
    {
        "name": {
            "value": "Włocławek, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5794,
        "urn": "urn:li:region:5794",
        "states": []
    },
    {
        "name": {
            "value": "Zamość, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5795,
        "urn": "urn:li:region:5795",
        "states": []
    },
    {
        "name": {
            "value": "Zgorzelecki, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5796,
        "urn": "urn:li:region:5796",
        "states": []
    },
    {
        "name": {
            "value": "Zielona Góra, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5797,
        "urn": "urn:li:region:5797",
        "states": []
    },
    {
        "name": {
            "value": "Łomża, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5798,
        "urn": "urn:li:region:5798",
        "states": []
    },
    {
        "name": {
            "value": "Łódź, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5799,
        "urn": "urn:li:region:5799",
        "states": []
    },
    {
        "name": {
            "value": "Świdnica, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5800,
        "urn": "urn:li:region:5800",
        "states": []
    },
    {
        "name": {
            "value": "Żarski, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 5801,
        "urn": "urn:li:region:5801",
        "states": []
    },
    {
        "name": {
            "value": "Atlantis, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5841,
        "urn": "urn:li:region:5841",
        "states": []
    },
    {
        "name": {
            "value": "Barberton, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5842,
        "urn": "urn:li:region:5842",
        "states": []
    },
    {
        "name": {
            "value": "Benoni, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5843,
        "urn": "urn:li:region:5843",
        "states": []
    },
    {
        "name": {
            "value": "Bethal, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5844,
        "urn": "urn:li:region:5844",
        "states": []
    },
    {
        "name": {
            "value": "Bethlehem, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5845,
        "urn": "urn:li:region:5845",
        "states": []
    },
    {
        "name": {
            "value": "Bisho, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5846,
        "urn": "urn:li:region:5846",
        "states": []
    },
    {
        "name": {
            "value": "Bloemfontein, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5847,
        "urn": "urn:li:region:5847",
        "states": []
    },
    {
        "name": {
            "value": "Bothaville, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5848,
        "urn": "urn:li:region:5848",
        "states": []
    },
    {
        "name": {
            "value": "Botshabelo, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5849,
        "urn": "urn:li:region:5849",
        "states": []
    },
    {
        "name": {
            "value": "Brakpan, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5850,
        "urn": "urn:li:region:5850",
        "states": []
    },
    {
        "name": {
            "value": "Brits, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5851,
        "urn": "urn:li:region:5851",
        "states": []
    },
    {
        "name": {
            "value": "Cape Town, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5852,
        "urn": "urn:li:region:5852",
        "states": []
    },
    {
        "name": {
            "value": "Carletonville, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5853,
        "urn": "urn:li:region:5853",
        "states": []
    },
    {
        "name": {
            "value": "Dundee, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5854,
        "urn": "urn:li:region:5854",
        "states": []
    },
    {
        "name": {
            "value": "Durban, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5855,
        "urn": "urn:li:region:5855",
        "states": []
    },
    {
        "name": {
            "value": "Embalenhle, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5856,
        "urn": "urn:li:region:5856",
        "states": []
    },
    {
        "name": {
            "value": "Fort Beaufort, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5857,
        "urn": "urn:li:region:5857",
        "states": []
    },
    {
        "name": {
            "value": "George, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5858,
        "urn": "urn:li:region:5858",
        "states": []
    },
    {
        "name": {
            "value": "Graaff-Reinet, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5859,
        "urn": "urn:li:region:5859",
        "states": []
    },
    {
        "name": {
            "value": "Grahamstown, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5860,
        "urn": "urn:li:region:5860",
        "states": []
    },
    {
        "name": {
            "value": "Heilbron, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5861,
        "urn": "urn:li:region:5861",
        "states": []
    },
    {
        "name": {
            "value": "Johannesburg, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5862,
        "urn": "urn:li:region:5862",
        "states": []
    },
    {
        "name": {
            "value": "Kimberley, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5863,
        "urn": "urn:li:region:5863",
        "states": []
    },
    {
        "name": {
            "value": "Klerksdorp, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5864,
        "urn": "urn:li:region:5864",
        "states": []
    },
    {
        "name": {
            "value": "Knysna, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5865,
        "urn": "urn:li:region:5865",
        "states": []
    },
    {
        "name": {
            "value": "Kroonstad, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5866,
        "urn": "urn:li:region:5866",
        "states": []
    },
    {
        "name": {
            "value": "Krugersdorp, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5867,
        "urn": "urn:li:region:5867",
        "states": []
    },
    {
        "name": {
            "value": "Louis Trichardt, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5868,
        "urn": "urn:li:region:5868",
        "states": []
    },
    {
        "name": {
            "value": "Mabopane, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5869,
        "urn": "urn:li:region:5869",
        "states": []
    },
    {
        "name": {
            "value": "Middelburg, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5870,
        "urn": "urn:li:region:5870",
        "states": []
    },
    {
        "name": {
            "value": "Mmabatho, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5871,
        "urn": "urn:li:region:5871",
        "states": []
    },
    {
        "name": {
            "value": "Mpumalanga, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5872,
        "urn": "urn:li:region:5872",
        "states": []
    },
    {
        "name": {
            "value": "Nelspruit, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5873,
        "urn": "urn:li:region:5873",
        "states": []
    },
    {
        "name": {
            "value": "Newcastle, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5874,
        "urn": "urn:li:region:5874",
        "states": []
    },
    {
        "name": {
            "value": "Noorder-Paarl, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5875,
        "urn": "urn:li:region:5875",
        "states": []
    },
    {
        "name": {
            "value": "Orkney, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5876,
        "urn": "urn:li:region:5876",
        "states": []
    },
    {
        "name": {
            "value": "Oudtshoorn, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5877,
        "urn": "urn:li:region:5877",
        "states": []
    },
    {
        "name": {
            "value": "Phalaborwa, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5878,
        "urn": "urn:li:region:5878",
        "states": []
    },
    {
        "name": {
            "value": "Phuthaditjhaba, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5879,
        "urn": "urn:li:region:5879",
        "states": []
    },
    {
        "name": {
            "value": "Piet Retief, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5880,
        "urn": "urn:li:region:5880",
        "states": []
    },
    {
        "name": {
            "value": "Pietermaritzburg, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5881,
        "urn": "urn:li:region:5881",
        "states": []
    },
    {
        "name": {
            "value": "Polokwane, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5882,
        "urn": "urn:li:region:5882",
        "states": []
    },
    {
        "name": {
            "value": "Port Elizabeth, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5883,
        "urn": "urn:li:region:5883",
        "states": []
    },
    {
        "name": {
            "value": "Port Shepstone, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5884,
        "urn": "urn:li:region:5884",
        "states": []
    },
    {
        "name": {
            "value": "Potgietersrus, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5885,
        "urn": "urn:li:region:5885",
        "states": []
    },
    {
        "name": {
            "value": "Pretoria, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5886,
        "urn": "urn:li:region:5886",
        "states": []
    },
    {
        "name": {
            "value": "Queenstown, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5887,
        "urn": "urn:li:region:5887",
        "states": []
    },
    {
        "name": {
            "value": "Richards Bay, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5888,
        "urn": "urn:li:region:5888",
        "states": []
    },
    {
        "name": {
            "value": "Rustenburg, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5889,
        "urn": "urn:li:region:5889",
        "states": []
    },
    {
        "name": {
            "value": "Saldanha, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5890,
        "urn": "urn:li:region:5890",
        "states": []
    },
    {
        "name": {
            "value": "Schweizer-Reineke, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5891,
        "urn": "urn:li:region:5891",
        "states": []
    },
    {
        "name": {
            "value": "Somerset West, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5892,
        "urn": "urn:li:region:5892",
        "states": []
    },
    {
        "name": {
            "value": "Standerton, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5893,
        "urn": "urn:li:region:5893",
        "states": []
    },
    {
        "name": {
            "value": "Stanger, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5894,
        "urn": "urn:li:region:5894",
        "states": []
    },
    {
        "name": {
            "value": "Uitenhage, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5895,
        "urn": "urn:li:region:5895",
        "states": []
    },
    {
        "name": {
            "value": "Umtata, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5896,
        "urn": "urn:li:region:5896",
        "states": []
    },
    {
        "name": {
            "value": "Upington, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5897,
        "urn": "urn:li:region:5897",
        "states": []
    },
    {
        "name": {
            "value": "Vanderbijlpark, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5898,
        "urn": "urn:li:region:5898",
        "states": []
    },
    {
        "name": {
            "value": "Vereeniging, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5899,
        "urn": "urn:li:region:5899",
        "states": []
    },
    {
        "name": {
            "value": "Virginia, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5900,
        "urn": "urn:li:region:5900",
        "states": []
    },
    {
        "name": {
            "value": "Vryheid, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5901,
        "urn": "urn:li:region:5901",
        "states": []
    },
    {
        "name": {
            "value": "Warmbaths, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5902,
        "urn": "urn:li:region:5902",
        "states": []
    },
    {
        "name": {
            "value": "Welkom, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5903,
        "urn": "urn:li:region:5903",
        "states": []
    },
    {
        "name": {
            "value": "Witbank, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5904,
        "urn": "urn:li:region:5904",
        "states": []
    },
    {
        "name": {
            "value": "Worcester, South Africa"
        },
        "country": "urn:li:country:za",
        "id": 5905,
        "urn": "urn:li:region:5905",
        "states": []
    },
    {
        "name": {
            "value": "Breda, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5906,
        "urn": "urn:li:region:5906",
        "states": [
            "urn:li:state:(urn:li:country:nl,06)",
            "urn:li:state:(urn:li:country:nl,11)",
            "urn:li:state:(urn:li:country:nl,10)"
        ]
    },
    {
        "name": {
            "value": "Tilburg, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5907,
        "urn": "urn:li:region:5907",
        "states": [
            "urn:li:state:(urn:li:country:nl,06)",
            "urn:li:state:(urn:li:country:nl,03)",
            "urn:li:state:(urn:li:country:nl,11)"
        ]
    },
    {
        "name": {
            "value": "Rotterdam, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 5908,
        "urn": "urn:li:region:5908",
        "states": [
            "urn:li:state:(urn:li:country:nl,11)",
            "urn:li:state:(urn:li:country:nl,06)",
            "urn:li:state:(urn:li:country:nl,10)",
            "urn:li:state:(urn:li:country:nl,01)",
            "urn:li:state:(urn:li:country:nl,09)"
        ]
    },
    {
        "name": {
            "value": "Tlalnepantla, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5909,
        "urn": "urn:li:region:5909",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,DIF)"
        ]
    },
    {
        "name": {
            "value": "Aguascalientes, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5910,
        "urn": "urn:li:region:5910",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,AGU)",
            "urn:li:state:(urn:li:country:mx,ZAC)",
            "urn:li:state:(urn:li:country:mx,JAL)",
            "urn:li:state:(urn:li:country:mx,SLP)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,MIC)",
            "urn:li:state:(urn:li:country:mx,HID)"
        ]
    },
    {
        "name": {
            "value": "Cancún, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5911,
        "urn": "urn:li:region:5911",
        "states": [
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,YUC)"
        ]
    },
    {
        "name": {
            "value": "Durango, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5912,
        "urn": "urn:li:region:5912",
        "states": [
            "urn:li:state:(urn:li:country:mx,DUR)",
            "urn:li:state:(urn:li:country:mx,ZAC)",
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,ROO)"
        ]
    },
    {
        "name": {
            "value": "Ciudad Victoria, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5913,
        "urn": "urn:li:region:5913",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,NLE)",
            "urn:li:state:(urn:li:country:mx,SLP)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,COA)"
        ]
    },
    {
        "name": {
            "value": "Campeche, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5914,
        "urn": "urn:li:region:5914",
        "states": [
            "urn:li:state:(urn:li:country:mx,CAM)",
            "urn:li:state:(urn:li:country:mx,ROO)"
        ]
    },
    {
        "name": {
            "value": "Ecatepec, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5915,
        "urn": "urn:li:region:5915",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,HID)",
            "urn:li:state:(urn:li:country:mx,VER)",
            "urn:li:state:(urn:li:country:mx,PUE)",
            "urn:li:state:(urn:li:country:mx,DIF)",
            "urn:li:state:(urn:li:country:mx,TLA)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,GUA)"
        ]
    },
    {
        "name": {
            "value": "Ciudad Nezahualcóyotl, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5916,
        "urn": "urn:li:region:5916",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,DIF)",
            "urn:li:state:(urn:li:country:mx,MOR)",
            "urn:li:state:(urn:li:country:mx,PUE)",
            "urn:li:state:(urn:li:country:mx,TLA)"
        ]
    },
    {
        "name": {
            "value": "Mérida, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5917,
        "urn": "urn:li:region:5917",
        "states": [
            "urn:li:state:(urn:li:country:mx,YUC)",
            "urn:li:state:(urn:li:country:mx,CAM)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,VER)"
        ]
    },
    {
        "name": {
            "value": "Torreón, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5918,
        "urn": "urn:li:region:5918",
        "states": [
            "urn:li:state:(urn:li:country:mx,DUR)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,ZAC)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Coatzacoalcos, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5919,
        "urn": "urn:li:region:5919",
        "states": [
            "urn:li:state:(urn:li:country:mx,VER)",
            "urn:li:state:(urn:li:country:mx,OAX)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,PUE)",
            "urn:li:state:(urn:li:country:mx,YUC)"
        ]
    },
    {
        "name": {
            "value": "Nuevo Laredo, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5920,
        "urn": "urn:li:region:5920",
        "states": [
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,NLE)",
            "urn:li:state:(urn:li:country:mx,GRO)"
        ]
    },
    {
        "name": {
            "value": "Mexico City, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5921,
        "urn": "urn:li:region:5921",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,DIF)",
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,MOR)",
            "urn:li:state:(urn:li:country:mx,GRO)"
        ]
    },
    {
        "name": {
            "value": "San Nicolás de los Garzas, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5922,
        "urn": "urn:li:region:5922",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,NLE)",
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,HID)"
        ]
    },
    {
        "name": {
            "value": "Veracruz, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5923,
        "urn": "urn:li:region:5923",
        "states": [
            "urn:li:state:(urn:li:country:mx,VER)",
            "urn:li:state:(urn:li:country:mx,OAX)",
            "urn:li:state:(urn:li:country:mx,PUE)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,GRO)"
        ]
    },
    {
        "name": {
            "value": "Querétaro, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5924,
        "urn": "urn:li:region:5924",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,QUE)",
            "urn:li:state:(urn:li:country:mx,GUA)",
            "urn:li:state:(urn:li:country:mx,HID)",
            "urn:li:state:(urn:li:country:mx,MIC)",
            "urn:li:state:(urn:li:country:mx,SLP)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Puebla de Zaragoza, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5925,
        "urn": "urn:li:region:5925",
        "states": [
            "urn:li:state:(urn:li:country:mx,PUE)",
            "urn:li:state:(urn:li:country:mx,TLA)",
            "urn:li:state:(urn:li:country:mx,OAX)",
            "urn:li:state:(urn:li:country:mx,VER)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,MOR)",
            "urn:li:state:(urn:li:country:mx,CHP)"
        ]
    },
    {
        "name": {
            "value": "Los Mochis, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5926,
        "urn": "urn:li:region:5926",
        "states": [
            "urn:li:state:(urn:li:country:mx,BCS)",
            "urn:li:state:(urn:li:country:mx,SIN)",
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,HID)",
            "urn:li:state:(urn:li:country:mx,JAL)"
        ]
    },
    {
        "name": {
            "value": "Oaxaca de Juárez, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5927,
        "urn": "urn:li:region:5927",
        "states": [
            "urn:li:state:(urn:li:country:mx,OAX)",
            "urn:li:state:(urn:li:country:mx,GRO)"
        ]
    },
    {
        "name": {
            "value": "Tuxtla Gutiérrez, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5928,
        "urn": "urn:li:region:5928",
        "states": [
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,OAX)",
            "urn:li:state:(urn:li:country:mx,VER)"
        ]
    },
    {
        "name": {
            "value": "Tampico, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5929,
        "urn": "urn:li:region:5929",
        "states": [
            "urn:li:state:(urn:li:country:mx,VER)",
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,SLP)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,HID)"
        ]
    },
    {
        "name": {
            "value": "Tlaquepaque, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5930,
        "urn": "urn:li:region:5930",
        "states": [
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,JAL)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Matamoros, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5931,
        "urn": "urn:li:region:5931",
        "states": [
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,COA)"
        ]
    },
    {
        "name": {
            "value": "Reynosa, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5932,
        "urn": "urn:li:region:5932",
        "states": [
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,ROO)"
        ]
    },
    {
        "name": {
            "value": "Tijuana, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5933,
        "urn": "urn:li:region:5933",
        "states": [
            "urn:li:state:(urn:li:country:mx,BCN)"
        ]
    },
    {
        "name": {
            "value": "Culiacán, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5934,
        "urn": "urn:li:region:5934",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,SIN)",
            "urn:li:state:(urn:li:country:mx,DUR)",
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Acapulco de Juárez, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5935,
        "urn": "urn:li:region:5935",
        "states": [
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,GUA)"
        ]
    },
    {
        "name": {
            "value": "Tepic, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5936,
        "urn": "urn:li:region:5936",
        "states": [
            "urn:li:state:(urn:li:country:mx,NAY)",
            "urn:li:state:(urn:li:country:mx,JAL)",
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,DUR)",
            "urn:li:state:(urn:li:country:mx,SIN)",
            "urn:li:state:(urn:li:country:mx,COL)"
        ]
    },
    {
        "name": {
            "value": "León, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5937,
        "urn": "urn:li:region:5937",
        "states": [
            "urn:li:state:(urn:li:country:mx,GUA)",
            "urn:li:state:(urn:li:country:mx,JAL)",
            "urn:li:state:(urn:li:country:mx,MIC)"
        ]
    },
    {
        "name": {
            "value": "Ciudad López Mateos, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5938,
        "urn": "urn:li:region:5938",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,HID)",
            "urn:li:state:(urn:li:country:mx,DIF)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Zapopan, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5939,
        "urn": "urn:li:region:5939",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,JAL)",
            "urn:li:state:(urn:li:country:mx,NAY)",
            "urn:li:state:(urn:li:country:mx,ZAC)",
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,HID)"
        ]
    },
    {
        "name": {
            "value": "Tehuacán, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5940,
        "urn": "urn:li:region:5940",
        "states": [
            "urn:li:state:(urn:li:country:mx,OAX)",
            "urn:li:state:(urn:li:country:mx,GRO)"
        ]
    },
    {
        "name": {
            "value": "Jalapa Enríquez, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5941,
        "urn": "urn:li:region:5941",
        "states": [
            "urn:li:state:(urn:li:country:mx,VER)"
        ]
    },
    {
        "name": {
            "value": "Mexicali, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5942,
        "urn": "urn:li:region:5942",
        "states": [
            "urn:li:state:(urn:li:country:mx,BCN)",
            "urn:li:state:(urn:li:country:mx,SON)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Guadalupe, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5943,
        "urn": "urn:li:region:5943",
        "states": [
            "urn:li:state:(urn:li:country:mx,NLE)",
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,TLA)",
            "urn:li:state:(urn:li:country:mx,HID)"
        ]
    },
    {
        "name": {
            "value": "Monterrey, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5944,
        "urn": "urn:li:region:5944",
        "states": [
            "urn:li:state:(urn:li:country:mx,NLE)",
            "urn:li:state:(urn:li:country:mx,HID)"
        ]
    },
    {
        "name": {
            "value": "Ciudad Obregón, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5945,
        "urn": "urn:li:region:5945",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,SON)",
            "urn:li:state:(urn:li:country:mx,BCS)",
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,ROO)"
        ]
    },
    {
        "name": {
            "value": "Ensenada, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5946,
        "urn": "urn:li:region:5946",
        "states": [
            "urn:li:state:(urn:li:country:mx,BCN)"
        ]
    },
    {
        "name": {
            "value": "Guadalajara, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5947,
        "urn": "urn:li:region:5947",
        "states": [
            "urn:li:state:(urn:li:country:mx,JAL)",
            "urn:li:state:(urn:li:country:mx,MIC)",
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Saltillo, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5948,
        "urn": "urn:li:region:5948",
        "states": [
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,ZAC)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,SLP)",
            "urn:li:state:(urn:li:country:mx,NLE)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,ROO)"
        ]
    },
    {
        "name": {
            "value": "San Luis Potosí, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5949,
        "urn": "urn:li:region:5949",
        "states": [
            "urn:li:state:(urn:li:country:mx,SLP)",
            "urn:li:state:(urn:li:country:mx,GUA)",
            "urn:li:state:(urn:li:country:mx,TAM)",
            "urn:li:state:(urn:li:country:mx,ZAC)",
            "urn:li:state:(urn:li:country:mx,JAL)",
            "urn:li:state:(urn:li:country:mx,NLE)",
            "urn:li:state:(urn:li:country:mx,ROO)",
            "urn:li:state:(urn:li:country:mx,COA)"
        ]
    },
    {
        "name": {
            "value": "Gómez Palacio, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5950,
        "urn": "urn:li:region:5950",
        "states": [
            "urn:li:state:(urn:li:country:mx,DUR)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Mazatlán, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5951,
        "urn": "urn:li:region:5951",
        "states": [
            "urn:li:state:(urn:li:country:mx,SIN)",
            "urn:li:state:(urn:li:country:mx,NAY)",
            "urn:li:state:(urn:li:country:mx,DUR)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,MIC)"
        ]
    },
    {
        "name": {
            "value": "Pachuca de Soto, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5952,
        "urn": "urn:li:region:5952",
        "states": [
            "urn:li:state:(urn:li:country:mx,VER)",
            "urn:li:state:(urn:li:country:mx,SLP)",
            "urn:li:state:(urn:li:country:mx,HID)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Villahermosa, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5953,
        "urn": "urn:li:region:5953",
        "states": [
            "urn:li:state:(urn:li:country:mx,TAB)",
            "urn:li:state:(urn:li:country:mx,CAM)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,GRO)"
        ]
    },
    {
        "name": {
            "value": "General Escobedo, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5954,
        "urn": "urn:li:region:5954",
        "states": [
            "urn:li:state:(urn:li:country:mx,CHP)",
            "urn:li:state:(urn:li:country:mx,COA)"
        ]
    },
    {
        "name": {
            "value": "Naucalpan de Juárez, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5955,
        "urn": "urn:li:region:5955",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,DIF)",
            "urn:li:state:(urn:li:country:mx,HID)"
        ]
    },
    {
        "name": {
            "value": "Chihuahua, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5956,
        "urn": "urn:li:region:5956",
        "states": [
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,DUR)",
            "urn:li:state:(urn:li:country:mx,COA)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,ROO)"
        ]
    },
    {
        "name": {
            "value": "Morelia, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5957,
        "urn": "urn:li:region:5957",
        "states": [
            "urn:li:state:(urn:li:country:mx,MIC)",
            "urn:li:state:(urn:li:country:mx,GUA)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Toluca, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5958,
        "urn": "urn:li:region:5958",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,MOR)",
            "urn:li:state:(urn:li:country:mx,MIC)",
            "urn:li:state:(urn:li:country:mx,QUE)",
            "urn:li:state:(urn:li:country:mx,PUE)"
        ]
    },
    {
        "name": {
            "value": "Hermosillo, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5959,
        "urn": "urn:li:region:5959",
        "states": [
            "urn:li:state:(urn:li:country:mx,MEX)",
            "urn:li:state:(urn:li:country:mx,SON)",
            "urn:li:state:(urn:li:country:mx,BCS)",
            "urn:li:state:(urn:li:country:mx,BCN)",
            "urn:li:state:(urn:li:country:mx,COL)",
            "urn:li:state:(urn:li:country:mx,GRO)"
        ]
    },
    {
        "name": {
            "value": "Uruapan del Progreso, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5960,
        "urn": "urn:li:region:5960",
        "states": [
            "urn:li:state:(urn:li:country:mx,MIC)",
            "urn:li:state:(urn:li:country:mx,GRO)",
            "urn:li:state:(urn:li:country:mx,JAL)"
        ]
    },
    {
        "name": {
            "value": "Tonalá, Mexico"
        },
        "country": "urn:li:country:mx",
        "id": 5961,
        "urn": "urn:li:region:5961",
        "states": [
            "urn:li:state:(urn:li:country:mx,CHH)",
            "urn:li:state:(urn:li:country:mx,COL)"
        ]
    },
    {
        "name": {
            "value": "North Pole, Canada"
        },
        "country": "urn:li:country:ca",
        "id": 5962,
        "urn": "urn:li:region:5962",
        "states": []
    },
    {
        "name": {
            "value": "Mococa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5963,
        "urn": "urn:li:region:5963",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Ipirá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5964,
        "urn": "urn:li:region:5964",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Formiga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5965,
        "urn": "urn:li:region:5965",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Ipiaú, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5966,
        "urn": "urn:li:region:5966",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Altamira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5967,
        "urn": "urn:li:region:5967",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Alta Floresta, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5968,
        "urn": "urn:li:region:5968",
        "states": [
            "urn:li:state:(urn:li:country:br,MT)",
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Assis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5969,
        "urn": "urn:li:region:5969",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Laguna, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5970,
        "urn": "urn:li:region:5970",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Ipatinga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5971,
        "urn": "urn:li:region:5971",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Rondonópolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5972,
        "urn": "urn:li:region:5972",
        "states": [
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Belém, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5973,
        "urn": "urn:li:region:5973",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "São João del Rei, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5974,
        "urn": "urn:li:region:5974",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Taquara, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5975,
        "urn": "urn:li:region:5975",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Florianópolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5976,
        "urn": "urn:li:region:5976",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "São João da Boa Vista, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5977,
        "urn": "urn:li:region:5977",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Coxim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5978,
        "urn": "urn:li:region:5978",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)"
        ]
    },
    {
        "name": {
            "value": "Inhumas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5979,
        "urn": "urn:li:region:5979",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Paranavaí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5980,
        "urn": "urn:li:region:5980",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MS)"
        ]
    },
    {
        "name": {
            "value": "Porangatu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5981,
        "urn": "urn:li:region:5981",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)",
            "urn:li:state:(urn:li:country:br,TO)",
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Xique Xique, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5982,
        "urn": "urn:li:region:5982",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Paranaíba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5983,
        "urn": "urn:li:region:5983",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,GO)",
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Paranaguá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5984,
        "urn": "urn:li:region:5984",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Capão da Canoa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5985,
        "urn": "urn:li:region:5985",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Beberibe, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5986,
        "urn": "urn:li:region:5986",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Capâo Bonito, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5987,
        "urn": "urn:li:region:5987",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Alenquer, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5988,
        "urn": "urn:li:region:5988",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Xanxerê, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5989,
        "urn": "urn:li:region:5989",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Coruripe, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5990,
        "urn": "urn:li:region:5990",
        "states": [
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Conceição do Araguaia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5991,
        "urn": "urn:li:region:5991",
        "states": [
            "urn:li:state:(urn:li:country:br,TO)",
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Tefé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5992,
        "urn": "urn:li:region:5992",
        "states": [
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Mineiros, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5993,
        "urn": "urn:li:region:5993",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)",
            "urn:li:state:(urn:li:country:br,MT)",
            "urn:li:state:(urn:li:country:br,MS)"
        ]
    },
    {
        "name": {
            "value": "Bom Jesus da Lapa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5994,
        "urn": "urn:li:region:5994",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Corumbá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5995,
        "urn": "urn:li:region:5995",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,AC)"
        ]
    },
    {
        "name": {
            "value": "Pontes e Lacerda, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5996,
        "urn": "urn:li:region:5996",
        "states": [
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Mamanguape, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5997,
        "urn": "urn:li:region:5997",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Volta Redonda, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5998,
        "urn": "urn:li:region:5998",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Ponte Nova, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 5999,
        "urn": "urn:li:region:5999",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Naviraí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6000,
        "urn": "urn:li:region:6000",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Santa Inês, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6001,
        "urn": "urn:li:region:6001",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Pirassununga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6002,
        "urn": "urn:li:region:6002",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Paraguaçu Paulista, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6003,
        "urn": "urn:li:region:6003",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Vitória da Conquista, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6004,
        "urn": "urn:li:region:6004",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Lagoa da Prata, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6005,
        "urn": "urn:li:region:6005",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Pará de Minas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6006,
        "urn": "urn:li:region:6006",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Vitória, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6007,
        "urn": "urn:li:region:6007",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "Paracatu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6008,
        "urn": "urn:li:region:6008",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Patos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6009,
        "urn": "urn:li:region:6009",
        "states": [
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,RN)"
        ]
    },
    {
        "name": {
            "value": "Bom Despacho, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6010,
        "urn": "urn:li:region:6010",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Jaboatão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6011,
        "urn": "urn:li:region:6011",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz do Capibaribe, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6012,
        "urn": "urn:li:region:6012",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "São Gotardo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6013,
        "urn": "urn:li:region:6013",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Ponta Porã, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6014,
        "urn": "urn:li:region:6014",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6015,
        "urn": "urn:li:region:6015",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Colinas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6016,
        "urn": "urn:li:region:6016",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Cajazeiras, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6017,
        "urn": "urn:li:region:6017",
        "states": [
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Alagoinhas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6018,
        "urn": "urn:li:region:6018",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Imbituba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6019,
        "urn": "urn:li:region:6019",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Tabatinga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6020,
        "urn": "urn:li:region:6020",
        "states": [
            "urn:li:state:(urn:li:country:br,AM)",
            "urn:li:state:(urn:li:country:br,AC)"
        ]
    },
    {
        "name": {
            "value": "Ponta Grossa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6021,
        "urn": "urn:li:region:6021",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Coelho Neto, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6022,
        "urn": "urn:li:region:6022",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "São Gabriel, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6023,
        "urn": "urn:li:region:6023",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Lagarto, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6024,
        "urn": "urn:li:region:6024",
        "states": [
            "urn:li:state:(urn:li:country:br,SE)",
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Codó, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6025,
        "urn": "urn:li:region:6025",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Itapipoca, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6026,
        "urn": "urn:li:region:6026",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Ilhéus, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6027,
        "urn": "urn:li:region:6027",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Bocaiúva, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6028,
        "urn": "urn:li:region:6028",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Vila Velha, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6029,
        "urn": "urn:li:region:6029",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)",
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,SE)"
        ]
    },
    {
        "name": {
            "value": "Betim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6030,
        "urn": "urn:li:region:6030",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Itapecuru Mirim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6031,
        "urn": "urn:li:region:6031",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Panambi, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6032,
        "urn": "urn:li:region:6032",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Itapagé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6033,
        "urn": "urn:li:region:6033",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Rio de Janeiro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6034,
        "urn": "urn:li:region:6034",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Pompéu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6035,
        "urn": "urn:li:region:6035",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Canavieiras, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6036,
        "urn": "urn:li:region:6036",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Arcos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6037,
        "urn": "urn:li:region:6037",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Ijuí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6038,
        "urn": "urn:li:region:6038",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)",
            "urn:li:state:(urn:li:country:br,RN)"
        ]
    },
    {
        "name": {
            "value": "Araxá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6039,
        "urn": "urn:li:region:6039",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Carazinho, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6040,
        "urn": "urn:li:region:6040",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Itaituba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6041,
        "urn": "urn:li:region:6041",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)",
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Barreiros, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6042,
        "urn": "urn:li:region:6042",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Caicó, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6043,
        "urn": "urn:li:region:6043",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "São Francisco, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6044,
        "urn": "urn:li:region:6044",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Rio Claro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6045,
        "urn": "urn:li:region:6045",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,AC)"
        ]
    },
    {
        "name": {
            "value": "Araruama, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6046,
        "urn": "urn:li:region:6046",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "São Fidélis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6047,
        "urn": "urn:li:region:6047",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Brejo da Madre de Deus, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6048,
        "urn": "urn:li:region:6048",
        "states": [
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Poços de Caldas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6049,
        "urn": "urn:li:region:6049",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Igarapava, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6050,
        "urn": "urn:li:region:6050",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Salinópolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6051,
        "urn": "urn:li:region:6051",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Videira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6052,
        "urn": "urn:li:region:6052",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Serra Talhada, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6053,
        "urn": "urn:li:region:6053",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Viçosa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6054,
        "urn": "urn:li:region:6054",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "União dos Palmares, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6055,
        "urn": "urn:li:region:6055",
        "states": [
            "urn:li:state:(urn:li:country:br,AL)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Afogados da Ingazeira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6056,
        "urn": "urn:li:region:6056",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Camaquã, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6057,
        "urn": "urn:li:region:6057",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Contagem, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6058,
        "urn": "urn:li:region:6058",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Poções, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6059,
        "urn": "urn:li:region:6059",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Araranguá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6060,
        "urn": "urn:li:region:6060",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Campos do Jordão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6061,
        "urn": "urn:li:region:6061",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Conselheiro Lafaiete, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6062,
        "urn": "urn:li:region:6062",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Campos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6063,
        "urn": "urn:li:region:6063",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ibitinga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6064,
        "urn": "urn:li:region:6064",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6065,
        "urn": "urn:li:region:6065",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Macapá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6066,
        "urn": "urn:li:region:6066",
        "states": [
            "urn:li:state:(urn:li:country:br,AP)",
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Juiz de Fora, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6067,
        "urn": "urn:li:region:6067",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Palmeira das Missões, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6068,
        "urn": "urn:li:region:6068",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)",
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Ribeirão Preto, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6069,
        "urn": "urn:li:region:6069",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz do Rio Pardo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6070,
        "urn": "urn:li:region:6070",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Mafra, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6071,
        "urn": "urn:li:region:6071",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Campo Mourão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6072,
        "urn": "urn:li:region:6072",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Açu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6073,
        "urn": "urn:li:region:6073",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)"
        ]
    },
    {
        "name": {
            "value": "Araguari, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6074,
        "urn": "urn:li:region:6074",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Piripiri, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6075,
        "urn": "urn:li:region:6075",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Petrópolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6076,
        "urn": "urn:li:region:6076",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ipu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6077,
        "urn": "urn:li:region:6077",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Campo Grande, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6078,
        "urn": "urn:li:region:6078",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,MT)",
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Matão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6079,
        "urn": "urn:li:region:6079",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Natal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6080,
        "urn": "urn:li:region:6080",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Planaltina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6081,
        "urn": "urn:li:region:6081",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Barra do Corda, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6082,
        "urn": "urn:li:region:6082",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Acaraú, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6083,
        "urn": "urn:li:region:6083",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Araçatuba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6084,
        "urn": "urn:li:region:6084",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Chapadinha, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6085,
        "urn": "urn:li:region:6085",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Palhoça, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6086,
        "urn": "urn:li:region:6086",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Ribeira do Pombal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6087,
        "urn": "urn:li:region:6087",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Concórdia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6088,
        "urn": "urn:li:region:6088",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Ibaiti, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6089,
        "urn": "urn:li:region:6089",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Piuí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6090,
        "urn": "urn:li:region:6090",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Aracaju, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6091,
        "urn": "urn:li:region:6091",
        "states": [
            "urn:li:state:(urn:li:country:br,SE)"
        ]
    },
    {
        "name": {
            "value": "Presidente Epitácio, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6092,
        "urn": "urn:li:region:6092",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "São Bento do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6093,
        "urn": "urn:li:region:6093",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Euclides da Cunha, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6094,
        "urn": "urn:li:region:6094",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Conceição do Coité, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6095,
        "urn": "urn:li:region:6095",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Aquidauana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6096,
        "urn": "urn:li:region:6096",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)"
        ]
    },
    {
        "name": {
            "value": "Carangola, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6097,
        "urn": "urn:li:region:6097",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,ES)",
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Apucarana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6098,
        "urn": "urn:li:region:6098",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Abaetetuba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6099,
        "urn": "urn:li:region:6099",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Pinheiro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6100,
        "urn": "urn:li:region:6100",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Vera Cruz, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6101,
        "urn": "urn:li:region:6101",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Tucuruí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6102,
        "urn": "urn:li:region:6102",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "José Bonifácio, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6103,
        "urn": "urn:li:region:6103",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Campinas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6104,
        "urn": "urn:li:region:6104",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Gurupi, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6105,
        "urn": "urn:li:region:6105",
        "states": [
            "urn:li:state:(urn:li:country:br,TO)",
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Quixadá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6106,
        "urn": "urn:li:region:6106",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Sorocaba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6107,
        "urn": "urn:li:region:6107",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Pirapora, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6108,
        "urn": "urn:li:region:6108",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Boa Esperança, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6109,
        "urn": "urn:li:region:6109",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Santo Estêvão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6110,
        "urn": "urn:li:region:6110",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Imperatriz, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6111,
        "urn": "urn:li:region:6111",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)",
            "urn:li:state:(urn:li:country:br,TO)"
        ]
    },
    {
        "name": {
            "value": "Joinville, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6112,
        "urn": "urn:li:region:6112",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Guaxupé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6113,
        "urn": "urn:li:region:6113",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Maringá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6114,
        "urn": "urn:li:region:6114",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Blumenau, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6115,
        "urn": "urn:li:region:6115",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Marau, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6116,
        "urn": "urn:li:region:6116",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Coari, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6117,
        "urn": "urn:li:region:6117",
        "states": [
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Marília, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6118,
        "urn": "urn:li:region:6118",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Birigui, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6119,
        "urn": "urn:li:region:6119",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Bom Jesus do Itabapoana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6120,
        "urn": "urn:li:region:6120",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Limoeiro do Norte, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6121,
        "urn": "urn:li:region:6121",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)"
        ]
    },
    {
        "name": {
            "value": "Santo Antônio de Pádua, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6122,
        "urn": "urn:li:region:6122",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Santo Antônio de Jesus, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6123,
        "urn": "urn:li:region:6123",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Limoeiro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6124,
        "urn": "urn:li:region:6124",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Ouro Preto, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6125,
        "urn": "urn:li:region:6125",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Santo Antônio da Platina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6126,
        "urn": "urn:li:region:6126",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Iguatu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6127,
        "urn": "urn:li:region:6127",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Vilhena, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6128,
        "urn": "urn:li:region:6128",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)",
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Mariana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6129,
        "urn": "urn:li:region:6129",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "João Pinheiro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6130,
        "urn": "urn:li:region:6130",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Piracicaba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6131,
        "urn": "urn:li:region:6131",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Russas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6132,
        "urn": "urn:li:region:6132",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Balsas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6133,
        "urn": "urn:li:region:6133",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)",
            "urn:li:state:(urn:li:country:br,TO)",
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Várzea da Palma, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6134,
        "urn": "urn:li:region:6134",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "João Monlevade, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6135,
        "urn": "urn:li:region:6135",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Colatina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6136,
        "urn": "urn:li:region:6136",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Estância, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6137,
        "urn": "urn:li:region:6137",
        "states": [
            "urn:li:state:(urn:li:country:br,SE)",
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Botucatu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6138,
        "urn": "urn:li:region:6138",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Guaratinguetá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6139,
        "urn": "urn:li:region:6139",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Icó, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6140,
        "urn": "urn:li:region:6140",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Marechal Cândido Rondon, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6141,
        "urn": "urn:li:region:6141",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,MS)"
        ]
    },
    {
        "name": {
            "value": "Rio Branco, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6142,
        "urn": "urn:li:region:6142",
        "states": [
            "urn:li:state:(urn:li:country:br,AC)",
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Varginha, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6143,
        "urn": "urn:li:region:6143",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Ourinhos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6144,
        "urn": "urn:li:region:6144",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Petrolina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6145,
        "urn": "urn:li:region:6145",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Guarapuava, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6146,
        "urn": "urn:li:region:6146",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Bento Gonçalves, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6147,
        "urn": "urn:li:region:6147",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Pesqueira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6148,
        "urn": "urn:li:region:6148",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Vargem Grande do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6149,
        "urn": "urn:li:region:6149",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Camaçari, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6150,
        "urn": "urn:li:region:6150",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Guarapari, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6151,
        "urn": "urn:li:region:6151",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "Osvaldo Cruz, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6152,
        "urn": "urn:li:region:6152",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Resende, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6153,
        "urn": "urn:li:region:6153",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Montes Claros, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6154,
        "urn": "urn:li:region:6154",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Osório, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6155,
        "urn": "urn:li:region:6155",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Belo Horizonte, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6156,
        "urn": "urn:li:region:6156",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,AL)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Angra dos Reis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6157,
        "urn": "urn:li:region:6157",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Horizonte, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6158,
        "urn": "urn:li:region:6158",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Registro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6159,
        "urn": "urn:li:region:6159",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Coaraci, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6160,
        "urn": "urn:li:region:6160",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Caldas Novas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6161,
        "urn": "urn:li:region:6161",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Sete Lagoas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6162,
        "urn": "urn:li:region:6162",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Palmas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6163,
        "urn": "urn:li:region:6163",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Guanambi, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6164,
        "urn": "urn:li:region:6164",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Valença, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6165,
        "urn": "urn:li:region:6165",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Valença, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6166,
        "urn": "urn:li:region:6166",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Castanhal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6167,
        "urn": "urn:li:region:6167",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Santo Ângelo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6168,
        "urn": "urn:li:region:6168",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Jequié, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6169,
        "urn": "urn:li:region:6169",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "São Carlos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6170,
        "urn": "urn:li:region:6170",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "São Miguel do Guamá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6171,
        "urn": "urn:li:region:6171",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Espírito Santo do Pinhal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6172,
        "urn": "urn:li:region:6172",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Cascavel, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6173,
        "urn": "urn:li:region:6173",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Ribeirão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6174,
        "urn": "urn:li:region:6174",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Andradina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6175,
        "urn": "urn:li:region:6175",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Patos de Minas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6176,
        "urn": "urn:li:region:6176",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Cajati, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6177,
        "urn": "urn:li:region:6177",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Andradas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6178,
        "urn": "urn:li:region:6178",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Erechim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6179,
        "urn": "urn:li:region:6179",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Serrinha, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6180,
        "urn": "urn:li:region:6180",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Pindamonhangaba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6181,
        "urn": "urn:li:region:6181",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Lajedo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6182,
        "urn": "urn:li:region:6182",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Santa Vitória do Palmar, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6183,
        "urn": "urn:li:region:6183",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "São Mateus do Maranhão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6184,
        "urn": "urn:li:region:6184",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Caruaru, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6185,
        "urn": "urn:li:region:6185",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Manhuaçu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6186,
        "urn": "urn:li:region:6186",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "Parintins, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6187,
        "urn": "urn:li:region:6187",
        "states": [
            "urn:li:state:(urn:li:country:br,AM)",
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Oliveira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6188,
        "urn": "urn:li:region:6188",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Jaú, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6189,
        "urn": "urn:li:region:6189",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Tomé Açu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6190,
        "urn": "urn:li:region:6190",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Porto Velho, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6191,
        "urn": "urn:li:region:6191",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)",
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Pimenta Bueno, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6192,
        "urn": "urn:li:region:6192",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)",
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Uruguaiana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6193,
        "urn": "urn:li:region:6193",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Olímpia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6194,
        "urn": "urn:li:region:6194",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Caraguatatuba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6195,
        "urn": "urn:li:region:6195",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Bebedouro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6196,
        "urn": "urn:li:region:6196",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Cianorte, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6197,
        "urn": "urn:li:region:6197",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Morada Nova, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6198,
        "urn": "urn:li:region:6198",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Bauru, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6199,
        "urn": "urn:li:region:6199",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Gravataí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6200,
        "urn": "urn:li:region:6200",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "São Luís, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6201,
        "urn": "urn:li:region:6201",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Uruaçu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6202,
        "urn": "urn:li:region:6202",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Ouro Preto do Oeste, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6203,
        "urn": "urn:li:region:6203",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)"
        ]
    },
    {
        "name": {
            "value": "Santa Rosa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6204,
        "urn": "urn:li:region:6204",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Jataí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6205,
        "urn": "urn:li:region:6205",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Carpina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6206,
        "urn": "urn:li:region:6206",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Serra, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6207,
        "urn": "urn:li:region:6207",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "São Borja, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6208,
        "urn": "urn:li:region:6208",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "União da Vitória, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6209,
        "urn": "urn:li:region:6209",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Santa Rita do Sapucaí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6210,
        "urn": "urn:li:region:6210",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Lago da Pedra, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6211,
        "urn": "urn:li:region:6211",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Unaí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6212,
        "urn": "urn:li:region:6212",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Jaraguá do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6213,
        "urn": "urn:li:region:6213",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Jaraguá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6214,
        "urn": "urn:li:region:6214",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Breves, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6215,
        "urn": "urn:li:region:6215",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Umuarama, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6216,
        "urn": "urn:li:region:6216",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Quirinópolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6217,
        "urn": "urn:li:region:6217",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Timbaúba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6218,
        "urn": "urn:li:region:6218",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Caetité, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6219,
        "urn": "urn:li:region:6219",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Senhor do Bonfim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6220,
        "urn": "urn:li:region:6220",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Brejo Santo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6221,
        "urn": "urn:li:region:6221",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)",
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Gravatá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6222,
        "urn": "urn:li:region:6222",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Jaru, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6223,
        "urn": "urn:li:region:6223",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)"
        ]
    },
    {
        "name": {
            "value": "Granja, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6224,
        "urn": "urn:li:region:6224",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Januária, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6225,
        "urn": "urn:li:region:6225",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Chapecó, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6226,
        "urn": "urn:li:region:6226",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Novo Horizonte, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6227,
        "urn": "urn:li:region:6227",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Bacabal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6228,
        "urn": "urn:li:region:6228",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Novo Hamburgo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6229,
        "urn": "urn:li:region:6229",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Uberlândia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6230,
        "urn": "urn:li:region:6230",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Janaúba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6231,
        "urn": "urn:li:region:6231",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Uberaba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6232,
        "urn": "urn:li:region:6232",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Santana do Livramento, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6233,
        "urn": "urn:li:region:6233",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Tianguá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6234,
        "urn": "urn:li:region:6234",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Guajará Mirim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6235,
        "urn": "urn:li:region:6235",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)"
        ]
    },
    {
        "name": {
            "value": "Boa Vista, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6236,
        "urn": "urn:li:region:6236",
        "states": [
            "urn:li:state:(urn:li:country:br,RR)"
        ]
    },
    {
        "name": {
            "value": "Ubatuba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6237,
        "urn": "urn:li:region:6237",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Cachoeiro de Itapemirim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6238,
        "urn": "urn:li:region:6238",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Jales, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6239,
        "urn": "urn:li:region:6239",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Ubaitaba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6240,
        "urn": "urn:li:region:6240",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Ubá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6241,
        "urn": "urn:li:region:6241",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Nova Viçosa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6242,
        "urn": "urn:li:region:6242",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "Canoinhas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6243,
        "urn": "urn:li:region:6243",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Peruíbe, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6244,
        "urn": "urn:li:region:6244",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Nova Venécia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6245,
        "urn": "urn:li:region:6245",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Barretos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6246,
        "urn": "urn:li:region:6246",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Jaguariaíva, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6247,
        "urn": "urn:li:region:6247",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Campo Belo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6248,
        "urn": "urn:li:region:6248",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Mossoró, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6249,
        "urn": "urn:li:region:6249",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)",
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Teresina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6250,
        "urn": "urn:li:region:6250",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)",
            "urn:li:state:(urn:li:country:br,MA)",
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Guaíra, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6251,
        "urn": "urn:li:region:6251",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Almenara, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6252,
        "urn": "urn:li:region:6252",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Palmas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6253,
        "urn": "urn:li:region:6253",
        "states": [
            "urn:li:state:(urn:li:country:br,TO)"
        ]
    },
    {
        "name": {
            "value": "Jaguaquara, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6254,
        "urn": "urn:li:region:6254",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Cachoeira do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6255,
        "urn": "urn:li:region:6255",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Nova Iguaçu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6256,
        "urn": "urn:li:region:6256",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Machado, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6257,
        "urn": "urn:li:region:6257",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Nova Friburgo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6258,
        "urn": "urn:li:region:6258",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Governador Valadares, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6259,
        "urn": "urn:li:region:6259",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Tupã, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6260,
        "urn": "urn:li:region:6260",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Tauá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6261,
        "urn": "urn:li:region:6261",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Grajaú, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6262,
        "urn": "urn:li:region:6262",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Bragança, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6263,
        "urn": "urn:li:region:6263",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)",
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Humaitá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6264,
        "urn": "urn:li:region:6264",
        "states": [
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Macaé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6265,
        "urn": "urn:li:region:6265",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Alfenas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6266,
        "urn": "urn:li:region:6266",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Cacoal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6267,
        "urn": "urn:li:region:6267",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)"
        ]
    },
    {
        "name": {
            "value": "Paulo Afonso, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6268,
        "urn": "urn:li:region:6268",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Tucano, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6269,
        "urn": "urn:li:region:6269",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Além Paraíba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6270,
        "urn": "urn:li:region:6270",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Goiatuba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6271,
        "urn": "urn:li:region:6271",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Capanema, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6272,
        "urn": "urn:li:region:6272",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Goiânia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6273,
        "urn": "urn:li:region:6273",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Goianésia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6274,
        "urn": "urn:li:region:6274",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Vacaria, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6275,
        "urn": "urn:li:region:6275",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Barreiras, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6276,
        "urn": "urn:li:region:6276",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,TO)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Jacobina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6277,
        "urn": "urn:li:region:6277",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Alegrete, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6278,
        "urn": "urn:li:region:6278",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Dourados, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6279,
        "urn": "urn:li:region:6279",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Penedo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6280,
        "urn": "urn:li:region:6280",
        "states": [
            "urn:li:state:(urn:li:country:br,SE)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Maceió, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6281,
        "urn": "urn:li:region:6281",
        "states": [
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Caçador, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6282,
        "urn": "urn:li:region:6282",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Penápolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6283,
        "urn": "urn:li:region:6283",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Niquelândia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6284,
        "urn": "urn:li:region:6284",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Pelotas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6285,
        "urn": "urn:li:region:6285",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Recife, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6286,
        "urn": "urn:li:region:6286",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,RO)"
        ]
    },
    {
        "name": {
            "value": "Caxias do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6287,
        "urn": "urn:li:region:6287",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Ariquemes, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6288,
        "urn": "urn:li:region:6288",
        "states": [
            "urn:li:state:(urn:li:country:br,RO)"
        ]
    },
    {
        "name": {
            "value": "Rio Pardo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6289,
        "urn": "urn:li:region:6289",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Três Pontas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6290,
        "urn": "urn:li:region:6290",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Cabo Frio, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6291,
        "urn": "urn:li:region:6291",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Santiago, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6292,
        "urn": "urn:li:region:6292",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Três Lagoas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6293,
        "urn": "urn:li:region:6293",
        "states": [
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Santa Maria, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6294,
        "urn": "urn:li:region:6294",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Jaboticabal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6295,
        "urn": "urn:li:region:6295",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Lucas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6296,
        "urn": "urn:li:region:6296",
        "states": [
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Santa Helena de Goiás, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6297,
        "urn": "urn:li:region:6297",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Dom Pedrito, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6298,
        "urn": "urn:li:region:6298",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Arcoverde, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6299,
        "urn": "urn:li:region:6299",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Barra do Garças, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6300,
        "urn": "urn:li:region:6300",
        "states": [
            "urn:li:state:(urn:li:country:br,MT)",
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Capitão Poço, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6301,
        "urn": "urn:li:region:6301",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)",
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Santa Fé do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6302,
        "urn": "urn:li:region:6302",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MS)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Lorena, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6303,
        "urn": "urn:li:region:6303",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Três Corações, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6304,
        "urn": "urn:li:region:6304",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Barra do Bugres, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6305,
        "urn": "urn:li:region:6305",
        "states": [
            "urn:li:state:(urn:li:country:br,MT)"
        ]
    },
    {
        "name": {
            "value": "Piraju, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6306,
        "urn": "urn:li:region:6306",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Araripina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6307,
        "urn": "urn:li:region:6307",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)",
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Nanuque, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6308,
        "urn": "urn:li:region:6308",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "Londrina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6309,
        "urn": "urn:li:region:6309",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Garanhuns, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6310,
        "urn": "urn:li:region:6310",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Delmiro Gouveia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6311,
        "urn": "urn:li:region:6311",
        "states": [
            "urn:li:state:(urn:li:country:br,AL)",
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,SE)"
        ]
    },
    {
        "name": {
            "value": "São Sebastião do Paraíso, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6312,
        "urn": "urn:li:region:6312",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Ituverava, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6313,
        "urn": "urn:li:region:6313",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Manaus, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6314,
        "urn": "urn:li:region:6314",
        "states": [
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Iturama, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6315,
        "urn": "urn:li:region:6315",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Presidente Prudente, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6316,
        "urn": "urn:li:region:6316",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Itumbiara, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6317,
        "urn": "urn:li:region:6317",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Ituiutaba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6318,
        "urn": "urn:li:region:6318",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Garça, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6319,
        "urn": "urn:li:region:6319",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Quixeramobim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6320,
        "urn": "urn:li:region:6320",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Anápolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6321,
        "urn": "urn:li:region:6321",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Lins, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6322,
        "urn": "urn:li:region:6322",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "São Sebastião, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6323,
        "urn": "urn:li:region:6323",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Linhares, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6324,
        "urn": "urn:li:region:6324",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "Catanduva, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6325,
        "urn": "urn:li:region:6325",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Catalão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6326,
        "urn": "urn:li:region:6326",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Cataguases, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6327,
        "urn": "urn:li:region:6327",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Viamão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6328,
        "urn": "urn:li:region:6328",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Castro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6329,
        "urn": "urn:li:region:6329",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Tramandaí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6330,
        "urn": "urn:li:region:6330",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Entre Rios, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6331,
        "urn": "urn:li:region:6331",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Arapiraca, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6332,
        "urn": "urn:li:region:6332",
        "states": [
            "urn:li:state:(urn:li:country:br,AL)",
            "urn:li:state:(urn:li:country:br,SE)"
        ]
    },
    {
        "name": {
            "value": "Itaúna, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6333,
        "urn": "urn:li:region:6333",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Parnaíba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6334,
        "urn": "urn:li:region:6334",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)",
            "urn:li:state:(urn:li:country:br,MA)",
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Divinópolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6335,
        "urn": "urn:li:region:6335",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Muriaé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6336,
        "urn": "urn:li:region:6336",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,RJ)"
        ]
    },
    {
        "name": {
            "value": "Guarabira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6337,
        "urn": "urn:li:region:6337",
        "states": [
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,RN)"
        ]
    },
    {
        "name": {
            "value": "Barbacena, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6338,
        "urn": "urn:li:region:6338",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Itararé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6339,
        "urn": "urn:li:region:6339",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Rio Grande, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6340,
        "urn": "urn:li:region:6340",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Limeira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6341,
        "urn": "urn:li:region:6341",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Aracati, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6342,
        "urn": "urn:li:region:6342",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Itaqui, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6343,
        "urn": "urn:li:region:6343",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Torres, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6344,
        "urn": "urn:li:region:6344",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)",
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Diamantina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6345,
        "urn": "urn:li:region:6345",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Juazeiro do Norte, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6346,
        "urn": "urn:li:region:6346",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Fernandópolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6347,
        "urn": "urn:li:region:6347",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Presidente Venceslau, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6348,
        "urn": "urn:li:region:6348",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Itápolis, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6349,
        "urn": "urn:li:region:6349",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Bandeirantes, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6350,
        "urn": "urn:li:region:6350",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Currais Novos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6351,
        "urn": "urn:li:region:6351",
        "states": [
            "urn:li:state:(urn:li:country:br,RN)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Bom Conselho, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6352,
        "urn": "urn:li:region:6352",
        "states": [
            "urn:li:state:(urn:li:country:br,AL)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Leopoldina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6353,
        "urn": "urn:li:region:6353",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Paragominas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6354,
        "urn": "urn:li:region:6354",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)",
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "São Pedro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6355,
        "urn": "urn:li:region:6355",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Lençóis Paulista, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6356,
        "urn": "urn:li:region:6356",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Canindé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6357,
        "urn": "urn:li:region:6357",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Jaguarão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6358,
        "urn": "urn:li:region:6358",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Itapira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6359,
        "urn": "urn:li:region:6359",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Brumado, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6360,
        "urn": "urn:li:region:6360",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Cascavel, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6361,
        "urn": "urn:li:region:6361",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Rio do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6362,
        "urn": "urn:li:region:6362",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Toledo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6363,
        "urn": "urn:li:region:6363",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Leme, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6364,
        "urn": "urn:li:region:6364",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Pouso Alegre, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6365,
        "urn": "urn:li:region:6365",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Itapeva, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6366,
        "urn": "urn:li:region:6366",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Araras, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6367,
        "urn": "urn:li:region:6367",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "São Paulo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6368,
        "urn": "urn:li:region:6368",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,MA)",
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,PI)",
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Itapetininga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6369,
        "urn": "urn:li:region:6369",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Itapetinga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6370,
        "urn": "urn:li:region:6370",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Itaperuna, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6371,
        "urn": "urn:li:region:6371",
        "states": [
            "urn:li:state:(urn:li:country:br,RJ)",
            "urn:li:state:(urn:li:country:br,ES)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Tobias Barreto, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6372,
        "urn": "urn:li:region:6372",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,SE)"
        ]
    },
    {
        "name": {
            "value": "Lavras, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6373,
        "urn": "urn:li:region:6373",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Carmo do Paranaíba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6374,
        "urn": "urn:li:region:6374",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "João Pessoa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6375,
        "urn": "urn:li:region:6375",
        "states": [
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Manacapuru, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6376,
        "urn": "urn:li:region:6376",
        "states": [
            "urn:li:state:(urn:li:country:br,AM)"
        ]
    },
    {
        "name": {
            "value": "Timóteo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6377,
        "urn": "urn:li:region:6377",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Salvador, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6378,
        "urn": "urn:li:region:6378",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Curvelo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6379,
        "urn": "urn:li:region:6379",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "São Mateus, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6380,
        "urn": "urn:li:region:6380",
        "states": [
            "urn:li:state:(urn:li:country:br,ES)"
        ]
    },
    {
        "name": {
            "value": "Itamaraju, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6381,
        "urn": "urn:li:region:6381",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Morrinhos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6382,
        "urn": "urn:li:region:6382",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Porto Seguro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6383,
        "urn": "urn:li:region:6383",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Rancharia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6384,
        "urn": "urn:li:region:6384",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Itajubá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6385,
        "urn": "urn:li:region:6385",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "São Luiz Gonzaga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6386,
        "urn": "urn:li:region:6386",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Surubim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6387,
        "urn": "urn:li:region:6387",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Caratinga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6388,
        "urn": "urn:li:region:6388",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Itajaí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6389,
        "urn": "urn:li:region:6389",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Palmares, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6390,
        "urn": "urn:li:region:6390",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Presidente Dutra, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6391,
        "urn": "urn:li:region:6391",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Fortaleza, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6392,
        "urn": "urn:li:region:6392",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Vigia, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6393,
        "urn": "urn:li:region:6393",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Brasília, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6394,
        "urn": "urn:li:region:6394",
        "states": [
            "urn:li:state:(urn:li:country:br,DF)",
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Frutal, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6395,
        "urn": "urn:li:region:6395",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Adamantina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6396,
        "urn": "urn:li:region:6396",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Salgueiro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6397,
        "urn": "urn:li:region:6397",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)",
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Curitibanos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6398,
        "urn": "urn:li:region:6398",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Curitiba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6399,
        "urn": "urn:li:region:6399",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "São Lourenço do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6400,
        "urn": "urn:li:region:6400",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Viana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6401,
        "urn": "urn:li:region:6401",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Lajeado, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6402,
        "urn": "urn:li:region:6402",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Bragança Paulista, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6403,
        "urn": "urn:li:region:6403",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "São Lourenço, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6404,
        "urn": "urn:li:region:6404",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Patrocínio, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6405,
        "urn": "urn:li:region:6405",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Campo Maior, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6406,
        "urn": "urn:li:region:6406",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Salinas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6407,
        "urn": "urn:li:region:6407",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "São José dos Campos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6408,
        "urn": "urn:li:region:6408",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz das Palmeiras, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6409,
        "urn": "urn:li:region:6409",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "São José do Rio Preto, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6410,
        "urn": "urn:li:region:6410",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "São José do Rio Pardo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6411,
        "urn": "urn:li:region:6411",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Sousa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6412,
        "urn": "urn:li:region:6412",
        "states": [
            "urn:li:state:(urn:li:country:br,PB)",
            "urn:li:state:(urn:li:country:br,RN)"
        ]
    },
    {
        "name": {
            "value": "Feira de Santana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6413,
        "urn": "urn:li:region:6413",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)",
            "urn:li:state:(urn:li:country:br,AL)"
        ]
    },
    {
        "name": {
            "value": "Crato, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6414,
        "urn": "urn:li:region:6414",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Crateús, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6415,
        "urn": "urn:li:region:6415",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)",
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Cuiabá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6416,
        "urn": "urn:li:region:6416",
        "states": [
            "urn:li:state:(urn:li:country:br,MT)",
            "urn:li:state:(urn:li:country:br,MS)"
        ]
    },
    {
        "name": {
            "value": "Monte Carmelo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6417,
        "urn": "urn:li:region:6417",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Floriano, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6418,
        "urn": "urn:li:region:6418",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)",
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Francisco Beltrão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6419,
        "urn": "urn:li:region:6419",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Itabuna, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6420,
        "urn": "urn:li:region:6420",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Bagé, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6421,
        "urn": "urn:li:region:6421",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Teófilo Otoni, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6422,
        "urn": "urn:li:region:6422",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Itabira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6423,
        "urn": "urn:li:region:6423",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Pato Branco, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6424,
        "urn": "urn:li:region:6424",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)",
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Franca, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6425,
        "urn": "urn:li:region:6425",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Itaberaba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6426,
        "urn": "urn:li:region:6426",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Porto Ferreira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6427,
        "urn": "urn:li:region:6427",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Monte Alto, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6428,
        "urn": "urn:li:region:6428",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Itabaiana, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6429,
        "urn": "urn:li:region:6429",
        "states": [
            "urn:li:state:(urn:li:country:br,SE)"
        ]
    },
    {
        "name": {
            "value": "Lages, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6430,
        "urn": "urn:li:region:6430",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Foz do Iguaçu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6431,
        "urn": "urn:li:region:6431",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Campina Grande, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6432,
        "urn": "urn:li:region:6432",
        "states": [
            "urn:li:state:(urn:li:country:br,PB)"
        ]
    },
    {
        "name": {
            "value": "Telêmaco Borba, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6433,
        "urn": "urn:li:region:6433",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Passos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6434,
        "urn": "urn:li:region:6434",
        "states": [
            "urn:li:state:(urn:li:country:br,MG)",
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Pacajus, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6435,
        "urn": "urn:li:region:6435",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Cruzeiro, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6436,
        "urn": "urn:li:region:6436",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Passo Fundo, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6437,
        "urn": "urn:li:region:6437",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)",
            "urn:li:state:(urn:li:country:br,MA)",
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Cruz das Almas, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6438,
        "urn": "urn:li:region:6438",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Cruz Alta, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6439,
        "urn": "urn:li:region:6439",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Irecê, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6440,
        "urn": "urn:li:region:6440",
        "states": [
            "urn:li:state:(urn:li:country:br,BA)"
        ]
    },
    {
        "name": {
            "value": "Irati, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6441,
        "urn": "urn:li:region:6441",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Taubaté, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6442,
        "urn": "urn:li:region:6442",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Sobral, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6443,
        "urn": "urn:li:region:6443",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Tatuí, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6444,
        "urn": "urn:li:region:6444",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Camocim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6445,
        "urn": "urn:li:region:6445",
        "states": [
            "urn:li:state:(urn:li:country:br,CE)"
        ]
    },
    {
        "name": {
            "value": "Santarém, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6446,
        "urn": "urn:li:region:6446",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Ouricuri, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6447,
        "urn": "urn:li:region:6447",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Marabá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6448,
        "urn": "urn:li:region:6448",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)",
            "urn:li:state:(urn:li:country:br,TO)",
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Cametá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6449,
        "urn": "urn:li:region:6449",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Avaré, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6450,
        "urn": "urn:li:region:6450",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Oriximiná, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6451,
        "urn": "urn:li:region:6451",
        "states": [
            "urn:li:state:(urn:li:country:br,PA)"
        ]
    },
    {
        "name": {
            "value": "Santana do Ipanema, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6452,
        "urn": "urn:li:region:6452",
        "states": [
            "urn:li:state:(urn:li:country:br,AL)",
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "Cristalina, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6453,
        "urn": "urn:li:region:6453",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Rosário do Sul, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6454,
        "urn": "urn:li:region:6454",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Medianeira, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6455,
        "urn": "urn:li:region:6455",
        "states": [
            "urn:li:state:(urn:li:country:br,PR)"
        ]
    },
    {
        "name": {
            "value": "Taquaritinga, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6456,
        "urn": "urn:li:region:6456",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Iporá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6457,
        "urn": "urn:li:region:6457",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)"
        ]
    },
    {
        "name": {
            "value": "Formosa, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6458,
        "urn": "urn:li:region:6458",
        "states": [
            "urn:li:state:(urn:li:country:br,GO)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Coroatá, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6459,
        "urn": "urn:li:region:6459",
        "states": [
            "urn:li:state:(urn:li:country:br,MA)"
        ]
    },
    {
        "name": {
            "value": "Mogi Guaçu, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6460,
        "urn": "urn:li:region:6460",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)",
            "urn:li:state:(urn:li:country:br,MG)"
        ]
    },
    {
        "name": {
            "value": "Moji das Cruzes, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6461,
        "urn": "urn:li:region:6461",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Picos, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6462,
        "urn": "urn:li:region:6462",
        "states": [
            "urn:li:state:(urn:li:country:br,PI)"
        ]
    },
    {
        "name": {
            "value": "Criciúma, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6463,
        "urn": "urn:li:region:6463",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Belo Jardim, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6464,
        "urn": "urn:li:region:6464",
        "states": [
            "urn:li:state:(urn:li:country:br,PE)"
        ]
    },
    {
        "name": {
            "value": "São Joaquim da Barra, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6465,
        "urn": "urn:li:region:6465",
        "states": [
            "urn:li:state:(urn:li:country:br,SP)"
        ]
    },
    {
        "name": {
            "value": "Tubarão, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6466,
        "urn": "urn:li:region:6466",
        "states": [
            "urn:li:state:(urn:li:country:br,SC)"
        ]
    },
    {
        "name": {
            "value": "Porto Alegre, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6467,
        "urn": "urn:li:region:6467",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)",
            "urn:li:state:(urn:li:country:br,RN)"
        ]
    },
    {
        "name": {
            "value": "Taquari, Brazil"
        },
        "country": "urn:li:country:br",
        "id": 6468,
        "urn": "urn:li:region:6468",
        "states": [
            "urn:li:state:(urn:li:country:br,RS)"
        ]
    },
    {
        "name": {
            "value": "Indapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6469,
        "urn": "urn:li:region:6469",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Sanchor, India"
        },
        "country": "urn:li:country:in",
        "id": 6470,
        "urn": "urn:li:region:6470",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Mansa, India"
        },
        "country": "urn:li:country:in",
        "id": 6471,
        "urn": "urn:li:region:6471",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Coimbatore, India"
        },
        "country": "urn:li:country:in",
        "id": 6472,
        "urn": "urn:li:region:6472",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Sundarnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6473,
        "urn": "urn:li:region:6473",
        "states": [
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Gadwal, India"
        },
        "country": "urn:li:country:in",
        "id": 6474,
        "urn": "urn:li:region:6474",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Sundargarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6475,
        "urn": "urn:li:region:6475",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Bhinmal, India"
        },
        "country": "urn:li:country:in",
        "id": 6476,
        "urn": "urn:li:region:6476",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Cochin, India"
        },
        "country": "urn:li:country:in",
        "id": 6477,
        "urn": "urn:li:region:6477",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Kumbakonam, India"
        },
        "country": "urn:li:country:in",
        "id": 6478,
        "urn": "urn:li:region:6478",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Bhind, India"
        },
        "country": "urn:li:country:in",
        "id": 6479,
        "urn": "urn:li:region:6479",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Ashoknagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6480,
        "urn": "urn:li:region:6480",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Ilkal, India"
        },
        "country": "urn:li:country:in",
        "id": 6481,
        "urn": "urn:li:region:6481",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Varanasi, India"
        },
        "country": "urn:li:country:in",
        "id": 6482,
        "urn": "urn:li:region:6482",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Churu, India"
        },
        "country": "urn:li:country:in",
        "id": 6483,
        "urn": "urn:li:region:6483",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Gadarwara, India"
        },
        "country": "urn:li:country:in",
        "id": 6484,
        "urn": "urn:li:region:6484",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Gadag, India"
        },
        "country": "urn:li:country:in",
        "id": 6485,
        "urn": "urn:li:region:6485",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Karimnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6486,
        "urn": "urn:li:region:6486",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Mannarakkat, India"
        },
        "country": "urn:li:country:in",
        "id": 6487,
        "urn": "urn:li:region:6487",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Karimganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6488,
        "urn": "urn:li:region:6488",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Kulti, India"
        },
        "country": "urn:li:country:in",
        "id": 6489,
        "urn": "urn:li:region:6489",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Churachandpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6490,
        "urn": "urn:li:region:6490",
        "states": [
            "urn:li:state:(urn:li:country:in,MN)"
        ]
    },
    {
        "name": {
            "value": "Sanawad, India"
        },
        "country": "urn:li:country:in",
        "id": 6491,
        "urn": "urn:li:region:6491",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bhilwara, India"
        },
        "country": "urn:li:country:in",
        "id": 6492,
        "urn": "urn:li:region:6492",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Suluru, India"
        },
        "country": "urn:li:country:in",
        "id": 6493,
        "urn": "urn:li:region:6493",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Vaniyambadi, India"
        },
        "country": "urn:li:country:in",
        "id": 6494,
        "urn": "urn:li:region:6494",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Mankachar, India"
        },
        "country": "urn:li:country:in",
        "id": 6495,
        "urn": "urn:li:region:6495",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,ML)"
        ]
    },
    {
        "name": {
            "value": "Nawada, India"
        },
        "country": "urn:li:country:in",
        "id": 6496,
        "urn": "urn:li:region:6496",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Forbesganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6497,
        "urn": "urn:li:region:6497",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Bhilai, India"
        },
        "country": "urn:li:country:in",
        "id": 6498,
        "urn": "urn:li:region:6498",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Manjlegaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6499,
        "urn": "urn:li:region:6499",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Etawah, India"
        },
        "country": "urn:li:country:in",
        "id": 6500,
        "urn": "urn:li:region:6500",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Sambalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6501,
        "urn": "urn:li:region:6501",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Bada Barabil, India"
        },
        "country": "urn:li:country:in",
        "id": 6502,
        "urn": "urn:li:region:6502",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Firozpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6503,
        "urn": "urn:li:region:6503",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Firozabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6504,
        "urn": "urn:li:region:6504",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Manjeri, India"
        },
        "country": "urn:li:country:in",
        "id": 6505,
        "urn": "urn:li:region:6505",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Samastipur, India"
        },
        "country": "urn:li:country:in",
        "id": 6506,
        "urn": "urn:li:region:6506",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Kareli, India"
        },
        "country": "urn:li:country:in",
        "id": 6507,
        "urn": "urn:li:region:6507",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Hyderabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6508,
        "urn": "urn:li:region:6508",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Kukshi, India"
        },
        "country": "urn:li:country:in",
        "id": 6509,
        "urn": "urn:li:region:6509",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Huvinabadgalli, India"
        },
        "country": "urn:li:country:in",
        "id": 6510,
        "urn": "urn:li:region:6510",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Valsad, India"
        },
        "country": "urn:li:country:in",
        "id": 6511,
        "urn": "urn:li:region:6511",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,DD)"
        ]
    },
    {
        "name": {
            "value": "Valparai, India"
        },
        "country": "urn:li:country:in",
        "id": 6512,
        "urn": "urn:li:region:6512",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)",
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Navadwip, India"
        },
        "country": "urn:li:country:in",
        "id": 6513,
        "urn": "urn:li:region:6513",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Husainabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6514,
        "urn": "urn:li:region:6514",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Fatehpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6515,
        "urn": "urn:li:region:6515",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Moga, India"
        },
        "country": "urn:li:country:in",
        "id": 6516,
        "urn": "urn:li:region:6516",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Hunsur, India"
        },
        "country": "urn:li:country:in",
        "id": 6517,
        "urn": "urn:li:region:6517",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Salur, India"
        },
        "country": "urn:li:country:in",
        "id": 6518,
        "urn": "urn:li:region:6518",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Kohima, India"
        },
        "country": "urn:li:country:in",
        "id": 6519,
        "urn": "urn:li:region:6519",
        "states": [
            "urn:li:state:(urn:li:country:in,NL)",
            "urn:li:state:(urn:li:country:in,MN)"
        ]
    },
    {
        "name": {
            "value": "Deoria, India"
        },
        "country": "urn:li:country:in",
        "id": 6520,
        "urn": "urn:li:region:6520",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Tiptur, India"
        },
        "country": "urn:li:country:in",
        "id": 6521,
        "urn": "urn:li:region:6521",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Bhawaniganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6522,
        "urn": "urn:li:region:6522",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bhavnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6523,
        "urn": "urn:li:region:6523",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Azamgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6524,
        "urn": "urn:li:region:6524",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Farrukhabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6525,
        "urn": "urn:li:region:6525",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Begusarai, India"
        },
        "country": "urn:li:country:in",
        "id": 6526,
        "urn": "urn:li:region:6526",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Mangrol, India"
        },
        "country": "urn:li:country:in",
        "id": 6527,
        "urn": "urn:li:region:6527",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Karanja, India"
        },
        "country": "urn:li:country:in",
        "id": 6528,
        "urn": "urn:li:region:6528",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Faridabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6529,
        "urn": "urn:li:region:6529",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Seram, India"
        },
        "country": "urn:li:country:in",
        "id": 6530,
        "urn": "urn:li:region:6530",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Bhatpara, India"
        },
        "country": "urn:li:country:in",
        "id": 6531,
        "urn": "urn:li:region:6531",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Salem, India"
        },
        "country": "urn:li:country:in",
        "id": 6532,
        "urn": "urn:li:region:6532",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Hubli, India"
        },
        "country": "urn:li:country:in",
        "id": 6533,
        "urn": "urn:li:region:6533",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Bhatkal, India"
        },
        "country": "urn:li:country:in",
        "id": 6534,
        "urn": "urn:li:region:6534",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Chodavaram, India"
        },
        "country": "urn:li:country:in",
        "id": 6535,
        "urn": "urn:li:region:6535",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Chodavaram, India"
        },
        "country": "urn:li:country:in",
        "id": 6536,
        "urn": "urn:li:region:6536",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Bhatinda, India"
        },
        "country": "urn:li:country:in",
        "id": 6537,
        "urn": "urn:li:region:6537",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Faizabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6538,
        "urn": "urn:li:region:6538",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Vaijapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6539,
        "urn": "urn:li:region:6539",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Karaikkudi, India"
        },
        "country": "urn:li:country:in",
        "id": 6540,
        "urn": "urn:li:region:6540",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Hosur, India"
        },
        "country": "urn:li:country:in",
        "id": 6541,
        "urn": "urn:li:region:6541",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Hospet, India"
        },
        "country": "urn:li:country:in",
        "id": 6542,
        "urn": "urn:li:region:6542",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Hoshiarpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6543,
        "urn": "urn:li:region:6543",
        "states": [
            "urn:li:state:(urn:li:country:in,HP)",
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Hoshangabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6544,
        "urn": "urn:li:region:6544",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Narsipatnam, India"
        },
        "country": "urn:li:country:in",
        "id": 6545,
        "urn": "urn:li:region:6545",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Chittaurgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6546,
        "urn": "urn:li:region:6546",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Narsimhapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6547,
        "urn": "urn:li:region:6547",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Sagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6548,
        "urn": "urn:li:region:6548",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Karad, India"
        },
        "country": "urn:li:country:in",
        "id": 6549,
        "urn": "urn:li:region:6549",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Charkhi Dadri, India"
        },
        "country": "urn:li:country:in",
        "id": 6550,
        "urn": "urn:li:region:6550",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Bharuch, India"
        },
        "country": "urn:li:country:in",
        "id": 6551,
        "urn": "urn:li:region:6551",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Vadodara, India"
        },
        "country": "urn:li:country:in",
        "id": 6552,
        "urn": "urn:li:region:6552",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Mandya, India"
        },
        "country": "urn:li:country:in",
        "id": 6553,
        "urn": "urn:li:region:6553",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Krishnagiri, India"
        },
        "country": "urn:li:country:in",
        "id": 6554,
        "urn": "urn:li:region:6554",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Chitradurga, India"
        },
        "country": "urn:li:country:in",
        "id": 6555,
        "urn": "urn:li:region:6555",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Narnaul, India"
        },
        "country": "urn:li:country:in",
        "id": 6556,
        "urn": "urn:li:region:6556",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Erode, India"
        },
        "country": "urn:li:country:in",
        "id": 6557,
        "urn": "urn:li:region:6557",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Begamganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6558,
        "urn": "urn:li:region:6558",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Mandvi, India"
        },
        "country": "urn:li:country:in",
        "id": 6559,
        "urn": "urn:li:region:6559",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Calicut, India"
        },
        "country": "urn:li:country:in",
        "id": 6560,
        "urn": "urn:li:region:6560",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Srinagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6561,
        "urn": "urn:li:region:6561",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Erattupetta, India"
        },
        "country": "urn:li:country:in",
        "id": 6562,
        "urn": "urn:li:region:6562",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Punasa, India"
        },
        "country": "urn:li:country:in",
        "id": 6563,
        "urn": "urn:li:region:6563",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Mandsaur, India"
        },
        "country": "urn:li:country:in",
        "id": 6564,
        "urn": "urn:li:region:6564",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Sri Madhopur, India"
        },
        "country": "urn:li:country:in",
        "id": 6565,
        "urn": "urn:li:region:6565",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Attur, India"
        },
        "country": "urn:li:country:in",
        "id": 6566,
        "urn": "urn:li:region:6566",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Srikakulam, India"
        },
        "country": "urn:li:country:in",
        "id": 6567,
        "urn": "urn:li:region:6567",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bharatpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6568,
        "urn": "urn:li:region:6568",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Hole Narsipur, India"
        },
        "country": "urn:li:country:in",
        "id": 6569,
        "urn": "urn:li:region:6569",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Kovilpatti, India"
        },
        "country": "urn:li:country:in",
        "id": 6570,
        "urn": "urn:li:region:6570",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Sri Dungargarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6571,
        "urn": "urn:li:region:6571",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Nargund, India"
        },
        "country": "urn:li:country:in",
        "id": 6572,
        "urn": "urn:li:region:6572",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Hojai, India"
        },
        "country": "urn:li:country:in",
        "id": 6573,
        "urn": "urn:li:region:6573",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Mandla, India"
        },
        "country": "urn:li:country:in",
        "id": 6574,
        "urn": "urn:li:region:6574",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Emmiganuru, India"
        },
        "country": "urn:li:country:in",
        "id": 6575,
        "urn": "urn:li:region:6575",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Eluru, India"
        },
        "country": "urn:li:country:in",
        "id": 6576,
        "urn": "urn:li:region:6576",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Aligarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6577,
        "urn": "urn:li:region:6577",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Mandi, India"
        },
        "country": "urn:li:country:in",
        "id": 6578,
        "urn": "urn:li:region:6578",
        "states": [
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Kottayam, India"
        },
        "country": "urn:li:country:in",
        "id": 6579,
        "urn": "urn:li:region:6579",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Atmakur, India"
        },
        "country": "urn:li:country:in",
        "id": 6580,
        "urn": "urn:li:region:6580",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Chirala, India"
        },
        "country": "urn:li:country:in",
        "id": 6581,
        "urn": "urn:li:region:6581",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Narayanpet, India"
        },
        "country": "urn:li:country:in",
        "id": 6582,
        "urn": "urn:li:region:6582",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Athni, India"
        },
        "country": "urn:li:country:in",
        "id": 6583,
        "urn": "urn:li:region:6583",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Bhimavaram, India"
        },
        "country": "urn:li:country:in",
        "id": 6584,
        "urn": "urn:li:region:6584",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Hisar, India"
        },
        "country": "urn:li:country:in",
        "id": 6585,
        "urn": "urn:li:region:6585",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Dhar, India"
        },
        "country": "urn:li:country:in",
        "id": 6586,
        "urn": "urn:li:region:6586",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Nawalgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6587,
        "urn": "urn:li:region:6587",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Hiriyur, India"
        },
        "country": "urn:li:country:in",
        "id": 6588,
        "urn": "urn:li:region:6588",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Chintamani, India"
        },
        "country": "urn:li:country:in",
        "id": 6589,
        "urn": "urn:li:region:6589",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Kottagudem, India"
        },
        "country": "urn:li:country:in",
        "id": 6590,
        "urn": "urn:li:region:6590",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Atarra, India"
        },
        "country": "urn:li:country:in",
        "id": 6591,
        "urn": "urn:li:region:6591",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Sainthia, India"
        },
        "country": "urn:li:country:in",
        "id": 6592,
        "urn": "urn:li:region:6592",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Narasaraopet, India"
        },
        "country": "urn:li:country:in",
        "id": 6593,
        "urn": "urn:li:region:6593",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Kanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6594,
        "urn": "urn:li:region:6594",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Narasapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6595,
        "urn": "urn:li:region:6595",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bhandara, India"
        },
        "country": "urn:li:country:in",
        "id": 6596,
        "urn": "urn:li:region:6596",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Lunglei, India"
        },
        "country": "urn:li:country:in",
        "id": 6597,
        "urn": "urn:li:region:6597",
        "states": [
            "urn:li:state:(urn:li:country:in,MZ)"
        ]
    },
    {
        "name": {
            "value": "Sailu, India"
        },
        "country": "urn:li:country:in",
        "id": 6598,
        "urn": "urn:li:region:6598",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Phaltan, India"
        },
        "country": "urn:li:country:in",
        "id": 6599,
        "urn": "urn:li:region:6599",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Egra, India"
        },
        "country": "urn:li:country:in",
        "id": 6600,
        "urn": "urn:li:region:6600",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Kot Putli, India"
        },
        "country": "urn:li:country:in",
        "id": 6601,
        "urn": "urn:li:region:6601",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Kannauj, India"
        },
        "country": "urn:li:country:in",
        "id": 6602,
        "urn": "urn:li:region:6602",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Kotma, India"
        },
        "country": "urn:li:country:in",
        "id": 6603,
        "urn": "urn:li:region:6603",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Kannangad, India"
        },
        "country": "urn:li:country:in",
        "id": 6604,
        "urn": "urn:li:region:6604",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Sitapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6605,
        "urn": "urn:li:region:6605",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Dungarpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6606,
        "urn": "urn:li:region:6606",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Hingoli, India"
        },
        "country": "urn:li:country:in",
        "id": 6607,
        "urn": "urn:li:region:6607",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Dindigul, India"
        },
        "country": "urn:li:country:in",
        "id": 6608,
        "urn": "urn:li:region:6608",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Hinganghat, India"
        },
        "country": "urn:li:country:in",
        "id": 6609,
        "urn": "urn:li:region:6609",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Hindupur, India"
        },
        "country": "urn:li:country:in",
        "id": 6610,
        "urn": "urn:li:region:6610",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Mancheral, India"
        },
        "country": "urn:li:country:in",
        "id": 6611,
        "urn": "urn:li:region:6611",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Sahibganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6612,
        "urn": "urn:li:region:6612",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Manawar, India"
        },
        "country": "urn:li:country:in",
        "id": 6613,
        "urn": "urn:li:region:6613",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Sahaswan, India"
        },
        "country": "urn:li:country:in",
        "id": 6614,
        "urn": "urn:li:region:6614",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Bangaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6615,
        "urn": "urn:li:region:6615",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Hindaun, India"
        },
        "country": "urn:li:country:in",
        "id": 6616,
        "urn": "urn:li:region:6616",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Saharsa, India"
        },
        "country": "urn:li:country:in",
        "id": 6617,
        "urn": "urn:li:region:6617",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Nanpara, India"
        },
        "country": "urn:li:country:in",
        "id": 6618,
        "urn": "urn:li:region:6618",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Saharanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6619,
        "urn": "urn:li:region:6619",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,UL)",
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Songadh, India"
        },
        "country": "urn:li:country:in",
        "id": 6620,
        "urn": "urn:li:region:6620",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Himatnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6621,
        "urn": "urn:li:region:6621",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ashta, India"
        },
        "country": "urn:li:country:in",
        "id": 6622,
        "urn": "urn:li:region:6622",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "karaikal, India"
        },
        "country": "urn:li:country:in",
        "id": 6623,
        "urn": "urn:li:region:6623",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,PY)"
        ]
    },
    {
        "name": {
            "value": "Bhaisa, India"
        },
        "country": "urn:li:country:in",
        "id": 6624,
        "urn": "urn:li:region:6624",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Kanigiri, India"
        },
        "country": "urn:li:country:in",
        "id": 6625,
        "urn": "urn:li:region:6625",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Kotdwara, India"
        },
        "country": "urn:li:country:in",
        "id": 6626,
        "urn": "urn:li:region:6626",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Nangal, India"
        },
        "country": "urn:li:country:in",
        "id": 6627,
        "urn": "urn:li:region:6627",
        "states": [
            "urn:li:state:(urn:li:country:in,HP)",
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Chilakalurupet, India"
        },
        "country": "urn:li:country:in",
        "id": 6628,
        "urn": "urn:li:region:6628",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Punch, India"
        },
        "country": "urn:li:country:in",
        "id": 6629,
        "urn": "urn:li:region:6629",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Nandyal, India"
        },
        "country": "urn:li:country:in",
        "id": 6630,
        "urn": "urn:li:region:6630",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Upleta, India"
        },
        "country": "urn:li:country:in",
        "id": 6631,
        "urn": "urn:li:region:6631",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Bargarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6632,
        "urn": "urn:li:region:6632",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Asansol, India"
        },
        "country": "urn:li:country:in",
        "id": 6633,
        "urn": "urn:li:region:6633",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Nandurbar, India"
        },
        "country": "urn:li:country:in",
        "id": 6634,
        "urn": "urn:li:region:6634",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Chikmagalur, India"
        },
        "country": "urn:li:country:in",
        "id": 6635,
        "urn": "urn:li:region:6635",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Durgapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6636,
        "urn": "urn:li:region:6636",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Mirzapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6637,
        "urn": "urn:li:region:6637",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Durg, India"
        },
        "country": "urn:li:country:in",
        "id": 6638,
        "urn": "urn:li:region:6638",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Unjha, India"
        },
        "country": "urn:li:country:in",
        "id": 6639,
        "urn": "urn:li:region:6639",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Perambalur, India"
        },
        "country": "urn:li:country:in",
        "id": 6640,
        "urn": "urn:li:region:6640",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Sagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6641,
        "urn": "urn:li:region:6641",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Chikhli, India"
        },
        "country": "urn:li:country:in",
        "id": 6642,
        "urn": "urn:li:region:6642",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Sambhal, India"
        },
        "country": "urn:li:country:in",
        "id": 6643,
        "urn": "urn:li:region:6643",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Kota, India"
        },
        "country": "urn:li:country:in",
        "id": 6644,
        "urn": "urn:li:region:6644",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Aruppukkottai, India"
        },
        "country": "urn:li:country:in",
        "id": 6645,
        "urn": "urn:li:region:6645",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Nandikotkur, India"
        },
        "country": "urn:li:country:in",
        "id": 6646,
        "urn": "urn:li:region:6646",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Sadri, India"
        },
        "country": "urn:li:country:in",
        "id": 6647,
        "urn": "urn:li:region:6647",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Bhagalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6648,
        "urn": "urn:li:region:6648",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Kandukur, India"
        },
        "country": "urn:li:country:in",
        "id": 6649,
        "urn": "urn:li:region:6649",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Chidambaram, India"
        },
        "country": "urn:li:country:in",
        "id": 6650,
        "urn": "urn:li:region:6650",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Tehri, India"
        },
        "country": "urn:li:country:in",
        "id": 6651,
        "urn": "urn:li:region:6651",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Arsikere, India"
        },
        "country": "urn:li:country:in",
        "id": 6652,
        "urn": "urn:li:region:6652",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Sultanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6653,
        "urn": "urn:li:region:6653",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Hazaribag, India"
        },
        "country": "urn:li:country:in",
        "id": 6654,
        "urn": "urn:li:region:6654",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Sardarshahr, India"
        },
        "country": "urn:li:country:in",
        "id": 6655,
        "urn": "urn:li:region:6655",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Una, India"
        },
        "country": "urn:li:country:in",
        "id": 6656,
        "urn": "urn:li:region:6656",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,DD)"
        ]
    },
    {
        "name": {
            "value": "Kosi, India"
        },
        "country": "urn:li:country:in",
        "id": 6657,
        "urn": "urn:li:region:6657",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Un, India"
        },
        "country": "urn:li:country:in",
        "id": 6658,
        "urn": "urn:li:region:6658",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Haveri, India"
        },
        "country": "urn:li:country:in",
        "id": 6659,
        "urn": "urn:li:region:6659",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Kandla, India"
        },
        "country": "urn:li:country:in",
        "id": 6660,
        "urn": "urn:li:region:6660",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Ajmer, India"
        },
        "country": "urn:li:country:in",
        "id": 6661,
        "urn": "urn:li:region:6661",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Malpura, India"
        },
        "country": "urn:li:country:in",
        "id": 6662,
        "urn": "urn:li:region:6662",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Dumraon, India"
        },
        "country": "urn:li:country:in",
        "id": 6663,
        "urn": "urn:li:region:6663",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Bhadrakh, India"
        },
        "country": "urn:li:country:in",
        "id": 6664,
        "urn": "urn:li:region:6664",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Kandi, India"
        },
        "country": "urn:li:country:in",
        "id": 6665,
        "urn": "urn:li:region:6665",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Bhadrachalam, India"
        },
        "country": "urn:li:country:in",
        "id": 6666,
        "urn": "urn:li:region:6666",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Hatta, India"
        },
        "country": "urn:li:country:in",
        "id": 6667,
        "urn": "urn:li:region:6667",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bhadra, India"
        },
        "country": "urn:li:country:in",
        "id": 6668,
        "urn": "urn:li:region:6668",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Mudhol, India"
        },
        "country": "urn:li:country:in",
        "id": 6669,
        "urn": "urn:li:region:6669",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Dumka, India"
        },
        "country": "urn:li:country:in",
        "id": 6670,
        "urn": "urn:li:region:6670",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Sojat, India"
        },
        "country": "urn:li:country:in",
        "id": 6671,
        "urn": "urn:li:region:6671",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Bhawanipatna, India"
        },
        "country": "urn:li:country:in",
        "id": 6672,
        "urn": "urn:li:region:6672",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Ichalkaranji, India"
        },
        "country": "urn:li:country:in",
        "id": 6673,
        "urn": "urn:li:region:6673",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Godda, India"
        },
        "country": "urn:li:country:in",
        "id": 6674,
        "urn": "urn:li:region:6674",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Kanchipuram, India"
        },
        "country": "urn:li:country:in",
        "id": 6675,
        "urn": "urn:li:region:6675",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Chhindwara, India"
        },
        "country": "urn:li:country:in",
        "id": 6676,
        "urn": "urn:li:region:6676",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bhadravati, India"
        },
        "country": "urn:li:country:in",
        "id": 6677,
        "urn": "urn:li:region:6677",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Sabalgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6678,
        "urn": "urn:li:region:6678",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Korba, India"
        },
        "country": "urn:li:country:in",
        "id": 6679,
        "urn": "urn:li:region:6679",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Malkangiri, India"
        },
        "country": "urn:li:country:in",
        "id": 6680,
        "urn": "urn:li:region:6680",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Koratla, India"
        },
        "country": "urn:li:country:in",
        "id": 6681,
        "urn": "urn:li:region:6681",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Yavatmal, India"
        },
        "country": "urn:li:country:in",
        "id": 6682,
        "urn": "urn:li:region:6682",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Muddebihal, India"
        },
        "country": "urn:li:country:in",
        "id": 6683,
        "urn": "urn:li:region:6683",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Koraput, India"
        },
        "country": "urn:li:country:in",
        "id": 6684,
        "urn": "urn:li:region:6684",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Bhabua, India"
        },
        "country": "urn:li:country:in",
        "id": 6685,
        "urn": "urn:li:region:6685",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Rajapalaiyam, India"
        },
        "country": "urn:li:country:in",
        "id": 6686,
        "urn": "urn:li:region:6686",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Payyannur, India"
        },
        "country": "urn:li:country:in",
        "id": 6687,
        "urn": "urn:li:region:6687",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Hassan, India"
        },
        "country": "urn:li:country:in",
        "id": 6688,
        "urn": "urn:li:region:6688",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Chhatarpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6689,
        "urn": "urn:li:region:6689",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Suratgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6690,
        "urn": "urn:li:region:6690",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Umarkot, India"
        },
        "country": "urn:li:country:in",
        "id": 6691,
        "urn": "urn:li:region:6691",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Betul, India"
        },
        "country": "urn:li:country:in",
        "id": 6692,
        "urn": "urn:li:region:6692",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Umaria, India"
        },
        "country": "urn:li:country:in",
        "id": 6693,
        "urn": "urn:li:region:6693",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bettiah, India"
        },
        "country": "urn:li:country:in",
        "id": 6694,
        "urn": "urn:li:region:6694",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Koppal, India"
        },
        "country": "urn:li:country:in",
        "id": 6695,
        "urn": "urn:li:region:6695",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Umarkhed, India"
        },
        "country": "urn:li:country:in",
        "id": 6696,
        "urn": "urn:li:region:6696",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Araria, India"
        },
        "country": "urn:li:country:in",
        "id": 6697,
        "urn": "urn:li:region:6697",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Arantangi, India"
        },
        "country": "urn:li:country:in",
        "id": 6698,
        "urn": "urn:li:region:6698",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Kopargaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6699,
        "urn": "urn:li:region:6699",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Nalgonda, India"
        },
        "country": "urn:li:country:in",
        "id": 6700,
        "urn": "urn:li:region:6700",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Kunigal, India"
        },
        "country": "urn:li:country:in",
        "id": 6701,
        "urn": "urn:li:region:6701",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Arani, India"
        },
        "country": "urn:li:country:in",
        "id": 6702,
        "urn": "urn:li:region:6702",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Siwan, India"
        },
        "country": "urn:li:country:in",
        "id": 6703,
        "urn": "urn:li:region:6703",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Puliyangudi, India"
        },
        "country": "urn:li:country:in",
        "id": 6704,
        "urn": "urn:li:region:6704",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Akot, India"
        },
        "country": "urn:li:country:in",
        "id": 6705,
        "urn": "urn:li:region:6705",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Bareilly, India"
        },
        "country": "urn:li:country:in",
        "id": 6706,
        "urn": "urn:li:region:6706",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Arambagh, India"
        },
        "country": "urn:li:country:in",
        "id": 6707,
        "urn": "urn:li:region:6707",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Abohar, India"
        },
        "country": "urn:li:country:in",
        "id": 6708,
        "urn": "urn:li:region:6708",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Mukher, India"
        },
        "country": "urn:li:country:in",
        "id": 6709,
        "urn": "urn:li:region:6709",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Jodhpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6710,
        "urn": "urn:li:region:6710",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Agar, India"
        },
        "country": "urn:li:country:in",
        "id": 6711,
        "urn": "urn:li:region:6711",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Sivakasi, India"
        },
        "country": "urn:li:country:in",
        "id": 6712,
        "urn": "urn:li:region:6712",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Parli Vaijnath, India"
        },
        "country": "urn:li:country:in",
        "id": 6713,
        "urn": "urn:li:region:6713",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Harpanahalli, India"
        },
        "country": "urn:li:country:in",
        "id": 6714,
        "urn": "urn:li:region:6714",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Rawatsar, India"
        },
        "country": "urn:li:country:in",
        "id": 6715,
        "urn": "urn:li:region:6715",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ara, India"
        },
        "country": "urn:li:country:in",
        "id": 6716,
        "urn": "urn:li:region:6716",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Koch Bihar, India"
        },
        "country": "urn:li:country:in",
        "id": 6717,
        "urn": "urn:li:region:6717",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Ujjain, India"
        },
        "country": "urn:li:country:in",
        "id": 6718,
        "urn": "urn:li:region:6718",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Najibabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6719,
        "urn": "urn:li:region:6719",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Jais, India"
        },
        "country": "urn:li:country:in",
        "id": 6720,
        "urn": "urn:li:region:6720",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Berasia, India"
        },
        "country": "urn:li:country:in",
        "id": 6721,
        "urn": "urn:li:region:6721",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kamareddi, India"
        },
        "country": "urn:li:country:in",
        "id": 6722,
        "urn": "urn:li:region:6722",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Naini Tal, India"
        },
        "country": "urn:li:country:in",
        "id": 6723,
        "urn": "urn:li:region:6723",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Kondagaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6724,
        "urn": "urn:li:region:6724",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Konch, India"
        },
        "country": "urn:li:country:in",
        "id": 6725,
        "urn": "urn:li:region:6725",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kaman, India"
        },
        "country": "urn:li:country:in",
        "id": 6726,
        "urn": "urn:li:region:6726",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Talaja, India"
        },
        "country": "urn:li:country:in",
        "id": 6727,
        "urn": "urn:li:region:6727",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Anupgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6728,
        "urn": "urn:li:region:6728",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Sitamarhi, India"
        },
        "country": "urn:li:country:in",
        "id": 6729,
        "urn": "urn:li:region:6729",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Shujalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6730,
        "urn": "urn:li:region:6730",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Patna, India"
        },
        "country": "urn:li:country:in",
        "id": 6731,
        "urn": "urn:li:region:6731",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Udumalaippettai, India"
        },
        "country": "urn:li:country:in",
        "id": 6732,
        "urn": "urn:li:region:6732",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Shahjahanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6733,
        "urn": "urn:li:region:6733",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Udipi, India"
        },
        "country": "urn:li:country:in",
        "id": 6734,
        "urn": "urn:li:region:6734",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Udhampur, India"
        },
        "country": "urn:li:country:in",
        "id": 6735,
        "urn": "urn:li:region:6735",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Contai, India"
        },
        "country": "urn:li:country:in",
        "id": 6736,
        "urn": "urn:li:region:6736",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Udgir, India"
        },
        "country": "urn:li:country:in",
        "id": 6737,
        "urn": "urn:li:region:6737",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Nahan, India"
        },
        "country": "urn:li:country:in",
        "id": 6738,
        "urn": "urn:li:region:6738",
        "states": [
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Patiala, India"
        },
        "country": "urn:li:country:in",
        "id": 6739,
        "urn": "urn:li:region:6739",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Harihar, India"
        },
        "country": "urn:li:country:in",
        "id": 6740,
        "urn": "urn:li:region:6740",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Makrana, India"
        },
        "country": "urn:li:country:in",
        "id": 6741,
        "urn": "urn:li:region:6741",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Haridwar, India"
        },
        "country": "urn:li:country:in",
        "id": 6742,
        "urn": "urn:li:region:6742",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Nim ka Thana, India"
        },
        "country": "urn:li:country:in",
        "id": 6743,
        "urn": "urn:li:region:6743",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Sindhnur, India"
        },
        "country": "urn:li:country:in",
        "id": 6744,
        "urn": "urn:li:region:6744",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Rohtak, India"
        },
        "country": "urn:li:country:in",
        "id": 6745,
        "urn": "urn:li:region:6745",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Ballari, India"
        },
        "country": "urn:li:country:in",
        "id": 6746,
        "urn": "urn:li:region:6746",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bela, India"
        },
        "country": "urn:li:country:in",
        "id": 6747,
        "urn": "urn:li:region:6747",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Chiplun, India"
        },
        "country": "urn:li:country:in",
        "id": 6748,
        "urn": "urn:li:region:6748",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Udaipur, India"
        },
        "country": "urn:li:country:in",
        "id": 6749,
        "urn": "urn:li:region:6749",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Udaipur, India"
        },
        "country": "urn:li:country:in",
        "id": 6750,
        "urn": "urn:li:region:6750",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Nagpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6751,
        "urn": "urn:li:region:6751",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Fatehpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6752,
        "urn": "urn:li:region:6752",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Sirsilla, India"
        },
        "country": "urn:li:country:in",
        "id": 6753,
        "urn": "urn:li:region:6753",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Pathankot, India"
        },
        "country": "urn:li:country:in",
        "id": 6754,
        "urn": "urn:li:region:6754",
        "states": [
            "urn:li:state:(urn:li:country:in,HP)",
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Pathanamthitta, India"
        },
        "country": "urn:li:country:in",
        "id": 6755,
        "urn": "urn:li:region:6755",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Anjar, India"
        },
        "country": "urn:li:country:in",
        "id": 6756,
        "urn": "urn:li:region:6756",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Sirsi, India"
        },
        "country": "urn:li:country:in",
        "id": 6757,
        "urn": "urn:li:region:6757",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Nautanwa, India"
        },
        "country": "urn:li:country:in",
        "id": 6758,
        "urn": "urn:li:region:6758",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Sirsa, India"
        },
        "country": "urn:li:country:in",
        "id": 6759,
        "urn": "urn:li:region:6759",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Belagavi, India"
        },
        "country": "urn:li:country:in",
        "id": 6760,
        "urn": "urn:li:region:6760",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,GA)"
        ]
    },
    {
        "name": {
            "value": "Nagina, India"
        },
        "country": "urn:li:country:in",
        "id": 6761,
        "urn": "urn:li:region:6761",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Nagercoil, India"
        },
        "country": "urn:li:country:in",
        "id": 6762,
        "urn": "urn:li:region:6762",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Robertsonpet, India"
        },
        "country": "urn:li:country:in",
        "id": 6763,
        "urn": "urn:li:region:6763",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Robertsganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6764,
        "urn": "urn:li:region:6764",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Sironj, India"
        },
        "country": "urn:li:country:in",
        "id": 6765,
        "urn": "urn:li:region:6765",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kolar, India"
        },
        "country": "urn:li:country:in",
        "id": 6766,
        "urn": "urn:li:region:6766",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Karauli, India"
        },
        "country": "urn:li:country:in",
        "id": 6767,
        "urn": "urn:li:region:6767",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Hanumangarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6768,
        "urn": "urn:li:region:6768",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Nagaur, India"
        },
        "country": "urn:li:country:in",
        "id": 6769,
        "urn": "urn:li:region:6769",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Risod, India"
        },
        "country": "urn:li:country:in",
        "id": 6770,
        "urn": "urn:li:region:6770",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Patan, India"
        },
        "country": "urn:li:country:in",
        "id": 6771,
        "urn": "urn:li:region:6771",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Patamundai, India"
        },
        "country": "urn:li:country:in",
        "id": 6772,
        "urn": "urn:li:region:6772",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Nagar Karnul, India"
        },
        "country": "urn:li:country:in",
        "id": 6773,
        "urn": "urn:li:region:6773",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Kallakkurichchi, India"
        },
        "country": "urn:li:country:in",
        "id": 6774,
        "urn": "urn:li:region:6774",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Kokrajhar, India"
        },
        "country": "urn:li:country:in",
        "id": 6775,
        "urn": "urn:li:region:6775",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Balasore, India"
        },
        "country": "urn:li:country:in",
        "id": 6776,
        "urn": "urn:li:region:6776",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Tura, India"
        },
        "country": "urn:li:country:in",
        "id": 6777,
        "urn": "urn:li:region:6777",
        "states": [
            "urn:li:state:(urn:li:country:in,ML)"
        ]
    },
    {
        "name": {
            "value": "Hangal, India"
        },
        "country": "urn:li:country:in",
        "id": 6778,
        "urn": "urn:li:region:6778",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Mainpuri, India"
        },
        "country": "urn:li:country:in",
        "id": 6779,
        "urn": "urn:li:region:6779",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Tuni, India"
        },
        "country": "urn:li:country:in",
        "id": 6780,
        "urn": "urn:li:region:6780",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Sira, India"
        },
        "country": "urn:li:country:in",
        "id": 6781,
        "urn": "urn:li:region:6781",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Malkapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6782,
        "urn": "urn:li:region:6782",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Pasighat, India"
        },
        "country": "urn:li:country:in",
        "id": 6783,
        "urn": "urn:li:region:6783",
        "states": [
            "urn:li:state:(urn:li:country:in,AR)"
        ]
    },
    {
        "name": {
            "value": "Pasan, India"
        },
        "country": "urn:li:country:in",
        "id": 6784,
        "urn": "urn:li:region:6784",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Nagappattinam, India"
        },
        "country": "urn:li:country:in",
        "id": 6785,
        "urn": "urn:li:region:6785",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,PY)"
        ]
    },
    {
        "name": {
            "value": "Mahasamund, India"
        },
        "country": "urn:li:country:in",
        "id": 6786,
        "urn": "urn:li:region:6786",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Hamirpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6787,
        "urn": "urn:li:region:6787",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Chas, India"
        },
        "country": "urn:li:country:in",
        "id": 6788,
        "urn": "urn:li:region:6788",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Vite, India"
        },
        "country": "urn:li:country:in",
        "id": 6789,
        "urn": "urn:li:region:6789",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Parvatipuram, India"
        },
        "country": "urn:li:country:in",
        "id": 6790,
        "urn": "urn:li:region:6790",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Tumsar, India"
        },
        "country": "urn:li:country:in",
        "id": 6791,
        "urn": "urn:li:region:6791",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Disa, India"
        },
        "country": "urn:li:country:in",
        "id": 6792,
        "urn": "urn:li:region:6792",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Rewari, India"
        },
        "country": "urn:li:country:in",
        "id": 6793,
        "urn": "urn:li:region:6793",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Partur, India"
        },
        "country": "urn:li:country:in",
        "id": 6794,
        "urn": "urn:li:region:6794",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Khed Brahma, India"
        },
        "country": "urn:li:country:in",
        "id": 6795,
        "urn": "urn:li:region:6795",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Rewa, India"
        },
        "country": "urn:li:country:in",
        "id": 6796,
        "urn": "urn:li:region:6796",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Tumakuru, India"
        },
        "country": "urn:li:country:in",
        "id": 6797,
        "urn": "urn:li:region:6797",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Anantnag, India"
        },
        "country": "urn:li:country:in",
        "id": 6798,
        "urn": "urn:li:region:6798",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Diphu, India"
        },
        "country": "urn:li:country:in",
        "id": 6799,
        "urn": "urn:li:region:6799",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Anantapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6800,
        "urn": "urn:li:region:6800",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Dinhata, India"
        },
        "country": "urn:li:country:in",
        "id": 6801,
        "urn": "urn:li:region:6801",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Beawar, India"
        },
        "country": "urn:li:country:in",
        "id": 6802,
        "urn": "urn:li:region:6802",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Renukut, India"
        },
        "country": "urn:li:country:in",
        "id": 6803,
        "urn": "urn:li:region:6803",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Anakapalle, India"
        },
        "country": "urn:li:country:in",
        "id": 6804,
        "urn": "urn:li:region:6804",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Haldwani, India"
        },
        "country": "urn:li:country:in",
        "id": 6805,
        "urn": "urn:li:region:6805",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Mahoba, India"
        },
        "country": "urn:li:country:in",
        "id": 6806,
        "urn": "urn:li:region:6806",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Dimapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6807,
        "urn": "urn:li:region:6807",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,NL)"
        ]
    },
    {
        "name": {
            "value": "Sherghati, India"
        },
        "country": "urn:li:country:in",
        "id": 6808,
        "urn": "urn:li:region:6808",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Bayana, India"
        },
        "country": "urn:li:country:in",
        "id": 6809,
        "urn": "urn:li:region:6809",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Bolpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6810,
        "urn": "urn:li:region:6810",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Kodar, India"
        },
        "country": "urn:li:country:in",
        "id": 6811,
        "urn": "urn:li:region:6811",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Mysuru, India"
        },
        "country": "urn:li:country:in",
        "id": 6812,
        "urn": "urn:li:region:6812",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Kishangarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6813,
        "urn": "urn:li:region:6813",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Tuensang, India"
        },
        "country": "urn:li:country:in",
        "id": 6814,
        "urn": "urn:li:region:6814",
        "states": [
            "urn:li:state:(urn:li:country:in,NL)"
        ]
    },
    {
        "name": {
            "value": "Amritsar, India"
        },
        "country": "urn:li:country:in",
        "id": 6815,
        "urn": "urn:li:region:6815",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Umarga, India"
        },
        "country": "urn:li:country:in",
        "id": 6816,
        "urn": "urn:li:region:6816",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Muzaffarpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6817,
        "urn": "urn:li:region:6817",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Amreli, India"
        },
        "country": "urn:li:country:in",
        "id": 6818,
        "urn": "urn:li:region:6818",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Muzaffarnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6819,
        "urn": "urn:li:region:6819",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Amravati, India"
        },
        "country": "urn:li:country:in",
        "id": 6820,
        "urn": "urn:li:region:6820",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Hailakandi, India"
        },
        "country": "urn:li:country:in",
        "id": 6821,
        "urn": "urn:li:region:6821",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Kalamb, India"
        },
        "country": "urn:li:country:in",
        "id": 6822,
        "urn": "urn:li:region:6822",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Banda, India"
        },
        "country": "urn:li:country:in",
        "id": 6823,
        "urn": "urn:li:region:6823",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Rehli, India"
        },
        "country": "urn:li:country:in",
        "id": 6824,
        "urn": "urn:li:region:6824",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Parlakimidi, India"
        },
        "country": "urn:li:country:in",
        "id": 6825,
        "urn": "urn:li:region:6825",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Mahendragarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6826,
        "urn": "urn:li:region:6826",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Tiruppur, India"
        },
        "country": "urn:li:country:in",
        "id": 6827,
        "urn": "urn:li:region:6827",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Jodhpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6828,
        "urn": "urn:li:region:6828",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Haflong, India"
        },
        "country": "urn:li:country:in",
        "id": 6829,
        "urn": "urn:li:region:6829",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Channarayapatna, India"
        },
        "country": "urn:li:country:in",
        "id": 6830,
        "urn": "urn:li:region:6830",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Thiruvananthapuram, India"
        },
        "country": "urn:li:country:in",
        "id": 6831,
        "urn": "urn:li:region:6831",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)",
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Digras, India"
        },
        "country": "urn:li:country:in",
        "id": 6832,
        "urn": "urn:li:region:6832",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Sindgi, India"
        },
        "country": "urn:li:country:in",
        "id": 6833,
        "urn": "urn:li:region:6833",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Mahbubnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6834,
        "urn": "urn:li:region:6834",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Mahbubabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6835,
        "urn": "urn:li:region:6835",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Hadgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6836,
        "urn": "urn:li:region:6836",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Diglur, India"
        },
        "country": "urn:li:country:in",
        "id": 6837,
        "urn": "urn:li:region:6837",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Changanacheri, India"
        },
        "country": "urn:li:country:in",
        "id": 6838,
        "urn": "urn:li:region:6838",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Trichur, India"
        },
        "country": "urn:li:country:in",
        "id": 6839,
        "urn": "urn:li:region:6839",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Shimla, India"
        },
        "country": "urn:li:country:in",
        "id": 6840,
        "urn": "urn:li:region:6840",
        "states": [
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Mushabani, India"
        },
        "country": "urn:li:country:in",
        "id": 6841,
        "urn": "urn:li:region:6841",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Batala, India"
        },
        "country": "urn:li:country:in",
        "id": 6842,
        "urn": "urn:li:region:6842",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Maharajganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6843,
        "urn": "urn:li:region:6843",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Rayadrug, India"
        },
        "country": "urn:li:country:in",
        "id": 6844,
        "urn": "urn:li:region:6844",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Rayachoti, India"
        },
        "country": "urn:li:country:in",
        "id": 6845,
        "urn": "urn:li:region:6845",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Parbhani, India"
        },
        "country": "urn:li:country:in",
        "id": 6846,
        "urn": "urn:li:region:6846",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Raxaul, India"
        },
        "country": "urn:li:country:in",
        "id": 6847,
        "urn": "urn:li:region:6847",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Amla, India"
        },
        "country": "urn:li:country:in",
        "id": 6848,
        "urn": "urn:li:region:6848",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kishanganj, India"
        },
        "country": "urn:li:country:in",
        "id": 6849,
        "urn": "urn:li:region:6849",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Murwara, India"
        },
        "country": "urn:li:country:in",
        "id": 6850,
        "urn": "urn:li:region:6850",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Didwana, India"
        },
        "country": "urn:li:country:in",
        "id": 6851,
        "urn": "urn:li:region:6851",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Simdega, India"
        },
        "country": "urn:li:country:in",
        "id": 6852,
        "urn": "urn:li:region:6852",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Krishnanagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6853,
        "urn": "urn:li:region:6853",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Jaora, India"
        },
        "country": "urn:li:country:in",
        "id": 6854,
        "urn": "urn:li:region:6854",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Dibrugarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6855,
        "urn": "urn:li:region:6855",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,AR)"
        ]
    },
    {
        "name": {
            "value": "Gwalior, India"
        },
        "country": "urn:li:country:in",
        "id": 6856,
        "urn": "urn:li:region:6856",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Sillod, India"
        },
        "country": "urn:li:country:in",
        "id": 6857,
        "urn": "urn:li:region:6857",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Faridkot, India"
        },
        "country": "urn:li:country:in",
        "id": 6858,
        "urn": "urn:li:region:6858",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Parasia, India"
        },
        "country": "urn:li:country:in",
        "id": 6859,
        "urn": "urn:li:region:6859",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kakinada, India"
        },
        "country": "urn:li:country:in",
        "id": 6860,
        "urn": "urn:li:region:6860",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Basmat, India"
        },
        "country": "urn:li:country:in",
        "id": 6861,
        "urn": "urn:li:region:6861",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Kinwat, India"
        },
        "country": "urn:li:country:in",
        "id": 6862,
        "urn": "urn:li:region:6862",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Rawatbhata, India"
        },
        "country": "urn:li:country:in",
        "id": 6863,
        "urn": "urn:li:region:6863",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Gursarai, India"
        },
        "country": "urn:li:country:in",
        "id": 6864,
        "urn": "urn:li:region:6864",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Gorakhpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6865,
        "urn": "urn:li:region:6865",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Silchar, India"
        },
        "country": "urn:li:country:in",
        "id": 6866,
        "urn": "urn:li:region:6866",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,MZ)"
        ]
    },
    {
        "name": {
            "value": "Tonk, India"
        },
        "country": "urn:li:country:in",
        "id": 6867,
        "urn": "urn:li:region:6867",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ratnagiri, India"
        },
        "country": "urn:li:country:in",
        "id": 6868,
        "urn": "urn:li:region:6868",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Paramagudi, India"
        },
        "country": "urn:li:country:in",
        "id": 6869,
        "urn": "urn:li:region:6869",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Ratlam, India"
        },
        "country": "urn:li:country:in",
        "id": 6870,
        "urn": "urn:li:region:6870",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Mahad, India"
        },
        "country": "urn:li:country:in",
        "id": 6871,
        "urn": "urn:li:region:6871",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Ambikapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6872,
        "urn": "urn:li:region:6872",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Basavana Bagevadi, India"
        },
        "country": "urn:li:country:in",
        "id": 6873,
        "urn": "urn:li:region:6873",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Basavakalyan, India"
        },
        "country": "urn:li:country:in",
        "id": 6874,
        "urn": "urn:li:region:6874",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Rath, India"
        },
        "country": "urn:li:country:in",
        "id": 6875,
        "urn": "urn:li:region:6875",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Kaithal, India"
        },
        "country": "urn:li:country:in",
        "id": 6876,
        "urn": "urn:li:region:6876",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Dhule, India"
        },
        "country": "urn:li:country:in",
        "id": 6877,
        "urn": "urn:li:region:6877",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Dhuburi, India"
        },
        "country": "urn:li:country:in",
        "id": 6878,
        "urn": "urn:li:region:6878",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,ML)"
        ]
    },
    {
        "name": {
            "value": "Chandigarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6879,
        "urn": "urn:li:region:6879",
        "states": [
            "urn:li:state:(urn:li:country:in,CH)",
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Auraiya, India"
        },
        "country": "urn:li:country:in",
        "id": 6880,
        "urn": "urn:li:region:6880",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Dhrangadhra, India"
        },
        "country": "urn:li:country:in",
        "id": 6881,
        "urn": "urn:li:region:6881",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Ratangarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6882,
        "urn": "urn:li:region:6882",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Munnar, India"
        },
        "country": "urn:li:country:in",
        "id": 6883,
        "urn": "urn:li:region:6883",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Sikar, India"
        },
        "country": "urn:li:country:in",
        "id": 6884,
        "urn": "urn:li:region:6884",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Basti, India"
        },
        "country": "urn:li:country:in",
        "id": 6885,
        "urn": "urn:li:region:6885",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Madurantakam, India"
        },
        "country": "urn:li:country:in",
        "id": 6886,
        "urn": "urn:li:region:6886",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Madurai, India"
        },
        "country": "urn:li:country:in",
        "id": 6887,
        "urn": "urn:li:region:6887",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Barwani, India"
        },
        "country": "urn:li:country:in",
        "id": 6888,
        "urn": "urn:li:region:6888",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Ambala, India"
        },
        "country": "urn:li:country:in",
        "id": 6889,
        "urn": "urn:li:region:6889",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Guntakal, India"
        },
        "country": "urn:li:country:in",
        "id": 6890,
        "urn": "urn:li:region:6890",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Chennai, India"
        },
        "country": "urn:li:country:in",
        "id": 6891,
        "urn": "urn:li:region:6891",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Dhoraji, India"
        },
        "country": "urn:li:country:in",
        "id": 6892,
        "urn": "urn:li:region:6892",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Munger, India"
        },
        "country": "urn:li:country:in",
        "id": 6893,
        "urn": "urn:li:region:6893",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Mungeli, India"
        },
        "country": "urn:li:country:in",
        "id": 6894,
        "urn": "urn:li:region:6894",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Pavagada, India"
        },
        "country": "urn:li:country:in",
        "id": 6895,
        "urn": "urn:li:region:6895",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Madikeri, India"
        },
        "country": "urn:li:country:in",
        "id": 6896,
        "urn": "urn:li:region:6896",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Baruni, India"
        },
        "country": "urn:li:country:in",
        "id": 6897,
        "urn": "urn:li:region:6897",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Gundlupet, India"
        },
        "country": "urn:li:country:in",
        "id": 6898,
        "urn": "urn:li:region:6898",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Chandrapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6899,
        "urn": "urn:li:region:6899",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Titlagarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6900,
        "urn": "urn:li:region:6900",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Pauri, India"
        },
        "country": "urn:li:country:in",
        "id": 6901,
        "urn": "urn:li:region:6901",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Panna, India"
        },
        "country": "urn:li:country:in",
        "id": 6902,
        "urn": "urn:li:region:6902",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Madhupur, India"
        },
        "country": "urn:li:country:in",
        "id": 6903,
        "urn": "urn:li:region:6903",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Madhubani, India"
        },
        "country": "urn:li:country:in",
        "id": 6904,
        "urn": "urn:li:region:6904",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Sidhi, India"
        },
        "country": "urn:li:country:in",
        "id": 6905,
        "urn": "urn:li:region:6905",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Malegaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6906,
        "urn": "urn:li:region:6906",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Akbarpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6907,
        "urn": "urn:li:region:6907",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Kadur, India"
        },
        "country": "urn:li:country:in",
        "id": 6908,
        "urn": "urn:li:region:6908",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Guna, India"
        },
        "country": "urn:li:country:in",
        "id": 6909,
        "urn": "urn:li:region:6909",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Barsi, India"
        },
        "country": "urn:li:country:in",
        "id": 6910,
        "urn": "urn:li:region:6910",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Siddipet, India"
        },
        "country": "urn:li:country:in",
        "id": 6911,
        "urn": "urn:li:region:6911",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Madhipura, India"
        },
        "country": "urn:li:country:in",
        "id": 6912,
        "urn": "urn:li:region:6912",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Panipat, India"
        },
        "country": "urn:li:country:in",
        "id": 6913,
        "urn": "urn:li:region:6913",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Pratapgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 6914,
        "urn": "urn:li:region:6914",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Daund, India"
        },
        "country": "urn:li:country:in",
        "id": 6915,
        "urn": "urn:li:region:6915",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Tiruvalla, India"
        },
        "country": "urn:li:country:in",
        "id": 6916,
        "urn": "urn:li:region:6916",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Kadiri, India"
        },
        "country": "urn:li:country:in",
        "id": 6917,
        "urn": "urn:li:region:6917",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Gumla, India"
        },
        "country": "urn:li:country:in",
        "id": 6918,
        "urn": "urn:li:region:6918",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Barpeta, India"
        },
        "country": "urn:li:country:in",
        "id": 6919,
        "urn": "urn:li:region:6919",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Sibsagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6920,
        "urn": "urn:li:region:6920",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,NL)"
        ]
    },
    {
        "name": {
            "value": "Chamrajnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6921,
        "urn": "urn:li:region:6921",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Kadi, India"
        },
        "country": "urn:li:country:in",
        "id": 6922,
        "urn": "urn:li:region:6922",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Arangaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6923,
        "urn": "urn:li:region:6923",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Madgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 6924,
        "urn": "urn:li:region:6924",
        "states": [
            "urn:li:state:(urn:li:country:in,GA)"
        ]
    },
    {
        "name": {
            "value": "Nasik, India"
        },
        "country": "urn:li:country:in",
        "id": 6925,
        "urn": "urn:li:region:6925",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Champa, India"
        },
        "country": "urn:li:country:in",
        "id": 6926,
        "urn": "urn:li:region:6926",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Amalapuram, India"
        },
        "country": "urn:li:country:in",
        "id": 6927,
        "urn": "urn:li:region:6927",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Tirupati, India"
        },
        "country": "urn:li:country:in",
        "id": 6928,
        "urn": "urn:li:region:6928",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Mangalore, India"
        },
        "country": "urn:li:country:in",
        "id": 6929,
        "urn": "urn:li:region:6929",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Barnala, India"
        },
        "country": "urn:li:country:in",
        "id": 6930,
        "urn": "urn:li:region:6930",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Kalburgi, India"
        },
        "country": "urn:li:country:in",
        "id": 6931,
        "urn": "urn:li:region:6931",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Alwar, India"
        },
        "country": "urn:li:country:in",
        "id": 6932,
        "urn": "urn:li:region:6932",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Kadaiyanallur, India"
        },
        "country": "urn:li:country:in",
        "id": 6933,
        "urn": "urn:li:region:6933",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Barmer, India"
        },
        "country": "urn:li:country:in",
        "id": 6934,
        "urn": "urn:li:region:6934",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ranibennur, India"
        },
        "country": "urn:li:country:in",
        "id": 6935,
        "urn": "urn:li:region:6935",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Gulabpura, India"
        },
        "country": "urn:li:country:in",
        "id": 6936,
        "urn": "urn:li:region:6936",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Madanapalle, India"
        },
        "country": "urn:li:country:in",
        "id": 6937,
        "urn": "urn:li:region:6937",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Challakere, India"
        },
        "country": "urn:li:country:in",
        "id": 6938,
        "urn": "urn:li:region:6938",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Shorapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6939,
        "urn": "urn:li:region:6939",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Muktsar, India"
        },
        "country": "urn:li:country:in",
        "id": 6940,
        "urn": "urn:li:region:6940",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Solapur, India"
        },
        "country": "urn:li:country:in",
        "id": 6941,
        "urn": "urn:li:region:6941",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Tiruchchendur, India"
        },
        "country": "urn:li:country:in",
        "id": 6942,
        "urn": "urn:li:region:6942",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Gudur, India"
        },
        "country": "urn:li:country:in",
        "id": 6943,
        "urn": "urn:li:region:6943",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Jangipur, India"
        },
        "country": "urn:li:country:in",
        "id": 6944,
        "urn": "urn:li:region:6944",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Rangia, India"
        },
        "country": "urn:li:country:in",
        "id": 6945,
        "urn": "urn:li:region:6945",
        "states": [
            "urn:li:state:(urn:li:country:in,AR)",
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Machilipatnam, India"
        },
        "country": "urn:li:country:in",
        "id": 6946,
        "urn": "urn:li:region:6946",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Almora, India"
        },
        "country": "urn:li:country:in",
        "id": 6947,
        "urn": "urn:li:region:6947",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Pandhurna, India"
        },
        "country": "urn:li:country:in",
        "id": 6948,
        "urn": "urn:li:region:6948",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Pandharpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6949,
        "urn": "urn:li:region:6949",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Shivpuri, India"
        },
        "country": "urn:li:country:in",
        "id": 6950,
        "urn": "urn:li:region:6950",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Macherla, India"
        },
        "country": "urn:li:country:in",
        "id": 6951,
        "urn": "urn:li:region:6951",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Alleppey, India"
        },
        "country": "urn:li:country:in",
        "id": 6952,
        "urn": "urn:li:region:6952",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Dharmavaram, India"
        },
        "country": "urn:li:country:in",
        "id": 6953,
        "urn": "urn:li:region:6953",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Kakching, India"
        },
        "country": "urn:li:country:in",
        "id": 6954,
        "urn": "urn:li:region:6954",
        "states": [
            "urn:li:state:(urn:li:country:in,MN)"
        ]
    },
    {
        "name": {
            "value": "Dharmapuri, India"
        },
        "country": "urn:li:country:in",
        "id": 6955,
        "urn": "urn:li:region:6955",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Gudiyattam, India"
        },
        "country": "urn:li:country:in",
        "id": 6956,
        "urn": "urn:li:region:6956",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Gudivada, India"
        },
        "country": "urn:li:country:in",
        "id": 6957,
        "urn": "urn:li:region:6957",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Allahabad, India"
        },
        "country": "urn:li:country:in",
        "id": 6958,
        "urn": "urn:li:region:6958",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Dharmanagar, India"
        },
        "country": "urn:li:country:in",
        "id": 6959,
        "urn": "urn:li:region:6959",
        "states": [
            "urn:li:state:(urn:li:country:in,TR)",
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Bari, India"
        },
        "country": "urn:li:country:in",
        "id": 6960,
        "urn": "urn:li:region:6960",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Junnar, India"
        },
        "country": "urn:li:country:in",
        "id": 6961,
        "urn": "urn:li:region:6961",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Shirpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6962,
        "urn": "urn:li:region:6962",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Warora, India"
        },
        "country": "urn:li:country:in",
        "id": 6963,
        "urn": "urn:li:region:6963",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Ranchi, India"
        },
        "country": "urn:li:country:in",
        "id": 6964,
        "urn": "urn:li:region:6964",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Tinsukia, India"
        },
        "country": "urn:li:country:in",
        "id": 6965,
        "urn": "urn:li:region:6965",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,AR)"
        ]
    },
    {
        "name": {
            "value": "Gudalur, India"
        },
        "country": "urn:li:country:in",
        "id": 6966,
        "urn": "urn:li:region:6966",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Junagadh, India"
        },
        "country": "urn:li:country:in",
        "id": 6967,
        "urn": "urn:li:region:6967",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Jumri Tilaiya, India"
        },
        "country": "urn:li:country:in",
        "id": 6968,
        "urn": "urn:li:region:6968",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Dharapuram, India"
        },
        "country": "urn:li:country:in",
        "id": 6969,
        "urn": "urn:li:region:6969",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Sawai Madhopur, India"
        },
        "country": "urn:li:country:in",
        "id": 6970,
        "urn": "urn:li:region:6970",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Tindivanam, India"
        },
        "country": "urn:li:country:in",
        "id": 6971,
        "urn": "urn:li:region:6971",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Jalandhar, India"
        },
        "country": "urn:li:country:in",
        "id": 6972,
        "urn": "urn:li:region:6972",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,HP)"
        ]
    },
    {
        "name": {
            "value": "Chaibasa, India"
        },
        "country": "urn:li:country:in",
        "id": 6973,
        "urn": "urn:li:region:6973",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Pattukkottai, India"
        },
        "country": "urn:li:country:in",
        "id": 6974,
        "urn": "urn:li:region:6974",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Gopichettipalaiyam, India"
        },
        "country": "urn:li:country:in",
        "id": 6975,
        "urn": "urn:li:region:6975",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Arakkonam, India"
        },
        "country": "urn:li:country:in",
        "id": 6976,
        "urn": "urn:li:region:6976",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Ambur, India"
        },
        "country": "urn:li:country:in",
        "id": 6977,
        "urn": "urn:li:region:6977",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Lunavada, India"
        },
        "country": "urn:li:country:in",
        "id": 6978,
        "urn": "urn:li:region:6978",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Malaut, India"
        },
        "country": "urn:li:country:in",
        "id": 6979,
        "urn": "urn:li:region:6979",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Panaji, India"
        },
        "country": "urn:li:country:in",
        "id": 6980,
        "urn": "urn:li:region:6980",
        "states": [
            "urn:li:state:(urn:li:country:in,GA)"
        ]
    },
    {
        "name": {
            "value": "Shivamoga, India"
        },
        "country": "urn:li:country:in",
        "id": 6981,
        "urn": "urn:li:region:6981",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Lumding, India"
        },
        "country": "urn:li:country:in",
        "id": 6982,
        "urn": "urn:li:region:6982",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Shillong, India"
        },
        "country": "urn:li:country:in",
        "id": 6983,
        "urn": "urn:li:region:6983",
        "states": [
            "urn:li:state:(urn:li:country:in,ML)"
        ]
    },
    {
        "name": {
            "value": "Shiliguri, India"
        },
        "country": "urn:li:country:in",
        "id": 6984,
        "urn": "urn:li:region:6984",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Chhabra, India"
        },
        "country": "urn:li:country:in",
        "id": 6985,
        "urn": "urn:li:region:6985",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Imphal, India"
        },
        "country": "urn:li:country:in",
        "id": 6986,
        "urn": "urn:li:region:6986",
        "states": [
            "urn:li:state:(urn:li:country:in,MN)"
        ]
    },
    {
        "name": {
            "value": "Jorhat, India"
        },
        "country": "urn:li:country:in",
        "id": 6987,
        "urn": "urn:li:region:6987",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,AR)"
        ]
    },
    {
        "name": {
            "value": "Rampur Hat, India"
        },
        "country": "urn:li:country:in",
        "id": 6988,
        "urn": "urn:li:region:6988",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Ludhiana, India"
        },
        "country": "urn:li:country:in",
        "id": 6989,
        "urn": "urn:li:region:6989",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Shikarpur, India"
        },
        "country": "urn:li:country:in",
        "id": 6990,
        "urn": "urn:li:region:6990",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Palwal, India"
        },
        "country": "urn:li:country:in",
        "id": 6991,
        "urn": "urn:li:region:6991",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Luckeesarai, India"
        },
        "country": "urn:li:country:in",
        "id": 6992,
        "urn": "urn:li:region:6992",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Jolarpettai, India"
        },
        "country": "urn:li:country:in",
        "id": 6993,
        "urn": "urn:li:region:6993",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Padrauna, India"
        },
        "country": "urn:li:country:in",
        "id": 6994,
        "urn": "urn:li:region:6994",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Barddhaman, India"
        },
        "country": "urn:li:country:in",
        "id": 6995,
        "urn": "urn:li:region:6995",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Rampur, India"
        },
        "country": "urn:li:country:in",
        "id": 6996,
        "urn": "urn:li:region:6996",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Canning, India"
        },
        "country": "urn:li:country:in",
        "id": 6997,
        "urn": "urn:li:region:6997",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Cannanore, India"
        },
        "country": "urn:li:country:in",
        "id": 6998,
        "urn": "urn:li:region:6998",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Siuri, India"
        },
        "country": "urn:li:country:in",
        "id": 6999,
        "urn": "urn:li:region:6999",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Motihari, India"
        },
        "country": "urn:li:country:in",
        "id": 7000,
        "urn": "urn:li:region:7000",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Jogbani, India"
        },
        "country": "urn:li:country:in",
        "id": 7001,
        "urn": "urn:li:region:7001",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Paloncha, India"
        },
        "country": "urn:li:country:in",
        "id": 7002,
        "urn": "urn:li:region:7002",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Kolkata, India"
        },
        "country": "urn:li:country:in",
        "id": 7003,
        "urn": "urn:li:region:7003",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Palni, India"
        },
        "country": "urn:li:country:in",
        "id": 7004,
        "urn": "urn:li:region:7004",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Akola, India"
        },
        "country": "urn:li:country:in",
        "id": 7005,
        "urn": "urn:li:region:7005",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Palmaner, India"
        },
        "country": "urn:li:country:in",
        "id": 7006,
        "urn": "urn:li:region:7006",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Tikamgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7007,
        "urn": "urn:li:region:7007",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Dhandhuka, India"
        },
        "country": "urn:li:country:in",
        "id": 7008,
        "urn": "urn:li:region:7008",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Buxar, India"
        },
        "country": "urn:li:country:in",
        "id": 7009,
        "urn": "urn:li:region:7009",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Ramnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7010,
        "urn": "urn:li:region:7010",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Gorakhpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7011,
        "urn": "urn:li:region:7011",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,PB)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ramnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7012,
        "urn": "urn:li:region:7012",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Sheopur, India"
        },
        "country": "urn:li:country:in",
        "id": 7013,
        "urn": "urn:li:region:7013",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Dhanbad, India"
        },
        "country": "urn:li:country:in",
        "id": 7014,
        "urn": "urn:li:region:7014",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Morsi, India"
        },
        "country": "urn:li:country:in",
        "id": 7015,
        "urn": "urn:li:region:7015",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Sheoganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7016,
        "urn": "urn:li:region:7016",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Aurangabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7017,
        "urn": "urn:li:region:7017",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Baran, India"
        },
        "country": "urn:li:country:in",
        "id": 7018,
        "urn": "urn:li:region:7018",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Baramula, India"
        },
        "country": "urn:li:country:in",
        "id": 7019,
        "urn": "urn:li:region:7019",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Palkonda, India"
        },
        "country": "urn:li:country:in",
        "id": 7020,
        "urn": "urn:li:region:7020",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Aurangabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7021,
        "urn": "urn:li:region:7021",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Jintur, India"
        },
        "country": "urn:li:country:in",
        "id": 7022,
        "urn": "urn:li:region:7022",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Palitana, India"
        },
        "country": "urn:li:country:in",
        "id": 7023,
        "urn": "urn:li:region:7023",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Dhamtari, India"
        },
        "country": "urn:li:country:in",
        "id": 7024,
        "urn": "urn:li:region:7024",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Dhulian, India"
        },
        "country": "urn:li:country:in",
        "id": 7025,
        "urn": "urn:li:region:7025",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Palia Kalan, India"
        },
        "country": "urn:li:country:in",
        "id": 7026,
        "urn": "urn:li:region:7026",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Jind, India"
        },
        "country": "urn:li:country:in",
        "id": 7027,
        "urn": "urn:li:region:7027",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Pali, India"
        },
        "country": "urn:li:country:in",
        "id": 7028,
        "urn": "urn:li:region:7028",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Kashipur, India"
        },
        "country": "urn:li:country:in",
        "id": 7029,
        "urn": "urn:li:region:7029",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Dhamnod, India"
        },
        "country": "urn:li:country:in",
        "id": 7030,
        "urn": "urn:li:region:7030",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Harda, India"
        },
        "country": "urn:li:country:in",
        "id": 7031,
        "urn": "urn:li:region:7031",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Jhunjhunun, India"
        },
        "country": "urn:li:country:in",
        "id": 7032,
        "urn": "urn:li:region:7032",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Gopalganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7033,
        "urn": "urn:li:region:7033",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Morbi, India"
        },
        "country": "urn:li:country:in",
        "id": 7034,
        "urn": "urn:li:region:7034",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Palghat, India"
        },
        "country": "urn:li:country:in",
        "id": 7035,
        "urn": "urn:li:region:7035",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Palghar, India"
        },
        "country": "urn:li:country:in",
        "id": 7036,
        "urn": "urn:li:region:7036",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Burhanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7037,
        "urn": "urn:li:region:7037",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Thiruvarur, India"
        },
        "country": "urn:li:country:in",
        "id": 7038,
        "urn": "urn:li:region:7038",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Shantipur, India"
        },
        "country": "urn:li:country:in",
        "id": 7039,
        "urn": "urn:li:region:7039",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Ramganj Mandi, India"
        },
        "country": "urn:li:country:in",
        "id": 7040,
        "urn": "urn:li:region:7040",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Gangarampur, India"
        },
        "country": "urn:li:country:in",
        "id": 7041,
        "urn": "urn:li:region:7041",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Lohardaga, India"
        },
        "country": "urn:li:country:in",
        "id": 7042,
        "urn": "urn:li:region:7042",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Aizawl, India"
        },
        "country": "urn:li:country:in",
        "id": 7043,
        "urn": "urn:li:region:7043",
        "states": [
            "urn:li:state:(urn:li:country:in,MZ)"
        ]
    },
    {
        "name": {
            "value": "Rameswaram, India"
        },
        "country": "urn:li:country:in",
        "id": 7044,
        "urn": "urn:li:region:7044",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Gondia, India"
        },
        "country": "urn:li:country:in",
        "id": 7045,
        "urn": "urn:li:region:7045",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Moradabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7046,
        "urn": "urn:li:region:7046",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Chakradharpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7047,
        "urn": "urn:li:region:7047",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Gonda, India"
        },
        "country": "urn:li:country:in",
        "id": 7048,
        "urn": "urn:li:region:7048",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Budaun, India"
        },
        "country": "urn:li:country:in",
        "id": 7049,
        "urn": "urn:li:region:7049",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Bundi, India"
        },
        "country": "urn:li:country:in",
        "id": 7050,
        "urn": "urn:li:region:7050",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Palasa, India"
        },
        "country": "urn:li:country:in",
        "id": 7051,
        "urn": "urn:li:region:7051",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Palanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7052,
        "urn": "urn:li:region:7052",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Dewas, India"
        },
        "country": "urn:li:country:in",
        "id": 7053,
        "urn": "urn:li:region:7053",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Buldana, India"
        },
        "country": "urn:li:country:in",
        "id": 7054,
        "urn": "urn:li:region:7054",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Khargon, India"
        },
        "country": "urn:li:country:in",
        "id": 7055,
        "urn": "urn:li:region:7055",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Jharsuguda, India"
        },
        "country": "urn:li:country:in",
        "id": 7056,
        "urn": "urn:li:region:7056",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Ramanathapuram, India"
        },
        "country": "urn:li:country:in",
        "id": 7057,
        "urn": "urn:li:region:7057",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Shajapur, India"
        },
        "country": "urn:li:country:in",
        "id": 7058,
        "urn": "urn:li:region:7058",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Palakollu, India"
        },
        "country": "urn:li:country:in",
        "id": 7059,
        "urn": "urn:li:region:7059",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Thanjavur, India"
        },
        "country": "urn:li:country:in",
        "id": 7060,
        "urn": "urn:li:region:7060",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Lingsugur, India"
        },
        "country": "urn:li:country:in",
        "id": 7061,
        "urn": "urn:li:region:7061",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Ahmadpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7062,
        "urn": "urn:li:region:7062",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Ahmadnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7063,
        "urn": "urn:li:region:7063",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Jhargram, India"
        },
        "country": "urn:li:country:in",
        "id": 7064,
        "urn": "urn:li:region:7064",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Ahmedabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7065,
        "urn": "urn:li:region:7065",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Fazilka, India"
        },
        "country": "urn:li:country:in",
        "id": 7066,
        "urn": "urn:li:region:7066",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Gola Gokarannath, India"
        },
        "country": "urn:li:country:in",
        "id": 7067,
        "urn": "urn:li:region:7067",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Golaghat, India"
        },
        "country": "urn:li:country:in",
        "id": 7068,
        "urn": "urn:li:region:7068",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Jhansi, India"
        },
        "country": "urn:li:country:in",
        "id": 7069,
        "urn": "urn:li:region:7069",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Shahpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7070,
        "urn": "urn:li:region:7070",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Venkatagiri, India"
        },
        "country": "urn:li:country:in",
        "id": 7071,
        "urn": "urn:li:region:7071",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Devarkonda, India"
        },
        "country": "urn:li:country:in",
        "id": 7072,
        "urn": "urn:li:region:7072",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Pakaur, India"
        },
        "country": "urn:li:country:in",
        "id": 7073,
        "urn": "urn:li:region:7073",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Gokak, India"
        },
        "country": "urn:li:country:in",
        "id": 7074,
        "urn": "urn:li:region:7074",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Banswara, India"
        },
        "country": "urn:li:country:in",
        "id": 7075,
        "urn": "urn:li:region:7075",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Shahganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7076,
        "urn": "urn:li:region:7076",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Kharagpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7077,
        "urn": "urn:li:region:7077",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Paithan, India"
        },
        "country": "urn:li:country:in",
        "id": 7078,
        "urn": "urn:li:region:7078",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Shahdol, India"
        },
        "country": "urn:li:country:in",
        "id": 7079,
        "urn": "urn:li:region:7079",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Devakottai, India"
        },
        "country": "urn:li:country:in",
        "id": 7080,
        "urn": "urn:li:region:7080",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Rajula, India"
        },
        "country": "urn:li:country:in",
        "id": 7081,
        "urn": "urn:li:region:7081",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Zunheboto, India"
        },
        "country": "urn:li:country:in",
        "id": 7082,
        "urn": "urn:li:region:7082",
        "states": [
            "urn:li:state:(urn:li:country:in,NL)"
        ]
    },
    {
        "name": {
            "value": "Shahada, India"
        },
        "country": "urn:li:country:in",
        "id": 7083,
        "urn": "urn:li:region:7083",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bansi, India"
        },
        "country": "urn:li:country:in",
        "id": 7084,
        "urn": "urn:li:region:7084",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Tezpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7085,
        "urn": "urn:li:region:7085",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,AR)"
        ]
    },
    {
        "name": {
            "value": "Nagda, India"
        },
        "country": "urn:li:country:in",
        "id": 7086,
        "urn": "urn:li:region:7086",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Brajarajnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7087,
        "urn": "urn:li:region:7087",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Jhabua, India"
        },
        "country": "urn:li:country:in",
        "id": 7088,
        "urn": "urn:li:region:7088",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Brahmapur, India"
        },
        "country": "urn:li:country:in",
        "id": 7089,
        "urn": "urn:li:region:7089",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Rajpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7090,
        "urn": "urn:li:region:7090",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Godhra, India"
        },
        "country": "urn:li:country:in",
        "id": 7091,
        "urn": "urn:li:region:7091",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Guntur, India"
        },
        "country": "urn:li:country:in",
        "id": 7092,
        "urn": "urn:li:region:7092",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Lucknow, India"
        },
        "country": "urn:li:country:in",
        "id": 7093,
        "urn": "urn:li:region:7093",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Raj Nandgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7094,
        "urn": "urn:li:region:7094",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Leh, India"
        },
        "country": "urn:li:country:in",
        "id": 7095,
        "urn": "urn:li:region:7095",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Botad, India"
        },
        "country": "urn:li:country:in",
        "id": 7096,
        "urn": "urn:li:region:7096",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Ambajogai, India"
        },
        "country": "urn:li:country:in",
        "id": 7097,
        "urn": "urn:li:region:7097",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Khandwa, India"
        },
        "country": "urn:li:country:in",
        "id": 7098,
        "urn": "urn:li:region:7098",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Tenkasi, India"
        },
        "country": "urn:li:country:in",
        "id": 7099,
        "urn": "urn:li:region:7099",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Teni, India"
        },
        "country": "urn:li:country:in",
        "id": 7100,
        "urn": "urn:li:region:7100",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Zaidpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7101,
        "urn": "urn:li:region:7101",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Chandausi, India"
        },
        "country": "urn:li:country:in",
        "id": 7102,
        "urn": "urn:li:region:7102",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Rajkot, India"
        },
        "country": "urn:li:country:in",
        "id": 7103,
        "urn": "urn:li:region:7103",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Jaypur, India"
        },
        "country": "urn:li:country:in",
        "id": 7104,
        "urn": "urn:li:region:7104",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Adoni, India"
        },
        "country": "urn:li:country:in",
        "id": 7105,
        "urn": "urn:li:region:7105",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Banka, India"
        },
        "country": "urn:li:country:in",
        "id": 7106,
        "urn": "urn:li:region:7106",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Dhone, India"
        },
        "country": "urn:li:country:in",
        "id": 7107,
        "urn": "urn:li:region:7107",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Modasa, India"
        },
        "country": "urn:li:country:in",
        "id": 7108,
        "urn": "urn:li:region:7108",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Goalpara, India"
        },
        "country": "urn:li:country:in",
        "id": 7109,
        "urn": "urn:li:region:7109",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,ML)"
        ]
    },
    {
        "name": {
            "value": "Adilabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7110,
        "urn": "urn:li:region:7110",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Ponnani, India"
        },
        "country": "urn:li:country:in",
        "id": 7111,
        "urn": "urn:li:region:7111",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Rajgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7112,
        "urn": "urn:li:region:7112",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Phulabani, India"
        },
        "country": "urn:li:country:in",
        "id": 7113,
        "urn": "urn:li:region:7113",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Latur, India"
        },
        "country": "urn:li:country:in",
        "id": 7114,
        "urn": "urn:li:region:7114",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Sihora, India"
        },
        "country": "urn:li:country:in",
        "id": 7115,
        "urn": "urn:li:region:7115",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Seoni, India"
        },
        "country": "urn:li:country:in",
        "id": 7116,
        "urn": "urn:li:region:7116",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Rajgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7117,
        "urn": "urn:li:region:7117",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Tellicherry, India"
        },
        "country": "urn:li:country:in",
        "id": 7118,
        "urn": "urn:li:region:7118",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Khammam, India"
        },
        "country": "urn:li:country:in",
        "id": 7119,
        "urn": "urn:li:region:7119",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Giridih, India"
        },
        "country": "urn:li:country:in",
        "id": 7120,
        "urn": "urn:li:region:7120",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Yellandu, India"
        },
        "country": "urn:li:country:in",
        "id": 7121,
        "urn": "urn:li:region:7121",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Rajauri, India"
        },
        "country": "urn:li:country:in",
        "id": 7122,
        "urn": "urn:li:region:7122",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Khamgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7123,
        "urn": "urn:li:region:7123",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Jaunpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7124,
        "urn": "urn:li:region:7124",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Khambhat, India"
        },
        "country": "urn:li:country:in",
        "id": 7125,
        "urn": "urn:li:region:7125",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Khambhaliya, India"
        },
        "country": "urn:li:country:in",
        "id": 7126,
        "urn": "urn:li:region:7126",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Bengaluru, India"
        },
        "country": "urn:li:country:in",
        "id": 7127,
        "urn": "urn:li:region:7127",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Sendhwa, India"
        },
        "country": "urn:li:country:in",
        "id": 7128,
        "urn": "urn:li:region:7128",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Vyara, India"
        },
        "country": "urn:li:country:in",
        "id": 7129,
        "urn": "urn:li:region:7129",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Achalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7130,
        "urn": "urn:li:region:7130",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Pachora, India"
        },
        "country": "urn:li:country:in",
        "id": 7131,
        "urn": "urn:li:region:7131",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Bulandshahr, India"
        },
        "country": "urn:li:country:in",
        "id": 7132,
        "urn": "urn:li:region:7132",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Abu Road, India"
        },
        "country": "urn:li:country:in",
        "id": 7133,
        "urn": "urn:li:region:7133",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Mirialguda, India"
        },
        "country": "urn:li:country:in",
        "id": 7134,
        "urn": "urn:li:region:7134",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Rajampet, India"
        },
        "country": "urn:li:country:in",
        "id": 7135,
        "urn": "urn:li:region:7135",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Kalyan, India"
        },
        "country": "urn:li:country:in",
        "id": 7136,
        "urn": "urn:li:region:7136",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Giddalur, India"
        },
        "country": "urn:li:country:in",
        "id": 7137,
        "urn": "urn:li:region:7137",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bandipura, India"
        },
        "country": "urn:li:country:in",
        "id": 7138,
        "urn": "urn:li:region:7138",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Kolhapur, India"
        },
        "country": "urn:li:country:in",
        "id": 7139,
        "urn": "urn:li:region:7139",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Rajahmundry, India"
        },
        "country": "urn:li:country:in",
        "id": 7140,
        "urn": "urn:li:region:7140",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bongaigaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7141,
        "urn": "urn:li:region:7141",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Paradip Garh, India"
        },
        "country": "urn:li:country:in",
        "id": 7142,
        "urn": "urn:li:region:7142",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Jasdan, India"
        },
        "country": "urn:li:country:in",
        "id": 7143,
        "urn": "urn:li:region:7143",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Yamunanagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7144,
        "urn": "urn:li:region:7144",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Panruti, India"
        },
        "country": "urn:li:country:in",
        "id": 7145,
        "urn": "urn:li:region:7145",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Kuchaman, India"
        },
        "country": "urn:li:country:in",
        "id": 7146,
        "urn": "urn:li:region:7146",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Chirawa, India"
        },
        "country": "urn:li:country:in",
        "id": 7147,
        "urn": "urn:li:region:7147",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Raisinghnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7148,
        "urn": "urn:li:region:7148",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Tasgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7149,
        "urn": "urn:li:region:7149",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Mumbai, India"
        },
        "country": "urn:li:country:in",
        "id": 7150,
        "urn": "urn:li:region:7150",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "New Delhi, India"
        },
        "country": "urn:li:country:in",
        "id": 7151,
        "urn": "urn:li:region:7151",
        "states": [
            "urn:li:state:(urn:li:country:in,DL)",
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Khajuraho, India"
        },
        "country": "urn:li:country:in",
        "id": 7152,
        "urn": "urn:li:region:7152",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Osmanabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7153,
        "urn": "urn:li:region:7153",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Banda, India"
        },
        "country": "urn:li:country:in",
        "id": 7154,
        "urn": "urn:li:region:7154",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Yadgir, India"
        },
        "country": "urn:li:country:in",
        "id": 7155,
        "urn": "urn:li:region:7155",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Mettur, India"
        },
        "country": "urn:li:country:in",
        "id": 7156,
        "urn": "urn:li:region:7156",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Dehri, India"
        },
        "country": "urn:li:country:in",
        "id": 7157,
        "urn": "urn:li:region:7157",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Dehra Dun, India"
        },
        "country": "urn:li:country:in",
        "id": 7158,
        "urn": "urn:li:region:7158",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)",
            "urn:li:state:(urn:li:country:in,HP)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Bokaro, India"
        },
        "country": "urn:li:country:in",
        "id": 7159,
        "urn": "urn:li:region:7159",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Mokokchung, India"
        },
        "country": "urn:li:country:in",
        "id": 7160,
        "urn": "urn:li:region:7160",
        "states": [
            "urn:li:state:(urn:li:country:in,NL)"
        ]
    },
    {
        "name": {
            "value": "Raipur, India"
        },
        "country": "urn:li:country:in",
        "id": 7161,
        "urn": "urn:li:region:7161",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Wokha, India"
        },
        "country": "urn:li:country:in",
        "id": 7162,
        "urn": "urn:li:region:7162",
        "states": [
            "urn:li:state:(urn:li:country:in,NL)"
        ]
    },
    {
        "name": {
            "value": "Orai, India"
        },
        "country": "urn:li:country:in",
        "id": 7163,
        "urn": "urn:li:region:7163",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Kendraparha, India"
        },
        "country": "urn:li:country:in",
        "id": 7164,
        "urn": "urn:li:region:7164",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Ongole, India"
        },
        "country": "urn:li:country:in",
        "id": 7165,
        "urn": "urn:li:region:7165",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Lalsot, India"
        },
        "country": "urn:li:country:in",
        "id": 7166,
        "urn": "urn:li:region:7166",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Taranagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7167,
        "urn": "urn:li:region:7167",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Raigarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7168,
        "urn": "urn:li:region:7168",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Balrampur, India"
        },
        "country": "urn:li:country:in",
        "id": 7169,
        "urn": "urn:li:region:7169",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Raiganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7170,
        "urn": "urn:li:region:7170",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Bamor Kalan, India"
        },
        "country": "urn:li:country:in",
        "id": 7171,
        "urn": "urn:li:region:7171",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Dhupgari, India"
        },
        "country": "urn:li:country:in",
        "id": 7172,
        "urn": "urn:li:region:7172",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Raichur, India"
        },
        "country": "urn:li:country:in",
        "id": 7173,
        "urn": "urn:li:region:7173",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Lalitpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7174,
        "urn": "urn:li:region:7174",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Thanesar, India"
        },
        "country": "urn:li:country:in",
        "id": 7175,
        "urn": "urn:li:region:7175",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Jangaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7176,
        "urn": "urn:li:region:7176",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Ghazipur, India"
        },
        "country": "urn:li:country:in",
        "id": 7177,
        "urn": "urn:li:region:7177",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Shahpura, India"
        },
        "country": "urn:li:country:in",
        "id": 7178,
        "urn": "urn:li:region:7178",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Bobbili, India"
        },
        "country": "urn:li:country:in",
        "id": 7179,
        "urn": "urn:li:region:7179",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Merta, India"
        },
        "country": "urn:li:country:in",
        "id": 7180,
        "urn": "urn:li:region:7180",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Saundatti, India"
        },
        "country": "urn:li:country:in",
        "id": 7181,
        "urn": "urn:li:region:7181",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Dausa, India"
        },
        "country": "urn:li:country:in",
        "id": 7182,
        "urn": "urn:li:region:7182",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Keshod, India"
        },
        "country": "urn:li:country:in",
        "id": 7183,
        "urn": "urn:li:region:7183",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Satyamangalam, India"
        },
        "country": "urn:li:country:in",
        "id": 7184,
        "urn": "urn:li:region:7184",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Obra, India"
        },
        "country": "urn:li:country:in",
        "id": 7185,
        "urn": "urn:li:region:7185",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kollegal, India"
        },
        "country": "urn:li:country:in",
        "id": 7186,
        "urn": "urn:li:region:7186",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Utraula, India"
        },
        "country": "urn:li:country:in",
        "id": 7187,
        "urn": "urn:li:region:7187",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Wanparti, India"
        },
        "country": "urn:li:country:in",
        "id": 7188,
        "urn": "urn:li:region:7188",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Jamui, India"
        },
        "country": "urn:li:country:in",
        "id": 7189,
        "urn": "urn:li:region:7189",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Washim, India"
        },
        "country": "urn:li:country:in",
        "id": 7190,
        "urn": "urn:li:region:7190",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Daudnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7191,
        "urn": "urn:li:region:7191",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Siruguppa, India"
        },
        "country": "urn:li:country:in",
        "id": 7192,
        "urn": "urn:li:region:7192",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Raghogarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7193,
        "urn": "urn:li:region:7193",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Ghatal, India"
        },
        "country": "urn:li:country:in",
        "id": 7194,
        "urn": "urn:li:region:7194",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Jamshedpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7195,
        "urn": "urn:li:region:7195",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Nuzvid, India"
        },
        "country": "urn:li:country:in",
        "id": 7196,
        "urn": "urn:li:region:7196",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Warud, India"
        },
        "country": "urn:li:country:in",
        "id": 7197,
        "urn": "urn:li:region:7197",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Rae Bareli, India"
        },
        "country": "urn:li:country:in",
        "id": 7198,
        "urn": "urn:li:region:7198",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Radhanpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7199,
        "urn": "urn:li:region:7199",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Satna, India"
        },
        "country": "urn:li:country:in",
        "id": 7200,
        "urn": "urn:li:region:7200",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Balurghat, India"
        },
        "country": "urn:li:country:in",
        "id": 7201,
        "urn": "urn:li:region:7201",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Jamnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7202,
        "urn": "urn:li:region:7202",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Bishnupur, India"
        },
        "country": "urn:li:country:in",
        "id": 7203,
        "urn": "urn:li:region:7203",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Jammu, India"
        },
        "country": "urn:li:country:in",
        "id": 7204,
        "urn": "urn:li:region:7204",
        "states": [
            "urn:li:state:(urn:li:country:in,JK)"
        ]
    },
    {
        "name": {
            "value": "Rabkavi, India"
        },
        "country": "urn:li:country:in",
        "id": 7205,
        "urn": "urn:li:region:7205",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Hardoi, India"
        },
        "country": "urn:li:country:in",
        "id": 7206,
        "urn": "urn:li:region:7206",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Wardha, India"
        },
        "country": "urn:li:country:in",
        "id": 7207,
        "urn": "urn:li:region:7207",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Jamkhandi, India"
        },
        "country": "urn:li:country:in",
        "id": 7208,
        "urn": "urn:li:region:7208",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Quilon, India"
        },
        "country": "urn:li:country:in",
        "id": 7209,
        "urn": "urn:li:region:7209",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Warangal, India"
        },
        "country": "urn:li:country:in",
        "id": 7210,
        "urn": "urn:li:region:7210",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Talcher, India"
        },
        "country": "urn:li:country:in",
        "id": 7211,
        "urn": "urn:li:region:7211",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Wapi, India"
        },
        "country": "urn:li:country:in",
        "id": 7212,
        "urn": "urn:li:region:7212",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,DD)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Balotra, India"
        },
        "country": "urn:li:country:in",
        "id": 7213,
        "urn": "urn:li:region:7213",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Lakhimpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7214,
        "urn": "urn:li:region:7214",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Mehekar, India"
        },
        "country": "urn:li:country:in",
        "id": 7215,
        "urn": "urn:li:region:7215",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Tandur, India"
        },
        "country": "urn:li:country:in",
        "id": 7216,
        "urn": "urn:li:region:7216",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Lakheri, India"
        },
        "country": "urn:li:country:in",
        "id": 7217,
        "urn": "urn:li:region:7217",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Satara, India"
        },
        "country": "urn:li:country:in",
        "id": 7218,
        "urn": "urn:li:region:7218",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Wani, India"
        },
        "country": "urn:li:country:in",
        "id": 7219,
        "urn": "urn:li:region:7219",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Kalpetta, India"
        },
        "country": "urn:li:country:in",
        "id": 7220,
        "urn": "urn:li:region:7220",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Meerut, India"
        },
        "country": "urn:li:country:in",
        "id": 7221,
        "urn": "urn:li:region:7221",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Tanda, India"
        },
        "country": "urn:li:country:in",
        "id": 7222,
        "urn": "urn:li:region:7222",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Birmitrapur, India"
        },
        "country": "urn:li:country:in",
        "id": 7223,
        "urn": "urn:li:region:7223",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Puttur, India"
        },
        "country": "urn:li:country:in",
        "id": 7224,
        "urn": "urn:li:region:7224",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Gevrai, India"
        },
        "country": "urn:li:country:in",
        "id": 7225,
        "urn": "urn:li:region:7225",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Udagamandalam, India"
        },
        "country": "urn:li:country:in",
        "id": 7226,
        "urn": "urn:li:region:7226",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Darjiling, India"
        },
        "country": "urn:li:country:in",
        "id": 7227,
        "urn": "urn:li:region:7227",
        "states": [
            "urn:li:state:(urn:li:country:in,SK)",
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "North Lakhimpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7228,
        "urn": "urn:li:region:7228",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,AR)"
        ]
    },
    {
        "name": {
            "value": "Medak, India"
        },
        "country": "urn:li:country:in",
        "id": 7229,
        "urn": "urn:li:region:7229",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Sangola, India"
        },
        "country": "urn:li:country:in",
        "id": 7230,
        "urn": "urn:li:region:7230",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Kekri, India"
        },
        "country": "urn:li:country:in",
        "id": 7231,
        "urn": "urn:li:region:7231",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Jalpaiguri, India"
        },
        "country": "urn:li:country:in",
        "id": 7232,
        "urn": "urn:li:region:7232",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Gaya, India"
        },
        "country": "urn:li:country:in",
        "id": 7233,
        "urn": "urn:li:region:7233",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Jalor, India"
        },
        "country": "urn:li:country:in",
        "id": 7234,
        "urn": "urn:li:region:7234",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Gokarn, India"
        },
        "country": "urn:li:country:in",
        "id": 7235,
        "urn": "urn:li:region:7235",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Pusad, India"
        },
        "country": "urn:li:country:in",
        "id": 7236,
        "urn": "urn:li:region:7236",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Darbhanga, India"
        },
        "country": "urn:li:country:in",
        "id": 7237,
        "urn": "urn:li:region:7237",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Wai, India"
        },
        "country": "urn:li:country:in",
        "id": 7238,
        "urn": "urn:li:region:7238",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Puruliya, India"
        },
        "country": "urn:li:country:in",
        "id": 7239,
        "urn": "urn:li:region:7239",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Purnia, India"
        },
        "country": "urn:li:country:in",
        "id": 7240,
        "urn": "urn:li:region:7240",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Lahar, India"
        },
        "country": "urn:li:country:in",
        "id": 7241,
        "urn": "urn:li:region:7241",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Agra, India"
        },
        "country": "urn:li:country:in",
        "id": 7242,
        "urn": "urn:li:region:7242",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kawardha, India"
        },
        "country": "urn:li:country:in",
        "id": 7243,
        "urn": "urn:li:region:7243",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Nokha, India"
        },
        "country": "urn:li:country:in",
        "id": 7244,
        "urn": "urn:li:region:7244",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Puri, India"
        },
        "country": "urn:li:country:in",
        "id": 7245,
        "urn": "urn:li:region:7245",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Nohar, India"
        },
        "country": "urn:li:country:in",
        "id": 7246,
        "urn": "urn:li:region:7246",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Ladnun, India"
        },
        "country": "urn:li:country:in",
        "id": 7247,
        "urn": "urn:li:region:7247",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Guwahati, India"
        },
        "country": "urn:li:country:in",
        "id": 7248,
        "urn": "urn:li:region:7248",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)",
            "urn:li:state:(urn:li:country:in,ML)"
        ]
    },
    {
        "name": {
            "value": "Puranpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7249,
        "urn": "urn:li:region:7249",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Medinipur, India"
        },
        "country": "urn:li:country:in",
        "id": 7250,
        "urn": "urn:li:region:7250",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Jalalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7251,
        "urn": "urn:li:region:7251",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Bina-Etawa, India"
        },
        "country": "urn:li:country:in",
        "id": 7252,
        "urn": "urn:li:region:7252",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Kavali, India"
        },
        "country": "urn:li:country:in",
        "id": 7253,
        "urn": "urn:li:region:7253",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Mauganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7254,
        "urn": "urn:li:region:7254",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Garwa, India"
        },
        "country": "urn:li:country:in",
        "id": 7255,
        "urn": "urn:li:region:7255",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Maudaha, India"
        },
        "country": "urn:li:country:in",
        "id": 7256,
        "urn": "urn:li:region:7256",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Dhenkanal, India"
        },
        "country": "urn:li:country:in",
        "id": 7257,
        "urn": "urn:li:region:7257",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Nizamabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7258,
        "urn": "urn:li:region:7258",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Balangir, India"
        },
        "country": "urn:li:country:in",
        "id": 7259,
        "urn": "urn:li:region:7259",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Mau, India"
        },
        "country": "urn:li:country:in",
        "id": 7260,
        "urn": "urn:li:region:7260",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Taliparamba, India"
        },
        "country": "urn:li:country:in",
        "id": 7261,
        "urn": "urn:li:region:7261",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Talikota, India"
        },
        "country": "urn:li:country:in",
        "id": 7262,
        "urn": "urn:li:region:7262",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Jhalawar, India"
        },
        "country": "urn:li:country:in",
        "id": 7263,
        "urn": "urn:li:region:7263",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Vriddhachalam, India"
        },
        "country": "urn:li:country:in",
        "id": 7264,
        "urn": "urn:li:region:7264",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Vizianagaram, India"
        },
        "country": "urn:li:country:in",
        "id": 7265,
        "urn": "urn:li:region:7265",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Balaghat, India"
        },
        "country": "urn:li:country:in",
        "id": 7266,
        "urn": "urn:li:region:7266",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Amalner, India"
        },
        "country": "urn:li:country:in",
        "id": 7267,
        "urn": "urn:li:region:7267",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Phalodi, India"
        },
        "country": "urn:li:country:in",
        "id": 7268,
        "urn": "urn:li:region:7268",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Mattanur, India"
        },
        "country": "urn:li:country:in",
        "id": 7269,
        "urn": "urn:li:region:7269",
        "states": [
            "urn:li:state:(urn:li:country:in,KL)"
        ]
    },
    {
        "name": {
            "value": "Jajpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7270,
        "urn": "urn:li:region:7270",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Dandeli, India"
        },
        "country": "urn:li:country:in",
        "id": 7271,
        "urn": "urn:li:region:7271",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Visnagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7272,
        "urn": "urn:li:region:7272",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Nirmal, India"
        },
        "country": "urn:li:country:in",
        "id": 7273,
        "urn": "urn:li:region:7273",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Gariadhar, India"
        },
        "country": "urn:li:country:in",
        "id": 7274,
        "urn": "urn:li:region:7274",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Vishakhapatnam, India"
        },
        "country": "urn:li:country:in",
        "id": 7275,
        "urn": "urn:li:region:7275",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Damoh, India"
        },
        "country": "urn:li:country:in",
        "id": 7276,
        "urn": "urn:li:region:7276",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bilaspur, India"
        },
        "country": "urn:li:country:in",
        "id": 7277,
        "urn": "urn:li:region:7277",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Bilasipara, India"
        },
        "country": "urn:li:country:in",
        "id": 7278,
        "urn": "urn:li:region:7278",
        "states": [
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Bilara, India"
        },
        "country": "urn:li:country:in",
        "id": 7279,
        "urn": "urn:li:region:7279",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Pulivendla, India"
        },
        "country": "urn:li:country:in",
        "id": 7280,
        "urn": "urn:li:region:7280",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Katoya, India"
        },
        "country": "urn:li:country:in",
        "id": 7281,
        "urn": "urn:li:region:7281",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Jaisalmer, India"
        },
        "country": "urn:li:country:in",
        "id": 7282,
        "urn": "urn:li:region:7282",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Mathura, India"
        },
        "country": "urn:li:country:in",
        "id": 7283,
        "urn": "urn:li:region:7283",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Katol, India"
        },
        "country": "urn:li:country:in",
        "id": 7284,
        "urn": "urn:li:region:7284",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Bikramganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7285,
        "urn": "urn:li:region:7285",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Pilibhit, India"
        },
        "country": "urn:li:country:in",
        "id": 7286,
        "urn": "urn:li:region:7286",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Jaipur, India"
        },
        "country": "urn:li:country:in",
        "id": 7287,
        "urn": "urn:li:region:7287",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Rajsamand, India"
        },
        "country": "urn:li:country:in",
        "id": 7288,
        "urn": "urn:li:region:7288",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Bikaner, India"
        },
        "country": "urn:li:country:in",
        "id": 7289,
        "urn": "urn:li:region:7289",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Sirohi, India"
        },
        "country": "urn:li:country:in",
        "id": 7290,
        "urn": "urn:li:region:7290",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Sarangpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7291,
        "urn": "urn:li:region:7291",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Tirunelveli, India"
        },
        "country": "urn:li:country:in",
        "id": 7292,
        "urn": "urn:li:region:7292",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Katihar, India"
        },
        "country": "urn:li:country:in",
        "id": 7293,
        "urn": "urn:li:region:7293",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)",
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Daltenganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7294,
        "urn": "urn:li:region:7294",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Vinukonda, India"
        },
        "country": "urn:li:country:in",
        "id": 7295,
        "urn": "urn:li:region:7295",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bijnor, India"
        },
        "country": "urn:li:country:in",
        "id": 7296,
        "urn": "urn:li:region:7296",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)",
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Pudukkottai, India"
        },
        "country": "urn:li:country:in",
        "id": 7297,
        "urn": "urn:li:region:7297",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Sangamner, India"
        },
        "country": "urn:li:country:in",
        "id": 7298,
        "urn": "urn:li:region:7298",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Villupuram, India"
        },
        "country": "urn:li:country:in",
        "id": 7299,
        "urn": "urn:li:region:7299",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Proddatur, India"
        },
        "country": "urn:li:country:in",
        "id": 7300,
        "urn": "urn:li:region:7300",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Gangapur, India"
        },
        "country": "urn:li:country:in",
        "id": 7301,
        "urn": "urn:li:region:7301",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Bairagnia, India"
        },
        "country": "urn:li:country:in",
        "id": 7302,
        "urn": "urn:li:region:7302",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Vijayapura, India"
        },
        "country": "urn:li:country:in",
        "id": 7303,
        "urn": "urn:li:region:7303",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Angul, India"
        },
        "country": "urn:li:country:in",
        "id": 7304,
        "urn": "urn:li:region:7304",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Nimbahera, India"
        },
        "country": "urn:li:country:in",
        "id": 7305,
        "urn": "urn:li:region:7305",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Jagtial, India"
        },
        "country": "urn:li:country:in",
        "id": 7306,
        "urn": "urn:li:region:7306",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Vikarabad, India"
        },
        "country": "urn:li:country:in",
        "id": 7307,
        "urn": "urn:li:region:7307",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Vijayawada, India"
        },
        "country": "urn:li:country:in",
        "id": 7308,
        "urn": "urn:li:region:7308",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Jaggayyapeta, India"
        },
        "country": "urn:li:country:in",
        "id": 7309,
        "urn": "urn:li:region:7309",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bihar Sharif, India"
        },
        "country": "urn:li:country:in",
        "id": 7310,
        "urn": "urn:li:region:7310",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Jha Jha, India"
        },
        "country": "urn:li:country:in",
        "id": 7311,
        "urn": "urn:li:region:7311",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Jagdalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7312,
        "urn": "urn:li:region:7312",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)",
            "urn:li:state:(urn:li:country:in,CG)"
        ]
    },
    {
        "name": {
            "value": "Mulbagal, India"
        },
        "country": "urn:li:country:in",
        "id": 7313,
        "urn": "urn:li:region:7313",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Gangtok, India"
        },
        "country": "urn:li:country:in",
        "id": 7314,
        "urn": "urn:li:region:7314",
        "states": [
            "urn:li:state:(urn:li:country:in,SK)"
        ]
    },
    {
        "name": {
            "value": "Vidisha, India"
        },
        "country": "urn:li:country:in",
        "id": 7315,
        "urn": "urn:li:region:7315",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Mannargudi, India"
        },
        "country": "urn:li:country:in",
        "id": 7316,
        "urn": "urn:li:region:7316",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Bidhuna, India"
        },
        "country": "urn:li:country:in",
        "id": 7317,
        "urn": "urn:li:region:7317",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Tadpatri, India"
        },
        "country": "urn:li:country:in",
        "id": 7318,
        "urn": "urn:li:region:7318",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Dahod, India"
        },
        "country": "urn:li:country:in",
        "id": 7319,
        "urn": "urn:li:region:7319",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Supaul, India"
        },
        "country": "urn:li:country:in",
        "id": 7320,
        "urn": "urn:li:region:7320",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Bidar, India"
        },
        "country": "urn:li:country:in",
        "id": 7321,
        "urn": "urn:li:region:7321",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Mayiladuthurai, India"
        },
        "country": "urn:li:country:in",
        "id": 7322,
        "urn": "urn:li:region:7322",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,PY)"
        ]
    },
    {
        "name": {
            "value": "Agartala, India"
        },
        "country": "urn:li:country:in",
        "id": 7323,
        "urn": "urn:li:region:7323",
        "states": [
            "urn:li:state:(urn:li:country:in,TR)"
        ]
    },
    {
        "name": {
            "value": "Tadepallegudem, India"
        },
        "country": "urn:li:country:in",
        "id": 7324,
        "urn": "urn:li:region:7324",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Bahraich, India"
        },
        "country": "urn:li:country:in",
        "id": 7325,
        "urn": "urn:li:region:7325",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Dahanu, India"
        },
        "country": "urn:li:country:in",
        "id": 7326,
        "urn": "urn:li:region:7326",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Gangawati, India"
        },
        "country": "urn:li:country:in",
        "id": 7327,
        "urn": "urn:li:region:7327",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Biaora, India"
        },
        "country": "urn:li:country:in",
        "id": 7328,
        "urn": "urn:li:region:7328",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Jalna, India"
        },
        "country": "urn:li:country:in",
        "id": 7329,
        "urn": "urn:li:region:7329",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Veraval, India"
        },
        "country": "urn:li:country:in",
        "id": 7330,
        "urn": "urn:li:region:7330",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)",
            "urn:li:state:(urn:li:country:in,DD)"
        ]
    },
    {
        "name": {
            "value": "Baheri, India"
        },
        "country": "urn:li:country:in",
        "id": 7331,
        "urn": "urn:li:region:7331",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Port Blair, India"
        },
        "country": "urn:li:country:in",
        "id": 7332,
        "urn": "urn:li:region:7332",
        "states": [
            "urn:li:state:(urn:li:country:in,AN)"
        ]
    },
    {
        "name": {
            "value": "Kanker, India"
        },
        "country": "urn:li:country:in",
        "id": 7333,
        "urn": "urn:li:region:7333",
        "states": [
            "urn:li:state:(urn:li:country:in,CG)",
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Baharampur, India"
        },
        "country": "urn:li:country:in",
        "id": 7334,
        "urn": "urn:li:region:7334",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Nongstoin, India"
        },
        "country": "urn:li:country:in",
        "id": 7335,
        "urn": "urn:li:region:7335",
        "states": [
            "urn:li:state:(urn:li:country:in,ML)",
            "urn:li:state:(urn:li:country:in,AS)"
        ]
    },
    {
        "name": {
            "value": "Jabalpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7336,
        "urn": "urn:li:region:7336",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bhusawal, India"
        },
        "country": "urn:li:country:in",
        "id": 7337,
        "urn": "urn:li:region:7337",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Porbandar, India"
        },
        "country": "urn:li:country:in",
        "id": 7338,
        "urn": "urn:li:region:7338",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Kasganj, India"
        },
        "country": "urn:li:country:in",
        "id": 7339,
        "urn": "urn:li:region:7339",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Ganganagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7340,
        "urn": "urn:li:region:7340",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)",
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Marmagao, India"
        },
        "country": "urn:li:country:in",
        "id": 7341,
        "urn": "urn:li:region:7341",
        "states": [
            "urn:li:state:(urn:li:country:in,GA)",
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Kumta, India"
        },
        "country": "urn:li:country:in",
        "id": 7342,
        "urn": "urn:li:region:7342",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Markapur, India"
        },
        "country": "urn:li:country:in",
        "id": 7343,
        "urn": "urn:li:region:7343",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Dabwali, India"
        },
        "country": "urn:li:country:in",
        "id": 7344,
        "urn": "urn:li:region:7344",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Rajpipla, India"
        },
        "country": "urn:li:country:in",
        "id": 7345,
        "urn": "urn:li:region:7345",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Itarsi, India"
        },
        "country": "urn:li:country:in",
        "id": 7346,
        "urn": "urn:li:region:7346",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Itanagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7347,
        "urn": "urn:li:region:7347",
        "states": [
            "urn:li:state:(urn:li:country:in,AR)"
        ]
    },
    {
        "name": {
            "value": "Pithoragarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7348,
        "urn": "urn:li:region:7348",
        "states": [
            "urn:li:state:(urn:li:country:in,UL)"
        ]
    },
    {
        "name": {
            "value": "Puducherry, India"
        },
        "country": "urn:li:country:in",
        "id": 7349,
        "urn": "urn:li:region:7349",
        "states": [
            "urn:li:state:(urn:li:country:in,PY)",
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Pune, India"
        },
        "country": "urn:li:country:in",
        "id": 7350,
        "urn": "urn:li:region:7350",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Bhuj, India"
        },
        "country": "urn:li:country:in",
        "id": 7351,
        "urn": "urn:li:region:7351",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Vellore, India"
        },
        "country": "urn:li:country:in",
        "id": 7352,
        "urn": "urn:li:region:7352",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Marhaura, India"
        },
        "country": "urn:li:country:in",
        "id": 7353,
        "urn": "urn:li:region:7353",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Bhubaneshwar, India"
        },
        "country": "urn:li:country:in",
        "id": 7354,
        "urn": "urn:li:region:7354",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Suriapet, India"
        },
        "country": "urn:li:country:in",
        "id": 7355,
        "urn": "urn:li:region:7355",
        "states": [
            "urn:li:state:(urn:li:country:in,TS)"
        ]
    },
    {
        "name": {
            "value": "Tiruvannamalai, India"
        },
        "country": "urn:li:country:in",
        "id": 7356,
        "urn": "urn:li:region:7356",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Karwar, India"
        },
        "country": "urn:li:country:in",
        "id": 7357,
        "urn": "urn:li:region:7357",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)",
            "urn:li:state:(urn:li:country:in,GA)"
        ]
    },
    {
        "name": {
            "value": "Cuttack, India"
        },
        "country": "urn:li:country:in",
        "id": 7358,
        "urn": "urn:li:region:7358",
        "states": [
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Surendranagar, India"
        },
        "country": "urn:li:country:in",
        "id": 7359,
        "urn": "urn:li:region:7359",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Jetpur, India"
        },
        "country": "urn:li:country:in",
        "id": 7360,
        "urn": "urn:li:region:7360",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Chalisgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7361,
        "urn": "urn:li:region:7361",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Sangrur, India"
        },
        "country": "urn:li:country:in",
        "id": 7362,
        "urn": "urn:li:region:7362",
        "states": [
            "urn:li:state:(urn:li:country:in,PB)"
        ]
    },
    {
        "name": {
            "value": "Jalgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7363,
        "urn": "urn:li:region:7363",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Tiruchchirappalli, India"
        },
        "country": "urn:li:country:in",
        "id": 7364,
        "urn": "urn:li:region:7364",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Karur, India"
        },
        "country": "urn:li:country:in",
        "id": 7365,
        "urn": "urn:li:region:7365",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Bhopal, India"
        },
        "country": "urn:li:country:in",
        "id": 7366,
        "urn": "urn:li:region:7366",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Surat, India"
        },
        "country": "urn:li:country:in",
        "id": 7367,
        "urn": "urn:li:region:7367",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Kundla, India"
        },
        "country": "urn:li:country:in",
        "id": 7368,
        "urn": "urn:li:region:7368",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Cuddapah, India"
        },
        "country": "urn:li:country:in",
        "id": 7369,
        "urn": "urn:li:region:7369",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Dwarka, India"
        },
        "country": "urn:li:country:in",
        "id": 7370,
        "urn": "urn:li:region:7370",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Cuddalore, India"
        },
        "country": "urn:li:country:in",
        "id": 7371,
        "urn": "urn:li:region:7371",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)",
            "urn:li:state:(urn:li:country:in,PY)"
        ]
    },
    {
        "name": {
            "value": "Srivilliputtur, India"
        },
        "country": "urn:li:country:in",
        "id": 7372,
        "urn": "urn:li:region:7372",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Rajgarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7373,
        "urn": "urn:li:region:7373",
        "states": [
            "urn:li:state:(urn:li:country:in,RJ)"
        ]
    },
    {
        "name": {
            "value": "Vedaranniyam, India"
        },
        "country": "urn:li:country:in",
        "id": 7374,
        "urn": "urn:li:region:7374",
        "states": [
            "urn:li:state:(urn:li:country:in,TN)"
        ]
    },
    {
        "name": {
            "value": "Nellore, India"
        },
        "country": "urn:li:country:in",
        "id": 7375,
        "urn": "urn:li:region:7375",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)",
            "urn:li:state:(urn:li:country:in,OR)"
        ]
    },
    {
        "name": {
            "value": "Ingraj Bazar, India"
        },
        "country": "urn:li:country:in",
        "id": 7376,
        "urn": "urn:li:region:7376",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)",
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Mapuca, India"
        },
        "country": "urn:li:country:in",
        "id": 7377,
        "urn": "urn:li:region:7377",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)",
            "urn:li:state:(urn:li:country:in,GA)"
        ]
    },
    {
        "name": {
            "value": "Bankura, India"
        },
        "country": "urn:li:country:in",
        "id": 7378,
        "urn": "urn:li:region:7378",
        "states": [
            "urn:li:state:(urn:li:country:in,WB)"
        ]
    },
    {
        "name": {
            "value": "Bagasra, India"
        },
        "country": "urn:li:country:in",
        "id": 7379,
        "urn": "urn:li:region:7379",
        "states": [
            "urn:li:state:(urn:li:country:in,GJ)"
        ]
    },
    {
        "name": {
            "value": "Coondapoor, India"
        },
        "country": "urn:li:country:in",
        "id": 7380,
        "urn": "urn:li:region:7380",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Gajendragarh, India"
        },
        "country": "urn:li:country:in",
        "id": 7381,
        "urn": "urn:li:region:7381",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Indore, India"
        },
        "country": "urn:li:country:in",
        "id": 7382,
        "urn": "urn:li:region:7382",
        "states": [
            "urn:li:state:(urn:li:country:in,MP)"
        ]
    },
    {
        "name": {
            "value": "Bagalkot, India"
        },
        "country": "urn:li:country:in",
        "id": 7383,
        "urn": "urn:li:region:7383",
        "states": [
            "urn:li:state:(urn:li:country:in,KA)"
        ]
    },
    {
        "name": {
            "value": "Chatra, India"
        },
        "country": "urn:li:country:in",
        "id": 7384,
        "urn": "urn:li:region:7384",
        "states": [
            "urn:li:state:(urn:li:country:in,JH)"
        ]
    },
    {
        "name": {
            "value": "Bagaha, India"
        },
        "country": "urn:li:country:in",
        "id": 7385,
        "urn": "urn:li:region:7385",
        "states": [
            "urn:li:state:(urn:li:country:in,BR)"
        ]
    },
    {
        "name": {
            "value": "Pithapuram, India"
        },
        "country": "urn:li:country:in",
        "id": 7386,
        "urn": "urn:li:region:7386",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Baramati, India"
        },
        "country": "urn:li:country:in",
        "id": 7387,
        "urn": "urn:li:region:7387",
        "states": [
            "urn:li:state:(urn:li:country:in,MH)"
        ]
    },
    {
        "name": {
            "value": "Bhiwani, India"
        },
        "country": "urn:li:country:in",
        "id": 7388,
        "urn": "urn:li:region:7388",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Karnal, India"
        },
        "country": "urn:li:country:in",
        "id": 7389,
        "urn": "urn:li:region:7389",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)",
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Nayudupeta, India"
        },
        "country": "urn:li:country:in",
        "id": 7390,
        "urn": "urn:li:region:7390",
        "states": [
            "urn:li:state:(urn:li:country:in,AP)"
        ]
    },
    {
        "name": {
            "value": "Gurgaon, India"
        },
        "country": "urn:li:country:in",
        "id": 7391,
        "urn": "urn:li:region:7391",
        "states": [
            "urn:li:state:(urn:li:country:in,HR)"
        ]
    },
    {
        "name": {
            "value": "Noida, India"
        },
        "country": "urn:li:country:in",
        "id": 7392,
        "urn": "urn:li:region:7392",
        "states": [
            "urn:li:state:(urn:li:country:in,UP)"
        ]
    },
    {
        "name": {
            "value": "Guimarães, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7393,
        "urn": "urn:li:region:7393",
        "states": [
            "urn:li:state:(urn:li:country:pt,17)",
            "urn:li:state:(urn:li:country:pt,18)",
            "urn:li:state:(urn:li:country:pt,13)",
            "urn:li:state:(urn:li:country:pt,09)",
            "urn:li:state:(urn:li:country:pt,04)",
            "urn:li:state:(urn:li:country:pt,03)"
        ]
    },
    {
        "name": {
            "value": "Faro, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7394,
        "urn": "urn:li:region:7394",
        "states": [
            "urn:li:state:(urn:li:country:pt,08)",
            "urn:li:state:(urn:li:country:pt,02)"
        ]
    },
    {
        "name": {
            "value": "Olhão, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7395,
        "urn": "urn:li:region:7395",
        "states": [
            "urn:li:state:(urn:li:country:pt,08)",
            "urn:li:state:(urn:li:country:pt,02)"
        ]
    },
    {
        "name": {
            "value": "Setúbal, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7396,
        "urn": "urn:li:region:7396",
        "states": [
            "urn:li:state:(urn:li:country:pt,15)",
            "urn:li:state:(urn:li:country:pt,07)",
            "urn:li:state:(urn:li:country:pt,14)"
        ]
    },
    {
        "name": {
            "value": "Porto, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7397,
        "urn": "urn:li:region:7397",
        "states": [
            "urn:li:state:(urn:li:country:pt,13)",
            "urn:li:state:(urn:li:country:pt,01)",
            "urn:li:state:(urn:li:country:pt,03)",
            "urn:li:state:(urn:li:country:pt,18)",
            "urn:li:state:(urn:li:country:pt,16)",
            "urn:li:state:(urn:li:country:pt,45)"
        ]
    },
    {
        "name": {
            "value": "Portimão, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7398,
        "urn": "urn:li:region:7398",
        "states": [
            "urn:li:state:(urn:li:country:pt,08)",
            "urn:li:state:(urn:li:country:pt,02)",
            "urn:li:state:(urn:li:country:pt,15)"
        ]
    },
    {
        "name": {
            "value": "Viseu, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7399,
        "urn": "urn:li:region:7399",
        "states": [
            "urn:li:state:(urn:li:country:pt,18)",
            "urn:li:state:(urn:li:country:pt,06)",
            "urn:li:state:(urn:li:country:pt,09)",
            "urn:li:state:(urn:li:country:pt,01)"
        ]
    },
    {
        "name": {
            "value": "Castelo Branco, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7400,
        "urn": "urn:li:region:7400",
        "states": [
            "urn:li:state:(urn:li:country:pt,05)",
            "urn:li:state:(urn:li:country:pt,12)",
            "urn:li:state:(urn:li:country:pt,14)",
            "urn:li:state:(urn:li:country:pt,09)",
            "urn:li:state:(urn:li:country:pt,06)"
        ]
    },
    {
        "name": {
            "value": "Bragança, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7401,
        "urn": "urn:li:region:7401",
        "states": [
            "urn:li:state:(urn:li:country:pt,04)",
            "urn:li:state:(urn:li:country:pt,17)"
        ]
    },
    {
        "name": {
            "value": "Ponte do Lima, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7402,
        "urn": "urn:li:region:7402",
        "states": [
            "urn:li:state:(urn:li:country:pt,16)"
        ]
    },
    {
        "name": {
            "value": "Marinha Grande, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7403,
        "urn": "urn:li:region:7403",
        "states": [
            "urn:li:state:(urn:li:country:pt,10)"
        ]
    },
    {
        "name": {
            "value": "Braga, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7404,
        "urn": "urn:li:region:7404",
        "states": [
            "urn:li:state:(urn:li:country:pt,03)",
            "urn:li:state:(urn:li:country:pt,13)",
            "urn:li:state:(urn:li:country:pt,16)",
            "urn:li:state:(urn:li:country:pt,17)"
        ]
    },
    {
        "name": {
            "value": "Lisbon, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7405,
        "urn": "urn:li:region:7405",
        "states": [
            "urn:li:state:(urn:li:country:pt,11)",
            "urn:li:state:(urn:li:country:pt,15)",
            "urn:li:state:(urn:li:country:pt,14)"
        ]
    },
    {
        "name": {
            "value": "Aveiro, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7406,
        "urn": "urn:li:region:7406",
        "states": [
            "urn:li:state:(urn:li:country:pt,01)",
            "urn:li:state:(urn:li:country:pt,06)"
        ]
    },
    {
        "name": {
            "value": "Leiria, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7407,
        "urn": "urn:li:region:7407",
        "states": [
            "urn:li:state:(urn:li:country:pt,10)",
            "urn:li:state:(urn:li:country:pt,14)",
            "urn:li:state:(urn:li:country:pt,06)"
        ]
    },
    {
        "name": {
            "value": "Évora, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7408,
        "urn": "urn:li:region:7408",
        "states": [
            "urn:li:state:(urn:li:country:pt,07)",
            "urn:li:state:(urn:li:country:pt,02)",
            "urn:li:state:(urn:li:country:pt,12)",
            "urn:li:state:(urn:li:country:pt,15)",
            "urn:li:state:(urn:li:country:pt,05)"
        ]
    },
    {
        "name": {
            "value": "Monsanto, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7409,
        "urn": "urn:li:region:7409",
        "states": [
            "urn:li:state:(urn:li:country:pt,14)",
            "urn:li:state:(urn:li:country:pt,05)",
            "urn:li:state:(urn:li:country:pt,10)"
        ]
    },
    {
        "name": {
            "value": "Santarém, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7410,
        "urn": "urn:li:region:7410",
        "states": [
            "urn:li:state:(urn:li:country:pt,14)",
            "urn:li:state:(urn:li:country:pt,11)",
            "urn:li:state:(urn:li:country:pt,12)",
            "urn:li:state:(urn:li:country:pt,15)"
        ]
    },
    {
        "name": {
            "value": "Sequeira, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7411,
        "urn": "urn:li:region:7411",
        "states": [
            "urn:li:state:(urn:li:country:pt,09)",
            "urn:li:state:(urn:li:country:pt,05)",
            "urn:li:state:(urn:li:country:pt,18)",
            "urn:li:state:(urn:li:country:pt,06)"
        ]
    },
    {
        "name": {
            "value": "Coimbra, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7412,
        "urn": "urn:li:region:7412",
        "states": [
            "urn:li:state:(urn:li:country:pt,06)",
            "urn:li:state:(urn:li:country:pt,10)",
            "urn:li:state:(urn:li:country:pt,18)",
            "urn:li:state:(urn:li:country:pt,01)",
            "urn:li:state:(urn:li:country:pt,05)"
        ]
    },
    {
        "name": {
            "value": "Caldas da Rainha, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7413,
        "urn": "urn:li:region:7413",
        "states": [
            "urn:li:state:(urn:li:country:pt,11)",
            "urn:li:state:(urn:li:country:pt,10)",
            "urn:li:state:(urn:li:country:pt,14)"
        ]
    },
    {
        "name": {
            "value": "Azores, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7414,
        "urn": "urn:li:region:7414",
        "states": []
    },
    {
        "name": {
            "value": "Madeira, Portugal"
        },
        "country": "urn:li:country:pt",
        "id": 7415,
        "urn": "urn:li:region:7415",
        "states": [
            "urn:li:state:(urn:li:country:pt,31)",
            "urn:li:state:(urn:li:country:pt,16)"
        ]
    },
    {
        "name": {
            "value": "Baltimore, Maryland"
        },
        "country": "urn:li:country:us",
        "id": 7416,
        "urn": "urn:li:region:7416",
        "states": [
            "urn:li:state:(urn:li:country:us,MD)",
            "urn:li:state:(urn:li:country:us,PA)"
        ]
    },
    {
        "name": {
            "value": "Maastricht, Netherlands"
        },
        "country": "urn:li:country:nl",
        "id": 7417,
        "urn": "urn:li:region:7417",
        "states": [
            "urn:li:state:(urn:li:country:nl,05)"
        ]
    },
    {
        "name": {
            "value": "Ulyanovsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7423,
        "urn": "urn:li:region:7423",
        "states": []
    },
    {
        "name": {
            "value": "Chuvash Republic, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7424,
        "urn": "urn:li:region:7424",
        "states": []
    },
    {
        "name": {
            "value": "Orenburg Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7425,
        "urn": "urn:li:region:7425",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Mordovia, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7426,
        "urn": "urn:li:region:7426",
        "states": []
    },
    {
        "name": {
            "value": "Udmurtian Republic, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7427,
        "urn": "urn:li:region:7427",
        "states": []
    },
    {
        "name": {
            "value": "Kirov Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7428,
        "urn": "urn:li:region:7428",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Mari El, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7429,
        "urn": "urn:li:region:7429",
        "states": []
    },
    {
        "name": {
            "value": "Perm Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7430,
        "urn": "urn:li:region:7430",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Tatarstan, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7431,
        "urn": "urn:li:region:7431",
        "states": []
    },
    {
        "name": {
            "value": "Nizhny Novgorod Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7432,
        "urn": "urn:li:region:7432",
        "states": []
    },
    {
        "name": {
            "value": "Samara Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7433,
        "urn": "urn:li:region:7433",
        "states": []
    },
    {
        "name": {
            "value": "Saratov Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7434,
        "urn": "urn:li:region:7434",
        "states": []
    },
    {
        "name": {
            "value": "Penza Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7435,
        "urn": "urn:li:region:7435",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Bashkortostan, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7436,
        "urn": "urn:li:region:7436",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Khakassia, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7437,
        "urn": "urn:li:region:7437",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Buryatia, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7438,
        "urn": "urn:li:region:7438",
        "states": []
    },
    {
        "name": {
            "value": "Altai Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7439,
        "urn": "urn:li:region:7439",
        "states": []
    },
    {
        "name": {
            "value": "Trans-Baikal Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7440,
        "urn": "urn:li:region:7440",
        "states": []
    },
    {
        "name": {
            "value": "Irkutsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7441,
        "urn": "urn:li:region:7441",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Tuva, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7442,
        "urn": "urn:li:region:7442",
        "states": []
    },
    {
        "name": {
            "value": "Tomsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7443,
        "urn": "urn:li:region:7443",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Altai, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7444,
        "urn": "urn:li:region:7444",
        "states": []
    },
    {
        "name": {
            "value": "Omsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7445,
        "urn": "urn:li:region:7445",
        "states": []
    },
    {
        "name": {
            "value": "Krasnodar Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7446,
        "urn": "urn:li:region:7446",
        "states": []
    },
    {
        "name": {
            "value": "Novosibirsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7447,
        "urn": "urn:li:region:7447",
        "states": []
    },
    {
        "name": {
            "value": "Kemerovo Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7448,
        "urn": "urn:li:region:7448",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Sakha (Yakutia), Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7449,
        "urn": "urn:li:region:7449",
        "states": []
    },
    {
        "name": {
            "value": "Sakhalin Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7450,
        "urn": "urn:li:region:7450",
        "states": []
    },
    {
        "name": {
            "value": "Kamchatka Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7451,
        "urn": "urn:li:region:7451",
        "states": []
    },
    {
        "name": {
            "value": "Chukotka Autonomous, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7452,
        "urn": "urn:li:region:7452",
        "states": []
    },
    {
        "name": {
            "value": "Magadan Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7453,
        "urn": "urn:li:region:7453",
        "states": []
    },
    {
        "name": {
            "value": "Jewish Autonomous Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7454,
        "urn": "urn:li:region:7454",
        "states": []
    },
    {
        "name": {
            "value": "Primorye Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7455,
        "urn": "urn:li:region:7455",
        "states": []
    },
    {
        "name": {
            "value": "Amur Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7456,
        "urn": "urn:li:region:7456",
        "states": []
    },
    {
        "name": {
            "value": "Khabarovsk Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7457,
        "urn": "urn:li:region:7457",
        "states": []
    },
    {
        "name": {
            "value": "Leningrad Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7458,
        "urn": "urn:li:region:7458",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Karelia, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7459,
        "urn": "urn:li:region:7459",
        "states": []
    },
    {
        "name": {
            "value": "Vologda Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7460,
        "urn": "urn:li:region:7460",
        "states": []
    },
    {
        "name": {
            "value": "Kaliningrad Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7461,
        "urn": "urn:li:region:7461",
        "states": []
    },
    {
        "name": {
            "value": "Novgorod Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7462,
        "urn": "urn:li:region:7462",
        "states": []
    },
    {
        "name": {
            "value": "Saint Petersburg, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7463,
        "urn": "urn:li:region:7463",
        "states": []
    },
    {
        "name": {
            "value": "Murmansk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7464,
        "urn": "urn:li:region:7464",
        "states": []
    },
    {
        "name": {
            "value": "Arkhangelsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7465,
        "urn": "urn:li:region:7465",
        "states": []
    },
    {
        "name": {
            "value": "Komi Republic, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7466,
        "urn": "urn:li:region:7466",
        "states": []
    },
    {
        "name": {
            "value": "Pskov Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7467,
        "urn": "urn:li:region:7467",
        "states": []
    },
    {
        "name": {
            "value": "Tyumen Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7468,
        "urn": "urn:li:region:7468",
        "states": []
    },
    {
        "name": {
            "value": "Sverdlovsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7469,
        "urn": "urn:li:region:7469",
        "states": []
    },
    {
        "name": {
            "value": "Kurgan Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7470,
        "urn": "urn:li:region:7470",
        "states": []
    },
    {
        "name": {
            "value": "Chelyabinsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7471,
        "urn": "urn:li:region:7471",
        "states": []
    },
    {
        "name": {
            "value": "Ryazan Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7472,
        "urn": "urn:li:region:7472",
        "states": []
    },
    {
        "name": {
            "value": "Ivanovo Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7473,
        "urn": "urn:li:region:7473",
        "states": []
    },
    {
        "name": {
            "value": "Tula Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7474,
        "urn": "urn:li:region:7474",
        "states": []
    },
    {
        "name": {
            "value": "Belgorod Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7475,
        "urn": "urn:li:region:7475",
        "states": []
    },
    {
        "name": {
            "value": "Yaroslavl Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7476,
        "urn": "urn:li:region:7476",
        "states": []
    },
    {
        "name": {
            "value": "Kursk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7477,
        "urn": "urn:li:region:7477",
        "states": []
    },
    {
        "name": {
            "value": "Tver Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7478,
        "urn": "urn:li:region:7478",
        "states": []
    },
    {
        "name": {
            "value": "Bryansk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7479,
        "urn": "urn:li:region:7479",
        "states": []
    },
    {
        "name": {
            "value": "Orel Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7480,
        "urn": "urn:li:region:7480",
        "states": []
    },
    {
        "name": {
            "value": "Moscow Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7481,
        "urn": "urn:li:region:7481",
        "states": []
    },
    {
        "name": {
            "value": "Lipetsk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7482,
        "urn": "urn:li:region:7482",
        "states": []
    },
    {
        "name": {
            "value": "Kostroma Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7483,
        "urn": "urn:li:region:7483",
        "states": []
    },
    {
        "name": {
            "value": "Kaluga Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7484,
        "urn": "urn:li:region:7484",
        "states": []
    },
    {
        "name": {
            "value": "Vladimir Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7485,
        "urn": "urn:li:region:7485",
        "states": []
    },
    {
        "name": {
            "value": "Tambov Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7486,
        "urn": "urn:li:region:7486",
        "states": []
    },
    {
        "name": {
            "value": "Moscow, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7487,
        "urn": "urn:li:region:7487",
        "states": []
    },
    {
        "name": {
            "value": "Voronezh Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7488,
        "urn": "urn:li:region:7488",
        "states": []
    },
    {
        "name": {
            "value": "Smolensk Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7489,
        "urn": "urn:li:region:7489",
        "states": []
    },
    {
        "name": {
            "value": "Krasnoyarsk Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7490,
        "urn": "urn:li:region:7490",
        "states": []
    },
    {
        "name": {
            "value": "Volgograd Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7491,
        "urn": "urn:li:region:7491",
        "states": []
    },
    {
        "name": {
            "value": "Rostov Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7492,
        "urn": "urn:li:region:7492",
        "states": []
    },
    {
        "name": {
            "value": "Chechen Republic, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7493,
        "urn": "urn:li:region:7493",
        "states": []
    },
    {
        "name": {
            "value": "Stavropol Territory, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7494,
        "urn": "urn:li:region:7494",
        "states": []
    },
    {
        "name": {
            "value": "Kabardino-Balkarian Republic, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7495,
        "urn": "urn:li:region:7495",
        "states": []
    },
    {
        "name": {
            "value": "Republic of North Ossetia - Alania, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7496,
        "urn": "urn:li:region:7496",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Daghestan, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7497,
        "urn": "urn:li:region:7497",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Kalmykia, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7498,
        "urn": "urn:li:region:7498",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Ingushetia, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7499,
        "urn": "urn:li:region:7499",
        "states": []
    },
    {
        "name": {
            "value": "Republic of Adygeya, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7500,
        "urn": "urn:li:region:7500",
        "states": []
    },
    {
        "name": {
            "value": "Astrakhan Region, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7501,
        "urn": "urn:li:region:7501",
        "states": []
    },
    {
        "name": {
            "value": "Karachayevo-Circassian Republic, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7502,
        "urn": "urn:li:region:7502",
        "states": []
    },
    {
        "name": {
            "value": "Nenets Autonomous, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7503,
        "urn": "urn:li:region:7503",
        "states": []
    },
    {
        "name": {
            "value": "Khanty-Mansi Autonomous - Yugra, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7504,
        "urn": "urn:li:region:7504",
        "states": []
    },
    {
        "name": {
            "value": "Yamal-Nenets Autonomous, Russian Federation"
        },
        "country": "urn:li:country:ru",
        "id": 7505,
        "urn": "urn:li:region:7505",
        "states": []
    },
    {
        "name": {
            "value": "Gorj County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7516,
        "urn": "urn:li:region:7516",
        "states": [
            "urn:li:state:(urn:li:country:ro,19)",
            "urn:li:state:(urn:li:country:ro,39)",
            "urn:li:state:(urn:li:country:ro,16)"
        ]
    },
    {
        "name": {
            "value": "Valcea County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7517,
        "urn": "urn:li:region:7517",
        "states": [
            "urn:li:state:(urn:li:country:ro,39)",
            "urn:li:state:(urn:li:country:ro,03)",
            "urn:li:state:(urn:li:country:ro,30)"
        ]
    },
    {
        "name": {
            "value": "Olt County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7518,
        "urn": "urn:li:region:7518",
        "states": [
            "urn:li:state:(urn:li:country:ro,29)",
            "urn:li:state:(urn:li:country:ro,03)",
            "urn:li:state:(urn:li:country:ro,39)",
            "urn:li:state:(urn:li:country:ro,35)"
        ]
    },
    {
        "name": {
            "value": "Dolj County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7519,
        "urn": "urn:li:region:7519",
        "states": [
            "urn:li:state:(urn:li:country:ro,17)",
            "urn:li:state:(urn:li:country:ro,29)",
            "urn:li:state:(urn:li:country:ro,39)",
            "urn:li:state:(urn:li:country:ro,19)"
        ]
    },
    {
        "name": {
            "value": "Mehedinti County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7520,
        "urn": "urn:li:region:7520",
        "states": [
            "urn:li:state:(urn:li:country:ro,26)",
            "urn:li:state:(urn:li:country:ro,19)",
            "urn:li:state:(urn:li:country:ro,17)"
        ]
    },
    {
        "name": {
            "value": "Giurgiu County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7521,
        "urn": "urn:li:region:7521",
        "states": [
            "urn:li:state:(urn:li:country:ro,42)",
            "urn:li:state:(urn:li:country:ro,41)",
            "urn:li:state:(urn:li:country:ro,14)"
        ]
    },
    {
        "name": {
            "value": "Ialomita County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7522,
        "urn": "urn:li:region:7522",
        "states": [
            "urn:li:state:(urn:li:country:ro,22)",
            "urn:li:state:(urn:li:country:ro,41)",
            "urn:li:state:(urn:li:country:ro,11)",
            "urn:li:state:(urn:li:country:ro,30)",
            "urn:li:state:(urn:li:country:ro,42)"
        ]
    },
    {
        "name": {
            "value": "Dambovita County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7523,
        "urn": "urn:li:region:7523",
        "states": [
            "urn:li:state:(urn:li:country:ro,16)",
            "urn:li:state:(urn:li:country:ro,30)",
            "urn:li:state:(urn:li:country:ro,09)"
        ]
    },
    {
        "name": {
            "value": "Arges County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7524,
        "urn": "urn:li:region:7524",
        "states": [
            "urn:li:state:(urn:li:country:ro,03)",
            "urn:li:state:(urn:li:country:ro,16)",
            "urn:li:state:(urn:li:country:ro,30)"
        ]
    },
    {
        "name": {
            "value": "Teleorman County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7525,
        "urn": "urn:li:region:7525",
        "states": [
            "urn:li:state:(urn:li:country:ro,35)",
            "urn:li:state:(urn:li:country:ro,42)",
            "urn:li:state:(urn:li:country:ro,16)",
            "urn:li:state:(urn:li:country:ro,03)",
            "urn:li:state:(urn:li:country:ro,43)",
            "urn:li:state:(urn:li:country:ro,36)"
        ]
    },
    {
        "name": {
            "value": "Prahova County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7526,
        "urn": "urn:li:region:7526",
        "states": [
            "urn:li:state:(urn:li:country:ro,30)"
        ]
    },
    {
        "name": {
            "value": "Bihor County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7527,
        "urn": "urn:li:region:7527",
        "states": [
            "urn:li:state:(urn:li:country:ro,05)",
            "urn:li:state:(urn:li:country:ro,01)",
            "urn:li:state:(urn:li:country:ro,02)",
            "urn:li:state:(urn:li:country:ro,31)"
        ]
    },
    {
        "name": {
            "value": "Satu Mare County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7528,
        "urn": "urn:li:region:7528",
        "states": [
            "urn:li:state:(urn:li:country:ro,32)",
            "urn:li:state:(urn:li:country:ro,25)",
            "urn:li:state:(urn:li:country:ro,31)",
            "urn:li:state:(urn:li:country:ro,27)"
        ]
    },
    {
        "name": {
            "value": "Cluj County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7529,
        "urn": "urn:li:region:7529",
        "states": [
            "urn:li:state:(urn:li:country:ro,13)",
            "urn:li:state:(urn:li:country:ro,27)",
            "urn:li:state:(urn:li:country:ro,01)",
            "urn:li:state:(urn:li:country:ro,25)"
        ]
    },
    {
        "name": {
            "value": "Salaj County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7530,
        "urn": "urn:li:region:7530",
        "states": [
            "urn:li:state:(urn:li:country:ro,31)",
            "urn:li:state:(urn:li:country:ro,25)",
            "urn:li:state:(urn:li:country:ro,13)"
        ]
    },
    {
        "name": {
            "value": "Bistrita-Nasaud County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7531,
        "urn": "urn:li:region:7531",
        "states": [
            "urn:li:state:(urn:li:country:ro,06)",
            "urn:li:state:(urn:li:country:ro,27)",
            "urn:li:state:(urn:li:country:ro,13)"
        ]
    },
    {
        "name": {
            "value": "Caras-Severin County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7532,
        "urn": "urn:li:region:7532",
        "states": [
            "urn:li:state:(urn:li:country:ro,12)",
            "urn:li:state:(urn:li:country:ro,21)",
            "urn:li:state:(urn:li:country:ro,36)"
        ]
    },
    {
        "name": {
            "value": "Timis County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7533,
        "urn": "urn:li:region:7533",
        "states": [
            "urn:li:state:(urn:li:country:ro,36)",
            "urn:li:state:(urn:li:country:ro,32)"
        ]
    },
    {
        "name": {
            "value": "Arad County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7534,
        "urn": "urn:li:region:7534",
        "states": [
            "urn:li:state:(urn:li:country:ro,02)",
            "urn:li:state:(urn:li:country:ro,21)"
        ]
    },
    {
        "name": {
            "value": "Hunedoara County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7535,
        "urn": "urn:li:region:7535",
        "states": [
            "urn:li:state:(urn:li:country:ro,21)",
            "urn:li:state:(urn:li:country:ro,01)",
            "urn:li:state:(urn:li:country:ro,29)"
        ]
    },
    {
        "name": {
            "value": "Bucharest, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7536,
        "urn": "urn:li:region:7536",
        "states": [
            "urn:li:state:(urn:li:country:ro,43)",
            "urn:li:state:(urn:li:country:ro,42)",
            "urn:li:state:(urn:li:country:ro,41)"
        ]
    },
    {
        "name": {
            "value": "Ilfov County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7537,
        "urn": "urn:li:region:7537",
        "states": [
            "urn:li:state:(urn:li:country:ro,43)",
            "urn:li:state:(urn:li:country:ro,30)",
            "urn:li:state:(urn:li:country:ro,16)",
            "urn:li:state:(urn:li:country:ro,42)"
        ]
    },
    {
        "name": {
            "value": "Sibiu County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7538,
        "urn": "urn:li:region:7538",
        "states": [
            "urn:li:state:(urn:li:country:ro,33)",
            "urn:li:state:(urn:li:country:ro,09)"
        ]
    },
    {
        "name": {
            "value": "Alba County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7539,
        "urn": "urn:li:region:7539",
        "states": [
            "urn:li:state:(urn:li:country:ro,01)",
            "urn:li:state:(urn:li:country:ro,21)",
            "urn:li:state:(urn:li:country:ro,27)",
            "urn:li:state:(urn:li:country:ro,33)"
        ]
    },
    {
        "name": {
            "value": "Covasna County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7540,
        "urn": "urn:li:region:7540",
        "states": [
            "urn:li:state:(urn:li:country:ro,15)",
            "urn:li:state:(urn:li:country:ro,09)",
            "urn:li:state:(urn:li:country:ro,20)"
        ]
    },
    {
        "name": {
            "value": "Harghita County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7541,
        "urn": "urn:li:region:7541",
        "states": [
            "urn:li:state:(urn:li:country:ro,20)",
            "urn:li:state:(urn:li:country:ro,15)",
            "urn:li:state:(urn:li:country:ro,27)"
        ]
    },
    {
        "name": {
            "value": "Mures County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7542,
        "urn": "urn:li:region:7542",
        "states": [
            "urn:li:state:(urn:li:country:ro,27)",
            "urn:li:state:(urn:li:country:ro,33)",
            "urn:li:state:(urn:li:country:ro,29)"
        ]
    },
    {
        "name": {
            "value": "Brasov County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7543,
        "urn": "urn:li:region:7543",
        "states": [
            "urn:li:state:(urn:li:country:ro,09)",
            "urn:li:state:(urn:li:country:ro,33)",
            "urn:li:state:(urn:li:country:ro,15)"
        ]
    },
    {
        "name": {
            "value": "Galati County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7544,
        "urn": "urn:li:region:7544",
        "states": [
            "urn:li:state:(urn:li:country:ro,18)",
            "urn:li:state:(urn:li:country:ro,40)",
            "urn:li:state:(urn:li:country:ro,38)"
        ]
    },
    {
        "name": {
            "value": "Braila County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7545,
        "urn": "urn:li:region:7545",
        "states": [
            "urn:li:state:(urn:li:country:ro,08)",
            "urn:li:state:(urn:li:country:ro,11)",
            "urn:li:state:(urn:li:country:ro,22)"
        ]
    },
    {
        "name": {
            "value": "Vrancea County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7546,
        "urn": "urn:li:region:7546",
        "states": [
            "urn:li:state:(urn:li:country:ro,40)",
            "urn:li:state:(urn:li:country:ro,15)",
            "urn:li:state:(urn:li:country:ro,18)"
        ]
    },
    {
        "name": {
            "value": "Tulcea County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7547,
        "urn": "urn:li:region:7547",
        "states": [
            "urn:li:state:(urn:li:country:ro,37)",
            "urn:li:state:(urn:li:country:ro,08)"
        ]
    },
    {
        "name": {
            "value": "Constantza County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7548,
        "urn": "urn:li:region:7548",
        "states": [
            "urn:li:state:(urn:li:country:ro,14)",
            "urn:li:state:(urn:li:country:ro,41)"
        ]
    },
    {
        "name": {
            "value": "Buzau County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7549,
        "urn": "urn:li:region:7549",
        "states": [
            "urn:li:state:(urn:li:country:ro,11)",
            "urn:li:state:(urn:li:country:ro,30)",
            "urn:li:state:(urn:li:country:ro,29)"
        ]
    },
    {
        "name": {
            "value": "Bacau County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7550,
        "urn": "urn:li:region:7550",
        "states": [
            "urn:li:state:(urn:li:country:ro,04)",
            "urn:li:state:(urn:li:country:ro,23)",
            "urn:li:state:(urn:li:country:ro,29)"
        ]
    },
    {
        "name": {
            "value": "Suceava County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7551,
        "urn": "urn:li:region:7551",
        "states": [
            "urn:li:state:(urn:li:country:ro,34)",
            "urn:li:state:(urn:li:country:ro,28)",
            "urn:li:state:(urn:li:country:ro,20)",
            "urn:li:state:(urn:li:country:ro,27)"
        ]
    },
    {
        "name": {
            "value": "Iasi County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7552,
        "urn": "urn:li:region:7552",
        "states": [
            "urn:li:state:(urn:li:country:ro,23)",
            "urn:li:state:(urn:li:country:ro,28)",
            "urn:li:state:(urn:li:country:ro,38)",
            "urn:li:state:(urn:li:country:ro,29)"
        ]
    },
    {
        "name": {
            "value": "Botosani County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7553,
        "urn": "urn:li:region:7553",
        "states": [
            "urn:li:state:(urn:li:country:ro,07)",
            "urn:li:state:(urn:li:country:ro,34)",
            "urn:li:state:(urn:li:country:ro,28)"
        ]
    },
    {
        "name": {
            "value": "Neamt County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7554,
        "urn": "urn:li:region:7554",
        "states": [
            "urn:li:state:(urn:li:country:ro,28)",
            "urn:li:state:(urn:li:country:ro,04)",
            "urn:li:state:(urn:li:country:ro,20)"
        ]
    },
    {
        "name": {
            "value": "Vaslui County, Romania"
        },
        "country": "urn:li:country:ro",
        "id": 7555,
        "urn": "urn:li:region:7555",
        "states": [
            "urn:li:state:(urn:li:country:ro,38)",
            "urn:li:state:(urn:li:country:ro,04)",
            "urn:li:state:(urn:li:country:ro,18)",
            "urn:li:state:(urn:li:country:ro,40)"
        ]
    },
    {
        "name": {
            "value": "Mardin, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7556,
        "urn": "urn:li:region:7556",
        "states": [
            "urn:li:state:(urn:li:country:tr,47)",
            "urn:li:state:(urn:li:country:tr,68)"
        ]
    },
    {
        "name": {
            "value": "Gaziantep, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7557,
        "urn": "urn:li:region:7557",
        "states": [
            "urn:li:state:(urn:li:country:tr,27)"
        ]
    },
    {
        "name": {
            "value": "Sirnak, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7558,
        "urn": "urn:li:region:7558",
        "states": [
            "urn:li:state:(urn:li:country:tr,73)"
        ]
    },
    {
        "name": {
            "value": "Siirt, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7559,
        "urn": "urn:li:region:7559",
        "states": [
            "urn:li:state:(urn:li:country:tr,56)"
        ]
    },
    {
        "name": {
            "value": "Batman, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7560,
        "urn": "urn:li:region:7560",
        "states": [
            "urn:li:state:(urn:li:country:tr,72)"
        ]
    },
    {
        "name": {
            "value": "Adiyaman, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7561,
        "urn": "urn:li:region:7561",
        "states": [
            "urn:li:state:(urn:li:country:tr,2)",
            "urn:li:state:(urn:li:country:tr,6)"
        ]
    },
    {
        "name": {
            "value": "Kilis, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7562,
        "urn": "urn:li:region:7562",
        "states": [
            "urn:li:state:(urn:li:country:tr,79)"
        ]
    },
    {
        "name": {
            "value": "Sanliurfa, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7563,
        "urn": "urn:li:region:7563",
        "states": [
            "urn:li:state:(urn:li:country:tr,63)"
        ]
    },
    {
        "name": {
            "value": "Diyarbakir, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7564,
        "urn": "urn:li:region:7564",
        "states": [
            "urn:li:state:(urn:li:country:tr,21)",
            "urn:li:state:(urn:li:country:tr,33)",
            "urn:li:state:(urn:li:country:tr,16)"
        ]
    },
    {
        "name": {
            "value": "Cankiri, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7565,
        "urn": "urn:li:region:7565",
        "states": [
            "urn:li:state:(urn:li:country:tr,18)"
        ]
    },
    {
        "name": {
            "value": "Konya, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7566,
        "urn": "urn:li:region:7566",
        "states": [
            "urn:li:state:(urn:li:country:tr,42)"
        ]
    },
    {
        "name": {
            "value": "Kayseri, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7567,
        "urn": "urn:li:region:7567",
        "states": [
            "urn:li:state:(urn:li:country:tr,38)"
        ]
    },
    {
        "name": {
            "value": "Nevsehir, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7568,
        "urn": "urn:li:region:7568",
        "states": [
            "urn:li:state:(urn:li:country:tr,50)"
        ]
    },
    {
        "name": {
            "value": "Ankara, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7569,
        "urn": "urn:li:region:7569",
        "states": [
            "urn:li:state:(urn:li:country:tr,6)"
        ]
    },
    {
        "name": {
            "value": "Yozgat, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7570,
        "urn": "urn:li:region:7570",
        "states": [
            "urn:li:state:(urn:li:country:tr,66)"
        ]
    },
    {
        "name": {
            "value": "Aksaray, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7571,
        "urn": "urn:li:region:7571",
        "states": [
            "urn:li:state:(urn:li:country:tr,68)",
            "urn:li:state:(urn:li:country:tr,19)"
        ]
    },
    {
        "name": {
            "value": "Eskisehir, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7572,
        "urn": "urn:li:region:7572",
        "states": [
            "urn:li:state:(urn:li:country:tr,26)"
        ]
    },
    {
        "name": {
            "value": "Sivas, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7573,
        "urn": "urn:li:region:7573",
        "states": [
            "urn:li:state:(urn:li:country:tr,58)"
        ]
    },
    {
        "name": {
            "value": "Karaman, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7574,
        "urn": "urn:li:region:7574",
        "states": [
            "urn:li:state:(urn:li:country:tr,70)"
        ]
    },
    {
        "name": {
            "value": "Kirsehir, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7575,
        "urn": "urn:li:region:7575",
        "states": [
            "urn:li:state:(urn:li:country:tr,40)"
        ]
    },
    {
        "name": {
            "value": "Kirikkale, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7576,
        "urn": "urn:li:region:7576",
        "states": [
            "urn:li:state:(urn:li:country:tr,71)",
            "urn:li:state:(urn:li:country:tr,16)"
        ]
    },
    {
        "name": {
            "value": "Nigde, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7577,
        "urn": "urn:li:region:7577",
        "states": [
            "urn:li:state:(urn:li:country:tr,51)"
        ]
    },
    {
        "name": {
            "value": "Bursa, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7578,
        "urn": "urn:li:region:7578",
        "states": [
            "urn:li:state:(urn:li:country:tr,16)",
            "urn:li:state:(urn:li:country:tr,7)",
            "urn:li:state:(urn:li:country:tr,34)",
            "urn:li:state:(urn:li:country:tr,55)"
        ]
    },
    {
        "name": {
            "value": "Canakkale, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7579,
        "urn": "urn:li:region:7579",
        "states": [
            "urn:li:state:(urn:li:country:tr,17)"
        ]
    },
    {
        "name": {
            "value": "Bilecik, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7580,
        "urn": "urn:li:region:7580",
        "states": [
            "urn:li:state:(urn:li:country:tr,11)"
        ]
    },
    {
        "name": {
            "value": "Kirklareli, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7581,
        "urn": "urn:li:region:7581",
        "states": [
            "urn:li:state:(urn:li:country:tr,39)"
        ]
    },
    {
        "name": {
            "value": "Yalova, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7582,
        "urn": "urn:li:region:7582",
        "states": [
            "urn:li:state:(urn:li:country:tr,77)"
        ]
    },
    {
        "name": {
            "value": "Kocaeli, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7583,
        "urn": "urn:li:region:7583",
        "states": [
            "urn:li:state:(urn:li:country:tr,41)"
        ]
    },
    {
        "name": {
            "value": "Balikesir, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7584,
        "urn": "urn:li:region:7584",
        "states": [
            "urn:li:state:(urn:li:country:tr,10)",
            "urn:li:state:(urn:li:country:tr,32)",
            "urn:li:state:(urn:li:country:tr,16)"
        ]
    },
    {
        "name": {
            "value": "Istanbul, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7585,
        "urn": "urn:li:region:7585",
        "states": [
            "urn:li:state:(urn:li:country:tr,34)"
        ]
    },
    {
        "name": {
            "value": "Tekirdag, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7586,
        "urn": "urn:li:region:7586",
        "states": [
            "urn:li:state:(urn:li:country:tr,59)",
            "urn:li:state:(urn:li:country:tr,65)"
        ]
    },
    {
        "name": {
            "value": "Edirne, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7587,
        "urn": "urn:li:region:7587",
        "states": [
            "urn:li:state:(urn:li:country:tr,22)"
        ]
    },
    {
        "name": {
            "value": "Sakarya, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7588,
        "urn": "urn:li:region:7588",
        "states": [
            "urn:li:state:(urn:li:country:tr,54)"
        ]
    },
    {
        "name": {
            "value": "Burdur, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7589,
        "urn": "urn:li:region:7589",
        "states": [
            "urn:li:state:(urn:li:country:tr,15)",
            "urn:li:state:(urn:li:country:tr,34)"
        ]
    },
    {
        "name": {
            "value": "Hatay, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7590,
        "urn": "urn:li:region:7590",
        "states": [
            "urn:li:state:(urn:li:country:tr,31)"
        ]
    },
    {
        "name": {
            "value": "Antalya, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7591,
        "urn": "urn:li:region:7591",
        "states": [
            "urn:li:state:(urn:li:country:tr,7)",
            "urn:li:state:(urn:li:country:tr,15)"
        ]
    },
    {
        "name": {
            "value": "Isparta, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7592,
        "urn": "urn:li:region:7592",
        "states": [
            "urn:li:state:(urn:li:country:tr,32)",
            "urn:li:state:(urn:li:country:tr,7)"
        ]
    },
    {
        "name": {
            "value": "Kahramanmaras, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7593,
        "urn": "urn:li:region:7593",
        "states": [
            "urn:li:state:(urn:li:country:tr,46)"
        ]
    },
    {
        "name": {
            "value": "Mersin, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7594,
        "urn": "urn:li:region:7594",
        "states": [
            "urn:li:state:(urn:li:country:tr,33)",
            "urn:li:state:(urn:li:country:tr,66)"
        ]
    },
    {
        "name": {
            "value": "Adana, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7595,
        "urn": "urn:li:region:7595",
        "states": [
            "urn:li:state:(urn:li:country:tr,1)"
        ]
    },
    {
        "name": {
            "value": "Osmaniye, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7596,
        "urn": "urn:li:region:7596",
        "states": [
            "urn:li:state:(urn:li:country:tr,80)"
        ]
    },
    {
        "name": {
            "value": "Erzurum, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7597,
        "urn": "urn:li:region:7597",
        "states": [
            "urn:li:state:(urn:li:country:tr,25)"
        ]
    },
    {
        "name": {
            "value": "Mus, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7598,
        "urn": "urn:li:region:7598",
        "states": [
            "urn:li:state:(urn:li:country:tr,49)"
        ]
    },
    {
        "name": {
            "value": "Erzincan, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7599,
        "urn": "urn:li:region:7599",
        "states": [
            "urn:li:state:(urn:li:country:tr,24)"
        ]
    },
    {
        "name": {
            "value": "Malatya, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7600,
        "urn": "urn:li:region:7600",
        "states": [
            "urn:li:state:(urn:li:country:tr,44)"
        ]
    },
    {
        "name": {
            "value": "Van, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7601,
        "urn": "urn:li:region:7601",
        "states": [
            "urn:li:state:(urn:li:country:tr,65)"
        ]
    },
    {
        "name": {
            "value": "Elazig, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7602,
        "urn": "urn:li:region:7602",
        "states": [
            "urn:li:state:(urn:li:country:tr,23)"
        ]
    },
    {
        "name": {
            "value": "Hakkari, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7603,
        "urn": "urn:li:region:7603",
        "states": [
            "urn:li:state:(urn:li:country:tr,30)"
        ]
    },
    {
        "name": {
            "value": "Ardahan, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7604,
        "urn": "urn:li:region:7604",
        "states": [
            "urn:li:state:(urn:li:country:tr,75)"
        ]
    },
    {
        "name": {
            "value": "Bingol, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7605,
        "urn": "urn:li:region:7605",
        "states": [
            "urn:li:state:(urn:li:country:tr,12)"
        ]
    },
    {
        "name": {
            "value": "Kars, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7606,
        "urn": "urn:li:region:7606",
        "states": [
            "urn:li:state:(urn:li:country:tr,36)",
            "urn:li:state:(urn:li:country:tr,7)"
        ]
    },
    {
        "name": {
            "value": "Tunceli, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7607,
        "urn": "urn:li:region:7607",
        "states": [
            "urn:li:state:(urn:li:country:tr,62)",
            "urn:li:state:(urn:li:country:tr,78)"
        ]
    },
    {
        "name": {
            "value": "Igdir, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7608,
        "urn": "urn:li:region:7608",
        "states": [
            "urn:li:state:(urn:li:country:tr,76)"
        ]
    },
    {
        "name": {
            "value": "Bitlis, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7609,
        "urn": "urn:li:region:7609",
        "states": [
            "urn:li:state:(urn:li:country:tr,13)"
        ]
    },
    {
        "name": {
            "value": "Agri, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7610,
        "urn": "urn:li:region:7610",
        "states": [
            "urn:li:state:(urn:li:country:tr,4)"
        ]
    },
    {
        "name": {
            "value": "Manisa, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7611,
        "urn": "urn:li:region:7611",
        "states": [
            "urn:li:state:(urn:li:country:tr,45)"
        ]
    },
    {
        "name": {
            "value": "Usak, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7612,
        "urn": "urn:li:region:7612",
        "states": [
            "urn:li:state:(urn:li:country:tr,64)"
        ]
    },
    {
        "name": {
            "value": "Izmir, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7613,
        "urn": "urn:li:region:7613",
        "states": [
            "urn:li:state:(urn:li:country:tr,35)"
        ]
    },
    {
        "name": {
            "value": "Mugla, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7614,
        "urn": "urn:li:region:7614",
        "states": [
            "urn:li:state:(urn:li:country:tr,48)"
        ]
    },
    {
        "name": {
            "value": "Kutahya, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7615,
        "urn": "urn:li:region:7615",
        "states": [
            "urn:li:state:(urn:li:country:tr,43)"
        ]
    },
    {
        "name": {
            "value": "Afyonkarahisar, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7616,
        "urn": "urn:li:region:7616",
        "states": [
            "urn:li:state:(urn:li:country:tr,3)",
            "urn:li:state:(urn:li:country:tr,19)"
        ]
    },
    {
        "name": {
            "value": "Denizli, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7617,
        "urn": "urn:li:region:7617",
        "states": [
            "urn:li:state:(urn:li:country:tr,20)",
            "urn:li:state:(urn:li:country:tr,44)",
            "urn:li:state:(urn:li:country:tr,37)"
        ]
    },
    {
        "name": {
            "value": "Aydin, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7618,
        "urn": "urn:li:region:7618",
        "states": [
            "urn:li:state:(urn:li:country:tr,9)",
            "urn:li:state:(urn:li:country:tr,11)"
        ]
    },
    {
        "name": {
            "value": "Giresun, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7619,
        "urn": "urn:li:region:7619",
        "states": [
            "urn:li:state:(urn:li:country:tr,28)"
        ]
    },
    {
        "name": {
            "value": "Bayburt, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7620,
        "urn": "urn:li:region:7620",
        "states": [
            "urn:li:state:(urn:li:country:tr,69)"
        ]
    },
    {
        "name": {
            "value": "Duzce, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7621,
        "urn": "urn:li:region:7621",
        "states": [
            "urn:li:state:(urn:li:country:tr,81)"
        ]
    },
    {
        "name": {
            "value": "Zonguldak, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7622,
        "urn": "urn:li:region:7622",
        "states": [
            "urn:li:state:(urn:li:country:tr,67)"
        ]
    },
    {
        "name": {
            "value": "Corum, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7623,
        "urn": "urn:li:region:7623",
        "states": [
            "urn:li:state:(urn:li:country:tr,19)"
        ]
    },
    {
        "name": {
            "value": "Karabuk, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7624,
        "urn": "urn:li:region:7624",
        "states": [
            "urn:li:state:(urn:li:country:tr,78)",
            "urn:li:state:(urn:li:country:tr,17)"
        ]
    },
    {
        "name": {
            "value": "Artvin, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7625,
        "urn": "urn:li:region:7625",
        "states": [
            "urn:li:state:(urn:li:country:tr,8)"
        ]
    },
    {
        "name": {
            "value": "Amasya, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7626,
        "urn": "urn:li:region:7626",
        "states": [
            "urn:li:state:(urn:li:country:tr,5)"
        ]
    },
    {
        "name": {
            "value": "Kastamonu, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7627,
        "urn": "urn:li:region:7627",
        "states": [
            "urn:li:state:(urn:li:country:tr,37)",
            "urn:li:state:(urn:li:country:tr,38)"
        ]
    },
    {
        "name": {
            "value": "Trabzon, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7628,
        "urn": "urn:li:region:7628",
        "states": [
            "urn:li:state:(urn:li:country:tr,61)",
            "urn:li:state:(urn:li:country:tr,45)"
        ]
    },
    {
        "name": {
            "value": "Tokat, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7629,
        "urn": "urn:li:region:7629",
        "states": [
            "urn:li:state:(urn:li:country:tr,60)",
            "urn:li:state:(urn:li:country:tr,44)"
        ]
    },
    {
        "name": {
            "value": "Rize, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7630,
        "urn": "urn:li:region:7630",
        "states": [
            "urn:li:state:(urn:li:country:tr,53)",
            "urn:li:state:(urn:li:country:tr,60)"
        ]
    },
    {
        "name": {
            "value": "Gumushane, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7631,
        "urn": "urn:li:region:7631",
        "states": [
            "urn:li:state:(urn:li:country:tr,29)"
        ]
    },
    {
        "name": {
            "value": "Bartin, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7632,
        "urn": "urn:li:region:7632",
        "states": [
            "urn:li:state:(urn:li:country:tr,74)"
        ]
    },
    {
        "name": {
            "value": "Sinop, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7633,
        "urn": "urn:li:region:7633",
        "states": [
            "urn:li:state:(urn:li:country:tr,57)"
        ]
    },
    {
        "name": {
            "value": "Samsun, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7634,
        "urn": "urn:li:region:7634",
        "states": [
            "urn:li:state:(urn:li:country:tr,55)",
            "urn:li:state:(urn:li:country:tr,17)"
        ]
    },
    {
        "name": {
            "value": "Ordu, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7635,
        "urn": "urn:li:region:7635",
        "states": [
            "urn:li:state:(urn:li:country:tr,52)",
            "urn:li:state:(urn:li:country:tr,64)"
        ]
    },
    {
        "name": {
            "value": "Bolu, Turkey"
        },
        "country": "urn:li:country:tr",
        "id": 7636,
        "urn": "urn:li:region:7636",
        "states": [
            "urn:li:state:(urn:li:country:tr,14)"
        ]
    },
    {
        "name": {
            "value": "Yamaguchi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7637,
        "urn": "urn:li:region:7637",
        "states": []
    },
    {
        "name": {
            "value": "Shimane, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7638,
        "urn": "urn:li:region:7638",
        "states": []
    },
    {
        "name": {
            "value": "Tottori, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7639,
        "urn": "urn:li:region:7639",
        "states": []
    },
    {
        "name": {
            "value": "Hiroshima, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7640,
        "urn": "urn:li:region:7640",
        "states": []
    },
    {
        "name": {
            "value": "Okayama, , Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7641,
        "urn": "urn:li:region:7641",
        "states": []
    },
    {
        "name": {
            "value": "Kumamoto, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7642,
        "urn": "urn:li:region:7642",
        "states": []
    },
    {
        "name": {
            "value": "Miyazaki, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7643,
        "urn": "urn:li:region:7643",
        "states": []
    },
    {
        "name": {
            "value": "Nagasaki, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7644,
        "urn": "urn:li:region:7644",
        "states": []
    },
    {
        "name": {
            "value": "Fukuoka, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7645,
        "urn": "urn:li:region:7645",
        "states": []
    },
    {
        "name": {
            "value": "Saga, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7646,
        "urn": "urn:li:region:7646",
        "states": []
    },
    {
        "name": {
            "value": "Okinawa, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7647,
        "urn": "urn:li:region:7647",
        "states": []
    },
    {
        "name": {
            "value": "Kagoshima, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7648,
        "urn": "urn:li:region:7648",
        "states": []
    },
    {
        "name": {
            "value": "Oita, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7649,
        "urn": "urn:li:region:7649",
        "states": []
    },
    {
        "name": {
            "value": "Yamanashi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7650,
        "urn": "urn:li:region:7650",
        "states": []
    },
    {
        "name": {
            "value": "Ishikawa, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7651,
        "urn": "urn:li:region:7651",
        "states": []
    },
    {
        "name": {
            "value": "Fukui, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7652,
        "urn": "urn:li:region:7652",
        "states": []
    },
    {
        "name": {
            "value": "Shizuoka, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7653,
        "urn": "urn:li:region:7653",
        "states": []
    },
    {
        "name": {
            "value": "Aichi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7654,
        "urn": "urn:li:region:7654",
        "states": []
    },
    {
        "name": {
            "value": "Gifu, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7655,
        "urn": "urn:li:region:7655",
        "states": []
    },
    {
        "name": {
            "value": "Toyama, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7656,
        "urn": "urn:li:region:7656",
        "states": []
    },
    {
        "name": {
            "value": "Nagano, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7657,
        "urn": "urn:li:region:7657",
        "states": []
    },
    {
        "name": {
            "value": "Niigata, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7658,
        "urn": "urn:li:region:7658",
        "states": []
    },
    {
        "name": {
            "value": "Akita, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7659,
        "urn": "urn:li:region:7659",
        "states": []
    },
    {
        "name": {
            "value": "Yamagata, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7660,
        "urn": "urn:li:region:7660",
        "states": []
    },
    {
        "name": {
            "value": "Aomori, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7661,
        "urn": "urn:li:region:7661",
        "states": []
    },
    {
        "name": {
            "value": "Fukushima, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7662,
        "urn": "urn:li:region:7662",
        "states": []
    },
    {
        "name": {
            "value": "Miyagi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7663,
        "urn": "urn:li:region:7663",
        "states": []
    },
    {
        "name": {
            "value": "Iwate, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7664,
        "urn": "urn:li:region:7664",
        "states": []
    },
    {
        "name": {
            "value": "Tokushima, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7665,
        "urn": "urn:li:region:7665",
        "states": []
    },
    {
        "name": {
            "value": "Ehime, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7666,
        "urn": "urn:li:region:7666",
        "states": []
    },
    {
        "name": {
            "value": "Kochi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7667,
        "urn": "urn:li:region:7667",
        "states": []
    },
    {
        "name": {
            "value": "Kagawa, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7668,
        "urn": "urn:li:region:7668",
        "states": []
    },
    {
        "name": {
            "value": "Ibaraki, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7669,
        "urn": "urn:li:region:7669",
        "states": []
    },
    {
        "name": {
            "value": "Gunma, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7670,
        "urn": "urn:li:region:7670",
        "states": []
    },
    {
        "name": {
            "value": "Chiba, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7671,
        "urn": "urn:li:region:7671",
        "states": []
    },
    {
        "name": {
            "value": "Tokyo, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7672,
        "urn": "urn:li:region:7672",
        "states": []
    },
    {
        "name": {
            "value": "Kanagawa, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7673,
        "urn": "urn:li:region:7673",
        "states": []
    },
    {
        "name": {
            "value": "Tochigi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7674,
        "urn": "urn:li:region:7674",
        "states": []
    },
    {
        "name": {
            "value": "Saitama, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7675,
        "urn": "urn:li:region:7675",
        "states": []
    },
    {
        "name": {
            "value": "Hokkaido, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7676,
        "urn": "urn:li:region:7676",
        "states": []
    },
    {
        "name": {
            "value": "Hyogo, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7677,
        "urn": "urn:li:region:7677",
        "states": []
    },
    {
        "name": {
            "value": "Kyoto, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7678,
        "urn": "urn:li:region:7678",
        "states": []
    },
    {
        "name": {
            "value": "Nara, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7679,
        "urn": "urn:li:region:7679",
        "states": []
    },
    {
        "name": {
            "value": "Osaka, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7680,
        "urn": "urn:li:region:7680",
        "states": []
    },
    {
        "name": {
            "value": "Mie, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7681,
        "urn": "urn:li:region:7681",
        "states": []
    },
    {
        "name": {
            "value": "Shiga, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7682,
        "urn": "urn:li:region:7682",
        "states": []
    },
    {
        "name": {
            "value": "Wakayama, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7683,
        "urn": "urn:li:region:7683",
        "states": []
    },
    {
        "name": {
            "value": "Hamamatsu, Shizuoka, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7684,
        "urn": "urn:li:region:7684",
        "states": []
    },
    {
        "name": {
            "value": "Nagoya, Aichi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7685,
        "urn": "urn:li:region:7685",
        "states": []
    },
    {
        "name": {
            "value": "Sendai, Miyagi, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7686,
        "urn": "urn:li:region:7686",
        "states": []
    },
    {
        "name": {
            "value": "Within 23 wards, Tokyo, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7687,
        "urn": "urn:li:region:7687",
        "states": []
    },
    {
        "name": {
            "value": "Outside 23 wards, Tokyo, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7688,
        "urn": "urn:li:region:7688",
        "states": []
    },
    {
        "name": {
            "value": "Yokohama, Kanagawa, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7689,
        "urn": "urn:li:region:7689",
        "states": []
    },
    {
        "name": {
            "value": "Kawasaki, Kanagawa, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7690,
        "urn": "urn:li:region:7690",
        "states": []
    },
    {
        "name": {
            "value": "Saitama, Saitama, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7691,
        "urn": "urn:li:region:7691",
        "states": []
    },
    {
        "name": {
            "value": "Sapporo, Hokkaido, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7692,
        "urn": "urn:li:region:7692",
        "states": []
    },
    {
        "name": {
            "value": "Kobe, Hyogo, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7693,
        "urn": "urn:li:region:7693",
        "states": []
    },
    {
        "name": {
            "value": "Kyoto, Kyoto, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7694,
        "urn": "urn:li:region:7694",
        "states": []
    },
    {
        "name": {
            "value": "Osaka, Osaka, Japan"
        },
        "country": "urn:li:country:jp",
        "id": 7695,
        "urn": "urn:li:region:7695",
        "states": []
    },
    {
        "name": {
            "value": "Songpa-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7696,
        "urn": "urn:li:region:7696",
        "states": []
    },
    {
        "name": {
            "value": "Jongro-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7697,
        "urn": "urn:li:region:7697",
        "states": []
    },
    {
        "name": {
            "value": "Dongdaemun-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7698,
        "urn": "urn:li:region:7698",
        "states": []
    },
    {
        "name": {
            "value": "Jung-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7699,
        "urn": "urn:li:region:7699",
        "states": []
    },
    {
        "name": {
            "value": "Gangnam-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7700,
        "urn": "urn:li:region:7700",
        "states": []
    },
    {
        "name": {
            "value": "Mapo-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7701,
        "urn": "urn:li:region:7701",
        "states": []
    },
    {
        "name": {
            "value": "Yangcheon-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7702,
        "urn": "urn:li:region:7702",
        "states": []
    },
    {
        "name": {
            "value": "Nowon-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7703,
        "urn": "urn:li:region:7703",
        "states": []
    },
    {
        "name": {
            "value": "Gwangjin-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7704,
        "urn": "urn:li:region:7704",
        "states": []
    },
    {
        "name": {
            "value": "Yeongdeungpo-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7705,
        "urn": "urn:li:region:7705",
        "states": []
    },
    {
        "name": {
            "value": "Dobong-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7706,
        "urn": "urn:li:region:7706",
        "states": []
    },
    {
        "name": {
            "value": "Geumcheon-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7707,
        "urn": "urn:li:region:7707",
        "states": []
    },
    {
        "name": {
            "value": "Seongdong-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7708,
        "urn": "urn:li:region:7708",
        "states": []
    },
    {
        "name": {
            "value": "Gwanak-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7709,
        "urn": "urn:li:region:7709",
        "states": []
    },
    {
        "name": {
            "value": "Gangbuk-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7710,
        "urn": "urn:li:region:7710",
        "states": []
    },
    {
        "name": {
            "value": "Jungrang-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7711,
        "urn": "urn:li:region:7711",
        "states": []
    },
    {
        "name": {
            "value": "Seongbuk-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7712,
        "urn": "urn:li:region:7712",
        "states": []
    },
    {
        "name": {
            "value": "Seocho-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7713,
        "urn": "urn:li:region:7713",
        "states": []
    },
    {
        "name": {
            "value": "Guro-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7714,
        "urn": "urn:li:region:7714",
        "states": []
    },
    {
        "name": {
            "value": "Gangdong-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7715,
        "urn": "urn:li:region:7715",
        "states": []
    },
    {
        "name": {
            "value": "Yongsan-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7716,
        "urn": "urn:li:region:7716",
        "states": []
    },
    {
        "name": {
            "value": "Seodaemun-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7717,
        "urn": "urn:li:region:7717",
        "states": []
    },
    {
        "name": {
            "value": "Gangseo-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7718,
        "urn": "urn:li:region:7718",
        "states": []
    },
    {
        "name": {
            "value": "Dongjak-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7719,
        "urn": "urn:li:region:7719",
        "states": []
    },
    {
        "name": {
            "value": "Eunpyeong-gu, Seoul, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7720,
        "urn": "urn:li:region:7720",
        "states": []
    },
    {
        "name": {
            "value": "Uljin-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7721,
        "urn": "urn:li:region:7721",
        "states": []
    },
    {
        "name": {
            "value": "Goryeong-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7722,
        "urn": "urn:li:region:7722",
        "states": []
    },
    {
        "name": {
            "value": "Chilgok-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7723,
        "urn": "urn:li:region:7723",
        "states": []
    },
    {
        "name": {
            "value": "Yeongyang-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7724,
        "urn": "urn:li:region:7724",
        "states": []
    },
    {
        "name": {
            "value": "Uiseong-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7725,
        "urn": "urn:li:region:7725",
        "states": []
    },
    {
        "name": {
            "value": "Bonghwa-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7726,
        "urn": "urn:li:region:7726",
        "states": []
    },
    {
        "name": {
            "value": "Sangju, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7727,
        "urn": "urn:li:region:7727",
        "states": []
    },
    {
        "name": {
            "value": "Gumi, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7728,
        "urn": "urn:li:region:7728",
        "states": []
    },
    {
        "name": {
            "value": "Yecheon-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7729,
        "urn": "urn:li:region:7729",
        "states": []
    },
    {
        "name": {
            "value": "Ulleung-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7730,
        "urn": "urn:li:region:7730",
        "states": []
    },
    {
        "name": {
            "value": "Gyeongju, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7731,
        "urn": "urn:li:region:7731",
        "states": []
    },
    {
        "name": {
            "value": "Gimcheon, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7732,
        "urn": "urn:li:region:7732",
        "states": []
    },
    {
        "name": {
            "value": "Yeongcheon, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7733,
        "urn": "urn:li:region:7733",
        "states": []
    },
    {
        "name": {
            "value": "Yeongdeok-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7734,
        "urn": "urn:li:region:7734",
        "states": []
    },
    {
        "name": {
            "value": "Gyeongsan, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7735,
        "urn": "urn:li:region:7735",
        "states": []
    },
    {
        "name": {
            "value": "Cheongdo-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7736,
        "urn": "urn:li:region:7736",
        "states": []
    },
    {
        "name": {
            "value": "Pohang, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7737,
        "urn": "urn:li:region:7737",
        "states": []
    },
    {
        "name": {
            "value": "Andong, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7738,
        "urn": "urn:li:region:7738",
        "states": []
    },
    {
        "name": {
            "value": "Cheongsong-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7739,
        "urn": "urn:li:region:7739",
        "states": []
    },
    {
        "name": {
            "value": "Yeongju, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7740,
        "urn": "urn:li:region:7740",
        "states": []
    },
    {
        "name": {
            "value": "Gunwi-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7741,
        "urn": "urn:li:region:7741",
        "states": []
    },
    {
        "name": {
            "value": "Mungyeong, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7742,
        "urn": "urn:li:region:7742",
        "states": []
    },
    {
        "name": {
            "value": "Seongju-gun, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7743,
        "urn": "urn:li:region:7743",
        "states": []
    },
    {
        "name": {
            "value": "Pohang, Gyeongsangbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7744,
        "urn": "urn:li:region:7744",
        "states": []
    },
    {
        "name": {
            "value": "Suyeong-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7745,
        "urn": "urn:li:region:7745",
        "states": []
    },
    {
        "name": {
            "value": "Yeonje-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7746,
        "urn": "urn:li:region:7746",
        "states": []
    },
    {
        "name": {
            "value": "Jung-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7747,
        "urn": "urn:li:region:7747",
        "states": []
    },
    {
        "name": {
            "value": "Saha-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7748,
        "urn": "urn:li:region:7748",
        "states": []
    },
    {
        "name": {
            "value": "Gijang-gun, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7749,
        "urn": "urn:li:region:7749",
        "states": []
    },
    {
        "name": {
            "value": "Buk-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7750,
        "urn": "urn:li:region:7750",
        "states": []
    },
    {
        "name": {
            "value": "Seo-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7751,
        "urn": "urn:li:region:7751",
        "states": []
    },
    {
        "name": {
            "value": "Geumjeong-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7752,
        "urn": "urn:li:region:7752",
        "states": []
    },
    {
        "name": {
            "value": "Dong-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7753,
        "urn": "urn:li:region:7753",
        "states": []
    },
    {
        "name": {
            "value": "Busanjin-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7754,
        "urn": "urn:li:region:7754",
        "states": []
    },
    {
        "name": {
            "value": "Sasang-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7755,
        "urn": "urn:li:region:7755",
        "states": []
    },
    {
        "name": {
            "value": "Nam-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7756,
        "urn": "urn:li:region:7756",
        "states": []
    },
    {
        "name": {
            "value": "Yeongdo-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7757,
        "urn": "urn:li:region:7757",
        "states": []
    },
    {
        "name": {
            "value": "Gangseo-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7758,
        "urn": "urn:li:region:7758",
        "states": []
    },
    {
        "name": {
            "value": "Haeundae-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7759,
        "urn": "urn:li:region:7759",
        "states": []
    },
    {
        "name": {
            "value": "Dongrae-gu, Busan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7760,
        "urn": "urn:li:region:7760",
        "states": []
    },
    {
        "name": {
            "value": "Namdong-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7761,
        "urn": "urn:li:region:7761",
        "states": []
    },
    {
        "name": {
            "value": "Jung-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7762,
        "urn": "urn:li:region:7762",
        "states": []
    },
    {
        "name": {
            "value": "Seo-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7763,
        "urn": "urn:li:region:7763",
        "states": []
    },
    {
        "name": {
            "value": "Gyeyang-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7764,
        "urn": "urn:li:region:7764",
        "states": []
    },
    {
        "name": {
            "value": "Ongjin-gun, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7765,
        "urn": "urn:li:region:7765",
        "states": []
    },
    {
        "name": {
            "value": "Dong-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7766,
        "urn": "urn:li:region:7766",
        "states": []
    },
    {
        "name": {
            "value": "Ganghwa-gun, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7767,
        "urn": "urn:li:region:7767",
        "states": []
    },
    {
        "name": {
            "value": "Nam-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7768,
        "urn": "urn:li:region:7768",
        "states": []
    },
    {
        "name": {
            "value": "Yeonsu-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7769,
        "urn": "urn:li:region:7769",
        "states": []
    },
    {
        "name": {
            "value": "Bupyeong-gu, Incheon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7770,
        "urn": "urn:li:region:7770",
        "states": []
    },
    {
        "name": {
            "value": "Imsil-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7771,
        "urn": "urn:li:region:7771",
        "states": []
    },
    {
        "name": {
            "value": "Gochang-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7772,
        "urn": "urn:li:region:7772",
        "states": []
    },
    {
        "name": {
            "value": "Namwon, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7773,
        "urn": "urn:li:region:7773",
        "states": []
    },
    {
        "name": {
            "value": "Jeongeup, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7774,
        "urn": "urn:li:region:7774",
        "states": []
    },
    {
        "name": {
            "value": "Jeonju, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7775,
        "urn": "urn:li:region:7775",
        "states": []
    },
    {
        "name": {
            "value": "Jangsu-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7777,
        "urn": "urn:li:region:7777",
        "states": []
    },
    {
        "name": {
            "value": "Iksan, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7778,
        "urn": "urn:li:region:7778",
        "states": []
    },
    {
        "name": {
            "value": "Buan-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7779,
        "urn": "urn:li:region:7779",
        "states": []
    },
    {
        "name": {
            "value": "Gimje, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7780,
        "urn": "urn:li:region:7780",
        "states": []
    },
    {
        "name": {
            "value": "Sunchang-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7781,
        "urn": "urn:li:region:7781",
        "states": []
    },
    {
        "name": {
            "value": "Gunsan, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7782,
        "urn": "urn:li:region:7782",
        "states": []
    },
    {
        "name": {
            "value": "Jinan-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7783,
        "urn": "urn:li:region:7783",
        "states": []
    },
    {
        "name": {
            "value": "Wanju-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7784,
        "urn": "urn:li:region:7784",
        "states": []
    },
    {
        "name": {
            "value": "Muju-gun, Jeollabuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7785,
        "urn": "urn:li:region:7785",
        "states": []
    },
    {
        "name": {
            "value": "Jung-gu, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7786,
        "urn": "urn:li:region:7786",
        "states": []
    },
    {
        "name": {
            "value": "Buk-gu, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7787,
        "urn": "urn:li:region:7787",
        "states": []
    },
    {
        "name": {
            "value": "Seo-gu, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7788,
        "urn": "urn:li:region:7788",
        "states": []
    },
    {
        "name": {
            "value": "Dalseo-gu, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7789,
        "urn": "urn:li:region:7789",
        "states": []
    },
    {
        "name": {
            "value": "Dong-gu, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7790,
        "urn": "urn:li:region:7790",
        "states": []
    },
    {
        "name": {
            "value": "Nam-gu, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7791,
        "urn": "urn:li:region:7791",
        "states": []
    },
    {
        "name": {
            "value": "Dalseong-gun, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7792,
        "urn": "urn:li:region:7792",
        "states": []
    },
    {
        "name": {
            "value": "Suseong-gu, Daegu, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7793,
        "urn": "urn:li:region:7793",
        "states": []
    },
    {
        "name": {
            "value": "Tongyeong, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7794,
        "urn": "urn:li:region:7794",
        "states": []
    },
    {
        "name": {
            "value": "Changnyeong-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7795,
        "urn": "urn:li:region:7795",
        "states": []
    },
    {
        "name": {
            "value": "Namhae-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7796,
        "urn": "urn:li:region:7796",
        "states": []
    },
    {
        "name": {
            "value": "Hadong-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7797,
        "urn": "urn:li:region:7797",
        "states": []
    },
    {
        "name": {
            "value": "Geoje, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7798,
        "urn": "urn:li:region:7798",
        "states": []
    },
    {
        "name": {
            "value": "Hapcheon-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7799,
        "urn": "urn:li:region:7799",
        "states": []
    },
    {
        "name": {
            "value": "Miryang, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7800,
        "urn": "urn:li:region:7800",
        "states": []
    },
    {
        "name": {
            "value": "Jinju, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7801,
        "urn": "urn:li:region:7801",
        "states": []
    },
    {
        "name": {
            "value": "Yangsan, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7802,
        "urn": "urn:li:region:7802",
        "states": []
    },
    {
        "name": {
            "value": "Gimhae, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7803,
        "urn": "urn:li:region:7803",
        "states": []
    },
    {
        "name": {
            "value": "Haman-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7804,
        "urn": "urn:li:region:7804",
        "states": []
    },
    {
        "name": {
            "value": "Uiryeong-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7805,
        "urn": "urn:li:region:7805",
        "states": []
    },
    {
        "name": {
            "value": "Geochang-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7806,
        "urn": "urn:li:region:7806",
        "states": []
    },
    {
        "name": {
            "value": "Sacheon, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7807,
        "urn": "urn:li:region:7807",
        "states": []
    },
    {
        "name": {
            "value": "Hamyang-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7808,
        "urn": "urn:li:region:7808",
        "states": []
    },
    {
        "name": {
            "value": "Goseong-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7809,
        "urn": "urn:li:region:7809",
        "states": []
    },
    {
        "name": {
            "value": "Sancheong-gun, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7810,
        "urn": "urn:li:region:7810",
        "states": []
    },
    {
        "name": {
            "value": "Jinhae, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7811,
        "urn": "urn:li:region:7811",
        "states": []
    },
    {
        "name": {
            "value": "Changwon, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7812,
        "urn": "urn:li:region:7812",
        "states": []
    },
    {
        "name": {
            "value": "Masan, Gyeongsangnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7813,
        "urn": "urn:li:region:7813",
        "states": []
    },
    {
        "name": {
            "value": "Yuseong-gu, Daejeon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7814,
        "urn": "urn:li:region:7814",
        "states": []
    },
    {
        "name": {
            "value": "Jung-gu, Daejeon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7815,
        "urn": "urn:li:region:7815",
        "states": []
    },
    {
        "name": {
            "value": "Daedeok-gu, Daejeon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7816,
        "urn": "urn:li:region:7816",
        "states": []
    },
    {
        "name": {
            "value": "Seo-gu, Daejeon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7817,
        "urn": "urn:li:region:7817",
        "states": []
    },
    {
        "name": {
            "value": "Dong-gu, Daejeon, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7818,
        "urn": "urn:li:region:7818",
        "states": []
    },
    {
        "name": {
            "value": "Jung-gu, Ulsan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7819,
        "urn": "urn:li:region:7819",
        "states": []
    },
    {
        "name": {
            "value": "Ulju-gun, Ulsan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7820,
        "urn": "urn:li:region:7820",
        "states": []
    },
    {
        "name": {
            "value": "Buk-gu, Ulsan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7821,
        "urn": "urn:li:region:7821",
        "states": []
    },
    {
        "name": {
            "value": "Dong-gu, Ulsan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7822,
        "urn": "urn:li:region:7822",
        "states": []
    },
    {
        "name": {
            "value": "Nam-gu, Ulsan, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7823,
        "urn": "urn:li:region:7823",
        "states": []
    },
    {
        "name": {
            "value": "Yongin, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7824,
        "urn": "urn:li:region:7824",
        "states": []
    },
    {
        "name": {
            "value": "Bucheon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7825,
        "urn": "urn:li:region:7825",
        "states": []
    },
    {
        "name": {
            "value": "Dongducheon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7826,
        "urn": "urn:li:region:7826",
        "states": []
    },
    {
        "name": {
            "value": "Yangpyeong-gun, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7827,
        "urn": "urn:li:region:7827",
        "states": []
    },
    {
        "name": {
            "value": "Gwacheon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7828,
        "urn": "urn:li:region:7828",
        "states": []
    },
    {
        "name": {
            "value": "Gimpo, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7829,
        "urn": "urn:li:region:7829",
        "states": []
    },
    {
        "name": {
            "value": "Suwon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7830,
        "urn": "urn:li:region:7830",
        "states": []
    },
    {
        "name": {
            "value": "Osan, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7831,
        "urn": "urn:li:region:7831",
        "states": []
    },
    {
        "name": {
            "value": "Yangju, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7832,
        "urn": "urn:li:region:7832",
        "states": []
    },
    {
        "name": {
            "value": "Hanam, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7833,
        "urn": "urn:li:region:7833",
        "states": []
    },
    {
        "name": {
            "value": "Pocheon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7834,
        "urn": "urn:li:region:7834",
        "states": []
    },
    {
        "name": {
            "value": "Hwaseong, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7835,
        "urn": "urn:li:region:7835",
        "states": []
    },
    {
        "name": {
            "value": "Yeoncheon-gun, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7836,
        "urn": "urn:li:region:7836",
        "states": []
    },
    {
        "name": {
            "value": "Seongnam, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7837,
        "urn": "urn:li:region:7837",
        "states": []
    },
    {
        "name": {
            "value": "Anyang, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7838,
        "urn": "urn:li:region:7838",
        "states": []
    },
    {
        "name": {
            "value": "Uiwang, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7839,
        "urn": "urn:li:region:7839",
        "states": []
    },
    {
        "name": {
            "value": "Guri, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7840,
        "urn": "urn:li:region:7840",
        "states": []
    },
    {
        "name": {
            "value": "Suwon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7841,
        "urn": "urn:li:region:7841",
        "states": []
    },
    {
        "name": {
            "value": "Namyangju, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7842,
        "urn": "urn:li:region:7842",
        "states": []
    },
    {
        "name": {
            "value": "Seongnam, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7843,
        "urn": "urn:li:region:7843",
        "states": []
    },
    {
        "name": {
            "value": "Bucheon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7844,
        "urn": "urn:li:region:7844",
        "states": []
    },
    {
        "name": {
            "value": "Suwon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7845,
        "urn": "urn:li:region:7845",
        "states": []
    },
    {
        "name": {
            "value": "Yongin, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7846,
        "urn": "urn:li:region:7846",
        "states": []
    },
    {
        "name": {
            "value": "Goyang, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7847,
        "urn": "urn:li:region:7847",
        "states": []
    },
    {
        "name": {
            "value": "Gapyeong-gun, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7848,
        "urn": "urn:li:region:7848",
        "states": []
    },
    {
        "name": {
            "value": "Yeoju-gun, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7849,
        "urn": "urn:li:region:7849",
        "states": []
    },
    {
        "name": {
            "value": "Anyang, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7850,
        "urn": "urn:li:region:7850",
        "states": []
    },
    {
        "name": {
            "value": "Suwon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7851,
        "urn": "urn:li:region:7851",
        "states": []
    },
    {
        "name": {
            "value": "Anseong, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7852,
        "urn": "urn:li:region:7852",
        "states": []
    },
    {
        "name": {
            "value": "Yongin, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7853,
        "urn": "urn:li:region:7853",
        "states": []
    },
    {
        "name": {
            "value": "Pyeongtaek, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7854,
        "urn": "urn:li:region:7854",
        "states": []
    },
    {
        "name": {
            "value": "Uijeongbu, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7855,
        "urn": "urn:li:region:7855",
        "states": []
    },
    {
        "name": {
            "value": "Ansan, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7856,
        "urn": "urn:li:region:7856",
        "states": []
    },
    {
        "name": {
            "value": "Bucheon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7857,
        "urn": "urn:li:region:7857",
        "states": []
    },
    {
        "name": {
            "value": "Seongnam, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7858,
        "urn": "urn:li:region:7858",
        "states": []
    },
    {
        "name": {
            "value": "Icheon, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7859,
        "urn": "urn:li:region:7859",
        "states": []
    },
    {
        "name": {
            "value": "Paju, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7860,
        "urn": "urn:li:region:7860",
        "states": []
    },
    {
        "name": {
            "value": "Goyang, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7861,
        "urn": "urn:li:region:7861",
        "states": []
    },
    {
        "name": {
            "value": "Siheung, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7862,
        "urn": "urn:li:region:7862",
        "states": []
    },
    {
        "name": {
            "value": "Goyang, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7863,
        "urn": "urn:li:region:7863",
        "states": []
    },
    {
        "name": {
            "value": "Gunpo, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7865,
        "urn": "urn:li:region:7865",
        "states": []
    },
    {
        "name": {
            "value": "Gwangmyeong, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7866,
        "urn": "urn:li:region:7866",
        "states": []
    },
    {
        "name": {
            "value": "Gwangju, Gyeonggi-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7867,
        "urn": "urn:li:region:7867",
        "states": []
    },
    {
        "name": {
            "value": "Eumseong-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7868,
        "urn": "urn:li:region:7868",
        "states": []
    },
    {
        "name": {
            "value": "Cheongwon-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7869,
        "urn": "urn:li:region:7869",
        "states": []
    },
    {
        "name": {
            "value": "Yeongdong-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7870,
        "urn": "urn:li:region:7870",
        "states": []
    },
    {
        "name": {
            "value": "Chungju, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7871,
        "urn": "urn:li:region:7871",
        "states": []
    },
    {
        "name": {
            "value": "Jincheon-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7872,
        "urn": "urn:li:region:7872",
        "states": []
    },
    {
        "name": {
            "value": "Jecheon, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7873,
        "urn": "urn:li:region:7873",
        "states": []
    },
    {
        "name": {
            "value": "Okcheon-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7874,
        "urn": "urn:li:region:7874",
        "states": []
    },
    {
        "name": {
            "value": "Cheongju, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7875,
        "urn": "urn:li:region:7875",
        "states": []
    },
    {
        "name": {
            "value": "Jeungpyeong-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7876,
        "urn": "urn:li:region:7876",
        "states": []
    },
    {
        "name": {
            "value": "Boeun-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7877,
        "urn": "urn:li:region:7877",
        "states": []
    },
    {
        "name": {
            "value": "Cheongju, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7878,
        "urn": "urn:li:region:7878",
        "states": []
    },
    {
        "name": {
            "value": "Danyang-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7879,
        "urn": "urn:li:region:7879",
        "states": []
    },
    {
        "name": {
            "value": "Goesan-gun, Chungcheongbuk-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7880,
        "urn": "urn:li:region:7880",
        "states": []
    },
    {
        "name": {
            "value": "Damyang-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7881,
        "urn": "urn:li:region:7881",
        "states": []
    },
    {
        "name": {
            "value": "Muan-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7882,
        "urn": "urn:li:region:7882",
        "states": []
    },
    {
        "name": {
            "value": "Wando-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7883,
        "urn": "urn:li:region:7883",
        "states": []
    },
    {
        "name": {
            "value": "Naju, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7884,
        "urn": "urn:li:region:7884",
        "states": []
    },
    {
        "name": {
            "value": "Sinan-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7885,
        "urn": "urn:li:region:7885",
        "states": []
    },
    {
        "name": {
            "value": "Jangheung-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7886,
        "urn": "urn:li:region:7886",
        "states": []
    },
    {
        "name": {
            "value": "Yeonggwang-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7887,
        "urn": "urn:li:region:7887",
        "states": []
    },
    {
        "name": {
            "value": "Yeongam-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7888,
        "urn": "urn:li:region:7888",
        "states": []
    },
    {
        "name": {
            "value": "Gokseong-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7889,
        "urn": "urn:li:region:7889",
        "states": []
    },
    {
        "name": {
            "value": "Mokpo, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7890,
        "urn": "urn:li:region:7890",
        "states": []
    },
    {
        "name": {
            "value": "Hwasun-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7891,
        "urn": "urn:li:region:7891",
        "states": []
    },
    {
        "name": {
            "value": "Gurye-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7892,
        "urn": "urn:li:region:7892",
        "states": []
    },
    {
        "name": {
            "value": "Haenam-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7893,
        "urn": "urn:li:region:7893",
        "states": []
    },
    {
        "name": {
            "value": "Boseong-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7894,
        "urn": "urn:li:region:7894",
        "states": []
    },
    {
        "name": {
            "value": "Gwangyang, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7895,
        "urn": "urn:li:region:7895",
        "states": []
    },
    {
        "name": {
            "value": "Jindo-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7896,
        "urn": "urn:li:region:7896",
        "states": []
    },
    {
        "name": {
            "value": "Goheung-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7897,
        "urn": "urn:li:region:7897",
        "states": []
    },
    {
        "name": {
            "value": "Jangseong-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7898,
        "urn": "urn:li:region:7898",
        "states": []
    },
    {
        "name": {
            "value": "Suncheon, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7899,
        "urn": "urn:li:region:7899",
        "states": []
    },
    {
        "name": {
            "value": "Yeosu, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7900,
        "urn": "urn:li:region:7900",
        "states": []
    },
    {
        "name": {
            "value": "Hampyeong-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7901,
        "urn": "urn:li:region:7901",
        "states": []
    },
    {
        "name": {
            "value": "Gangjin-gun, Jeollanam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7902,
        "urn": "urn:li:region:7902",
        "states": []
    },
    {
        "name": {
            "value": "Hongcheon-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7903,
        "urn": "urn:li:region:7903",
        "states": []
    },
    {
        "name": {
            "value": "Gangreung, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7904,
        "urn": "urn:li:region:7904",
        "states": []
    },
    {
        "name": {
            "value": "Yeongwol-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7905,
        "urn": "urn:li:region:7905",
        "states": []
    },
    {
        "name": {
            "value": "Wonju, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7906,
        "urn": "urn:li:region:7906",
        "states": []
    },
    {
        "name": {
            "value": "Chuncheon, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7907,
        "urn": "urn:li:region:7907",
        "states": []
    },
    {
        "name": {
            "value": "Yangyang-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7908,
        "urn": "urn:li:region:7908",
        "states": []
    },
    {
        "name": {
            "value": "Inje-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7909,
        "urn": "urn:li:region:7909",
        "states": []
    },
    {
        "name": {
            "value": "Hoengseong-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7910,
        "urn": "urn:li:region:7910",
        "states": []
    },
    {
        "name": {
            "value": "Hwacheon-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7911,
        "urn": "urn:li:region:7911",
        "states": []
    },
    {
        "name": {
            "value": "Goseong-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7912,
        "urn": "urn:li:region:7912",
        "states": []
    },
    {
        "name": {
            "value": "Pyeongchang-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7913,
        "urn": "urn:li:region:7913",
        "states": []
    },
    {
        "name": {
            "value": "Taebaek, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7914,
        "urn": "urn:li:region:7914",
        "states": []
    },
    {
        "name": {
            "value": "Donghae, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7915,
        "urn": "urn:li:region:7915",
        "states": []
    },
    {
        "name": {
            "value": "Sokcho, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7916,
        "urn": "urn:li:region:7916",
        "states": []
    },
    {
        "name": {
            "value": "Samcheok, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7917,
        "urn": "urn:li:region:7917",
        "states": []
    },
    {
        "name": {
            "value": "Jeongseon-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7918,
        "urn": "urn:li:region:7918",
        "states": []
    },
    {
        "name": {
            "value": "Yanggu-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7919,
        "urn": "urn:li:region:7919",
        "states": []
    },
    {
        "name": {
            "value": "Cheorwon-gun, Gangwon-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7920,
        "urn": "urn:li:region:7920",
        "states": []
    },
    {
        "name": {
            "value": "Asan, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7921,
        "urn": "urn:li:region:7921",
        "states": []
    },
    {
        "name": {
            "value": "Geumsan-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7922,
        "urn": "urn:li:region:7922",
        "states": []
    },
    {
        "name": {
            "value": "Gongju, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7923,
        "urn": "urn:li:region:7923",
        "states": []
    },
    {
        "name": {
            "value": "Hongseong-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7924,
        "urn": "urn:li:region:7924",
        "states": []
    },
    {
        "name": {
            "value": "Seocheon-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7925,
        "urn": "urn:li:region:7925",
        "states": []
    },
    {
        "name": {
            "value": "Boryeong, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7926,
        "urn": "urn:li:region:7926",
        "states": []
    },
    {
        "name": {
            "value": "Dangjin-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7927,
        "urn": "urn:li:region:7927",
        "states": []
    },
    {
        "name": {
            "value": "Seosan, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7928,
        "urn": "urn:li:region:7928",
        "states": []
    },
    {
        "name": {
            "value": "Gyeryong, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7929,
        "urn": "urn:li:region:7929",
        "states": []
    },
    {
        "name": {
            "value": "Nonsan, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7930,
        "urn": "urn:li:region:7930",
        "states": []
    },
    {
        "name": {
            "value": "Yesan-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7931,
        "urn": "urn:li:region:7931",
        "states": []
    },
    {
        "name": {
            "value": "Buyeo-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7932,
        "urn": "urn:li:region:7932",
        "states": []
    },
    {
        "name": {
            "value": "Cheonan, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7933,
        "urn": "urn:li:region:7933",
        "states": []
    },
    {
        "name": {
            "value": "Taean-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7935,
        "urn": "urn:li:region:7935",
        "states": []
    },
    {
        "name": {
            "value": "Yeongi-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7936,
        "urn": "urn:li:region:7936",
        "states": []
    },
    {
        "name": {
            "value": "Cheongyang-gun, Chungcheongnam-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7937,
        "urn": "urn:li:region:7937",
        "states": []
    },
    {
        "name": {
            "value": "Buk-gu, Gwangju, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7938,
        "urn": "urn:li:region:7938",
        "states": []
    },
    {
        "name": {
            "value": "Seo-gu, Gwangju, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7939,
        "urn": "urn:li:region:7939",
        "states": []
    },
    {
        "name": {
            "value": "Dong-gu, Gwangju, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7940,
        "urn": "urn:li:region:7940",
        "states": []
    },
    {
        "name": {
            "value": "Gwangsan-gu, Gwangju, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7941,
        "urn": "urn:li:region:7941",
        "states": []
    },
    {
        "name": {
            "value": "Nam-gu, Gwangju, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7942,
        "urn": "urn:li:region:7942",
        "states": []
    },
    {
        "name": {
            "value": "Seogwipo, Jeju-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7943,
        "urn": "urn:li:region:7943",
        "states": []
    },
    {
        "name": {
            "value": "Jeju, Jeju-do, Korea"
        },
        "country": "urn:li:country:kr",
        "id": 7944,
        "urn": "urn:li:region:7944",
        "states": []
    },
    {
        "name": {
            "value": "Selangor, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7945,
        "urn": "urn:li:region:7945",
        "states": [
            "urn:li:state:(urn:li:country:my,SGR)",
            "urn:li:state:(urn:li:country:my,JHR)"
        ]
    },
    {
        "name": {
            "value": "Labuan, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7946,
        "urn": "urn:li:region:7946",
        "states": [
            "urn:li:state:(urn:li:country:my,LBN)",
            "urn:li:state:(urn:li:country:my,SBH)"
        ]
    },
    {
        "name": {
            "value": "Perlis, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7947,
        "urn": "urn:li:region:7947",
        "states": [
            "urn:li:state:(urn:li:country:my,PLS)"
        ]
    },
    {
        "name": {
            "value": "Negeri Sembilan, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7948,
        "urn": "urn:li:region:7948",
        "states": [
            "urn:li:state:(urn:li:country:my,NSN)",
            "urn:li:state:(urn:li:country:my,PHG)"
        ]
    },
    {
        "name": {
            "value": "Putra Jaya, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7949,
        "urn": "urn:li:region:7949",
        "states": [
            "urn:li:state:(urn:li:country:my,PJY)",
            "urn:li:state:(urn:li:country:my,SGR)"
        ]
    },
    {
        "name": {
            "value": "Sabah, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7950,
        "urn": "urn:li:region:7950",
        "states": [
            "urn:li:state:(urn:li:country:my,SBH)"
        ]
    },
    {
        "name": {
            "value": "Terengganu, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7951,
        "urn": "urn:li:region:7951",
        "states": [
            "urn:li:state:(urn:li:country:my,TRG)"
        ]
    },
    {
        "name": {
            "value": "Johor, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7952,
        "urn": "urn:li:region:7952",
        "states": [
            "urn:li:state:(urn:li:country:my,JHR)",
            "urn:li:state:(urn:li:country:my,KUL)"
        ]
    },
    {
        "name": {
            "value": "Kedah, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7953,
        "urn": "urn:li:region:7953",
        "states": [
            "urn:li:state:(urn:li:country:my,KDH)",
            "urn:li:state:(urn:li:country:my,PLS)"
        ]
    },
    {
        "name": {
            "value": "Malacca, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7954,
        "urn": "urn:li:region:7954",
        "states": [
            "urn:li:state:(urn:li:country:my,MLK)",
            "urn:li:state:(urn:li:country:my,NSN)"
        ]
    },
    {
        "name": {
            "value": "Sarawak, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7955,
        "urn": "urn:li:region:7955",
        "states": [
            "urn:li:state:(urn:li:country:my,SRW)"
        ]
    },
    {
        "name": {
            "value": "Perak, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7956,
        "urn": "urn:li:region:7956",
        "states": [
            "urn:li:state:(urn:li:country:my,PRK)"
        ]
    },
    {
        "name": {
            "value": "Pahang, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7957,
        "urn": "urn:li:region:7957",
        "states": [
            "urn:li:state:(urn:li:country:my,PHG)",
            "urn:li:state:(urn:li:country:my,SGR)",
            "urn:li:state:(urn:li:country:my,PRK)"
        ]
    },
    {
        "name": {
            "value": "Kelantan, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7958,
        "urn": "urn:li:region:7958",
        "states": [
            "urn:li:state:(urn:li:country:my,KTN)"
        ]
    },
    {
        "name": {
            "value": "Penang, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7959,
        "urn": "urn:li:region:7959",
        "states": [
            "urn:li:state:(urn:li:country:my,PNG)"
        ]
    },
    {
        "name": {
            "value": "Kuala Lumpur, Malaysia"
        },
        "country": "urn:li:country:my",
        "id": 7960,
        "urn": "urn:li:region:7960",
        "states": [
            "urn:li:state:(urn:li:country:my,KUL)",
            "urn:li:state:(urn:li:country:my,SGR)"
        ]
    },
    {
        "name": {
            "value": "Rezina Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7961,
        "urn": "urn:li:region:7961",
        "states": []
    },
    {
        "name": {
            "value": "Floresti Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7962,
        "urn": "urn:li:region:7962",
        "states": []
    },
    {
        "name": {
            "value": "Telenesti Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7963,
        "urn": "urn:li:region:7963",
        "states": []
    },
    {
        "name": {
            "value": "Soroca Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7964,
        "urn": "urn:li:region:7964",
        "states": []
    },
    {
        "name": {
            "value": "Briceni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7965,
        "urn": "urn:li:region:7965",
        "states": []
    },
    {
        "name": {
            "value": "Bender Municipality, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7966,
        "urn": "urn:li:region:7966",
        "states": []
    },
    {
        "name": {
            "value": "Balti Municipality, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7967,
        "urn": "urn:li:region:7967",
        "states": []
    },
    {
        "name": {
            "value": "Ialoveni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7968,
        "urn": "urn:li:region:7968",
        "states": []
    },
    {
        "name": {
            "value": "Falesti Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7969,
        "urn": "urn:li:region:7969",
        "states": []
    },
    {
        "name": {
            "value": "Ocnita Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7970,
        "urn": "urn:li:region:7970",
        "states": []
    },
    {
        "name": {
            "value": "Criuleni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7971,
        "urn": "urn:li:region:7971",
        "states": []
    },
    {
        "name": {
            "value": "Cantemir Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7972,
        "urn": "urn:li:region:7972",
        "states": []
    },
    {
        "name": {
            "value": "Leova Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7973,
        "urn": "urn:li:region:7973",
        "states": []
    },
    {
        "name": {
            "value": "Drochia Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7974,
        "urn": "urn:li:region:7974",
        "states": []
    },
    {
        "name": {
            "value": "Straseni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7975,
        "urn": "urn:li:region:7975",
        "states": []
    },
    {
        "name": {
            "value": "Hancesti Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7976,
        "urn": "urn:li:region:7976",
        "states": []
    },
    {
        "name": {
            "value": "Dubasari Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7977,
        "urn": "urn:li:region:7977",
        "states": []
    },
    {
        "name": {
            "value": "Sangerei Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7978,
        "urn": "urn:li:region:7978",
        "states": []
    },
    {
        "name": {
            "value": "Taraclia Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7979,
        "urn": "urn:li:region:7979",
        "states": []
    },
    {
        "name": {
            "value": "Ungheni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7980,
        "urn": "urn:li:region:7980",
        "states": []
    },
    {
        "name": {
            "value": "Anenii Noi Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7981,
        "urn": "urn:li:region:7981",
        "states": []
    },
    {
        "name": {
            "value": "Glodeni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7982,
        "urn": "urn:li:region:7982",
        "states": []
    },
    {
        "name": {
            "value": "Cahul Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7983,
        "urn": "urn:li:region:7983",
        "states": []
    },
    {
        "name": {
            "value": "Calarasi Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7984,
        "urn": "urn:li:region:7984",
        "states": []
    },
    {
        "name": {
            "value": "Soldanesti Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7985,
        "urn": "urn:li:region:7985",
        "states": []
    },
    {
        "name": {
            "value": "Edinet Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7986,
        "urn": "urn:li:region:7986",
        "states": []
    },
    {
        "name": {
            "value": "Donduseni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7987,
        "urn": "urn:li:region:7987",
        "states": []
    },
    {
        "name": {
            "value": "ATU Gagauzia, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7988,
        "urn": "urn:li:region:7988",
        "states": []
    },
    {
        "name": {
            "value": "Cimislia Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7989,
        "urn": "urn:li:region:7989",
        "states": []
    },
    {
        "name": {
            "value": "Stefan Voda Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7990,
        "urn": "urn:li:region:7990",
        "states": []
    },
    {
        "name": {
            "value": "ATU Transnistria, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7991,
        "urn": "urn:li:region:7991",
        "states": []
    },
    {
        "name": {
            "value": "Causeni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7992,
        "urn": "urn:li:region:7992",
        "states": []
    },
    {
        "name": {
            "value": "Basarabeasca Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7993,
        "urn": "urn:li:region:7993",
        "states": []
    },
    {
        "name": {
            "value": "Chisinau Municipality, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7994,
        "urn": "urn:li:region:7994",
        "states": []
    },
    {
        "name": {
            "value": "Nisporeni Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7995,
        "urn": "urn:li:region:7995",
        "states": []
    },
    {
        "name": {
            "value": "Rascani Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7996,
        "urn": "urn:li:region:7996",
        "states": []
    },
    {
        "name": {
            "value": "Orhei Region, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7997,
        "urn": "urn:li:region:7997",
        "states": []
    },
    {
        "name": {
            "value": "Rezina, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7998,
        "urn": "urn:li:region:7998",
        "states": []
    },
    {
        "name": {
            "value": "Floresti, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 7999,
        "urn": "urn:li:region:7999",
        "states": []
    },
    {
        "name": {
            "value": "Telenesti, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8000,
        "urn": "urn:li:region:8000",
        "states": []
    },
    {
        "name": {
            "value": "Soroca, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8001,
        "urn": "urn:li:region:8001",
        "states": []
    },
    {
        "name": {
            "value": "Briceni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8002,
        "urn": "urn:li:region:8002",
        "states": []
    },
    {
        "name": {
            "value": "Bender, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8003,
        "urn": "urn:li:region:8003",
        "states": []
    },
    {
        "name": {
            "value": "Balti, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8004,
        "urn": "urn:li:region:8004",
        "states": []
    },
    {
        "name": {
            "value": "Ialoveni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8005,
        "urn": "urn:li:region:8005",
        "states": []
    },
    {
        "name": {
            "value": "Falesti, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8006,
        "urn": "urn:li:region:8006",
        "states": []
    },
    {
        "name": {
            "value": "Ocnita, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8007,
        "urn": "urn:li:region:8007",
        "states": []
    },
    {
        "name": {
            "value": "Criuleni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8008,
        "urn": "urn:li:region:8008",
        "states": []
    },
    {
        "name": {
            "value": "Cantemir, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8009,
        "urn": "urn:li:region:8009",
        "states": []
    },
    {
        "name": {
            "value": "Leova, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8010,
        "urn": "urn:li:region:8010",
        "states": []
    },
    {
        "name": {
            "value": "Drochia, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8011,
        "urn": "urn:li:region:8011",
        "states": []
    },
    {
        "name": {
            "value": "Straseni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8012,
        "urn": "urn:li:region:8012",
        "states": []
    },
    {
        "name": {
            "value": "Hancesti, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8013,
        "urn": "urn:li:region:8013",
        "states": []
    },
    {
        "name": {
            "value": "Sangerei, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8014,
        "urn": "urn:li:region:8014",
        "states": []
    },
    {
        "name": {
            "value": "Taraclia, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8015,
        "urn": "urn:li:region:8015",
        "states": []
    },
    {
        "name": {
            "value": "Ungheni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8016,
        "urn": "urn:li:region:8016",
        "states": []
    },
    {
        "name": {
            "value": "Anenii Noi, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8017,
        "urn": "urn:li:region:8017",
        "states": []
    },
    {
        "name": {
            "value": "Glodeni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8018,
        "urn": "urn:li:region:8018",
        "states": []
    },
    {
        "name": {
            "value": "Cahul, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8019,
        "urn": "urn:li:region:8019",
        "states": []
    },
    {
        "name": {
            "value": "Calarasi, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8020,
        "urn": "urn:li:region:8020",
        "states": []
    },
    {
        "name": {
            "value": "Soldanesti, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8021,
        "urn": "urn:li:region:8021",
        "states": []
    },
    {
        "name": {
            "value": "Edinet, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8022,
        "urn": "urn:li:region:8022",
        "states": []
    },
    {
        "name": {
            "value": "Donduseni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8023,
        "urn": "urn:li:region:8023",
        "states": []
    },
    {
        "name": {
            "value": "Comrat, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8024,
        "urn": "urn:li:region:8024",
        "states": []
    },
    {
        "name": {
            "value": "Cimislia, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8025,
        "urn": "urn:li:region:8025",
        "states": []
    },
    {
        "name": {
            "value": "Stefan Voda, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8026,
        "urn": "urn:li:region:8026",
        "states": []
    },
    {
        "name": {
            "value": "Tiraspol, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8027,
        "urn": "urn:li:region:8027",
        "states": []
    },
    {
        "name": {
            "value": "Causeni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8028,
        "urn": "urn:li:region:8028",
        "states": []
    },
    {
        "name": {
            "value": "Basarabeasca, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8029,
        "urn": "urn:li:region:8029",
        "states": []
    },
    {
        "name": {
            "value": "Chisinau, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8030,
        "urn": "urn:li:region:8030",
        "states": []
    },
    {
        "name": {
            "value": "Nisporeni, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8031,
        "urn": "urn:li:region:8031",
        "states": []
    },
    {
        "name": {
            "value": "Rascani, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8032,
        "urn": "urn:li:region:8032",
        "states": []
    },
    {
        "name": {
            "value": "Orhei, Moldova"
        },
        "country": "urn:li:country:md",
        "id": 8033,
        "urn": "urn:li:region:8033",
        "states": []
    },
    {
        "name": {
            "value": "Jamtland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8034,
        "urn": "urn:li:region:8034",
        "states": [
            "urn:li:state:(urn:li:country:se,Z)"
        ]
    },
    {
        "name": {
            "value": "Vastmanland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8035,
        "urn": "urn:li:region:8035",
        "states": [
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Gotland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8036,
        "urn": "urn:li:region:8036",
        "states": []
    },
    {
        "name": {
            "value": "Varmland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8037,
        "urn": "urn:li:region:8037",
        "states": [
            "urn:li:state:(urn:li:country:se,S)"
        ]
    },
    {
        "name": {
            "value": "Blekinge County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8038,
        "urn": "urn:li:region:8038",
        "states": [
            "urn:li:state:(urn:li:country:se,K)",
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Stockholm County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8039,
        "urn": "urn:li:region:8039",
        "states": [
            "urn:li:state:(urn:li:country:se,AB)"
        ]
    },
    {
        "name": {
            "value": "Vasterbotten County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8040,
        "urn": "urn:li:region:8040",
        "states": [
            "urn:li:state:(urn:li:country:se,AC)",
            "urn:li:state:(urn:li:country:se,BD)"
        ]
    },
    {
        "name": {
            "value": "Jonkoping County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8041,
        "urn": "urn:li:region:8041",
        "states": [
            "urn:li:state:(urn:li:country:se,F)",
            "urn:li:state:(urn:li:country:se,E)"
        ]
    },
    {
        "name": {
            "value": "Vasternorrland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8042,
        "urn": "urn:li:region:8042",
        "states": [
            "urn:li:state:(urn:li:country:se,Y)",
            "urn:li:state:(urn:li:country:se,Z)"
        ]
    },
    {
        "name": {
            "value": "Kronoberg County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8043,
        "urn": "urn:li:region:8043",
        "states": [
            "urn:li:state:(urn:li:country:se,G)",
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Skane County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8044,
        "urn": "urn:li:region:8044",
        "states": [
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Kalmar County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8045,
        "urn": "urn:li:region:8045",
        "states": [
            "urn:li:state:(urn:li:country:se,H)",
            "urn:li:state:(urn:li:country:se,G)"
        ]
    },
    {
        "name": {
            "value": "Halland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8046,
        "urn": "urn:li:region:8046",
        "states": [
            "urn:li:state:(urn:li:country:se,N)",
            "urn:li:state:(urn:li:country:se,F)"
        ]
    },
    {
        "name": {
            "value": "Sodermanland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8047,
        "urn": "urn:li:region:8047",
        "states": [
            "urn:li:state:(urn:li:country:se,D)",
            "urn:li:state:(urn:li:country:se,E)"
        ]
    },
    {
        "name": {
            "value": "Norrbotten County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8048,
        "urn": "urn:li:region:8048",
        "states": [
            "urn:li:state:(urn:li:country:se,BD)"
        ]
    },
    {
        "name": {
            "value": "Uppsala County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8049,
        "urn": "urn:li:region:8049",
        "states": [
            "urn:li:state:(urn:li:country:se,C)",
            "urn:li:state:(urn:li:country:se,AB)",
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Dalarna County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8050,
        "urn": "urn:li:region:8050",
        "states": [
            "urn:li:state:(urn:li:country:se,W)"
        ]
    },
    {
        "name": {
            "value": "Gavleborg County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8051,
        "urn": "urn:li:region:8051",
        "states": [
            "urn:li:state:(urn:li:country:se,X)",
            "urn:li:state:(urn:li:country:se,Z)"
        ]
    },
    {
        "name": {
            "value": "Vastra Gotaland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8052,
        "urn": "urn:li:region:8052",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Orebro County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8053,
        "urn": "urn:li:region:8053",
        "states": [
            "urn:li:state:(urn:li:country:se,T)"
        ]
    },
    {
        "name": {
            "value": "Ostergotland County, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8054,
        "urn": "urn:li:region:8054",
        "states": [
            "urn:li:state:(urn:li:country:se,E)",
            "urn:li:state:(urn:li:country:se,H)"
        ]
    },
    {
        "name": {
            "value": "Ostersund, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8055,
        "urn": "urn:li:region:8055",
        "states": [
            "urn:li:state:(urn:li:country:se,Z)",
            "urn:li:state:(urn:li:country:se,Y)"
        ]
    },
    {
        "name": {
            "value": "Vasteras, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8056,
        "urn": "urn:li:region:8056",
        "states": [
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Koping, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8057,
        "urn": "urn:li:region:8057",
        "states": [
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Visby, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8058,
        "urn": "urn:li:region:8058",
        "states": [
            "urn:li:state:(urn:li:country:se,I)"
        ]
    },
    {
        "name": {
            "value": "Karlstad, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8059,
        "urn": "urn:li:region:8059",
        "states": [
            "urn:li:state:(urn:li:country:se,S)",
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Kristinehamn, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8060,
        "urn": "urn:li:region:8060",
        "states": [
            "urn:li:state:(urn:li:country:se,S)",
            "urn:li:state:(urn:li:country:se,O)",
            "urn:li:state:(urn:li:country:se,T)"
        ]
    },
    {
        "name": {
            "value": "Karlskrona, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8061,
        "urn": "urn:li:region:8061",
        "states": [
            "urn:li:state:(urn:li:country:se,K)",
            "urn:li:state:(urn:li:country:se,H)"
        ]
    },
    {
        "name": {
            "value": "Karlshamn, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8062,
        "urn": "urn:li:region:8062",
        "states": [
            "urn:li:state:(urn:li:country:se,K)",
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Ronneby, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8063,
        "urn": "urn:li:region:8063",
        "states": [
            "urn:li:state:(urn:li:country:se,K)",
            "urn:li:state:(urn:li:country:se,H)",
            "urn:li:state:(urn:li:country:se,G)"
        ]
    },
    {
        "name": {
            "value": "Stockholm, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8064,
        "urn": "urn:li:region:8064",
        "states": [
            "urn:li:state:(urn:li:country:se,AB)"
        ]
    },
    {
        "name": {
            "value": "Sodertalje, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8065,
        "urn": "urn:li:region:8065",
        "states": [
            "urn:li:state:(urn:li:country:se,AB)",
            "urn:li:state:(urn:li:country:se,D)"
        ]
    },
    {
        "name": {
            "value": "Umea, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8066,
        "urn": "urn:li:region:8066",
        "states": [
            "urn:li:state:(urn:li:country:se,AC)"
        ]
    },
    {
        "name": {
            "value": "Skelleftea, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8067,
        "urn": "urn:li:region:8067",
        "states": [
            "urn:li:state:(urn:li:country:se,AC)",
            "urn:li:state:(urn:li:country:se,BD)",
            "urn:li:state:(urn:li:country:se,Z)"
        ]
    },
    {
        "name": {
            "value": "Jonkoping, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8068,
        "urn": "urn:li:region:8068",
        "states": [
            "urn:li:state:(urn:li:country:se,F)",
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Varnamo, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8069,
        "urn": "urn:li:region:8069",
        "states": [
            "urn:li:state:(urn:li:country:se,F)",
            "urn:li:state:(urn:li:country:se,G)",
            "urn:li:state:(urn:li:country:se,N)"
        ]
    },
    {
        "name": {
            "value": "Tranas, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8070,
        "urn": "urn:li:region:8070",
        "states": [
            "urn:li:state:(urn:li:country:se,F)",
            "urn:li:state:(urn:li:country:se,E)"
        ]
    },
    {
        "name": {
            "value": "Vetlanda, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8071,
        "urn": "urn:li:region:8071",
        "states": [
            "urn:li:state:(urn:li:country:se,F)",
            "urn:li:state:(urn:li:country:se,H)",
            "urn:li:state:(urn:li:country:se,G)"
        ]
    },
    {
        "name": {
            "value": "Sundsvall, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8072,
        "urn": "urn:li:region:8072",
        "states": [
            "urn:li:state:(urn:li:country:se,Y)",
            "urn:li:state:(urn:li:country:se,Z)"
        ]
    },
    {
        "name": {
            "value": "Ornskoldsvik, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8073,
        "urn": "urn:li:region:8073",
        "states": [
            "urn:li:state:(urn:li:country:se,Y)",
            "urn:li:state:(urn:li:country:se,AC)"
        ]
    },
    {
        "name": {
            "value": "Harnosand, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8074,
        "urn": "urn:li:region:8074",
        "states": [
            "urn:li:state:(urn:li:country:se,Y)"
        ]
    },
    {
        "name": {
            "value": "Vaxjo, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8075,
        "urn": "urn:li:region:8075",
        "states": [
            "urn:li:state:(urn:li:country:se,G)",
            "urn:li:state:(urn:li:country:se,H)"
        ]
    },
    {
        "name": {
            "value": "Ljungby, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8076,
        "urn": "urn:li:region:8076",
        "states": [
            "urn:li:state:(urn:li:country:se,G)",
            "urn:li:state:(urn:li:country:se,N)"
        ]
    },
    {
        "name": {
            "value": "Malmo, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8077,
        "urn": "urn:li:region:8077",
        "states": [
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Helsingborg, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8078,
        "urn": "urn:li:region:8078",
        "states": [
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Kristianstad, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8079,
        "urn": "urn:li:region:8079",
        "states": [
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Landskrona, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8080,
        "urn": "urn:li:region:8080",
        "states": [
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Trelleborg, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8081,
        "urn": "urn:li:region:8081",
        "states": [
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Angelholm, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8082,
        "urn": "urn:li:region:8082",
        "states": [
            "urn:li:state:(urn:li:country:se,M)",
            "urn:li:state:(urn:li:country:se,N)"
        ]
    },
    {
        "name": {
            "value": "Lund, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8083,
        "urn": "urn:li:region:8083",
        "states": [
            "urn:li:state:(urn:li:country:se,AB)",
            "urn:li:state:(urn:li:country:se,C)"
        ]
    },
    {
        "name": {
            "value": "Ystad, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8084,
        "urn": "urn:li:region:8084",
        "states": [
            "urn:li:state:(urn:li:country:se,M)"
        ]
    },
    {
        "name": {
            "value": "Hassleholm, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8085,
        "urn": "urn:li:region:8085",
        "states": [
            "urn:li:state:(urn:li:country:se,M)",
            "urn:li:state:(urn:li:country:se,G)",
            "urn:li:state:(urn:li:country:se,N)"
        ]
    },
    {
        "name": {
            "value": "Kalmar, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8086,
        "urn": "urn:li:region:8086",
        "states": [
            "urn:li:state:(urn:li:country:se,H)"
        ]
    },
    {
        "name": {
            "value": "Vastervik, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8087,
        "urn": "urn:li:region:8087",
        "states": [
            "urn:li:state:(urn:li:country:se,H)",
            "urn:li:state:(urn:li:country:se,E)"
        ]
    },
    {
        "name": {
            "value": "Oskarshamn, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8088,
        "urn": "urn:li:region:8088",
        "states": [
            "urn:li:state:(urn:li:country:se,H)"
        ]
    },
    {
        "name": {
            "value": "Halmstad, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8089,
        "urn": "urn:li:region:8089",
        "states": [
            "urn:li:state:(urn:li:country:se,N)"
        ]
    },
    {
        "name": {
            "value": "Kungsbacka, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8090,
        "urn": "urn:li:region:8090",
        "states": [
            "urn:li:state:(urn:li:country:se,N)",
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Varberg, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8091,
        "urn": "urn:li:region:8091",
        "states": [
            "urn:li:state:(urn:li:country:se,N)",
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Falkenberg, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8092,
        "urn": "urn:li:region:8092",
        "states": [
            "urn:li:state:(urn:li:country:se,N)"
        ]
    },
    {
        "name": {
            "value": "Eskilstuna, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8093,
        "urn": "urn:li:region:8093",
        "states": [
            "urn:li:state:(urn:li:country:se,D)",
            "urn:li:state:(urn:li:country:se,AB)"
        ]
    },
    {
        "name": {
            "value": "Nykoping, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8094,
        "urn": "urn:li:region:8094",
        "states": [
            "urn:li:state:(urn:li:country:se,D)",
            "urn:li:state:(urn:li:country:se,T)"
        ]
    },
    {
        "name": {
            "value": "Strangnas, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8095,
        "urn": "urn:li:region:8095",
        "states": [
            "urn:li:state:(urn:li:country:se,D)"
        ]
    },
    {
        "name": {
            "value": "Katrineholm, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8096,
        "urn": "urn:li:region:8096",
        "states": [
            "urn:li:state:(urn:li:country:se,D)"
        ]
    },
    {
        "name": {
            "value": "Pitea, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8097,
        "urn": "urn:li:region:8097",
        "states": [
            "urn:li:state:(urn:li:country:se,BD)",
            "urn:li:state:(urn:li:country:se,AC)"
        ]
    },
    {
        "name": {
            "value": "Lulea, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8098,
        "urn": "urn:li:region:8098",
        "states": [
            "urn:li:state:(urn:li:country:se,BD)"
        ]
    },
    {
        "name": {
            "value": "Kiruna, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8099,
        "urn": "urn:li:region:8099",
        "states": [
            "urn:li:state:(urn:li:country:se,BD)"
        ]
    },
    {
        "name": {
            "value": "Uppsala, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8100,
        "urn": "urn:li:region:8100",
        "states": [
            "urn:li:state:(urn:li:country:se,C)",
            "urn:li:state:(urn:li:country:se,AB)",
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Enkoping, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8101,
        "urn": "urn:li:region:8101",
        "states": [
            "urn:li:state:(urn:li:country:se,C)",
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Borlange, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8102,
        "urn": "urn:li:region:8102",
        "states": [
            "urn:li:state:(urn:li:country:se,W)"
        ]
    },
    {
        "name": {
            "value": "Falun, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8103,
        "urn": "urn:li:region:8103",
        "states": [
            "urn:li:state:(urn:li:country:se,W)",
            "urn:li:state:(urn:li:country:se,X)"
        ]
    },
    {
        "name": {
            "value": "Avesta, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8104,
        "urn": "urn:li:region:8104",
        "states": [
            "urn:li:state:(urn:li:country:se,W)",
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Ludvika, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8105,
        "urn": "urn:li:region:8105",
        "states": [
            "urn:li:state:(urn:li:country:se,W)",
            "urn:li:state:(urn:li:country:se,T)",
            "urn:li:state:(urn:li:country:se,S)"
        ]
    },
    {
        "name": {
            "value": "Gavle, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8106,
        "urn": "urn:li:region:8106",
        "states": [
            "urn:li:state:(urn:li:country:se,X)",
            "urn:li:state:(urn:li:country:se,C)"
        ]
    },
    {
        "name": {
            "value": "Sandviken, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8107,
        "urn": "urn:li:region:8107",
        "states": [
            "urn:li:state:(urn:li:country:se,X)",
            "urn:li:state:(urn:li:country:se,W)",
            "urn:li:state:(urn:li:country:se,U)"
        ]
    },
    {
        "name": {
            "value": "Hudiksvall, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8108,
        "urn": "urn:li:region:8108",
        "states": [
            "urn:li:state:(urn:li:country:se,X)"
        ]
    },
    {
        "name": {
            "value": "Bollnas, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8109,
        "urn": "urn:li:region:8109",
        "states": [
            "urn:li:state:(urn:li:country:se,X)"
        ]
    },
    {
        "name": {
            "value": "Soderhamn, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8110,
        "urn": "urn:li:region:8110",
        "states": [
            "urn:li:state:(urn:li:country:se,X)"
        ]
    },
    {
        "name": {
            "value": "Gothenburg, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8111,
        "urn": "urn:li:region:8111",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Boras, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8112,
        "urn": "urn:li:region:8112",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Skovde, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8113,
        "urn": "urn:li:region:8113",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Uddevalla, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8114,
        "urn": "urn:li:region:8114",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Alingsas, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8115,
        "urn": "urn:li:region:8115",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Lidkoping, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8116,
        "urn": "urn:li:region:8116",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Kungalv, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8117,
        "urn": "urn:li:region:8117",
        "states": [
            "urn:li:state:(urn:li:country:se,O)"
        ]
    },
    {
        "name": {
            "value": "Orebro, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8118,
        "urn": "urn:li:region:8118",
        "states": [
            "urn:li:state:(urn:li:country:se,T)"
        ]
    },
    {
        "name": {
            "value": "Karlskoga, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8119,
        "urn": "urn:li:region:8119",
        "states": [
            "urn:li:state:(urn:li:country:se,T)",
            "urn:li:state:(urn:li:country:se,S)"
        ]
    },
    {
        "name": {
            "value": "Norrkoping, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8120,
        "urn": "urn:li:region:8120",
        "states": [
            "urn:li:state:(urn:li:country:se,E)"
        ]
    },
    {
        "name": {
            "value": "Linkoping, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8121,
        "urn": "urn:li:region:8121",
        "states": [
            "urn:li:state:(urn:li:country:se,E)",
            "urn:li:state:(urn:li:country:se,T)"
        ]
    },
    {
        "name": {
            "value": "Finspang, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8122,
        "urn": "urn:li:region:8122",
        "states": [
            "urn:li:state:(urn:li:country:se,E)",
            "urn:li:state:(urn:li:country:se,T)"
        ]
    },
    {
        "name": {
            "value": "Mjolby, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8123,
        "urn": "urn:li:region:8123",
        "states": [
            "urn:li:state:(urn:li:country:se,E)"
        ]
    },
    {
        "name": {
            "value": "Motala, Sweden"
        },
        "country": "urn:li:country:se",
        "id": 8124,
        "urn": "urn:li:region:8124",
        "states": [
            "urn:li:state:(urn:li:country:se,E)",
            "urn:li:state:(urn:li:country:se,O)",
            "urn:li:state:(urn:li:country:se,T)"
        ]
    },
    {
        "name": {
            "value": "Podlaskie District, Wysokie Mazowieckie County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8125,
        "urn": "urn:li:region:8125",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Lomza County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8126,
        "urn": "urn:li:region:8126",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Grajewo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8127,
        "urn": "urn:li:region:8127",
        "states": []
    },
    {
        "name": {
            "value": "PodlaskieDistrict, Hajnowka County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8128,
        "urn": "urn:li:region:8128",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Bialystok County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8129,
        "urn": "urn:li:region:8129",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Kolno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8130,
        "urn": "urn:li:region:8130",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Sejny County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8131,
        "urn": "urn:li:region:8131",
        "states": []
    },
    {
        "name": {
            "value": "Suwalki, Podlaskie District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8132,
        "urn": "urn:li:region:8132",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Suwalki County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8133,
        "urn": "urn:li:region:8133",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Sokolka County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8134,
        "urn": "urn:li:region:8134",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Siemiatycze County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8135,
        "urn": "urn:li:region:8135",
        "states": []
    },
    {
        "name": {
            "value": "Bialystok, Podlaskie District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8136,
        "urn": "urn:li:region:8136",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Zambrow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8137,
        "urn": "urn:li:region:8137",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Bielsko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8138,
        "urn": "urn:li:region:8138",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Augustow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8139,
        "urn": "urn:li:region:8139",
        "states": []
    },
    {
        "name": {
            "value": "Podlaskie District, Monki County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8140,
        "urn": "urn:li:region:8140",
        "states": []
    },
    {
        "name": {
            "value": "Lomza, Podlaskie District,, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8141,
        "urn": "urn:li:region:8141",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Wegrow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8142,
        "urn": "urn:li:region:8142",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Makow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8143,
        "urn": "urn:li:region:8143",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Sierpc County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8144,
        "urn": "urn:li:region:8144",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Przasnysz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8145,
        "urn": "urn:li:region:8145",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Pruszkow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8146,
        "urn": "urn:li:region:8146",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Losice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8147,
        "urn": "urn:li:region:8147",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Lipsko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8148,
        "urn": "urn:li:region:8148",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Ostrow Mazowiecka County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8149,
        "urn": "urn:li:region:8149",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Mlawa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8150,
        "urn": "urn:li:region:8150",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Ostroleka County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8151,
        "urn": "urn:li:region:8151",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Garwolin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8152,
        "urn": "urn:li:region:8152",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Otwock County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8153,
        "urn": "urn:li:region:8153",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Piaseczno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8154,
        "urn": "urn:li:region:8154",
        "states": []
    },
    {
        "name": {
            "value": "Ostroleka, Masovian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8155,
        "urn": "urn:li:region:8155",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Siedlce County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8156,
        "urn": "urn:li:region:8156",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Sokolow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8157,
        "urn": "urn:li:region:8157",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Gostynin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8158,
        "urn": "urn:li:region:8158",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Sochaczew County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8159,
        "urn": "urn:li:region:8159",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Bialobrzegi County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8160,
        "urn": "urn:li:region:8160",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Wyszkow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8161,
        "urn": "urn:li:region:8161",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Nowy Dwor Mazowiecki County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8162,
        "urn": "urn:li:region:8162",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Grojec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8163,
        "urn": "urn:li:region:8163",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Minsk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8164,
        "urn": "urn:li:region:8164",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Grodzisk Mazowiecki County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8165,
        "urn": "urn:li:region:8165",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Pultusk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8166,
        "urn": "urn:li:region:8166",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Warsaw West County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8167,
        "urn": "urn:li:region:8167",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Plonsk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8168,
        "urn": "urn:li:region:8168",
        "states": []
    },
    {
        "name": {
            "value": "Radom, Masovian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8169,
        "urn": "urn:li:region:8169",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Wolomin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8170,
        "urn": "urn:li:region:8170",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Ciechanow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8171,
        "urn": "urn:li:region:8171",
        "states": []
    },
    {
        "name": {
            "value": "Warsaw, Masovian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8172,
        "urn": "urn:li:region:8172",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Zwolen County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8173,
        "urn": "urn:li:region:8173",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Radom County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8174,
        "urn": "urn:li:region:8174",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Przysucha County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8175,
        "urn": "urn:li:region:8175",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Szydlowiec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8176,
        "urn": "urn:li:region:8176",
        "states": []
    },
    {
        "name": {
            "value": "Plock, Masovian District , Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8177,
        "urn": "urn:li:region:8177",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Zuromin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8178,
        "urn": "urn:li:region:8178",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Legionowo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8179,
        "urn": "urn:li:region:8179",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Zyrardow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8180,
        "urn": "urn:li:region:8180",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Kozienice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8181,
        "urn": "urn:li:region:8181",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Siedlce County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8182,
        "urn": "urn:li:region:8182",
        "states": []
    },
    {
        "name": {
            "value": "Masovian District, Plock County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8183,
        "urn": "urn:li:region:8183",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Raciborz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8184,
        "urn": "urn:li:region:8184",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Wodzislaw County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8185,
        "urn": "urn:li:region:8185",
        "states": []
    },
    {
        "name": {
            "value": "Jastrzebie-Zdroj, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8186,
        "urn": "urn:li:region:8186",
        "states": []
    },
    {
        "name": {
            "value": "Sosnowiec, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8187,
        "urn": "urn:li:region:8187",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Bierun-Ledziny County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8188,
        "urn": "urn:li:region:8188",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Pszczyna County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8189,
        "urn": "urn:li:region:8189",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Tarnow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8190,
        "urn": "urn:li:region:8190",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Rybnik County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8191,
        "urn": "urn:li:region:8191",
        "states": []
    },
    {
        "name": {
            "value": "Bytom, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8192,
        "urn": "urn:li:region:8192",
        "states": []
    },
    {
        "name": {
            "value": "Ruda Slaska, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8193,
        "urn": "urn:li:region:8193",
        "states": []
    },
    {
        "name": {
            "value": "Czestochowa, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8194,
        "urn": "urn:li:region:8194",
        "states": []
    },
    {
        "name": {
            "value": "Zabrze, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8195,
        "urn": "urn:li:region:8195",
        "states": []
    },
    {
        "name": {
            "value": "Tychy, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8196,
        "urn": "urn:li:region:8196",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Mikolow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8197,
        "urn": "urn:li:region:8197",
        "states": []
    },
    {
        "name": {
            "value": "Rybnik, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8198,
        "urn": "urn:li:region:8198",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Bedzin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8199,
        "urn": "urn:li:region:8199",
        "states": []
    },
    {
        "name": {
            "value": "Bielsko-Biala, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8200,
        "urn": "urn:li:region:8200",
        "states": []
    },
    {
        "name": {
            "value": "Dabrowa Gornicza, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8201,
        "urn": "urn:li:region:8201",
        "states": []
    },
    {
        "name": {
            "value": "Jaworzno, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8202,
        "urn": "urn:li:region:8202",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Zywiec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8203,
        "urn": "urn:li:region:8203",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Czestochowa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8204,
        "urn": "urn:li:region:8204",
        "states": []
    },
    {
        "name": {
            "value": "Swietochlowice, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8205,
        "urn": "urn:li:region:8205",
        "states": []
    },
    {
        "name": {
            "value": "Piekary Slaskie, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8206,
        "urn": "urn:li:region:8206",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Bielsk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8207,
        "urn": "urn:li:region:8207",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Tarnowskie Gory County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8208,
        "urn": "urn:li:region:8208",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Siemianowice Slaskie, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8209,
        "urn": "urn:li:region:8209",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Lubliniec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8210,
        "urn": "urn:li:region:8210",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Gliwice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8211,
        "urn": "urn:li:region:8211",
        "states": []
    },
    {
        "name": {
            "value": "Myslowice, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8212,
        "urn": "urn:li:region:8212",
        "states": []
    },
    {
        "name": {
            "value": "Chorzow, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8213,
        "urn": "urn:li:region:8213",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Zawiercie County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8214,
        "urn": "urn:li:region:8214",
        "states": []
    },
    {
        "name": {
            "value": "Zory, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8215,
        "urn": "urn:li:region:8215",
        "states": []
    },
    {
        "name": {
            "value": "Katowice, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8216,
        "urn": "urn:li:region:8216",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Myszkow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8217,
        "urn": "urn:li:region:8217",
        "states": []
    },
    {
        "name": {
            "value": "Gliwice, Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8218,
        "urn": "urn:li:region:8218",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Klobuck County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8219,
        "urn": "urn:li:region:8219",
        "states": []
    },
    {
        "name": {
            "value": "Silesian District, Cieszyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8220,
        "urn": "urn:li:region:8220",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Torun County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8221,
        "urn": "urn:li:region:8221",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Aleksandrow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8222,
        "urn": "urn:li:region:8222",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Sepolno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8223,
        "urn": "urn:li:region:8223",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Naklo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8224,
        "urn": "urn:li:region:8224",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Wabrzezno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8225,
        "urn": "urn:li:region:8225",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Chelmno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8226,
        "urn": "urn:li:region:8226",
        "states": []
    },
    {
        "name": {
            "value": "Grudziadz, Kuyavian-Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8227,
        "urn": "urn:li:region:8227",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Lipno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8228,
        "urn": "urn:li:region:8228",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Brodnica County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8229,
        "urn": "urn:li:region:8229",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Grudziadz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8230,
        "urn": "urn:li:region:8230",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Golub-Dobrzyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8231,
        "urn": "urn:li:region:8231",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Rypin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8232,
        "urn": "urn:li:region:8232",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Swiecie County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8233,
        "urn": "urn:li:region:8233",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Wloclawek County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8234,
        "urn": "urn:li:region:8234",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Znin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8235,
        "urn": "urn:li:region:8235",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Bydgoszcz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8236,
        "urn": "urn:li:region:8236",
        "states": []
    },
    {
        "name": {
            "value": "Bydgoszcz, Kuyavian-Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8237,
        "urn": "urn:li:region:8237",
        "states": []
    },
    {
        "name": {
            "value": "Torun, Kuyavian-Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8238,
        "urn": "urn:li:region:8238",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Mogilno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8239,
        "urn": "urn:li:region:8239",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Radziejow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8240,
        "urn": "urn:li:region:8240",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Tuchola County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8241,
        "urn": "urn:li:region:8241",
        "states": []
    },
    {
        "name": {
            "value": "Kuyavian-Pomeranian District, Inowroclaw County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8242,
        "urn": "urn:li:region:8242",
        "states": []
    },
    {
        "name": {
            "value": "Wloclawek, Kuyavian-Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8243,
        "urn": "urn:li:region:8243",
        "states": []
    },
    {
        "name": {
            "value": "Lublin, Lublin District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8244,
        "urn": "urn:li:region:8244",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Parczew County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8245,
        "urn": "urn:li:region:8245",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Bilgoraj County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8246,
        "urn": "urn:li:region:8246",
        "states": []
    },
    {
        "name": {
            "value": "Biala Podlaska, Lublin District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8247,
        "urn": "urn:li:region:8247",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Janow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8248,
        "urn": "urn:li:region:8248",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Lublin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8249,
        "urn": "urn:li:region:8249",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Swidnik County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8250,
        "urn": "urn:li:region:8250",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Opole County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8251,
        "urn": "urn:li:region:8251",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Krasnik County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8252,
        "urn": "urn:li:region:8252",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Lubartow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8253,
        "urn": "urn:li:region:8253",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Krasnystaw County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8254,
        "urn": "urn:li:region:8254",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Hrubieszow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8255,
        "urn": "urn:li:region:8255",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Chelm County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8256,
        "urn": "urn:li:region:8256",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Tomaszow Lubelski County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8257,
        "urn": "urn:li:region:8257",
        "states": []
    },
    {
        "name": {
            "value": "Zamosc, Lublin District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8258,
        "urn": "urn:li:region:8258",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Wlodawa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8259,
        "urn": "urn:li:region:8259",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Zamosc County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8260,
        "urn": "urn:li:region:8260",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Biala Podlaska County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8261,
        "urn": "urn:li:region:8261",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Lukow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8262,
        "urn": "urn:li:region:8262",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Leczna County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8263,
        "urn": "urn:li:region:8263",
        "states": []
    },
    {
        "name": {
            "value": "Chelm, Lublin District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8264,
        "urn": "urn:li:region:8264",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Pulawy County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8265,
        "urn": "urn:li:region:8265",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Ryki County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8266,
        "urn": "urn:li:region:8266",
        "states": []
    },
    {
        "name": {
            "value": "Lublin District, Radzyn Podlaski County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8267,
        "urn": "urn:li:region:8267",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Opoczno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8268,
        "urn": "urn:li:region:8268",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Lask County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8269,
        "urn": "urn:li:region:8269",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Zgierz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8270,
        "urn": "urn:li:region:8270",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Lodz East County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8271,
        "urn": "urn:li:region:8271",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Rawa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8272,
        "urn": "urn:li:region:8272",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Wielun County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8273,
        "urn": "urn:li:region:8273",
        "states": []
    },
    {
        "name": {
            "value": "Lodz, Lodz District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8274,
        "urn": "urn:li:region:8274",
        "states": []
    },
    {
        "name": {
            "value": "Piotrkow Trybunalski, Lodz District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8275,
        "urn": "urn:li:region:8275",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Lowicz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8276,
        "urn": "urn:li:region:8276",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Skierniewice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8277,
        "urn": "urn:li:region:8277",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Pajeczno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8278,
        "urn": "urn:li:region:8278",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Piotrkow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8279,
        "urn": "urn:li:region:8279",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Tomaszow Mazowiecki County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8280,
        "urn": "urn:li:region:8280",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Radomsko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8281,
        "urn": "urn:li:region:8281",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Pabianice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8282,
        "urn": "urn:li:region:8282",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Sieradz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8283,
        "urn": "urn:li:region:8283",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Belchatow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8284,
        "urn": "urn:li:region:8284",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Wieruszow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8285,
        "urn": "urn:li:region:8285",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Zdunska Wola County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8286,
        "urn": "urn:li:region:8286",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Leczyca County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8287,
        "urn": "urn:li:region:8287",
        "states": []
    },
    {
        "name": {
            "value": "Skierniewice, Lodz District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8288,
        "urn": "urn:li:region:8288",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Brzeziny County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8289,
        "urn": "urn:li:region:8289",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Poddebice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8290,
        "urn": "urn:li:region:8290",
        "states": []
    },
    {
        "name": {
            "value": "Lodz District, Kutno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8291,
        "urn": "urn:li:region:8291",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Bialogard County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8292,
        "urn": "urn:li:region:8292",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Police County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8293,
        "urn": "urn:li:region:8293",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Swidwin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8294,
        "urn": "urn:li:region:8294",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Pyrzyce County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8295,
        "urn": "urn:li:region:8295",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Mysliborz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8296,
        "urn": "urn:li:region:8296",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Szczecinek County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8297,
        "urn": "urn:li:region:8297",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Choszczno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8298,
        "urn": "urn:li:region:8298",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Lobez County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8299,
        "urn": "urn:li:region:8299",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Gryfino County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8300,
        "urn": "urn:li:region:8300",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Kamien County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8301,
        "urn": "urn:li:region:8301",
        "states": []
    },
    {
        "name": {
            "value": "Szczecin, West Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8302,
        "urn": "urn:li:region:8302",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Kolobrzeg County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8303,
        "urn": "urn:li:region:8303",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Goleniow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8304,
        "urn": "urn:li:region:8304",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Slawno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8305,
        "urn": "urn:li:region:8305",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Gryfice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8306,
        "urn": "urn:li:region:8306",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Drawsko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8307,
        "urn": "urn:li:region:8307",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Stargard County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8308,
        "urn": "urn:li:region:8308",
        "states": []
    },
    {
        "name": {
            "value": "Koszalin, West Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8309,
        "urn": "urn:li:region:8309",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Koszalin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8310,
        "urn": "urn:li:region:8310",
        "states": []
    },
    {
        "name": {
            "value": "West Pomeranian District, Walcz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8311,
        "urn": "urn:li:region:8311",
        "states": []
    },
    {
        "name": {
            "value": "Swinoujscie, West Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8312,
        "urn": "urn:li:region:8312",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Kwidzyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8313,
        "urn": "urn:li:region:8313",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Malbork County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8314,
        "urn": "urn:li:region:8314",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Bytow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8315,
        "urn": "urn:li:region:8315",
        "states": []
    },
    {
        "name": {
            "value": "Slupsk, Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8316,
        "urn": "urn:li:region:8316",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Gdansk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8317,
        "urn": "urn:li:region:8317",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Lebork County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8318,
        "urn": "urn:li:region:8318",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Kartuzy County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8319,
        "urn": "urn:li:region:8319",
        "states": []
    },
    {
        "name": {
            "value": "Gdansk, Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8320,
        "urn": "urn:li:region:8320",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Slupsk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8321,
        "urn": "urn:li:region:8321",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Koscierzyna County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8322,
        "urn": "urn:li:region:8322",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Nowy Dwor Gdanski County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8323,
        "urn": "urn:li:region:8323",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Wejherowo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8324,
        "urn": "urn:li:region:8324",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Puck County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8325,
        "urn": "urn:li:region:8325",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Tczew County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8326,
        "urn": "urn:li:region:8326",
        "states": []
    },
    {
        "name": {
            "value": "Sopot, Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8327,
        "urn": "urn:li:region:8327",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Sztum County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8328,
        "urn": "urn:li:region:8328",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Starogard County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8329,
        "urn": "urn:li:region:8329",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Czluchow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8330,
        "urn": "urn:li:region:8330",
        "states": []
    },
    {
        "name": {
            "value": "Pomeranian District, Chojnice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8331,
        "urn": "urn:li:region:8331",
        "states": []
    },
    {
        "name": {
            "value": "Gdynia, Pomeranian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8332,
        "urn": "urn:li:region:8332",
        "states": []
    },
    {
        "name": {
            "value": "Konin, Greater Poland District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8333,
        "urn": "urn:li:region:8333",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Jarocin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8334,
        "urn": "urn:li:region:8334",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Ostrzeszow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8335,
        "urn": "urn:li:region:8335",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Wolsztyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8336,
        "urn": "urn:li:region:8336",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Miedzychod County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8337,
        "urn": "urn:li:region:8337",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Ostrow Wielkopolski County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8338,
        "urn": "urn:li:region:8338",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Czarnkow-Trzcianka County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8339,
        "urn": "urn:li:region:8339",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Turek County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8340,
        "urn": "urn:li:region:8340",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Srem County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8341,
        "urn": "urn:li:region:8341",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Poznan County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8342,
        "urn": "urn:li:region:8342",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Chodziez County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8343,
        "urn": "urn:li:region:8343",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Wrzesnia County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8344,
        "urn": "urn:li:region:8344",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Rawicz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8345,
        "urn": "urn:li:region:8345",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Konin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8346,
        "urn": "urn:li:region:8346",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Koscian County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8347,
        "urn": "urn:li:region:8347",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Gostyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8348,
        "urn": "urn:li:region:8348",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Grodzisk Wielkopolski County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8349,
        "urn": "urn:li:region:8349",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Pila County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8350,
        "urn": "urn:li:region:8350",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Wagrowiec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8351,
        "urn": "urn:li:region:8351",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Pleszew County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8352,
        "urn": "urn:li:region:8352",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Leszno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8353,
        "urn": "urn:li:region:8353",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Zlotow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8354,
        "urn": "urn:li:region:8354",
        "states": []
    },
    {
        "name": {
            "value": "Poznan, Greater Poland District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8355,
        "urn": "urn:li:region:8355",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Slupca County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8356,
        "urn": "urn:li:region:8356",
        "states": []
    },
    {
        "name": {
            "value": "Leszno, Greater Poland District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8357,
        "urn": "urn:li:region:8357",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Sroda Wielkopolska County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8358,
        "urn": "urn:li:region:8358",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Kepno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8359,
        "urn": "urn:li:region:8359",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Krotoszyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8360,
        "urn": "urn:li:region:8360",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Nowy Tomysl County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8361,
        "urn": "urn:li:region:8361",
        "states": []
    },
    {
        "name": {
            "value": "Kalisz, Greater Poland District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8362,
        "urn": "urn:li:region:8362",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Szamotuly County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8363,
        "urn": "urn:li:region:8363",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Gniezno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8364,
        "urn": "urn:li:region:8364",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Kalisz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8365,
        "urn": "urn:li:region:8365",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Oborniki County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8366,
        "urn": "urn:li:region:8366",
        "states": []
    },
    {
        "name": {
            "value": "Greater Poland District, Kolo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8367,
        "urn": "urn:li:region:8367",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Slubice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8368,
        "urn": "urn:li:region:8368",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Wschow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8369,
        "urn": "urn:li:region:8369",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Zielona Gora County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8370,
        "urn": "urn:li:region:8370",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Zagan County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8371,
        "urn": "urn:li:region:8371",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Swiebodzin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8372,
        "urn": "urn:li:region:8372",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Strzelece-Drezdenko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8373,
        "urn": "urn:li:region:8373",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Nowa Sol County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8374,
        "urn": "urn:li:region:8374",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Gorzow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8375,
        "urn": "urn:li:region:8375",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Sulecin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8376,
        "urn": "urn:li:region:8376",
        "states": []
    },
    {
        "name": {
            "value": "Gorzow Wielkopolski, Lubusz District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8377,
        "urn": "urn:li:region:8377",
        "states": []
    },
    {
        "name": {
            "value": "Zielona Gora, Lubusz District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8378,
        "urn": "urn:li:region:8378",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Miedzyrzecz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8379,
        "urn": "urn:li:region:8379",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Krosno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8380,
        "urn": "urn:li:region:8380",
        "states": []
    },
    {
        "name": {
            "value": "Lubusz District, Zary County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8381,
        "urn": "urn:li:region:8381",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Nowe Miasto County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8382,
        "urn": "urn:li:region:8382",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Goldap County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8383,
        "urn": "urn:li:region:8383",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Bartoszyce County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8384,
        "urn": "urn:li:region:8384",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Olecko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8385,
        "urn": "urn:li:region:8385",
        "states": []
    },
    {
        "name": {
            "value": "Olsztyn, Warmian-Masurian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8386,
        "urn": "urn:li:region:8386",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Elblag County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8387,
        "urn": "urn:li:region:8387",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Elk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8388,
        "urn": "urn:li:region:8388",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Szczytno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8389,
        "urn": "urn:li:region:8389",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Lidzbark County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8390,
        "urn": "urn:li:region:8390",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Mragowo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8391,
        "urn": "urn:li:region:8391",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Ostroda County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8392,
        "urn": "urn:li:region:8392",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Gizycko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8393,
        "urn": "urn:li:region:8393",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Olsztyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8394,
        "urn": "urn:li:region:8394",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Pisz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8395,
        "urn": "urn:li:region:8395",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Braniewo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8396,
        "urn": "urn:li:region:8396",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Ketrzyn County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8397,
        "urn": "urn:li:region:8397",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Dzialdowo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8398,
        "urn": "urn:li:region:8398",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Wegorzewo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8399,
        "urn": "urn:li:region:8399",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Ilawa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8400,
        "urn": "urn:li:region:8400",
        "states": []
    },
    {
        "name": {
            "value": "Warmian-Masurian District, Nidzica County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8401,
        "urn": "urn:li:region:8401",
        "states": []
    },
    {
        "name": {
            "value": "Elblag, Warmian-Masurian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8402,
        "urn": "urn:li:region:8402",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Strzelin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8403,
        "urn": "urn:li:region:8403",
        "states": []
    },
    {
        "name": {
            "value": "Wroclaw, Lower Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8404,
        "urn": "urn:li:region:8404",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Kamienna Gora County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8405,
        "urn": "urn:li:region:8405",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Polkowice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8406,
        "urn": "urn:li:region:8406",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Wolow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8407,
        "urn": "urn:li:region:8407",
        "states": []
    },
    {
        "name": {
            "value": "Jelenia Gora, Lower Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8408,
        "urn": "urn:li:region:8408",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Jawor County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8409,
        "urn": "urn:li:region:8409",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Milicz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8410,
        "urn": "urn:li:region:8410",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Trzebnica County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8411,
        "urn": "urn:li:region:8411",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Zabkowice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8412,
        "urn": "urn:li:region:8412",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Glogow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8413,
        "urn": "urn:li:region:8413",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Swidnica County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8414,
        "urn": "urn:li:region:8414",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Wroclaw County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8415,
        "urn": "urn:li:region:8415",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Gora County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8416,
        "urn": "urn:li:region:8416",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Dzierzoniow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8417,
        "urn": "urn:li:region:8417",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Luban County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8418,
        "urn": "urn:li:region:8418",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Jelenia Gora County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8419,
        "urn": "urn:li:region:8419",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Zgorzelec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8420,
        "urn": "urn:li:region:8420",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Olawa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8421,
        "urn": "urn:li:region:8421",
        "states": []
    },
    {
        "name": {
            "value": "Legnica, Lower Silesian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8422,
        "urn": "urn:li:region:8422",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Walbrzych County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8423,
        "urn": "urn:li:region:8423",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Olesnica County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8424,
        "urn": "urn:li:region:8424",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Zlotoryja County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8425,
        "urn": "urn:li:region:8425",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Sroda Slaska County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8426,
        "urn": "urn:li:region:8426",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Boleslawiec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8427,
        "urn": "urn:li:region:8427",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Lubin County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8428,
        "urn": "urn:li:region:8428",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Lwowek County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8429,
        "urn": "urn:li:region:8429",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Klodzko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8430,
        "urn": "urn:li:region:8430",
        "states": []
    },
    {
        "name": {
            "value": "Lower Silesian District, Legnica County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8431,
        "urn": "urn:li:region:8431",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Ostrowiec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8432,
        "urn": "urn:li:region:8432",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Skarzysko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8433,
        "urn": "urn:li:region:8433",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Jedrzejow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8434,
        "urn": "urn:li:region:8434",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Opatow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8435,
        "urn": "urn:li:region:8435",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Starachowice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8436,
        "urn": "urn:li:region:8436",
        "states": []
    },
    {
        "name": {
            "value": "Kielce, Swietokrzyskie District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8437,
        "urn": "urn:li:region:8437",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Sandomierz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8438,
        "urn": "urn:li:region:8438",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Kazimierza County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8439,
        "urn": "urn:li:region:8439",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Konskie County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8440,
        "urn": "urn:li:region:8440",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Busko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8441,
        "urn": "urn:li:region:8441",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Pinczow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8442,
        "urn": "urn:li:region:8442",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Wloszczowa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8443,
        "urn": "urn:li:region:8443",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Kielce County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8444,
        "urn": "urn:li:region:8444",
        "states": []
    },
    {
        "name": {
            "value": "Swietokrzyskie District, Staszow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8445,
        "urn": "urn:li:region:8445",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Nowy Sacz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8446,
        "urn": "urn:li:region:8446",
        "states": []
    },
    {
        "name": {
            "value": "Cracow, Lesser Poland District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8447,
        "urn": "urn:li:region:8447",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Sucha County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8448,
        "urn": "urn:li:region:8448",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Brzeg County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8449,
        "urn": "urn:li:region:8449",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Tarnow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8450,
        "urn": "urn:li:region:8450",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Bochnia County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8451,
        "urn": "urn:li:region:8451",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Wadowice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8452,
        "urn": "urn:li:region:8452",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Limanowa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8453,
        "urn": "urn:li:region:8453",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Myslenice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8454,
        "urn": "urn:li:region:8454",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Proszowice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8455,
        "urn": "urn:li:region:8455",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Dabrowa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8456,
        "urn": "urn:li:region:8456",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Cracow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8457,
        "urn": "urn:li:region:8457",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Nowy Targ County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8458,
        "urn": "urn:li:region:8458",
        "states": []
    },
    {
        "name": {
            "value": "Tarnow, Lesser Poland District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8459,
        "urn": "urn:li:region:8459",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Gorlice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8460,
        "urn": "urn:li:region:8460",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Tatra County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8461,
        "urn": "urn:li:region:8461",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Wieliczka County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8462,
        "urn": "urn:li:region:8462",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Chrzanow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8463,
        "urn": "urn:li:region:8463",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Oswiecim County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8464,
        "urn": "urn:li:region:8464",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Olkusz County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8465,
        "urn": "urn:li:region:8465",
        "states": []
    },
    {
        "name": {
            "value": "Lesser Poland District, Miechow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8466,
        "urn": "urn:li:region:8466",
        "states": []
    },
    {
        "name": {
            "value": "Nowy Sacz, Lesser Poland District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8467,
        "urn": "urn:li:region:8467",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Namyslow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8468,
        "urn": "urn:li:region:8468",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Brzeg Opolski County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8469,
        "urn": "urn:li:region:8469",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Glubczyce County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8470,
        "urn": "urn:li:region:8470",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Krapkowice County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8471,
        "urn": "urn:li:region:8471",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Prudnik County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8472,
        "urn": "urn:li:region:8472",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Olesno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8473,
        "urn": "urn:li:region:8473",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Strzelce County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8474,
        "urn": "urn:li:region:8474",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Nysa County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8475,
        "urn": "urn:li:region:8475",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Opole County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8476,
        "urn": "urn:li:region:8476",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Kedzierzyn-Kozle County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8477,
        "urn": "urn:li:region:8477",
        "states": []
    },
    {
        "name": {
            "value": "Opole District, Kluczbork County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8478,
        "urn": "urn:li:region:8478",
        "states": []
    },
    {
        "name": {
            "value": "Opole, Opole District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8479,
        "urn": "urn:li:region:8479",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Strzyzow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8480,
        "urn": "urn:li:region:8480",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Przemysl County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8481,
        "urn": "urn:li:region:8481",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Kolbuszow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8482,
        "urn": "urn:li:region:8482",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Lubaczow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8483,
        "urn": "urn:li:region:8483",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Rzeszow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8484,
        "urn": "urn:li:region:8484",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Przeworsk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8485,
        "urn": "urn:li:region:8485",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Jaslo County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8486,
        "urn": "urn:li:region:8486",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Debica County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8487,
        "urn": "urn:li:region:8487",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Stalowa Wola County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8488,
        "urn": "urn:li:region:8488",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Brzozow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8489,
        "urn": "urn:li:region:8489",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Mielec County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8490,
        "urn": "urn:li:region:8490",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Lancut County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8491,
        "urn": "urn:li:region:8491",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Sanok County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8492,
        "urn": "urn:li:region:8492",
        "states": []
    },
    {
        "name": {
            "value": "Tarnobrzeg, Subcarpathian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8493,
        "urn": "urn:li:region:8493",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Tarnobrzeg County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8494,
        "urn": "urn:li:region:8494",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Lezajsk County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8495,
        "urn": "urn:li:region:8495",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Nisko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8496,
        "urn": "urn:li:region:8496",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Przemysl, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8497,
        "urn": "urn:li:region:8497",
        "states": []
    },
    {
        "name": {
            "value": "Krosno, Subcarpathian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8498,
        "urn": "urn:li:region:8498",
        "states": []
    },
    {
        "name": {
            "value": "Rzeszow, Subcarpathian District, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8499,
        "urn": "urn:li:region:8499",
        "states": []
    },
    {
        "name": {
            "value": "Sanok, Subcarpathian District,, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8500,
        "urn": "urn:li:region:8500",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Jaroslaw County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8501,
        "urn": "urn:li:region:8501",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Ropczyce-Sedziszow County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8502,
        "urn": "urn:li:region:8502",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Krosno County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8503,
        "urn": "urn:li:region:8503",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Bieszczady County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8504,
        "urn": "urn:li:region:8504",
        "states": []
    },
    {
        "name": {
            "value": "Subcarpathian District, Lesko County, Poland"
        },
        "country": "urn:li:country:pl",
        "id": 8505,
        "urn": "urn:li:region:8505",
        "states": []
    },
    {
        "name": {
            "value": "Bengkulu Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8577,
        "urn": "urn:li:region:8577",
        "states": []
    },
    {
        "name": {
            "value": "West Papua Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8578,
        "urn": "urn:li:region:8578",
        "states": []
    },
    {
        "name": {
            "value": "Aceh Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8579,
        "urn": "urn:li:region:8579",
        "states": []
    },
    {
        "name": {
            "value": "East Kalimantan Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8580,
        "urn": "urn:li:region:8580",
        "states": []
    },
    {
        "name": {
            "value": "West Sulawesi Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8581,
        "urn": "urn:li:region:8581",
        "states": []
    },
    {
        "name": {
            "value": "Papua Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8582,
        "urn": "urn:li:region:8582",
        "states": []
    },
    {
        "name": {
            "value": "East Java Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8583,
        "urn": "urn:li:region:8583",
        "states": []
    },
    {
        "name": {
            "value": "Jambi Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8584,
        "urn": "urn:li:region:8584",
        "states": []
    },
    {
        "name": {
            "value": "Bangka-Belitung Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8585,
        "urn": "urn:li:region:8585",
        "states": []
    },
    {
        "name": {
            "value": "Central Java Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8586,
        "urn": "urn:li:region:8586",
        "states": []
    },
    {
        "name": {
            "value": "West Kalimantan Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8587,
        "urn": "urn:li:region:8587",
        "states": []
    },
    {
        "name": {
            "value": "Riau Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8588,
        "urn": "urn:li:region:8588",
        "states": []
    },
    {
        "name": {
            "value": "North Maluku Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8589,
        "urn": "urn:li:region:8589",
        "states": []
    },
    {
        "name": {
            "value": "Riau Islands Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8590,
        "urn": "urn:li:region:8590",
        "states": []
    },
    {
        "name": {
            "value": "Central Kalimantan Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8591,
        "urn": "urn:li:region:8591",
        "states": []
    },
    {
        "name": {
            "value": "East Nusa Tenggara Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8592,
        "urn": "urn:li:region:8592",
        "states": []
    },
    {
        "name": {
            "value": "South Sumatera Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8593,
        "urn": "urn:li:region:8593",
        "states": []
    },
    {
        "name": {
            "value": "Greater Jakarta, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8594,
        "urn": "urn:li:region:8594",
        "states": []
    },
    {
        "name": {
            "value": "West Nusa Tenggara Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8595,
        "urn": "urn:li:region:8595",
        "states": []
    },
    {
        "name": {
            "value": "Maluku Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8596,
        "urn": "urn:li:region:8596",
        "states": []
    },
    {
        "name": {
            "value": "South East Sulawesi Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8597,
        "urn": "urn:li:region:8597",
        "states": []
    },
    {
        "name": {
            "value": "Lampung Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8598,
        "urn": "urn:li:region:8598",
        "states": []
    },
    {
        "name": {
            "value": "North Sulawesi Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8599,
        "urn": "urn:li:region:8599",
        "states": []
    },
    {
        "name": {
            "value": "North Sumatera Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8600,
        "urn": "urn:li:region:8600",
        "states": []
    },
    {
        "name": {
            "value": "Yogyakarta Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8601,
        "urn": "urn:li:region:8601",
        "states": []
    },
    {
        "name": {
            "value": "Banten Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8602,
        "urn": "urn:li:region:8602",
        "states": []
    },
    {
        "name": {
            "value": "South Sulawesi Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8603,
        "urn": "urn:li:region:8603",
        "states": []
    },
    {
        "name": {
            "value": "Gorontalo Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8604,
        "urn": "urn:li:region:8604",
        "states": []
    },
    {
        "name": {
            "value": "West Java Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8605,
        "urn": "urn:li:region:8605",
        "states": []
    },
    {
        "name": {
            "value": "South Kalimantan Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8606,
        "urn": "urn:li:region:8606",
        "states": []
    },
    {
        "name": {
            "value": "West Sumatera Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8607,
        "urn": "urn:li:region:8607",
        "states": []
    },
    {
        "name": {
            "value": "Central Sulawesi Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8608,
        "urn": "urn:li:region:8608",
        "states": []
    },
    {
        "name": {
            "value": "Bali Province, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8609,
        "urn": "urn:li:region:8609",
        "states": []
    },
    {
        "name": {
            "value": "Bengkulu, Bengkulu, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8610,
        "urn": "urn:li:region:8610",
        "states": []
    },
    {
        "name": {
            "value": "Manokwari, West Papua, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8611,
        "urn": "urn:li:region:8611",
        "states": []
    },
    {
        "name": {
            "value": "Banda Aceh, Aceh, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8612,
        "urn": "urn:li:region:8612",
        "states": []
    },
    {
        "name": {
            "value": "Samarinda, East Kalimantan, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8613,
        "urn": "urn:li:region:8613",
        "states": []
    },
    {
        "name": {
            "value": "Mamuju, West Sulawesi, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8614,
        "urn": "urn:li:region:8614",
        "states": []
    },
    {
        "name": {
            "value": "Jayapura, Papua, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8615,
        "urn": "urn:li:region:8615",
        "states": []
    },
    {
        "name": {
            "value": "Surabaya, East Java, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8616,
        "urn": "urn:li:region:8616",
        "states": []
    },
    {
        "name": {
            "value": "Malang, East Java, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8617,
        "urn": "urn:li:region:8617",
        "states": []
    },
    {
        "name": {
            "value": "Jambi, Jambi, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8618,
        "urn": "urn:li:region:8618",
        "states": []
    },
    {
        "name": {
            "value": "Pangkal Pinang, Bangka-Belitung, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8619,
        "urn": "urn:li:region:8619",
        "states": []
    },
    {
        "name": {
            "value": "Semarang, Central Java, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8620,
        "urn": "urn:li:region:8620",
        "states": []
    },
    {
        "name": {
            "value": "Pontianak, West Kalimantan, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8621,
        "urn": "urn:li:region:8621",
        "states": []
    },
    {
        "name": {
            "value": "Pekanbaru, Riau, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8622,
        "urn": "urn:li:region:8622",
        "states": []
    },
    {
        "name": {
            "value": "Ternate, North Maluku, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8623,
        "urn": "urn:li:region:8623",
        "states": []
    },
    {
        "name": {
            "value": "Tanjung Pinang, Riau Islands, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8624,
        "urn": "urn:li:region:8624",
        "states": []
    },
    {
        "name": {
            "value": "Palangka Raya, Central Kalimantan, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8625,
        "urn": "urn:li:region:8625",
        "states": []
    },
    {
        "name": {
            "value": "Kupang, East Nusa Tenggara, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8626,
        "urn": "urn:li:region:8626",
        "states": []
    },
    {
        "name": {
            "value": "Palembang, South Sumatera, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8627,
        "urn": "urn:li:region:8627",
        "states": []
    },
    {
        "name": {
            "value": "Mataram, West Nusa Tenggara, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8628,
        "urn": "urn:li:region:8628",
        "states": []
    },
    {
        "name": {
            "value": "Ambon, Maluku, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8629,
        "urn": "urn:li:region:8629",
        "states": []
    },
    {
        "name": {
            "value": "Kendari, South East Sulawesi, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8630,
        "urn": "urn:li:region:8630",
        "states": []
    },
    {
        "name": {
            "value": "Bandar Lampung, Lampung, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8631,
        "urn": "urn:li:region:8631",
        "states": []
    },
    {
        "name": {
            "value": "Manado, North Sulawesi, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8632,
        "urn": "urn:li:region:8632",
        "states": []
    },
    {
        "name": {
            "value": "Medan, North Sumatera, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8633,
        "urn": "urn:li:region:8633",
        "states": []
    },
    {
        "name": {
            "value": "Yogyakarta, Yogyakarta, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8634,
        "urn": "urn:li:region:8634",
        "states": []
    },
    {
        "name": {
            "value": "Serang, Banten, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8635,
        "urn": "urn:li:region:8635",
        "states": []
    },
    {
        "name": {
            "value": "Makassar, South Sulawesi, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8636,
        "urn": "urn:li:region:8636",
        "states": []
    },
    {
        "name": {
            "value": "Gorontalo, Gorontalo, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8637,
        "urn": "urn:li:region:8637",
        "states": []
    },
    {
        "name": {
            "value": "Bandung, West Java, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8638,
        "urn": "urn:li:region:8638",
        "states": []
    },
    {
        "name": {
            "value": "Banjarmasin, South Kalimantan, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8639,
        "urn": "urn:li:region:8639",
        "states": []
    },
    {
        "name": {
            "value": "Padang, West Sumatera, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8640,
        "urn": "urn:li:region:8640",
        "states": []
    },
    {
        "name": {
            "value": "Palu, Central Sulawesi, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8641,
        "urn": "urn:li:region:8641",
        "states": []
    },
    {
        "name": {
            "value": "Denpasar, Bali, Indonesia"
        },
        "country": "urn:li:country:id",
        "id": 8642,
        "urn": "urn:li:region:8642",
        "states": []
    },
    {
        "name": {
            "value": "Prague, The Capital, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8643,
        "urn": "urn:li:region:8643",
        "states": []
    },
    {
        "name": {
            "value": "District Prague-East, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8644,
        "urn": "urn:li:region:8644",
        "states": []
    },
    {
        "name": {
            "value": "District Prague-West, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8645,
        "urn": "urn:li:region:8645",
        "states": []
    },
    {
        "name": {
            "value": "District Klatovy, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8646,
        "urn": "urn:li:region:8646",
        "states": []
    },
    {
        "name": {
            "value": "District Plzen-North, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8647,
        "urn": "urn:li:region:8647",
        "states": []
    },
    {
        "name": {
            "value": "District Plzen-City, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8648,
        "urn": "urn:li:region:8648",
        "states": []
    },
    {
        "name": {
            "value": "District Rokycany, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8649,
        "urn": "urn:li:region:8649",
        "states": []
    },
    {
        "name": {
            "value": "District Domazlice, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8650,
        "urn": "urn:li:region:8650",
        "states": []
    },
    {
        "name": {
            "value": "District Tachov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8651,
        "urn": "urn:li:region:8651",
        "states": []
    },
    {
        "name": {
            "value": "District Plzen-South, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8652,
        "urn": "urn:li:region:8652",
        "states": []
    },
    {
        "name": {
            "value": "District Cheb, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8653,
        "urn": "urn:li:region:8653",
        "states": []
    },
    {
        "name": {
            "value": "District Karlovy Vary, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8654,
        "urn": "urn:li:region:8654",
        "states": []
    },
    {
        "name": {
            "value": "District Sokolov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8655,
        "urn": "urn:li:region:8655",
        "states": []
    },
    {
        "name": {
            "value": "District Ostrava-City, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8656,
        "urn": "urn:li:region:8656",
        "states": []
    },
    {
        "name": {
            "value": "District Bruntal, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8657,
        "urn": "urn:li:region:8657",
        "states": []
    },
    {
        "name": {
            "value": "District Frydek-Mistek, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8658,
        "urn": "urn:li:region:8658",
        "states": []
    },
    {
        "name": {
            "value": "District Novy Jicin, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8659,
        "urn": "urn:li:region:8659",
        "states": []
    },
    {
        "name": {
            "value": "District Karvina, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8660,
        "urn": "urn:li:region:8660",
        "states": []
    },
    {
        "name": {
            "value": "District Opava, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8661,
        "urn": "urn:li:region:8661",
        "states": []
    },
    {
        "name": {
            "value": "District Most, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8662,
        "urn": "urn:li:region:8662",
        "states": []
    },
    {
        "name": {
            "value": "District Litomerice, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8663,
        "urn": "urn:li:region:8663",
        "states": []
    },
    {
        "name": {
            "value": "District Teplice, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8664,
        "urn": "urn:li:region:8664",
        "states": []
    },
    {
        "name": {
            "value": "District Louny, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8665,
        "urn": "urn:li:region:8665",
        "states": []
    },
    {
        "name": {
            "value": "District Decin, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8666,
        "urn": "urn:li:region:8666",
        "states": []
    },
    {
        "name": {
            "value": "District Usti nad Labem, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8667,
        "urn": "urn:li:region:8667",
        "states": []
    },
    {
        "name": {
            "value": "District Chomutov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8668,
        "urn": "urn:li:region:8668",
        "states": []
    },
    {
        "name": {
            "value": "District Prostejov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8669,
        "urn": "urn:li:region:8669",
        "states": []
    },
    {
        "name": {
            "value": "District Prerov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8670,
        "urn": "urn:li:region:8670",
        "states": []
    },
    {
        "name": {
            "value": "District Jesenik, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8671,
        "urn": "urn:li:region:8671",
        "states": []
    },
    {
        "name": {
            "value": "District Sumperk, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8672,
        "urn": "urn:li:region:8672",
        "states": []
    },
    {
        "name": {
            "value": "District Olomouc, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8673,
        "urn": "urn:li:region:8673",
        "states": []
    },
    {
        "name": {
            "value": "District Usti nad Orlici, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8674,
        "urn": "urn:li:region:8674",
        "states": []
    },
    {
        "name": {
            "value": "District Pardubice, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8675,
        "urn": "urn:li:region:8675",
        "states": []
    },
    {
        "name": {
            "value": "District Chrudim, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8676,
        "urn": "urn:li:region:8676",
        "states": []
    },
    {
        "name": {
            "value": "District Svitavy, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8677,
        "urn": "urn:li:region:8677",
        "states": []
    },
    {
        "name": {
            "value": "District Nachod, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8678,
        "urn": "urn:li:region:8678",
        "states": []
    },
    {
        "name": {
            "value": "District Jicin, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8679,
        "urn": "urn:li:region:8679",
        "states": []
    },
    {
        "name": {
            "value": "District Trutnov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8680,
        "urn": "urn:li:region:8680",
        "states": []
    },
    {
        "name": {
            "value": "District Rychnov nad Kneznou, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8681,
        "urn": "urn:li:region:8681",
        "states": []
    },
    {
        "name": {
            "value": "District Hradec Kralove, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8682,
        "urn": "urn:li:region:8682",
        "states": []
    },
    {
        "name": {
            "value": "District Prachatice, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8683,
        "urn": "urn:li:region:8683",
        "states": []
    },
    {
        "name": {
            "value": "District Strakonice, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8684,
        "urn": "urn:li:region:8684",
        "states": []
    },
    {
        "name": {
            "value": "District Ceske Budejovice, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8685,
        "urn": "urn:li:region:8685",
        "states": []
    },
    {
        "name": {
            "value": "District Cesky Krumlov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8686,
        "urn": "urn:li:region:8686",
        "states": []
    },
    {
        "name": {
            "value": "District Tabor, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8687,
        "urn": "urn:li:region:8687",
        "states": []
    },
    {
        "name": {
            "value": "District Pisek, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8688,
        "urn": "urn:li:region:8688",
        "states": []
    },
    {
        "name": {
            "value": "District Jindrichuv Hradec, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8689,
        "urn": "urn:li:region:8689",
        "states": []
    },
    {
        "name": {
            "value": "District Zlin, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8690,
        "urn": "urn:li:region:8690",
        "states": []
    },
    {
        "name": {
            "value": "District Uherske Hradiste, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8691,
        "urn": "urn:li:region:8691",
        "states": []
    },
    {
        "name": {
            "value": "District Vsetin, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8692,
        "urn": "urn:li:region:8692",
        "states": []
    },
    {
        "name": {
            "value": "District Kromeriz, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8693,
        "urn": "urn:li:region:8693",
        "states": []
    },
    {
        "name": {
            "value": "District Vyskov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8694,
        "urn": "urn:li:region:8694",
        "states": []
    },
    {
        "name": {
            "value": "District Blansko, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8695,
        "urn": "urn:li:region:8695",
        "states": []
    },
    {
        "name": {
            "value": "District Hodonin, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8696,
        "urn": "urn:li:region:8696",
        "states": []
    },
    {
        "name": {
            "value": "District Breclav, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8697,
        "urn": "urn:li:region:8697",
        "states": []
    },
    {
        "name": {
            "value": "District Znojmo, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8698,
        "urn": "urn:li:region:8698",
        "states": []
    },
    {
        "name": {
            "value": "District Brno-City, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8699,
        "urn": "urn:li:region:8699",
        "states": []
    },
    {
        "name": {
            "value": "District Brno-Country, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8700,
        "urn": "urn:li:region:8700",
        "states": []
    },
    {
        "name": {
            "value": "District Semily, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8701,
        "urn": "urn:li:region:8701",
        "states": []
    },
    {
        "name": {
            "value": "District Liberec, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8702,
        "urn": "urn:li:region:8702",
        "states": []
    },
    {
        "name": {
            "value": "District Ceska Lipa, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8703,
        "urn": "urn:li:region:8703",
        "states": []
    },
    {
        "name": {
            "value": "District Jablonec nad Nisou, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8704,
        "urn": "urn:li:region:8704",
        "states": []
    },
    {
        "name": {
            "value": "District Pelhrimov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8705,
        "urn": "urn:li:region:8705",
        "states": []
    },
    {
        "name": {
            "value": "District Zdar nad Sazavou, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8706,
        "urn": "urn:li:region:8706",
        "states": []
    },
    {
        "name": {
            "value": "District Havlickuv Brod, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8707,
        "urn": "urn:li:region:8707",
        "states": []
    },
    {
        "name": {
            "value": "District Trebic, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8708,
        "urn": "urn:li:region:8708",
        "states": []
    },
    {
        "name": {
            "value": "District Jihlava, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8709,
        "urn": "urn:li:region:8709",
        "states": []
    },
    {
        "name": {
            "value": "District Rakovnik, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8710,
        "urn": "urn:li:region:8710",
        "states": []
    },
    {
        "name": {
            "value": "District Beroun, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8711,
        "urn": "urn:li:region:8711",
        "states": []
    },
    {
        "name": {
            "value": "District Kladno, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8712,
        "urn": "urn:li:region:8712",
        "states": []
    },
    {
        "name": {
            "value": "District Benesov, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8713,
        "urn": "urn:li:region:8713",
        "states": []
    },
    {
        "name": {
            "value": "District Nymburk, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8714,
        "urn": "urn:li:region:8714",
        "states": []
    },
    {
        "name": {
            "value": "District Kutna Hora, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8715,
        "urn": "urn:li:region:8715",
        "states": []
    },
    {
        "name": {
            "value": "District Pribram, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8716,
        "urn": "urn:li:region:8716",
        "states": []
    },
    {
        "name": {
            "value": "District Mlada Boleslav, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8717,
        "urn": "urn:li:region:8717",
        "states": []
    },
    {
        "name": {
            "value": "District Melnik, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8718,
        "urn": "urn:li:region:8718",
        "states": []
    },
    {
        "name": {
            "value": "District Kolin, Czech Republic"
        },
        "country": "urn:li:country:cz",
        "id": 8719,
        "urn": "urn:li:region:8719",
        "states": []
    },
    {
        "name": {
            "value": "Agrigento, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8827,
        "urn": "urn:li:region:8827",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Alessandria, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8828,
        "urn": "urn:li:region:8828",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)"
        ]
    },
    {
        "name": {
            "value": "Ancona, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8829,
        "urn": "urn:li:region:8829",
        "states": [
            "urn:li:state:(urn:li:country:it,MH)"
        ]
    },
    {
        "name": {
            "value": "Aosta, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8830,
        "urn": "urn:li:region:8830",
        "states": [
            "urn:li:state:(urn:li:country:it,VD)"
        ]
    },
    {
        "name": {
            "value": "Ascoli Piceno, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8831,
        "urn": "urn:li:region:8831",
        "states": [
            "urn:li:state:(urn:li:country:it,MH)"
        ]
    },
    {
        "name": {
            "value": "Avellino, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8832,
        "urn": "urn:li:region:8832",
        "states": [
            "urn:li:state:(urn:li:country:it,CM)"
        ]
    },
    {
        "name": {
            "value": "Barletta-Andria-Trani, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8833,
        "urn": "urn:li:region:8833",
        "states": [
            "urn:li:state:(urn:li:country:it,PU)"
        ]
    },
    {
        "name": {
            "value": "Belluno, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8834,
        "urn": "urn:li:region:8834",
        "states": [
            "urn:li:state:(urn:li:country:it,VN)"
        ]
    },
    {
        "name": {
            "value": "Biella, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8835,
        "urn": "urn:li:region:8835",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)"
        ]
    },
    {
        "name": {
            "value": "Brindisi, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8836,
        "urn": "urn:li:region:8836",
        "states": [
            "urn:li:state:(urn:li:country:it,PU)"
        ]
    },
    {
        "name": {
            "value": "Campobasso, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8837,
        "urn": "urn:li:region:8837",
        "states": [
            "urn:li:state:(urn:li:country:it,ML)"
        ]
    },
    {
        "name": {
            "value": "Carbonia Iglesias, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8838,
        "urn": "urn:li:region:8838",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Cremona, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8839,
        "urn": "urn:li:region:8839",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Crotone, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8840,
        "urn": "urn:li:region:8840",
        "states": [
            "urn:li:state:(urn:li:country:it,CI)"
        ]
    },
    {
        "name": {
            "value": "Enna, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8841,
        "urn": "urn:li:region:8841",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Fermo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8842,
        "urn": "urn:li:region:8842",
        "states": [
            "urn:li:state:(urn:li:country:it,MH)"
        ]
    },
    {
        "name": {
            "value": "Ferrara, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8843,
        "urn": "urn:li:region:8843",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Forlì Cesena, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8844,
        "urn": "urn:li:region:8844",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Frosinone, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8845,
        "urn": "urn:li:region:8845",
        "states": [
            "urn:li:state:(urn:li:country:it,LZ)"
        ]
    },
    {
        "name": {
            "value": "Gorizia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8846,
        "urn": "urn:li:region:8846",
        "states": [
            "urn:li:state:(urn:li:country:it,FV)"
        ]
    },
    {
        "name": {
            "value": "Imperia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8847,
        "urn": "urn:li:region:8847",
        "states": [
            "urn:li:state:(urn:li:country:it,LG)"
        ]
    },
    {
        "name": {
            "value": "Isernia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8848,
        "urn": "urn:li:region:8848",
        "states": [
            "urn:li:state:(urn:li:country:it,ML)"
        ]
    },
    {
        "name": {
            "value": "Lecco, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8849,
        "urn": "urn:li:region:8849",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Lodi, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8850,
        "urn": "urn:li:region:8850",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Lucca, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8851,
        "urn": "urn:li:region:8851",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Macerata, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8852,
        "urn": "urn:li:region:8852",
        "states": [
            "urn:li:state:(urn:li:country:it,MH)"
        ]
    },
    {
        "name": {
            "value": "Mantova, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8853,
        "urn": "urn:li:region:8853",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Massa Carrara, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8854,
        "urn": "urn:li:region:8854",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Medio Campidano, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8855,
        "urn": "urn:li:region:8855",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Nuoro, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8856,
        "urn": "urn:li:region:8856",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Ogliastra, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8857,
        "urn": "urn:li:region:8857",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Olbia Tempio, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8858,
        "urn": "urn:li:region:8858",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Oristano, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8859,
        "urn": "urn:li:region:8859",
        "states": [
            "urn:li:state:(urn:li:country:it,SD)"
        ]
    },
    {
        "name": {
            "value": "Pavia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8860,
        "urn": "urn:li:region:8860",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Pesaro Urbino, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8861,
        "urn": "urn:li:region:8861",
        "states": [
            "urn:li:state:(urn:li:country:it,MH)"
        ]
    },
    {
        "name": {
            "value": "Piacenza, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8862,
        "urn": "urn:li:region:8862",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Pisa, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8863,
        "urn": "urn:li:region:8863",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Pistoia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8864,
        "urn": "urn:li:region:8864",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Ragusa, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8865,
        "urn": "urn:li:region:8865",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Reggio Emilia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8866,
        "urn": "urn:li:region:8866",
        "states": [
            "urn:li:state:(urn:li:country:it,ER)"
        ]
    },
    {
        "name": {
            "value": "Rieti, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8867,
        "urn": "urn:li:region:8867",
        "states": [
            "urn:li:state:(urn:li:country:it,LZ)"
        ]
    },
    {
        "name": {
            "value": "Rovigo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8868,
        "urn": "urn:li:region:8868",
        "states": [
            "urn:li:state:(urn:li:country:it,VN)"
        ]
    },
    {
        "name": {
            "value": "Siena, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8869,
        "urn": "urn:li:region:8869",
        "states": [
            "urn:li:state:(urn:li:country:it,TC)"
        ]
    },
    {
        "name": {
            "value": "Sondrio, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8870,
        "urn": "urn:li:region:8870",
        "states": [
            "urn:li:state:(urn:li:country:it,LM)"
        ]
    },
    {
        "name": {
            "value": "Teramo, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8871,
        "urn": "urn:li:region:8871",
        "states": [
            "urn:li:state:(urn:li:country:it,AB)"
        ]
    },
    {
        "name": {
            "value": "Trapani, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8872,
        "urn": "urn:li:region:8872",
        "states": [
            "urn:li:state:(urn:li:country:it,SC)"
        ]
    },
    {
        "name": {
            "value": "Verbano-Cusio-Ossola, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8873,
        "urn": "urn:li:region:8873",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)"
        ]
    },
    {
        "name": {
            "value": "Vercelli, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8874,
        "urn": "urn:li:region:8874",
        "states": [
            "urn:li:state:(urn:li:country:it,PM)"
        ]
    },
    {
        "name": {
            "value": "Vibo Valentia, Italy"
        },
        "country": "urn:li:country:it",
        "id": 8875,
        "urn": "urn:li:region:8875",
        "states": [
            "urn:li:state:(urn:li:country:it,CI)"
        ]
    },
    {
        "name": {
            "value": "Rest of Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8876,
        "urn": "urn:li:region:8876",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Rest of Yunnan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8877,
        "urn": "urn:li:region:8877",
        "states": [
            "urn:li:state:(urn:li:country:cn,YN)"
        ]
    },
    {
        "name": {
            "value": "Rest of Xinjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8878,
        "urn": "urn:li:region:8878",
        "states": [
            "urn:li:state:(urn:li:country:cn,XJ)"
        ]
    },
    {
        "name": {
            "value": "Rest of Tibet, China"
        },
        "country": "urn:li:country:cn",
        "id": 8879,
        "urn": "urn:li:region:8879",
        "states": [
            "urn:li:state:(urn:li:country:cn,TB)"
        ]
    },
    {
        "name": {
            "value": "Tianjin Suburb, China"
        },
        "country": "urn:li:country:cn",
        "id": 8880,
        "urn": "urn:li:region:8880",
        "states": []
    },
    {
        "name": {
            "value": "Rest of Sichuan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8881,
        "urn": "urn:li:region:8881",
        "states": [
            "urn:li:state:(urn:li:country:cn,SC)"
        ]
    },
    {
        "name": {
            "value": "Rest of Shanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 8882,
        "urn": "urn:li:region:8882",
        "states": [
            "urn:li:state:(urn:li:country:cn,SX)"
        ]
    },
    {
        "name": {
            "value": "Shanghai Suburb, China"
        },
        "country": "urn:li:country:cn",
        "id": 8883,
        "urn": "urn:li:region:8883",
        "states": []
    },
    {
        "name": {
            "value": "Rest of Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8884,
        "urn": "urn:li:region:8884",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Rest of Shaanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 8885,
        "urn": "urn:li:region:8885",
        "states": [
            "urn:li:state:(urn:li:country:cn,SA)"
        ]
    },
    {
        "name": {
            "value": "Rest of Qinghai, China"
        },
        "country": "urn:li:country:cn",
        "id": 8886,
        "urn": "urn:li:region:8886",
        "states": [
            "urn:li:state:(urn:li:country:cn,QH)"
        ]
    },
    {
        "name": {
            "value": "Rest of Ningxia, China"
        },
        "country": "urn:li:country:cn",
        "id": 8887,
        "urn": "urn:li:region:8887",
        "states": [
            "urn:li:state:(urn:li:country:cn,NX)"
        ]
    },
    {
        "name": {
            "value": "Rest of Liaoning, China"
        },
        "country": "urn:li:country:cn",
        "id": 8888,
        "urn": "urn:li:region:8888",
        "states": [
            "urn:li:state:(urn:li:country:cn,LN)"
        ]
    },
    {
        "name": {
            "value": "Rest of Jilin, China"
        },
        "country": "urn:li:country:cn",
        "id": 8889,
        "urn": "urn:li:region:8889",
        "states": [
            "urn:li:state:(urn:li:country:cn,JL)"
        ]
    },
    {
        "name": {
            "value": "Rest of Jiangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 8890,
        "urn": "urn:li:region:8890",
        "states": [
            "urn:li:state:(urn:li:country:cn,JX)"
        ]
    },
    {
        "name": {
            "value": "Rest of Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8891,
        "urn": "urn:li:region:8891",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Rest of Inner Mongolia, China"
        },
        "country": "urn:li:country:cn",
        "id": 8892,
        "urn": "urn:li:region:8892",
        "states": [
            "urn:li:state:(urn:li:country:cn,IM)"
        ]
    },
    {
        "name": {
            "value": "Rest of Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8893,
        "urn": "urn:li:region:8893",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Rest of Hubei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8894,
        "urn": "urn:li:region:8894",
        "states": [
            "urn:li:state:(urn:li:country:cn,HU)"
        ]
    },
    {
        "name": {
            "value": "Rest of Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8895,
        "urn": "urn:li:region:8895",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Rest of Heilongjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8896,
        "urn": "urn:li:region:8896",
        "states": [
            "urn:li:state:(urn:li:country:cn,HL)"
        ]
    },
    {
        "name": {
            "value": "Rest of Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8897,
        "urn": "urn:li:region:8897",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Rest of Hainan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8898,
        "urn": "urn:li:region:8898",
        "states": [
            "urn:li:state:(urn:li:country:cn,HA)"
        ]
    },
    {
        "name": {
            "value": "Rest of Guizhou, China"
        },
        "country": "urn:li:country:cn",
        "id": 8899,
        "urn": "urn:li:region:8899",
        "states": [
            "urn:li:state:(urn:li:country:cn,GZ)"
        ]
    },
    {
        "name": {
            "value": "Rest of Guangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 8900,
        "urn": "urn:li:region:8900",
        "states": [
            "urn:li:state:(urn:li:country:cn,GX)"
        ]
    },
    {
        "name": {
            "value": "Rest of Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8901,
        "urn": "urn:li:region:8901",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Rest of Gansu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8902,
        "urn": "urn:li:region:8902",
        "states": [
            "urn:li:state:(urn:li:country:cn,GS)"
        ]
    },
    {
        "name": {
            "value": "Rest of Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8903,
        "urn": "urn:li:region:8903",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Chongqing Suburb, China"
        },
        "country": "urn:li:country:cn",
        "id": 8904,
        "urn": "urn:li:region:8904",
        "states": []
    },
    {
        "name": {
            "value": "Beijing Suburb, China"
        },
        "country": "urn:li:country:cn",
        "id": 8905,
        "urn": "urn:li:region:8905",
        "states": []
    },
    {
        "name": {
            "value": "Rest of Anhui, China"
        },
        "country": "urn:li:country:cn",
        "id": 8906,
        "urn": "urn:li:region:8906",
        "states": [
            "urn:li:state:(urn:li:country:cn,AH)"
        ]
    },
    {
        "name": {
            "value": "Tianjin City, China"
        },
        "country": "urn:li:country:cn",
        "id": 8907,
        "urn": "urn:li:region:8907",
        "states": [
            "urn:li:state:(urn:li:country:cn,TJ)"
        ]
    },
    {
        "name": {
            "value": "Chongqing City, China"
        },
        "country": "urn:li:country:cn",
        "id": 8908,
        "urn": "urn:li:region:8908",
        "states": [
            "urn:li:state:(urn:li:country:cn,CQ)",
            "urn:li:state:(urn:li:country:cn,HU)"
        ]
    },
    {
        "name": {
            "value": "Shanghai City, China"
        },
        "country": "urn:li:country:cn",
        "id": 8909,
        "urn": "urn:li:region:8909",
        "states": [
            "urn:li:state:(urn:li:country:cn,SH)"
        ]
    },
    {
        "name": {
            "value": "Shenzhen, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8910,
        "urn": "urn:li:region:8910",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Beijing City, China"
        },
        "country": "urn:li:country:cn",
        "id": 8911,
        "urn": "urn:li:region:8911",
        "states": [
            "urn:li:state:(urn:li:country:cn,BJ)"
        ]
    },
    {
        "name": {
            "value": "Fuzhou, Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8912,
        "urn": "urn:li:region:8912",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Xiamen, Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8913,
        "urn": "urn:li:region:8913",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Nanping, Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8914,
        "urn": "urn:li:region:8914",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Quanzhou, Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8915,
        "urn": "urn:li:region:8915",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Longyan, Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8916,
        "urn": "urn:li:region:8916",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Zhangzhou, Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8917,
        "urn": "urn:li:region:8917",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Putian, Fujian, China"
        },
        "country": "urn:li:country:cn",
        "id": 8918,
        "urn": "urn:li:region:8918",
        "states": [
            "urn:li:state:(urn:li:country:cn,FJ)"
        ]
    },
    {
        "name": {
            "value": "Guangzhou, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8919,
        "urn": "urn:li:region:8919",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Hefei, Anhui, China"
        },
        "country": "urn:li:country:cn",
        "id": 8920,
        "urn": "urn:li:region:8920",
        "states": [
            "urn:li:state:(urn:li:country:cn,AH)"
        ]
    },
    {
        "name": {
            "value": "Wuhu, Anhui, China"
        },
        "country": "urn:li:country:cn",
        "id": 8921,
        "urn": "urn:li:region:8921",
        "states": [
            "urn:li:state:(urn:li:country:cn,AH)"
        ]
    },
    {
        "name": {
            "value": "Bengbu, Anhui, China"
        },
        "country": "urn:li:country:cn",
        "id": 8922,
        "urn": "urn:li:region:8922",
        "states": [
            "urn:li:state:(urn:li:country:cn,AH)"
        ]
    },
    {
        "name": {
            "value": "Foshan, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8923,
        "urn": "urn:li:region:8923",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Dongguan, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8924,
        "urn": "urn:li:region:8924",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Huizhou, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8925,
        "urn": "urn:li:region:8925",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Jieyang, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8926,
        "urn": "urn:li:region:8926",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Shantou, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8927,
        "urn": "urn:li:region:8927",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Zhanjiang, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8928,
        "urn": "urn:li:region:8928",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Maoming, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8929,
        "urn": "urn:li:region:8929",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Zhuhai, Guangdong, China"
        },
        "country": "urn:li:country:cn",
        "id": 8930,
        "urn": "urn:li:region:8930",
        "states": [
            "urn:li:state:(urn:li:country:cn,GD)"
        ]
    },
    {
        "name": {
            "value": "Hangzhou, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8931,
        "urn": "urn:li:region:8931",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Ningbo, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8932,
        "urn": "urn:li:region:8932",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Jiaxing, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8933,
        "urn": "urn:li:region:8933",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Huzhou, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8934,
        "urn": "urn:li:region:8934",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Jinhua, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8935,
        "urn": "urn:li:region:8935",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Shaoxing, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8936,
        "urn": "urn:li:region:8936",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Taizhou, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8937,
        "urn": "urn:li:region:8937",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Wenzhou, Zhejiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8938,
        "urn": "urn:li:region:8938",
        "states": [
            "urn:li:state:(urn:li:country:cn,ZJ)"
        ]
    },
    {
        "name": {
            "value": "Nanjing, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8939,
        "urn": "urn:li:region:8939",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Changzhou, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8940,
        "urn": "urn:li:region:8940",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Huaian, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8941,
        "urn": "urn:li:region:8941",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Lianyungang, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8942,
        "urn": "urn:li:region:8942",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Nantong, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8943,
        "urn": "urn:li:region:8943",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Suzhou, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8944,
        "urn": "urn:li:region:8944",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Wuxi, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8945,
        "urn": "urn:li:region:8945",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Xuzhou, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8946,
        "urn": "urn:li:region:8946",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Yancheng, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8948,
        "urn": "urn:li:region:8948",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Yangzhou, Jiangsu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8949,
        "urn": "urn:li:region:8949",
        "states": [
            "urn:li:state:(urn:li:country:cn,JS)"
        ]
    },
    {
        "name": {
            "value": "Lanzhou, Gansu, China"
        },
        "country": "urn:li:country:cn",
        "id": 8950,
        "urn": "urn:li:region:8950",
        "states": [
            "urn:li:state:(urn:li:country:cn,GS)"
        ]
    },
    {
        "name": {
            "value": "Nanning, Guangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 8951,
        "urn": "urn:li:region:8951",
        "states": [
            "urn:li:state:(urn:li:country:cn,GX)"
        ]
    },
    {
        "name": {
            "value": "Guilin, Guangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 8952,
        "urn": "urn:li:region:8952",
        "states": [
            "urn:li:state:(urn:li:country:cn,GX)"
        ]
    },
    {
        "name": {
            "value": "Yulin, Guangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 8953,
        "urn": "urn:li:region:8953",
        "states": [
            "urn:li:state:(urn:li:country:cn,GX)"
        ]
    },
    {
        "name": {
            "value": "Lasa, Tibet, China"
        },
        "country": "urn:li:country:cn",
        "id": 8954,
        "urn": "urn:li:region:8954",
        "states": [
            "urn:li:state:(urn:li:country:cn,TB)"
        ]
    },
    {
        "name": {
            "value": "Haikou, Hainan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8955,
        "urn": "urn:li:region:8955",
        "states": [
            "urn:li:state:(urn:li:country:cn,HA)"
        ]
    },
    {
        "name": {
            "value": "Sanya, Hainan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8956,
        "urn": "urn:li:region:8956",
        "states": [
            "urn:li:state:(urn:li:country:cn,HA)"
        ]
    },
    {
        "name": {
            "value": "Guiyang, Guizhou, China"
        },
        "country": "urn:li:country:cn",
        "id": 8957,
        "urn": "urn:li:region:8957",
        "states": [
            "urn:li:state:(urn:li:country:cn,GZ)"
        ]
    },
    {
        "name": {
            "value": "Zunyi, Guizhou, China"
        },
        "country": "urn:li:country:cn",
        "id": 8958,
        "urn": "urn:li:region:8958",
        "states": [
            "urn:li:state:(urn:li:country:cn,GZ)"
        ]
    },
    {
        "name": {
            "value": "Baotou, Inner Mongolia, China"
        },
        "country": "urn:li:country:cn",
        "id": 8959,
        "urn": "urn:li:region:8959",
        "states": [
            "urn:li:state:(urn:li:country:cn,IM)"
        ]
    },
    {
        "name": {
            "value": "Huhehaote, Inner Mongolia, China"
        },
        "country": "urn:li:country:cn",
        "id": 8961,
        "urn": "urn:li:region:8961",
        "states": [
            "urn:li:state:(urn:li:country:cn,IM)"
        ]
    },
    {
        "name": {
            "value": "Chifeng, Inner Mongolia, China"
        },
        "country": "urn:li:country:cn",
        "id": 8962,
        "urn": "urn:li:region:8962",
        "states": [
            "urn:li:state:(urn:li:country:cn,IM)"
        ]
    },
    {
        "name": {
            "value": "Chengdu, Sichuan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8963,
        "urn": "urn:li:region:8963",
        "states": [
            "urn:li:state:(urn:li:country:cn,SC)",
            "urn:li:state:(urn:li:country:cn,CQ)"
        ]
    },
    {
        "name": {
            "value": "Mianyang, Sichuan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8964,
        "urn": "urn:li:region:8964",
        "states": [
            "urn:li:state:(urn:li:country:cn,SC)"
        ]
    },
    {
        "name": {
            "value": "Nanchong, Sichuan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8965,
        "urn": "urn:li:region:8965",
        "states": [
            "urn:li:state:(urn:li:country:cn,SC)"
        ]
    },
    {
        "name": {
            "value": "Deyang, Sichuan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8966,
        "urn": "urn:li:region:8966",
        "states": [
            "urn:li:state:(urn:li:country:cn,SC)"
        ]
    },
    {
        "name": {
            "value": "Luzhou, Sichuan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8967,
        "urn": "urn:li:region:8967",
        "states": [
            "urn:li:state:(urn:li:country:cn,SC)"
        ]
    },
    {
        "name": {
            "value": "Yibin, Sichuan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8968,
        "urn": "urn:li:region:8968",
        "states": [
            "urn:li:state:(urn:li:country:cn,SC)"
        ]
    },
    {
        "name": {
            "value": "Shijiazhuang, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8969,
        "urn": "urn:li:region:8969",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Baoding, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8970,
        "urn": "urn:li:region:8970",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Handan, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8971,
        "urn": "urn:li:region:8971",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Hengshui, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8972,
        "urn": "urn:li:region:8972",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Langfang, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8973,
        "urn": "urn:li:region:8973",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Tangshan, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8974,
        "urn": "urn:li:region:8974",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Xingtai, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8975,
        "urn": "urn:li:region:8975",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Zhangjiakou, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8976,
        "urn": "urn:li:region:8976",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Qinhuangdao, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8977,
        "urn": "urn:li:region:8977",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Chengde, Hebei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8978,
        "urn": "urn:li:region:8978",
        "states": [
            "urn:li:state:(urn:li:country:cn,HB)"
        ]
    },
    {
        "name": {
            "value": "Wuhan, Hubei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8979,
        "urn": "urn:li:region:8979",
        "states": [
            "urn:li:state:(urn:li:country:cn,HU)"
        ]
    },
    {
        "name": {
            "value": "Huanggang, Hubei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8981,
        "urn": "urn:li:region:8981",
        "states": [
            "urn:li:state:(urn:li:country:cn,HU)"
        ]
    },
    {
        "name": {
            "value": "Jingzhou, Hubei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8982,
        "urn": "urn:li:region:8982",
        "states": [
            "urn:li:state:(urn:li:country:cn,HU)"
        ]
    },
    {
        "name": {
            "value": "Xiaogan, Hubei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8983,
        "urn": "urn:li:region:8983",
        "states": [
            "urn:li:state:(urn:li:country:cn,HU)"
        ]
    },
    {
        "name": {
            "value": "Yichang, Hubei, China"
        },
        "country": "urn:li:country:cn",
        "id": 8984,
        "urn": "urn:li:region:8984",
        "states": [
            "urn:li:state:(urn:li:country:cn,HU)"
        ]
    },
    {
        "name": {
            "value": "Changsha, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8985,
        "urn": "urn:li:region:8985",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Changde, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8986,
        "urn": "urn:li:region:8986",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Chenzhou, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8987,
        "urn": "urn:li:region:8987",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Hengyang, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8988,
        "urn": "urn:li:region:8988",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Huaihua, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8989,
        "urn": "urn:li:region:8989",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Shaoyang, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8990,
        "urn": "urn:li:region:8990",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Yiyang, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8991,
        "urn": "urn:li:region:8991",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Yongzhou, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8992,
        "urn": "urn:li:region:8992",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Yueyang, Hunan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8993,
        "urn": "urn:li:region:8993",
        "states": [
            "urn:li:state:(urn:li:country:cn,HN)"
        ]
    },
    {
        "name": {
            "value": "Haerbin, Heilongjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8994,
        "urn": "urn:li:region:8994",
        "states": [
            "urn:li:state:(urn:li:country:cn,HL)"
        ]
    },
    {
        "name": {
            "value": "Qiqihaer, Heilongjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8995,
        "urn": "urn:li:region:8995",
        "states": [
            "urn:li:state:(urn:li:country:cn,HL)"
        ]
    },
    {
        "name": {
            "value": "Suihua, Heilongjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8996,
        "urn": "urn:li:region:8996",
        "states": [
            "urn:li:state:(urn:li:country:cn,HL)"
        ]
    },
    {
        "name": {
            "value": "Daqing, Heilongjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 8997,
        "urn": "urn:li:region:8997",
        "states": [
            "urn:li:state:(urn:li:country:cn,HL)"
        ]
    },
    {
        "name": {
            "value": "Zhengzhou, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8998,
        "urn": "urn:li:region:8998",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Anyang, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 8999,
        "urn": "urn:li:region:8999",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Kaifeng, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9000,
        "urn": "urn:li:region:9000",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Luoyang, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9001,
        "urn": "urn:li:region:9001",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Nanyang, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9002,
        "urn": "urn:li:region:9002",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Shangqiu, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9003,
        "urn": "urn:li:region:9003",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Zhoukou, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9004,
        "urn": "urn:li:region:9004",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Zhumadian, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9005,
        "urn": "urn:li:region:9005",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Pingdingshan, Henan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9006,
        "urn": "urn:li:region:9006",
        "states": [
            "urn:li:state:(urn:li:country:cn,HE)"
        ]
    },
    {
        "name": {
            "value": "Nanchang, Jiangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9007,
        "urn": "urn:li:region:9007",
        "states": [
            "urn:li:state:(urn:li:country:cn,JX)"
        ]
    },
    {
        "name": {
            "value": "Ganzhou, Jiangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9009,
        "urn": "urn:li:region:9009",
        "states": [
            "urn:li:state:(urn:li:country:cn,JX)"
        ]
    },
    {
        "name": {
            "value": "Shangrao, Jiangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9010,
        "urn": "urn:li:region:9010",
        "states": [
            "urn:li:state:(urn:li:country:cn,JX)"
        ]
    },
    {
        "name": {
            "value": "Yichun, Jiangxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9011,
        "urn": "urn:li:region:9011",
        "states": [
            "urn:li:state:(urn:li:country:cn,JX)"
        ]
    },
    {
        "name": {
            "value": "Changchun, Jilin, China"
        },
        "country": "urn:li:country:cn",
        "id": 9014,
        "urn": "urn:li:region:9014",
        "states": [
            "urn:li:state:(urn:li:country:cn,JL)"
        ]
    },
    {
        "name": {
            "value": "Jilin, Jilin, China"
        },
        "country": "urn:li:country:cn",
        "id": 9015,
        "urn": "urn:li:region:9015",
        "states": [
            "urn:li:state:(urn:li:country:cn,JL)",
            "urn:li:state:(urn:li:country:cn,JL)"
        ]
    },
    {
        "name": {
            "value": "Siping, Jilin, China"
        },
        "country": "urn:li:country:cn",
        "id": 9016,
        "urn": "urn:li:region:9016",
        "states": [
            "urn:li:state:(urn:li:country:cn,JL)"
        ]
    },
    {
        "name": {
            "value": "Shenyang, Liaoning, China"
        },
        "country": "urn:li:country:cn",
        "id": 9017,
        "urn": "urn:li:region:9017",
        "states": [
            "urn:li:state:(urn:li:country:cn,LN)"
        ]
    },
    {
        "name": {
            "value": "Dalian, Liaoning, China"
        },
        "country": "urn:li:country:cn",
        "id": 9018,
        "urn": "urn:li:region:9018",
        "states": [
            "urn:li:state:(urn:li:country:cn,LN)"
        ]
    },
    {
        "name": {
            "value": "Anshan, Liaoning, China"
        },
        "country": "urn:li:country:cn",
        "id": 9019,
        "urn": "urn:li:region:9019",
        "states": [
            "urn:li:state:(urn:li:country:cn,LN)"
        ]
    },
    {
        "name": {
            "value": "Yinchuan, Ningxia, China"
        },
        "country": "urn:li:country:cn",
        "id": 9020,
        "urn": "urn:li:region:9020",
        "states": [
            "urn:li:state:(urn:li:country:cn,NX)"
        ]
    },
    {
        "name": {
            "value": "Xining, Qinghai, China"
        },
        "country": "urn:li:country:cn",
        "id": 9021,
        "urn": "urn:li:region:9021",
        "states": [
            "urn:li:state:(urn:li:country:cn,QH)"
        ]
    },
    {
        "name": {
            "value": "Xi‘an, Shaanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9022,
        "urn": "urn:li:region:9022",
        "states": [
            "urn:li:state:(urn:li:country:cn,SA)"
        ]
    },
    {
        "name": {
            "value": "Baoji, Shaanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9024,
        "urn": "urn:li:region:9024",
        "states": [
            "urn:li:state:(urn:li:country:cn,SA)"
        ]
    },
    {
        "name": {
            "value": "Weinan, Shaanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9025,
        "urn": "urn:li:region:9025",
        "states": [
            "urn:li:state:(urn:li:country:cn,SA)"
        ]
    },
    {
        "name": {
            "value": "Xianyang, Shaanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9026,
        "urn": "urn:li:region:9026",
        "states": [
            "urn:li:state:(urn:li:country:cn,SA)"
        ]
    },
    {
        "name": {
            "value": "Jinan, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9027,
        "urn": "urn:li:region:9027",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Qingdao, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9028,
        "urn": "urn:li:region:9028",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Weifang, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9029,
        "urn": "urn:li:region:9029",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Weihai, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9030,
        "urn": "urn:li:region:9030",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Yantai, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9031,
        "urn": "urn:li:region:9031",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Jining, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9032,
        "urn": "urn:li:region:9032",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Liaocheng, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9033,
        "urn": "urn:li:region:9033",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Linyi, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9034,
        "urn": "urn:li:region:9034",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Heze, Shandong, China"
        },
        "country": "urn:li:country:cn",
        "id": 9035,
        "urn": "urn:li:region:9035",
        "states": [
            "urn:li:state:(urn:li:country:cn,SD)"
        ]
    },
    {
        "name": {
            "value": "Taiyuan, Shanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9036,
        "urn": "urn:li:region:9036",
        "states": [
            "urn:li:state:(urn:li:country:cn,SX)"
        ]
    },
    {
        "name": {
            "value": "Linfen, Shanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9037,
        "urn": "urn:li:region:9037",
        "states": [
            "urn:li:state:(urn:li:country:cn,SX)"
        ]
    },
    {
        "name": {
            "value": "Yuncheng, Shanxi, China"
        },
        "country": "urn:li:country:cn",
        "id": 9038,
        "urn": "urn:li:region:9038",
        "states": [
            "urn:li:state:(urn:li:country:cn,SX)"
        ]
    },
    {
        "name": {
            "value": "Kunming, Yunnan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9039,
        "urn": "urn:li:region:9039",
        "states": [
            "urn:li:state:(urn:li:country:cn,YN)"
        ]
    },
    {
        "name": {
            "value": "Qujing, Yunnan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9040,
        "urn": "urn:li:region:9040",
        "states": [
            "urn:li:state:(urn:li:country:cn,YN)"
        ]
    },
    {
        "name": {
            "value": "Zhaotong, Yunnan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9041,
        "urn": "urn:li:region:9041",
        "states": [
            "urn:li:state:(urn:li:country:cn,YN)"
        ]
    },
    {
        "name": {
            "value": "Lijiang, Yunnan, China"
        },
        "country": "urn:li:country:cn",
        "id": 9042,
        "urn": "urn:li:region:9042",
        "states": [
            "urn:li:state:(urn:li:country:cn,YN)"
        ]
    },
    {
        "name": {
            "value": "Urumqi, Xinjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 9043,
        "urn": "urn:li:region:9043",
        "states": [
            "urn:li:state:(urn:li:country:cn,XJ)"
        ]
    },
    {
        "name": {
            "value": "Ili Prefecture, Xinjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 9044,
        "urn": "urn:li:region:9044",
        "states": [
            "urn:li:state:(urn:li:country:cn,XJ)"
        ]
    },
    {
        "name": {
            "value": "Kashgar Prefecture, Xinjiang, China"
        },
        "country": "urn:li:country:cn",
        "id": 9045,
        "urn": "urn:li:region:9045",
        "states": [
            "urn:li:state:(urn:li:country:cn,XJ)"
        ]
    },
    {
        "name": {
            "value": "West Sussex, United Kingdom, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 9190,
        "urn": "urn:li:region:9190",
        "states": []
    },
    {
        "name": {
            "value": "South Lanarkshire, United Kingdom, United Kingdom"
        },
        "country": "urn:li:country:gb",
        "id": 9191,
        "urn": "urn:li:region:9191",
        "states": []
    },
    {
        "name": {
            "value": "Auckland, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9194,
        "urn": "urn:li:region:9194",
        "states": []
    },
    {
        "name": {
            "value": "Bay of Plenty, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9195,
        "urn": "urn:li:region:9195",
        "states": []
    },
    {
        "name": {
            "value": "Canterbury & West Coast, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9196,
        "urn": "urn:li:region:9196",
        "states": []
    },
    {
        "name": {
            "value": "Gisborne & Hawkes Bay, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9197,
        "urn": "urn:li:region:9197",
        "states": []
    },
    {
        "name": {
            "value": "Nelson, Marlborough & Tasman, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9198,
        "urn": "urn:li:region:9198",
        "states": []
    },
    {
        "name": {
            "value": "Northland, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9199,
        "urn": "urn:li:region:9199",
        "states": []
    },
    {
        "name": {
            "value": "Otago & Southland, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9200,
        "urn": "urn:li:region:9200",
        "states": []
    },
    {
        "name": {
            "value": "Taranaki, Wanganui & Manawatu, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9202,
        "urn": "urn:li:region:9202",
        "states": []
    },
    {
        "name": {
            "value": "Waikato, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9203,
        "urn": "urn:li:region:9203",
        "states": []
    },
    {
        "name": {
            "value": "Wellington & Wairarapa, New Zealand"
        },
        "country": "urn:li:country:nz",
        "id": 9204,
        "urn": "urn:li:region:9204",
        "states": []
    },
    {
        "name": {
            "value": "Changhua County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9205,
        "urn": "urn:li:region:9205",
        "states": []
    },
    {
        "name": {
            "value": "Chiayi County/City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9207,
        "urn": "urn:li:region:9207",
        "states": []
    },
    {
        "name": {
            "value": "Ang Thong, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9208,
        "urn": "urn:li:region:9208",
        "states": [
            "urn:li:state:(urn:li:country:th,15)"
        ]
    },
    {
        "name": {
            "value": "Hsinchu County/City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9209,
        "urn": "urn:li:region:9209",
        "states": []
    },
    {
        "name": {
            "value": "Hualien County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9210,
        "urn": "urn:li:region:9210",
        "states": []
    },
    {
        "name": {
            "value": "Kaohsiung City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9211,
        "urn": "urn:li:region:9211",
        "states": []
    },
    {
        "name": {
            "value": "Keelung City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9213,
        "urn": "urn:li:region:9213",
        "states": []
    },
    {
        "name": {
            "value": "Kinmen County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9214,
        "urn": "urn:li:region:9214",
        "states": []
    },
    {
        "name": {
            "value": "Lienchiang County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9215,
        "urn": "urn:li:region:9215",
        "states": []
    },
    {
        "name": {
            "value": "Miaoli County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9216,
        "urn": "urn:li:region:9216",
        "states": []
    },
    {
        "name": {
            "value": "Nantou County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9218,
        "urn": "urn:li:region:9218",
        "states": []
    },
    {
        "name": {
            "value": "Penghu County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9219,
        "urn": "urn:li:region:9219",
        "states": []
    },
    {
        "name": {
            "value": "Pingtung County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9220,
        "urn": "urn:li:region:9220",
        "states": []
    },
    {
        "name": {
            "value": "Taichung City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9221,
        "urn": "urn:li:region:9221",
        "states": []
    },
    {
        "name": {
            "value": "Tainan City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9223,
        "urn": "urn:li:region:9223",
        "states": []
    },
    {
        "name": {
            "value": "Taipei City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9225,
        "urn": "urn:li:region:9225",
        "states": []
    },
    {
        "name": {
            "value": "New Taipei City, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9226,
        "urn": "urn:li:region:9226",
        "states": []
    },
    {
        "name": {
            "value": "Taitung County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9227,
        "urn": "urn:li:region:9227",
        "states": []
    },
    {
        "name": {
            "value": "Taoyuan County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9228,
        "urn": "urn:li:region:9228",
        "states": []
    },
    {
        "name": {
            "value": "Yilan County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9229,
        "urn": "urn:li:region:9229",
        "states": []
    },
    {
        "name": {
            "value": "Yunlin County, Taiwan"
        },
        "country": "urn:li:country:tw",
        "id": 9230,
        "urn": "urn:li:region:9230",
        "states": []
    },
    {
        "name": {
            "value": "ARMM - Autonomous Region in Muslim Mindanao, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9231,
        "urn": "urn:li:region:9231",
        "states": []
    },
    {
        "name": {
            "value": "CAR - Cordillera Administrative Region, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9232,
        "urn": "urn:li:region:9232",
        "states": []
    },
    {
        "name": {
            "value": "NCR - National Capital Region, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9233,
        "urn": "urn:li:region:9233",
        "states": []
    },
    {
        "name": {
            "value": "Region I - Ilocos, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9234,
        "urn": "urn:li:region:9234",
        "states": []
    },
    {
        "name": {
            "value": "Region II - Cagayan Valley, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9235,
        "urn": "urn:li:region:9235",
        "states": []
    },
    {
        "name": {
            "value": "Region III - Central Luzon, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9236,
        "urn": "urn:li:region:9236",
        "states": []
    },
    {
        "name": {
            "value": "Region IVA - Calabarzon, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9237,
        "urn": "urn:li:region:9237",
        "states": []
    },
    {
        "name": {
            "value": "Region IVB - Mimaropa, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9238,
        "urn": "urn:li:region:9238",
        "states": []
    },
    {
        "name": {
            "value": "Region IX - Zamboanga Peninsula, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9239,
        "urn": "urn:li:region:9239",
        "states": []
    },
    {
        "name": {
            "value": "Region V - Bicol, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9240,
        "urn": "urn:li:region:9240",
        "states": []
    },
    {
        "name": {
            "value": "Region VI - Western Visayas, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9241,
        "urn": "urn:li:region:9241",
        "states": []
    },
    {
        "name": {
            "value": "Region VII - Central Visayas, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9242,
        "urn": "urn:li:region:9242",
        "states": []
    },
    {
        "name": {
            "value": "Region VIII - Eastern Visayas, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9243,
        "urn": "urn:li:region:9243",
        "states": []
    },
    {
        "name": {
            "value": "Region X - Northern Mindanao, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9244,
        "urn": "urn:li:region:9244",
        "states": []
    },
    {
        "name": {
            "value": "Region XI - Davao, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9245,
        "urn": "urn:li:region:9245",
        "states": []
    },
    {
        "name": {
            "value": "Region XII - Soccsksargen, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9246,
        "urn": "urn:li:region:9246",
        "states": []
    },
    {
        "name": {
            "value": "Region XIII - Caraga, Philippines"
        },
        "country": "urn:li:country:ph",
        "id": 9247,
        "urn": "urn:li:region:9247",
        "states": []
    },
    {
        "name": {
            "value": "Bangkok Metropolitan, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9250,
        "urn": "urn:li:region:9250",
        "states": [
            "urn:li:state:(urn:li:country:th,10)",
            "urn:li:state:(urn:li:country:th,73)",
            "urn:li:state:(urn:li:country:th,12)",
            "urn:li:state:(urn:li:country:th,11)",
            "urn:li:state:(urn:li:country:th,74)"
        ]
    },
    {
        "name": {
            "value": "Chai Nat, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9251,
        "urn": "urn:li:region:9251",
        "states": []
    },
    {
        "name": {
            "value": "Kamphaeng Phet, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9252,
        "urn": "urn:li:region:9252",
        "states": [
            "urn:li:state:(urn:li:country:th,62)"
        ]
    },
    {
        "name": {
            "value": "Lopburi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9253,
        "urn": "urn:li:region:9253",
        "states": []
    },
    {
        "name": {
            "value": "Nakhon Nayok, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9254,
        "urn": "urn:li:region:9254",
        "states": [
            "urn:li:state:(urn:li:country:th,26)"
        ]
    },
    {
        "name": {
            "value": "Nakhon Sawan, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9256,
        "urn": "urn:li:region:9256",
        "states": [
            "urn:li:state:(urn:li:country:th,60)",
            "urn:li:state:(urn:li:country:th,30)"
        ]
    },
    {
        "name": {
            "value": "Phetchabun, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9259,
        "urn": "urn:li:region:9259",
        "states": [
            "urn:li:state:(urn:li:country:th,76)"
        ]
    },
    {
        "name": {
            "value": "Phichit, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9260,
        "urn": "urn:li:region:9260",
        "states": [
            "urn:li:state:(urn:li:country:th,66)"
        ]
    },
    {
        "name": {
            "value": "Phitsanulok, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9261,
        "urn": "urn:li:region:9261",
        "states": [
            "urn:li:state:(urn:li:country:th,65)"
        ]
    },
    {
        "name": {
            "value": "Phra Nakhon Si Ayutthaya, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9262,
        "urn": "urn:li:region:9262",
        "states": []
    },
    {
        "name": {
            "value": "Samut Songkhram, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9265,
        "urn": "urn:li:region:9265",
        "states": [
            "urn:li:state:(urn:li:country:th,75)"
        ]
    },
    {
        "name": {
            "value": "Saraburi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9266,
        "urn": "urn:li:region:9266",
        "states": [
            "urn:li:state:(urn:li:country:th,19)"
        ]
    },
    {
        "name": {
            "value": "Sing Buri, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9267,
        "urn": "urn:li:region:9267",
        "states": []
    },
    {
        "name": {
            "value": "Sukhothai, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9268,
        "urn": "urn:li:region:9268",
        "states": [
            "urn:li:state:(urn:li:country:th,64)"
        ]
    },
    {
        "name": {
            "value": "Suphan Buri, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9269,
        "urn": "urn:li:region:9269",
        "states": [
            "urn:li:state:(urn:li:country:th,72)"
        ]
    },
    {
        "name": {
            "value": "Uthai Thani, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9270,
        "urn": "urn:li:region:9270",
        "states": [
            "urn:li:state:(urn:li:country:th,61)"
        ]
    },
    {
        "name": {
            "value": "Chachoengsao, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9271,
        "urn": "urn:li:region:9271",
        "states": [
            "urn:li:state:(urn:li:country:th,24)"
        ]
    },
    {
        "name": {
            "value": "Chanthaburi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9272,
        "urn": "urn:li:region:9272",
        "states": [
            "urn:li:state:(urn:li:country:th,22)"
        ]
    },
    {
        "name": {
            "value": "Chonburi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9273,
        "urn": "urn:li:region:9273",
        "states": []
    },
    {
        "name": {
            "value": "Prachin Buri, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9274,
        "urn": "urn:li:region:9274",
        "states": []
    },
    {
        "name": {
            "value": "Rayong, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9275,
        "urn": "urn:li:region:9275",
        "states": [
            "urn:li:state:(urn:li:country:th,21)"
        ]
    },
    {
        "name": {
            "value": "Sa Kaeo, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9276,
        "urn": "urn:li:region:9276",
        "states": [
            "urn:li:state:(urn:li:country:th,27)"
        ]
    },
    {
        "name": {
            "value": "Trat, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9277,
        "urn": "urn:li:region:9277",
        "states": [
            "urn:li:state:(urn:li:country:th,23)"
        ]
    },
    {
        "name": {
            "value": "Chiang Mai, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9278,
        "urn": "urn:li:region:9278",
        "states": [
            "urn:li:state:(urn:li:country:th,50)"
        ]
    },
    {
        "name": {
            "value": "Chiang Rai, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9279,
        "urn": "urn:li:region:9279",
        "states": [
            "urn:li:state:(urn:li:country:th,57)"
        ]
    },
    {
        "name": {
            "value": "Lampang, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9280,
        "urn": "urn:li:region:9280",
        "states": [
            "urn:li:state:(urn:li:country:th,52)"
        ]
    },
    {
        "name": {
            "value": "Lamphun, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9281,
        "urn": "urn:li:region:9281",
        "states": [
            "urn:li:state:(urn:li:country:th,51)"
        ]
    },
    {
        "name": {
            "value": "Mae Hong Son, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9282,
        "urn": "urn:li:region:9282",
        "states": [
            "urn:li:state:(urn:li:country:th,58)"
        ]
    },
    {
        "name": {
            "value": "Nan, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9283,
        "urn": "urn:li:region:9283",
        "states": [
            "urn:li:state:(urn:li:country:th,55)"
        ]
    },
    {
        "name": {
            "value": "Phayao, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9284,
        "urn": "urn:li:region:9284",
        "states": [
            "urn:li:state:(urn:li:country:th,56)"
        ]
    },
    {
        "name": {
            "value": "Phrae, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9285,
        "urn": "urn:li:region:9285",
        "states": [
            "urn:li:state:(urn:li:country:th,54)"
        ]
    },
    {
        "name": {
            "value": "Uttaradit, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9286,
        "urn": "urn:li:region:9286",
        "states": [
            "urn:li:state:(urn:li:country:th,53)",
            "urn:li:state:(urn:li:country:th,55)"
        ]
    },
    {
        "name": {
            "value": "Amnat Charoen, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9287,
        "urn": "urn:li:region:9287",
        "states": [
            "urn:li:state:(urn:li:country:th,37)"
        ]
    },
    {
        "name": {
            "value": "Bueng Kan, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9288,
        "urn": "urn:li:region:9288",
        "states": [
            "urn:li:state:(urn:li:country:th,43)"
        ]
    },
    {
        "name": {
            "value": "Buri Ram, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9289,
        "urn": "urn:li:region:9289",
        "states": []
    },
    {
        "name": {
            "value": "Chaiyaphum, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9290,
        "urn": "urn:li:region:9290",
        "states": [
            "urn:li:state:(urn:li:country:th,36)"
        ]
    },
    {
        "name": {
            "value": "Kalasin, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9291,
        "urn": "urn:li:region:9291",
        "states": [
            "urn:li:state:(urn:li:country:th,46)"
        ]
    },
    {
        "name": {
            "value": "Khon Kaen, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9292,
        "urn": "urn:li:region:9292",
        "states": [
            "urn:li:state:(urn:li:country:th,40)"
        ]
    },
    {
        "name": {
            "value": "Loei, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9293,
        "urn": "urn:li:region:9293",
        "states": [
            "urn:li:state:(urn:li:country:th,42)",
            "urn:li:state:(urn:li:country:th,41)"
        ]
    },
    {
        "name": {
            "value": "Maha Sarakham, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9294,
        "urn": "urn:li:region:9294",
        "states": [
            "urn:li:state:(urn:li:country:th,44)"
        ]
    },
    {
        "name": {
            "value": "Mukdahan, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9295,
        "urn": "urn:li:region:9295",
        "states": [
            "urn:li:state:(urn:li:country:th,49)"
        ]
    },
    {
        "name": {
            "value": "Nakhon Phanom, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9296,
        "urn": "urn:li:region:9296",
        "states": [
            "urn:li:state:(urn:li:country:th,48)"
        ]
    },
    {
        "name": {
            "value": "Nakhon Ratchasima, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9297,
        "urn": "urn:li:region:9297",
        "states": [
            "urn:li:state:(urn:li:country:th,30)"
        ]
    },
    {
        "name": {
            "value": "Nong Bua Lamphu, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9298,
        "urn": "urn:li:region:9298",
        "states": []
    },
    {
        "name": {
            "value": "Nong Khai, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9299,
        "urn": "urn:li:region:9299",
        "states": [
            "urn:li:state:(urn:li:country:th,43)"
        ]
    },
    {
        "name": {
            "value": "Roi Et, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9300,
        "urn": "urn:li:region:9300",
        "states": [
            "urn:li:state:(urn:li:country:th,45)"
        ]
    },
    {
        "name": {
            "value": "Sakon Nakhon, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9301,
        "urn": "urn:li:region:9301",
        "states": [
            "urn:li:state:(urn:li:country:th,47)"
        ]
    },
    {
        "name": {
            "value": "Sisaket, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9302,
        "urn": "urn:li:region:9302",
        "states": []
    },
    {
        "name": {
            "value": "Surin, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9303,
        "urn": "urn:li:region:9303",
        "states": []
    },
    {
        "name": {
            "value": "Ubon Ratchathani, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9304,
        "urn": "urn:li:region:9304",
        "states": [
            "urn:li:state:(urn:li:country:th,34)"
        ]
    },
    {
        "name": {
            "value": "Udon Thani, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9305,
        "urn": "urn:li:region:9305",
        "states": [
            "urn:li:state:(urn:li:country:th,41)"
        ]
    },
    {
        "name": {
            "value": "Yasothon, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9306,
        "urn": "urn:li:region:9306",
        "states": [
            "urn:li:state:(urn:li:country:th,35)"
        ]
    },
    {
        "name": {
            "value": "Chumphon, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9307,
        "urn": "urn:li:region:9307",
        "states": []
    },
    {
        "name": {
            "value": "Krabi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9308,
        "urn": "urn:li:region:9308",
        "states": [
            "urn:li:state:(urn:li:country:th,81)"
        ]
    },
    {
        "name": {
            "value": "Nakhon Si Thammarat, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9309,
        "urn": "urn:li:region:9309",
        "states": []
    },
    {
        "name": {
            "value": "Narathiwat, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9310,
        "urn": "urn:li:region:9310",
        "states": [
            "urn:li:state:(urn:li:country:th,96)"
        ]
    },
    {
        "name": {
            "value": "Pattani, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9311,
        "urn": "urn:li:region:9311",
        "states": [
            "urn:li:state:(urn:li:country:th,94)"
        ]
    },
    {
        "name": {
            "value": "Phang Nga, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9312,
        "urn": "urn:li:region:9312",
        "states": [
            "urn:li:state:(urn:li:country:th,82)"
        ]
    },
    {
        "name": {
            "value": "Phatthalung, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9313,
        "urn": "urn:li:region:9313",
        "states": []
    },
    {
        "name": {
            "value": "Phuket, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9314,
        "urn": "urn:li:region:9314",
        "states": [
            "urn:li:state:(urn:li:country:th,83)"
        ]
    },
    {
        "name": {
            "value": "Ranong, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9315,
        "urn": "urn:li:region:9315",
        "states": [
            "urn:li:state:(urn:li:country:th,85)"
        ]
    },
    {
        "name": {
            "value": "Satun, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9316,
        "urn": "urn:li:region:9316",
        "states": [
            "urn:li:state:(urn:li:country:th,91)"
        ]
    },
    {
        "name": {
            "value": "Songkhla, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9317,
        "urn": "urn:li:region:9317",
        "states": [
            "urn:li:state:(urn:li:country:th,90)"
        ]
    },
    {
        "name": {
            "value": "Surat Thani, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9318,
        "urn": "urn:li:region:9318",
        "states": [
            "urn:li:state:(urn:li:country:th,84)"
        ]
    },
    {
        "name": {
            "value": "Trang, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9319,
        "urn": "urn:li:region:9319",
        "states": [
            "urn:li:state:(urn:li:country:th,92)"
        ]
    },
    {
        "name": {
            "value": "Yala, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9320,
        "urn": "urn:li:region:9320",
        "states": [
            "urn:li:state:(urn:li:country:th,95)"
        ]
    },
    {
        "name": {
            "value": "Kanchanaburi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9321,
        "urn": "urn:li:region:9321",
        "states": []
    },
    {
        "name": {
            "value": "Phetchaburi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9322,
        "urn": "urn:li:region:9322",
        "states": [
            "urn:li:state:(urn:li:country:th,78)"
        ]
    },
    {
        "name": {
            "value": "Prachuap Khiri Khan, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9323,
        "urn": "urn:li:region:9323",
        "states": []
    },
    {
        "name": {
            "value": "Ratchaburi, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9324,
        "urn": "urn:li:region:9324",
        "states": [
            "urn:li:state:(urn:li:country:th,70)"
        ]
    },
    {
        "name": {
            "value": "Tak, Thailand"
        },
        "country": "urn:li:country:th",
        "id": 9325,
        "urn": "urn:li:region:9325",
        "states": [
            "urn:li:state:(urn:li:country:th,63)"
        ]
    },
    {
        "name": {
            "value": "Northern Region, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 9326,
        "urn": "urn:li:region:9326",
        "states": [
            "urn:li:state:(urn:li:country:dk,1081)",
            "urn:li:state:(urn:li:country:dk,1082)"
        ]
    },
    {
        "name": {
            "value": "Central Region, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 9327,
        "urn": "urn:li:region:9327",
        "states": [
            "urn:li:state:(urn:li:country:dk,1082)"
        ]
    },
    {
        "name": {
            "value": "Southern Region, Funen, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 9328,
        "urn": "urn:li:region:9328",
        "states": [
            "urn:li:state:(urn:li:country:dk,1083)"
        ]
    },
    {
        "name": {
            "value": "Southern Region, South Jutland, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 9329,
        "urn": "urn:li:region:9329",
        "states": [
            "urn:li:state:(urn:li:country:dk,1083)",
            "urn:li:state:(urn:li:country:dk,1082)"
        ]
    },
    {
        "name": {
            "value": "Copenhagen, Capital Region, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 9330,
        "urn": "urn:li:region:9330",
        "states": [
            "urn:li:state:(urn:li:country:dk,1084)",
            "urn:li:state:(urn:li:country:dk,1085)"
        ]
    },
    {
        "name": {
            "value": "Sealand Region, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 9331,
        "urn": "urn:li:region:9331",
        "states": [
            "urn:li:state:(urn:li:country:dk,1085)"
        ]
    },
    {
        "name": {
            "value": "Bornholm, Denmark"
        },
        "country": "urn:li:country:dk",
        "id": 9332,
        "urn": "urn:li:region:9332",
        "states": [
            "urn:li:state:(urn:li:country:dk,1084)"
        ]
    },
    {
        "name": {
            "value": "American Samoa"
        },
        "country": "urn:li:country:as",
        "id": 9334,
        "urn": "urn:li:region:9334",
        "states": [
            "urn:li:state:(urn:li:country:as,60)"
        ]
    },
    {
        "name": {
            "value": "Austria"
        },
        "country": "urn:li:country:at",
        "id": 9335,
        "urn": "urn:li:region:9335",
        "states": [
            "urn:li:state:(urn:li:country:at,03)",
            "urn:li:state:(urn:li:country:at,04)",
            "urn:li:state:(urn:li:country:at,06)",
            "urn:li:state:(urn:li:country:at,07)",
            "urn:li:state:(urn:li:country:at,02)",
            "urn:li:state:(urn:li:country:at,05)",
            "urn:li:state:(urn:li:country:at,01)",
            "urn:li:state:(urn:li:country:at,08)",
            "urn:li:state:(urn:li:country:at,09)"
        ]
    },
    {
        "name": {
            "value": "Bulgaria"
        },
        "country": "urn:li:country:bg",
        "id": 9336,
        "urn": "urn:li:region:9336",
        "states": [
            "urn:li:state:(urn:li:country:bg,KRZ)",
            "urn:li:state:(urn:li:country:bg,BGS)",
            "urn:li:state:(urn:li:country:bg,BLG)",
            "urn:li:state:(urn:li:country:bg,HKV)",
            "urn:li:state:(urn:li:country:bg,SML)",
            "urn:li:state:(urn:li:country:bg,PDV)",
            "urn:li:state:(urn:li:country:bg,DOB)",
            "urn:li:state:(urn:li:country:bg,SFO)",
            "urn:li:state:(urn:li:country:bg,SZR)",
            "urn:li:state:(urn:li:country:bg,TGV)",
            "urn:li:state:(urn:li:country:bg,VTR)",
            "urn:li:state:(urn:li:country:bg,SHU)",
            "urn:li:state:(urn:li:country:bg,VAR)",
            "urn:li:state:(urn:li:country:bg,KNL)",
            "urn:li:state:(urn:li:country:bg,VID)",
            "urn:li:state:(urn:li:country:bg,MON)",
            "urn:li:state:(urn:li:country:bg,LOV)",
            "urn:li:state:(urn:li:country:bg,PVN)",
            "urn:li:state:(urn:li:country:bg,VRC)",
            "urn:li:state:(urn:li:country:bg,PAZ)",
            "urn:li:state:(urn:li:country:bg,SLS)",
            "urn:li:state:(urn:li:country:bg,GAB)",
            "urn:li:state:(urn:li:country:bg,JAM)",
            "urn:li:state:(urn:li:country:bg,RAZ)",
            "urn:li:state:(urn:li:country:bg,RSE)",
            "urn:li:state:(urn:li:country:bg,SOF)"
        ]
    },
    {
        "name": {
            "value": "Algeria"
        },
        "country": "urn:li:country:dz",
        "id": 9337,
        "urn": "urn:li:region:9337",
        "states": [
            "urn:li:state:(urn:li:country:dz,15)",
            "urn:li:state:(urn:li:country:dz,16)",
            "urn:li:state:(urn:li:country:dz,05)",
            "urn:li:state:(urn:li:country:dz,19)",
            "urn:li:state:(urn:li:country:dz,06)",
            "urn:li:state:(urn:li:country:dz,10)",
            "urn:li:state:(urn:li:country:dz,31)",
            "urn:li:state:(urn:li:country:dz,02)",
            "urn:li:state:(urn:li:country:dz,07)",
            "urn:li:state:(urn:li:country:dz,13)",
            "urn:li:state:(urn:li:country:dz,39)",
            "urn:li:state:(urn:li:country:dz,29)",
            "urn:li:state:(urn:li:country:dz,48)",
            "urn:li:state:(urn:li:country:dz,14)",
            "urn:li:state:(urn:li:country:dz,28)",
            "urn:li:state:(urn:li:country:dz,43)",
            "urn:li:state:(urn:li:country:dz,42)",
            "urn:li:state:(urn:li:country:dz,34)",
            "urn:li:state:(urn:li:country:dz,35)",
            "urn:li:state:(urn:li:country:dz,30)",
            "urn:li:state:(urn:li:country:dz,25)",
            "urn:li:state:(urn:li:country:dz,44)",
            "urn:li:state:(urn:li:country:dz,12)",
            "urn:li:state:(urn:li:country:dz,23)",
            "urn:li:state:(urn:li:country:dz,46)",
            "urn:li:state:(urn:li:country:dz,08)",
            "urn:li:state:(urn:li:country:dz,01)",
            "urn:li:state:(urn:li:country:dz,24)",
            "urn:li:state:(urn:li:country:dz,36)",
            "urn:li:state:(urn:li:country:dz,03)",
            "urn:li:state:(urn:li:country:dz,40)",
            "urn:li:state:(urn:li:country:dz,41)",
            "urn:li:state:(urn:li:country:dz,20)",
            "urn:li:state:(urn:li:country:dz,32)",
            "urn:li:state:(urn:li:country:dz,47)",
            "urn:li:state:(urn:li:country:dz,45)",
            "urn:li:state:(urn:li:country:dz,38)",
            "urn:li:state:(urn:li:country:dz,11)",
            "urn:li:state:(urn:li:country:dz,33)"
        ]
    },
    {
        "name": {
            "value": "French Guiana"
        },
        "country": "urn:li:country:gf",
        "id": 9338,
        "urn": "urn:li:region:9338",
        "states": [
            "urn:li:state:(urn:li:country:gf,GF)"
        ]
    },
    {
        "name": {
            "value": "Guadeloupe"
        },
        "country": "urn:li:country:gp",
        "id": 9339,
        "urn": "urn:li:region:9339",
        "states": [
            "urn:li:state:(urn:li:country:gp,GP)"
        ]
    },
    {
        "name": {
            "value": "Guatemala"
        },
        "country": "urn:li:country:gt",
        "id": 9340,
        "urn": "urn:li:region:9340",
        "states": [
            "urn:li:state:(urn:li:country:gt,07)",
            "urn:li:state:(urn:li:country:gt,17)",
            "urn:li:state:(urn:li:country:gt,08)",
            "urn:li:state:(urn:li:country:gt,13)",
            "urn:li:state:(urn:li:country:gt,20)",
            "urn:li:state:(urn:li:country:gt,12)",
            "urn:li:state:(urn:li:country:gt,18)",
            "urn:li:state:(urn:li:country:gt,01)",
            "urn:li:state:(urn:li:country:gt,14)",
            "urn:li:state:(urn:li:country:gt,19)",
            "urn:li:state:(urn:li:country:gt,09)",
            "urn:li:state:(urn:li:country:gt,06)",
            "urn:li:state:(urn:li:country:gt,22)",
            "urn:li:state:(urn:li:country:gt,16)",
            "urn:li:state:(urn:li:country:gt,03)",
            "urn:li:state:(urn:li:country:gt,15)",
            "urn:li:state:(urn:li:country:gt,02)",
            "urn:li:state:(urn:li:country:gt,21)"
        ]
    },
    {
        "name": {
            "value": "Guam"
        },
        "country": "urn:li:country:gu",
        "id": 9341,
        "urn": "urn:li:region:9341",
        "states": [
            "urn:li:state:(urn:li:country:gu,GU)",
            "urn:li:state:(urn:li:country:gu,66)"
        ]
    },
    {
        "name": {
            "value": "Hungary"
        },
        "country": "urn:li:country:hu",
        "id": 9342,
        "urn": "urn:li:region:9342",
        "states": [
            "urn:li:state:(urn:li:country:hu,BZ)",
            "urn:li:state:(urn:li:country:hu,PE)",
            "urn:li:state:(urn:li:country:hu,SZ)",
            "urn:li:state:(urn:li:country:hu,VE)",
            "urn:li:state:(urn:li:country:hu,SO)",
            "urn:li:state:(urn:li:country:hu,GS)",
            "urn:li:state:(urn:li:country:hu,BA)",
            "urn:li:state:(urn:li:country:hu,VA)",
            "urn:li:state:(urn:li:country:hu,ZA)",
            "urn:li:state:(urn:li:country:hu,BU)",
            "urn:li:state:(urn:li:country:hu,FE)",
            "urn:li:state:(urn:li:country:hu,HB)",
            "urn:li:state:(urn:li:country:hu,HE)",
            "urn:li:state:(urn:li:country:hu,NO)",
            "urn:li:state:(urn:li:country:hu,TO)",
            "urn:li:state:(urn:li:country:hu,JN)",
            "urn:li:state:(urn:li:country:hu,CS)",
            "urn:li:state:(urn:li:country:hu,KE)",
            "urn:li:state:(urn:li:country:hu,BE)"
        ]
    },
    {
        "name": {
            "value": "Liechtenstein"
        },
        "country": "urn:li:country:li",
        "id": 9343,
        "urn": "urn:li:region:9343",
        "states": [
            "urn:li:state:(urn:li:country:li,7008)",
            "urn:li:state:(urn:li:country:li,7009)"
        ]
    },
    {
        "name": {
            "value": "Monaco"
        },
        "country": "urn:li:country:mc",
        "id": 9344,
        "urn": "urn:li:region:9344",
        "states": [
            "urn:li:state:(urn:li:country:mc,01)"
        ]
    },
    {
        "name": {
            "value": "Marshall Islands"
        },
        "country": "urn:li:country:mh",
        "id": 9345,
        "urn": "urn:li:region:9345",
        "states": [
            "urn:li:state:(urn:li:country:mh,68)"
        ]
    },
    {
        "name": {
            "value": "Northern Mariana Islands"
        },
        "country": "urn:li:country:mp",
        "id": 9346,
        "urn": "urn:li:region:9346",
        "states": [
            "urn:li:state:(urn:li:country:mp,69)"
        ]
    },
    {
        "name": {
            "value": "Martinique"
        },
        "country": "urn:li:country:mq",
        "id": 9347,
        "urn": "urn:li:region:9347",
        "states": [
            "urn:li:state:(urn:li:country:mq,MQ)"
        ]
    },
    {
        "name": {
            "value": "Saint Pierre and Miquelon"
        },
        "country": "urn:li:country:pm",
        "id": 9348,
        "urn": "urn:li:region:9348",
        "states": [
            "urn:li:state:(urn:li:country:pm,97501)"
        ]
    },
    {
        "name": {
            "value": "Puerto Rico"
        },
        "country": "urn:li:country:pr",
        "id": 9349,
        "urn": "urn:li:region:9349",
        "states": [
            "urn:li:state:(urn:li:country:pr,PR)",
            "urn:li:state:(urn:li:country:pr,72)"
        ]
    },
    {
        "name": {
            "value": "Reunion"
        },
        "country": "urn:li:country:re",
        "id": 9350,
        "urn": "urn:li:region:9350",
        "states": [
            "urn:li:state:(urn:li:country:re,RE)",
            "urn:li:state:(urn:li:country:re,00)"
        ]
    },
    {
        "name": {
            "value": "Svalbard and Jan Mayen"
        },
        "country": "urn:li:country:sj",
        "id": 9351,
        "urn": "urn:li:region:9351",
        "states": [
            "urn:li:state:(urn:li:country:sj,22)",
            "urn:li:state:(urn:li:country:sj,21)"
        ]
    },
    {
        "name": {
            "value": "Slovak Republic"
        },
        "country": "urn:li:country:sk",
        "id": 9352,
        "urn": "urn:li:region:9352",
        "states": [
            "urn:li:state:(urn:li:country:sk,NI)",
            "urn:li:state:(urn:li:country:sk,PV)",
            "urn:li:state:(urn:li:country:sk,BC)",
            "urn:li:state:(urn:li:country:sk,KI)",
            "urn:li:state:(urn:li:country:sk,ZI)",
            "urn:li:state:(urn:li:country:sk,TC)",
            "urn:li:state:(urn:li:country:sk,BL)"
        ]
    },
    {
        "name": {
            "value": "Virgin Islands (U.S.)"
        },
        "country": "urn:li:country:vi",
        "id": 9353,
        "urn": "urn:li:region:9353",
        "states": [
            "urn:li:state:(urn:li:country:vi,78)"
        ]
    },
    {
        "name": {
            "value": "Mayotte"
        },
        "country": "urn:li:country:yt",
        "id": 9354,
        "urn": "urn:li:region:9354",
        "states": [
            "urn:li:state:(urn:li:country:yt,97614)"
        ]
    },
    {
        "name": {
            "value": "Helsinki, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9355,
        "urn": "urn:li:region:9355",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Nummela , Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9356,
        "urn": "urn:li:region:9356",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Karkkila , Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9357,
        "urn": "urn:li:region:9357",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Sipoo, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9358,
        "urn": "urn:li:region:9358",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Kerava, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9359,
        "urn": "urn:li:region:9359",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Tuusula, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9360,
        "urn": "urn:li:region:9360",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Jrvenp, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9361,
        "urn": "urn:li:region:9361",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Hyvink, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9362,
        "urn": "urn:li:region:9362",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Porvoo, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9363,
        "urn": "urn:li:region:9363",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Lohja, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9364,
        "urn": "urn:li:region:9364",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Raseborg, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9365,
        "urn": "urn:li:region:9365",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Riihimki, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9366,
        "urn": "urn:li:region:9366",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Hmeenlinna, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9367,
        "urn": "urn:li:region:9367",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Lahti, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9368,
        "urn": "urn:li:region:9368",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Turku, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9369,
        "urn": "urn:li:region:9369",
        "states": [
            "urn:li:state:(urn:li:country:fi,2)"
        ]
    },
    {
        "name": {
            "value": "Mariehamn, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9370,
        "urn": "urn:li:region:9370",
        "states": [
            "urn:li:state:(urn:li:country:fi,3)"
        ]
    },
    {
        "name": {
            "value": "Pori, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9371,
        "urn": "urn:li:region:9371",
        "states": [
            "urn:li:state:(urn:li:country:fi,2)"
        ]
    },
    {
        "name": {
            "value": "Forssa, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9372,
        "urn": "urn:li:region:9372",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Tampere, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9373,
        "urn": "urn:li:region:9373",
        "states": [
            "urn:li:state:(urn:li:country:fi,4)"
        ]
    },
    {
        "name": {
            "value": "Jyvskyl, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9374,
        "urn": "urn:li:region:9374",
        "states": [
            "urn:li:state:(urn:li:country:fi,4)"
        ]
    },
    {
        "name": {
            "value": "Kouvola, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9375,
        "urn": "urn:li:region:9375",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Kotka, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9376,
        "urn": "urn:li:region:9376",
        "states": [
            "urn:li:state:(urn:li:country:fi,1)"
        ]
    },
    {
        "name": {
            "value": "Mikkeli, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9377,
        "urn": "urn:li:region:9377",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Lappeenranta, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9378,
        "urn": "urn:li:region:9378",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Imatra, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9379,
        "urn": "urn:li:region:9379",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Savonlinna, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9380,
        "urn": "urn:li:region:9380",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Parikkala, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9381,
        "urn": "urn:li:region:9381",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Seinjoki, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9382,
        "urn": "urn:li:region:9382",
        "states": [
            "urn:li:state:(urn:li:country:fi,4)"
        ]
    },
    {
        "name": {
            "value": "Vaasa, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9383,
        "urn": "urn:li:region:9383",
        "states": [
            "urn:li:state:(urn:li:country:fi,4)"
        ]
    },
    {
        "name": {
            "value": "Kokkola, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9384,
        "urn": "urn:li:region:9384",
        "states": [
            "urn:li:state:(urn:li:country:fi,4)"
        ]
    },
    {
        "name": {
            "value": "Kuopio, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9385,
        "urn": "urn:li:region:9385",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Pieksmki, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9386,
        "urn": "urn:li:region:9386",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Pieksamki, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9387,
        "urn": "urn:li:region:9387",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Varkaus, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9388,
        "urn": "urn:li:region:9388",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Joensuu, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9389,
        "urn": "urn:li:region:9389",
        "states": [
            "urn:li:state:(urn:li:country:fi,5)"
        ]
    },
    {
        "name": {
            "value": "Ylivieska, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9390,
        "urn": "urn:li:region:9390",
        "states": [
            "urn:li:state:(urn:li:country:fi,6)"
        ]
    },
    {
        "name": {
            "value": "Kajaani, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9391,
        "urn": "urn:li:region:9391",
        "states": [
            "urn:li:state:(urn:li:country:fi,6)"
        ]
    },
    {
        "name": {
            "value": "Oulu, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9392,
        "urn": "urn:li:region:9392",
        "states": [
            "urn:li:state:(urn:li:country:fi,6)"
        ]
    },
    {
        "name": {
            "value": "Kemi, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9393,
        "urn": "urn:li:region:9393",
        "states": [
            "urn:li:state:(urn:li:country:fi,7)"
        ]
    },
    {
        "name": {
            "value": "Rovaniemi, Finland"
        },
        "country": "urn:li:country:fi",
        "id": 9394,
        "urn": "urn:li:region:9394",
        "states": [
            "urn:li:state:(urn:li:country:fi,7)"
        ]
    },
    {
        "name": {
            "value": "Kiryat Ono, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9400,
        "urn": "urn:li:region:9400",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Tel Aviv, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9401,
        "urn": "urn:li:region:9401",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Ramat HaSharon, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9402,
        "urn": "urn:li:region:9402",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Ramat Gan, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9403,
        "urn": "urn:li:region:9403",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Or Yehuda, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9404,
        "urn": "urn:li:region:9404",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Azor, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9405,
        "urn": "urn:li:region:9405",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Kfar Shmaryahu, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9406,
        "urn": "urn:li:region:9406",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Holon, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9407,
        "urn": "urn:li:region:9407",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Herzliya, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9408,
        "urn": "urn:li:region:9408",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Giv’atayim, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9409,
        "urn": "urn:li:region:9409",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Bnei Brak, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9410,
        "urn": "urn:li:region:9410",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Bat Yam, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9411,
        "urn": "urn:li:region:9411",
        "states": [
            "urn:li:state:(urn:li:country:il,TA)"
        ]
    },
    {
        "name": {
            "value": "Safed, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9412,
        "urn": "urn:li:region:9412",
        "states": [
            "urn:li:state:(urn:li:country:il,Z)"
        ]
    },
    {
        "name": {
            "value": "Nazareth, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9413,
        "urn": "urn:li:region:9413",
        "states": [
            "urn:li:state:(urn:li:country:il,Z)"
        ]
    },
    {
        "name": {
            "value": "Kinneret, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9414,
        "urn": "urn:li:region:9414",
        "states": [
            "urn:li:state:(urn:li:country:il,Z)"
        ]
    },
    {
        "name": {
            "value": "Jezreel Valley, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9415,
        "urn": "urn:li:region:9415",
        "states": [
            "urn:li:state:(urn:li:country:il,Z)"
        ]
    },
    {
        "name": {
            "value": "Golan, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9416,
        "urn": "urn:li:region:9416",
        "states": [
            "urn:li:state:(urn:li:country:il,Z)"
        ]
    },
    {
        "name": {
            "value": "Acre, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9417,
        "urn": "urn:li:region:9417",
        "states": [
            "urn:li:state:(urn:li:country:il,Z)"
        ]
    },
    {
        "name": {
            "value": "Rishon LeZion, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9418,
        "urn": "urn:li:region:9418",
        "states": [
            "urn:li:state:(urn:li:country:il,M)"
        ]
    },
    {
        "name": {
            "value": "Rehovot, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9419,
        "urn": "urn:li:region:9419",
        "states": [
            "urn:li:state:(urn:li:country:il,M)"
        ]
    },
    {
        "name": {
            "value": "Ramla, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9420,
        "urn": "urn:li:region:9420",
        "states": [
            "urn:li:state:(urn:li:country:il,M)"
        ]
    },
    {
        "name": {
            "value": "Petah Tikva, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9421,
        "urn": "urn:li:region:9421",
        "states": [
            "urn:li:state:(urn:li:country:il,M)"
        ]
    },
    {
        "name": {
            "value": "Netanya, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9422,
        "urn": "urn:li:region:9422",
        "states": [
            "urn:li:state:(urn:li:country:il,M)"
        ]
    },
    {
        "name": {
            "value": "Haifa, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9423,
        "urn": "urn:li:region:9423",
        "states": [
            "urn:li:state:(urn:li:country:il,HA)"
        ]
    },
    {
        "name": {
            "value": "Hadera, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9424,
        "urn": "urn:li:region:9424",
        "states": [
            "urn:li:state:(urn:li:country:il,HA)"
        ]
    },
    {
        "name": {
            "value": "Be’er Sheva, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9425,
        "urn": "urn:li:region:9425",
        "states": [
            "urn:li:state:(urn:li:country:il,D)"
        ]
    },
    {
        "name": {
            "value": "Ashkelon, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9426,
        "urn": "urn:li:region:9426",
        "states": [
            "urn:li:state:(urn:li:country:il,D)"
        ]
    },
    {
        "name": {
            "value": "Judea and Samaria, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9427,
        "urn": "urn:li:region:9427",
        "states": [
            "urn:li:state:(urn:li:country:il,JM)"
        ]
    },
    {
        "name": {
            "value": "Jerusalem, Israel"
        },
        "country": "urn:li:country:il",
        "id": 9428,
        "urn": "urn:li:region:9428",
        "states": [
            "urn:li:state:(urn:li:country:il,JS)"
        ]
    },
    {
        "name": {
            "value": "Bend, Oregon"
        },
        "country": "urn:li:country:us",
        "id": 9429,
        "urn": "urn:li:region:9429",
        "states": [
            "urn:li:state:(urn:li:country:us,OR)"
        ]
    },
    {
        "name": {
            "value": "Simpson, KY"
        },
        "country": "urn:li:country:us",
        "id": 9430,
        "urn": "urn:li:region:9430",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Allen, KY"
        },
        "country": "urn:li:country:us",
        "id": 9431,
        "urn": "urn:li:region:9431",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Logan, KY"
        },
        "country": "urn:li:country:us",
        "id": 9432,
        "urn": "urn:li:region:9432",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Todd, KY"
        },
        "country": "urn:li:country:us",
        "id": 9433,
        "urn": "urn:li:region:9433",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Bowling Green, KY"
        },
        "country": "urn:li:country:us",
        "id": 9434,
        "urn": "urn:li:region:9434",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Glasglow, KY"
        },
        "country": "urn:li:country:us",
        "id": 9435,
        "urn": "urn:li:region:9435",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Adair, KY"
        },
        "country": "urn:li:country:us",
        "id": 9436,
        "urn": "urn:li:region:9436",
        "states": [
            "urn:li:state:(urn:li:country:us,KY)"
        ]
    },
    {
        "name": {
            "value": "Corrientes Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9437,
        "urn": "urn:li:region:9437",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-W)"
        ]
    },
    {
        "name": {
            "value": "Santa Fe Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9438,
        "urn": "urn:li:region:9438",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-S)"
        ]
    },
    {
        "name": {
            "value": "Greater Buenos Aires, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9439,
        "urn": "urn:li:region:9439",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-C)"
        ]
    },
    {
        "name": {
            "value": "Córdoba Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9440,
        "urn": "urn:li:region:9440",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-X)"
        ]
    },
    {
        "name": {
            "value": "San Juan Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9441,
        "urn": "urn:li:region:9441",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-J)"
        ]
    },
    {
        "name": {
            "value": "Jujuy Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9442,
        "urn": "urn:li:region:9442",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-Y)"
        ]
    },
    {
        "name": {
            "value": "Mendoza Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9443,
        "urn": "urn:li:region:9443",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-M)"
        ]
    },
    {
        "name": {
            "value": "Salta Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9444,
        "urn": "urn:li:region:9444",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-A)"
        ]
    },
    {
        "name": {
            "value": "Entre Ríos Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9445,
        "urn": "urn:li:region:9445",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-E)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9446,
        "urn": "urn:li:region:9446",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-Z)"
        ]
    },
    {
        "name": {
            "value": "Tucumán Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9447,
        "urn": "urn:li:region:9447",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-T)"
        ]
    },
    {
        "name": {
            "value": "Tierra del Fuego Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9448,
        "urn": "urn:li:region:9448",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-V)"
        ]
    },
    {
        "name": {
            "value": "Chubut Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9449,
        "urn": "urn:li:region:9449",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-U)"
        ]
    },
    {
        "name": {
            "value": "San Luis Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9450,
        "urn": "urn:li:region:9450",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-D)"
        ]
    },
    {
        "name": {
            "value": "Río Negro Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9451,
        "urn": "urn:li:region:9451",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-R)"
        ]
    },
    {
        "name": {
            "value": "Catamarca Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9452,
        "urn": "urn:li:region:9452",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-K)"
        ]
    },
    {
        "name": {
            "value": "Santiago del Estero Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9453,
        "urn": "urn:li:region:9453",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-G)"
        ]
    },
    {
        "name": {
            "value": "La Pampa Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9454,
        "urn": "urn:li:region:9454",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-L)"
        ]
    },
    {
        "name": {
            "value": "La Rioja Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9455,
        "urn": "urn:li:region:9455",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-F)"
        ]
    },
    {
        "name": {
            "value": "Neuquén Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9456,
        "urn": "urn:li:region:9456",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-Q)"
        ]
    },
    {
        "name": {
            "value": "Chaco Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9457,
        "urn": "urn:li:region:9457",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-H)"
        ]
    },
    {
        "name": {
            "value": "Formosa Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9458,
        "urn": "urn:li:region:9458",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-P)"
        ]
    },
    {
        "name": {
            "value": "Misiones Province, Argentina"
        },
        "country": "urn:li:country:ar",
        "id": 9459,
        "urn": "urn:li:region:9459",
        "states": [
            "urn:li:state:(urn:li:country:ar,AR-N)"
        ]
    },
    {
        "name": {
            "value": "Central, Bahrain"
        },
        "country": "urn:li:country:bh",
        "id": 9460,
        "urn": "urn:li:region:9460",
        "states": [
            "urn:li:state:(urn:li:country:bh,BH-01)"
        ]
    },
    {
        "name": {
            "value": "Northern, Bahrain"
        },
        "country": "urn:li:country:bh",
        "id": 9461,
        "urn": "urn:li:region:9461",
        "states": [
            "urn:li:state:(urn:li:country:bh,BH-17)"
        ]
    },
    {
        "name": {
            "value": "Capital, Bahrain"
        },
        "country": "urn:li:country:bh",
        "id": 9462,
        "urn": "urn:li:region:9462",
        "states": [
            "urn:li:state:(urn:li:country:bh,BH-13)"
        ]
    },
    {
        "name": {
            "value": "Southern, Bahrain"
        },
        "country": "urn:li:country:bh",
        "id": 9463,
        "urn": "urn:li:region:9463",
        "states": [
            "urn:li:state:(urn:li:country:bh,BH-14)"
        ]
    },
    {
        "name": {
            "value": "Muharraq, Bahrain"
        },
        "country": "urn:li:country:bh",
        "id": 9464,
        "urn": "urn:li:region:9464",
        "states": [
            "urn:li:state:(urn:li:country:bh,BH-15)"
        ]
    },
    {
        "name": {
            "value": "Llanquihue Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9465,
        "urn": "urn:li:region:9465",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LL)"
        ]
    },
    {
        "name": {
            "value": "Bío Bío Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9466,
        "urn": "urn:li:region:9466",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-BI)"
        ]
    },
    {
        "name": {
            "value": "Melipilla Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9467,
        "urn": "urn:li:region:9467",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-RM)"
        ]
    },
    {
        "name": {
            "value": "Aisén Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9468,
        "urn": "urn:li:region:9468",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AI)"
        ]
    },
    {
        "name": {
            "value": "San Antonio Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9469,
        "urn": "urn:li:region:9469",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Arica Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9470,
        "urn": "urn:li:region:9470",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AP)"
        ]
    },
    {
        "name": {
            "value": "Cordillera Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9471,
        "urn": "urn:li:region:9471",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-RM)"
        ]
    },
    {
        "name": {
            "value": "Cautín Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9472,
        "urn": "urn:li:region:9472",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AR)"
        ]
    },
    {
        "name": {
            "value": "Chacabuco Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9473,
        "urn": "urn:li:region:9473",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-RM)"
        ]
    },
    {
        "name": {
            "value": "Valdivia Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9474,
        "urn": "urn:li:region:9474",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LR)"
        ]
    },
    {
        "name": {
            "value": "Arauco Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9475,
        "urn": "urn:li:region:9475",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-BI)"
        ]
    },
    {
        "name": {
            "value": "Colchagua Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9476,
        "urn": "urn:li:region:9476",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LI)"
        ]
    },
    {
        "name": {
            "value": "Cauquenes Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9477,
        "urn": "urn:li:region:9477",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-ML)"
        ]
    },
    {
        "name": {
            "value": "Ñuble Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9478,
        "urn": "urn:li:region:9478",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-BI)"
        ]
    },
    {
        "name": {
            "value": "El Loa Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9479,
        "urn": "urn:li:region:9479",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AN)"
        ]
    },
    {
        "name": {
            "value": "Huasco Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9480,
        "urn": "urn:li:region:9480",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AT)"
        ]
    },
    {
        "name": {
            "value": "Marga Marga Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9481,
        "urn": "urn:li:region:9481",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Petorca Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9482,
        "urn": "urn:li:region:9482",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Santiago Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9483,
        "urn": "urn:li:region:9483",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-RM)"
        ]
    },
    {
        "name": {
            "value": "General Carrera Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9484,
        "urn": "urn:li:region:9484",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AI)"
        ]
    },
    {
        "name": {
            "value": "Talagante Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9485,
        "urn": "urn:li:region:9485",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-RM)"
        ]
    },
    {
        "name": {
            "value": "Iquique Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9486,
        "urn": "urn:li:region:9486",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-TA)"
        ]
    },
    {
        "name": {
            "value": "Copiapó Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9487,
        "urn": "urn:li:region:9487",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AT)"
        ]
    },
    {
        "name": {
            "value": "Cachapoal Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9488,
        "urn": "urn:li:region:9488",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LI)"
        ]
    },
    {
        "name": {
            "value": "Talca Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9489,
        "urn": "urn:li:region:9489",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-ML)"
        ]
    },
    {
        "name": {
            "value": "Coihaique Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9490,
        "urn": "urn:li:region:9490",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AI)"
        ]
    },
    {
        "name": {
            "value": "Choapa Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9491,
        "urn": "urn:li:region:9491",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-CO)"
        ]
    },
    {
        "name": {
            "value": "San Felipe de Aconcagua Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9492,
        "urn": "urn:li:region:9492",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Los Andes Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9493,
        "urn": "urn:li:region:9493",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Parinacota Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9494,
        "urn": "urn:li:region:9494",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AP)"
        ]
    },
    {
        "name": {
            "value": "Quillota Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9495,
        "urn": "urn:li:region:9495",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Última Esperanza Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9496,
        "urn": "urn:li:region:9496",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-MA)"
        ]
    },
    {
        "name": {
            "value": "Linares Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9497,
        "urn": "urn:li:region:9497",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-ML)"
        ]
    },
    {
        "name": {
            "value": "Chañaral Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9498,
        "urn": "urn:li:region:9498",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AT)"
        ]
    },
    {
        "name": {
            "value": "Tierra del Fuego Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9499,
        "urn": "urn:li:region:9499",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-MA)"
        ]
    },
    {
        "name": {
            "value": "Magallanes Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9500,
        "urn": "urn:li:region:9500",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-MA)"
        ]
    },
    {
        "name": {
            "value": "Tamarugal Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9501,
        "urn": "urn:li:region:9501",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-TA)"
        ]
    },
    {
        "name": {
            "value": "Capitán Prat Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9502,
        "urn": "urn:li:region:9502",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AI)"
        ]
    },
    {
        "name": {
            "value": "Ranco Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9503,
        "urn": "urn:li:region:9503",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LR)"
        ]
    },
    {
        "name": {
            "value": "Isla de Pascua Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9504,
        "urn": "urn:li:region:9504",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Chiloé Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9505,
        "urn": "urn:li:region:9505",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LL)"
        ]
    },
    {
        "name": {
            "value": "Limarí Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9506,
        "urn": "urn:li:region:9506",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-CO)"
        ]
    },
    {
        "name": {
            "value": "Elqui Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9507,
        "urn": "urn:li:region:9507",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-CO)"
        ]
    },
    {
        "name": {
            "value": "Valparaíso Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9508,
        "urn": "urn:li:region:9508",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-VS)"
        ]
    },
    {
        "name": {
            "value": "Malleco Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9509,
        "urn": "urn:li:region:9509",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AR)"
        ]
    },
    {
        "name": {
            "value": "Antártica Chilena Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9510,
        "urn": "urn:li:region:9510",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-MA)"
        ]
    },
    {
        "name": {
            "value": "Palena Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9511,
        "urn": "urn:li:region:9511",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LL)"
        ]
    },
    {
        "name": {
            "value": "Maipo Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9512,
        "urn": "urn:li:region:9512",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-RM)"
        ]
    },
    {
        "name": {
            "value": "Concepción Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9513,
        "urn": "urn:li:region:9513",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-BI)"
        ]
    },
    {
        "name": {
            "value": "Cardenal Caro Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9514,
        "urn": "urn:li:region:9514",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LI)"
        ]
    },
    {
        "name": {
            "value": "Tocopilla Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9515,
        "urn": "urn:li:region:9515",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AN)"
        ]
    },
    {
        "name": {
            "value": "Curicó Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9516,
        "urn": "urn:li:region:9516",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-ML)"
        ]
    },
    {
        "name": {
            "value": "Osorno Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9517,
        "urn": "urn:li:region:9517",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-LL)"
        ]
    },
    {
        "name": {
            "value": "Antofagasta Province, Chile"
        },
        "country": "urn:li:country:cl",
        "id": 9518,
        "urn": "urn:li:region:9518",
        "states": [
            "urn:li:state:(urn:li:country:cl,CL-AN)"
        ]
    },
    {
        "name": {
            "value": "Cauca, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9519,
        "urn": "urn:li:region:9519",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-CAU)"
        ]
    },
    {
        "name": {
            "value": "Huila, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9520,
        "urn": "urn:li:region:9520",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-HUI)"
        ]
    },
    {
        "name": {
            "value": "Archipelago of San Andrés, Providencia and Santa Catalina, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9521,
        "urn": "urn:li:region:9521",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-SAP)"
        ]
    },
    {
        "name": {
            "value": "Cundinamarca, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9522,
        "urn": "urn:li:region:9522",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-CUN)"
        ]
    },
    {
        "name": {
            "value": "Antioquia, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9523,
        "urn": "urn:li:region:9523",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-ANT)"
        ]
    },
    {
        "name": {
            "value": "Cesar, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9524,
        "urn": "urn:li:region:9524",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-CES)"
        ]
    },
    {
        "name": {
            "value": "Amazonas, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9525,
        "urn": "urn:li:region:9525",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-AMA)"
        ]
    },
    {
        "name": {
            "value": "Guaviare, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9526,
        "urn": "urn:li:region:9526",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-GUV)"
        ]
    },
    {
        "name": {
            "value": "Valle del Cauca, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9527,
        "urn": "urn:li:region:9527",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-VAC)"
        ]
    },
    {
        "name": {
            "value": "La Guajira, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9528,
        "urn": "urn:li:region:9528",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-LAG)"
        ]
    },
    {
        "name": {
            "value": "Norte de Santander, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9529,
        "urn": "urn:li:region:9529",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-NSA)"
        ]
    },
    {
        "name": {
            "value": "Meta, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9530,
        "urn": "urn:li:region:9530",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-MET)"
        ]
    },
    {
        "name": {
            "value": "Caldas, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9531,
        "urn": "urn:li:region:9531",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-CAL)"
        ]
    },
    {
        "name": {
            "value": "Chocó, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9532,
        "urn": "urn:li:region:9532",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-CHO)"
        ]
    },
    {
        "name": {
            "value": "Casanare, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9533,
        "urn": "urn:li:region:9533",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-CAS)"
        ]
    },
    {
        "name": {
            "value": "Atlántico, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9534,
        "urn": "urn:li:region:9534",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-ATL)"
        ]
    },
    {
        "name": {
            "value": "Risaralda, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9535,
        "urn": "urn:li:region:9535",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-RIS)"
        ]
    },
    {
        "name": {
            "value": "Sucre, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9536,
        "urn": "urn:li:region:9536",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-SUC)"
        ]
    },
    {
        "name": {
            "value": "Caquetá, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9537,
        "urn": "urn:li:region:9537",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-CAQ)"
        ]
    },
    {
        "name": {
            "value": "Magdalena, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9538,
        "urn": "urn:li:region:9538",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-MAG)"
        ]
    },
    {
        "name": {
            "value": "Vichada, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9539,
        "urn": "urn:li:region:9539",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-VID)"
        ]
    },
    {
        "name": {
            "value": "Arauca, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9540,
        "urn": "urn:li:region:9540",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-ARA)"
        ]
    },
    {
        "name": {
            "value": "Boyacá, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9541,
        "urn": "urn:li:region:9541",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-BOY)"
        ]
    },
    {
        "name": {
            "value": "Santander, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9542,
        "urn": "urn:li:region:9542",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-SAN)"
        ]
    },
    {
        "name": {
            "value": "Córdoba, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9543,
        "urn": "urn:li:region:9543",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-COR)"
        ]
    },
    {
        "name": {
            "value": "Vaupés, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9544,
        "urn": "urn:li:region:9544",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-VAU)"
        ]
    },
    {
        "name": {
            "value": "Guainía, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9545,
        "urn": "urn:li:region:9545",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-GUA)"
        ]
    },
    {
        "name": {
            "value": "Tolima, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9546,
        "urn": "urn:li:region:9546",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-TOL)"
        ]
    },
    {
        "name": {
            "value": "Putumayo, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9547,
        "urn": "urn:li:region:9547",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-PUT)"
        ]
    },
    {
        "name": {
            "value": "Nariño, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9548,
        "urn": "urn:li:region:9548",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-NAR)"
        ]
    },
    {
        "name": {
            "value": "Bogotá D.C., Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9549,
        "urn": "urn:li:region:9549",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-DC)"
        ]
    },
    {
        "name": {
            "value": "Quindío, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9550,
        "urn": "urn:li:region:9550",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-QUI)"
        ]
    },
    {
        "name": {
            "value": "Bolívar, Colombia"
        },
        "country": "urn:li:country:co",
        "id": 9551,
        "urn": "urn:li:region:9551",
        "states": [
            "urn:li:state:(urn:li:country:co,CO-BOL)"
        ]
    },
    {
        "name": {
            "value": "Azarbayejan-e Sharghi Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9552,
        "urn": "urn:li:region:9552",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-01)"
        ]
    },
    {
        "name": {
            "value": "Yazd Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9553,
        "urn": "urn:li:region:9553",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-25)"
        ]
    },
    {
        "name": {
            "value": "Khuzestan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9554,
        "urn": "urn:li:region:9554",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-10)"
        ]
    },
    {
        "name": {
            "value": "Fars Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9555,
        "urn": "urn:li:region:9555",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-14)"
        ]
    },
    {
        "name": {
            "value": "Kohgiluyeh and Boyerahmad Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9556,
        "urn": "urn:li:region:9556",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-18)"
        ]
    },
    {
        "name": {
            "value": "Kermanshah Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9557,
        "urn": "urn:li:region:9557",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-17)"
        ]
    },
    {
        "name": {
            "value": "Hamedan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9558,
        "urn": "urn:li:region:9558",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-24)"
        ]
    },
    {
        "name": {
            "value": "Qom Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9559,
        "urn": "urn:li:region:9559",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-26)"
        ]
    },
    {
        "name": {
            "value": "Bushehr Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9560,
        "urn": "urn:li:region:9560",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-06)"
        ]
    },
    {
        "name": {
            "value": "Mazandaran Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9561,
        "urn": "urn:li:region:9561",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-21)"
        ]
    },
    {
        "name": {
            "value": "Ilam Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9562,
        "urn": "urn:li:region:9562",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-05)"
        ]
    },
    {
        "name": {
            "value": "Markazi Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9563,
        "urn": "urn:li:region:9563",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-22)"
        ]
    },
    {
        "name": {
            "value": "Semnan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9564,
        "urn": "urn:li:region:9564",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-12)"
        ]
    },
    {
        "name": {
            "value": "Zanjan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9565,
        "urn": "urn:li:region:9565",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-11)"
        ]
    },
    {
        "name": {
            "value": "Tehran Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9566,
        "urn": "urn:li:region:9566",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-07)"
        ]
    },
    {
        "name": {
            "value": "Kordestan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9567,
        "urn": "urn:li:region:9567",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-16)"
        ]
    },
    {
        "name": {
            "value": "Azarbayejan-e Gharbi Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9568,
        "urn": "urn:li:region:9568",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-02)"
        ]
    },
    {
        "name": {
            "value": "Gilan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9569,
        "urn": "urn:li:region:9569",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-19)"
        ]
    },
    {
        "name": {
            "value": "Ardebil Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9570,
        "urn": "urn:li:region:9570",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-03)"
        ]
    },
    {
        "name": {
            "value": "Khorasan-e Jonubi Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9571,
        "urn": "urn:li:region:9571",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-29)"
        ]
    },
    {
        "name": {
            "value": "Khorasan-e Razavi Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9572,
        "urn": "urn:li:region:9572",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-30)"
        ]
    },
    {
        "name": {
            "value": "Chaharmahal and Bakhtiyari Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9573,
        "urn": "urn:li:region:9573",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-08)"
        ]
    },
    {
        "name": {
            "value": "Esfahan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9574,
        "urn": "urn:li:region:9574",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-04)"
        ]
    },
    {
        "name": {
            "value": "Hormozgan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9575,
        "urn": "urn:li:region:9575",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-23)"
        ]
    },
    {
        "name": {
            "value": "Khorasan-e Shomali Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9576,
        "urn": "urn:li:region:9576",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-31)"
        ]
    },
    {
        "name": {
            "value": "Qazvin Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9577,
        "urn": "urn:li:region:9577",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-28)"
        ]
    },
    {
        "name": {
            "value": "Golestan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9578,
        "urn": "urn:li:region:9578",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-27)"
        ]
    },
    {
        "name": {
            "value": "Sistan and Baluchestan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9579,
        "urn": "urn:li:region:9579",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-13)"
        ]
    },
    {
        "name": {
            "value": "Kerman Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9580,
        "urn": "urn:li:region:9580",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-15)"
        ]
    },
    {
        "name": {
            "value": "Alborz Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9581,
        "urn": "urn:li:region:9581",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-32)"
        ]
    },
    {
        "name": {
            "value": "Lorestan Province, Iran"
        },
        "country": "urn:li:country:ir",
        "id": 9582,
        "urn": "urn:li:region:9582",
        "states": [
            "urn:li:state:(urn:li:country:ir,IR-20)"
        ]
    },
    {
        "name": {
            "value": "Nador Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9583,
        "urn": "urn:li:region:9583",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-04)"
        ]
    },
    {
        "name": {
            "value": "Azilal Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9584,
        "urn": "urn:li:region:9584",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-12)"
        ]
    },
    {
        "name": {
            "value": "Al Haouz Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9585,
        "urn": "urn:li:region:9585",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-11)"
        ]
    },
    {
        "name": {
            "value": "El Kelaâ des Sraghna Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9586,
        "urn": "urn:li:region:9586",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-11)"
        ]
    },
    {
        "name": {
            "value": "Safi Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9587,
        "urn": "urn:li:region:9587",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-10)"
        ]
    },
    {
        "name": {
            "value": "Boujdour Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9588,
        "urn": "urn:li:region:9588",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-15)"
        ]
    },
    {
        "name": {
            "value": "M’diq-Fnideq Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9589,
        "urn": "urn:li:region:9589",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-01)"
        ]
    },
    {
        "name": {
            "value": "Khénifra Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9590,
        "urn": "urn:li:region:9590",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-06)"
        ]
    },
    {
        "name": {
            "value": "Al Hoceima Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9591,
        "urn": "urn:li:region:9591",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-03)"
        ]
    },
    {
        "name": {
            "value": "Béni Mellal Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9592,
        "urn": "urn:li:region:9592",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-12)"
        ]
    },
    {
        "name": {
            "value": "Tétouan Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9593,
        "urn": "urn:li:region:9593",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-01)"
        ]
    },
    {
        "name": {
            "value": "Taourirt Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9594,
        "urn": "urn:li:region:9594",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-04)"
        ]
    },
    {
        "name": {
            "value": "Khémisset Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9595,
        "urn": "urn:li:region:9595",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-07)"
        ]
    },
    {
        "name": {
            "value": "Fahs Anjra Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9596,
        "urn": "urn:li:region:9596",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-01)"
        ]
    },
    {
        "name": {
            "value": "Larache Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9597,
        "urn": "urn:li:region:9597",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-01)"
        ]
    },
    {
        "name": {
            "value": "El Jadida Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9598,
        "urn": "urn:li:region:9598",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-10)"
        ]
    },
    {
        "name": {
            "value": "Youssoufia Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9599,
        "urn": "urn:li:region:9599",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-10)"
        ]
    },
    {
        "name": {
            "value": "Fquih Ben Salah Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9600,
        "urn": "urn:li:region:9600",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-12)"
        ]
    },
    {
        "name": {
            "value": "Driouch Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9601,
        "urn": "urn:li:region:9601",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-04)"
        ]
    },
    {
        "name": {
            "value": "Kénitra Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9602,
        "urn": "urn:li:region:9602",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-02)"
        ]
    },
    {
        "name": {
            "value": "Berrechid Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9603,
        "urn": "urn:li:region:9603",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-09)"
        ]
    },
    {
        "name": {
            "value": "Mohammédia Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9604,
        "urn": "urn:li:region:9604",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-08)"
        ]
    },
    {
        "name": {
            "value": "Rehamna Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9605,
        "urn": "urn:li:region:9605",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-11)"
        ]
    },
    {
        "name": {
            "value": "Assa-Zag Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9606,
        "urn": "urn:li:region:9606",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-14)"
        ]
    },
    {
        "name": {
            "value": "Taounate Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9607,
        "urn": "urn:li:region:9607",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-03)"
        ]
    },
    {
        "name": {
            "value": "Guelmim Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9608,
        "urn": "urn:li:region:9608",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-14)"
        ]
    },
    {
        "name": {
            "value": "Sefrou Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9609,
        "urn": "urn:li:region:9609",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-05)"
        ]
    },
    {
        "name": {
            "value": "Meknès Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9610,
        "urn": "urn:li:region:9610",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-06)"
        ]
    },
    {
        "name": {
            "value": "Sidi Ifni Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9611,
        "urn": "urn:li:region:9611",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Berkane Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9612,
        "urn": "urn:li:region:9612",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-04)"
        ]
    },
    {
        "name": {
            "value": "Chefchaouen Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9613,
        "urn": "urn:li:region:9613",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-01)"
        ]
    },
    {
        "name": {
            "value": "Jerada Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9614,
        "urn": "urn:li:region:9614",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-04)"
        ]
    },
    {
        "name": {
            "value": "Tan-Tan Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9615,
        "urn": "urn:li:region:9615",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-14)"
        ]
    },
    {
        "name": {
            "value": "Figuig Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9616,
        "urn": "urn:li:region:9616",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-04)"
        ]
    },
    {
        "name": {
            "value": "Salé Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9617,
        "urn": "urn:li:region:9617",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-07)"
        ]
    },
    {
        "name": {
            "value": "Moulay Yacoub Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9618,
        "urn": "urn:li:region:9618",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-05)"
        ]
    },
    {
        "name": {
            "value": "Casablanca Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9619,
        "urn": "urn:li:region:9619",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-08)"
        ]
    },
    {
        "name": {
            "value": "Sidi Bennour Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9620,
        "urn": "urn:li:region:9620",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-10)"
        ]
    },
    {
        "name": {
            "value": "Ouarzazate Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9621,
        "urn": "urn:li:region:9621",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Nouaceur Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9622,
        "urn": "urn:li:region:9622",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-08)"
        ]
    },
    {
        "name": {
            "value": "Chtouka-Aït Baha Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9623,
        "urn": "urn:li:region:9623",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Ifrane Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9624,
        "urn": "urn:li:region:9624",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-06)"
        ]
    },
    {
        "name": {
            "value": "Sidi Kacem Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9625,
        "urn": "urn:li:region:9625",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-02)"
        ]
    },
    {
        "name": {
            "value": "Ben Slimane Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9626,
        "urn": "urn:li:region:9626",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-09)"
        ]
    },
    {
        "name": {
            "value": "Errachidia Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9627,
        "urn": "urn:li:region:9627",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-06)"
        ]
    },
    {
        "name": {
            "value": "Sidi Slimane Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9628,
        "urn": "urn:li:region:9628",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-02)"
        ]
    },
    {
        "name": {
            "value": "Midelt Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9629,
        "urn": "urn:li:region:9629",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-06)"
        ]
    },
    {
        "name": {
            "value": "Tarfaya Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9630,
        "urn": "urn:li:region:9630",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-15)"
        ]
    },
    {
        "name": {
            "value": "Ouezzane Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9631,
        "urn": "urn:li:region:9631",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-01)"
        ]
    },
    {
        "name": {
            "value": "Tanger-Asilah Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9632,
        "urn": "urn:li:region:9632",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-01)"
        ]
    },
    {
        "name": {
            "value": "Boulemane Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9633,
        "urn": "urn:li:region:9633",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-05)"
        ]
    },
    {
        "name": {
            "value": "Aousserd Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9634,
        "urn": "urn:li:region:9634",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-16)"
        ]
    },
    {
        "name": {
            "value": "Fès Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9635,
        "urn": "urn:li:region:9635",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-05)"
        ]
    },
    {
        "name": {
            "value": "Taroudant Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9636,
        "urn": "urn:li:region:9636",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Médiouna Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9637,
        "urn": "urn:li:region:9637",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-08)"
        ]
    },
    {
        "name": {
            "value": "Es-Semara Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9638,
        "urn": "urn:li:region:9638",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-14)"
        ]
    },
    {
        "name": {
            "value": "Marrakech Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9639,
        "urn": "urn:li:region:9639",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-11)"
        ]
    },
    {
        "name": {
            "value": "Inezgane-Aït Melloul Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9640,
        "urn": "urn:li:region:9640",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Oujda-Angad Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9641,
        "urn": "urn:li:region:9641",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-04)"
        ]
    },
    {
        "name": {
            "value": "Tiznit Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9642,
        "urn": "urn:li:region:9642",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Chichaoua Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9643,
        "urn": "urn:li:region:9643",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-11)"
        ]
    },
    {
        "name": {
            "value": "Zagora Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9644,
        "urn": "urn:li:region:9644",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Tata Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9645,
        "urn": "urn:li:region:9645",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-14)"
        ]
    },
    {
        "name": {
            "value": "Rabat Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9646,
        "urn": "urn:li:region:9646",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-07)"
        ]
    },
    {
        "name": {
            "value": "Laâyoune Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9647,
        "urn": "urn:li:region:9647",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-15)"
        ]
    },
    {
        "name": {
            "value": "Skhirat-Témara Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9648,
        "urn": "urn:li:region:9648",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-07)"
        ]
    },
    {
        "name": {
            "value": "Tinghir Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9649,
        "urn": "urn:li:region:9649",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "El Hajeb Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9650,
        "urn": "urn:li:region:9650",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-06)"
        ]
    },
    {
        "name": {
            "value": "Taza Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9651,
        "urn": "urn:li:region:9651",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-03)"
        ]
    },
    {
        "name": {
            "value": "Khouribga Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9652,
        "urn": "urn:li:region:9652",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-09)"
        ]
    },
    {
        "name": {
            "value": "Essaouira Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9653,
        "urn": "urn:li:region:9653",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-11)"
        ]
    },
    {
        "name": {
            "value": "Guercif Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9654,
        "urn": "urn:li:region:9654",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-03)"
        ]
    },
    {
        "name": {
            "value": "Settat Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9655,
        "urn": "urn:li:region:9655",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-09)"
        ]
    },
    {
        "name": {
            "value": "Oued Ed-Dahab-Lagouira Province, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9656,
        "urn": "urn:li:region:9656",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-16)"
        ]
    },
    {
        "name": {
            "value": "Agadir Ida-Outanane Prefecture, Morocco"
        },
        "country": "urn:li:country:ma",
        "id": 9657,
        "urn": "urn:li:region:9657",
        "states": [
            "urn:li:state:(urn:li:country:ma,MA-13)"
        ]
    },
    {
        "name": {
            "value": "Víctor Fajardo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9658,
        "urn": "urn:li:region:9658",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Lucanas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9659,
        "urn": "urn:li:region:9659",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Caylloma Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9660,
        "urn": "urn:li:region:9660",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "Huancabamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9661,
        "urn": "urn:li:region:9661",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Cañete Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9662,
        "urn": "urn:li:region:9662",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Huamalíes Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9663,
        "urn": "urn:li:region:9663",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Puerto Inca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9664,
        "urn": "urn:li:region:9664",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Barranca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9665,
        "urn": "urn:li:region:9665",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Leoncio Prado Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9666,
        "urn": "urn:li:region:9666",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Ferreñafe Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9667,
        "urn": "urn:li:region:9667",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAM)"
        ]
    },
    {
        "name": {
            "value": "Asunción Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9668,
        "urn": "urn:li:region:9668",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Rodríguez de Mendoza Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9669,
        "urn": "urn:li:region:9669",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AMA)"
        ]
    },
    {
        "name": {
            "value": "Huarmey Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9670,
        "urn": "urn:li:region:9670",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Mariscal Nieto Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9671,
        "urn": "urn:li:region:9671",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-MOQ)"
        ]
    },
    {
        "name": {
            "value": "Utcubamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9672,
        "urn": "urn:li:region:9672",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AMA)"
        ]
    },
    {
        "name": {
            "value": "Antabamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9673,
        "urn": "urn:li:region:9673",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-APU)"
        ]
    },
    {
        "name": {
            "value": "Huanta Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9674,
        "urn": "urn:li:region:9674",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Acomayo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9675,
        "urn": "urn:li:region:9675",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "San Román Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9676,
        "urn": "urn:li:region:9676",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Marañón Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9677,
        "urn": "urn:li:region:9677",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Palpa Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9678,
        "urn": "urn:li:region:9678",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ICA)"
        ]
    },
    {
        "name": {
            "value": "Yauli Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9679,
        "urn": "urn:li:region:9679",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Rioja Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9680,
        "urn": "urn:li:region:9680",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Loreto Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9681,
        "urn": "urn:li:region:9681",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LOR)"
        ]
    },
    {
        "name": {
            "value": "Parinacochas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9682,
        "urn": "urn:li:region:9682",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Moho Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9683,
        "urn": "urn:li:region:9683",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Churcampa Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9684,
        "urn": "urn:li:region:9684",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUV)"
        ]
    },
    {
        "name": {
            "value": "Ambo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9685,
        "urn": "urn:li:region:9685",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Pomabamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9686,
        "urn": "urn:li:region:9686",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Grau Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9687,
        "urn": "urn:li:region:9687",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-APU)"
        ]
    },
    {
        "name": {
            "value": "Yauyos Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9688,
        "urn": "urn:li:region:9688",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Yunguyo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9689,
        "urn": "urn:li:region:9689",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Huancavelica Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9690,
        "urn": "urn:li:region:9690",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUV)"
        ]
    },
    {
        "name": {
            "value": "Tacna Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9691,
        "urn": "urn:li:region:9691",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-TAC)"
        ]
    },
    {
        "name": {
            "value": "Pallasca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9692,
        "urn": "urn:li:region:9692",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Cañas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9693,
        "urn": "urn:li:region:9693",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Pisco Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9694,
        "urn": "urn:li:region:9694",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ICA)"
        ]
    },
    {
        "name": {
            "value": "Gran Chimú Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9695,
        "urn": "urn:li:region:9695",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Chachapoyas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9696,
        "urn": "urn:li:region:9696",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AMA)"
        ]
    },
    {
        "name": {
            "value": "Huaura Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9697,
        "urn": "urn:li:region:9697",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Espinar Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9698,
        "urn": "urn:li:region:9698",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Concepción Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9699,
        "urn": "urn:li:region:9699",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "San Martín Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9700,
        "urn": "urn:li:region:9700",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Cusco Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9701,
        "urn": "urn:li:region:9701",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Celendín Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9702,
        "urn": "urn:li:region:9702",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Paita Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9703,
        "urn": "urn:li:region:9703",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Quispicanchi Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9704,
        "urn": "urn:li:region:9704",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Sullana Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9705,
        "urn": "urn:li:region:9705",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Acobamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9706,
        "urn": "urn:li:region:9706",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUV)"
        ]
    },
    {
        "name": {
            "value": "Tahuamanú Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9707,
        "urn": "urn:li:region:9707",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-MDD)"
        ]
    },
    {
        "name": {
            "value": "Sánchez Carrión Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9708,
        "urn": "urn:li:region:9708",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "San Miguel Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9709,
        "urn": "urn:li:region:9709",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Ilo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9710,
        "urn": "urn:li:region:9710",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-MOQ)"
        ]
    },
    {
        "name": {
            "value": "Cajamarca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9711,
        "urn": "urn:li:region:9711",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Chanchamayo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9712,
        "urn": "urn:li:region:9712",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Pachitea Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9713,
        "urn": "urn:li:region:9713",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Purús Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9714,
        "urn": "urn:li:region:9714",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-UCA)"
        ]
    },
    {
        "name": {
            "value": "Huari Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9715,
        "urn": "urn:li:region:9715",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Contumazá Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9716,
        "urn": "urn:li:region:9716",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Tambopata Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9717,
        "urn": "urn:li:region:9717",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-MDD)"
        ]
    },
    {
        "name": {
            "value": "Casma Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9718,
        "urn": "urn:li:region:9718",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Angaraes Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9719,
        "urn": "urn:li:region:9719",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUV)"
        ]
    },
    {
        "name": {
            "value": "San Ignacio Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9720,
        "urn": "urn:li:region:9720",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Páucar del Sara Sara Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9721,
        "urn": "urn:li:region:9721",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "San Antonio de Putina Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9722,
        "urn": "urn:li:region:9722",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Yarowilca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9723,
        "urn": "urn:li:region:9723",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Sihuas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9724,
        "urn": "urn:li:region:9724",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Azángaro Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9725,
        "urn": "urn:li:region:9725",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Huancayo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9726,
        "urn": "urn:li:region:9726",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Paucartambo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9727,
        "urn": "urn:li:region:9727",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Anta Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9728,
        "urn": "urn:li:region:9728",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Jaén Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9729,
        "urn": "urn:li:region:9729",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Bolívar Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9730,
        "urn": "urn:li:region:9730",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Otuzco Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9731,
        "urn": "urn:li:region:9731",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Huaral Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9732,
        "urn": "urn:li:region:9732",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Tayacaja Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9733,
        "urn": "urn:li:region:9733",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUV)"
        ]
    },
    {
        "name": {
            "value": "Caravelí Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9734,
        "urn": "urn:li:region:9734",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "Huaylas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9735,
        "urn": "urn:li:region:9735",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "San Pablo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9736,
        "urn": "urn:li:region:9736",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Candarave Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9737,
        "urn": "urn:li:region:9737",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-TAC)"
        ]
    },
    {
        "name": {
            "value": "Chumbivilcas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9738,
        "urn": "urn:li:region:9738",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Lambayeque Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9739,
        "urn": "urn:li:region:9739",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAM)"
        ]
    },
    {
        "name": {
            "value": "Hualgayoc Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9740,
        "urn": "urn:li:region:9740",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Carlos Fermín Fitzcarrald Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9741,
        "urn": "urn:li:region:9741",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "San Marcos Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9742,
        "urn": "urn:li:region:9742",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Puno Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9743,
        "urn": "urn:li:region:9743",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Virú Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9744,
        "urn": "urn:li:region:9744",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Huánuco Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9745,
        "urn": "urn:li:region:9745",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Huanca Sancos Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9746,
        "urn": "urn:li:region:9746",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Oyón Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9747,
        "urn": "urn:li:region:9747",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Abancay Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9748,
        "urn": "urn:li:region:9748",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-APU)"
        ]
    },
    {
        "name": {
            "value": "Tarma Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9749,
        "urn": "urn:li:region:9749",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Arequipa Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9750,
        "urn": "urn:li:region:9750",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "Cajatambo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9751,
        "urn": "urn:li:region:9751",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "El Collao Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9752,
        "urn": "urn:li:region:9752",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Calca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9753,
        "urn": "urn:li:region:9753",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Maynas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9754,
        "urn": "urn:li:region:9754",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LOR)"
        ]
    },
    {
        "name": {
            "value": "Carhuaz Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9755,
        "urn": "urn:li:region:9755",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Julcán Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9756,
        "urn": "urn:li:region:9756",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Zarumilla Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9757,
        "urn": "urn:li:region:9757",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-TUM)"
        ]
    },
    {
        "name": {
            "value": "Talara Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9758,
        "urn": "urn:li:region:9758",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Urubamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9759,
        "urn": "urn:li:region:9759",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "El Dorado Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9760,
        "urn": "urn:li:region:9760",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Huarochirí Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9761,
        "urn": "urn:li:region:9761",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Huallaga Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9762,
        "urn": "urn:li:region:9762",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Camaná Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9763,
        "urn": "urn:li:region:9763",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "Carabaya Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9764,
        "urn": "urn:li:region:9764",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "General Sánchez Cerro Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9765,
        "urn": "urn:li:region:9765",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-MOQ)"
        ]
    },
    {
        "name": {
            "value": "Pataz Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9766,
        "urn": "urn:li:region:9766",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Satipo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9767,
        "urn": "urn:li:region:9767",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Corongo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9768,
        "urn": "urn:li:region:9768",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Oxapampa Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9769,
        "urn": "urn:li:region:9769",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PAS)"
        ]
    },
    {
        "name": {
            "value": "Castilla Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9770,
        "urn": "urn:li:region:9770",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "Santa Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9771,
        "urn": "urn:li:region:9771",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Paruro Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9772,
        "urn": "urn:li:region:9772",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Mariscal Ramón Castilla Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9773,
        "urn": "urn:li:region:9773",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LOR)"
        ]
    },
    {
        "name": {
            "value": "Recuay Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9774,
        "urn": "urn:li:region:9774",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Chiclayo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9775,
        "urn": "urn:li:region:9775",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAM)"
        ]
    },
    {
        "name": {
            "value": "Huancané Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9776,
        "urn": "urn:li:region:9776",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Chota Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9777,
        "urn": "urn:li:region:9777",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Bagua Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9778,
        "urn": "urn:li:region:9778",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AMA)"
        ]
    },
    {
        "name": {
            "value": "Huamanga Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9779,
        "urn": "urn:li:region:9779",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Chincheros Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9780,
        "urn": "urn:li:region:9780",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-APU)"
        ]
    },
    {
        "name": {
            "value": "Lauricocha Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9781,
        "urn": "urn:li:region:9781",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Chincha Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9782,
        "urn": "urn:li:region:9782",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ICA)"
        ]
    },
    {
        "name": {
            "value": "Piura Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9783,
        "urn": "urn:li:region:9783",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Santa Cruz Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9784,
        "urn": "urn:li:region:9784",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Callao Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9785,
        "urn": "urn:li:region:9785",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAL)"
        ]
    },
    {
        "name": {
            "value": "Dos de Mayo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9786,
        "urn": "urn:li:region:9786",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Tocache Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9787,
        "urn": "urn:li:region:9787",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Cotabambas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9788,
        "urn": "urn:li:region:9788",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-APU)"
        ]
    },
    {
        "name": {
            "value": "Requena Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9789,
        "urn": "urn:li:region:9789",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LOR)"
        ]
    },
    {
        "name": {
            "value": "Islay Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9790,
        "urn": "urn:li:region:9790",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "La Mar Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9791,
        "urn": "urn:li:region:9791",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Ica Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9792,
        "urn": "urn:li:region:9792",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ICA)"
        ]
    },
    {
        "name": {
            "value": "Ayabaca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9793,
        "urn": "urn:li:region:9793",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Chepén Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9794,
        "urn": "urn:li:region:9794",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Sandia Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9795,
        "urn": "urn:li:region:9795",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Bolognesi Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9796,
        "urn": "urn:li:region:9796",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Canchis Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9797,
        "urn": "urn:li:region:9797",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Condorcanqui Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9798,
        "urn": "urn:li:region:9798",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AMA)"
        ]
    },
    {
        "name": {
            "value": "Aymaraes Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9799,
        "urn": "urn:li:region:9799",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-APU)"
        ]
    },
    {
        "name": {
            "value": "Bellavista Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9800,
        "urn": "urn:li:region:9800",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Chucuíto Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9801,
        "urn": "urn:li:region:9801",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "La Unión Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9802,
        "urn": "urn:li:region:9802",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "Pacasmayo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9803,
        "urn": "urn:li:region:9803",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Antonio Raymondi Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9804,
        "urn": "urn:li:region:9804",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Trujillo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9805,
        "urn": "urn:li:region:9805",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Lampa Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9806,
        "urn": "urn:li:region:9806",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Huacaybamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9807,
        "urn": "urn:li:region:9807",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUC)"
        ]
    },
    {
        "name": {
            "value": "Datem del Marañón Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9808,
        "urn": "urn:li:region:9808",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LOR)"
        ]
    },
    {
        "name": {
            "value": "Aija Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9809,
        "urn": "urn:li:region:9809",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Tumbes Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9810,
        "urn": "urn:li:region:9810",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-TUM)"
        ]
    },
    {
        "name": {
            "value": "Cajabamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9811,
        "urn": "urn:li:region:9811",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Santiago de Chuco Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9812,
        "urn": "urn:li:region:9812",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Ocros Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9813,
        "urn": "urn:li:region:9813",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Tarata Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9814,
        "urn": "urn:li:region:9814",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-TAC)"
        ]
    },
    {
        "name": {
            "value": "Lamas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9815,
        "urn": "urn:li:region:9815",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Junín Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9816,
        "urn": "urn:li:region:9816",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Huaraz Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9817,
        "urn": "urn:li:region:9817",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Coronel Portillo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9818,
        "urn": "urn:li:region:9818",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-UCA)"
        ]
    },
    {
        "name": {
            "value": "Andahuaylas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9819,
        "urn": "urn:li:region:9819",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-APU)"
        ]
    },
    {
        "name": {
            "value": "La Convención Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9820,
        "urn": "urn:li:region:9820",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CUS)"
        ]
    },
    {
        "name": {
            "value": "Bongará Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9821,
        "urn": "urn:li:region:9821",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AMA)"
        ]
    },
    {
        "name": {
            "value": "Nazca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9822,
        "urn": "urn:li:region:9822",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ICA)"
        ]
    },
    {
        "name": {
            "value": "Canta Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9823,
        "urn": "urn:li:region:9823",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Sechura Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9824,
        "urn": "urn:li:region:9824",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Cangallo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9825,
        "urn": "urn:li:region:9825",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Contralmirante Villar Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9826,
        "urn": "urn:li:region:9826",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-TUM)"
        ]
    },
    {
        "name": {
            "value": "Yungay Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9827,
        "urn": "urn:li:region:9827",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Morropón Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9828,
        "urn": "urn:li:region:9828",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PIU)"
        ]
    },
    {
        "name": {
            "value": "Huaytará Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9829,
        "urn": "urn:li:region:9829",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUV)"
        ]
    },
    {
        "name": {
            "value": "Moyobamba Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9830,
        "urn": "urn:li:region:9830",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Mariscal Luzuriaga Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9831,
        "urn": "urn:li:region:9831",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ANC)"
        ]
    },
    {
        "name": {
            "value": "Padre Abad Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9832,
        "urn": "urn:li:region:9832",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-UCA)"
        ]
    },
    {
        "name": {
            "value": "Cutervo Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9833,
        "urn": "urn:li:region:9833",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-CAJ)"
        ]
    },
    {
        "name": {
            "value": "Vilcas Huamán Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9834,
        "urn": "urn:li:region:9834",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Condesuyos Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9835,
        "urn": "urn:li:region:9835",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-ARE)"
        ]
    },
    {
        "name": {
            "value": "Mariscal Cáceres Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9836,
        "urn": "urn:li:region:9836",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Manu Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9837,
        "urn": "urn:li:region:9837",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-MDD)"
        ]
    },
    {
        "name": {
            "value": "Castrovirreyna Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9838,
        "urn": "urn:li:region:9838",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-HUV)"
        ]
    },
    {
        "name": {
            "value": "Chupaca Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9839,
        "urn": "urn:li:region:9839",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Alto Amazonas Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9840,
        "urn": "urn:li:region:9840",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LOR)"
        ]
    },
    {
        "name": {
            "value": "Lima Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9841,
        "urn": "urn:li:region:9841",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LIM)"
        ]
    },
    {
        "name": {
            "value": "Pasco Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9842,
        "urn": "urn:li:region:9842",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PAS)"
        ]
    },
    {
        "name": {
            "value": "Jauja Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9843,
        "urn": "urn:li:region:9843",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-JUN)"
        ]
    },
    {
        "name": {
            "value": "Ascope Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9844,
        "urn": "urn:li:region:9844",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LAL)"
        ]
    },
    {
        "name": {
            "value": "Atalaya Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9845,
        "urn": "urn:li:region:9845",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-UCA)"
        ]
    },
    {
        "name": {
            "value": "Luya Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9846,
        "urn": "urn:li:region:9846",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AMA)"
        ]
    },
    {
        "name": {
            "value": "Picota Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9847,
        "urn": "urn:li:region:9847",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-SAM)"
        ]
    },
    {
        "name": {
            "value": "Ucayali Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9848,
        "urn": "urn:li:region:9848",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-LOR)"
        ]
    },
    {
        "name": {
            "value": "Jorge Basadre Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9849,
        "urn": "urn:li:region:9849",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-TAC)"
        ]
    },
    {
        "name": {
            "value": "Sucre Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9850,
        "urn": "urn:li:region:9850",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-AYA)"
        ]
    },
    {
        "name": {
            "value": "Daniel Alcides Carrión Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9851,
        "urn": "urn:li:region:9851",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PAS)"
        ]
    },
    {
        "name": {
            "value": "Melgar Province, Peru"
        },
        "country": "urn:li:country:pe",
        "id": 9852,
        "urn": "urn:li:region:9852",
        "states": [
            "urn:li:state:(urn:li:country:pe,PE-PUN)"
        ]
    },
    {
        "name": {
            "value": "Ariana Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9853,
        "urn": "urn:li:region:9853",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-12)"
        ]
    },
    {
        "name": {
            "value": "Nabeul Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9854,
        "urn": "urn:li:region:9854",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-21)"
        ]
    },
    {
        "name": {
            "value": "Tozeur Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9855,
        "urn": "urn:li:region:9855",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-72)"
        ]
    },
    {
        "name": {
            "value": "Gafsa Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9856,
        "urn": "urn:li:region:9856",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-71)"
        ]
    },
    {
        "name": {
            "value": "Mannouba Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9857,
        "urn": "urn:li:region:9857",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-14)"
        ]
    },
    {
        "name": {
            "value": "Kasserine Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9858,
        "urn": "urn:li:region:9858",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-42)"
        ]
    },
    {
        "name": {
            "value": "Le Kef Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9859,
        "urn": "urn:li:region:9859",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-33)"
        ]
    },
    {
        "name": {
            "value": "Kairouan Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9860,
        "urn": "urn:li:region:9860",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-41)"
        ]
    },
    {
        "name": {
            "value": "Ben Arous Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9861,
        "urn": "urn:li:region:9861",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-13)"
        ]
    },
    {
        "name": {
            "value": "Tunis Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9862,
        "urn": "urn:li:region:9862",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-11)"
        ]
    },
    {
        "name": {
            "value": "Bizerte Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9863,
        "urn": "urn:li:region:9863",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-23)"
        ]
    },
    {
        "name": {
            "value": "Monastir Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9864,
        "urn": "urn:li:region:9864",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-52)"
        ]
    },
    {
        "name": {
            "value": "Médenine Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9865,
        "urn": "urn:li:region:9865",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-82)"
        ]
    },
    {
        "name": {
            "value": "Sidi Bouzid Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9866,
        "urn": "urn:li:region:9866",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-43)"
        ]
    },
    {
        "name": {
            "value": "Kebili Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9867,
        "urn": "urn:li:region:9867",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-73)"
        ]
    },
    {
        "name": {
            "value": "Gabès Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9868,
        "urn": "urn:li:region:9868",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-81)"
        ]
    },
    {
        "name": {
            "value": "Zaghouan Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9869,
        "urn": "urn:li:region:9869",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-22)"
        ]
    },
    {
        "name": {
            "value": "Siliana Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9870,
        "urn": "urn:li:region:9870",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-34)"
        ]
    },
    {
        "name": {
            "value": "Béja Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9871,
        "urn": "urn:li:region:9871",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-31)"
        ]
    },
    {
        "name": {
            "value": "Tataouine Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9872,
        "urn": "urn:li:region:9872",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-83)"
        ]
    },
    {
        "name": {
            "value": "Jendouba Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9873,
        "urn": "urn:li:region:9873",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-32)"
        ]
    },
    {
        "name": {
            "value": "Sfax Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9874,
        "urn": "urn:li:region:9874",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-61)"
        ]
    },
    {
        "name": {
            "value": "Sousse Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9875,
        "urn": "urn:li:region:9875",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-51)"
        ]
    },
    {
        "name": {
            "value": "Mahdia Governorate, Tunisia"
        },
        "country": "urn:li:country:tn",
        "id": 9876,
        "urn": "urn:li:region:9876",
        "states": [
            "urn:li:state:(urn:li:country:tn,TN-53)"
        ]
    },
    {
        "name": {
            "value": "Guárico, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9877,
        "urn": "urn:li:region:9877",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-J)"
        ]
    },
    {
        "name": {
            "value": "Bolívar, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9878,
        "urn": "urn:li:region:9878",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-F)"
        ]
    },
    {
        "name": {
            "value": "Apure, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9879,
        "urn": "urn:li:region:9879",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-C)"
        ]
    },
    {
        "name": {
            "value": "Táchira, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9880,
        "urn": "urn:li:region:9880",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-S)"
        ]
    },
    {
        "name": {
            "value": "Barinas, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9881,
        "urn": "urn:li:region:9881",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-E)"
        ]
    },
    {
        "name": {
            "value": "Sucre, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9882,
        "urn": "urn:li:region:9882",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-R)"
        ]
    },
    {
        "name": {
            "value": "Miranda, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9883,
        "urn": "urn:li:region:9883",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-M)"
        ]
    },
    {
        "name": {
            "value": "Zulia, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9884,
        "urn": "urn:li:region:9884",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-V)"
        ]
    },
    {
        "name": {
            "value": "Falcón, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9885,
        "urn": "urn:li:region:9885",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-I)"
        ]
    },
    {
        "name": {
            "value": "Carabobo, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9886,
        "urn": "urn:li:region:9886",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-G)"
        ]
    },
    {
        "name": {
            "value": "Aragua, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9887,
        "urn": "urn:li:region:9887",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-D)"
        ]
    },
    {
        "name": {
            "value": "Trujillo, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9888,
        "urn": "urn:li:region:9888",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-T)"
        ]
    },
    {
        "name": {
            "value": "Monagas, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9889,
        "urn": "urn:li:region:9889",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-N)"
        ]
    },
    {
        "name": {
            "value": "Vargas, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9890,
        "urn": "urn:li:region:9890",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-X)"
        ]
    },
    {
        "name": {
            "value": "Cojedes, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9891,
        "urn": "urn:li:region:9891",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-H)"
        ]
    },
    {
        "name": {
            "value": "Anzoátegui, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9892,
        "urn": "urn:li:region:9892",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-B)"
        ]
    },
    {
        "name": {
            "value": "Caracas, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9893,
        "urn": "urn:li:region:9893",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-A)"
        ]
    },
    {
        "name": {
            "value": "Yaracuy, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9894,
        "urn": "urn:li:region:9894",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-U)"
        ]
    },
    {
        "name": {
            "value": "Lara, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9895,
        "urn": "urn:li:region:9895",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-K)"
        ]
    },
    {
        "name": {
            "value": "Delta Amacuro, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9896,
        "urn": "urn:li:region:9896",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-Y)"
        ]
    },
    {
        "name": {
            "value": "Amazonas, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9897,
        "urn": "urn:li:region:9897",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-Z)"
        ]
    },
    {
        "name": {
            "value": "Nueva Esparta, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9898,
        "urn": "urn:li:region:9898",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-O)"
        ]
    },
    {
        "name": {
            "value": "Mérida, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9899,
        "urn": "urn:li:region:9899",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-L)"
        ]
    },
    {
        "name": {
            "value": "Portuguesa, Venezuela"
        },
        "country": "urn:li:country:ve",
        "id": 9900,
        "urn": "urn:li:region:9900",
        "states": [
            "urn:li:state:(urn:li:country:ve,VE-P)"
        ]
    },
    {
        "name": {
            "value": "Aswan Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9901,
        "urn": "urn:li:region:9901",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-ASN)"
        ]
    },
    {
        "name": {
            "value": "New Valley Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9902,
        "urn": "urn:li:region:9902",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-WAD)"
        ]
    },
    {
        "name": {
            "value": "Sohag Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9903,
        "urn": "urn:li:region:9903",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-SHG)"
        ]
    },
    {
        "name": {
            "value": "North Sinai Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9904,
        "urn": "urn:li:region:9904",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-SIN)"
        ]
    },
    {
        "name": {
            "value": "Matrouh Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9905,
        "urn": "urn:li:region:9905",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-MT)"
        ]
    },
    {
        "name": {
            "value": "Port Said Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9906,
        "urn": "urn:li:region:9906",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-PTS)"
        ]
    },
    {
        "name": {
            "value": "Faiyum Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9907,
        "urn": "urn:li:region:9907",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-FYM)"
        ]
    },
    {
        "name": {
            "value": "Cairo Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9908,
        "urn": "urn:li:region:9908",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-C)"
        ]
    },
    {
        "name": {
            "value": "Giza Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9909,
        "urn": "urn:li:region:9909",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-GZ)"
        ]
    },
    {
        "name": {
            "value": "Asyut Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9910,
        "urn": "urn:li:region:9910",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-AST)"
        ]
    },
    {
        "name": {
            "value": "Damietta Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9911,
        "urn": "urn:li:region:9911",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-DT)"
        ]
    },
    {
        "name": {
            "value": "Dakahlia Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9912,
        "urn": "urn:li:region:9912",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-DK)"
        ]
    },
    {
        "name": {
            "value": "Gharbia Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9913,
        "urn": "urn:li:region:9913",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-GH)"
        ]
    },
    {
        "name": {
            "value": "Minya Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9914,
        "urn": "urn:li:region:9914",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-MN)"
        ]
    },
    {
        "name": {
            "value": "Beni Suef Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9915,
        "urn": "urn:li:region:9915",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-BNS)"
        ]
    },
    {
        "name": {
            "value": "Monufia Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9916,
        "urn": "urn:li:region:9916",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-MNF)"
        ]
    },
    {
        "name": {
            "value": "Red Sea Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9917,
        "urn": "urn:li:region:9917",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-BA)"
        ]
    },
    {
        "name": {
            "value": "Ismailia Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9918,
        "urn": "urn:li:region:9918",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-IS)"
        ]
    },
    {
        "name": {
            "value": "Alexandria Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9919,
        "urn": "urn:li:region:9919",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-ALX)"
        ]
    },
    {
        "name": {
            "value": "Qalyubia Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9920,
        "urn": "urn:li:region:9920",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-KB)"
        ]
    },
    {
        "name": {
            "value": "Kafr el-Sheikh Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9921,
        "urn": "urn:li:region:9921",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-KFS)"
        ]
    },
    {
        "name": {
            "value": "South Sinai Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9922,
        "urn": "urn:li:region:9922",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-JS)"
        ]
    },
    {
        "name": {
            "value": "Suez Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9923,
        "urn": "urn:li:region:9923",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-SUZ)"
        ]
    },
    {
        "name": {
            "value": "Beheira Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9924,
        "urn": "urn:li:region:9924",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-BH)"
        ]
    },
    {
        "name": {
            "value": "Qena Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9925,
        "urn": "urn:li:region:9925",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-KN)"
        ]
    },
    {
        "name": {
            "value": "Luxor Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9926,
        "urn": "urn:li:region:9926",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-LX)"
        ]
    },
    {
        "name": {
            "value": "Al Sharqia Governorate, Egypt"
        },
        "country": "urn:li:country:eg",
        "id": 9927,
        "urn": "urn:li:region:9927",
        "states": [
            "urn:li:state:(urn:li:country:eg,EG-SHR)"
        ]
    },
    {
        "name": {
            "value": "Baabda District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9928,
        "urn": "urn:li:region:9928",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JL)"
        ]
    },
    {
        "name": {
            "value": "Koura District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9929,
        "urn": "urn:li:region:9929",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-AS)"
        ]
    },
    {
        "name": {
            "value": "Hermel District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9930,
        "urn": "urn:li:region:9930",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-BI)"
        ]
    },
    {
        "name": {
            "value": "Zahle District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9931,
        "urn": "urn:li:region:9931",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-BI)"
        ]
    },
    {
        "name": {
            "value": "Nabatiye District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9932,
        "urn": "urn:li:region:9932",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-NA)"
        ]
    },
    {
        "name": {
            "value": "Sour District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9933,
        "urn": "urn:li:region:9933",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JA)"
        ]
    },
    {
        "name": {
            "value": "Baalbek District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9934,
        "urn": "urn:li:region:9934",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-BI)"
        ]
    },
    {
        "name": {
            "value": "Tripoli District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9935,
        "urn": "urn:li:region:9935",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-AS)"
        ]
    },
    {
        "name": {
            "value": "Beirut District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9936,
        "urn": "urn:li:region:9936",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-BA)"
        ]
    },
    {
        "name": {
            "value": "Hasbaiya District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9937,
        "urn": "urn:li:region:9937",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-NA)"
        ]
    },
    {
        "name": {
            "value": "Akkar District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9938,
        "urn": "urn:li:region:9938",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-AS)"
        ]
    },
    {
        "name": {
            "value": "Zgharta District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9939,
        "urn": "urn:li:region:9939",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-AS)"
        ]
    },
    {
        "name": {
            "value": "Jezzine District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9940,
        "urn": "urn:li:region:9940",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JA)"
        ]
    },
    {
        "name": {
            "value": "Bint Jubail District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9941,
        "urn": "urn:li:region:9941",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-NA)"
        ]
    },
    {
        "name": {
            "value": "Batroun District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9942,
        "urn": "urn:li:region:9942",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-AS)"
        ]
    },
    {
        "name": {
            "value": "El Metn District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9943,
        "urn": "urn:li:region:9943",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JL)"
        ]
    },
    {
        "name": {
            "value": "Bcharre District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9944,
        "urn": "urn:li:region:9944",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-AS)"
        ]
    },
    {
        "name": {
            "value": "Jubail District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9945,
        "urn": "urn:li:region:9945",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JL)"
        ]
    },
    {
        "name": {
            "value": "Rachiaya District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9946,
        "urn": "urn:li:region:9946",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-BI)"
        ]
    },
    {
        "name": {
            "value": "Minieh-Danieh District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9947,
        "urn": "urn:li:region:9947",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-AS)"
        ]
    },
    {
        "name": {
            "value": "Chouf District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9948,
        "urn": "urn:li:region:9948",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JL)"
        ]
    },
    {
        "name": {
            "value": "West Bekaa District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9949,
        "urn": "urn:li:region:9949",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-BI)"
        ]
    },
    {
        "name": {
            "value": "Aley District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9950,
        "urn": "urn:li:region:9950",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JL)"
        ]
    },
    {
        "name": {
            "value": "Marjaayoun District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9951,
        "urn": "urn:li:region:9951",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-NA)"
        ]
    },
    {
        "name": {
            "value": "Saida District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9952,
        "urn": "urn:li:region:9952",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JA)"
        ]
    },
    {
        "name": {
            "value": "Kasrouane District, Lebanon"
        },
        "country": "urn:li:country:lb",
        "id": 9953,
        "urn": "urn:li:region:9953",
        "states": [
            "urn:li:state:(urn:li:country:lb,LB-JL)"
        ]
    },
    {
        "name": {
            "value": "Doha, Qatar"
        },
        "country": "urn:li:country:qa",
        "id": 9954,
        "urn": "urn:li:region:9954",
        "states": [
            "urn:li:state:(urn:li:country:qa,QA-DA)"
        ]
    },
    {
        "name": {
            "value": "Al Shamal, Qatar"
        },
        "country": "urn:li:country:qa",
        "id": 9955,
        "urn": "urn:li:region:9955",
        "states": [
            "urn:li:state:(urn:li:country:qa,QA-MS)"
        ]
    },
    {
        "name": {
            "value": "Al Rayyan, Qatar"
        },
        "country": "urn:li:country:qa",
        "id": 9956,
        "urn": "urn:li:region:9956",
        "states": [
            "urn:li:state:(urn:li:country:qa,QA-RA)"
        ]
    },
    {
        "name": {
            "value": "Al Khor, Qatar"
        },
        "country": "urn:li:country:qa",
        "id": 9957,
        "urn": "urn:li:region:9957",
        "states": [
            "urn:li:state:(urn:li:country:qa,QA-KH)"
        ]
    },
    {
        "name": {
            "value": "Umm Salal, Qatar"
        },
        "country": "urn:li:country:qa",
        "id": 9958,
        "urn": "urn:li:region:9958",
        "states": [
            "urn:li:state:(urn:li:country:qa,QA-US)"
        ]
    },
    {
        "name": {
            "value": "Al Wakrah, Qatar"
        },
        "country": "urn:li:country:qa",
        "id": 9959,
        "urn": "urn:li:region:9959",
        "states": [
            "urn:li:state:(urn:li:country:qa,QA-WA)"
        ]
    },
    {
        "name": {
            "value": "Al Dayyan, Qatar"
        },
        "country": "urn:li:country:qa",
        "id": 9960,
        "urn": "urn:li:region:9960",
        "states": [
            "urn:li:state:(urn:li:country:qa,QA-ZA)"
        ]
    },
    {
        "name": {
            "value": "Fujairah, United Arab Emirates"
        },
        "country": "urn:li:country:ae",
        "id": 9961,
        "urn": "urn:li:region:9961",
        "states": [
            "urn:li:state:(urn:li:country:ae,AE-FU)"
        ]
    },
    {
        "name": {
            "value": "Abu Dhabi, United Arab Emirates"
        },
        "country": "urn:li:country:ae",
        "id": 9962,
        "urn": "urn:li:region:9962",
        "states": [
            "urn:li:state:(urn:li:country:ae,AE-AZ)"
        ]
    },
    {
        "name": {
            "value": "Dubai, United Arab Emirates"
        },
        "country": "urn:li:country:ae",
        "id": 9963,
        "urn": "urn:li:region:9963",
        "states": [
            "urn:li:state:(urn:li:country:ae,AE-DU)"
        ]
    },
    {
        "name": {
            "value": "Sharjah, United Arab Emirates"
        },
        "country": "urn:li:country:ae",
        "id": 9964,
        "urn": "urn:li:region:9964",
        "states": [
            "urn:li:state:(urn:li:country:ae,AE-SH)"
        ]
    },
    {
        "name": {
            "value": "Ras al-Khaimah, United Arab Emirates"
        },
        "country": "urn:li:country:ae",
        "id": 9965,
        "urn": "urn:li:region:9965",
        "states": [
            "urn:li:state:(urn:li:country:ae,AE-RK)"
        ]
    },
    {
        "name": {
            "value": "Ajman, United Arab Emirates"
        },
        "country": "urn:li:country:ae",
        "id": 9966,
        "urn": "urn:li:region:9966",
        "states": [
            "urn:li:state:(urn:li:country:ae,AE-AJ)"
        ]
    },
    {
        "name": {
            "value": "Umm al-Quwain, United Arab Emirates"
        },
        "country": "urn:li:country:ae",
        "id": 9967,
        "urn": "urn:li:region:9967",
        "states": [
            "urn:li:state:(urn:li:country:ae,AE-UQ)"
        ]
    },
    {
        "name": {
            "value": "Tafielah Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9968,
        "urn": "urn:li:region:9968",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-AT)"
        ]
    },
    {
        "name": {
            "value": "Irbid Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9969,
        "urn": "urn:li:region:9969",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-IR)"
        ]
    },
    {
        "name": {
            "value": "Karak Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9970,
        "urn": "urn:li:region:9970",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-KA)"
        ]
    },
    {
        "name": {
            "value": "Amman Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9971,
        "urn": "urn:li:region:9971",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-AM)"
        ]
    },
    {
        "name": {
            "value": "Zarqa Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9972,
        "urn": "urn:li:region:9972",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-AZ)"
        ]
    },
    {
        "name": {
            "value": "Ma’an Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9973,
        "urn": "urn:li:region:9973",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-MN)"
        ]
    },
    {
        "name": {
            "value": "Balqa Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9974,
        "urn": "urn:li:region:9974",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-BA)"
        ]
    },
    {
        "name": {
            "value": "Madaba Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9975,
        "urn": "urn:li:region:9975",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-MD)"
        ]
    },
    {
        "name": {
            "value": "Jerash Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9977,
        "urn": "urn:li:region:9977",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-JA)"
        ]
    },
    {
        "name": {
            "value": "Aqaba Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9978,
        "urn": "urn:li:region:9978",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-AQ)"
        ]
    },
    {
        "name": {
            "value": "Mafraq Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9979,
        "urn": "urn:li:region:9979",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-MA)"
        ]
    },
    {
        "name": {
            "value": "Ajlun Governorate, Jordan"
        },
        "country": "urn:li:country:jo",
        "id": 9980,
        "urn": "urn:li:region:9980",
        "states": [
            "urn:li:state:(urn:li:country:jo,JO-AJ)"
        ]
    },
    {
        "name": {
            "value": "Muscat Governorate, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9981,
        "urn": "urn:li:region:9981",
        "states": []
    },
    {
        "name": {
            "value": "Dhofar Governorate, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9982,
        "urn": "urn:li:region:9982",
        "states": []
    },
    {
        "name": {
            "value": "Ash Sharqiyah Region, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9983,
        "urn": "urn:li:region:9983",
        "states": []
    },
    {
        "name": {
            "value": "Al Buraimi Governorate, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9984,
        "urn": "urn:li:region:9984",
        "states": []
    },
    {
        "name": {
            "value": "Al Wusta Region, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9985,
        "urn": "urn:li:region:9985",
        "states": []
    },
    {
        "name": {
            "value": "Al Batinah Region, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9986,
        "urn": "urn:li:region:9986",
        "states": []
    },
    {
        "name": {
            "value": "Ad Dakhiliyah Region, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9987,
        "urn": "urn:li:region:9987",
        "states": []
    },
    {
        "name": {
            "value": "Ad Dhahirah Governorate, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9988,
        "urn": "urn:li:region:9988",
        "states": []
    },
    {
        "name": {
            "value": "Musandam Governorate, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9989,
        "urn": "urn:li:region:9989",
        "states": [
            "urn:li:state:(urn:li:country:om,OM-MU)"
        ]
    },
    {
        "name": {
            "value": "Ad Dhahirah Region, Oman"
        },
        "country": "urn:li:country:om",
        "id": 9990,
        "urn": "urn:li:region:9990",
        "states": []
    },
    {
        "name": {
            "value": "Al-Mahd Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9991,
        "urn": "urn:li:region:9991",
        "states": []
    },
    {
        "name": {
            "value": "Abha Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9992,
        "urn": "urn:li:region:9992",
        "states": []
    },
    {
        "name": {
            "value": "Al-Mukhwa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9993,
        "urn": "urn:li:region:9993",
        "states": []
    },
    {
        "name": {
            "value": "Al-Asyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9994,
        "urn": "urn:li:region:9994",
        "states": []
    },
    {
        "name": {
            "value": "Oniezah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9995,
        "urn": "urn:li:region:9995",
        "states": []
    },
    {
        "name": {
            "value": "Al-Kharkir Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9996,
        "urn": "urn:li:region:9996",
        "states": []
    },
    {
        "name": {
            "value": "Al-Zulfa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9997,
        "urn": "urn:li:region:9997",
        "states": []
    },
    {
        "name": {
            "value": "Dhiram Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9998,
        "urn": "urn:li:region:9998",
        "states": []
    },
    {
        "name": {
            "value": "Al-’Ola Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 9999,
        "urn": "urn:li:region:9999",
        "states": []
    },
    {
        "name": {
            "value": "Al-Madinah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10000,
        "urn": "urn:li:region:10000",
        "states": []
    },
    {
        "name": {
            "value": "Al-Lieth Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10001,
        "urn": "urn:li:region:10001",
        "states": []
    },
    {
        "name": {
            "value": "Wadi Al-Dawsir Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10002,
        "urn": "urn:li:region:10002",
        "states": []
    },
    {
        "name": {
            "value": "Al-Hrath Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10003,
        "urn": "urn:li:region:10003",
        "states": []
    },
    {
        "name": {
            "value": "Al-Jubial Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10004,
        "urn": "urn:li:region:10004",
        "states": []
    },
    {
        "name": {
            "value": "Baq’aa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10005,
        "urn": "urn:li:region:10005",
        "states": []
    },
    {
        "name": {
            "value": "Abu ’Arieesh Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10006,
        "urn": "urn:li:region:10006",
        "states": []
    },
    {
        "name": {
            "value": "Dhiba Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10007,
        "urn": "urn:li:region:10007",
        "states": []
    },
    {
        "name": {
            "value": "Oyoon Al-Jewaa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10008,
        "urn": "urn:li:region:10008",
        "states": []
    },
    {
        "name": {
            "value": "Tathieth Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10009,
        "urn": "urn:li:region:10009",
        "states": []
    },
    {
        "name": {
            "value": "Rijal Alma’a Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10010,
        "urn": "urn:li:region:10010",
        "states": []
    },
    {
        "name": {
            "value": "Rabigh Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10011,
        "urn": "urn:li:region:10011",
        "states": []
    },
    {
        "name": {
            "value": "Ahad Rufiedah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10012,
        "urn": "urn:li:region:10012",
        "states": []
    },
    {
        "name": {
            "value": "Al-Nu’aeriyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10013,
        "urn": "urn:li:region:10013",
        "states": []
    },
    {
        "name": {
            "value": "Tayma Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10014,
        "urn": "urn:li:region:10014",
        "states": []
    },
    {
        "name": {
            "value": "Al-Rass Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10015,
        "urn": "urn:li:region:10015",
        "states": []
    },
    {
        "name": {
            "value": "Al-Salil Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10016,
        "urn": "urn:li:region:10016",
        "states": []
    },
    {
        "name": {
            "value": "Khamis Mishiet Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10017,
        "urn": "urn:li:region:10017",
        "states": []
    },
    {
        "name": {
            "value": "Badr Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10018,
        "urn": "urn:li:region:10018",
        "states": []
    },
    {
        "name": {
            "value": "Samtah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10019,
        "urn": "urn:li:region:10019",
        "states": []
    },
    {
        "name": {
            "value": "Sakaka Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10020,
        "urn": "urn:li:region:10020",
        "states": []
    },
    {
        "name": {
            "value": "Yanb’u Al-Bahr Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10021,
        "urn": "urn:li:region:10021",
        "states": []
    },
    {
        "name": {
            "value": "Al-Mandaq Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10022,
        "urn": "urn:li:region:10022",
        "states": []
    },
    {
        "name": {
            "value": "Al-Kamel Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10023,
        "urn": "urn:li:region:10023",
        "states": []
    },
    {
        "name": {
            "value": "Al-’Aqiq Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10024,
        "urn": "urn:li:region:10024",
        "states": []
    },
    {
        "name": {
            "value": "Makkah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10025,
        "urn": "urn:li:region:10025",
        "states": []
    },
    {
        "name": {
            "value": "Sharorah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10026,
        "urn": "urn:li:region:10026",
        "states": []
    },
    {
        "name": {
            "value": "Al-Bukieriyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10027,
        "urn": "urn:li:region:10027",
        "states": []
    },
    {
        "name": {
            "value": "Dhamd Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10028,
        "urn": "urn:li:region:10028",
        "states": []
    },
    {
        "name": {
            "value": "Raniyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10029,
        "urn": "urn:li:region:10029",
        "states": []
    },
    {
        "name": {
            "value": "Baljurashi Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10030,
        "urn": "urn:li:region:10030",
        "states": []
    },
    {
        "name": {
            "value": "Habouna Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10031,
        "urn": "urn:li:region:10031",
        "states": []
    },
    {
        "name": {
            "value": "Al-Qariyat Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10032,
        "urn": "urn:li:region:10032",
        "states": []
    },
    {
        "name": {
            "value": "Al-Shamasiyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10033,
        "urn": "urn:li:region:10033",
        "states": []
    },
    {
        "name": {
            "value": "Ha’il Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10034,
        "urn": "urn:li:region:10034",
        "states": []
    },
    {
        "name": {
            "value": "Ahd Al-Masarha Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10035,
        "urn": "urn:li:region:10035",
        "states": []
    },
    {
        "name": {
            "value": "Al-Dammam Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10036,
        "urn": "urn:li:region:10036",
        "states": []
    },
    {
        "name": {
            "value": "Al-Qwie’iyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10037,
        "urn": "urn:li:region:10037",
        "states": []
    },
    {
        "name": {
            "value": "Al-Nabhaniyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10038,
        "urn": "urn:li:region:10038",
        "states": []
    },
    {
        "name": {
            "value": "Al-Qunfudah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10039,
        "urn": "urn:li:region:10039",
        "states": []
    },
    {
        "name": {
            "value": "Fursan Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10040,
        "urn": "urn:li:region:10040",
        "states": []
    },
    {
        "name": {
            "value": "Ghazalah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10041,
        "urn": "urn:li:region:10041",
        "states": []
    },
    {
        "name": {
            "value": "Al-Qateif Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10042,
        "urn": "urn:li:region:10042",
        "states": []
    },
    {
        "name": {
            "value": "Al-Jamoum Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10043,
        "urn": "urn:li:region:10043",
        "states": []
    },
    {
        "name": {
            "value": "Al-Kharj Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10044,
        "urn": "urn:li:region:10044",
        "states": []
    },
    {
        "name": {
            "value": "Al-Aflaj Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10045,
        "urn": "urn:li:region:10045",
        "states": []
    },
    {
        "name": {
            "value": "Al-Harieq Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10046,
        "urn": "urn:li:region:10046",
        "states": []
    },
    {
        "name": {
            "value": "Al-Baha Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10047,
        "urn": "urn:li:region:10047",
        "states": []
    },
    {
        "name": {
            "value": "Khaibar Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10048,
        "urn": "urn:li:region:10048",
        "states": []
    },
    {
        "name": {
            "value": "Arar Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10049,
        "urn": "urn:li:region:10049",
        "states": []
    },
    {
        "name": {
            "value": "Al-Khafji Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10050,
        "urn": "urn:li:region:10050",
        "states": []
    },
    {
        "name": {
            "value": "Riaydh Al-Khubraa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10051,
        "urn": "urn:li:region:10051",
        "states": []
    },
    {
        "name": {
            "value": "Al-Maznab Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10052,
        "urn": "urn:li:region:10052",
        "states": []
    },
    {
        "name": {
            "value": "Buriedah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10053,
        "urn": "urn:li:region:10053",
        "states": []
    },
    {
        "name": {
            "value": "Al-Nams Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10054,
        "urn": "urn:li:region:10054",
        "states": []
    },
    {
        "name": {
            "value": "Balqarn Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10055,
        "urn": "urn:li:region:10055",
        "states": []
    },
    {
        "name": {
            "value": "Al Wajh Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10056,
        "urn": "urn:li:region:10056",
        "states": []
    },
    {
        "name": {
            "value": "Hafr Al-Batin Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10057,
        "urn": "urn:li:region:10057",
        "states": []
    },
    {
        "name": {
            "value": "Al-Dawdmi Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10058,
        "urn": "urn:li:region:10058",
        "states": []
    },
    {
        "name": {
            "value": "Thadiq Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10059,
        "urn": "urn:li:region:10059",
        "states": []
    },
    {
        "name": {
            "value": "Amlaj Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10060,
        "urn": "urn:li:region:10060",
        "states": []
    },
    {
        "name": {
            "value": "Jeddah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10061,
        "urn": "urn:li:region:10061",
        "states": []
    },
    {
        "name": {
            "value": "Al-Badai’a Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10062,
        "urn": "urn:li:region:10062",
        "states": []
    },
    {
        "name": {
            "value": "Yiadmah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10063,
        "urn": "urn:li:region:10063",
        "states": []
    },
    {
        "name": {
            "value": "Hurimalilaa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10064,
        "urn": "urn:li:region:10064",
        "states": []
    },
    {
        "name": {
            "value": "Haql Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10065,
        "urn": "urn:li:region:10065",
        "states": []
    },
    {
        "name": {
            "value": "Al-Riyadh Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10066,
        "urn": "urn:li:region:10066",
        "states": []
    },
    {
        "name": {
            "value": "Al-’Idabi Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10067,
        "urn": "urn:li:region:10067",
        "states": []
    },
    {
        "name": {
            "value": "Al-Ahsa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10068,
        "urn": "urn:li:region:10068",
        "states": []
    },
    {
        "name": {
            "value": "Sabiyaa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10069,
        "urn": "urn:li:region:10069",
        "states": []
    },
    {
        "name": {
            "value": "Khulies Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10070,
        "urn": "urn:li:region:10070",
        "states": []
    },
    {
        "name": {
            "value": "Biesh Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10071,
        "urn": "urn:li:region:10071",
        "states": []
    },
    {
        "name": {
            "value": "Dhahran Al-Janoub Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10072,
        "urn": "urn:li:region:10072",
        "states": []
    },
    {
        "name": {
            "value": "Dawmat Al-Jandal Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10073,
        "urn": "urn:li:region:10073",
        "states": []
    },
    {
        "name": {
            "value": "Al-Dair Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10074,
        "urn": "urn:li:region:10074",
        "states": []
    },
    {
        "name": {
            "value": "Rafha’ Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10075,
        "urn": "urn:li:region:10075",
        "states": []
    },
    {
        "name": {
            "value": "Al-Majardah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10076,
        "urn": "urn:li:region:10076",
        "states": []
    },
    {
        "name": {
            "value": "Qariyat Al-’Oliyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10077,
        "urn": "urn:li:region:10077",
        "states": []
    },
    {
        "name": {
            "value": "Qulwah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10078,
        "urn": "urn:li:region:10078",
        "states": []
    },
    {
        "name": {
            "value": "Al-Qura Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10079,
        "urn": "urn:li:region:10079",
        "states": []
    },
    {
        "name": {
            "value": "Najran Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10080,
        "urn": "urn:li:region:10080",
        "states": []
    },
    {
        "name": {
            "value": "Biesha Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10081,
        "urn": "urn:li:region:10081",
        "states": []
    },
    {
        "name": {
            "value": "Turbah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10082,
        "urn": "urn:li:region:10082",
        "states": []
    },
    {
        "name": {
            "value": "Raith Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10083,
        "urn": "urn:li:region:10083",
        "states": []
    },
    {
        "name": {
            "value": "Al-Ghat Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10084,
        "urn": "urn:li:region:10084",
        "states": []
    },
    {
        "name": {
            "value": "Al-Hanakiayh Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10085,
        "urn": "urn:li:region:10085",
        "states": []
    },
    {
        "name": {
            "value": "Al-Darb Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10086,
        "urn": "urn:li:region:10086",
        "states": []
    },
    {
        "name": {
            "value": "Tarif Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10087,
        "urn": "urn:li:region:10087",
        "states": []
    },
    {
        "name": {
            "value": "Al-Shanan Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10088,
        "urn": "urn:li:region:10088",
        "states": []
    },
    {
        "name": {
            "value": "Al-Khobar Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10089,
        "urn": "urn:li:region:10089",
        "states": []
    },
    {
        "name": {
            "value": "Ramamh Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10090,
        "urn": "urn:li:region:10090",
        "states": []
    },
    {
        "name": {
            "value": "Badr Al-Janoub Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10091,
        "urn": "urn:li:region:10091",
        "states": []
    },
    {
        "name": {
            "value": "Al-’Ardhah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10092,
        "urn": "urn:li:region:10092",
        "states": []
    },
    {
        "name": {
            "value": "Al-Taief Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10093,
        "urn": "urn:li:region:10093",
        "states": []
    },
    {
        "name": {
            "value": "Al-Muzahmiyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10094,
        "urn": "urn:li:region:10094",
        "states": []
    },
    {
        "name": {
            "value": "Al-Khurmah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10095,
        "urn": "urn:li:region:10095",
        "states": []
    },
    {
        "name": {
            "value": "Mahaiel Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10096,
        "urn": "urn:li:region:10096",
        "states": []
    },
    {
        "name": {
            "value": "Abgig Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10097,
        "urn": "urn:li:region:10097",
        "states": []
    },
    {
        "name": {
            "value": "Shaqraa Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10098,
        "urn": "urn:li:region:10098",
        "states": []
    },
    {
        "name": {
            "value": "Ras Tannoura Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10099,
        "urn": "urn:li:region:10099",
        "states": []
    },
    {
        "name": {
            "value": "Hotat Bani Tamim Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10100,
        "urn": "urn:li:region:10100",
        "states": []
    },
    {
        "name": {
            "value": "Al-Dir’iyah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10101,
        "urn": "urn:li:region:10101",
        "states": []
    },
    {
        "name": {
            "value": "Afif Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10102,
        "urn": "urn:li:region:10102",
        "states": []
    },
    {
        "name": {
            "value": "Tabuk Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10103,
        "urn": "urn:li:region:10103",
        "states": []
    },
    {
        "name": {
            "value": "Khabash Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10104,
        "urn": "urn:li:region:10104",
        "states": []
    },
    {
        "name": {
            "value": "Thar Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10105,
        "urn": "urn:li:region:10105",
        "states": []
    },
    {
        "name": {
            "value": "Al-Majma’ah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10106,
        "urn": "urn:li:region:10106",
        "states": []
    },
    {
        "name": {
            "value": "Jizan Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10107,
        "urn": "urn:li:region:10107",
        "states": []
    },
    {
        "name": {
            "value": "Surat ’Obiedah Governorate, Saudi Arabia"
        },
        "country": "urn:li:country:sa",
        "id": 10108,
        "urn": "urn:li:region:10108",
        "states": []
    },
    {
        "name": {
            "value": "Central Singapore"
        },
        "country": "urn:li:country:sg",
        "id": 10135,
        "urn": "urn:li:region:10135",
        "states": [
            "urn:li:state:(urn:li:country:sg,CS)"
        ]
    },
    {
        "name": {
            "value": "North East, Singapore"
        },
        "country": "urn:li:country:sg",
        "id": 10136,
        "urn": "urn:li:region:10136",
        "states": [
            "urn:li:state:(urn:li:country:sg,NE)"
        ]
    },
    {
        "name": {
            "value": "North West, Singapore"
        },
        "country": "urn:li:country:sg",
        "id": 10137,
        "urn": "urn:li:region:10137",
        "states": [
            "urn:li:state:(urn:li:country:sg,NW)"
        ]
    },
    {
        "name": {
            "value": "South East, Singapore"
        },
        "country": "urn:li:country:sg",
        "id": 10138,
        "urn": "urn:li:region:10138",
        "states": [
            "urn:li:state:(urn:li:country:sg,SE)"
        ]
    },
    {
        "name": {
            "value": "South West, Singapore"
        },
        "country": "urn:li:country:sg",
        "id": 10139,
        "urn": "urn:li:region:10139",
        "states": [
            "urn:li:state:(urn:li:country:sg,SW)"
        ]
    },
    {
        "name": {
            "value": "County Carlow, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10140,
        "urn": "urn:li:region:10140",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Westmeath, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10141,
        "urn": "urn:li:region:10141",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Laois, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10142,
        "urn": "urn:li:region:10142",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Limerick, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10143,
        "urn": "urn:li:region:10143",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-M)"
        ]
    },
    {
        "name": {
            "value": "County Louth, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10144,
        "urn": "urn:li:region:10144",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Kerry, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10145,
        "urn": "urn:li:region:10145",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-M)"
        ]
    },
    {
        "name": {
            "value": "County Clare, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10146,
        "urn": "urn:li:region:10146",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-M)"
        ]
    },
    {
        "name": {
            "value": "County Wicklow, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10147,
        "urn": "urn:li:region:10147",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Meath, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10148,
        "urn": "urn:li:region:10148",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Tipperary, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10149,
        "urn": "urn:li:region:10149",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-M)"
        ]
    },
    {
        "name": {
            "value": "County Cork, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10150,
        "urn": "urn:li:region:10150",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-M)"
        ]
    },
    {
        "name": {
            "value": "County Waterford, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10151,
        "urn": "urn:li:region:10151",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-M)"
        ]
    },
    {
        "name": {
            "value": "County Sligo, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10152,
        "urn": "urn:li:region:10152",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-C)"
        ]
    },
    {
        "name": {
            "value": "County Kilkenny, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10153,
        "urn": "urn:li:region:10153",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Galway, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10154,
        "urn": "urn:li:region:10154",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-C)"
        ]
    },
    {
        "name": {
            "value": "County Kildare, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10155,
        "urn": "urn:li:region:10155",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Longford, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10156,
        "urn": "urn:li:region:10156",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Offaly, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10157,
        "urn": "urn:li:region:10157",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Roscommon, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10158,
        "urn": "urn:li:region:10158",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-C)"
        ]
    },
    {
        "name": {
            "value": "County Leitrim, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10159,
        "urn": "urn:li:region:10159",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-C)"
        ]
    },
    {
        "name": {
            "value": "County Cavan, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10160,
        "urn": "urn:li:region:10160",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-U)"
        ]
    },
    {
        "name": {
            "value": "County Dublin, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10161,
        "urn": "urn:li:region:10161",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Mayo, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10162,
        "urn": "urn:li:region:10162",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-C)"
        ]
    },
    {
        "name": {
            "value": "County Wexford, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10163,
        "urn": "urn:li:region:10163",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-L)"
        ]
    },
    {
        "name": {
            "value": "County Monaghan, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10164,
        "urn": "urn:li:region:10164",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-U)"
        ]
    },
    {
        "name": {
            "value": "County Donegal, Ireland"
        },
        "country": "urn:li:country:ie",
        "id": 10165,
        "urn": "urn:li:region:10165",
        "states": [
            "urn:li:state:(urn:li:country:ie,IE-U)"
        ]
    },
    {
        "name": {
            "value": "Limburg, Belgium"
        },
        "country": "urn:li:country:be",
        "id": 10166,
        "urn": "urn:li:region:10166",
        "states": [
            "urn:li:state:(urn:li:country:be,VLG)"
        ]
    },
    {
        "name": {
            "value": "Federal Capial &AJK, Pakistan"
        },
        "country": "urn:li:country:pk",
        "id": 10167,
        "urn": "urn:li:region:10167",
        "states": [
            "urn:li:state:(urn:li:country:pk,2)"
        ]
    },
    {
        "name": {
            "value": "Southern Punjab Multan, Pakistan"
        },
        "country": "urn:li:country:pk",
        "id": 10168,
        "urn": "urn:li:region:10168",
        "states": [
            "urn:li:state:(urn:li:country:pk,4)"
        ]
    },
    {
        "name": {
            "value": "Balochisan, Pakistan"
        },
        "country": "urn:li:country:pk",
        "id": 10169,
        "urn": "urn:li:region:10169",
        "states": [
            "urn:li:state:(urn:li:country:pk,7)"
        ]
    },
    {
        "name": {
            "value": "Lahore, Pakistan"
        },
        "country": "urn:li:country:pk",
        "id": 10170,
        "urn": "urn:li:region:10170",
        "states": [
            "urn:li:state:(urn:li:country:pk,5)"
        ]
    },
    {
        "name": {
            "value": "Hyderabad, Pakistan"
        },
        "country": "urn:li:country:pk",
        "id": 10171,
        "urn": "urn:li:region:10171",
        "states": [
            "urn:li:state:(urn:li:country:pk,6)"
        ]
    },
    {
        "name": {
            "value": "Northern Punjab Rawalpindi, Pakistan"
        },
        "country": "urn:li:country:pk",
        "id": 10172,
        "urn": "urn:li:region:10172",
        "states": [
            "urn:li:state:(urn:li:country:pk,1)"
        ]
    },
    {
        "name": {
            "value": "NWFP Peshawar, Pakistan"
        },
        "country": "urn:li:country:pk",
        "id": 10173,
        "urn": "urn:li:region:10173",
        "states": [
            "urn:li:state:(urn:li:country:pk,3)"
        ]
    },
    {
        "name": {
            "value": "Vinnytsia Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10174,
        "urn": "urn:li:region:10174",
        "states": [
            "urn:li:state:(urn:li:country:ua,5)"
        ]
    },
    {
        "name": {
            "value": "Volyn Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10175,
        "urn": "urn:li:region:10175",
        "states": [
            "urn:li:state:(urn:li:country:ua,7)"
        ]
    },
    {
        "name": {
            "value": "Luhansk Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10176,
        "urn": "urn:li:region:10176",
        "states": [
            "urn:li:state:(urn:li:country:ua,9)"
        ]
    },
    {
        "name": {
            "value": "Dnipropetrovsk Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10177,
        "urn": "urn:li:region:10177",
        "states": [
            "urn:li:state:(urn:li:country:ua,12)"
        ]
    },
    {
        "name": {
            "value": "Zhytomyr Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10178,
        "urn": "urn:li:region:10178",
        "states": [
            "urn:li:state:(urn:li:country:ua,14)"
        ]
    },
    {
        "name": {
            "value": "Donetsk Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10179,
        "urn": "urn:li:region:10179",
        "states": [
            "urn:li:state:(urn:li:country:ua,18)"
        ]
    },
    {
        "name": {
            "value": "Zakarpattia Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10180,
        "urn": "urn:li:region:10180",
        "states": [
            "urn:li:state:(urn:li:country:ua,21)"
        ]
    },
    {
        "name": {
            "value": "Zaporizhia Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10181,
        "urn": "urn:li:region:10181",
        "states": [
            "urn:li:state:(urn:li:country:ua,23)"
        ]
    },
    {
        "name": {
            "value": "Ivano-Frankivsk Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10182,
        "urn": "urn:li:region:10182",
        "states": [
            "urn:li:state:(urn:li:country:ua,26)"
        ]
    },
    {
        "name": {
            "value": "Kiev Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10183,
        "urn": "urn:li:region:10183",
        "states": [
            "urn:li:state:(urn:li:country:ua,32)"
        ]
    },
    {
        "name": {
            "value": "Kirovohrad Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10184,
        "urn": "urn:li:region:10184",
        "states": [
            "urn:li:state:(urn:li:country:ua,35)"
        ]
    },
    {
        "name": {
            "value": "Lviv Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10186,
        "urn": "urn:li:region:10186",
        "states": [
            "urn:li:state:(urn:li:country:ua,46)"
        ]
    },
    {
        "name": {
            "value": "Mykolaiv Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10187,
        "urn": "urn:li:region:10187",
        "states": [
            "urn:li:state:(urn:li:country:ua,48)"
        ]
    },
    {
        "name": {
            "value": "Odessa Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10188,
        "urn": "urn:li:region:10188",
        "states": [
            "urn:li:state:(urn:li:country:ua,51)"
        ]
    },
    {
        "name": {
            "value": "Poltava Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10189,
        "urn": "urn:li:region:10189",
        "states": [
            "urn:li:state:(urn:li:country:ua,53)"
        ]
    },
    {
        "name": {
            "value": "Rivne Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10190,
        "urn": "urn:li:region:10190",
        "states": [
            "urn:li:state:(urn:li:country:ua,56)"
        ]
    },
    {
        "name": {
            "value": "Sumy Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10191,
        "urn": "urn:li:region:10191",
        "states": [
            "urn:li:state:(urn:li:country:ua,59)"
        ]
    },
    {
        "name": {
            "value": "Ternopil Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10192,
        "urn": "urn:li:region:10192",
        "states": [
            "urn:li:state:(urn:li:country:ua,61)"
        ]
    },
    {
        "name": {
            "value": "Kharkiv Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10193,
        "urn": "urn:li:region:10193",
        "states": [
            "urn:li:state:(urn:li:country:ua,63)"
        ]
    },
    {
        "name": {
            "value": "Kherson Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10194,
        "urn": "urn:li:region:10194",
        "states": [
            "urn:li:state:(urn:li:country:ua,65)"
        ]
    },
    {
        "name": {
            "value": "Khmelnytskyi Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10195,
        "urn": "urn:li:region:10195",
        "states": [
            "urn:li:state:(urn:li:country:ua,68)"
        ]
    },
    {
        "name": {
            "value": "Cherkasy Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10196,
        "urn": "urn:li:region:10196",
        "states": [
            "urn:li:state:(urn:li:country:ua,71)"
        ]
    },
    {
        "name": {
            "value": "Chernihiv Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10197,
        "urn": "urn:li:region:10197",
        "states": [
            "urn:li:state:(urn:li:country:ua,74)"
        ]
    },
    {
        "name": {
            "value": "Chernivtsi Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10198,
        "urn": "urn:li:region:10198",
        "states": [
            "urn:li:state:(urn:li:country:ua,77)"
        ]
    },
    {
        "name": {
            "value": "Crimea Region, Ukraine"
        },
        "country": "urn:li:country:ua",
        "id": 10199,
        "urn": "urn:li:region:10199",
        "states": []
    },
    {
        "name": {
            "value": "Neuchâtel, Switzerland"
        },
        "country": "urn:li:country:ch",
        "id": 10200,
        "urn": "urn:li:region:10200",
        "states": [
            "urn:li:state:(urn:li:country:ch,NE)"
        ]
    },
    {
        "name": {
            "value": "Jura, Switzerland"
        },
        "id": 10201,
        "urn": "urn:li:region:10201",
        "states": [
            "urn:li:state:(urn:li:country:ch,JU)"
        ]
    }
]