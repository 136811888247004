import React, { useContext } from 'react'
import Face from '../../assets/face.svg'
import Styles from './ConnectAccountSocialPopup.module.css'
import { UserNavigationContext } from '../../store/NavigationProvider'
const ConnectAccountSocialPopup=(props)=>{
    const userNavigation = useContext(UserNavigationContext)
    return(
        <div className={Styles.connect_account_social_popup} >
            <img src={Face} alt="" />
            <label>Connect your social account for analytics</label>
            <button onClick={()=>userNavigation.setisShowConnectAccountPopup(true)} >Connect</button>
        </div>
    )
}

export default ConnectAccountSocialPopup