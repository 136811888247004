import OverviewCard from '../../Component/OverviewCard/overviewcard'
import Styles from './LinkedinFollowerPage.module.css'
import { useContext, useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    registerables
} from 'chart.js'
import Navbar from '../../Component/Navbar/navbar';
import AllFollowerLocationModel from '../../Component/AllFollowerLocationModel/AllFollowerLocationModel';
import LinkedinFollowerPageSkeleton from '../../Component/LinkedinFollowerPageSkeleton/LinkedinFollowerPageSkeleton';
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent';
import ProgressBarCustomComponent from '../../Component/ProgressBarCustomComponent/ProgressBarCustomComponent';
import { seniorities } from '../../Util/seniorities';
import { CreateLinkedinAnalyticContext } from '../../store/linkedinAnalytic';
import { formatNumber } from '../../Util/helper';
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount';
import BlogListCard from '../../Component/BlogListCard/BlogListCard';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount';
import { UserNavigationContext } from '../../store/NavigationProvider';
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent';
ChartJS.register(
    ...registerables
)

const LinkedinFollowerPage = () => {
    const accounts = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const linkedinContext = useContext(CreateLinkedinAnalyticContext)
    const [hoverActive,sethoverActive]=useState(false)

    const data = {
        labels: linkedinContext.followerDateArray.slice(1,linkedinContext.followerDateArray.length),
        datasets: [{
            label: 'Follower oraganic count',
            data: linkedinContext.followerOrganicData,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            lineTension: 0.5,
            borderColor: '#0078FF',
            pointBorderColor: hoverActive ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverActive ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor:'#ff43ca'
        },
        {
            label: 'Follower sponred',
            data: linkedinContext.followerPaidData,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(255, 117, 37,0.25)"
            },
            lineTension: 0.5,
            borderDash:[4,3],
            borderColor: '#ff8a25',
            pointBorderColor: hoverActive ? 'rgba(255, 117, 37, 1)' : 'rgba(255, 117, 37, 0)',
            pointBackgroundColor: hoverActive ? 'rgba(255, 117, 37, 0.5)' : 'rgba(255, 117, 37, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor:'#ff43ca'
        }
        ]
    };

    const barChartData = {
        labels: linkedinContext.followerCountry,
        datasets: [{
            label: 'Follower location',
            data: linkedinContext.followerCountryData,
            fill: false,
            backgroundColor: '#0078FF',

        },

        ]
    };

    const barChartDataForAllCountry = {
        labels: linkedinContext.allFollowerCountry,
        datasets: [{
            label: 'Follower location',
            data: linkedinContext.allFollowerCountryData,
            fill: false,
            backgroundColor: '#0078FF',
            tension: 0.1
        },

        ]
    };

    useEffect(() => {
        if (linkedinContext.followerPageFilterLoading) {
            linkedinContext.getLinkedinOverviewPageAnalyticaData()
        }
        // eslint-disable-next-line
    }, [linkedinContext.selectedPeriod])

    const options = {
        interaction: {
            intersect: false,
            mode: "x"
        },
        radius: hoverActive ? 5 :5,
        scales: {
            x: {
                type: 'category',
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false
                }
            },
        },
        plugins: {
            legend: {
                display: false, // Hide dataset label
            },
            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                position: 'nearest',
                // Additional tooltip configuration options
            },
        },
    };

    const calculateTotalFollowers = () => {
        const totaleasymcqquestion = linkedinContext.OrganizationalEntityFollowerStatstics?.map(followers => followers.followerCounts).reduce((easymcqquestion, question) => {

            easymcqquestion += parseInt(question.organicFollowerCount + question.paidFollowerCount)

            return easymcqquestion
        }, 0)


        return totaleasymcqquestion
    }

    const calculateNewFollower = () => {
        const totaleasymcqquestion = linkedinContext.organizationalEntityFollowerStatisticsFilter?.elements?.map(followers => followers.followerGains).reduce((easymcqquestion, question) => {

            easymcqquestion += question.organicFollowerGain

            return easymcqquestion
        }, 0)

        return totaleasymcqquestion
    }









    const getseniority = (senioritiesName) => {
        const senioritiesData = seniorities.filter(senioritiesItem => senioritiesItem.$URN === senioritiesName)
        return senioritiesData[0].name
    }



    

    const getDecisionMakerTotal=()=>{
        const filter = ['Owner', 'Director', 'CXO', 'VP', 'Partner']

        return linkedinContext.organizationAllFollower?.elements[0]?.followerCountsBySeniority?.filter(data => filter?.includes(getseniority(data.seniority))).reduce((totalDecisionMaker, data) => {
            return totalDecisionMaker += data.followerCounts.organicFollowerCount
        }, 0)
    }

    return (
        <div className={Styles.linkedin_follower_page_container} >
            {(accounts.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {linkedinContext.showAllLocationFollowerModel ? <AllFollowerLocationModel title="Followers Location" totalFollower={calculateTotalFollowers()} data={linkedinContext.OrganizationalEntityFollowerStatstics} onClickClose={() => linkedinContext.setshowAllLocationFollowerModel(false)} chart={barChartDataForAllCountry} /> : <></>}
            {linkedinContext.showAllJobFunction ? <AllFollowerLocationModel title="Job Function Followers" totalFollower={calculateTotalFollowers()} data={linkedinContext.organizationAllFollower.elements[0].followerCountsByFunction} onClickClose={() => linkedinContext.setshowAllJobFunction(false)} chart={barChartDataForAllCountry} /> : <></>}
            {linkedinContext.showAllIndustry ? <AllFollowerLocationModel title="Indusry Followers" totalFollower={calculateTotalFollowers()} data={linkedinContext.organizationAllFollower.elements[0].followerCountsByIndustry} onClickClose={() => linkedinContext.setshowAllIndustry(false)} chart={barChartDataForAllCountry} /> : <></>}
            {linkedinContext.overviewPageLoading ? <LinkedinFollowerPageSkeleton /> :
                <div className={Styles.follower_container} >
                    
                    <div style={(accounts.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.overview_content_main_container} >
                        <ActiveSocialAccount page="linkedinpage" disableFilter={true} loading={linkedinContext.overviewPageLoading} selected={linkedinContext.selectedPeriod} onChangePeriod={(value) => { linkedinContext.setSelectedPeriod(value);accounts.getSocialAccountPostCount(accounts.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0],value); linkedinContext.onChangeFollowerFilterLoading(true) }} />

                        <Navbar active="follower" />
                        <div className={Styles.overview_content_container} >
                            {calculateTotalFollowers() < 100 ? <BlogListCard type="linkedin" /> : <></>}
                            <div className={Styles.card_container} >
                                <OverviewCard toolTip="Total Followers on LinkedIn refers to the cumulative number of individuals who have chosen to follow your LinkedIn profile. This metric provides insight into the reach and influence of your professional presence on the platform." data={true} count={formatNumber(calculateTotalFollowers() || 0)} percentage={isFinite(((((calculateTotalFollowers() - calculateNewFollower()) - calculateTotalFollowers()) / calculateTotalFollowers()) * 100).toFixed(1)) ? ((((calculateTotalFollowers() - calculateNewFollower()) - calculateTotalFollowers()) / calculateTotalFollowers()) * 100).toFixed(1) : 0} header="Total Followers" />
                                <OverviewCard toolTip="Displays the numerical value of new followers acquired, arranged from the highest to the lowest count." people={true} count={formatNumber(calculateNewFollower() || 0)} percentage={isFinite(linkedinContext.previousDateFollowerPercentage) ? linkedinContext.previousDateFollowerPercentage : 0} header="New Followers" />
                                <OverviewCard toolTip="The total number of individuals or entities responsible for making significant choices or determining courses of action, listed from the most comprehensive description to the briefest summary." profile={true} count={formatNumber(getDecisionMakerTotal() || 0)} percentage="NA" header="Decision Makers" />
                                <OverviewCard toolTip="LinkedIn page that displays the top locations based on count, from longest to shortest" website={true} count={formatNumber(linkedinContext.followerCountryData[0] || 0)} percentage={0} header={`Top Location (${linkedinContext.followerCountry[0]})` } />
                            </div>
                            <div className={Styles.latest_pin_and_followers_container} >
                                <LineChartCustomComponent onHoverOut={()=>sethoverActive(false)} onHoverIn={()=>sethoverActive(true)} type="line" header="Followers" subheader="" contentArray={[{ "header": "Organic",type: "solid", indicator: "#0078FF", value: formatNumber(linkedinContext.followerOrganicData.reduce((totalOrganic, data) => { return totalOrganic += data }, 0) || 0) }, {
                                    "header": "Sponsored",type: "dashed", indicator: "#ff8a25", value: formatNumber(linkedinContext.followerPaidData.reduce((totalPaid, data) => { return totalPaid += data }, 0) || 0)
                                }, {
                                    "header": "Growth Rate", value: `${isFinite((((linkedinContext.organizationalEntityFollowerStatisticsFilter?.elements?.reduce((newFollower, followerData) => {
                                        newFollower += parseInt(followerData.followerGains.organicFollowerGain + followerData.followerGains.paidFollowerGain)
                                        return newFollower
                                    }, 0)) / calculateTotalFollowers()) * 100).toFixed(1)) ? (((linkedinContext.organizationalEntityFollowerStatisticsFilter?.elements?.reduce((newFollower, followerData) => {
                                        newFollower += parseInt(followerData.followerGains.organicFollowerGain + followerData.followerGains.paidFollowerGain)
                                        return newFollower
                                    }, 0)) / calculateTotalFollowers()) * 100).toFixed(1) : 0}%`
                                }]} options={options} chartData={data} />
                                <ProgressBarCustomComponent onClickShowAll={() => linkedinContext.setshowAllJobFunction(true)} header="Followers by Job Function" type="job function" subHeader="Show All" progressBarArray={linkedinContext.organizationAllFollower?.elements[0]?.followerCountsByFunction.slice(0, 7)} totalFollowers={calculateTotalFollowers()} />

                            </div>

                            <div className={Styles.followers_location_container} >
                                <LineChartCustomComponent onHoverOut={()=>null} onHoverIn={()=>null} onClickSubHeader={() => linkedinContext.setshowAllLocationFollowerModel(true)} type="bar" header="Followers Location" subheader="Show All" contentArray={[{ "header": "Major Country Followers", value: formatNumber(linkedinContext.followerCountryData[0] || 0) }, { "header": "Total Major Countries", value: formatNumber(linkedinContext.OrganizationalEntityFollowerStatstics?.length || 0) }, { "header": "Top 5 Countries Contribution", value: formatNumber(linkedinContext.followerCountryData.reduce((top5Follower, data) => { return top5Follower += data }, 0) || 0) }]} options={{
                                     maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: 0,
                                    scales: {
                                        x: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {

                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={barChartData} />

                                <ProgressBarCustomComponent onClickShowAll={() => linkedinContext.setshowAllIndustry(true)} header="Followers by Industry" type="industry" subHeader="Show All" progressBarArray={linkedinContext.organizationAllFollower?.elements[0]?.followerCountsByIndustry.slice(0, 7)} totalFollowers={calculateTotalFollowers()} />



                            </div>

                            <div style={{ marginBottom: 130 }} className={Styles.latest_pin_and_followers_container} >

                                <ProgressBarCustomComponent header="Followers by Company Size" type="company size" progressBarArray={linkedinContext.organizationAllFollower?.elements[0]?.followerCountsByStaffCountRange.slice(0, 9)} totalFollowers={calculateTotalFollowers()} />


                                <ProgressBarCustomComponent header="Followers by Seniority" type="seniority" progressBarArray={linkedinContext.organizationAllFollower?.elements[0]?.followerCountsBySeniority.slice(0, 9)} totalFollowers={calculateTotalFollowers()} />

                            </div>





                        </div>
                    </div>



                </div>
            }
        </div>
    )
}

export default LinkedinFollowerPage