import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Styles from './AllFollowerLocationModel.module.css'
import { country } from '../../Util/Country';
import { jobFunction } from '../../Util/jobFunction';
import { industry } from '../../Util/industry';
import { regions } from '../../Util/Region';

const AllFollowerLocationModel = (props) => {
    const getJobFunctionName = (functionName) => {
        const jobFunctionData = jobFunction.filter(jobFunctionItem => jobFunctionItem.$URN === functionName)
        return jobFunctionData[0]?.name === undefined ? "Other" : jobFunctionData[0].name
    }


    const getIndustryName = (industryName) => {
        const industryFunctionData = industry.filter(industryItem => industryItem.$URN === industryName)
        return industryFunctionData[0]?.name === undefined ? "Other" : industryFunctionData[0]?.name
    }



    const getCountryCodeFotFacebbok = (code) => {
        return country.filter(data => data.code2.toLocaleLowerCase() === code.toLocaleLowerCase()).map(data => data.name)[0]
    }

    const getLabelAndValue = (data) => {

        if (props.title === "Indusry Followers") {
            return `${getIndustryName(data?.industry)} - ${data?.followerCounts?.organicFollowerCount + data.followerCounts?.paidFollowerCount} (${(((data.followerCounts?.organicFollowerCount + data.followerCounts?.paidFollowerCount) * 100) / props?.totalFollower).toFixed(2)}) %`
        }
        if (props.title === "Job Function Followers") {
            return `${getJobFunctionName(data?.function)}} - ${data?.followerCounts?.organicFollowerCount + data.followerCounts?.paidFollowerCount} (${(((data.followerCounts?.organicFollowerCount + data.followerCounts?.paidFollowerCount) * 100) / props?.totalFollower).toFixed(2)}) %`
        }
        if (props.title === "Followers Location") {
            return props?.page === "instagram" ? `${getCountryCodeFotFacebbok(data?.country)} - ${data?.value} (${(((data?.value) * 100) / props?.totalFollower).toFixed(2)}) %` : `${country?.find(country => country?.name?.toLowerCase() === data?.geo?.elements[0]?.displayText?.toLowerCase())?.name} - ${data?.followerCounts?.organicFollowerCount + data?.followerCounts?.paidFollowerCount} (${(((data?.followerCounts?.organicFollowerCount + data?.followerCounts?.paidFollowerCount) * 100) / props?.totalFollower).toFixed(2)}) %`
        }

        if (props.title === "Top Language") {
            return `${data?.language} - (${data?.country}) - ${data?.value} - ${(((data?.value) / props?.totalFollower) * 100).toFixed(2)}%`
        }
        if (props.title === "Top cities") {
            return `${data?.country}  - ${data?.value} - ${(((data?.value) / props?.totalFollower) * 100).toFixed(2)}%`
        }

        if (props.title === "Likes Location" || props.title === "Followers Location") {
            return `${getCountryCodeFotFacebbok(data?.country)}}  - ${data?.value} - ${(((data?.value) / props?.totalFollower) * 100).toFixed(2)}%`
        }
    }

    return (
        <div className={Styles.all_follower_location_model} >
            <div className={Styles.container} >
                <div className={Styles.header} >
                    <span>{props.title}</span>
                    {/* Corss SVG Icon */}
                    <svg onClick={() => props.onClickClose()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg>

                </div>
                <div className={Styles.chart_container} >
                    {props.data.map((data, index) => {
                        return <div key={index} >
                            {props?.page === "instagram" ?
                                <>
                                    <span>{props.title === "Followers Location" && getCountryCodeFotFacebbok(data.country)}
                                        {props.title === "Top cities" && data.country}
                                        {props.title === "Top Language" && (`${data.language} (${data.country})`)}
                                        - <p>{data.value} ({((((data.value)) / props.totalFollower) * 100).toFixed(2)}%) </p> </span>

                                    <div data-tooltip-id="my-tooltip-location-model" data-tooltip-content={getLabelAndValue(data)} className={Styles.custom_meter_bar} >
                                        <div style={{ width: `${((((data.value)) / props.totalFollower) * 100).toFixed(2)}%` }} className={Styles.value_bar} ></div>
                                    </div>
                                </>
                                : props?.page === "facebook" ? <>
                                    <span>{props.title === "Likes Location" && getCountryCodeFotFacebbok(data.country)}
                                        {props.title === "Top cities" && data.country}
                                        {props.title === "Top Language" && (`${data.language} (${data.country})`)}
                                        - <p>{data.value} ({((((data.value)) / props.totalFollower) * 100).toFixed(2)}%) </p> </span>
                                    <div data-tooltip-id="my-tooltip-location-model" data-tooltip-content={getLabelAndValue(data)} className={Styles.custom_meter_bar} >
                                        <div style={{ width: `${((((data.value)) / props.totalFollower) * 100).toFixed(2)}%` }} className={Styles.value_bar} ></div>
                                    </div>
                                </> : <>
                                    <span>
                                        {props.title === "Visitors Location" && (regions.find(country => country.urn === data.region)?.name?.value === undefined ? "Other" :regions.find(country => country.urn === data.region)?.name?.value)}
                                        {props.title === "Followers Location" && country.find(country => country.name.toLowerCase() === data?.geo?.elements[0]?.displayText?.toLowerCase())?.name}
                                        {props.title === "Job Function Followers" && getJobFunctionName(data.function)}
                                        {props.title === "Indusry Followers" && getIndustryName(data.industry)}
                                        - <p>{data?.followerCounts?.organicFollowerCount + data?.followerCounts?.paidFollowerCount} ({((((data.followerCounts?.organicFollowerCount + data.followerCounts?.paidFollowerCount)) / props.totalFollower) * 100).toFixed(2)}%) </p> </span>
                                    <div data-tooltip-id="my-tooltip-location-model" data-tooltip-content={getLabelAndValue(data)} className={Styles.custom_meter_bar} >
                                        <div style={{ width: `${((((data?.followerCounts?.organicFollowerCount + data?.followerCounts?.paidFollowerCount)) / props.totalFollower) * 100).toFixed(2)}%` }} className={Styles.value_bar} ></div>
                                    </div>
                                </>
                            }

                        </div>
                    })
                    }

                </div>




            </div>
            <Tooltip id="my-tooltip-location-model" style={{ width: '10vw', borderRadius: 10, fontFamily: 'Poppins', fontSize: 10, fontStyle: 'normal', fontWeight: "normal", lineHeight: "normal", letterSpacing: 0.36, zIndex: 12, backgroundColor: 'rgba(53, 56, 57, 1)', color: '#ffffff' }} />
        </div>
    )
}

export default AllFollowerLocationModel