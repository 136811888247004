import { createContext, useContext, useState } from 'react'
import axios from 'axios'
import { backend_url, url } from '../Util/constant'
import { UserNavigationContext } from './NavigationProvider'
import {toast} from 'react-toastify'
import variables from '../Util/variables'

export const UserConnectedAccountContext = createContext({
    getInfo: () => { },
    userData: null,
    getAccounts: () => { },
    accounts: '',
    accountsLoading: '',
    handleUserAccount: () => { },
    setaccounts: () => { },
    searchResult: '',
    setsearchResult: () => { },
    accountConnectionLoading: '',
    handleAccountConnectionLoading: () => { },
    accountsData: '',
    handleaccountsData: () => { },
    changeaccountsData: () => { },
    getSocialAccountPostCount: () => { },
    socialAccountCount: '',
    searchFilter: '',
    changeSearchFilter: () => { },
    getAllPostOfGhost: () => { },
    facebookBlog: '',
    pinterestBlog: '',
    instagramBlog: '',
    linkedinBlog: '',
    updateUsersDataAccountLimit:()=>{ },
    handleUserData: () =>{ }
})

export const UserConnectedAccountProvider = ({ children }) => {
    const userNavigation = useContext(UserNavigationContext)
    const [accountsLoading, setaccountsLoading] = useState(true)
    const [userData, setuserData] = useState(null)
    const [accounts, setaccounts] = useState([])
    const [searchResult, setsearchResult] = useState([])
    const [accountConnectionLoading, setAccountConnectionLoading] = useState(false)
    const [accountsData, setaccountsData] = useState([])
    const [socialAccountCount, setsocialAccountCount] = useState({})
    const [searchFilter, setsearchFilter] = useState(false)
    const [facebookBlog, setfacebookBlog] = useState([])
    const [pinterestBlog, setpinterestBlog] = useState([])
    const [instagramBlog, setinstagramBlog] = useState([])
    const [linkedinBlog, setlinkedinBlog] = useState([])

    const handleUserData=(value)=>{
        setuserData(value)
    }

    const updateUsersDataAccountLimit = (value) => {
        let userDataCopy = userData
        userDataCopy.featureFactoryData.socialAccounts = value
        setuserData(userDataCopy)
    }



    const changeSearchFilter = (value) => {
        setsearchFilter(value)
    }

    const handleaccountsData = (value) => {
        setaccountsData((prev) => [...prev, value])
    }

    const handleAccountConnectionLoading = (value) => {
        setAccountConnectionLoading(value)
    }

    const changesearchResult = (value) => {
        setsearchResult(value)
    }

    const getAllPostOfGhost = async () => {
        try {
            const blog1 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65bf1ac5bdb7c7bc705c6f7b?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog2 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65af9c53bdb7c7bc705c6f17?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog3 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65af9007bdb7c7bc705c6eea?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog4 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65af4541bdb7c7bc705c6eca?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog5 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65aea79bbdb7c7bc705c6e8f?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog6 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65a98c85bdb7c7bc705c6ab5?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog7 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65a97325bdb7c7bc705c6a58?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog8 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65a96ea3bdb7c7bc705c69d3?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog9 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65a7fbf7bdb7c7bc705c68f8?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog10 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65a7a3acbdb7c7bc705c683b?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog11 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65a6ddd4bdb7c7bc705c67ec?key=7f2eea060c7f0bd6d9d87ab0bd`)
            const blog12 = await axios.get(`https://ahijftavqd.creatosaurus.io/ghost/api/content/posts/65a62f97bdb7c7bc705c67a6?key=7f2eea060c7f0bd6d9d87ab0bd`)

            setfacebookBlog([blog1.data.posts[0], blog2.data.posts[0], blog3.data.posts[0]])
            setinstagramBlog([blog4.data.posts[0], blog5.data.posts[0], blog6.data.posts[0]])
            setlinkedinBlog([blog7.data.posts[0], blog8.data.posts[0], blog9.data.posts[0]])
            setpinterestBlog([blog10.data.posts[0], blog11.data.posts[0], blog12.data.posts[0]])

        } catch (error) {
            
        }
    }

    const getAccounts = async () => {
        try {
            setaccountsLoading(true)
            const workspaceId = localStorage.getItem('organizationId')
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };

            let res = await axios.get(url + `accounts/${workspaceId}`, config)
            res.data = res.data.filter(data => data.accountType === "linkedinpage" || data.accountType === "instagram" || data.accountType === "facebookpage" || data.accountType === "pinterest")

            userNavigation.setactiveLeftSideSocialMedia(0)
            userNavigation.setactiveRightSideSocialMedia(res.data[0].accountType)
            userNavigation.changeSocialMediaName(res.data[0].name)
            variables.leftSideBarState = res?.data[0]?.socialId

            setaccounts(res.data)
            setaccountsLoading(false)
            getSocialAccountPostCount(res.data[0].socialId)
        } catch (error) {
            setaccountsLoading(false)
        }
    }

    const getSocialAccountPostCount = async (id,selectedPeriod = 30) => {
        try {
            
            const currentDate = new Date();
            const startDate = new Date(currentDate);
            startDate.setDate(currentDate.getDate() - selectedPeriod);

            const workspaceId = localStorage.getItem('organizationId')
            const token = localStorage.getItem('token')
            const socialId = id !== undefined ? id : (accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].socialId
            const res = await axios.get(`${url}accounts/analytic/getSocialAccountCount?workspaceId=${workspaceId}&socialId=${socialId}&since=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}&until=${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()+ 1}`, { headers: { "Authorization": `Bearer ${token}` } })
            setsocialAccountCount(res.data)


        } catch (error) {
            
        }
    }

    const changeAccounts = (value) => {
        setaccounts(value)
    }

    const handleUserAccount = (value) => {
        setaccounts(value)
    }

    const getInfo = async () => {
        try {
            if (userData !== null) return

            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const res = await axios.get(backend_url + '/user/info', config);

            setuserData(res.data)

            localStorage.setItem('organizationId', res.data.activeWorkspace._id);
            localStorage.setItem('organizationName', res.data.activeWorkspace.workspace_name);
            getAccounts()
        } catch (error) {
            return error
        }
    }

    const changeaccountsData = (value) => {
        setaccountsData(value)
    }

    const context = {
        userData: userData,
        getInfo: getInfo,
        getAccounts: getAccounts,
        accounts: accounts,
        accountsLoading: accountsLoading,
        handleUserAccount: handleUserAccount,
        setaccounts: changeAccounts,
        searchResult: searchResult,
        setsearchResult: changesearchResult,
        accountConnectionLoading: accountConnectionLoading,
        handleAccountConnectionLoading: handleAccountConnectionLoading,
        accountsData: accountsData,
        handleaccountsData: handleaccountsData,
        changeaccountsData: changeaccountsData,
        getSocialAccountPostCount: getSocialAccountPostCount,
        socialAccountCount: socialAccountCount,
        searchFilter: searchFilter,
        changeSearchFilter: changeSearchFilter,
        getAllPostOfGhost: getAllPostOfGhost,
        facebookBlog: facebookBlog,
        pinterestBlog: pinterestBlog,
        instagramBlog: instagramBlog,
        linkedinBlog: linkedinBlog,
        updateUsersDataAccountLimit: updateUsersDataAccountLimit,
        handleUserData: handleUserData
    }

    return (
        <UserConnectedAccountContext.Provider value={context} >{children}</UserConnectedAccountContext.Provider>
    )

}

