import { useContext, useState, useEffect } from 'react';
import { ContentPageSkeleton } from '../../Component/ContentPageSkeleton/ContentPageSkeleton';
import InstagramPostDetails from '../../Component/InstagramPostDetails/InstagramPostDetails';
import InstagramReelDetails from '../../Component/InstagramReelDetails/InstagramReelDetails';
import OverviewCard from '../../Component/OverviewCard/overviewcard';
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics';
import Styles from './FacebookContent.module.css'
import FacebookContentTab from '../../Component/FacebookContentTab/FacebookContentTab';
import FacebookNavbar from '../../Component/FacebookNavbar/FacebookNavbar';
import { formatNumber } from '../../Util/helper';
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup';
import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount';
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount';
import BlogListCard from '../../Component/BlogListCard/BlogListCard';
import ActiveSocialAccountSkeleton from '../../Component/ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton';
import { UserNavigationContext } from '../../store/NavigationProvider';
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent';
import { generateDistinctColors } from '../../Util/constant';
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent';
export const FacebookContent = () => {
    const facebookContent = useContext(CreateFacebookAnalyticContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [activeTab, setactiveTab] = useState('feed')
    const [showPostDetails, setshowPostDetails] = useState(false)
    const [showReelDetails, setshowReelDetails] = useState(false)
    const [selectedPostDetails, setselectedPostDetails] = useState('')
    const [selectedReelDetails, setselectedReelDetails] = useState('')
    const [hoverTagInPost, sethoverTagInPost] = useState(false)
    const [hoverCommentGraph, sethoverCommentGraph] = useState(false)
    const [selectedCategory, setselectedCategory] = useState('')
    const [page, setpage] = useState(1)
    const limit = 5


    const captionTruncate = (str) => {
        return str?.length > 40 ? str?.substring(0, 40) + "..." : str;
    }

    useEffect(() => {
        if (facebookContent.contentPageFilterLoading) {
            facebookContent.getFacebookContentPageAnalyticaData()
            facebookContent.getFacebookOverviewPageAnalyticaData()
        }
        // eslint-disable-next-line
    }, [facebookContent.selectedPeriod])



    const getHeaderCard1 = (tab) => {
        if (tab === "feed")
            return "Total Post"

        if (tab === "video")
            return "Total Video"

        if (tab === "story")
            return "Total Story"
    }

    const getToolTipCard1 = (tab) => {
        if (tab === "feed")
            return "The total number of feed posts you've made in the selected time-frame."

        if (tab === "video")
            return "Your canvas for 30-second visual storytelling on Instagram. Explore, create, and share engaging clips tailored to your interests. Join a global community of creators and start your reel journey today"

        if (tab === "story")
            return "Total Story Facebook: A comprehensive analytics tool offering in-depth insights into your Facebook content performance. Track engagement metrics, audience demographics, and post effectiveness with intuitive visualizations, empowering you to optimize your social media strategy and maximize impact."
    }

    const getHeaderCard2 = (tab) => {
        if (tab === "feed")
            return "Average Eng."

        if (tab === "video")
            return "Average Eng."

        if (tab === "story")
            return "Average Views"
    }

    const getToolTipCard2 = (tab) => {
        if (tab === "feed")
            return "Average Engagement on Instagram measures the quality of interaction your content receives, factoring in likes, comments, shares, and saves per post, normalized by follower count. Higher averages indicate more meaningful engagement, vital for fostering a loyal community and boosting visibility"

        if (tab === "video")
            return "Average Engagement on Instagram measures the quality of interaction your content receives, factoring in likes, comments, shares, and saves per post, normalized by follower count. Higher averages indicate more meaningful engagement, vital for fostering a loyal community and boosting visibility"

        if (tab === "story")
            return "Average Views on Facebook: The average number of times content from your Facebook page or profile has been viewed by users over a specific period. This metric provides insights into the overall visibility and engagement level of your posts, helping you gauge audience interest and reach on the platform."
    }

    const getHeaderCard4 = (tab) => {
        if (tab === "feed")
            return "Total Saved"

        if (tab === "video")
            return "Average Time In Min."

        if (tab === "story")
            return "Profile Activity"
    }

    const getToolTipCard4 = (tab) => {
        if (tab === "feed")
            return "Save posts you love for easy access later. View your collection anytime, anywhere."

        if (tab === "video")
            return "Save and organize posts you love for easy access later. Keep all your discoveries in one place, accessible from your profile anytime, anywhere."

        if (tab === "story")
            return "Profile Activity: Stay connected and informed! View updates, posts, and interactions on your Facebook profile, including likes, comments, and shares from friends and groups. Monitor your online presence and engage with your network effortlessly."
    }

    const getTotalNoOfHeader = (tab) => {
        if (tab === "feed")
            return formatNumber(facebookContent.allPosts.length)

        if (tab === "video")
            return formatNumber(facebookContent.allVideos.length)


    }

    const getTotalNoOfHeaderValueCard2 = (tab) => {
        if (tab === "feed")
            return `${isNaN(parseFloat(facebookContent.allPosts.reduce((totalEngagement, data) => { return totalEngagement += data.statistics.filter(data => data.name === "post_engaged_users").map(data => data.values[0].value)[0] }, 0) / facebookContent.allPosts.length).toFixed(2)) ? 0 : `${parseFloat(facebookContent.allPosts.reduce((totalEngagement, data) => { return totalEngagement += data.statistics.filter(data => data.name === "post_engaged_users").map(data => data.values[0].value)[0] }, 0) / facebookContent.allPosts.length).toFixed(2)}%`}`

        if (tab === "video")
            return isNaN((facebookContent.allVideos.reduce((totalVideoInteraction, data) => {
                return totalVideoInteraction += parseFloat(isFinite((((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? formatNumber(data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1))
                    ? `${(((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? formatNumber(data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1)}` : 0)
            }, 0)) / facebookContent.allVideos.length) ? 0 + "%" : (facebookContent.allVideos.reduce((totalVideoInteraction, data) => {
                return totalVideoInteraction += parseFloat(isFinite((((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? formatNumber(data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1))
                    ? `${(((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? formatNumber(data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1)}` : 0)
            }, 0)) / facebookContent.allVideos.length + "%"


    }

    const getTotalNoOfHeaderValueCard4 = (tab) => {
        if (tab === "feed")
            return formatNumber(facebookContent.allPosts.filter(data => data.media_product_type === 'FEED').reduce((totalEngagement, data) => { return totalEngagement += facebookContent.allPostInsights.filter(allPosts => allPosts.id === data.id).map(allPosts => allPosts.res)[0].data.filter(allPosts => allPosts.name === "saved").map(allPosts => allPosts.values[0].value)[0] }, 0))

        if (tab === "video")
            return parseFloat(facebookContent.allVideos.reduce((totalVideoInteraction, data) => { return totalVideoInteraction += data?.statistics?.filter(data => data.name === "total_video_avg_time_watched").length ? (data?.statistics?.filter(data => data.name === "total_video_avg_time_watched")?.map(data => data.values[0].value)[0] / 100000) : 0 }, 0)).toFixed(2)


    }

    const getHeaderCard3 = (tab) => {
        if (tab === "feed")
            return "Average Impression"

        if (tab === "video")
            return "Average 10s Views"



    }

    const getTooltipCard3 = (tab) => {
        if (tab === "feed")
            return "Total views of your content, including repeat views, providing insights into visibility and engagement"

        if (tab === "video")
            return "Average 10s Views on Facebook: This metric represents the average number of views lasting at least 10 seconds that your content receives on Facebook"


    }

    const getHeaderValueCard3 = (tab) => {
        if (tab === "feed")
            return `${isNaN(parseFloat(facebookContent.allPosts.reduce((totalEngagement, data) => { return totalEngagement += data.statistics.filter(data => data.name === "post_impressions").map(data => data.values[0].value)[0] }, 0) / facebookContent.allPosts.length).toFixed(2)) ? 0 : `${parseFloat(facebookContent.allPosts.reduce((totalEngagement, data) => { return totalEngagement += data.statistics.filter(data => data.name === "post_impressions").map(data => data.values[0].value)[0] }, 0) / facebookContent.allPosts.length).toFixed(2)}`}`

        if (tab === "video")

            return formatNumber(facebookContent.allVideos.reduce((totalVideoInteraction, data) => { return totalVideoInteraction += data?.statistics?.filter(data => data.name === "total_video_10s_views").length ? data?.statistics?.filter(data => data.name === "total_video_10s_views")?.map(data => data.values[0].value)[0] : 0 }, 0))


    }

    const getVideoEngRate = (data) => {
        return isFinite((((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? formatNumber(data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1))
            ? `${(((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? formatNumber(data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1)}%` : "0%"
    }

    const pieChartDataAge = {
        // eslint-disable-next-line
        labels: [].concat(...facebookContent.stackedDataContent.map(data => {
            const postId = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
            // eslint-disable-next-line
            const commonElements = postId.map(item => {
                if (data.postId?.includes(item)) {
                    return data
                }
            }).filter(item => item !== undefined);
            return commonElements
        }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => data.contentCategory),
        datasets: [{
            label: ' Engagement',
            data: [].concat(...facebookContent.stackedDataContent.map(data => {
                const postId = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {

                const likeCount = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId.includes(postDetails.id)).map(data => activeTab === 'feed' ? data?.likes?.summary?.total_count || 0 : data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like || 0) : 0) : 0)
                const commentCount = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId.includes(postDetails.id)).map(data => data?.comments?.summary?.total_count)
                return (isFinite(likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) ? (likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0) + commentCount.reduce((totalComment, comment) => { return totalComment += parseInt(comment) }, 0) / data.postId.length) : 0)
            }),
            fill: false,
            backgroundColor: [].concat(...facebookContent.stackedDataContent.map(data => {
                const postId = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                return generateDistinctColors(1)[0]
            }),
        }
        ]
    };

    const pieChartDataLike = {
        labels: [].concat(...facebookContent.stackedDataContent.map(data => {
            const postId = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
            // eslint-disable-next-line
            const commonElements = postId.map(item => {
                if (data.postId.includes(item)) {
                    return data
                }
            }).filter(item => item !== undefined);
            return commonElements
        }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => data.contentCategory),
        datasets: [{
            label: 'Engagement',
            data: [].concat(...facebookContent.stackedDataContent.map(data => {
                const postId = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                const likeCount = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos)?.filter(postDetails => data.postId.includes(postDetails.id)).map(data => activeTab === 'feed' ? data?.likes?.summary?.total_count || 0 : data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like || 0) : 0) : 0)

                return (isFinite(likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)) ? (likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)) : 0)
            }),
            fill: false,
            backgroundColor: [].concat(...facebookContent.stackedDataContent.map(data => {
                const postId = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                return generateDistinctColors(1)[0]
            }),
        }
        ]
    };

    const dataForEngagement = {
        labels: facebookContent.dateArray,
        datasets: [{
            label: 'Engagement',
            data: facebookContent.dateArray.map(data => {
                const filteredMedia = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.created_time)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`);
                });
                let totalEng;
                if (activeTab === "feed") {
                    totalEng = filteredMedia.reduce((totalEng, data) => { return totalEng += parseFloat(data.statistics.filter(data => data.name === "post_engaged_users").map(data => data.values[0].value)[0] !== undefined ? data.statistics.filter(data => data.name === "post_engaged_users").map(data => data.values[0].value)[0] : 0) }, 0)
                } else {
                    totalEng = filteredMedia.reduce((totalEng, data) => { return totalEng += isFinite(parseFloat((((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? (data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? (data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1))) ? parseFloat((((parseInt(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? (data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like) : 0) : 0) + parseInt(data?.comments?.summary?.total_count !== undefined ? (data?.comments?.summary?.total_count) : 0)) / (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? (data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0]) : 0)) * 100).toFixed(1)) : 0 }, 0)
                }
                return totalEng
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverTagInPost ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverTagInPost ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const dataForImpression = {
        labels: facebookContent.dateArray,
        datasets: [{
            label: 'Impressions',
            data: facebookContent.dateArray.map(data => {
                const filteredMedia = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(item => {
                    // Extracting the date from the eventcreatedAt timestamp
                    const eventDate = new Date(item.created_time)
                    const monthShortName = eventDate.toLocaleString('default', { month: 'short' });
                    const day = String(eventDate.getDate()).padStart(2, '0');
                    // Check if the eventDate matches any date in the dates array
                    return data.includes(`${monthShortName}-${day}`);
                });
                let totalEng;
                if (activeTab === "feed") {
                    totalEng = filteredMedia.reduce((totalEng, data) => { return totalEng += data.statistics.filter(data => data.name === "post_impressions").map(data => data.values[0].value)[0] !== undefined ? data.statistics.filter(data => data.name === "post_impressions").map(data => data.values[0].value)[0] : 0 }, 0)
                } else {
                    totalEng = filteredMedia.reduce((totalEng, data) => { return totalEng += data?.statistics?.filter(data => data.name === "total_video_impressions")?.map(data => data.values[0].value)[0] !== undefined ? data?.statistics?.filter(data => data.name === "total_video_impressions")?.map(data => data.values[0].value)[0] : 0 }, 0)
                }
                return totalEng
            }),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverTagInPost ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverTagInPost ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    function getTopCategoryCounts(data) {
        const categoryCounts = {};

        // Iterate over each array
        data.forEach(array => {
            // Iterate over each object in the array
            array.forEach(item => {
                // Get the socialId and contentCategory
                const socialId = item.socialId;
                const category = item.contentCategory;

                // If the socialId doesn't exist in categoryCounts, initialize it
                if (!categoryCounts[socialId]) {
                    categoryCounts[socialId] = {};
                }

                // Increment the count for the category under the socialId
                if (!categoryCounts[socialId][category]) {
                    categoryCounts[socialId][category] = 1;
                } else {
                    categoryCounts[socialId][category]++;
                }
            });
        });

        // Convert the object to an array of objects
        const result = Object.entries(categoryCounts).map(([socialId, categories]) => {
            // Find the category with the highest count
            const topCategory = Object.entries(categories).reduce((acc, [category, count]) => {
                return count > acc.count ? { category, count } : acc;
            }, { category: null, count: -1 });

            return { socialId, topCategory };
        });

        return result;
    }


    const socialIds = facebookContent.stackedDataContent?.reduce((ids, item) => {
        if (!ids.includes(item.socialId)) {
            ids.push(item.socialId);
        }
        return ids;
    }, []);


    const categories = facebookContent.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.reduce((uniqueCategories, item) => {
        if (!uniqueCategories.includes(item.contentCategory)) {
            uniqueCategories.push(item.contentCategory);
        }
        return uniqueCategories;
    }, []);

    const datasets = socialIds.map((socialId) => {
        const color = generateDistinctColors(1)[0]

        return {
            label: (userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name,
            data: categories.map(category => {

                return facebookContent.stackedDataContent?.filter(item => item.socialId === socialId && item.contentCategory === category)?.length;
            }),
            backgroundColor: color,
        };
    });



    const dataDonghnut = {
        labels: facebookContent.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i)?.map(item => item.contentCategory),
        datasets: datasets,
    }

    const prevPage = () => {
        if (page > 1) {
            setpage(prev => prev - 1)
        }
    }

    const nextPage = () => {
        if (page < Math.ceil(Math.ceil(facebookContent.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? facebookContent?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
            const postDetails = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId.includes(postDetails.id))
            return postDetails.length
        })[0] / limit))) {
            setpage(prev => prev + 1)
        }
    }

    return (
        <div className={Styles.facebook_page_content_continer} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {showPostDetails ? <InstagramPostDetails type="facebook" data={selectedPostDetails} onClickClose={() => setshowPostDetails(false)} /> : <></>}
            {showReelDetails ? <InstagramReelDetails type="facebook" data={selectedReelDetails} onClickClose={() => setshowReelDetails(false)} /> : <></>}
            <div className={Styles.content_container} >

                <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.instagram_content_main_container} >
                    {facebookContent.contentPageLoading ? <ActiveSocialAccountSkeleton selected={facebookContent.selectedPeriod} /> : <ActiveSocialAccount page="facebookpage" loading={facebookContent.contentPageLoading} selected={facebookContent.selectedPeriod === 30 ? (facebookContent.selectedPeriod + 1) : facebookContent.selectedPeriod} onChangePeriod={(value) => { facebookContent.handleSelectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "facebookpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); facebookContent.onChangecontentPageFilterLoading(true) }} />}
                    <FacebookNavbar active="content" />
                    <FacebookContentTab loading={facebookContent.contentPageLoading} activeTab={activeTab} onchangeTab={(value) => setactiveTab(value)} totalPost={{ post: facebookContent?.allPosts?.length, video: facebookContent?.allVideos?.length }} />
                    {facebookContent.contentPageLoading ? <ContentPageSkeleton activeTab={activeTab} /> :
                        <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.instagram_content_container} >

                            {facebookContent.overviewStats?.likes < 100 && facebookContent.contentPageLoading === false ? <BlogListCard type="facebook" /> : <></>}
                            <div className={Styles.card_container} >
                                <OverviewCard toolTip={getToolTipCard1(activeTab)} story={true} count={getTotalNoOfHeader(activeTab)} percentage={0} header={getHeaderCard1(activeTab)} />
                                <OverviewCard toolTip={getToolTipCard2(activeTab)} impression={true} count={getTotalNoOfHeaderValueCard2(activeTab)} percentage={0} header={getHeaderCard2(activeTab)} />
                                <OverviewCard toolTip={getTooltipCard3(activeTab)} website={true} count={getHeaderValueCard3(activeTab)} percentage={0} header={getHeaderCard3(activeTab)} />
                                <OverviewCard toolTip={getToolTipCard4(activeTab)} slides={true} count={getTotalNoOfHeaderValueCard4(activeTab)} percentage={0} header={getHeaderCard4(activeTab)} />
                            </div>

                            {(activeTab === 'feed' || activeTab === 'video') ?
                                <div className={Styles.engagement_and_impression_container}>
                                    <LineChartCustomComponent onHoverOut={() => sethoverTagInPost(false)} onHoverIn={() => sethoverTagInPost(true)} type="line" header="Engagement" subheader="" contentArray={[]} options={{
                                        maintainAspectRatio: false,
                                        interaction: {
                                            intersect: false,
                                            mode: "x"
                                        },
                                        radius: hoverTagInPost ? 5 : 5,
                                        scales: {
                                            x: {
                                                type: 'category',
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false, // Hide dataset label
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        },
                                    }} chartData={dataForEngagement} />

                                    <LineChartCustomComponent onHoverOut={() => sethoverTagInPost(false)} onHoverIn={() => sethoverTagInPost(true)} type="line" header="Impression" subheader="" contentArray={[]} options={{
                                        maintainAspectRatio: false,
                                        interaction: {
                                            intersect: false,
                                            mode: "x"
                                        },
                                        radius: hoverTagInPost ? 5 : 5,
                                        scales: {
                                            x: {
                                                type: 'category',
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false, // Hide dataset label
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        },
                                    }} chartData={dataForImpression} />
                                </div>
                                :
                                <></>

                            }
                            {activeTab === 'feed' &&
                                <div className={Styles.post_main_container} >
                                    <div className={Styles.header_container} >
                                        <span>Post Activity</span>

                                    </div>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                        {facebookContent?.allPosts?.length ?
                                            <>
                                                <div className={Styles.post_container} >
                                                    <div className={Styles.header_container} >
                                                        <span>Post</span>
                                                        <div className={Styles.postHeaderContainer} >
                                                            <span>Eng Rate</span>


                                                            <span>Impressions</span>
                                                            <span>Likes</span>
                                                            <span>Comments</span>

                                                            <span>View</span>
                                                        </div>
                                                    </div>
                                                    <div className={Styles.border} ></div>
                                                    {facebookContent?.allPosts?.map((data, index) => {

                                                        return (
                                                            <div key={index} style={{ width: '100%' }} >
                                                                <div style={{ cursor: 'pointer' }} onClick={() => { setshowPostDetails(true); setselectedPostDetails(data) }} className={Styles.post_item_container} >
                                                                    <div className={Styles.post_content_container} >

                                                                        <div className={Styles.post_content_img} >
                                                                            <img alt="" src={data.full_picture} />
                                                                        </div>

                                                                        <div className={Styles.post_content} >
                                                                            <span>{captionTruncate(data?.message !== undefined ? captionTruncate(data?.message) : "This content has no caption")}</span>
                                                                            <p>{new Date(data.created_time).getDate() + "/" + (new Date(data.created_time).getMonth() + 1) + "/" + new Date(data.created_time).getFullYear()} {new Date(data?.created_time).toLocaleTimeString('en-US')}</p>
                                                                        </div>


                                                                    </div>
                                                                    <div className={Styles.postOtherDetails} >
                                                                        <div>{formatNumber(data.statistics.filter(data => data.name === "post_engaged_users").map(data => data.values[0].value)[0] || 0)}%</div>




                                                                        <div>{formatNumber(data.statistics.filter(data => data.name === "post_impressions").map(data => data.values[0].value)[0] || 0)}</div>

                                                                        <div>{formatNumber(data.likes.summary.total_count || 0)}</div>
                                                                        <div>{formatNumber(data.comments.summary.total_count || 0)}</div>

                                                                        <div style={{ cursor: 'pointer' }} > <svg onClick={(e) => { e.stopPropagation(); window.open(`${data.attachments.data[0].url}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_2171_2043)">
                                                                                <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_2171_2043">
                                                                                    <rect width="22" height="22" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg> </div>
                                                                    </div>
                                                                </div>
                                                                <section className={Styles.border_of_facebook_feed} ></section>
                                                            </div>
                                                        )
                                                    })

                                                    }

                                                </div>
                                            </>
                                            :
                                            <NoDataAvailablePopup noOfPost={facebookContent?.allPosts?.length} selectedPeriod={
                                                // eslint-disable-next-line
                                                facebookContent.selectedPeriod == 31 ? 30 : facebookContent.selectedPeriod} cardName="postlist" />

                                        }
                                    </> : <PremiumPopup cardName="postlist" />

                                    }


                                </div>
                            }


                            {activeTab === "video" &&
                                <div className={Styles.post_main_container} >
                                    <div className={Styles.header_container} >
                                        <span>Post Activity</span>

                                    </div>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                        {facebookContent?.allVideos?.length ?
                                            <>
                                                <div className={Styles.post_container} >
                                                    <div className={Styles.header_container} >
                                                        <span>Post</span>
                                                        <div className={Styles.postHeaderContainer} >
                                                            <span>Eng. Rate</span>
                                                            <span>10s Views</span>

                                                            <span>Reach</span>
                                                            <span>Impressions</span>
                                                            <span>Likes</span>
                                                            <span>Comments</span>
                                                            <span>Average time(Min.)</span>
                                                            <span>View</span>
                                                        </div>
                                                    </div>
                                                    <div className={Styles.border} ></div>
                                                    {facebookContent?.allVideos?.map((data, index) => {

                                                        return (
                                                            <div key={index} style={{ width: '100%' }}>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => { setshowReelDetails(true); setselectedReelDetails(data) }} className={Styles.post_item_container} >
                                                                    <div className={Styles.post_content_container} >

                                                                        <div className={Styles.post_content_img} >
                                                                            <img alt="" src={data?.thumbnails[0]?.uri} />
                                                                        </div>

                                                                        <div className={Styles.post_content} >
                                                                            <span>{data?.message !== undefined ? captionTruncate(data?.message) : "This content has no caption"}</span>
                                                                            <p>{new Date(data?.created_time)?.getDate() + "/" + (new Date(data?.created_time)?.getMonth() + 1) + "/" + new Date(data?.created_time)?.getFullYear()} {new Date(data?.created_time).toLocaleTimeString('en-US')}</p>
                                                                        </div>


                                                                    </div>
                                                                    <div className={Styles.postOtherDetails} >
                                                                        <div>{getVideoEngRate(data)}</div>
                                                                        <div>{data.statistics.filter(data => data.name === "total_video_10s_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data.statistics.filter(data => data.name === "total_video_10s_views")?.map(data => data.values[0].value)[0] || 0) : 0}</div>


                                                                        <div>{data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_views")?.map(data => data.values[0].value)[0] || 0) : 0}</div>

                                                                        <div>{data?.statistics?.filter(data => data.name === "total_video_impressions")?.map(data => data.values[0].value)[0] !== undefined ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_impressions")?.map(data => data.values[0].value)[0] || 0) : 0}</div>

                                                                        <div>{data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like || 0) : 0) : 0}</div>
                                                                        <div>{data?.comments?.summary?.total_count !== undefined ? formatNumber(data?.comments?.summary?.total_count) : 0}</div>

                                                                        <div>{data?.statistics?.filter(data => data.name === "total_video_avg_time_watched")?.map(data => data.values[0].value)[0] !== undefined ? `${(data?.statistics?.filter(data => data.name === "total_video_avg_time_watched")?.map(data => data.values[0].value)[0] / 100000).toFixed(2)}` : 0} </div>
                                                                        <div style={{ cursor: 'pointer' }} > <svg onClick={(e) => { e.stopPropagation(); window.open(`https://www.facebook.com/reel/${data.id}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_2171_2043)">
                                                                                <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_2171_2043">
                                                                                    <rect width="22" height="22" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg> </div>
                                                                    </div>
                                                                </div>
                                                                <section className={Styles.border_of_facebook_feed} ></section>
                                                            </div>
                                                        )
                                                    })

                                                    }

                                                </div>
                                            </> : <NoDataAvailablePopup noOfPost={facebookContent?.allVideos?.length} selectedPeriod={
                                                // eslint-disable-next-line
                                                facebookContent.selectedPeriod == 31 ? 30 : facebookContent.selectedPeriod} cardName="postlist" />

                                        }
                                    </> : <PremiumPopup cardName="postlist" />

                                    }


                                </div>
                            }

                            {activeTab === 'video' || activeTab === "feed" ?
                                <>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                        <div className={Styles.content_category_container} >
                                            <h4>Category</h4>
                                            {(activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos)?.length ?
                                                <>

                                                    <div className={Styles.header_container} >
                                                        <span>category</span>
                                                        <span>likes</span>
                                                        <span>comments</span>
                                                    </div>

                                                    {[].concat(...facebookContent.stackedDataContent.map(data => {
                                                        const postId = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos).map(postData => postData.id)
                                                        // eslint-disable-next-line
                                                        const commonElements = postId.map(item => {
                                                            if (data?.postId?.includes(item)) {
                                                                return data
                                                            }
                                                        }).filter(item => item !== undefined);
                                                        return commonElements
                                                    }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                                                        const likeCount = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId?.includes(postDetails.id)).map(data => activeTab === 'feed' ? data?.likes?.summary?.total_count || 0 : data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like || 0) : 0) : 0)
                                                        const commentCount = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId?.includes(postDetails.id)).map(data => data?.comments?.summary?.total_count || 0)

                                                        return (
                                                            <div className={Styles.category_content} >
                                                                <span>{data.contentCategory}</span>
                                                                <span>{likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)}</span>
                                                                <span>{commentCount.reduce((totalComment, comments) => { return totalComment += comments }, 0)}</span>
                                                            </div>
                                                        )
                                                    })

                                                    }

                                                </> :

                                                <NoDataAvailablePopup noOfPost={(activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos)?.length} selectedPeriod={
                                                    // eslint-fae-next-line
                                                    facebookContent.selectedPeriod == 31 ? 30 : facebookContent.selectedPeriod} cardName="postlist" />


                                            }
                                        </div> : <PremiumPopup cardName="postlist" />

                                    }

                                </> : <></>

                            }


                            {activeTab === 'video' || activeTab === 'feed' ?
                                <>
                                    {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                        <div className={Styles.competitor_category_content_container} >
                                            <LineChartCustomComponent componentName="category" style={{ width: '48%', height: '90%' }} header={`Category concentric graph (last 30 days)`} contentArray={getTopCategoryCounts(facebookContent.competitors?.filter(data => socialIds.includes(data.id))?.map(competitorData => {
                                                const categoriesData = facebookContent.stackedDataContent.filter(categories => categories.socialId === competitorData.id)
                                                return categoriesData
                                            }))?.map((data, index) => {
                                                // const name = instagramContext.competitors.find(competitor => competitor.id === data.socialId).name
                                                const color = dataDonghnut.datasets[index].backgroundColor
                                                return {
                                                    "header": `${facebookContent.competitors.find(competitor => competitor.id === data.socialId).name} ( ${data.topCategory.category} )`, indicator: color, type: 'solid', "value": data.topCategory.count
                                                }
                                            })} onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(false)} type="pie" options={{
                                                maintainAspectRatio: false,
                                                onClick: function (e, i) {
                                                    setpage(1)
                                                    setselectedCategory(dataDonghnut.labels[i[0].index])
                                                },
                                                scales: {
                                                    x: {
                                                        display: false,
                                                        grid: {
                                                            display: false
                                                        }
                                                    },
                                                    y: {
                                                        display: false,
                                                        grid: {
                                                            display: false
                                                        }
                                                    },
                                                },
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                        position: 'nearest',
                                                        // Additional tooltip configuration options
                                                    },
                                                }
                                            }} chartData={dataDonghnut} />
                                            {
                                                facebookContent.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? facebookContent?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                    const postDetails = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId.includes(postDetails.id))
                                                    return postDetails.length
                                                })[0] > 0 ? <div className={Styles.category_content_container} >
                                                    <div>
                                                        <label>{(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.name}{selectedCategory === '' ? facebookContent.stackedDataContent[0].contentCategory : selectedCategory}Post</label>
                                                        {facebookContent.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? facebookContent?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                            const postDetails = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId.includes(postDetails.id))
                                                            const renderPost = postDetails.slice((page - 1) * 5, (page - 1) * 5 + 5).map((postData, index) => {
                                                                return (
                                                                    <div className={Styles.single_content_container} >
                                                                        <div className={Styles.postDetailsContainer} >
                                                                            <p className={Styles.index_header} >{index + 1}</p>
                                                                            <img alt="media" src={activeTab === "feed" ? postData?.full_picture : postData?.thumbnails[0]?.uri} />
                                                                            <div className={Styles.post_content_container} >
                                                                                <h5>{captionTruncate(postData?.message === undefined ? "-":postData?.message, 15)}</h5>
                                                                                {activeTab === "feed" ? <p>{formatNumber(postData.likes.summary.total_count || 0)} Likes {formatNumber(postData.comments.summary.total_count || 0)} Comments</p>:<p>{postData?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(postData?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(postData?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like || 0) : 0) : 0} Likes {postData?.comments?.summary?.total_count !== undefined ? formatNumber(postData?.comments?.summary?.total_count) : 0} Comments</p>}
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`${postData.permalink_url}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_2171_2043)">
                                                                                <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_2171_2043">
                                                                                    <rect width="22" height="22" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </div>
                                                                )
                                                            })
                                                            return renderPost
                                                        })
                                                        }

                                                    </div>
                                                    <div className={Styles.post_pagination_container} >
                                                        <svg onClick={prevPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.375 3.75L5.625 7.5L9.375 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span>Showing {page}-{Math.ceil(facebookContent.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? facebookContent?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                            const postDetails = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId.includes(postDetails.id))
                                                            return postDetails.length
                                                        })[0] / limit)} posts</span>
                                                        <svg onClick={nextPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.625 3.75L9.375 7.5L5.625 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                    </div>
                                                </div> : <NoDataAvailablePopup noOfPost={facebookContent.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? facebookContent?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                    const postDetails = (activeTab === "feed" ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId.includes(postDetails.id))
                                                    return postDetails.length
                                                })[0]} selectedPeriod={facebookContent.selectedPeriod == 31 ? 30 : facebookContent.selectedPeriod} cardName="postlist" />


                                            }

                                        </div> : <PremiumPopup cardName="postlist" />
                                    }
                                </>
                                : <></>

                            }

                            {activeTab === 'video' || activeTab === "feed" ?
                                <div className={Styles.category_pie_chart_container} >
                                    <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Category Like" contentArray={[].concat(...facebookContent.stackedDataContent.map(data => {
                                        const postId = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
                                        // eslint-disable-next-line
                                        const commonElements = postId.map(item => {
                                            if (data.postId?.includes(item)) {
                                                return data
                                            }
                                        }).filter(item => item !== undefined);
                                        return commonElements
                                    }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map((data, index) => {

                                        const likeCount = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos)?.map(data => activeTab === 'feed' ? data?.likes?.summary?.total_count || 0 : data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like || 0) : 0) : 0)

                                        return { "header": data.contentCategory, type: "solid", indicator: pieChartDataLike.datasets[0].backgroundColor[index], value: `${(isFinite(likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) ? ((likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0))) : 0)}` }
                                    })} options={{
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        }
                                    }} chartData={pieChartDataLike} />

                                    <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Category Engagement Rate" contentArray={[].concat(...facebookContent.stackedDataContent.map(data => {
                                        const postId = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos)?.map(postData => postData.id)
                                        // eslint-disable-next-line
                                        const commonElements = postId.map(item => {
                                            if (data.postId?.includes(item)) {
                                                return data
                                            }
                                        }).filter(item => item !== undefined);
                                        return commonElements
                                    }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map((data, index) => {
                                        const likeCount = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId?.includes(postDetails.id)).map(data => activeTab === 'feed' ? data?.likes?.summary?.total_count || 0 : data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0] !== undefined ? (Object.keys(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]).length ? formatNumber(data?.statistics?.filter(data => data.name === "total_video_reactions_by_type_total")?.map(data => data.values[0].value)[0]?.like || 0) : 0) : 0)
                                        const commentCount = (activeTab === 'feed' ? facebookContent.allPosts : facebookContent.allVideos).filter(postDetails => data.postId?.includes(postDetails.id)).map(data => data?.comments?.summary?.total_count || 0)
                                        return { "header": data.contentCategory, type: "solid", indicator: pieChartDataAge.datasets[0].backgroundColor[index], value: `${(isFinite(likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)) ? (((likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0) + commentCount.reduce((totalComment, comment) => { return totalComment += parseInt(comment) }, 0)))) : 0)}` }
                                    })} options={{
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        }
                                    }} chartData={pieChartDataAge} />
                                </div> : <></>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}