import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CreateNavigationProvider } from '../src/store/navigation'
import { CreateLinkedinAnalyticProvider } from './store/linkedinAnalytic';
import { CreateInstagramAnalyticProvider } from './store/InstagramAnalytics';
import { UserConnectedAccountProvider } from './store/UserConnectedAccount';
import { UserNavigationProvider } from './store/NavigationProvider';
import { CreatePinterestAnalyticProvider } from './store/pinterestAnalytics';
import { CreateFacebookAnalyticProvider } from './store/FacebookAnalytics'
import { CommonSocialMediaProvider } from './store/CommonSocialMediaStore.js'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    
      <UserNavigationProvider>
        <UserConnectedAccountProvider>
          <CreateNavigationProvider>
            <CreateLinkedinAnalyticProvider>
              <CreateInstagramAnalyticProvider>
                <CreatePinterestAnalyticProvider>
                  <CreateFacebookAnalyticProvider>
                    <CommonSocialMediaProvider>
                    <App />
                    <Tooltip id="my-tooltip1" style={{ width: '10vw', fontFamily: 'Poppins', fontSize: 10, fontStyle: 'normal', fontWeight: "normal", lineHeight: "normal", letterSpacing: 0.36, zIndex: 12, backgroundColor: 'rgba(53, 56, 57, 1)', color: '#ffffff', borderRadius: 10 }} />
                    </CommonSocialMediaProvider>
                  </CreateFacebookAnalyticProvider>
                </CreatePinterestAnalyticProvider>
              </CreateInstagramAnalyticProvider>
            </CreateLinkedinAnalyticProvider>
          </CreateNavigationProvider>
        </UserConnectedAccountProvider>
      </UserNavigationProvider>
    

  // </React.StrictMode>
);

reportWebVitals();
