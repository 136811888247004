import OverviewCard from '../../Component/OverviewCard/overviewcard'
import Styles from './InstagramOverview.module.css'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import { useContext, useState, useEffect } from 'react'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import InstagramOverviewSkeleton from '../../Component/InstagramOverviewSkeleton/InstagramOverviewSkeleton'
import InstagramNavbar from '../../Component/InstagramNavbar/InstagramNavbar'
import { captionTruncate, formatNumber } from '../../Util/helper'
import { createNavigationContext } from '../../store/navigation'
import Next from '../../assets/nextArrow.svg'
import Previous from '../../assets/backArrow.svg'
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup'

import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount'
import BlogListCard from '../../Component/BlogListCard/BlogListCard'
import { UserNavigationContext } from '../../store/NavigationProvider'
import AudienceAcivityNoData from '../../Component/AudienceAcivityNoData/AudienceAcivityNoData'
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent'

const InstagramOverview = () => {

    const navigationContext = useContext(createNavigationContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [hoverFollower, sethoverFollower] = useState(false)
    const [hoverReach, sethoverReach] = useState(false)
    const [hoverProfileActivity, sethoverProfileActivity] = useState(false)


    const [postImageIndex, setpostImageIndex] = useState(0)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const prevClick = () => {
        if ((postImageIndex - 1) >= 0) {
            setpostImageIndex((prev) => prev - 1)
        }
    }



    const nextClick = () => {

        if (postImageIndex < (instagramContext?.postDetails?.data?.children?.data?.length - 1)) {
            setpostImageIndex((prev) => prev + 1)
        }
    }


    const instagramContext = useContext(CreateInstagramAnalyticContext)

    useEffect(() => {

        if (instagramContext.overviewPageFilterLoading) {
            instagramContext.getInstagramOverviewPageFilterPeriodData();
            instagramContext.getInstagramContentPageAnalyticaData();
            instagramContext.getInstagramAccountPageAnalyticaData();
        }
        // eslint-disable-next-line
    }, [instagramContext.selectedPeriod])


    const data = {
        labels: instagramContext?.follower[0]?.values?.map(data => {
            const newDate = new Date(data.end_time);
            const monthShortName = newDate.toLocaleString('default', { month: 'short' });
            const day = String(newDate.getDate()).padStart(2, '0');
            return `${monthShortName}-${day}`
        }),
        datasets: [{
            label: 'Follower oraganic count',
            data: instagramContext?.follower[0]?.values?.map(data => data.value),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverFollower ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverFollower ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const impressionChartData = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: 'Reach count',
            data: instagramContext?.reach?.map(data => data?.values?.map(data => data.value)).filter(data => data !== undefined).flat(),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverReach ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverReach ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'

        },
        {
            label: 'Impression count',
            data: instagramContext?.impressions?.map(data => data?.values?.map(data => data.value)).filter(data => data !== undefined).flat(),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(255, 117, 37,0.25)"
            },
            borderColor: '#ff8a25',
            borderDash: [4, 3],
            pointBorderColor: hoverReach ? 'rgba(255, 117, 37, 1)' : 'rgba(255, 117, 37, 0)',
            pointBackgroundColor: hoverReach ? 'rgba(255, 117, 37, 0.5)' : 'rgba(255, 117, 37, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    }



    const profileActivityChartData = {
        labels: instagramContext.followerDateArray,
        datasets: [{
            label: 'Profile Activity',
            data: instagramContext?.profileVisits?.map(data => data?.values?.map(data => data.value)).filter(data => data !== undefined).flat(),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverProfileActivity ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverProfileActivity ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        },
        {
            label: 'Website Clicks',
            data: instagramContext?.websiteClicks?.map(data => data?.values?.map(data => data.value)).filter(data => data !== undefined).flat(),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(255, 117, 37,0.25)"
            },
            borderColor: '#ff8a25',
            borderDash: [4, 3],
            pointBorderColor: hoverProfileActivity ? 'rgba(255, 117, 37, 1)' : 'rgba(255, 117, 37, 0)',
            pointBackgroundColor: hoverProfileActivity ? 'rgba(255, 117, 37, 0.5)' : 'rgba(255, 117, 37, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    }






    function getDayName(dateStr, locale) {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: 'long' });
    }







    const getBackGroundColor = (online_follower_percentage) => {
        if (online_follower_percentage >= 0 && online_follower_percentage <= 15)
            return '#e6f2ff'

        if (online_follower_percentage >= 16 && online_follower_percentage <= 30)
            return '#99c9ff';

        if (online_follower_percentage >= 31 && online_follower_percentage <= 45)
            return '#4da1ff'

        if (online_follower_percentage >= 46 && online_follower_percentage <= 60)
            return '#0078ff'

        if (online_follower_percentage >= 61 && online_follower_percentage <= 75)
            return '#0060cc';

        if (online_follower_percentage >= 76 && online_follower_percentage <= 90)
            return '#004899'

        if (online_follower_percentage >= 91 && online_follower_percentage <= 100)
            return '#003066'

    }

    function getRangeValues(input, range, color) {

        const { value } = input;
        const [startHour, endHour] = range?.split('-').map(Number);

        const output = [];
        for (let i = startHour; i < endHour; i++) {
            const key = `${i}-${i + 1}`;
            const val = value[i];
            output.push({ [key]: val });
        }

        return output.map((data, index) => {

            return (
                <span key={index} style={{ display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'flex-start' }} ><span style={{ backgroundColor: color }} className={Styles.circle} ></span> {Object.keys(data)[0] === "0-1" ? "12-1" : Object.keys(data)}: {Object.values(data)} followers <br /></span>
            )
        });
    }

    function getRangeValuesSum(input, range) {

        const { value } = input;
        const [startHour, endHour] = range?.split('-').map(Number);

        const output = [];
        for (let i = startHour; i < endHour; i++) {
            const key = `${i}-${i + 1}`;
            const val = value[i];
            output.push({ [key]: val });
        }

        return output.reduce((totalFollower, data) => {
            return totalFollower = totalFollower + parseInt(Object.values(data))
        }, 0)
    }

    const getNoOfHashtagByRank = (rank) => {
        // eslint-disable-next-line
        return instagramContext?.rankedHashtag?.filter(data => {
            if (rank === 1) {
                if (data.rank >= 1 && data.rank <= 10) {
                    return data
                }

            }
            if (rank === 2) {
                if (data.rank >= 11 && data.rank <= 25) {
                    return data
                }

            }
            if (rank === 3) {
                if (data.rank >= 26 && data.rank <= 50) {
                    return data
                }

            }
            if (rank === 4) {
                if (data.rank >= 51 && data.rank <= 100) {
                    return data
                }
            }
        }).length
    }

    return (
        <div className={Styles.instagram_overview_container} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {instagramContext.overviewPageLoading ? <InstagramOverviewSkeleton /> :
                <div className={Styles.overview_container} >

                    <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.overview_content_main_container} >
                        <ActiveSocialAccount page="instagram" disableFilter={true} loading={instagramContext.overviewPageLoading} selected={instagramContext.selectedPeriod === 30 ? (instagramContext.selectedPeriod + 1) : instagramContext.selectedPeriod} onChangePeriod={(value) => { instagramContext.setselectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); instagramContext.onChangeoverviewPageFilterLoading(true) }} />

                        <InstagramNavbar active="overview" />

                        <div className={Styles.overview_content_container} >

                            {instagramContext.totalFollower < 100 ? <BlogListCard type="instagram" /> : <></>}
                            <div className={Styles.card_container} >
                                <OverviewCard toolTip="Your change in follower over the selected time frame. This metric is only available over the period that you have had your instagram account linked to Analytics." data={true} count={formatNumber(instagramContext?.follower[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0) || 0)} percentage={0} header="Follower Change" />
                                <OverviewCard toolTip="The number of unique accounts that have seen any of your posts in the selected time frame." people={true} count={formatNumber(instagramContext?.reach?.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0))?.reduce((totalReach, data) => { return totalReach += data }, 0) || 0)} percentage={isFinite(instagramContext.reachPercentage) ? instagramContext.reachPercentage : 0} header="People Reached" />
                                <OverviewCard toolTip="The total number of times your profile was visited in selected time frame." profile={true} count={formatNumber(instagramContext?.profileVisits?.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0))?.reduce((totalReach, data) => { return totalReach += data }, 0) || 0)} percentage={isFinite(instagramContext.profileVisitPercentage) ? instagramContext.profileVisitPercentage : 0} header="Profile Visits" />
                                <OverviewCard toolTip="The total number of times the link displayed on your profile has been clicked in selected time frame" website={true} count={formatNumber(instagramContext?.websiteClicks?.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0))?.reduce((totalReach, data) => { return totalReach += data }, 0) || 0)} percentage={isFinite(instagramContext.websiteclicksPercentage) ? instagramContext.websiteclicksPercentage : 0} header="Website Clicks" />
                            </div>

                            <div className={Styles.latest_pin_and_followers_container} >
                                <div>

                                    <div className={Styles.header_container} >
                                        <label> Latest Post</label>
                                        <span onClick={() => navigationContext.setActive("content")} style={{ cursor: 'pointer' }} >See all</span>
                                    </div>
                                    {instagramContext?.latestPost ?
                                        <>

                                            <div className={Styles.latest_post_container} >

                                                <div className={Styles.img_container} >

                                                    {instagramContext.latestPost?.data?.media_type === 'CAROUSEL_ALBUM' ? <img alt="" src={instagramContext?.latestPost?.data?.children?.data[postImageIndex]?.media_url} /> : <></>}
                                                    {instagramContext.latestPost?.data?.media_type === "VIDEO" ? <img alt="" src={instagramContext?.latestPost?.data?.thumbnail_url} /> : <></>}
                                                    {instagramContext.latestPost?.data?.media_type === "IMAGE" ? <img alt="" src={instagramContext?.latestPost?.data?.media_url} /> : <></>}
                                                    {instagramContext.latestPost?.media_product_type === "FEED" ?
                                                        <div className={Styles.button_container} >
                                                            <button onClick={prevClick} > <img src={Previous} alt="previous" />  </button>
                                                            <button onClick={nextClick} > <img src={Next} alt="next" /> </button>
                                                        </div> : <></>}
                                                </div>
                                                {instagramContext.latestPost?.data?.media_product_type === "FEED" ?
                                                    <div className={Styles.content_container_overview} >
                                                        <div className={Styles.header_value_container} >
                                                            <span>Likes</span>
                                                            <span>{formatNumber(instagramContext?.latestPost?.data?.like_count || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Comments</span>
                                                            <span>{formatNumber(instagramContext?.latestPost?.data?.comments_count || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Engagement Rate</span>

                                                            {instagramContext.latestPost.res.data.length ?
                                                                <span>{isFinite(((((instagramContext?.latestPost?.data?.like_count + instagramContext?.latestPost?.data?.comments_count + instagramContext.latestPost.res.data.filter(data => data.name === "saved")?.map(data => data.values[0].value)[0]) / instagramContext.latestPost.res.data.filter(data => data.name === "reach")?.map(data => data.values[0].value)[0])) * 100).toFixed(1)) ? ((((instagramContext?.latestPost?.data?.like_count + instagramContext?.latestPost?.data?.comments_count + instagramContext.latestPost.res.data.filter(data => data.name === "saved")?.map(data => data.values[0].value)[0]) / instagramContext.latestPost.res.data.filter(data => data.name === "reach")?.map(data => data.values[0].value)[0])) * 100).toFixed(1) : 0}%</span> :
                                                                <span>0</span>
                                                            }

                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Saves</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "saved")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Impressions</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "impressions")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Reach</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "reach")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                    </div> :
                                                    <div className={Styles.content_container_overview} >
                                                        <div className={Styles.header_value_container} >
                                                            <span>Likes</span>
                                                            <span>{formatNumber(instagramContext?.latestPost?.data?.like_count || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Comments</span>
                                                            <span>{formatNumber(instagramContext?.latestPost?.data?.comments_count || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Share</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "shares")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Saves</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "saved")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Post Interactions</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "total_interactions")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Plays</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "plays")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                        <div className={Styles.header_value_container} >
                                                            <span>Reach</span>
                                                            <span>{formatNumber(instagramContext.latestPost.res.data.filter(data => data.name === "reach")?.map(data => data.values[0].value)[0] || 0)}</span>
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                            {instagramContext.latestPost?.data?.media_product_type === "FEED" ? <p>{captionTruncate(instagramContext?.latestPost?.data?.caption !== undefined ? instagramContext?.latestPost?.data?.caption : "This content has no caption", 190)}</p> : <p>This content has no caption</p>}
                                            {/*ranked hashtag */}
                                            <div className={Styles.ranked_hashtag_container} >
                                                <h4>Ranked Hashtags - The placement of your post within the 'top posts' feed.</h4>
                                                <div className={Styles.ranked_tab_container} >
                                                    <span onClick={() => instagramContext.onChangerannkedTab(1)} style={instagramContext.rannkedTab === 1 ? { borderBottom: '2px solid #000000', color: '#000000' } : {}} >Top 10 ({getNoOfHashtagByRank(1)})</span>
                                                    <span onClick={() => instagramContext.onChangerannkedTab(2)} style={instagramContext.rannkedTab === 2 ? { borderBottom: '2px solid #000000', color: '#000000' } : {}} >11th - 25th ({getNoOfHashtagByRank(2)})</span>
                                                    <span onClick={() => instagramContext.onChangerannkedTab(3)} style={instagramContext.rannkedTab === 3 ? { borderBottom: '2px solid #000000', color: '#000000' } : {}} >26th - 50th ({getNoOfHashtagByRank(3)})</span>
                                                    <span onClick={() => instagramContext.onChangerannkedTab(4)} style={instagramContext.rannkedTab === 4 ? { borderBottom: '2px solid #000000', color: '#000000' } : {}} >51st - 100th ({getNoOfHashtagByRank(4)})</span>
                                                </div>
                                                <div className={Styles.ranked_hashtag_name_cotainer} >
                                                    {// eslint-disable-next-line
                                                        instagramContext?.rankedHashtag?.filter(data => {
                                                            if (instagramContext.rannkedTab == 1) {
                                                                if (data.rank >= 1 && data.rank <= 10) {
                                                                    return data
                                                                }

                                                            }
                                                            if (instagramContext.rannkedTab == 2) {
                                                                if (data.rank >= 11 && data.rank <= 25) {
                                                                    return data
                                                                }

                                                            }
                                                            if (instagramContext.rannkedTab == 3) {
                                                                if (data.rank >= 26 && data.rank <= 50) {
                                                                    return data
                                                                }

                                                            }
                                                            if (instagramContext.rannkedTab == 4) {
                                                                if (data.rank >= 51 && data.rank <= 100) {
                                                                    return data
                                                                }
                                                            }
                                                        })?.sort((a, b) => a.rank - b.rank)?.map(data => {
                                                            return <div className={Styles.hashtag_item_container} >
                                                                {"#" + data.hashtag + ` (${data.rank})`}
                                                            </div>
                                                        })}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <NoDataAvailablePopup cardName="latestpost" />
                                        </>

                                    }

                                </div>

                                <LineChartCustomComponent onHoverOut={() => sethoverFollower(false)} onHoverIn={() => sethoverFollower(true)} type="line" header="Followers" subheader="" contentArray={[{ "header": "Total Followers", value: formatNumber(instagramContext.totalFollower || 0) }, {
                                    "header": "Followers Change", type: "solid", indicator: "#0078FF", value: formatNumber(instagramContext?.follower[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0) || 0)
                                }, {
                                    "header": "Growth Rate", value: ` ${isFinite(((instagramContext?.follower[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0) / instagramContext.totalFollower) * 100).toFixed(2)) ? ((instagramContext?.follower[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0) / instagramContext.totalFollower) * 100).toFixed(2) : 0}%`
                                }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverFollower ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },


                                }} chartData={data} />


                            </div>

                            <div className={Styles.audience_activity_container} >
                                <div className={Styles.header_container} >
                                    <label>Audience Activity</label>
                                    <button onClick={() => navigationContext.setActive("account")} >See all</button>
                                </div>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                    <>
                                        {instagramContext?.online_followers[0]?.date !== undefined && instagramContext?.online_followers?.some(data => data.online_follower !== 0) ? <div className={Styles.date_and_activity_container} >
                                            {/* eslint eqeqeq: 0 */}
                                            <label>{new Date(instagramContext?.online_followers[0]?.date)?.getDate()} {monthNames[new Date(instagramContext.online_followers[0]?.date).getMonth()]}, {getDayName(instagramContext?.online_followers[0]?.date)} -<br />Based on last week's activity, the best time to post today is <b>between {instagramContext?.online_followers?.filter(data => data.online_follower == Math.max(...instagramContext.online_followers.map(data => data.online_follower)))?.map(data => data.time)[0]}{instagramContext?.online_followers?.filter(data => data.online_follower == Math.max(...instagramContext.online_followers.map(data => data.online_follower)))?.map(data => data.time).length > 1 ? `, ${instagramContext?.online_followers?.filter(data => data.online_follower == Math.max(...instagramContext.online_followers.map(data => data.online_follower)))?.map(data => data.time)[1]}` : ''}.</b>
                                            </label>
                                            <div className={Styles.activity_container} >

                                                {instagramContext.online_followers.map((data, index) => {
                                                    return (
                                                        <div key={index} className={Styles.activity_item} >
                                                            <div style={{ backgroundColor: getBackGroundColor(((data.online_follower / instagramContext.totalFollower) * 100).toFixed(0)), border: `${Math.max(...instagramContext?.online_followers?.map(data => data.online_follower)) == data?.online_follower ? '3px solid #ff43ca' : 'none'}` }} className={Styles.activity} >
                                                                <div className={Styles.hover_card_container} >
                                                                    <label>Total: <b>{getRangeValuesSum(instagramContext.apiResOfOnlineFollwer.filter(item => item.end_time === instagramContext?.online_followers[0]?.date)[0], data.time === "12-2" ? "0-2" : data.time)}</b> followers</label>
                                                                    <div className={Styles.hover_sub_container} >
                                                                        <p>{getRangeValues(instagramContext.apiResOfOnlineFollwer.filter(item => item.end_time === instagramContext?.online_followers[0]?.date)[0], data.time === "12-2" ? "0-2" : data.time, getBackGroundColor(((data.online_follower / instagramContext.totalFollower) * 100).toFixed(0)))}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <svg width="1" height="5" viewBox="0 0 1 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="0.25" y1="0.5" x2="0.25" y2="4.5" stroke="#808080" strokeWidth="0.5" />
                                                            </svg>
                                                            <label>{data.time}</label>

                                                        </div>
                                                    )

                                                })
                                                }
                                            </div>
                                        </div> : <AudienceAcivityNoData />

                                        }
                                    </> : <PremiumPopup cardName="audience" />

                                }


                            </div>

                            <div className={Styles.reach_and_profile_activity} >
                                <LineChartCustomComponent onHoverOut={() => sethoverReach(false)} onHoverIn={() => sethoverReach(true)} type="line" header="Reach & Impressions" subheader="" contentArray={[{ "header": "Impressions", type: "solid", indicator: "#0078FF", value: formatNumber(instagramContext?.impressions?.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0))?.reduce((totalReach, data) => { return totalReach += data }, 0) || 0) }, {
                                    "header": "People Reached", type: "dashed", indicator: "#ff8a25", value: formatNumber(instagramContext?.reach.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0) || 0)
                                }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverReach ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={impressionChartData} />

                                <LineChartCustomComponent onHoverOut={() => sethoverProfileActivity(false)} onHoverIn={() => sethoverProfileActivity(true)} type="line" header="Profile Activity" subheader="" contentArray={[{ "header": "Profile Views", type: "solid", indicator: "#0078FF", value: formatNumber(instagramContext?.profileVisits?.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0))?.reduce((totalReach, data) => { return totalReach += data }, 0) || 0) }, {
                                    "header": "Website Clicks", type: "dashed", indicator: "#ff8a25", value: formatNumber(instagramContext?.websiteClicks.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0) || 0)
                                }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverProfileActivity ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={profileActivityChartData} />
                            </div>




                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

export default InstagramOverview