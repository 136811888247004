import { useContext, useState } from 'react';
import { captionTruncate, formatNumber } from '../../Util/helper';
import Styles from './InstagramPostDetails.module.css'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics';
import Back from '../../assets/backArrow.svg'
import Next from '../../assets/nextArrow.svg'
const InstagramPostDetails = (props) => {

    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const [postImageIndex, setpostImageIndex] = useState(0)
    const [rankedTab, setrankedTab] = useState(1)

    function filterHashtags(captionString) {
        // Create a regular expression to match hashtags
        const hashtagRegex = /#(\w+)/g;

        // Find all hashtags in the caption string
        const hashtags = captionString.match(hashtagRegex);

        // Return an array of filtered hashtags
        return hashtags?.filter(hashtag => {
            // Remove the hash symbol from the hashtag
            const filteredHashtag = hashtag.substring(1);

            // Return the filtered hashtag if it is not empty
            return filteredHashtag.length > 0;
        });
    }

    const prevClick = () => {
        if ((postImageIndex - 1) >= 0) {
            setpostImageIndex((prev) => prev - 1)
        }
    }

    const nextClick = () => {
        if (props?.type === "facebook") {
            if (postImageIndex < (props?.data?.attachments?.data[0]?.subattachments?.data?.length - 1)) {
                setpostImageIndex((prev) => prev + 1)
            }
        } else {
            if (postImageIndex < (props?.data?.children?.data?.length - 1)) {
                setpostImageIndex((prev) => prev + 1)
            }
        }
    }

    const checkHashtagRankedOrNot = (postData) => {
        const hashtagData = filterHashtags(postData.caption)
        if (hashtagData !== undefined) {
            return instagramContext.rankedHashtagContent.filter(data => hashtagData.map(data => data.replace('#', '').toLowerCase()).includes(data.hashtag.toLowerCase()) && data.rank !== "Not found").filter(data => {
                if (rankedTab === 1) {
                    if (data.rank >= 1 && data.rank <= 10) {
                        return data
                    }
                }
                if (rankedTab === 2) {
                    if (data.rank >= 11 && data.rank <= 25) {
                        return data
                    }
                }
                if (rankedTab === 3) {
                    if (data.rank >= 26 && data.rank <= 50) {
                        return data
                    }
                }
                if (rankedTab === 4) {
                    if (data.rank >= 51 && data.rank <= 100) {
                        return data
                    }
                }
            })?.sort((a,b)=> a.rank - b.rank)?.map(data => {
                return <div className={Styles.hashtag_item_container} >
                    {"#" + data.hashtag + ` (${data.rank})`}
                </div>
            })
        }
    }

    const getNoOfHashtagByRank = (rank) => {
        const hashtagData = filterHashtags(props.data.caption)
        if (hashtagData !== undefined) {
            return instagramContext?.rankedHashtagContent?.filter(data => {
                if (rank === 1) {
                    if (data.rank >= 1 && data.rank <= 10) {
                        return data
                    }

                }
                if (rank === 2) {
                    if (data.rank >= 11 && data.rank <= 25) {
                        return data
                    }

                }
                if (rank === 3) {
                    if (data.rank >= 26 && data.rank <= 50) {
                        return data
                    }

                }
                if (rank === 4) {
                    if (data.rank >= 51 && data.rank <= 100) {
                        return data
                    }
                }
            }).length
        } else {
            return 0
        }
    }



    return (
        <div className={Styles.instagram_post_details_model_container} >

            <div className={Styles.container} >
                <div className={Styles.header_container} >
                    <span>Post</span>
                    {/* Corss SVG Icon */}
                    <svg style={{ cursor: 'pointer' }} onClick={() => props.onClickClose()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg>
                </div>
                {props?.type === "facebook" ?
                    <div className={Styles.chart_container} >
                        <div className={Styles.post_content_container} >
                            <div className={Styles.image_container} >
                                {props?.data?.attachments?.data[0]?.type === "video_autoplay" || props?.data?.attachments?.data[0]?.type === "video_inline" ? <img alt="" src={props?.data?.attachments?.data[0]?.media?.image.src} /> : null}
                                {props?.data?.attachments?.data[0]?.type === "photo" ? <img alt="" src={props?.data?.attachments?.data[0]?.media?.image.src} /> : <></>}
                                {props?.data?.attachments?.data[0]?.type === "album" ? <img alt="" src={props?.data?.attachments?.data[0]?.subattachments?.data[postImageIndex]?.media?.image?.src} /> : <></>}
                                {props?.data?.attachments?.data[0]?.type === "album" ?
                                    <div className={Styles.button_container} >
                                        <button onClick={prevClick} ><img src={Back} alt="back" /></button>
                                        <button onClick={nextClick} ><img src={Next} alt="next" /></button>
                                    </div> : <></>
                                }

                            </div>
                            <div className={Styles.content_container} >
                                <div className={Styles.header_value_container} >
                                    <span>Likes</span>
                                    <span>{formatNumber(props?.data?.likes.summary.total_count || 0)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Comments</span>
                                    <span>{formatNumber(props?.data?.comments.summary.total_count || 0)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Engagement Rate</span>

                                    <span>{props?.data?.statistics?.filter(postDetails => postDetails.name === "post_engaged_users")?.map(data => data.values[0].value)?.length ? props?.data?.statistics?.filter(postDetails => postDetails.name === "post_engaged_users").map(data => data.values[0].value) : 0}%</span>
                                </div>

                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Impressions</span>
                                    <span>{formatNumber(props?.data?.statistics?.filter(postDetails => postDetails.name === "post_impressions").map(data => data.values[0].value || 0))}</span>
                                </div>

                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Created Date</span>
                                    <span>{new Date(props.data.created_time).getDate() + "/" + (new Date(props.data.created_time).getMonth() + 1) + "/" + new Date(props.data.created_time).getFullYear()} {new Date(props.data?.created_time).toLocaleTimeString('en-US')}</span>
                                </div>



                            </div>

                        </div>
                        {props?.data?.message !== undefined ? <p  >{captionTruncate(props?.data?.message)}</p> : <p>This content has no caption</p>}
                    </div> :

                    <div className={Styles.chart_container} >
                        <div className={Styles.post_content_container} >
                            <div className={Styles.image_container} >
                                {props?.data?.media_type === 'CAROUSEL_ALBUM' ? <img alt="" src={props?.data?.children?.data[postImageIndex]?.media_url} /> : <></>}
                                {props?.data?.media_type === "VIDEO" ? <img alt="" src={props?.data?.thumbnail_url} /> : <></>}
                                {props?.data?.media_type === "IMAGE" ? <img alt="" src={props?.data?.media_url} /> : <></>}
                                {props?.data?.media_type === 'CAROUSEL_ALBUM' ?
                                    <div className={Styles.button_container} >
                                        <button onClick={prevClick} ><img src={Back} alt="back" /></button>
                                        <button onClick={nextClick} ><img src={Next} alt="next" /></button>
                                    </div> : <></>}
                            </div>
                            <div className={Styles.content_container} >
                                <div className={Styles.header_value_container} >
                                    <span>Likes</span>
                                    <span>{formatNumber(props?.data?.like_count || 0)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Comments</span>
                                    <span>{formatNumber(props?.data?.comments_count || 0)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Engagement Rate</span>
                                    <span>{isFinite(((instagramContext.allPostInsights.filter(postDetails => postDetails.id === props?.data?.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext?.allPostInsights?.filter(postDetails => postDetails.id === props?.data?.id).map(postDetails => postDetails.res)[0]?.data?.filter(postDetails => postDetails.name === "reach")?.map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1)) ? ((instagramContext.allPostInsights.filter(postDetails => postDetails.id === props?.data?.id).map(postDetails => postDetails.res)[0].data.filter(postDetails => postDetails.name === "total_interactions").map(postDetails => postDetails.values[0].value)[0] / instagramContext?.allPostInsights?.filter(postDetails => postDetails.id === props?.data?.id).map(postDetails => postDetails.res)[0]?.data?.filter(postDetails => postDetails.name === "reach")?.map(postDetails => postDetails.values[0].value)[0]) * 100).toFixed(1) : 0}%</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Saves</span>
                                    <span>{formatNumber(instagramContext?.allPostInsights?.filter(postDetails => postDetails.id === props?.data?.id).map(postDetails => postDetails.res)[0]?.data?.filter(postDetails => postDetails.name === "saved")?.map(postDetails => postDetails.values[0].value)[0] || 0)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Impressions</span>
                                    <span>{formatNumber(instagramContext?.allPostInsights?.filter(postDetails => postDetails.id === props?.data?.id).map(postDetails => postDetails.res)[0]?.data?.filter(postDetails => postDetails.name === "impressions")?.map(postDetails => postDetails.values[0].value)[0] || 0)}</span>
                                </div>
                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Reach</span>
                                    <span>{formatNumber(instagramContext?.allPostInsights?.filter(postDetails => postDetails.id === props?.data?.id).map(postDetails => postDetails.res)[0]?.data?.filter(postDetails => postDetails.name === "reach")?.map(postDetails => postDetails.values[0].value)[0] || 0)}</span>
                                </div>

                                <div className={Styles.border_of_post} ></div>
                                <div className={Styles.header_value_container} >
                                    <span>Created Date</span>
                                    <span>{new Date(props.data.timestamp).getDate() + "/" + (new Date(props.data.timestamp).getMonth() + 1) + "/" + new Date(props.data.timestamp).getFullYear()} {new Date(props.data?.timestamp).toLocaleTimeString('en-US')}</span>
                                </div>


                            </div>

                        </div>

                        <div className={Styles.ranked_hashtag_container} >
                            <h4>Ranked Hashtags - The placement of your post within the 'top posts' feed.</h4>
                            <div className={Styles.ranked_tab_container} >
                                <span onClick={() => setrankedTab(1)} style={rankedTab === 1 ? { borderBottom: '2px solid #000000', color: '#000' } : {}}  >Top 10 ({getNoOfHashtagByRank(1)})</span>
                                <span onClick={() => setrankedTab(2)} style={rankedTab === 2 ? { borderBottom: '2px solid #000000', color: '#000' } : {}} >11th - 25th ({getNoOfHashtagByRank(2)})</span>
                                <span onClick={() => setrankedTab(3)} style={rankedTab === 3 ? { borderBottom: '2px solid #000000', color: '#000' } : {}} >26th - 50th ({getNoOfHashtagByRank(3)})</span>
                                <span onClick={() => setrankedTab(4)} style={rankedTab === 4 ? { borderBottom: '2px solid #000000', color: '#000' } : {}} >51st - 100th ({getNoOfHashtagByRank(4)})</span>
                            </div>
                            <div className={Styles.ranked_hashtag_name_cotainer} >
                                {checkHashtagRankedOrNot(props.data)}
                            </div>
                        </div>

                        {props?.data?.caption !== undefined ? <p  >{captionTruncate(props?.data?.caption)}</p> : <p>This content has no caption</p>}
                    </div>
                }
            </div>
        </div>
    )
}

export default InstagramPostDetails