import { createContext, useState, useContext } from 'react'
import APICall from '../services/api.service'
import { UserConnectedAccountContext } from './UserConnectedAccount'
import { url } from '../Util/constant'
import { UserNavigationContext } from './NavigationProvider'
import { toast } from 'react-toastify'
import axios from 'axios'
import ColorThief from "colorthief";
import variables from '../Util/variables'
import { CommonSocialMediaContext } from './CommonSocialMediaStore'


export const CreateInstagramAnalyticContext = createContext({
    selectedPeriod: '',
    setselectedPeriod: () => { },
    loading: '',
    setloading: () => { },
    follower: '',
    setfollower: () => { },
    reach: '',
    setreach: () => { },
    profileActivity: '',
    setprofileActivity: () => { },
    profileVisits: '',
    setprofileVisits: () => { },
    websiteClicks: '',
    setwebsiteClicks: () => { },
    postDetails: '',
    setpostDetails: () => { },
    audience: '',
    setaudience: () => { },
    followerDateArray: '',
    setfollowerDateArray: () => { },
    followerPercentage: '',
    setfollowerPercentage: () => { },
    reachPercentage: '',
    setreachPercentage: () => { },
    profileVisitPercentage: '',
    setprofileVisitPercentage: () => { },
    websiteclicksPercentage: '',
    setwebsiteclicksPercentage: () => { },
    totalFollower: '',
    settotalFollower: () => { },
    insightsPost: '',
    setinsightsPost: () => { },
    impressions: '',
    setimpressions: () => { },
    online_followers: '',
    setonline_followers: () => { },
    activeOnlineFollowersObject: '',
    setactiveOnlineFollowersObject: () => { },
    allPostInsights: '',
    setallPostInsights: () => { },
    impressionsPercentage: '',
    setimpressionsPercentage: () => { },
    allStoryDetails: '',
    setallStoryDetails: () => { },
    getInstagramAnalyticsData: () => { },
    getInstagramAnalyticsAsPerDaysFilterData: () => { },
    instagramAudienceData: [],
    online_follower_last_week: '',
    searchCompetitorLoading: '',

    competitorsData: '',
    getCompetitorsData: () => { },
    competitors: '',
    handleCompetitor: () => { },
    changeCompetitors: () => { },
    addPageToCompetitors: () => { },
    deleteCompetitors: () => { },
    saveInstagramCompetitors: () => { },
    audience_cityData: [],
    audience_country: [],
    filterLoading: '',
    setfilterLoading: () => { },
    followerDate: '',
    changeLoadingState: () => { },
    search: '',
    setsearch: () => { },
    addCompetitorsLoader: '',
    setaddCompetitorsLoader: () => { },
    saveCompetitorsLoading: '',
    latestPost: '',
    lineChartData: '',
    showSearchList: '',
    setshowSearchList: () => { },
    searchList: '',
    setsearchList: () => { },
    searchCompetitors: () => { },
    storyPagination: () => { },
    storyPage: '',
    stopStoryPagination: '',
    storyPaginationLoading: '',
    apiResOfOnlineFollwer: '',
    borderColorArray: '',
    changeborderColorArray: () => { },
    hideCompetitors: () => { },
    getInstagramOverviewPageAnalyticaData: () => { },
    getInstagramContentPageAnalyticaData: () => { },
    getInstagramAccountPageAnalyticaData: () => { },
    getInstagramCompetitorPageAnalyticaData: () => { },
    getInstagramOverviewPageFilterPeriodData: () => { },
    overviewPageLoading: true,
    contentPageLoading: true,
    accountPageLoading: true,
    competitorPageLoading: true,
    overviewPageFilterLoading: false,
    contentPageFilterLoading: false,
    accountPageFilterLoading: false,
    onChangeoverviewPageFilterLoading: () => { },
    onChangecontentPageFilterLoading: () => { },
    onChangeaccountPageFilterLoading: () => { },
    stackedData: [],
    stackedDataContent: [],
    rankedHashtag: [],
    rannkedTab: 1,
    onChangerannkedTab: () => { },
    rankedHashtagContent: [],
    keywordAdded: [],
    onChangeKeywordAdded: () => { },
    onChangeAllKeywordAdded: () => { },
    deleteKeywordAdded: () => { },
    confirmKeywordAdded: [],
    onChangeconfirmKeywordAdded: () => { },
    reportWeekArray: [],
    selectedReportDate: '',
    onChangesetselectedReportDate: () => { },
    mentionsData: [],
    tagData: [],
    clearState: () => { }
})

export const CreateInstagramAnalyticProvider = ({ children }) => {
    const commonSocialMediaContext = useContext(CommonSocialMediaContext)
    const userContext = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [allStoryDetails, setallStoryDetails] = useState([])
    const [impressionsPercentage, setimpressionsPercentage] = useState('')
    const [allPostInsights, setallPostInsights] = useState([])
    const [mentionsData, setmentionsData] = useState([])
    const [tagData, settagData] = useState([])
    const [activeOnlineFollowersObject, setactiveOnlineFollowersObject] = useState('')
    const [insightsPost, setinsightsPost] = useState({})
    const [totalFollower, settotalFollower] = useState('')
    const [loading, setloading] = useState(true)
    const [follower, setfollower] = useState([])
    const [online_followers, setonline_followers] = useState([])
    const [online_follower_last_week, setonline_followers_week] = useState([])
    const [reach, setreach] = useState([])
    const [profileVisits, setprofileVisits] = useState([])
    const [websiteClicks, setwebsiteClicks] = useState([])
    const [postDetails, setpostDetails] = useState([])
    const [audience, setaudience] = useState([])
    const [selectedPeriod, setselectedPeriod] = useState(30)
    const [followerDateArray, setfollowerDateArray] = useState([])
    const [profileActivity, setprofileActivity] = useState([])
    const [followerPercentage, setfollowerPercentage] = useState('')
    const [reachPercentage, setreachPercentage] = useState('')
    const [profileVisitPercentage, setprofileVisitPercentage] = useState('')
    const [websiteclicksPercentage, setwebsiteclicksPercentage] = useState('')
    const [impressions, setimpressions] = useState([])
    const [instagramAudienceData, setinstagramAudienceData] = useState([])
    const [competitorsData, setcompetitorsData] = useState([])
    const [competitors, setcompetitors] = useState([])
    const [filterLoading, setfilterLoading] = useState(false)
    const [audience_cityData, setaudience_cityData] = useState([])
    const [audience_country, setaudience_country] = useState([])
    const [followerDate, setfollowerDate] = useState([])
    const [search, setsearch] = useState('')
    const [addCompetitorsLoader, setaddCompetitorsLoader] = useState(false)
    const [saveCompetitorsLoading, setsaveCompetitorsLoading] = useState(false)
    const [latestPost, setlatestPost] = useState('')
    const [lineChartData, setlineChartData] = useState([])
    const [showSearchList, setshowSearchList] = useState(false)
    const [searchList, setsearchList] = useState([])
    const [storyPage, setstoryPage] = useState(1)
    const [stopStoryPagination, setstopStoryPagination] = useState(false)
    const [storyPaginationLoading, setstoryPaginationLoading] = useState(false)
    const [apiResOfOnlineFollwer, setapiResOfOnlineFollwer] = useState('')
    const [searchCompetitorLoading, setsearchCompetitorLoading] = useState(false)
    const [borderColorArray, setborderColorArray] = useState([])
    const [overviewPageLoading, setoverviewPageLoading] = useState(true)
    const [contentPageLoading, setcontentPageLoading] = useState(true)
    const [accountPageLoading, setaccountPageLoading] = useState(true)
    const [competitorPageLoading, setcompetitorPageLoading] = useState(true)

    const [overviewPageFilterLoading, setoverviewPageFilterLoading] = useState(false)
    const [contentPageFilterLoading, setcontentPageFilterLoading] = useState(false)
    const [accountPageFilterLoading, setaccountPageFilterLoading] = useState(false)
    const [stackedData, setstackedData] = useState([])
    const [stackedDataContent, setstackedDataContent] = useState([])
    const [rankedHashtag, setrankedHashtag] = useState([])
    const [rankedHashtagContent, setrankedHashtagContent] = useState([])
    const [rannkedTab, setrannkedTab] = useState(1)
    const [keywordAdded, setkeywordAdded] = useState([])
    const [confirmKeywordAdded, setconfirmKeywordAdded] = useState([])
    const [reportWeekArray, setreportWeekArray] = useState([])
    const [selectedReportDate, setselectedReportDate] = useState('')
    const failedUsernames = [];

    const clearState = () => {
        setallStoryDetails([])
        setimpressionsPercentage('')
        setallPostInsights([])
        setmentionsData([])
        settagData([])
        setactiveOnlineFollowersObject('')
        setinsightsPost({})
        settotalFollower('')
        setfollower([])
        setonline_followers([])
        setonline_followers_week([])
        setreachPercentage('')
        setfollowerPercentage('')
        setreach([])
        setprofileVisits([])
        setwebsiteClicks([])
        setprofileVisitPercentage('')
        setwebsiteclicksPercentage('')
        setimpressionsPercentage('')
        setimpressions([])
        setinstagramAudienceData([])
        setcompetitorsData([])
        setcompetitors([])
        setlatestPost('')
        setaudience_cityData([])
        setaudience_country([])
        setlineChartData([])
        setapiResOfOnlineFollwer('')
        setborderColorArray([])
        setstackedData([])
        setstackedDataContent([])
        setrankedHashtag([])
        setkeywordAdded([])

    }

    const onChangeselectedReportDate = (value) => {
        setselectedReportDate(value)
    }

    const onChangeconfirmKeywordAdded = (value) => {
        setconfirmKeywordAdded(value)
    }
    const onChangeKeywordAdded = (value) => {
        setkeywordAdded(prev => [...prev, value])
    }

    const onChangeAllKeywordAdded = (value) => {
        setkeywordAdded(value)
    }

    const deleteKeywordAdded = (value) => {
        setkeywordAdded(value)
    }

    const onChangerannkedTab = (value) => {
        setrannkedTab(value)
    }
    const onChangeoverviewPageFilterLoading = (value) => {
        setoverviewPageFilterLoading(value)
    }

    const onChangecontentPageFilterLoading = (value) => {
        setcontentPageFilterLoading(value)
    }

    const onChangeaccountPageFilterLoading = (value) => {
        setaccountPageFilterLoading(value)
    }







    const getCurrentAndPreviousDate = () => {
        const token = localStorage.getItem('token')
        const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);
        const socialId = inUser[0]?.socialId
        const workspaceId = localStorage.getItem('organizationId')
        const currentDate = new Date();
        const startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - selectedPeriod);
        const startDateMilliseconds = startDate.getTime();
        const endDateMilliSeconds = currentDate.getTime();
        const startDateOfOnlineFollower = new Date(currentDate)
        startDateOfOnlineFollower.setDate(currentDate.getDate() - 14)
        const startDateForOnlineFollowerMiliSeconds = startDateOfOnlineFollower.getTime()
        const previousDate = new Date(startDate)
        previousDate.setDate(startDate.getDate() - selectedPeriod)
        const previousDateStartDateMiliSec = previousDate.getTime()
        const dateArray = [];
        const filterDateArray = [];
        for (let i = 0; i < (selectedPeriod); i++) {
            const newDate = new Date(startDate);
            newDate.setDate(startDate.getDate() + i); // Adjust the increment as needed
            const monthShortName = newDate.toLocaleString('default', { month: 'short' });
            const day = String(newDate.getDate()).padStart(2, '0');
            dateArray.push(`${monthShortName}-${day}`);
            const dateInMilliseconds = newDate.setHours(0, 0, 0, 0)
            filterDateArray.push(dateInMilliseconds)
        }
        const reportWeeks = [];

        currentDate.setDate(currentDate.getDate() - selectedPeriod); // Minus selectedPeriod from currentDate

        for (let i = 0; i < selectedPeriod; i += 7) { // Increment by 7 to represent weeks
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + i); // Adjust the increment as needed

            const weekStartDate = new Date(newDate);
            weekStartDate.setDate(newDate.getDate() - 6); // Get the start date of the week

            reportWeeks.push(newDate);
        }

        setselectedReportDate(reportWeeks[reportWeeks?.length - 1]); // Set selected report date to the first week

        return { token: token, dateArray: dateArray, filterDateArray: filterDateArray, previousDateStartDateMiliSec: previousDateStartDateMiliSec, startDateForOnlineFollowerMiliSeconds: startDateForOnlineFollowerMiliSeconds, endDateMilliSeconds: endDateMilliSeconds, startDateMilliseconds: startDateMilliseconds, socialId: socialId, workspaceId: workspaceId, reportWeeks: reportWeeks }

    }

    const calculatePercentage = (currentDateMetrics, previousDateMetrics) => {
        return (((currentDateMetrics - previousDateMetrics) / previousDateMetrics) * 100).toFixed(1)
    }

    function filterHashtags(captionString) {
        // Create a regular expression to match hashtags
        const hashtagRegex = /#(\w+)/g;

        // Find all hashtags in the caption string
        const hashtags = captionString?.match(hashtagRegex);

        // Return an array of filtered hashtags
        return hashtags?.filter(hashtag => {
            // Remove the hash symbol from the hashtag
            const filteredHashtag = hashtag.substring(1);

            // Return the filtered hashtag if it is not empty
            return filteredHashtag.length > 0;
        });
    }

    function filterHashtagsForContent(contentArray) {
        // Create a regular expression to match hashtags
        const hashtagRegex = /#(\w+)/g;

        // Find all hashtags in the caption string
        const hashtags = contentArray.map(data => {
            return { id: data.id, hashtags: data.caption.match(hashtagRegex) }
        })


        // Return an array of filtered hashtags
        return hashtags
    }

    const getInstagramOverviewPageAnalyticaData = async () => {
        const { dateArray, previousDateStartDateMiliSec, startDateForOnlineFollowerMiliSeconds, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token, reportWeeks } = getCurrentAndPreviousDate()
        setfollowerDateArray(dateArray)
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                setoverviewPageLoading(true)

                const overviewPageData = await axios.get(`${url}analytics/v2/instagram/overview/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}&prevDateSince=${previousDateStartDateMiliSec.toString().slice(0, 10)}&prevDateUntil=${startDateMilliseconds.toString().slice(0, 10)}&startDateForOnlineFollowerMiliSeconds=${startDateForOnlineFollowerMiliSeconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })

                //assign response into state
                let maxObject = null;
                setapiResOfOnlineFollwer(overviewPageData.data.audienceActivity?.data[0]?.values)

                maxObject = overviewPageData.data.audienceActivity?.data[0]?.values[7]
                const timeRanges = [
                    { startTime: 0, endTime: 2 },
                    { startTime: 2, endTime: 4 },
                    { startTime: 4, endTime: 6 },
                    { startTime: 6, endTime: 8 },
                    { startTime: 8, endTime: 10 },
                    { startTime: 10, endTime: 12 },
                    { startTime: 12, endTime: 14 },
                    { startTime: 14, endTime: 16 },
                    { startTime: 16, endTime: 18 },
                    { startTime: 18, endTime: 20 },
                    { startTime: 20, endTime: 22 },
                    { startTime: 22, endTime: 24 },
                ];
                const online_follower = []
                function displayValuesInTimeRange(startTime, endTime) {
                    const values = maxObject?.value;
                    const timeKeys = values ? Object.keys(values) : [];
                    const timeRange = timeKeys?.filter((key) => {
                        const hour = parseInt(key, 10);
                        return hour >= startTime && hour < endTime;
                    });
                    const timeRangeValues = timeRange.map((key) => values[key]);
                    const obj = {
                        time: '',
                        online_follower: '',
                        date: ''
                    }
                    obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                    obj.online_follower = isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0
                    obj.date = maxObject?.end_time
                    online_follower.push(obj)

                }
                timeRanges.forEach((timeRange) => {
                    displayValuesInTimeRange(timeRange.startTime, timeRange.endTime);
                });
                setactiveOnlineFollowersObject(maxObject)
                setonline_followers(online_follower)
                settotalFollower(overviewPageData.data.followerCount.followers_count)
                setfollower(overviewPageData.data.latestFollowerCount?.data.filter(metric => metric.name === "follower_count"))
                setlatestPost(overviewPageData.data.latestMediaMatrx)
                setreach(overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "reach"))
                setwebsiteClicks(overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "website_clicks"))
                setimpressions(overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "impressions"))
                setprofileVisits(overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "profile_views"))
                const previousDateReachSum = overviewPageData.data.prevMonthInsights?.data.filter(metric => metric.name === "reach")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                const currentDateReach = overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "reach")[0][0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                setreachPercentage(calculatePercentage(currentDateReach, previousDateReachSum))
                const previousDateWebsiteClicks = overviewPageData.data.prevMonthInsights?.data.filter(metric => metric.name === "website_clicks")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                const currentDateWebsiteClicks = overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "website_clicks")[0][0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                setwebsiteclicksPercentage(calculatePercentage(currentDateWebsiteClicks, previousDateWebsiteClicks))
                const previousDateProfillehSum = overviewPageData.data.prevMonthInsights?.data.filter(metric => metric.name === "profile_views")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                const currentDateProfille = overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "profile_views")[0][0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                setprofileVisitPercentage(calculatePercentage(currentDateProfille, previousDateProfillehSum))
                //ranked hashtag code
                const hashtagData = filterHashtags(overviewPageData.data.latestMediaMatrx.data.caption)
                if (hashtagData !== undefined) {
                    const config = {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                    const hashtagLatestPostData = hashtagData?.map(async (data) => {
                        try {
                            const singleHashtagTopPost = await axios.get(`${url}analytics/v2/postHashtagData/get/media/${overviewPageData.data.latestMediaMatrx.data.id}/${workspaceId}/${socialId}/${data.replace("#", '')}`, config)
                            if (singleHashtagTopPost.data.message === "insert successfully.") {
                                return singleHashtagTopPost.data.data
                            } else {
                                return singleHashtagTopPost.data
                            }
                        } catch (error) {
                            return null
                        }
                    })



                    const allHashtagTopPost = await Promise.all(hashtagLatestPostData.filter(data => data !== null))

                    let rankedHashtag = []
                    allHashtagTopPost?.forEach(data => {

                        if (data?.message === undefined) {
                            const index = data?.mediaId?.findIndex(mediaId => mediaId === overviewPageData?.data?.latestMediaMatrx?.data?.id)
                            rankedHashtag.push({ rank: index === -1 ? 'Not found' : index + 1, hashtag: data?.Topic })
                        } else {
                            rankedHashtag.push({ rank: data?.data?.rank, hashtag: data?.data?.hashtag })
                        }
                    })
                    setrankedHashtag(rankedHashtag)
                }
                setoverviewPageLoading(false)



                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: null,
                                    overviewPageData: overviewPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: null,
                            overviewPageData: overviewPageData.data
                        }
                    })
                }
            } catch (error) {
                console.log(error)
                toast.error(error !== "Internal server error" ? "Token is expired!" : "Internal server error!")
                //toast.error('something went wrong!')
                setoverviewPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: error,
                                    overviewPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: error,
                            overviewPageData: null
                        }
                    })
                }
            }
        }
    }

    const getInstagramOverviewPageFilterPeriodData = async () => {
        const { dateArray, socialId, workspaceId, token, startDateForOnlineFollowerMiliSeconds, reportWeeks } = getCurrentAndPreviousDate()
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        setfollowerDate(followerDate)
        setfollowerDateArray(dateArray);
        setreportWeekArray(reportWeeks)

        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                setoverviewPageLoading(true)
                let selectedPeriodDateArray = getLastAsPerPeriodDays(selectedPeriod)
                let selectedPrevPeriodDateArray = getLastAsPerPreviousPeriodDays(selectedPeriod)
                const filterSelectedPeriodPromise = selectedPeriodDateArray.map(async (data, index) => {
                    const startDateMilliseconds = new Date(data.startDate).getTime()
                    const endDateMilliSeconds = new Date(data.endDate).getTime()
                    const startDatePreviousMiliseconds = new Date(selectedPrevPeriodDateArray[index].startDate).getTime()
                    const endDatePreviousMiliseconds = new Date(selectedPrevPeriodDateArray[index].endDate).getTime()

                    const previousDate = new Date(data.endDate)
                    previousDate.setDate(new Date(data.endDate).getDate() - 30)
                    const overviewPageData = await axios.get(`${url}analytics/v2/instagram/overview/filter/period/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}&prevDateSince=${startDatePreviousMiliseconds.toString().slice(0, 10)}&prevDateUntil=${endDatePreviousMiliseconds.toString().slice(0, 10)}&startDateForOnlineFollowerMiliSeconds=${startDateForOnlineFollowerMiliSeconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })

                    return { apiResOfProfileView: overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "profile_views"), apiResOfImpressions: overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "impressions"), apiResOfWebsiteClicks: overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "website_clicks"), apiResOfReachCounts: overviewPageData.data.lastMonthInsights?.data.filter(metric => metric.name === "reach"), apiResOfPreviousProfileView: overviewPageData.data.prevMonthInsights?.data.filter(metric => metric.name === "profile_views"), apiResOfPreviousWebsiteClicks: overviewPageData.data.prevMonthInsights?.data.filter(metric => metric.name === "website_clicks"), apiResOfPreviousReach: overviewPageData.data.prevMonthInsights?.data.filter(metric => metric.name === "reach"), apiResOfPreviousDateImpression: overviewPageData.data.prevMonthInsights?.data.filter(metric => metric.name === "impressions") }


                })

                const allInstagramAnalyticsAsPerDaysFilterData = await Promise.all(filterSelectedPeriodPromise)

                const output = allInstagramAnalyticsAsPerDaysFilterData.reduce((acc, current) => {
                    acc[0].apiResOfProfileView = acc[0].apiResOfProfileView.concat(current.apiResOfProfileView)


                    acc[0].apiResOfImpressions = acc[0].apiResOfImpressions.concat(current.apiResOfImpressions)
                    acc[0].apiResOfWebsiteClicks = acc[0].apiResOfWebsiteClicks.concat(current.apiResOfWebsiteClicks)
                    acc[0].apiResOfReachCounts = acc[0].apiResOfReachCounts.concat(current.apiResOfReachCounts)
                    acc[0].apiResOfPreviousProfileView = acc[0].apiResOfPreviousProfileView.concat(current.apiResOfPreviousProfileView)
                    acc[0].apiResOfPreviousWebsiteClicks = acc[0].apiResOfPreviousWebsiteClicks.concat(current.apiResOfPreviousWebsiteClicks)
                    acc[0].apiResOfPreviousReach = acc[0].apiResOfPreviousReach.concat(current.apiResOfPreviousReach)

                    acc[0].apiResOfPreviousDateImpression = acc[0].apiResOfPreviousDateImpression.concat(current.apiResOfPreviousDateImpression)

                    return acc;
                }, [{ apiResOfProfileView: [], apiResOfImpressions: [], apiResOfWebsiteClicks: [], apiResOfReachCounts: [], apiResOfPreviousProfileView: [], apiResOfPreviousWebsiteClicks: [], apiResOfPreviousReach: [], apiResOfPreviousDateImpression: [] }]);



                const previousDateReachSum = output[0].apiResOfPreviousReach.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                const currentDateReach = output[0].apiResOfReachCounts.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                setreachPercentage(calculatePercentage(currentDateReach, previousDateReachSum))
                const previousDateWebsiteClicks = output[0].apiResOfPreviousWebsiteClicks.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                const currentDateWebsiteClicks = output[0].apiResOfWebsiteClicks.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                setwebsiteclicksPercentage(calculatePercentage(currentDateWebsiteClicks, previousDateWebsiteClicks))
                const previousDateProfileVisit = output[0].apiResOfPreviousProfileView.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                const currentDateProfileVisit = output[0].apiResOfProfileView.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                setprofileVisitPercentage(calculatePercentage(currentDateProfileVisit, previousDateProfileVisit))

                setprofileVisitPercentage((((currentDateProfileVisit - previousDateProfileVisit) / previousDateProfileVisit) * 100).toFixed(1))

                setprofileVisits(output[0].apiResOfProfileView)


                setwebsiteClicks(output[0].apiResOfWebsiteClicks)

                setreach(output[0].apiResOfReachCounts)

                setimpressions(output[0].apiResOfImpressions)
                setoverviewPageLoading(false)
                setoverviewPageFilterLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: false,
                                    overviewPageData: output
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: false,
                            overviewPageData: output
                        }
                    })
                }
            } catch (error) {
                setoverviewPageLoading(false)
                setoverviewPageFilterLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: true,
                                    overviewPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: true,
                            overviewPageData: null
                        }
                    })
                }
            }
        }
    }

    const getInstagramContentPageAnalyticaData = async () => {
        const { dateArray, previousDateStartDateMiliSec, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token } = getCurrentAndPreviousDate()
        setfollowerDateArray(dateArray)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.filter(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        try {

            setcontentPageLoading(true)
            const contentPageData = await axios.get(`${url}analytics/v2/instagram/content/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}&prevDateSince=${previousDateStartDateMiliSec.toString().slice(0, 10)}&prevDateUntil=${startDateMilliseconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })



            setpostDetails(contentPageData.data.allPostDetails)
            setallPostInsights(contentPageData.data.allPostDetails)
            setallStoryDetails(contentPageData.data.allStoryDetails)
            setmentionsData(contentPageData.data.updatedPagePosts)
            settagData(contentPageData.data.tagsData)
            let allCompetitorPostData = []
            contentPageData.data.allPostDetails.forEach(data => {
                const postObj = { caption: data?.caption === undefined ? 'This post has no caption' : data?.caption, id: data.id, timestamp: data.timestamp, socialId: socialId }
                allCompetitorPostData.push(postObj)
            })

            const requestBody = {
                workspaceId: workspaceId,
                token: token,
                media: allCompetitorPostData,
                startDateMilliseconds: startDateMilliseconds,
                endDateMilliSeconds: endDateMilliSeconds
            }
            const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
            const groupedData = res.data.data.reduce((acc, obj) => {
                if (!acc[obj.contentCategory]) {
                    acc[obj.contentCategory] = { contentCategory: obj.contentCategory, socialId: obj.socialId, postId: [] };
                }
                acc[obj.contentCategory].postId.push(obj.postId);
                return acc;
            }, {});

            const result = Object.values(groupedData);

            setstackedDataContent(result)
            const hashtagData = filterHashtagsForContent(contentPageData.data.allPostDetails);
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };


            const hashtagLatestPostData = [].concat(...hashtagData.map(data => data.hashtags)).map(async (data) => {
                try {
                    const singleHashtagTopPost = await axios.get(`${url}analytics/v2/postHashtagData/get/media/${data.id}/${workspaceId}/${socialId}/${data?.replace("#", '')?.toLowerCase()}`, config);
                    if (singleHashtagTopPost.data.message === "insert successfully.") {
                    return singleHashtagTopPost.data.data;
                    }else{
                        return singleHashtagTopPost.data
                    }
                } catch (error) {
                    return null; // Returning null to maintain the structure of Promise.all array
                }
            });


            const allHashtagTopPost = await Promise.all(hashtagLatestPostData);

            let rankedHashtag = [];
            allHashtagTopPost.forEach(data => {
                if (data?.message === undefined) { // Check if data is not null
                    const index = data.mediaId.findIndex(mediaId => contentPageData.data.allPostDetails.map(data => data.id).includes(mediaId));
                    rankedHashtag.push({ rank: index === -1 ? 'Not found' : index + 1, hashtag: data.Topic });
                }else{
                    rankedHashtag.push({ rank: data?.data?.rank, hashtag: data?.data?.hashtag })
                }
            });
            setrankedHashtagContent(rankedHashtag.filter((v, i, a) => a.findIndex(v2 => (v2.hashtag === v.hashtag)) === i));
            setkeywordAdded(contentPageData.data.keywordData.keyword)
            setconfirmKeywordAdded(contentPageData.data.keywordData.keyword)
            setcontentPageLoading(false)



            if (socialMediaAnalyticDataisExits) {
                const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                    if (data.socialId === socialId) {
                        return {
                            ...data,
                            contentPageData: {
                                loading: false,
                                error: null,
                                contentPageData: contentPageData.data
                            }
                        }
                    }
                    return data
                })
                commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
            } else {
                commonSocialMediaContext.concateSocialMediaAnalyticsData({
                    socialId: socialId,
                    selectedPeriod: selectedPeriod,
                    contentPageData: {
                        loading: false,
                        error: null,
                        contentPageData: contentPageData.data
                    }
                })
            }
        } catch (error) {
            setcontentPageLoading(false)
            if (socialMediaAnalyticDataisExits) {
                const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                    if (data.socialId === socialId) {
                        return {
                            ...data,
                            contentPageData: {
                                loading: false,
                                error: error,
                                contentPageData: null
                            }
                        }
                    }
                    return data
                })
                commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
            } else {
                commonSocialMediaContext.concateSocialMediaAnalyticsData({
                    socialId: socialId,
                    selectedPeriod: selectedPeriod,
                    contentPageData: {
                        loading: false,
                        error: error,
                        contentPageData: null
                    }
                })
            }

        }
    }

    const getInstagramAccountPageAnalyticaData = async () => {

        const { dateArray, previousDateStartDateMiliSec, startDateForOnlineFollowerMiliSeconds, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token } = getCurrentAndPreviousDate()
        setfollowerDateArray(dateArray)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.filter(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        try {

            setaccountPageLoading(true)
            const accountPageData = await axios.get(`${url}analytics/v2/instagram/account/${workspaceId}/${socialId}?since=${startDateForOnlineFollowerMiliSeconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}&prevDateSince=${previousDateStartDateMiliSec.toString().slice(0, 10)}&prevDateUntil=${startDateMilliseconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })

            let maxObjectForWeek = null;
            const online_follower_last_week = []
            setapiResOfOnlineFollwer(accountPageData.data.audienceActivity.data[0]?.values)
            accountPageData.data.audienceActivity?.data[0]?.values.slice(7, (accountPageData.data.audienceActivity?.data[0]?.values.length)).forEach(data => {
                if (Object.keys(data.value).length > 0) {
                    maxObjectForWeek = data

                    const objForLastWeek = {
                        date: '',
                        value: []
                    }
                    const timeRangesLastWeek = [
                        { startTime: 0, endTime: 2 },
                        { startTime: 2, endTime: 4 },
                        { startTime: 4, endTime: 6 },
                        { startTime: 6, endTime: 8 },
                        { startTime: 8, endTime: 10 },
                        { startTime: 10, endTime: 12 },
                        { startTime: 12, endTime: 14 },
                        { startTime: 14, endTime: 16 },
                        { startTime: 16, endTime: 18 },
                        { startTime: 18, endTime: 20 },
                        { startTime: 20, endTime: 22 },
                        { startTime: 22, endTime: 24 },
                    ];

                    function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                        const values = maxObjectForWeek?.value;
                        const timeKeys = values ? Object.keys(values) : [];
                        const timeRange = timeKeys?.filter((key) => {
                            const hour = parseInt(key, 10);
                            return hour >= startTime && hour < endTime;
                        });


                        const timeRangeValues = timeRange?.map((key) => values[key]);
                        const obj = {
                            time: '',
                            online_follower: '',
                            date: ''
                        }
                        obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                        obj.date = maxObjectForWeek?.end_time

                        let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        objForLastWeek.date = maxObjectForWeek?.end_time
                        objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0 })

                    }

                    timeRangesLastWeek.forEach((timeRange) => {
                        displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                    });
                    online_follower_last_week.push(objForLastWeek)
                } else {
                    const filterDate = new Date(data?.end_time)
                    const startDateOfOnlineFollower = new Date(filterDate)
                    startDateOfOnlineFollower.setDate(filterDate.getDate() - 7)
                    const replaceDateForStartDate = accountPageData.data.audienceActivity?.data[0]?.values.filter(onlineFolloweData => new Date(onlineFolloweData?.end_time).setHours(0, 0, 0, 0) === startDateOfOnlineFollower.setHours(0, 0, 0, 0))[0]

                    maxObjectForWeek = replaceDateForStartDate

                    const objForLastWeek = {
                        date: '',
                        value: []
                    }
                    const timeRangesLastWeek = [
                        { startTime: 0, endTime: 2 },
                        { startTime: 2, endTime: 4 },
                        { startTime: 4, endTime: 6 },
                        { startTime: 6, endTime: 8 },
                        { startTime: 8, endTime: 10 },
                        { startTime: 10, endTime: 12 },
                        { startTime: 12, endTime: 14 },
                        { startTime: 14, endTime: 16 },
                        { startTime: 16, endTime: 18 },
                        { startTime: 18, endTime: 20 },
                        { startTime: 20, endTime: 22 },
                        { startTime: 22, endTime: 24 },
                    ];

                    function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                        const values = maxObjectForWeek?.value;
                        const timeKeys = values ? Object.keys(values) : [];
                        const timeRange = timeKeys?.filter((key) => {
                            const hour = parseInt(key, 10);
                            return hour >= startTime && hour < endTime;
                        });


                        const timeRangeValues = timeRange?.map((key) => values[key]);
                        const obj = {
                            time: '',
                            online_follower: '',
                            date: ''
                        }
                        obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                        obj.date = maxObjectForWeek?.end_time

                        let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        objForLastWeek.date = maxObjectForWeek?.end_time
                        objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0 })

                    }

                    timeRangesLastWeek.forEach((timeRange) => {
                        displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                    });
                    online_follower_last_week.push(objForLastWeek)
                }



            })
            setonline_followers_week(online_follower_last_week)

            if (accountPageData.data.countryData?.data?.length === 0) {
                setaudience_country([])
            }
            if (accountPageData.data.cityData?.data?.length === 0) {
                setaudience_cityData([])
            }
            if (accountPageData.data.ageGenderData?.data?.length === 0) {
                setinstagramAudienceData([])
            }
            accountPageData.data.countryData?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                const obj = {
                    country: '',
                    value: ''
                }
                obj.country = elements.dimension_values[0]
                obj.value = elements.value
                setaudience_country(prev => {
                    if (!prev) {
                        // If prev is undefined, set it to an empty array
                        return [obj];
                    } else {
                        // If prev is defined, spread its elements and add the new object
                        return [...prev, obj];
                    }
                })
            })
            accountPageData.data.cityData?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                const obj = {
                    country: '',
                    value: ''
                }
                obj.country = elements.dimension_values[0]
                obj.value = elements.value
                setaudience_cityData(prev => {
                    if (!prev) {
                        // If prev is undefined, set it to an empty array
                        return [obj];
                    } else {
                        // If prev is defined, spread its elements and add the new object
                        return [...prev, obj];
                    }
                })
            })

            accountPageData.data.ageGenderData.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                const obj = {
                    label: '',
                    F_count: '',
                    M_count: '',
                    U_count: ''
                }


                obj.label = elements.dimension_values[0]

                if (elements.dimension_values[1] === "F") {
                    obj.F_count = elements.value
                }
                if (elements.dimension_values[1] === "M") {
                    obj.M_count = elements.value
                }

                if (elements.dimension_values[1] === "U") {
                    obj.U_count = elements.value
                }

                setinstagramAudienceData(prev => {
                    if (!prev) {
                        // If prev is undefined, set it to an empty array
                        return [obj];
                    } else {
                        // If prev is defined, spread its elements and add the new object
                        return [...prev, obj];
                    }
                });

            })

            if (socialMediaAnalyticDataisExits) {
                const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                    if (data.socialId === socialId) {
                        return {
                            ...data,
                            accountPageData: {
                                loading: false,
                                error: null,
                                accountPageData: accountPageData.data
                            }
                        }
                    }
                    return data
                })
                commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
            } else {
                commonSocialMediaContext.concateSocialMediaAnalyticsData({
                    socialId: socialId,
                    selectedPeriod: selectedPeriod,
                    accountPageData: {
                        loading: false,
                        error: null,
                        accountPageData: accountPageData.data
                    }
                })
            }
            setaccountPageLoading(false)

        } catch (error) {
            setaccountPageLoading(false)
            if (socialMediaAnalyticDataisExits) {
                const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                    if (data.socialId === socialId) {
                        return {
                            ...data,
                            accountPageData: {
                                loading: false,
                                error: null,
                                accountPageData: null
                            }
                        }
                    }
                    return data
                })
                commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
            } else {
                commonSocialMediaContext.concateSocialMediaAnalyticsData({
                    socialId: socialId,
                    selectedPeriod: selectedPeriod,
                    accountPageData: {
                        loading: false,
                        error: null,
                        accountPageData: null
                    }
                })
            }

        }
    }

    const getInstagramCompetitorPageAnalyticaData = async () => {
        const { dateArray, previousDateStartDateMiliSec, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token } = getCurrentAndPreviousDate()
        setfollowerDateArray(dateArray)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.filter(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        try {

            setcompetitorPageLoading(true)
            const competitorPageData = await axios.get(`${url}analytics/v2/instagram/competitor/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}&prevDateSince=${previousDateStartDateMiliSec.toString().slice(0, 10)}&prevDateUntil=${startDateMilliseconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })


            const lineChartData = competitorPageData.data.allCompetitorData.filter(data => data !== null)?.map(data => {
                return {
                    name: data?.business_discovery?.name,

                    allPost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    reelPost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_product_type === 'REELS' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    imagePost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'IMAGE' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    carouselPost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'CAROUSEL_ALBUM' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    allPostLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    reelPostLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_product_type === 'REELS' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    imageLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'IMAGE' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    carouselLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'CAROUSEL_ALBUM' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    allPostComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    ReelPostComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_product_type === 'REELS' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    ImageComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'IMAGE' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    CarouselComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'CAROUSEL_ALBUM' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                }
            })




            setlineChartData(lineChartData)

            setcompetitorsData(competitorPageData.data.allCompetitorData.filter(data => data !== null))


            // Inside your async function
            const borderColorPromises = competitorPageData.data.allCompetitorData
                .filter(data => data !== null)
                .filter(data => !failedUsernames.includes(data.business_discovery.username))
                .map(data => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.crossOrigin = "anonymous";
                        img.src = data.business_discovery.profile_picture_url;
                        img.onload = () => {
                            const colorThief = new ColorThief();
                            const result = colorThief.getColor(img, 25);
                            resolve({ name: data.business_discovery.username, color: rgbToHex(result[0], result[1], result[2]) });
                        };
                        img.onerror = (error) => {
                            reject(error); // Handle image loading errors
                        };
                    });
                });

            // Wait for all promises to resolve
            const borderColorArray = await Promise.all(borderColorPromises);
            setborderColorArray(borderColorArray)

            setcompetitors(competitorPageData.data.competitors.competitorsData)
            let allCompetitorPostData = []
            competitorPageData.data.allCompetitorData.forEach(data => {
                data.business_discovery.media.data.forEach(media => {
                    const postObj = { caption: media?.caption, id: media.id, timestamp: media.timestamp, socialId: data.business_discovery.id }
                    allCompetitorPostData.push(postObj)
                })
            })

            const requestBody = {
                workspaceId: workspaceId,
                token: token,
                media: allCompetitorPostData,
                startDateMilliseconds: startDateMilliseconds,
                endDateMilliSeconds: endDateMilliSeconds
            }


            const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
            setstackedData(res.data.data)

            if (failedUsernames.length) {
                await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/insertInstagramCompetitors`, {
                    competitorsData: competitorPageData.data.allCompetitorData.filter(data => data !== null).filter(data => !failedUsernames.includes(data.business_discovery.username)),
                    socialMediaAccessToken: '',
                    workspaceId: workspaceId,
                    socialMediaId: socialId
                }, 2, false)
            }
            setcompetitorPageLoading(false)



            if (socialMediaAnalyticDataisExits) {
                const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                    if (data.socialId === socialId) {
                        return {
                            ...data,
                            competitorPageData: {
                                loading: false,
                                error: null,
                                competitorPageData: competitorPageData.data
                            }
                        }
                    }
                    return data
                })
                commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
            } else {
                commonSocialMediaContext.concateSocialMediaAnalyticsData({
                    socialId: socialId,
                    selectedPeriod: selectedPeriod,
                    competitorPageData: {
                        loading: false,
                        error: null,
                        competitorPageData: competitorPageData.data
                    }
                })
            }
        } catch (error) {
            setcompetitorPageLoading(false)
            if (socialMediaAnalyticDataisExits) {
                const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                    if (data.socialId === socialId) {
                        return {
                            ...data,
                            competitorPageData: {
                                loading: false,
                                error: error,
                                competitorPageData: null
                            }
                        }
                    }
                    return data
                })
                commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
            } else {
                commonSocialMediaContext.concateSocialMediaAnalyticsData({
                    socialId: socialId,
                    selectedPeriod: selectedPeriod,
                    competitorPageData: {
                        loading: false,
                        error: error,
                        competitorPageData: null
                    }
                })
            }

        }
    }



    const changeborderColorArray = (color) => {
        setborderColorArray(prev => [...prev, color])
    }

    const handleshowSearchList = (value) => {
        setshowSearchList(value)
    }

    const changesearch = (value) => {
        setsearch(value)
    }

    const changeFilter = (value) => {
        setfilterLoading(value)
    }

    const changeLoadingState = (value) => {
        setloading(value)
    }



    const saveInstagramCompetitors = async (competiorAdded, hide) => {

        try {
            setsaveCompetitorsLoading(true)

            const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);
            const instagramToken = inUser[0].accessToken
            const socialId = inUser[0]?.socialId

            await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/insertInstagramCompetitors`, {
                competitorsData: competitors.map(data => { return { business_discovery: data.business_discovery, id: data.id, hideView: data.hideView } }),
                socialMediaAccessToken: instagramToken,
                workspaceId: userContext?.userData?.activeWorkspace?._id,
                socialMediaId: socialId
            }, 2, false)

            //if (competitors?.length >= competitorsData?.length) {
            //if (competiorAdded) {

            const res = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/getInstagramCompetitors?workspaceId=${userContext?.userData?.activeWorkspace?._id}&socialMediaId=${socialId}`, {}, 1, false)

            setcompetitors(res?.data?.data?.competitorsData ? res?.data?.data?.competitorsData : [])


            const competitorDataDetails = await getCompetitorsData(res?.data?.data?.competitorsData, selectedPeriod !== 30 ? followerDateArray?.slice(-29) : followerDateArray)

            const updateAllCompetitorDetails = userContext.accountsData.map(async (data) => {
                if (data.id === socialId && data.selectedPeriod === "30") {
                    return {
                        ...data,
                        competitorDataDetails: competitorDataDetails.allcompetitorData,
                        competitors: res?.data?.data?.competitorsData ? res?.data?.data?.competitorsData : [],
                        lineChartData: competitorDataDetails.lineChartData,
                        borderColorArray: competitorDataDetails.borderColor,
                    }
                } else {
                    return data
                }
            })

            const promiseOfCompetitor = await Promise.all(updateAllCompetitorDetails);



            userContext.changeaccountsData(promiseOfCompetitor)
            if (competitors?.length > competitorsData?.length) {
                const { startDateMilliseconds, endDateMilliSeconds, token, workspaceId } = getCurrentAndPreviousDate()
                let allCompetitorPostData = []
                const addedPostId = stackedData.map(data => data.postId)

                competitorDataDetails.allcompetitorData.forEach(data => {
                    data.business_discovery.media.data.forEach(media => {
                        const postObj = { caption: media?.caption, id: media.id, timestamp: media.timestamp, socialId: data.business_discovery.id }
                        allCompetitorPostData.push(postObj)
                    })
                })

                const requestBody = {
                    workspaceId: workspaceId,
                    token: token,
                    media: allCompetitorPostData.filter(data => !addedPostId.includes(data.id)),
                    startDateMilliseconds: startDateMilliseconds,
                    endDateMilliSeconds: endDateMilliSeconds
                }


                const resOfCategory = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
                const addNewCompetitorCategory = stackedData.concat(...resOfCategory.data.data)

                setstackedData(addNewCompetitorCategory)
            } else {
                const removedCompetitors = competitors.map(data => data.business_discovery.id)

                setstackedData(stackedData.filter(data => removedCompetitors.includes(data.socialId)))
            }

            //}


            setsaveCompetitorsLoading(false)

            //} else {

            // const allcomepetitor = competitors.map(data => data.business_discovery.id)
            // const allcomepetitorName = competitors.map(data => data.business_discovery.name)

            // const updatedLineChart = lineChartData.filter(data => allcomepetitorName.includes(data.name))

            // const updateCompetitorData = competitorsData.filter(data => allcomepetitor.includes(data.business_discovery.id))
            // const updateCompetitorList = competitors.filter(data => allcomepetitor.includes(data.business_discovery.id))
            // setcompetitors(updateCompetitorList)
            // setcompetitorsData(updateCompetitorData)

            // const updateAllCompetitorDetails = userContext.accountsData.map(async (data) => {


            //     if (data.id === socialId) {
            //         return {
            //             ...data,
            //             competitorDataDetails: updateCompetitorData,
            //             competitors: updateCompetitorList,
            //             lineChartData: updatedLineChart
            //         }
            //     } else {
            //         return data
            //     }
            // })
            // const updateAllCompetitorDetailsPromise = await Promise.all(updateAllCompetitorDetails)

            // userContext.changeaccountsData(updateAllCompetitorDetailsPromise)
            // setsaveCompetitorsLoading(false)

            //}



        } catch (error) {
            toast.error(error !== "Internal server error" ? "Token is expired!" : "Internal server error!")
            return error
        }
    }



    const componentToHex = (c) => {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    const rgbToHex = (r, g, b) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }




    const getCompetitorsData = async (competitorData, dateArray, reverse) => {

        try {

            const currentDate = new Date()
            const startDate = new Date(currentDate);
            startDate.setDate(currentDate.getDate() - 30);
            // Get the milliseconds for the start date
            const startDateMilliseconds = startDate.getTime();
            const endDateMilliSeconds = currentDate.getTime();
            const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);
            const instagramToken = inUser[0].accessToken
            const socialId = inUser[0].socialId
            let workspaceId = localStorage.getItem('organizationId')

            const competitorPromise = competitorData?.map(async (data) => {
                try {
                    if (competitorsData.filter(alreadyCompetitorData => alreadyCompetitorData.business_discovery.id === data.business_discovery.id)[0]?.business_discovery?.biography !== undefined) {

                        return competitorsData.filter(alreadyCompetitorData => alreadyCompetitorData.business_discovery.id === data.business_discovery.id)[0]
                    } else {
                        const singleCompetitorsDataFromCacheBackend = await APICall(`${url}analytics/instagram/getCompetitorDetails`, {
                            "startDateMilliseconds": startDateMilliseconds.toString().slice(0, 10),
                            "endDateMilliSeconds": endDateMilliSeconds.toString().slice(0, 10),
                            "accountType": "instagram",
                            "socialId": socialId,
                            "workspaceId": workspaceId,
                            "business_discovery": data.business_discovery.username

                        }, 2, false)


                        return singleCompetitorsDataFromCacheBackend.data.singleCompetitorsData
                    }


                } catch (error) {
                    // Add failed competitor's username to the array

                    if (error?.response?.data?.error?.message === "Request failed with status code 400") {
                        failedUsernames.push(data.business_discovery.username);
                    }

                    // Explicitly return null or similar to indicate failure
                    return null;
                }

            })




            const allcompetitorData = await Promise.all(competitorPromise)

            allcompetitorData.forEach(data => {
                data.hideView = competitorData.filter(competitorsItem => competitorsItem.business_discovery.username === data.business_discovery.username)[0].hideView
            })

            const lineChartData = allcompetitorData.filter(data => data !== null)?.map(data => {
                return {
                    name: data?.business_discovery?.name,

                    allPost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    reelPost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_product_type === 'REELS' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    imagePost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'IMAGE' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    carouselPost: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'CAROUSEL_ALBUM' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    allPostLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    reelPostLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_product_type === 'REELS' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    imageLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'IMAGE' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    carouselLike: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'CAROUSEL_ALBUM' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.like_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    allPostComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    ReelPostComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_product_type === 'REELS' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    ImageComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'IMAGE' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    CarouselComment: dateArray?.map(date => {
                        const matchingPost = data?.business_discovery?.media?.data?.filter(post => post.media_type === 'CAROUSEL_ALBUM' && `${new Date(post.timestamp).toLocaleString('default', { month: 'short' })}-${String(new Date(post.timestamp).getDate()).padStart(2, '0')}` === date).map(data => data.comments_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                }
            })




            setlineChartData(lineChartData)
            setcompetitorsData(reverse ? allcompetitorData?.filter(data => data !== null).filter(data => !failedUsernames.includes(data.business_discovery.username)).reverse() : allcompetitorData.filter(data => data !== null))


            // Inside your async function
            const borderColorPromises = allcompetitorData
                .filter(data => data !== null)
                .filter(data => !failedUsernames.includes(data.business_discovery.username))
                .map(data => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.crossOrigin = "anonymous";
                        img.src = data.business_discovery.profile_picture_url;
                        img.onload = () => {
                            const colorThief = new ColorThief();
                            const result = colorThief.getColor(img, 25);
                            resolve({ name: data.business_discovery.username, color: rgbToHex(result[0], result[1], result[2]) });
                        };
                        img.onerror = (error) => {
                            reject(error); // Handle image loading errors
                        };
                    });
                });

            // Wait for all promises to resolve
            const borderColorArray = await Promise.all(borderColorPromises);


            setborderColorArray(borderColorArray)

            if (failedUsernames.length) {
                await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/insertInstagramCompetitors`, {
                    competitorsData: reverse ? allcompetitorData?.filter(data => data !== null).filter(data => !failedUsernames.includes(data.business_discovery.username)).reverse() : allcompetitorData.filter(data => data !== null).filter(data => !failedUsernames.includes(data.business_discovery.username)),
                    socialMediaAccessToken: instagramToken,
                    workspaceId: workspaceId,
                    socialMediaId: socialId
                }, 2, false)
            }


            return { lineChartData: lineChartData, allcompetitorData: allcompetitorData, borderColor: borderColorArray }
        } catch (error) {
            toast.error(error !== "Internal server error" ? "Token is expired!" : "Internal server error!")
            return error
        }
    }

    const deleteCompetitors = (competitor) => {
        const filterCompetitors = competitors.filter(data => data.business_discovery.username !== competitor)
        setcompetitors(filterCompetitors)
    }

    const hideCompetitors = async (competitor, status) => {
        competitors.forEach(data => {
            if (data.business_discovery.username === competitor) {
                data.hideView = status
            }
        })
        setcompetitors(competitors)



        competitorsData.forEach(data => {
            if (data.business_discovery.username === competitor) {
                data.hideView = status
            }
        })

        setcompetitorsData(competitorsData)


    }

    const addPageToCompetitors = async (value) => {
        try {
            const competitorObj = {
                ...value,
                hideView: false
            }
            setcompetitors(prev => [competitorObj, ...prev])

            toast('competitor added successfully.')
            setsearch('')
            setshowSearchList(false)

        } catch (error) {

            toast(error?.response?.data?.error?.message === "Invalid user id" ? "competitor not found" : error?.response?.data?.error?.message)
            setsearch('')
        }
    }

    const searchCompetitors = async (value) => {
        try {
            setsearchCompetitorLoading(true)
            const currentDate = new Date()
            const startDate = new Date(currentDate);
            startDate.setDate(currentDate.getDate() - selectedPeriod);
            // Get the milliseconds for the start date
            const startDateMilliseconds = startDate.getTime();
            const endDateMilliSeconds = currentDate.getTime();
            const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);
            const instagramToken = inUser[0].accessToken
            const socialId = inUser[0].socialId
            const res = await APICall(`https://graph.facebook.com/${socialId}?fields=business_discovery.username(${value}){name,username,profile_picture_url}&access_token=${instagramToken}&period=day&since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, {}, 1, false)

            setsearchList([res.data])
            setshowSearchList(true)
            setsearchCompetitorLoading(false)



        } catch (error) {
            setsearchCompetitorLoading(false)
            setshowSearchList(false)
            toast(error?.response?.data?.error?.message === "Invalid user id" ? "competitor not found" : error?.response?.data?.error?.message)
            setsearch('')

        }
    }

    const changeCompetitors = (value) => {
        setcompetitors((prev) => [...prev, value])
    }

    const changeallStoryDetails = (value) => {
        setallStoryDetails(value)
    }

    const changeImpressionPercentage = (value) => {
        setimpressionsPercentage(value)
    }

    const changeallPostInsights = (value) => {
        setallPostInsights(value)
    }

    const changeactiveOnlineFollowerObject = (value) => {
        setactiveOnlineFollowersObject(value)
    }

    const changeonline_followers = (value) => {
        setonline_followers(value)
    }

    const changeImpression = (value) => {
        setimpressions(value)
    }

    const changeInsights = (value) => {
        setinsightsPost(value)
    }

    const changetotalFollower = (value) => {
        settotalFollower(value)
    }

    const changefollowerPercentage = (value) => {
        setfollowerPercentage(value)
    }

    const changereachPercentage = (value) => {
        setreachPercentage(value)
    }

    const changeprofileVisitPercentage = (value) => {
        setprofileVisitPercentage(value)
    }

    const changewebsiteclicksPercentage = (value) => {
        setwebsiteclicksPercentage(value)
    }

    const changefollowerDateArray = (value) => {
        setfollowerDateArray(value)
    }

    const changeSelectedPeriod = (value) => {
        setselectedPeriod(value)
    }

    const changeprofileVisits = (value) => {
        setprofileVisits(value)
    }

    const changewebsiteClicks = (value) => {
        setwebsiteClicks(value)
    }

    const changepostDetails = (value) => {
        setpostDetails(value)
    }

    const changeaudience = (value) => {
        setaudience(value)
    }

    const changereach = (value) => {
        setreach(value)
    }

    const changeFollower = (value) => {
        setfollower(value)
    }

    const changeprofileActivity = (value) => {
        setprofileActivity(value)
    }

    const changeloading = (value) => {
        setloading(value)
    }

    const getInstagramAnalyticsData = async () => {
        try {

            const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);

            const instagramToken = inUser[0]?.accessToken
            const socialId = inUser[0]?.socialId

            const isAccountExits = userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === selectedPeriod.toString())

            if (isAccountExits.length) {
                if (selectedPeriod.toString() === "30") {

                    setloading(true)
                    setfollowerDateArray(isAccountExits[0].dateArray);
                    setlatestPost(isAccountExits[0].latestPostDetails)
                    setpostDetails(isAccountExits[0].listOfPostId.data)
                    setallPostInsights(isAccountExits[0].allPostDetails)
                    setaudience_country(isAccountExits[0].apiResOfCountry)
                    setaudience_cityData(isAccountExits[0].apiResOfCity)
                    setinstagramAudienceData(isAccountExits[0].apiResOfAudience)
                    setreachPercentage((((isAccountExits[0].currentDateReach - isAccountExits[0].previousDateReachSum) / isAccountExits[0].previousDateReachSum) * 100).toFixed(1))
                    setwebsiteclicksPercentage((((isAccountExits[0].currentDateWebsiteClicks - isAccountExits[0].previousDateWebsiteClicks) / isAccountExits[0].previousDateWebsiteClicks) * 100).toFixed(1))
                    settotalFollower(isAccountExits[0].apiResOfTotalFollowers.followers_count)
                    setprofileVisitPercentage((((isAccountExits[0].currentDateProfileVisit - isAccountExits[0].previousDateProfileVisit) / isAccountExits[0].previousDateProfileVisit) * 100).toFixed(1))
                    setprofileVisits(isAccountExits[0].apiResOfProfileView)
                    setonline_followers_week(isAccountExits[0].online_follower_last_week)
                    setactiveOnlineFollowersObject(isAccountExits[0].activeOnlineFollowersObject)
                    setonline_followers(isAccountExits[0].online_follower)

                    setfollower(isAccountExits[0].apiResOfFollowerCount)
                    setwebsiteClicks(isAccountExits[0].apiResOfWebsiteClicks)
                    setreach(isAccountExits[0].apiResOfReachCounts)
                    setimpressions(isAccountExits[0].apiResOfImpressions)
                    setimpressionsPercentage((((isAccountExits[0].currentDateImpressionsData - isAccountExits[0].previousDateImpressionData) / isAccountExits[0].previousDateImpressionData) * 100).toFixed(1))
                    setallStoryDetails(isAccountExits[0].storyDataFromDB.data.data)
                    setcompetitors(isAccountExits[0].competitors)
                    setcompetitorsData(isAccountExits[0].competitorDataDetails?.filter(data => data !== null))
                    setlineChartData(isAccountExits[0].lineChartData?.filter(data => data !== null))
                    setapiResOfOnlineFollwer(isAccountExits[0].apiResOfOnlineFollwer)

                    setborderColorArray(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].borderColorArray)
                    setloading(false)
                } else {
                    setfollowerDate(isAccountExits[0].followerDate)
                    setfollowerDateArray(isAccountExits[0].dateArray);
                    setpostDetails(isAccountExits[0].postDetails)
                    setallPostInsights(isAccountExits[0].allPostInsights)
                    setreachPercentage((((isAccountExits[0].currentDateReach - isAccountExits[0].previousDateReachSum) / isAccountExits[0].previousDateReachSum) * 100).toFixed(1))
                    setwebsiteclicksPercentage((((isAccountExits[0].currentDateWebsiteClicks - isAccountExits[0].previousDateWebsiteClicks) / isAccountExits[0].previousDateWebsiteClicks) * 100).toFixed(1))
                    setprofileVisitPercentage((((isAccountExits[0].currentDateProfileVisit - isAccountExits[0].previousDateProfileVisit) / isAccountExits[0].previousDateProfileVisit) * 100).toFixed(1))
                    settotalFollower(isAccountExits[0].totalFollower)
                    setprofileVisits(isAccountExits[0].profileVisits)
                    setwebsiteClicks(isAccountExits[0].websiteClicks)
                    setreach(isAccountExits[0].reach)
                    setimpressions(isAccountExits[0].impressions)
                    setimpressionsPercentage((((isAccountExits[0].currentDateImpressionsData - isAccountExits[0].previousDateImpressionData) / isAccountExits[0].previousDateImpressionData) * 100).toFixed(1))
                    setallStoryDetails(isAccountExits[0].allStoryDetails.data.data)
                    setcompetitorsData(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].competitorDataDetails?.filter(data => data !== null))
                    setlineChartData(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].lineChartData?.filter(data => data !== null))

                    setborderColorArray(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].borderColorArray)
                    setstopStoryPagination(isAccountExits[0].storyPagination)
                    setloading(false)
                }
            } else {
                if (loading === false) return

                setloading(true)

                const currentDate = new Date();

                // Calculate the date 29 days ago
                const startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - selectedPeriod);



                // Get the milliseconds for the start date
                const startDateMilliseconds = startDate.getTime();
                const endDateMilliSeconds = currentDate.getTime();



                const startDateOfOnlineFollower = new Date(currentDate)
                startDateOfOnlineFollower.setDate(currentDate.getDate() - 14)

                const startDateForOnlineFollowerMiliSeconds = startDateOfOnlineFollower.getTime()

                const previousDate = new Date(startDate)
                previousDate.setDate(startDate.getDate() - selectedPeriod)
                const previousDateStartDateMiliSec = previousDate.getTime()
                // Initialize an array to store the dates
                const dateArray = [];
                const filterDateArray = [];
                // Calculate and insert 6 dates into the array
                for (let i = 0; i < (selectedPeriod); i++) {
                    const newDate = new Date(startDate);
                    newDate.setDate(startDate.getDate() + i); // Adjust the increment as needed
                    const monthShortName = newDate.toLocaleString('default', { month: 'short' });
                    const day = String(newDate.getDate()).padStart(2, '0');
                    dateArray.push(`${monthShortName}-${day}`);
                    const dateInMilliseconds = newDate.setHours(0, 0, 0, 0)
                    filterDateArray.push(dateInMilliseconds)
                }

                setfollowerDateArray(dateArray);
                setloading(true)

                const workspaceId = localStorage.getItem('organizationId')
                const getInstagramAnalyticsDataFromBackend = await axios.post(`${url}analytics/instagram/getInstagramAnalyticsData`, {
                    "startDateMilliseconds": startDateMilliseconds.toString().slice(0, 10),
                    "endDateMilliSeconds": endDateMilliSeconds.toString().slice(0, 10),
                    "previousDateStartDateMiliSec": previousDateStartDateMiliSec.toString().slice(0, 10),
                    "startDateForOnlineFollowerMiliSeconds": startDateForOnlineFollowerMiliSeconds.toString().slice(0, 10),
                    "endDateMiliSecondForonline": endDateMilliSeconds.toString().slice(0, 10),
                    "accountType": "instagram",
                    "socialId": socialId,
                    "workspaceId": workspaceId
                })

                if (variables.leftSideBarState === socialId) {



                    const listOfPostId = getInstagramAnalyticsDataFromBackend.data.listOfPostId
                    setlatestPost(getInstagramAnalyticsDataFromBackend.data.latestPostDetails[0])
                    setpostDetails(listOfPostId.data)

                    setallPostInsights(getInstagramAnalyticsDataFromBackend.data.allPostDetails)


                    setinsightsPost(getInstagramAnalyticsDataFromBackend.data.latestPostInsightsDetails)


                    const currentDateOverviewStastics = getInstagramAnalyticsDataFromBackend.data.currentDateOverviewStastics;
                    const previousDateOverviewStastics = getInstagramAnalyticsDataFromBackend.data.previousDateOverviewStastics;
                    const apiResOfAudience = getInstagramAnalyticsDataFromBackend.data.apiResOfAudience;
                    const apiResOfOnlineFollwer = getInstagramAnalyticsDataFromBackend.data.apiResOfOnlineFollwer;
                    const apiResOfTotalFollowers = getInstagramAnalyticsDataFromBackend.data.apiResOfTotalFollowers;
                    const apiResOfCountry = getInstagramAnalyticsDataFromBackend.data.apiResOfCountry;
                    const apiResOfCity = getInstagramAnalyticsDataFromBackend.data.apiResOfCity;


                    if (apiResOfCountry?.data?.length === 0) {
                        setaudience_country([])
                    }
                    if (apiResOfCity?.data?.length === 0) {
                        setaudience_cityData([])
                    }
                    if (apiResOfAudience?.data?.length === 0) {
                        setinstagramAudienceData([])
                    }
                    apiResOfCountry?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                        const obj = {
                            country: '',
                            value: ''
                        }
                        obj.country = elements.dimension_values[0]
                        obj.value = elements.value
                        setaudience_country(prev => {
                            if (!prev) {
                                // If prev is undefined, set it to an empty array
                                return [obj];
                            } else {
                                // If prev is defined, spread its elements and add the new object
                                return [...prev, obj];
                            }
                        })
                    })
                    apiResOfCity?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                        const obj = {
                            country: '',
                            value: ''
                        }
                        obj.country = elements.dimension_values[0]
                        obj.value = elements.value
                        setaudience_cityData(prev => {
                            if (!prev) {
                                // If prev is undefined, set it to an empty array
                                return [obj];
                            } else {
                                // If prev is defined, spread its elements and add the new object
                                return [...prev, obj];
                            }
                        })
                    })

                    apiResOfAudience?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                        const obj = {
                            label: '',
                            F_count: '',
                            M_count: '',
                            U_count: ''
                        }


                        obj.label = elements.dimension_values[0]

                        if (elements.dimension_values[1] === "F") {
                            obj.F_count = elements.value
                        }
                        if (elements.dimension_values[1] === "M") {
                            obj.M_count = elements.value
                        }

                        if (elements.dimension_values[1] === "U") {
                            obj.U_count = elements.value
                        }

                        setinstagramAudienceData(prev => {
                            if (!prev) {
                                // If prev is undefined, set it to an empty array
                                return [obj];
                            } else {
                                // If prev is defined, spread its elements and add the new object
                                return [...prev, obj];
                            }
                        });

                    })


                    const previousDateReachSum = previousDateOverviewStastics?.data.filter(metric => metric.name === "reach")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                    const currentDateReach = currentDateOverviewStastics?.data.filter(metric => metric.name === "reach")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                    setreachPercentage((((currentDateReach - previousDateReachSum) / previousDateReachSum) * 100).toFixed(1))

                    const previousDateWebsiteClicks = previousDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                    const currentDateWebsiteClicks = currentDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                    setwebsiteclicksPercentage((((currentDateWebsiteClicks - previousDateWebsiteClicks) / previousDateWebsiteClicks) * 100).toFixed(1))

                    const previousDateProfileVisit = previousDateOverviewStastics?.data.filter(metric => metric.name === "profile_views")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)
                    const currentDateProfileVisit = currentDateOverviewStastics?.data.filter(metric => metric.name === "profile_views")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)

                    settotalFollower(apiResOfTotalFollowers.followers_count)

                    setprofileVisitPercentage((((currentDateProfileVisit - previousDateProfileVisit) / previousDateProfileVisit) * 100).toFixed(1))


                    setprofileVisits(currentDateOverviewStastics?.data.filter(metric => metric.name === "profile_views"))

                    let maxObjectForWeek = null;
                    const online_follower_last_week = []
                    setapiResOfOnlineFollwer(apiResOfOnlineFollwer?.data[0]?.values)

                    apiResOfOnlineFollwer?.data[0]?.values.slice(7, (apiResOfOnlineFollwer?.data[0]?.values.length)).forEach(data => {
                        if (Object.keys(data.value).length > 0) {
                            maxObjectForWeek = data

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = values ? Object.keys(values) : [];
                                const timeRange = timeKeys?.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek?.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek?.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0 })

                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        } else {
                            const filterDate = new Date(data?.end_time)
                            const startDateOfOnlineFollower = new Date(filterDate)
                            startDateOfOnlineFollower.setDate(filterDate.getDate() - 7)
                            const replaceDateForStartDate = apiResOfOnlineFollwer?.data[0]?.values.filter(onlineFolloweData => new Date(onlineFolloweData?.end_time).setHours(0, 0, 0, 0) === startDateOfOnlineFollower.setHours(0, 0, 0, 0))[0]

                            maxObjectForWeek = replaceDateForStartDate

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = values ? Object.keys(values) : [];
                                const timeRange = timeKeys?.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek?.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek?.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0 })

                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        }



                    })
                    setonline_followers_week(online_follower_last_week)




                    let maxObject = null;

                    maxObject = apiResOfOnlineFollwer?.data[0]?.values[7]


                    const timeRanges = [
                        { startTime: 0, endTime: 2 },
                        { startTime: 2, endTime: 4 },
                        { startTime: 4, endTime: 6 },
                        { startTime: 6, endTime: 8 },
                        { startTime: 8, endTime: 10 },
                        { startTime: 10, endTime: 12 },
                        { startTime: 12, endTime: 14 },
                        { startTime: 14, endTime: 16 },
                        { startTime: 16, endTime: 18 },
                        { startTime: 18, endTime: 20 },
                        { startTime: 20, endTime: 22 },
                        { startTime: 22, endTime: 24 },
                    ];
                    const online_follower = []
                    function displayValuesInTimeRange(startTime, endTime) {
                        const values = maxObject?.value;
                        const timeKeys = values ? Object.keys(values) : [];
                        const timeRange = timeKeys?.filter((key) => {
                            const hour = parseInt(key, 10);
                            return hour >= startTime && hour < endTime;
                        });


                        const timeRangeValues = timeRange.map((key) => values[key]);
                        const obj = {
                            time: '',
                            online_follower: '',
                            date: ''
                        }
                        obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        obj.online_follower = isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0
                        obj.date = maxObject?.end_time
                        online_follower.push(obj)

                    }

                    timeRanges.forEach((timeRange) => {
                        displayValuesInTimeRange(timeRange.startTime, timeRange.endTime);
                    });


                    setactiveOnlineFollowersObject(maxObject)




                    setonline_followers(online_follower)

                    setfollower(currentDateOverviewStastics?.data.filter(metric => metric.name === "follower_count"))

                    setwebsiteClicks(currentDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks"))

                    setreach(currentDateOverviewStastics?.data.filter(metric => metric.name === "reach"))



                    setimpressions(currentDateOverviewStastics?.data.filter(metric => metric.name === "impressions"))

                    const currentDateImpressionsData = currentDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0]?.values?.map(data => data.value).reduce((totalImpression, data) => { return totalImpression += data })
                    const previousDateImpressionData = previousDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0]?.values?.map(data => data.value).reduce((totalImpression, data) => { return totalImpression += data })
                    setimpressionsPercentage((((currentDateImpressionsData - previousDateImpressionData) / previousDateImpressionData) * 100).toFixed(1))
                    const listOfStoryId = getInstagramAnalyticsDataFromBackend.data.listOfStoryId

                    if (listOfStoryId?.data.filter(data => data !== null)?.length) {
                        const storyDetailsPromises = listOfStoryId?.data?.reverse()?.map(async data => {
                            try {
                                const storyDetails = await APICall(`https://graph.facebook.com/v15.0/${data.id}/insights?metric=impressions,shares,profile_visits,follows,reach,replies,total_interactions,navigation,saved,profile_activity&access_token=${instagramToken}&period=day&since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, {}, 1, false)
                                const storyDetailsOfExitsForward = await APICall(`https://graph.facebook.com/v15.0/${data.id}/insights?metric=navigation&breakdown=story_navigation_action_type&access_token=${instagramToken}&period=day&since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, {}, 1, false)

                                return { data: data, res: storyDetails.data, storyDetailsOfExitsForward: storyDetailsOfExitsForward.data }
                            } catch (error) {
                                return
                            }


                        })
                        const allStoryDetails = await Promise.all(storyDetailsPromises)
                        const datewiseStory = []
                        allStoryDetails.filter(data => data !== undefined).map(data => data.data.timestamp.split("T")[0]).filter((v, i, a) => a.findIndex(v2 => (v2 === v)) === i).forEach(data => {
                            const filterStories = allStoryDetails.filter(data => data !== undefined).filter(stories => { return stories.data.timestamp.split("T")[0] === data })
                            const obj = {
                                date: data,
                                story: filterStories
                            }
                            datewiseStory.push(obj)

                        })
                        const storyInsertData = []
                        datewiseStory.forEach(instagramStoryData => {

                            const obj = {
                                date: '',
                                storyData: [],
                                socialMediaAccessToken: '',
                                socialMediaId: ''
                            }
                            obj.date = instagramStoryData.date


                            const filterStories = instagramStoryData.story.filter(stories => { return stories.data.timestamp.split("T")[0] === instagramStoryData.date }).map(data => {

                                return {
                                    thumbnail_url: data.data.media_type === "IMAGE" ? data.data.media_url : data.data.thumbnail_url,
                                    media_type: data.data.media_type,
                                    story_id: data.data.id,
                                    exits: data.storyDetailsOfExitsForward.data[0].total_value.breakdowns[0].results.filter(data => data.dimension_values[0] === "tap_exit")[0],
                                    taps_forward: data.storyDetailsOfExitsForward.data[0].total_value.breakdowns[0].results.filter(data => data.dimension_values[0] === "tap_forward")[0],
                                    taps_back: data.storyDetailsOfExitsForward.data[0].total_value.breakdowns[0].results.filter(data => data.dimension_values[0] === "tap_exit")[0],
                                    impressions: data.res.data.filter(data => data.name === "impressions")[0],
                                    engagement: data.res.data.filter(data => data.name === "total_interactions")[0],
                                    reach: data.res.data.filter(data => data.name === "reach")[0],
                                    replies: data.res.data.filter(data => data.name === "replies")[0],
                                    saved: data.res.data.filter(data => data.name === "saved")[0],
                                    shares: data.res.data.filter(data => data.name === "shares")[0],
                                    profile_visits: data.res.data.filter(data => data.name === "profile_visits")[0],
                                    follows: data.res.data.filter(data => data.name === "follows")[0],
                                    total_interactions: data.res.data.filter(data => data.name === "total_interactions")[0],
                                    navigation: data.res.data.filter(data => data.name === "navigation")[0],
                                    profile_activity: data.res.data.filter(data => data.name === "profile_activity")[0],
                                    timestamp: data.data.timestamp
                                }
                            })


                            obj.storyData = filterStories
                            obj.socialMediaAccessToken = `${instagramToken}`
                            obj.socialMediaId = socialId
                            storyInsertData.push(obj)
                        })




                        try {

                            const storyPromise = storyInsertData.map(async (data) => {
                                const singleStoryData = await APICall(`${url}analytics/instagram/insertInstagramStory`, {
                                    story: storyInsertData
                                }, 2, false)

                                return singleStoryData
                            })
                            await Promise.all(storyPromise).then(async (result) => {


                            })

                        } catch (error) {


                        }



                    }
                    const storyDataFromDB = await APICall(`${url}analytics/instagram/getInstagramStory?page=1&limit=5&socialMediaId=${socialId}&since=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}&until=${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate() + 1}`, {}, 1, false)

                    setstoryPage(prev => prev + 1)

                    storyDataFromDB?.data?.data?.forEach(data => {
                        const filterStories = data.storyData.filter(stories => { return stories.timestamp.split("T")[0] === data.date.split("T")[0] })
                        data.story = filterStories
                    })
                    setallStoryDetails(storyDataFromDB.data.data)

                    setstopStoryPagination(storyDataFromDB.data.pagination)



                    let competitorDataDetails;
                    const res = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/getInstagramCompetitors?workspaceId=${userContext?.userData?.activeWorkspace?._id}&socialMediaId=${socialId}`, {}, 1, false)

                    if (res?.data?.data?.length === 0 || res?.data?.data === null) {
                        await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/insertInstagramCompetitors`, {
                            competitorsData: [{ business_discovery: { name: inUser.map(data => data.name)[0], username: inUser.map(data => data.name)[0], profile_picture_url: inUser.map(data => data.profileURL)[0], id: inUser.map(data => data.socialId)[0], hideView: false } }],
                            socialMediaAccessToken: instagramToken,
                            workspaceId: userContext?.userData?.activeWorkspace?._id,
                            socialMediaId: socialId
                        }, 2, false)

                        const res = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/getInstagramCompetitors?workspaceId=${userContext?.userData?.activeWorkspace?._id}&socialMediaId=${socialId}`, {}, 1, false)
                        competitorDataDetails = await getCompetitorsData(res?.data?.data?.competitorsData, dateArray)
                        setcompetitors(res?.data?.data?.competitorsData ? res?.data?.data?.competitorsData?.filter(data => !failedUsernames.includes(data.business_discovery.username))?.reverse() : [])

                        setloading(false)
                    } else {
                        competitorDataDetails = await getCompetitorsData(res?.data?.data?.competitorsData, dateArray, true)
                        setcompetitors(res?.data?.data?.competitorsData ? res?.data?.data?.competitorsData?.filter(data => !failedUsernames.includes(data.business_discovery.username))?.reverse() : [])


                        setloading(false)
                    }

                    const obj = {
                        id: socialId,
                        selectedPeriod: selectedPeriod.toString(),
                        dateArray: dateArray,
                        latestPostDetails: getInstagramAnalyticsDataFromBackend.data.latestPostDetails[0],
                        listOfPostId: listOfPostId,
                        allPostDetails: getInstagramAnalyticsDataFromBackend.data.allPostDetails,
                        apiResOfCountry: apiResOfCountry?.data[0]?.total_value?.breakdowns[0]?.results?.map(elements => {
                            const obj = {
                                country: '',
                                value: ''
                            }
                            obj.country = elements.dimension_values[0]
                            obj.value = elements.value
                            return obj
                        }),
                        apiResOfCity: apiResOfCity?.data[0]?.total_value?.breakdowns[0]?.results?.map(elements => {
                            const obj = {
                                country: '',
                                value: ''
                            }
                            obj.country = elements.dimension_values[0]
                            obj.value = elements.value
                            return obj
                        }),
                        apiResOfAudience: apiResOfAudience?.data[0]?.total_value?.breakdowns[0]?.results?.map(elements => {
                            const obj = {
                                label: '',
                                F_count: '',
                                M_count: ''
                            }


                            obj.label = elements.dimension_values[0]

                            if (elements.dimension_values[1] === "F") {
                                obj.F_count = elements.value
                            }
                            if (elements.dimension_values[1] === "M") {
                                obj.M_count = elements.value
                            }

                            return obj

                        }),
                        previousDateReachSum: previousDateOverviewStastics?.data.filter(metric => metric.name === "reach")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        currentDateReach: currentDateOverviewStastics?.data.filter(metric => metric.name === "reach")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        previousDateWebsiteClicks: previousDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        currentDateWebsiteClicks: currentDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        previousDateProfileVisit: previousDateOverviewStastics?.data.filter(metric => metric.name === "profile_views")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        currentDateProfileVisit: currentDateOverviewStastics?.data.filter(metric => metric.name === "profile_views")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        apiResOfTotalFollowers: apiResOfTotalFollowers,
                        apiResOfProfileView: currentDateOverviewStastics?.data.filter(metric => metric.name === "profile_views"),
                        online_follower_last_week: online_follower_last_week,
                        activeOnlineFollowersObject: maxObject,
                        online_follower: online_follower,
                        apiResOfFollowerCount: currentDateOverviewStastics?.data.filter(metric => metric.name === "follower_count"),
                        apiResOfWebsiteClicks: currentDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks"),
                        apiResOfReachCounts: currentDateOverviewStastics?.data.filter(metric => metric.name === "reach"),
                        apiResOfImpressions: currentDateOverviewStastics?.data.filter(metric => metric.name === "impressions"),
                        apiResOfPreviousDateImpression: previousDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0],
                        currentDateImpressionsData: currentDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0]?.values?.map(data => data.value).reduce((totalImpression, data) => { return totalImpression += data }),
                        previousDateImpressionData: previousDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0]?.values?.map(data => data.value).reduce((totalImpression, data) => { return totalImpression += data }),
                        storyDataFromDB: storyDataFromDB,
                        competitors: res?.data?.data?.competitorsData ? res?.data?.data?.competitorsData?.reverse() : [],
                        competitorDataDetails: competitorDataDetails.allcompetitorData,
                        lineChartData: competitorDataDetails.lineChartData,
                        apiResOfOnlineFollwer: apiResOfOnlineFollwer?.data[0]?.values,
                        borderColorArray: competitorDataDetails.borderColor,
                        storyPagination: storyDataFromDB.data.pagination
                    }



                    userContext.handleaccountsData(obj)
                    setloading(false)
                } else {
                    const listOfPostId = getInstagramAnalyticsDataFromBackend.data.listOfPostId


                    const currentDateOverviewStastics = getInstagramAnalyticsDataFromBackend.data.currentDateOverviewStastics;
                    const previousDateOverviewStastics = getInstagramAnalyticsDataFromBackend.data.previousDateOverviewStastics;
                    const apiResOfAudience = getInstagramAnalyticsDataFromBackend.data.apiResOfAudience;
                    const apiResOfOnlineFollwer = getInstagramAnalyticsDataFromBackend.data.apiResOfOnlineFollwer;
                    const apiResOfTotalFollowers = getInstagramAnalyticsDataFromBackend.data.apiResOfTotalFollowers;
                    const apiResOfCountry = getInstagramAnalyticsDataFromBackend.data.apiResOfCountry;
                    const apiResOfCity = getInstagramAnalyticsDataFromBackend.data.apiResOfCity;


                    if (apiResOfCountry?.data?.length === 0) {

                    }
                    if (apiResOfCity?.data?.length === 0) {

                    }
                    if (apiResOfAudience?.data?.length === 0) {

                    }
                    apiResOfCountry?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                        const obj = {
                            country: '',
                            value: ''
                        }
                        obj.country = elements.dimension_values[0]
                        obj.value = elements.value

                    })
                    apiResOfCity?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                        const obj = {
                            country: '',
                            value: ''
                        }
                        obj.country = elements.dimension_values[0]
                        obj.value = elements.value

                    })

                    apiResOfAudience?.data[0]?.total_value?.breakdowns[0]?.results?.forEach(elements => {
                        const obj = {
                            label: '',
                            F_count: '',
                            M_count: '',
                            U_count: ''
                        }


                        obj.label = elements.dimension_values[0]

                        if (elements.dimension_values[1] === "F") {
                            obj.F_count = elements.value
                        }
                        if (elements.dimension_values[1] === "M") {
                            obj.M_count = elements.value
                        }

                        if (elements.dimension_values[1] === "U") {
                            obj.U_count = elements.value
                        }



                    })





                    let maxObjectForWeek = null;
                    const online_follower_last_week = []

                    apiResOfOnlineFollwer?.data[0]?.values.slice(7, (apiResOfOnlineFollwer?.data[0]?.values.length)).forEach(data => {
                        if (Object.keys(data.value).length > 0) {
                            maxObjectForWeek = data

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = values ? Object.keys(values) : [];
                                const timeRange = timeKeys?.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek?.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek?.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0 })


                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        } else {
                            const filterDate = new Date(data?.end_time)
                            const startDateOfOnlineFollower = new Date(filterDate)
                            startDateOfOnlineFollower.setDate(filterDate.getDate() - 7)
                            const replaceDateForStartDate = apiResOfOnlineFollwer?.data[0]?.values.filter(onlineFolloweData => new Date(onlineFolloweData?.end_time).setHours(0, 0, 0, 0) === startDateOfOnlineFollower.setHours(0, 0, 0, 0))[0]

                            maxObjectForWeek = replaceDateForStartDate

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = values ? Object.keys(values) : [];
                                const timeRange = timeKeys?.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek?.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek?.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0 })

                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        }



                    })





                    let maxObject = null;

                    maxObject = apiResOfOnlineFollwer?.data[0]?.values[7]


                    const timeRanges = [
                        { startTime: 0, endTime: 2 },
                        { startTime: 2, endTime: 4 },
                        { startTime: 4, endTime: 6 },
                        { startTime: 6, endTime: 8 },
                        { startTime: 8, endTime: 10 },
                        { startTime: 10, endTime: 12 },
                        { startTime: 12, endTime: 14 },
                        { startTime: 14, endTime: 16 },
                        { startTime: 16, endTime: 18 },
                        { startTime: 18, endTime: 20 },
                        { startTime: 20, endTime: 22 },
                        { startTime: 22, endTime: 24 },
                    ];
                    const online_follower = []
                    function displayValuesInTimeRange(startTime, endTime) {
                        const values = maxObject?.value;
                        const timeKeys = values ? Object.keys(values) : [];
                        const timeRange = timeKeys?.filter((key) => {
                            const hour = parseInt(key, 10);
                            return hour >= startTime && hour < endTime;
                        });


                        const timeRangeValues = timeRange.map((key) => values[key]);
                        const obj = {
                            time: '',
                            online_follower: '',
                            date: ''
                        }
                        obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        obj.online_follower = isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues.length).toFixed(0) : 0
                        obj.date = maxObject?.end_time
                        online_follower.push(obj)

                    }

                    timeRanges.forEach((timeRange) => {
                        displayValuesInTimeRange(timeRange.startTime, timeRange.endTime);
                    });










                    const listOfStoryId = getInstagramAnalyticsDataFromBackend.data.listOfStoryId

                    if (listOfStoryId?.data.filter(data => data !== null)?.length) {
                        const storyDetailsPromises = listOfStoryId?.data?.reverse()?.map(async data => {
                            try {
                                const storyDetails = await APICall(`https://graph.facebook.com/v15.0/${data.id}/insights?metric=impressions,shares,profile_visits,follows,reach,replies,total_interactions,navigation,saved,profile_activity&access_token=${instagramToken}&period=day&since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, {}, 1, false)
                                const storyDetailsOfExitsForward = await APICall(`https://graph.facebook.com/v15.0/${data.id}/insights?metric=navigation&breakdown=story_navigation_action_type&access_token=${instagramToken}&period=day&since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, {}, 1, false)

                                return { data: data, res: storyDetails.data, storyDetailsOfExitsForward: storyDetailsOfExitsForward.data }
                            } catch (error) {
                                return
                            }


                        })
                        const allStoryDetails = await Promise.all(storyDetailsPromises)
                        const datewiseStory = []
                        allStoryDetails.filter(data => data !== undefined).map(data => data.data.timestamp.split("T")[0]).filter((v, i, a) => a.findIndex(v2 => (v2 === v)) === i).forEach(data => {
                            const filterStories = allStoryDetails.filter(data => data !== undefined).filter(stories => { return stories.data.timestamp.split("T")[0] === data })
                            const obj = {
                                date: data,
                                story: filterStories
                            }
                            datewiseStory.push(obj)

                        })
                        const storyInsertData = []
                        datewiseStory.forEach(instagramStoryData => {

                            const obj = {
                                date: '',
                                storyData: [],
                                socialMediaAccessToken: '',
                                socialMediaId: ''
                            }
                            obj.date = instagramStoryData.date


                            const filterStories = instagramStoryData.story.filter(stories => { return stories.data.timestamp.split("T")[0] === instagramStoryData.date }).map(data => {

                                return {
                                    thumbnail_url: data.data.media_type === "IMAGE" ? data.data.media_url : data.data.thumbnail_url,
                                    media_type: data.data.media_type,
                                    story_id: data.data.id,
                                    exits: data.storyDetailsOfExitsForward.data[0].total_value.breakdowns[0].results.filter(data => data.dimension_values[0] === "tap_exit")[0],
                                    taps_forward: data.storyDetailsOfExitsForward.data[0].total_value.breakdowns[0].results.filter(data => data.dimension_values[0] === "tap_forward")[0],
                                    taps_back: data.storyDetailsOfExitsForward.data[0].total_value.breakdowns[0].results.filter(data => data.dimension_values[0] === "tap_exit")[0],
                                    impressions: data.res.data.filter(data => data.name === "impressions")[0],
                                    engagement: data.res.data.filter(data => data.name === "total_interactions")[0],
                                    reach: data.res.data.filter(data => data.name === "reach")[0],
                                    replies: data.res.data.filter(data => data.name === "replies")[0],
                                    saved: data.res.data.filter(data => data.name === "saved")[0],
                                    shares: data.res.data.filter(data => data.name === "shares")[0],
                                    profile_visits: data.res.data.filter(data => data.name === "profile_visits")[0],
                                    follows: data.res.data.filter(data => data.name === "follows")[0],
                                    total_interactions: data.res.data.filter(data => data.name === "total_interactions")[0],
                                    navigation: data.res.data.filter(data => data.name === "navigation")[0],
                                    profile_activity: data.res.data.filter(data => data.name === "profile_activity")[0],
                                    timestamp: data.data.timestamp
                                }
                            })


                            obj.storyData = filterStories
                            obj.socialMediaAccessToken = `${instagramToken}`
                            obj.socialMediaId = socialId
                            storyInsertData.push(obj)
                        })




                        try {

                            const storyPromise = storyInsertData.map(async (data) => {
                                const singleStoryData = await APICall(`${url}analytics/instagram/insertInstagramStory`, {
                                    story: storyInsertData
                                }, 2, false)

                                return singleStoryData
                            })
                            await Promise.all(storyPromise).then(async (result) => {


                            })

                        } catch (error) {


                        }



                    }
                    const storyDataFromDB = await APICall(`${url}analytics/instagram/getInstagramStory?page=1&limit=5&socialMediaId=${socialId}&since=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}&until=${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate() + 1}`, {}, 1, false)



                    storyDataFromDB?.data?.data?.forEach(data => {
                        const filterStories = data.storyData.filter(stories => { return stories.timestamp.split("T")[0] === data.date.split("T")[0] })
                        data.story = filterStories
                    })



                    let competitorDataDetails;
                    const res = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/getInstagramCompetitors?workspaceId=${userContext?.userData?.activeWorkspace?._id}&socialMediaId=${socialId}`, {}, 1, false)

                    if (res?.data?.data?.length === 0 || res?.data?.data === null) {
                        await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/insertInstagramCompetitors`, {
                            competitorsData: [{ business_discovery: { name: inUser.map(data => data.name)[0], username: inUser.map(data => data.name)[0], profile_picture_url: inUser.map(data => data.profileURL)[0], id: inUser.map(data => data.socialId)[0], hideView: false } }],
                            socialMediaAccessToken: instagramToken,
                            workspaceId: userContext?.userData?.activeWorkspace?._id,
                            socialMediaId: socialId
                        }, 2, false)

                        const res = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/getInstagramCompetitors?workspaceId=${userContext?.userData?.activeWorkspace?._id}&socialMediaId=${socialId}`, {}, 1, false)
                        competitorDataDetails = await getCompetitorsData(res?.data?.data?.competitorsData, dateArray)



                    } else {
                        competitorDataDetails = await getCompetitorsData(res?.data?.data?.competitorsData, dateArray, true)




                    }

                    const obj = {
                        id: socialId,
                        selectedPeriod: selectedPeriod.toString(),
                        dateArray: dateArray,
                        latestPostDetails: getInstagramAnalyticsDataFromBackend.data.latestPostDetails[0],
                        listOfPostId: listOfPostId,
                        allPostDetails: getInstagramAnalyticsDataFromBackend.data.allPostDetails,
                        apiResOfCountry: apiResOfCountry?.data[0]?.total_value?.breakdowns[0]?.results?.map(elements => {
                            const obj = {
                                country: '',
                                value: ''
                            }
                            obj.country = elements.dimension_values[0]
                            obj.value = elements.value
                            return obj
                        }),
                        apiResOfCity: apiResOfCity?.data[0]?.total_value?.breakdowns[0]?.results?.map(elements => {
                            const obj = {
                                country: '',
                                value: ''
                            }
                            obj.country = elements.dimension_values[0]
                            obj.value = elements.value
                            return obj
                        }),
                        apiResOfAudience: apiResOfAudience?.data[0]?.total_value?.breakdowns[0]?.results?.map(elements => {
                            const obj = {
                                label: '',
                                F_count: '',
                                M_count: ''
                            }


                            obj.label = elements.dimension_values[0]

                            if (elements.dimension_values[1] === "F") {
                                obj.F_count = elements.value
                            }
                            if (elements.dimension_values[1] === "M") {
                                obj.M_count = elements.value
                            }

                            return obj

                        }),
                        previousDateReachSum: previousDateOverviewStastics?.data.filter(metric => metric.name === "reach")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        currentDateReach: currentDateOverviewStastics?.data.filter(metric => metric.name === "reach")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        previousDateWebsiteClicks: previousDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        currentDateWebsiteClicks: currentDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        previousDateProfileVisit: previousDateOverviewStastics?.data.filter(metric => metric.name === "profile_views")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        currentDateProfileVisit: currentDateOverviewStastics?.data.filter(metric => metric.name === "profile_views")[0]?.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0),
                        apiResOfTotalFollowers: apiResOfTotalFollowers,
                        apiResOfProfileView: currentDateOverviewStastics?.data.filter(metric => metric.name === "profile_views"),
                        online_follower_last_week: online_follower_last_week,
                        activeOnlineFollowersObject: maxObject,
                        online_follower: online_follower,
                        apiResOfFollowerCount: currentDateOverviewStastics?.data.filter(metric => metric.name === "follower_count"),
                        apiResOfWebsiteClicks: currentDateOverviewStastics?.data.filter(metric => metric.name === "website_clicks"),
                        apiResOfReachCounts: currentDateOverviewStastics?.data.filter(metric => metric.name === "reach"),
                        apiResOfImpressions: currentDateOverviewStastics?.data.filter(metric => metric.name === "impressions"),
                        apiResOfPreviousDateImpression: previousDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0],
                        currentDateImpressionsData: currentDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0]?.values?.map(data => data.value).reduce((totalImpression, data) => { return totalImpression += data }),
                        previousDateImpressionData: previousDateOverviewStastics?.data.filter(metric => metric.name === "impressions")[0]?.values?.map(data => data.value).reduce((totalImpression, data) => { return totalImpression += data }),
                        storyDataFromDB: storyDataFromDB,
                        competitors: res?.data?.data?.competitorsData ? res?.data?.data?.competitorsData?.reverse() : [],
                        competitorDataDetails: competitorDataDetails.allcompetitorData,
                        lineChartData: competitorDataDetails.lineChartData,
                        apiResOfOnlineFollwer: apiResOfOnlineFollwer?.data[0]?.values,
                        borderColorArray: competitorDataDetails.borderColor,
                        storyPagination: storyDataFromDB.data.pagination
                    }



                    userContext.handleaccountsData(obj)


                }
            }
            setfilterLoading(false)
        } catch (error) {

            toast.error('Something went to wrong')
            setloading(false)
        }
    }

    function getLastAsPerPeriodDays(filterSelectedPeriod) {
        const today = new Date();
        const dateRanges = [];

        for (let i = 0; i < (filterSelectedPeriod / 30); i++) {

            const endDate = new Date(dateRanges.length === 0 ? today : dateRanges[i - 1].startDate);
            endDate.setDate((dateRanges.length === 0 ? today : new Date(dateRanges[i - 1].startDate)).getDate() - i);

            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 30);
            const dateRange = {
                startDate: (startDate),
                endDate: (endDate),
            };

            dateRanges.push(dateRange);
        }

        return dateRanges;
    }

    const storyPagination = async () => {
        try {
            setstoryPaginationLoading(true)
            const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);

            const socialId = inUser[0].socialId
            const currentDate = new Date();
            const startDate = new Date(currentDate);
            startDate.setDate(startDate.getDate() - (selectedPeriod - 1))

            const storyDataFromDB = await APICall(`${url}analytics/instagram/getInstagramStory?page=${storyPage}&limit=5&socialMediaId=${socialId}&since=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}&until=${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate() + 1}`, {}, 1, false)
            setstoryPage((prev) => prev + 1)

            storyDataFromDB?.data?.data?.forEach(data => {
                const filterStories = data.storyData.filter(stories => { return stories.timestamp.split("T")[0] === data.date.split("T")[0] })
                data.story = filterStories
            })
            storyDataFromDB?.data?.data.forEach(data => {
                setallStoryDetails(prev => [...prev, data])
            })

            setstopStoryPagination(storyDataFromDB.data.pagination)
            setstoryPaginationLoading(false)

        } catch (error) {
            toast.error(error !== "Internal server error" ? "Token is expired!" : "Internal server error!")
        }
    }

    function getLastAsPerPreviousPeriodDays(filterSelectedPeriod) {
        const today = new Date();
        today.setDate(new Date(today).getDate() - selectedPeriod)

        const dateRanges = [];

        for (let i = 0; i < (filterSelectedPeriod / 30); i++) {

            const endDate = new Date(dateRanges.length === 0 ? today : dateRanges[i - 1].startDate);
            endDate.setDate((dateRanges.length === 0 ? today : new Date(dateRanges[i - 1].startDate)).getDate() - i);

            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 30);
            const dateRange = {
                startDate: (startDate),
                endDate: (endDate),
            };

            dateRanges.push(dateRange);
        }

        return dateRanges;
    }

    const getInstagramAnalyticsAsPerDaysFilterData = async () => {
        try {

            const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);

            const socialId = inUser[0].socialId
            const isAccountExits = userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === selectedPeriod.toString())

            if (isAccountExits.length) {

                if (selectedPeriod.toString() === "30") {
                    setloading(true)
                    setfollowerDateArray(isAccountExits[0].dateArray);
                    setlatestPost(isAccountExits[0].latestPostDetails)
                    setpostDetails(isAccountExits[0].listOfPostId.data)
                    setallPostInsights(isAccountExits[0].allPostDetails)
                    setaudience_country(isAccountExits[0].apiResOfCountry)
                    setaudience_cityData(isAccountExits[0].apiResOfCity)
                    setinstagramAudienceData(isAccountExits[0].apiResOfAudience)
                    setreachPercentage((((isAccountExits[0].currentDateReach - isAccountExits[0].previousDateReachSum) / isAccountExits[0].previousDateReachSum) * 100).toFixed(1))
                    setwebsiteclicksPercentage((((isAccountExits[0].currentDateWebsiteClicks - isAccountExits[0].previousDateWebsiteClicks) / isAccountExits[0].previousDateWebsiteClicks) * 100).toFixed(1))
                    settotalFollower(isAccountExits[0].apiResOfTotalFollowers.followers_count)
                    setprofileVisitPercentage((((isAccountExits[0].currentDateProfileVisit - isAccountExits[0].previousDateProfileVisit) / isAccountExits[0].previousDateProfileVisit) * 100).toFixed(1))
                    setprofileVisits(isAccountExits[0].apiResOfProfileView)
                    setonline_followers_week(isAccountExits[0].online_follower_last_week)
                    setactiveOnlineFollowersObject(isAccountExits[0].activeOnlineFollowersObject)
                    setonline_followers(isAccountExits[0].online_follower)
                    setfollower(isAccountExits[0].apiResOfFollowerCount)
                    setwebsiteClicks(isAccountExits[0].apiResOfWebsiteClicks)
                    setreach(isAccountExits[0].apiResOfReachCounts)
                    setimpressions(isAccountExits[0].apiResOfImpressions)
                    setimpressionsPercentage((((isAccountExits[0].currentDateImpressionsData - isAccountExits[0].previousDateImpressionData) / isAccountExits[0].previousDateImpressionData) * 100).toFixed(1))
                    setallStoryDetails(isAccountExits[0].storyDataFromDB.data.data)
                    setcompetitors(isAccountExits[0].competitors)
                    setcompetitorsData(isAccountExits[0].competitorDataDetails)
                    setlineChartData(isAccountExits[0].lineChartData)
                    setapiResOfOnlineFollwer(isAccountExits[0].apiResOfOnlineFollwer)
                    setborderColorArray(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].borderColorArray)
                    setstopStoryPagination(isAccountExits[0].storyPagination)

                } else {
                    setfollowerDate(isAccountExits[0].followerDate)
                    setfollowerDateArray(isAccountExits[0].dateArray);
                    setpostDetails(isAccountExits[0].postDetails)
                    setallPostInsights(isAccountExits[0].allPostInsights)
                    setreachPercentage((((isAccountExits[0].currentDateReach - isAccountExits[0].previousDateReachSum) / isAccountExits[0].previousDateReachSum) * 100).toFixed(1))
                    setwebsiteclicksPercentage((((isAccountExits[0].currentDateWebsiteClicks - isAccountExits[0].previousDateWebsiteClicks) / isAccountExits[0].previousDateWebsiteClicks) * 100).toFixed(1))
                    setprofileVisitPercentage((((isAccountExits[0].currentDateProfileVisit - isAccountExits[0].previousDateProfileVisit) / isAccountExits[0].previousDateProfileVisit) * 100).toFixed(1))
                    settotalFollower(isAccountExits[0].totalFollower)
                    setprofileVisits(isAccountExits[0].profileVisits)
                    setwebsiteClicks(isAccountExits[0].websiteClicks)
                    setreach(isAccountExits[0].reach)
                    setimpressions(isAccountExits[0].impressions)
                    setimpressionsPercentage((((isAccountExits[0].currentDateImpressionsData - isAccountExits[0].previousDateImpressionData) / isAccountExits[0].previousDateImpressionData) * 100).toFixed(1))
                    setallStoryDetails(isAccountExits[0].allStoryDetails.data.data)
                    setlineChartData(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].lineChartData)
                    setapiResOfOnlineFollwer(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].apiResOfOnlineFollwer)
                    setborderColorArray(userContext.accountsData.filter(data => data?.id === socialId && data.selectedPeriod === "30")[0].borderColorArray)
                    setstopStoryPagination(isAccountExits[0].storyPagination)
                }
            } else {
                if (filterLoading === false) return

                setloading(true)
                let selectedPeriodDateArray = getLastAsPerPeriodDays(selectedPeriod)
                let selectedPrevPeriodDateArray = getLastAsPerPreviousPeriodDays(selectedPeriod)
                const currentDate = new Date();
                const startDate = new Date(currentDate);
                startDate.setDate(startDate.getDate() - (selectedPeriod));
                const dateArray = [];
                const filterDateArray = [];
                for (let i = 0; i < (selectedPeriod === "30" ? (selectedPeriod) : selectedPeriod); i++) {
                    const newDate = new Date(startDate);
                    newDate.setDate(startDate.getDate() + i); // Adjust the increment as needed
                    const monthShortName = newDate.toLocaleString('default', { month: 'short' });
                    const day = String(newDate.getDate()).padStart(2, '0');
                    dateArray.push(`${monthShortName}-${day}`);
                    const dateInMilliseconds = newDate.setHours(0, 0, 0, 0)
                    filterDateArray.push(dateInMilliseconds)
                }

                setfollowerDate(followerDate)
                setfollowerDateArray(dateArray);
                const workspaceId = localStorage.getItem('organizationId')

                const filterSelectedPeriodPromise = selectedPeriodDateArray.map(async (data, index) => {

                    const startDateMilliseconds = new Date(data.startDate).getTime()
                    const endDateMilliSeconds = new Date(data.endDate).getTime()
                    const startDatePreviousMiliseconds = new Date(selectedPrevPeriodDateArray[index].startDate).getTime()
                    const endDatePreviousMiliseconds = new Date(selectedPrevPeriodDateArray[index].endDate).getTime()

                    const previousDate = new Date(data.endDate)
                    previousDate.setDate(new Date(data.endDate).getDate() - 30)


                    const getInstagramAnalyticFromBackendAsperPeriod = await axios.post(`${url}analytics/instagram/getInstagramAnalyticsDataWithPeriod`, {
                        "startDateMilliseconds": startDateMilliseconds.toString().slice(0, 10),
                        "endDateMilliSeconds": endDateMilliSeconds.toString().slice(0, 10),
                        "startDatePreviousMiliseconds": startDatePreviousMiliseconds.toString().slice(0, 10),
                        "endDatePreviousMiliseconds": endDatePreviousMiliseconds.toString().slice(0, 10),
                        "accountType": "instagram",
                        "socialId": socialId,
                        "workspaceId": workspaceId
                    })

                    const listOfPostId = getInstagramAnalyticFromBackendAsperPeriod.data.listOfPostId

                    const allPostDetails = getInstagramAnalyticFromBackendAsperPeriod.data.allPostDetails
                    const currentDateOverviewStastics = getInstagramAnalyticFromBackendAsperPeriod.data.currentDateOverviewStastics
                    const previousDateOverviewStastics = getInstagramAnalyticFromBackendAsperPeriod.data.previousDateOverviewStastics
                    const apiResOfOnlineFollwer = getInstagramAnalyticFromBackendAsperPeriod.data.apiResOfOnlineFollwer



                    return { listOfPostId: listOfPostId?.data, allPostDetails: allPostDetails, apiResOfProfileView: currentDateOverviewStastics?.data?.filter(metric => metric.name === "profile_views"), apiResOfOnlineFollwer: apiResOfOnlineFollwer?.data, apiResOfImpressions: currentDateOverviewStastics?.data?.filter(metric => metric.name === "impressions"), apiResOfWebsiteClicks: currentDateOverviewStastics.data?.filter(metric => metric.name === "website_clicks"), apiResOfReachCounts: currentDateOverviewStastics?.data?.filter(metric => metric.name === "reach"), apiResOfPreviousProfileView: previousDateOverviewStastics?.data?.filter(metric => metric.name === "profile_views"), apiResOfPreviousWebsiteClicks: previousDateOverviewStastics?.data?.filter(metric => metric.name === "website_clicks"), apiResOfPreviousReach: previousDateOverviewStastics?.data?.filter(metric => metric.name === "reach"), apiResOfPreviousDateImpression: currentDateOverviewStastics?.data?.filter(metric => metric.name === "impressions") }
                })


                const allInstagramAnalyticsAsPerDaysFilterData = await Promise.all(filterSelectedPeriodPromise)

                const output = allInstagramAnalyticsAsPerDaysFilterData.reduce((acc, current) => {
                    acc[0].listOfPostId = acc[0].listOfPostId.concat(current.listOfPostId);
                    acc[0].allPostDetails = acc[0]?.allPostDetails?.concat(current?.allPostDetails)
                    acc[0].apiResOfProfileView = acc[0].apiResOfProfileView.concat(current.apiResOfProfileView)

                    acc[0].apiResOfOnlineFollwer = acc[0].apiResOfOnlineFollwer.concat(current.apiResOfOnlineFollwer)
                    acc[0].apiResOfImpressions = acc[0].apiResOfImpressions.concat(current.apiResOfImpressions)
                    acc[0].apiResOfWebsiteClicks = acc[0].apiResOfWebsiteClicks.concat(current.apiResOfWebsiteClicks)
                    acc[0].apiResOfReachCounts = acc[0].apiResOfReachCounts.concat(current.apiResOfReachCounts)
                    acc[0].apiResOfPreviousProfileView = acc[0].apiResOfPreviousProfileView.concat(current.apiResOfPreviousProfileView)
                    acc[0].apiResOfPreviousWebsiteClicks = acc[0].apiResOfPreviousWebsiteClicks.concat(current.apiResOfPreviousWebsiteClicks)
                    acc[0].apiResOfPreviousReach = acc[0].apiResOfPreviousReach.concat(current.apiResOfPreviousReach)

                    acc[0].apiResOfPreviousDateImpression = acc[0].apiResOfPreviousDateImpression.concat(current.apiResOfPreviousDateImpression)

                    return acc;
                }, [{ listOfPostId: [], allPostDetails: [], apiResOfProfileView: [], apiResOfOnlineFollwer: [], apiResOfImpressions: [], apiResOfWebsiteClicks: [], apiResOfReachCounts: [], apiResOfPreviousProfileView: [], apiResOfPreviousWebsiteClicks: [], apiResOfPreviousReach: [], apiResOfPreviousDateImpression: [] }]);

                setpostDetails(output[0].listOfPostId)
                setallPostInsights(output[0].allPostDetails)


                const previousDateReachSum = output[0].apiResOfPreviousReach.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                const currentDateReach = output[0].apiResOfReachCounts.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                setreachPercentage((((currentDateReach - previousDateReachSum) / previousDateReachSum) * 100).toFixed(1))

                const previousDateWebsiteClicks = output[0].apiResOfPreviousWebsiteClicks.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                const currentDateWebsiteClicks = output[0].apiResOfWebsiteClicks.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                setwebsiteclicksPercentage((((currentDateWebsiteClicks - previousDateWebsiteClicks) / previousDateWebsiteClicks) * 100).toFixed(1))

                const previousDateProfileVisit = output[0].apiResOfPreviousProfileView.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                const currentDateProfileVisit = output[0].apiResOfProfileView.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)



                setprofileVisitPercentage((((currentDateProfileVisit - previousDateProfileVisit) / previousDateProfileVisit) * 100).toFixed(1))

                setprofileVisits(output[0].apiResOfProfileView)


                setwebsiteClicks(output[0].apiResOfWebsiteClicks)

                setreach(output[0].apiResOfReachCounts)

                setimpressions(output[0].apiResOfImpressions)

                const currentDateImpressionsData = output[0].apiResOfImpressions.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                const previousDateImpressionData = output[0].apiResOfPreviousDateImpression.map(data => data.values?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0)).reduce((totalReach, data) => { return totalReach += data }, 0)
                setimpressionsPercentage((((currentDateImpressionsData - previousDateImpressionData) / previousDateImpressionData) * 100).toFixed(1))

                const storyDataFromDB = await APICall(`${url}analytics/instagram/getInstagramStory?page=1&limit=5&socialMediaId=${socialId}&since=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}&until=${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate() + 1}`, {}, 1, false)

                setstoryPage(prev => prev + 1)

                storyDataFromDB?.data?.data?.forEach(data => {
                    const filterStories = data.storyData.filter(stories => { return stories.timestamp.split("T")[0] === data.date.split("T")[0] })
                    data.story = filterStories
                })
                setallStoryDetails(storyDataFromDB.data.data)

                setstopStoryPagination(storyDataFromDB.data.pagination)


                const obj = {
                    id: socialId,
                    selectedPeriod: selectedPeriod.toString(),
                    followerDate: followerDate,
                    dateArray: dateArray,
                    postDetails: output[0].listOfPostId,
                    allPostInsights: output[0].allPostDetails,
                    competitors: competitors,
                    competitorsData: competitorsData,
                    previousDateReachSum: previousDateReachSum,
                    currentDateReach: currentDateReach,
                    previousDateWebsiteClicks: previousDateWebsiteClicks,
                    currentDateWebsiteClicks: currentDateWebsiteClicks,
                    previousDateProfileVisit: previousDateProfileVisit,
                    currentDateProfileVisit: currentDateProfileVisit,
                    totalFollower: totalFollower,
                    profileVisits: output[0].apiResOfProfileView,
                    websiteClicks: output[0].apiResOfWebsiteClicks,
                    reach: output[0].apiResOfReachCounts,
                    impressions: output[0].apiResOfImpressions,
                    currentDateImpressionsData: currentDateImpressionsData,
                    previousDateImpressionData: previousDateImpressionData,
                    allStoryDetails: storyDataFromDB,
                    storyPagination: storyDataFromDB.data.pagination

                }
                userContext.handleaccountsData(obj)

            }

            setloading(false)
            setfilterLoading(false)
        } catch (error) {

            setloading(false)
            setfilterLoading(false)
        }
    }

    const changeCompetitorsLoading = (value) => {
        setaddCompetitorsLoader(value)
    }


    const handleCompetitor = (value) => {
        setcompetitors(value)
    }

    const handleSearchList = (value) => {
        setsearchList(value)
    }

    const context = {
        allStoryDetails: allStoryDetails,
        setallStoryDetails: changeallStoryDetails,
        impressionsPercentage: impressionsPercentage,
        setimpressionsPercentage: changeImpressionPercentage,
        allPostInsights: allPostInsights,
        setallPostInsights: changeallPostInsights,
        activeOnlineFollowersObject: activeOnlineFollowersObject,
        setactiveOnlineFollowersObject: changeactiveOnlineFollowerObject,
        online_followers: online_followers,
        setonline_followers: changeonline_followers,
        impressions: impressions,
        setimpressions: changeImpression,
        insightsPost: insightsPost,
        setinsightsPost: changeInsights,
        totalFollower: totalFollower,
        settotalFollower: changetotalFollower,
        followerPercentage: followerPercentage,
        setfollowerPercentage: changefollowerPercentage,
        reachPercentage: reachPercentage,
        setreachPercentage: changereachPercentage,
        profileVisitPercentage: profileVisitPercentage,
        setprofileVisitPercentage: changeprofileVisitPercentage,
        websiteclicksPercentage: websiteclicksPercentage,
        setwebsiteclicksPercentage: changewebsiteclicksPercentage,
        followerDateArray: followerDateArray,
        setfollowerDateArray: changefollowerDateArray,
        selectedPeriod: selectedPeriod,
        setselectedPeriod: changeSelectedPeriod,
        profileActivity: profileActivity,
        setprofileActivity: changeprofileActivity,
        profileVisits: profileVisits,
        setprofileVisits: changeprofileVisits,
        websiteClicks: websiteClicks,
        setwebsiteClicks: changewebsiteClicks,
        postDetails: postDetails,
        setpostDetails: changepostDetails,
        audience: audience,
        setaudience: changeaudience,
        follower: follower,
        setfollower: changeFollower,
        loading: loading,
        setloading: changeloading,
        reach: reach,
        setreach: changereach,
        getInstagramAnalyticsData: getInstagramAnalyticsData,
        getInstagramAnalyticsAsPerDaysFilterData: getInstagramAnalyticsAsPerDaysFilterData,
        instagramAudienceData: instagramAudienceData,
        online_follower_last_week: online_follower_last_week,
        competitorsData: competitorsData,
        getCompetitorsData: getCompetitorsData,
        competitors: competitors,
        handleCompetitor: handleCompetitor,
        changeCompetitors: changeCompetitors,
        addPageToCompetitors: addPageToCompetitors,
        deleteCompetitors: deleteCompetitors,
        saveInstagramCompetitors: saveInstagramCompetitors,
        audience_cityData: audience_cityData,
        audience_country: audience_country,
        filterLoading: filterLoading,
        setfilterLoading: changeFilter,
        followerDate: followerDate,
        changeLoadingState: changeLoadingState,
        search: search,
        setsearch: changesearch,
        addCompetitorsLoader: addCompetitorsLoader,
        setaddCompetitorsLoader: changeCompetitorsLoading,
        saveCompetitorsLoading: saveCompetitorsLoading,
        latestPost: latestPost,
        lineChartData: lineChartData,
        showSearchList: showSearchList,
        setshowSearchList: handleshowSearchList,
        searchList: searchList,
        setsearchList: handleSearchList,
        searchCompetitors: searchCompetitors,
        storyPagination: storyPagination,
        storyPage: storyPage,
        stopStoryPagination: stopStoryPagination,
        storyPaginationLoading: storyPaginationLoading,
        apiResOfOnlineFollwer: apiResOfOnlineFollwer,
        searchCompetitorLoading: searchCompetitorLoading,
        borderColorArray: borderColorArray,
        changeborderColorArray: changeborderColorArray,
        hideCompetitors: hideCompetitors,
        getInstagramOverviewPageAnalyticaData: getInstagramOverviewPageAnalyticaData,
        getInstagramContentPageAnalyticaData: getInstagramContentPageAnalyticaData,
        getInstagramAccountPageAnalyticaData: getInstagramAccountPageAnalyticaData,
        getInstagramCompetitorPageAnalyticaData: getInstagramCompetitorPageAnalyticaData,
        getInstagramOverviewPageFilterPeriodData: getInstagramOverviewPageFilterPeriodData,
        overviewPageLoading: overviewPageLoading,
        contentPageLoading: contentPageLoading,
        accountPageLoading: accountPageLoading,
        competitorPageLoading: competitorPageLoading,
        overviewPageFilterLoading: overviewPageFilterLoading,
        contentPageFilterLoading: contentPageFilterLoading,
        accountPageFilterLoading: accountPageFilterLoading,
        onChangeoverviewPageFilterLoading: onChangeoverviewPageFilterLoading,
        onChangecontentPageFilterLoading: onChangecontentPageFilterLoading,
        onChangeaccountPageFilterLoading: onChangeaccountPageFilterLoading,
        stackedData: stackedData,
        stackedDataContent: stackedDataContent,
        rankedHashtag: rankedHashtag,
        rannkedTab: rannkedTab,
        onChangerannkedTab: onChangerannkedTab,
        rankedHashtagContent: rankedHashtagContent,
        keywordAdded: keywordAdded,
        onChangeKeywordAdded: onChangeKeywordAdded,
        onChangeAllKeywordAdded: onChangeAllKeywordAdded,
        deleteKeywordAdded: deleteKeywordAdded,
        onChangeconfirmKeywordAdded: onChangeconfirmKeywordAdded,
        confirmKeywordAdded: confirmKeywordAdded,
        reportWeekArray: reportWeekArray,
        selectedReportDate: selectedReportDate,
        onChangesetselectedReportDate: onChangeselectedReportDate,
        mentionsData: mentionsData,
        tagData: tagData,
        clearState: clearState
    }


    return (
        <CreateInstagramAnalyticContext.Provider value={context} >{children}</CreateInstagramAnalyticContext.Provider>
    )

}

