import React, { useContext, useState, useEffect } from 'react'
import Style from './PinterestOverview.module.css'
import { CreatePinterestAnalyticContext } from '../../store/pinterestAnalytics';
import OverviewCard from '../../Component/OverviewCard/overviewcard';
import { createNavigationContext } from '../../store/navigation';
import { formatNumber } from '../../Util/helper'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import LinkedinOverviewSkeleton from '../../Component/LinkedinOverviewSkeleton/LinkedinOverviewSkeleton';
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup';
import PinterestNavbar from '../../Component/PinterestNavbar/PinterestNavbar';
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount';
import BlogListCard from '../../Component/BlogListCard/BlogListCard';
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount';
import { UserNavigationContext } from '../../store/NavigationProvider';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const PinterestOverview = () => {
    const context = useContext(CreatePinterestAnalyticContext)
    const naviationContext = useContext(createNavigationContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [hoverImpressions, sethoverImpressions] = useState(false)
    const [hoverEngagement, sethoverEngagement] = useState(false)
    const [hoverOutboundClick, sethoverOutboundClick] = useState(false)
    const [hoverSave, sethoverSave] = useState(false)
    const [hoverClickThrough, sethoverClickThrough] = useState(false)

    const navigation = (value) => {
        naviationContext.setActive(value)
    }

    const captionTruncate = (str) => {
        return str.length > 335 ? str.substring(0, 333) + "..." : str;
    }


    // NOTE: impression chart data
    const impressionChartData = {
        labels: context.impressions.date,
        datasets: [{
            label: 'Impressions',
            data: context.impressions.points,
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverImpressions ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverImpressions ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        },
        {
            label: 'Outbound Clicks',
            data: context.outboundClicks.points,
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(255, 117, 37,0.25)"
            },
            borderColor: '#ff8a25',
            borderDash: [4, 3],
            pointBorderColor: hoverImpressions ? 'rgba(255, 117, 37, 1)' : 'rgba(255, 117, 37, 0)',
            pointBackgroundColor: hoverImpressions ? 'rgba(255, 117, 37, 0.5)' : 'rgba(255, 117, 37, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };



    // NOTE: engagement chart data
    const engagementChartData = {
        labels: context.engagement.date,
        datasets: [{
            label: 'Engagement',
            data: context.engagement.points,
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverEngagement ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverEngagement ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    // NOTE: outbound chart data
    const outboundChartData = {
        labels: context.outboundClicks.date,
        datasets: [{
            label: 'Engagement',
            data: context.outboundClicks.points,
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverOutboundClick ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverOutboundClick ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }




    // NOTE: save chart data
    const saveChartData = {
        labels: context.save.date,
        datasets: [{
            label: 'Engagement',
            data: context.save.points,
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverSave ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverSave ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    // NOTE: click through chart data
    const clickThroughChartData = {
        labels: context.clickThrough.date,
        datasets: [{
            label: 'Engagement',
            data: context.clickThrough.points.filter(data => data !== undefined),
            borderColor: '#0078FF',
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            pointBorderColor: hoverClickThrough ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverClickThrough ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    useEffect(() => {


        if (context.filterLoading) {
            context.getPinterestOverviewPageAnalyticaData()
            context.getPinterestContentPageAnalyticaData()
        }
        // eslint-disable-next-line 
    }, [context.selectedPeriod])


    return (
        <div className={Style.pinterest_overview_container}>
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {context.overviewPageLoading ? <LinkedinOverviewSkeleton selected={context.selectedPeriod} page="pinterest" /> : <div className={Style.overview_container}>

                <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Style.overview_content_main_container}>
                    <ActiveSocialAccount page="pinterest" disableFilter={true} loading={context.overviewPageLoading} selected={context.selectedPeriod} onChangePeriod={(value) => { context.handleSelectedPeriod(value); context.handleFilterLoading(true) }} />

                    <PinterestNavbar active="overview" />
                    <div className={Style.overview_content_container}>
                        {context.accountInfo?.follower_count < 100 ? <BlogListCard type="pinterest" /> : <></>}
                        <div className={Style.card_container} >
                            <OverviewCard toolTip="Total number of individuals or accounts subscribed to receive updates from this profile, indicating its popularity and reach." data={true} count={formatNumber(context.accountInfo?.follower_count)} percentage={0} header="Total Followers" />
                            <OverviewCard toolTip="Total views of your profile in a month, indicating visibility and engagement. Higher views suggest increased interest." people={true} count={formatNumber(context.accountInfo?.monthly_views)} percentage={0} header="Monthly Accounts Views" />
                            <OverviewCard toolTip="Metric measuring audience interaction with content. Expressed as a percentage, it gauges interactions (likes, shares, comments, clicks) relative to views or followers. High rates indicate compelling content, while low rates signal a need for adjustments." profile={true} count={context.totalStats.ENGAGEMENT_RATE?.toFixed(2) + "%" || 0 + "%"} percentage={0} header="Average Engagement Rate" />
                            <OverviewCard toolTip="Click to save this item to your board. Use Pin Save to organize your favorites, plan your projects, and explore ideas. Whether it's an inspiring image, a handy guide, or a must-try recipe, keep all your discoveries in one place for easy access anytime." website={true} count={formatNumber(context.totalStats.SAVE)} percentage={0} header="Pin Save" />
                        </div>

                        <div className={Style.latest_pin_and_followers_container} >
                            <div>
                                <div className={Style.header_container} >
                                    <label>Latest Pin</label>
                                    <span onClick={() => navigation('content')} style={{ cursor: 'pointer' }} >Show All <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                        <path d="M6.125 11.25L9.875 7.5L6.125 3.75" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg></span>
                                </div>

                                {context.allPins[0] ? <>
                                    <div className={Style.latest_post_container} >
                                        <div className={Style.img_container} >

                                            <img src={context.allPins[0]?.media?.images["1200x"]?.url} alt="" />

                                        </div>
                                        <div className={Style.content_container} >
                                            <div className={Style.header_value_container} >
                                                <span>Save</span>
                                                <span>{formatNumber(context.allPins[0]?.pin_metrics?.all_time?.save || 0)}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>
                                            <div className={Style.header_value_container} >
                                                <span>Profile Visits</span>
                                                <span>{formatNumber(context.allPins[0]?.pin_metrics?.profile_visit || 0)}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>

                                            <div className={Style.header_value_container} >
                                                <span>User Follow</span>
                                                <span>{formatNumber(context.allPins[0]?.pin_metrics?.user_follow || 0)}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>



                                            <div className={Style.header_value_container} >
                                                <span>Impressions</span>
                                                <span>{formatNumber(context.allPins[0]?.pin_metrics?.all_time?.impression || 0)}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>

                                            <div className={Style.header_value_container} >
                                                <span>Outbound Click</span>
                                                <span>{formatNumber(context.allPins[0]?.pin_metrics?.all_time?.outbound_click || 0)}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>


                                            <div className={Style.header_value_container} >
                                                <span>Pin Click</span>
                                                <span>{formatNumber(context.allPins[0]?.pin_metrics?.all_time?.pin_click || 0)}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>
                                            <div className={Style.header_value_container} >
                                                <span>Author Name</span>
                                                <span>{context.allPins[0]?.board_owner?.username !== undefined ? context.allPins[0]?.board_owner?.username : "-"}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>

                                            <div className={Style.header_value_container} >
                                                <span>Created at</span>
                                                <span>{new Date(context.allPins[0]?.created_at).getFullYear() + "-" + (new Date(context.allPins[0]?.created_at).getMonth() + 1) + "-" + new Date(context.allPins[0]?.created_at).getDate()}</span>
                                            </div>
                                            <label className={Style.border_of_post} ></label>

                                        </div>

                                    </div>
                                    <p>{captionTruncate(context.allPins[0]?.description || "")}</p>
                                </> : <NoDataAvailablePopup cardName="latestpost" />}

                            </div>

                            <LineChartCustomComponent onHoverOut={() => sethoverImpressions(false)} onHoverIn={() => sethoverImpressions(true)} type="line" header="Impressions" subheader="" contentArray={[{ "header": "Impressions", type: "solid", indicator: '#0078FF', value: formatNumber(context.totalStats.IMPRESSION || 0) }, {
                                "header": "Outbound Click", type: "dashed", indicator: '#ff8a25', value: formatNumber(context.totalStats?.OUTBOUND_CLICK || 0)
                            }, {
                                "header": "Outbound Click Rate", value: context.totalStats?.OUTBOUND_CLICK_RATE?.toFixed(2) + "%"
                            }]} options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                radius: hoverImpressions ? 5 : 5,

                                scales: {
                                    x: {
                                        type: 'category',
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        beginAtZero: true,
                                        grid: {
                                            display: false
                                        }
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false, // Hide dataset label
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                },


                            }} chartData={impressionChartData} />
                        </div>

                        <div className={Style.reach_and_profile_activity}>
                            <LineChartCustomComponent onHoverOut={() => sethoverEngagement(false)} onHoverIn={() => sethoverEngagement(true)} type="line" header="Engagement" subheader="" contentArray={[{
                                "header": "Total Engagement Rate", value: formatNumber(context.engagement.points.reduce((totalEng, data) => { return totalEng += data }, 0))
                            }, { "header": "Highest Engagement", value: formatNumber(Math.max(...context.engagement.points)) }, { "header": "Lowest Engagement", value: formatNumber(Math.min(...context.engagement.points)) }]} options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                radius: hoverEngagement ? 5 : 5,

                                scales: {
                                    x: {
                                        type: 'category',
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        beginAtZero: true,
                                        grid: {
                                            display: false
                                        }
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false, // Hide dataset label
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                },

                            }} chartData={engagementChartData} />
                            <LineChartCustomComponent onHoverOut={() => sethoverOutboundClick(false)} onHoverIn={() => sethoverOutboundClick(true)} type="line" header="Outbound Click" subheader="" contentArray={[{
                                "header": "Outbound Click Rate", value: context.totalStats.OUTBOUND_CLICK_RATE?.toFixed(2) + "%"
                            }, { "header": "Highest Outbound Click", value: formatNumber(Math.max(...context.outboundClicks.points)) }, { "header": "Lowest Outbound Click", value: formatNumber(Math.min(...context.outboundClicks.points)) }]} options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                radius: hoverOutboundClick ? 5 : 5,

                                scales: {
                                    x: {
                                        type: 'category',
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        beginAtZero: true,
                                        grid: {
                                            display: false
                                        }
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false, // Hide dataset label
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                },

                            }} chartData={outboundChartData} />
                        </div>



                        <div className={Style.reach_and_profile_activity} style={{
                            marginBottom: "120px"
                        }}>
                            <LineChartCustomComponent onHoverOut={() => sethoverSave(false)} onHoverIn={() => sethoverSave(true)} type="line" header="Save" subheader="" contentArray={[{
                                "header": "Save Rate", value: context.totalStats.SAVE_RATE?.toFixed(2) + "%"
                            }, { "header": "Highest Save", value: formatNumber(Math.max(...context.save.points)) }, { "header": "Lowest Save", value: formatNumber(Math.min(...context.save.points)) }]} options={{
                                maintainAspectRatio: false,
                                interaction: {
                                    intersect: false,
                                    mode: "x"
                                },
                                radius: hoverSave ? 5 : 5,

                                scales: {
                                    x: {
                                        type: 'category',
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        beginAtZero: true,
                                        grid: {
                                            display: false
                                        }
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: false, // Hide dataset label
                                    },
                                    tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                        position: 'nearest',
                                        // Additional tooltip configuration options
                                    },
                                },

                            }} chartData={saveChartData} />
                            <LineChartCustomComponent onHoverOut={() => sethoverClickThrough(false)} onHoverIn={() => sethoverClickThrough(true)} type="line" header="Click Through" subheader="" contentArray={[{
                                "header": "Click Through Rate", value: context.totalStats.CLICKTHROUGH_RATE?.toFixed(2) + "%"
                            }, { "header": "Highest Click Through", value: formatNumber(Math.max(...context.clickThrough.points.filter(data => data !== undefined))) }, { "header": "Lowest Click Through", value: formatNumber(Math.min(...context.clickThrough.points.filter(data => data !== undefined))) }]} options={
                                {
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverClickThrough ? 5 : 5,

                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },

                                }
                            } chartData={clickThroughChartData} />
                        </div>

                    </div>



                </div>



            </div>}
        </div >
    );
}

export default PinterestOverview;