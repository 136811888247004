export const industry =[
    {
        "$URN": "urn:li:industry:1",
        "name":  "Defense & Space"
        
    },
    {
        "$URN": "urn:li:industry:3",
        "name": "Computer Hardware"
            
    },
    {
        "$URN": "urn:li:industry:4",
        "name": "Computer Software"
        
    },
    {
        "$URN": "urn:li:industry:5",
        "name":  "Computer Networking"
    },
    {
        "$URN": "urn:li:industry:6",
        "name":  "Internet"
    },
    {
        "$URN": "urn:li:industry:7",
        "name":  "Semiconductors"
        
    },
    {
        "$URN": "urn:li:industry:8",
        "name":  "Telecommunications"
    },
    {
        "$URN": "urn:li:industry:9",
        "name":  "Law Practice"
        
    },
    {
        "$URN": "urn:li:industry:10",
        "name":  "Legal Services"
        
    },
    {
        "$URN": "urn:li:industry:11",
        "name":  "Management Consulting"
        
    },
    {
        "$URN": "urn:li:industry:12",
        "name":  "Biotechnology"
        
    },
    {
        "$URN": "urn:li:industry:13",
        "name":  "Medical Practice"
            
    },
    {
        "$URN": "urn:li:industry:14",
        "name":  "Hospital & Health Care"
            
    },
    {
        "$URN": "urn:li:industry:15",
        "name":  "Pharmaceuticals"
            
    },
    {
        "$URN": "urn:li:industry:16",
        "name":  "Veterinary"
            
    },
    {
        "$URN": "urn:li:industry:17",
        "name":  "Medical Device"
            
    },
    {
        "$URN": "urn:li:industry:18",
        "name":  "Cosmetics"
    },
    {
        "$URN": "urn:li:industry:19",
        "name":  "Apparel & Fashion"
            
        
    },
    {
        "$URN": "urn:li:industry:20",
        "name":  "Sporting Goods"
            
    },
    {
        "$URN": "urn:li:industry:21",
        "name":  "Tobacco"
            
    },
    {
        "$URN": "urn:li:industry:22",
        "name":  "Supermarkets"
            
    },
    {
        "$URN": "urn:li:industry:23",
        "name":  "Food Production"
            
    },
    {
        "$URN": "urn:li:industry:24",
        "name":  "Consumer Electronics"
            
    },
    {
        "$URN": "urn:li:industry:25",
        "name":  "Consumer Goods"
            
    },
    {
        "$URN": "urn:li:industry:26",
        "name":  "Furniture"
            
    },
    {
        "$URN": "urn:li:industry:27",
        "name":  "Retail"
            
    },
    {
        "$URN": "urn:li:industry:28",
        "name":  "Entertainment"
            
    },
    {
        "$URN": "urn:li:industry:29",
        "name":  "Gambling & Casinos"
            
    },
    {
        "$URN": "urn:li:industry:30",
        "name":  "Leisure, Travel & Tourism"
            
    },
    {
        "$URN": "urn:li:industry:31",
        "name":  "Hospitality"
            
    },
    {
        "$URN": "urn:li:industry:32",
        "name":  "Restaurants"
            
    },
    {
        "$URN": "urn:li:industry:33",
        "name":  "Sports"
            
    },
    {
        "$URN": "urn:li:industry:34",
        "name":  "Food & Beverages"
    },
    {
        "$URN": "urn:li:industry:35",
        "name": "Motion Pictures & Film"
            
    },
    {
        "$URN": "urn:li:industry:36",
        "name":  "Broadcast Media"
            
    },
    {
        "$URN": "urn:li:industry:37",
        "name":  "Museums & Institutions"
            
    },
    {
        "$URN": "urn:li:industry:38",
        "name":  "Fine Art"
            
    },
    {
        "$URN": "urn:li:industry:39",
        "name":  "Performing Arts"
            
    },
    {
        "$URN": "urn:li:industry:40",
        "name":  "Recreational Facilities & Services"
            
    },
    {
        "$URN": "urn:li:industry:41",
        "name":  "Banking"
            
    },
    {
        "$URN": "urn:li:industry:42",
        "name":  "Insurance"
            
    },
    {
        "$URN": "urn:li:industry:43",
        "name":  "Financial Services"
            
    },
    {
        "$URN": "urn:li:industry:44",
        "name":  "Real Estate"
            
    },
    {
        "$URN": "urn:li:industry:45",
        "name":  "Investment Banking"
            
    },
    {
        "$URN": "urn:li:industry:46",
        "name":  "Investment Management"
            
    },
    {
        "$URN": "urn:li:industry:47",
        "name":  "Accounting"
            
    },
    {
        "$URN": "urn:li:industry:48",
        "name":  "Construction"
            
    },
    {
        "$URN": "urn:li:industry:49",
        "name":  "Building Materials"
            
    },
    {
        "$URN": "urn:li:industry:50",
        "name":  "Architecture & Planning"
            
    },
    {
        "$URN": "urn:li:industry:51",
        "name":  "Civil Engineering"
            
        
    },
    {
        "$URN": "urn:li:industry:52",
        "name":  "Aviation & Aerospace"
            
    },
    {
        "$URN": "urn:li:industry:53",
        "name":  "Automotive"
            
    },
    {
        "$URN": "urn:li:industry:54",
        "name":  "Chemicals"
            
    },
    {
        "$URN": "urn:li:industry:55",
        "name":  "Machinery"
            
    },
    {
        "$URN": "urn:li:industry:56",
        "name":  "Mining & Metals"
            
    },
    {
        "$URN": "urn:li:industry:57",
        "name":  "Oil & Energy"
            
    },
    {
        "$URN": "urn:li:industry:58",
        "name":  "Shipbuilding"
            
    },
    {
        "$URN": "urn:li:industry:59",
        "name":  "Utilities"
            
    },
    {
        "$URN": "urn:li:industry:3131",
        "name":  "Mobile Games"
            
    },
    {
        "$URN": "urn:li:industry:60",
        "name":  "Textiles"
            
    },
    {
        "$URN": "urn:li:industry:61",
        "name":  "Paper & Forest Products"
            
    },
    {
        "$URN": "urn:li:industry:62",
        "name":  "Railroad Manufacture"
            
    },
    {
        "$URN": "urn:li:industry:63",
        "name":  "Farming"
            
    },
    {
        "$URN": "urn:li:industry:64",
        "name":  "Ranching"
            
    },
    {
        "$URN": "urn:li:industry:65",
        "name":  "Dairy"
            
    },
    {
        "$URN": "urn:li:industry:66",
        "name":  "Fishery"
            
    },
    {
        "$URN": "urn:li:industry:67",
        "name":  "Primary/Secondary Education"
            
    },
    {
        "$URN": "urn:li:industry:68",
        "name":  "Higher Education"
            
        
    },
    {
        "$URN": "urn:li:industry:69",
        "name":  "Education Management"
            
    },
    {
        "$URN": "urn:li:industry:70",
        "name":  "Research"
            
    },
    {
        "$URN": "urn:li:industry:71",
        "name":  "Military"
            
    },
    {
        "$URN": "urn:li:industry:72",
        "name":  "Legislative Office"
            
        
    },
    {
        "$URN": "urn:li:industry:73",
        "name":  "Judiciary"
            
        
    },
    {
        "$URN": "urn:li:industry:74",
        "name":  "International Affairs"
            
    },
    {
        "$URN": "urn:li:industry:75",
        "name":  "Government Administration"
            
    },
    {
        "$URN": "urn:li:industry:76",
        "name":  "Executive Office"
            
    },
    {
        "$URN": "urn:li:industry:77",
        "name":  "Law Enforcement"
            
    },
    {
        "$URN": "urn:li:industry:78",
        "name":  "Public Safety"
            
    },
    {
        "$URN": "urn:li:industry:79",
        "name":  "Public Policy"
            
    },
    {
        "$URN": "urn:li:industry:80",
        "name":  "Marketing & Advertising"
            
    },
    {
        "$URN": "urn:li:industry:81",
        "name":  "Newspapers"
            
    },
    {
        "$URN": "urn:li:industry:82",
        "name": "Publishing"
            
    },
    {
        "$URN": "urn:li:industry:83",
        "name":  "Printing"
            
    },
    {
        "$URN": "urn:li:industry:84",
        "name":  "Information Services"
            
    },
    {
        "$URN": "urn:li:industry:85",
        "name":  "Libraries"
            
    },
    {
        "$URN": "urn:li:industry:86",
        "name":  "Environmental Services"
            
    },
    {
        "$URN": "urn:li:industry:87",
        "name":  "Package/Freight Delivery"
            
    },
    {
        "$URN": "urn:li:industry:88",
        "name":  "Individual & Family Services"
            
    },
    {
        "$URN": "urn:li:industry:89",
        "name":  "Religious Institutions"
            
    },
    {
        "$URN": "urn:li:industry:90",
        "name":  "Civic & Social Organization"
            
    },
    {
        "$URN": "urn:li:industry:91",
        "name": "Consumer Services"
            
    },
    {
        "$URN": "urn:li:industry:92",
        "name":  "Transportation/Trucking/Railroad"
            
    },
    {
        "$URN": "urn:li:industry:93",
        "name":  "Warehousing"
            
    },
    {
        "$URN": "urn:li:industry:94",
        "name":  "Airlines/Aviation"
            
    },
    {
        "$URN": "urn:li:industry:95",
        "name":  "Maritime"
         
    },
    {
        "$URN": "urn:li:industry:96",
        "name":  "Information Technology & Services"
            
    },
    {
        "$URN": "urn:li:industry:97",
        "name":  "Market Research"
            
    },
    {
        "$URN": "urn:li:industry:98",
        "name":  "Public Relations & Communications"
           
    },
    {
        "$URN": "urn:li:industry:99",
        "name":  "Design"
            
    },
    {
        "$URN": "urn:li:industry:100",
        "name":  "Non-profit Organization Management"
            
    },
    {
        "$URN": "urn:li:industry:101",
        "name":  "Fundraising"
            
    },
    {
        "$URN": "urn:li:industry:102",
        "name":  "Program Development"
            
    },
    {
        "$URN": "urn:li:industry:103",
        "name":  "Writing & Editing"
            
    },
    {
        "$URN": "urn:li:industry:104",
        "name":  "Staffing & Recruiting"
            
    },
    {
        "$URN": "urn:li:industry:105",
        "name":  "Professional Training & Coaching"
            
    },
    {
        "$URN": "urn:li:industry:106",
        "name":  "Venture Capital & Private Equity"
            
    },
    {
        "$URN": "urn:li:industry:107",
        "name":  "Political Organization"
            
    },
    {
        "$URN": "urn:li:industry:108",
        "name":  "Translation & Localization"
            
    },
    {
        "$URN": "urn:li:industry:109",
        "name":  "Computer Games"
            
    },
    {
        "$URN": "urn:li:industry:110",
        "name":  "Events Services"
            
    },
    {
        "$URN": "urn:li:industry:111",
        "name":  "Arts & Crafts"
            
    },
    {
        "$URN": "urn:li:industry:112",
        "name":  "Electrical & Electronic Manufacturing"
            
    },
    {
        "$URN": "urn:li:industry:113",
        "name":  "Online Media"
            
    },
    {
        "$URN": "urn:li:industry:114",
        "name":  "Nanotechnology"
            
    },
    {
        "$URN": "urn:li:industry:115",
        "name":  "Music"
            
    },
    {
        "$URN": "urn:li:industry:116",
        "name":  "Logistics & Supply Chain"
            
    },
    {
        "$URN": "urn:li:industry:117",
        "name":  "Plastics"
            
    },
    {
        "$URN": "urn:li:industry:118",
        "name":  "Computer & Network Security"
    
    },
    {
        "$URN": "urn:li:industry:119",
        "name":  "Wireless"
            
    },
    {
        "$URN": "urn:li:industry:120",
        "name":  "Alternative Dispute Resolution"
            
    },
    {
        "$URN": "urn:li:industry:121",
        "name":  "Security & Investigations"
            
    },
    {
        "$URN": "urn:li:industry:122",
        "name":  "Facilities Services"
            
    },
    {
        "$URN": "urn:li:industry:123",
        "name":  "Outsourcing/Offshoring"
            
    },
    {
        "$URN": "urn:li:industry:124",
        "name":  "Health, Wellness & Fitness"
            
    },
    {
        "$URN": "urn:li:industry:125",
        "name":  "Alternative Medicine"
            
    },
    {
        "$URN": "urn:li:industry:126",
        "name":  "Media Production"
            
    },
    {
        "$URN": "urn:li:industry:127",
        "name":  "Animation"
            
    },
    {
        "$URN": "urn:li:industry:128",
        "name":  "Commercial Real Estate"
            
    },
    {
        "$URN": "urn:li:industry:129",
        "name":  "Capital Markets"
         
    },
    {
        "$URN": "urn:li:industry:130",
        "name":  "Think Tanks"
            
    },
    {
        "$URN": "urn:li:industry:131",
        "name":  "Philanthropy"
            
    },
    {
        "$URN": "urn:li:industry:132",
        "name":  "E-learning"
            
    },
    {
        "$URN": "urn:li:industry:133",
        "name":  "Wholesale"
            
    },
    {
        "$URN": "urn:li:industry:134",
        "name":  "Import & Export"
            
    },
    {
        "$URN": "urn:li:industry:135",
        "name":  "Mechanical Or Industrial Engineering"
            
    },
    {
        "$URN": "urn:li:industry:136",
        "name":  "Photography"
            
    },
    {
        "$URN": "urn:li:industry:137",
        "name":  "Human Resources"
            
    },
    {
        "$URN": "urn:li:industry:138",
        "name": "Business Supplies & Equipment"
            
    },
    {
        "$URN": "urn:li:industry:139",
        "name":  "Mental Health Care"
            
    },
    {
        "$URN": "urn:li:industry:140",
        "name":  "Graphic Design"
            
    },
    {
        "$URN": "urn:li:industry:141",
        "name":  "International Trade & Development"
            
    },
    {
        "$URN": "urn:li:industry:142",
        "name":  "Wine & Spirits"
            
    },
    {
        "$URN": "urn:li:industry:143",
        "name":  "Luxury Goods & Jewelry"
            
    },
    {
        "$URN": "urn:li:industry:144",
        "name":  "Renewables & Environment"
            
    },
    {
        "$URN": "urn:li:industry:145",
        "name":  "Glass, Ceramics & Concrete"
            
    },
    {
        "$URN": "urn:li:industry:146",
        "name":  "Packaging & Containers"
            
    },
    {
        "$URN": "urn:li:industry:147",
        "name":  "Industrial Automation"
            
    },
    {
        "$URN": "urn:li:industry:148",
        "name":  "Government Relations"
            
    },
    {
        "$URN": "urn:li:industry:150",
        "name":  "Horticulture"
            
    },
    {
        "$URN":"urn:li:industry:150+",
        "name":"Other"
    }
]
