export const captionTruncate = (str, count) => {
    return str?.length > count ? str.substring(0, count) + "..." : str;
}

export const formatNumber = (num) => {
    if (Number(num)) {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'm';
        } else if (num >= 10000) {
            return (num / 1000).toFixed(1) + 'k';
        } else {
            return num.toString();
        }
    }else{
        // eslint-disable-next-line
        if(num == 0){
            return 0
        }else{
        return "NA"
        }
    }
};