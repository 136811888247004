import ActiveSocialAccountSkeleton from '../ActiveSocialAccountSkeleton/ActiveSocialAccountSkeleton'
import Navbar from '../Navbar/navbar'
import PinterestNavbar from '../PinterestNavbar/PinterestNavbar'
import Styles from './LinkedinOverviewSkeleton.module.css'

const LinkedinOverviewSkeleton = ({page,selected}) => {
    
    return (
        <div className={Styles.linkedin_overview_container}>



            <div className={Styles.overview_container} >
                <div className={Styles.overview_content_main_container} >
                    <ActiveSocialAccountSkeleton selected={selected} />
                    {page === "pinterest" ?<PinterestNavbar selected="31" active="overview" />:<Navbar selected="31" active="overview" />
                    }
                    
                    <div className={`${Styles.overview_content_container} `} >

                        <div className={`${Styles.card_container} `} >
                            {[1, 2, 3, 4].map((index) => {
                                return <div key={index} >
                                    <div className={Styles.card_content} >
                                        <div className={`${Styles.svg_container} ${Styles.skeleton}`} ></div>
                                        <div className={Styles.card_content_header} >
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                        </div>

                                        <div className={`${Styles.percentage_container} ${Styles.skeleton}`} >

                                        </div>

                                    </div>


                                </div>
                            })

                            }

                        </div>

                        <div className={Styles.latest_pin_and_followers_container} >
                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>
                                <div className={Styles.latest_post_container} >
                                    <div className={`${Styles.img_container} ${Styles.skeleton}`} >

                                    </div>
                                    <div className={` ${Styles.content_container}`} >
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        
                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        

                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        

                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        

                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        

                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        

                                        <div className={`${Styles.header_value_container} ${Styles.skeleton}`} >

                                        </div>
                                        

                                    </div>

                                </div>
                                <p className={Styles.skeleton} ></p>
                            </div>

                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>

                                <div className={`${Styles.follower_chart_container} ${Styles.skeleton}`} >

                                </div>

                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                

                            </div>


                        </div>

                        <div className={Styles.followers_location_container} >
                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>
                                <div className={`${Styles.bar_chart_container} ${Styles.skeleton}`} >

                                </div>

                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                


                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                


                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                



                            </div>

                            <div className={Styles.impression_container} >
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>
                                <div className={`${Styles.bar_chart_container} ${Styles.skeleton}`} >


                                </div>
                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                <label className={Styles.border_of_post} ></label>


                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                <label className={Styles.border_of_post} ></label>


                                <div className={`${Styles.follower_location_container} ${Styles.skeleton}`} >

                                </div>
                                <label className={Styles.border_of_post} ></label>

                            </div>

                        </div>

                        <div style={{marginBottom:130}} className={Styles.impressions_and_engagement_rate_container} >


                            <div>
                                <div className={`${Styles.header_container} ${Styles.skeleton}`} >

                                </div>
                                <div className={`${Styles.bar_chart_container} ${Styles.skeleton}`} >


                                </div>
                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                <label className={Styles.border_of_post} ></label>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                <label className={Styles.border_of_post} ></label>


                                <div className={`${Styles.oragnic_container} ${Styles.skeleton}`} >

                                </div>
                                <label className={Styles.border_of_post} ></label>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default LinkedinOverviewSkeleton