import React, { useContext, useState } from 'react'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import Styles from './ShareReport.module.css'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import { UserNavigationContext } from '../../store/NavigationProvider'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import { formatNumber } from '../../Util/helper'
import { createNavigationContext } from '../../store/navigation'
const ShareReport = ({close}) => {
    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const userNavigation = useContext(UserNavigationContext)
    const accountContext = useContext(UserConnectedAccountContext)
    const context = useContext(createNavigationContext)

    const navigation = (value) => {
        context.setActive(value)
    }
    const [hoverFollower, sethoverFollower] = useState(false)
    instagramContext?.audience_country?.sort((a, b) => b?.value - a?.value);

    const startDateGraph = new Date(instagramContext.selectedReportDate)
    const enddateGraph = new Date(instagramContext.selectedReportDate)
    enddateGraph.setDate(enddateGraph.getDate() - 7);


    const getDataAsPerMetric = (name) => {
        if (name === "Profile View") {
            return instagramContext.profileVisits[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph).map(data => data.value)
        }
        if (name === "Website Clicks") {
            return instagramContext.websiteClicks[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph).map(data => data.value)
        }
    }


    const data = {
        labels: instagramContext?.follower[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph)?.map(data => {
            const newDate = new Date(data.end_time);
            const monthShortName = newDate.toLocaleString('default', { month: 'short' });
            const day = String(newDate.getDate()).padStart(2, '0');
            return `${monthShortName}-${day}`
        }),
        datasets: [{
            label: 'Follower oraganic count',
            data: instagramContext?.follower[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph)?.map(data => data.value),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverFollower ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverFollower ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };

    const getDateAndReturnDateAndMonth = (dateEnd) => {
        const date = new Date(dateEnd)
        const monthShortName = date.toLocaleString('default', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        return `${day} ${monthShortName}`
    }

    const getStartDateAndReturnDateAndMonth = (dateEnd) => {
        const date = new Date(dateEnd)
        date.setDate(date.getDate() - 7);
        const monthShortName = date.toLocaleString('default', { month: 'short' });
        const day = String(date.getDate()).padStart(2, '0');
        return `${day} ${monthShortName}`
    }

    const getPeopleReach = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext?.reach[0]?.values?.filter(data => new Date(data.end_time) <= startDate && new Date(data.end_time) >= enddate)?.reduce((totalReach, data) => { return totalReach += data.value }, 0))
    }

    const getPeopleImpression = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext.impressions[0]?.values?.filter(data => new Date(data.end_time) <= startDate && new Date(data.end_time) >= enddate)?.reduce((totalReach, data) => { return totalReach += data.value }, 0))
    }

    const getPeopleProfileView = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext.profileVisits[0]?.values?.filter(data => new Date(data.end_time) <= startDate && new Date(data.end_time) >= enddate)?.reduce((totalReach, data) => { return totalReach += data.value }, 0))
    }

    const getPeopleWebsiteClicks = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext.websiteClicks[0]?.values?.filter(data => new Date(data.end_time) <= startDate && new Date(data.end_time) >= enddate)?.reduce((totalReach, data) => { return totalReach += data.value }, 0))
    }

    const getPeopleLikes = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext.allPostInsights?.filter(data => new Date(data.timestamp) <= startDate && new Date(data.timestamp) >= enddate)?.reduce((totalLikes, data) => { return totalLikes += data.like_count }, 0))
    }

    const getPeopleComments = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext.allPostInsights?.filter(data => new Date(data.timestamp) <= startDate && new Date(data.timestamp) >= enddate)?.reduce((totalLikes, data) => { return totalLikes += data.comments_count }, 0))
    }

    const getFollowerChange = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext.follower[0]?.values?.filter(data => new Date(data.end_time) <= startDate && new Date(data.end_time) >= enddate)?.reduce((totalReach, data) => { return totalReach += data.value }, 0))
    }

    const getPostByHightEngagement = () => {
        const startDate = new Date(instagramContext.selectedReportDate)
        const enddate = new Date(instagramContext.selectedReportDate)
        enddate.setDate(enddate.getDate() - 7);
        return (instagramContext.allPostInsights?.filter(data => new Date(data.timestamp) <= startDate && new Date(data.timestamp) >= enddate)?.sort((a, b) => b.like_count - a.like_count && b.comments_count - a.comments_count)[0] !== undefined ? instagramContext.allPostInsights?.filter(data => new Date(data.timestamp) <= startDate && new Date(data.timestamp) >= enddate).sort((a, b) => b.like_count - a.like_count && b.comments_count - a.comments_count)[0] : [])
    }

    const dataset = [{ name: 'Profile View', color: '#0078FF' }, { name: 'Website Clicks', color: '#ff8a25' }].map(data => {
        return {
            label: data.name,
            data: getDataAsPerMetric(data.name),
            fill: false,
            backgroundColor: data.color,
        }
    })
    const barChartData = {
        labels: instagramContext?.follower[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph)?.map(data => {
            const newDate = new Date(data.end_time);
            const monthShortName = newDate.toLocaleString('default', { month: 'short' });
            const day = String(newDate.getDate()).padStart(2, '0');
            return `${monthShortName}-${day}`
        }),
        datasets: dataset,
    };


    return (
        <div className={Styles.report_main_container} >
            <div className={Styles.button_container} >
                        <button className={Styles.close_container} onClick={() => close()}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0007 9.99973L14.3695 14.3686M5.63184 14.3686L10.0007 9.99973L5.63184 14.3686ZM14.3695 5.63086L10.0007 9.99973L14.3695 5.63086ZM10.0007 9.99973L5.63184 5.63086L10.0007 9.99973Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
            <div className={Styles.report_container} >

                <div className={Styles.report_content_main_container} >
                    

                    <div className={Styles.report_content_container} >
                        {/* top bar header */}

                        <div className={Styles.bottom_container} >
                            <div className={Styles.report_analysis_container} >
                                <div className={Styles.branding_and_header} >
                                    <span>
                                        <svg width="73" height="35" viewBox="0 0 73 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_4973_132)">
                                                <path d="M49.0625 35.0007L45.8661 33.0234L45.3594 35.0007H49.0625Z" fill="black" />
                                                <path d="M53.7871 32.5093L49.5545 30.5625V32.5855L53.7871 32.5093Z" fill="black" />
                                                <path d="M44.952 34.8246L41.1956 31.2968L43.1462 27.9023L45.5234 32.6226L44.952 34.8246Z" fill="black" />
                                                <path d="M38.1094 7.93945L29.5146 17.5247L4.59516 17.3419L38.1094 7.93945Z" fill="black" />
                                                <path d="M59.4128 17.4258L59.4395 22.0736H54.4792L59.4128 17.4258Z" fill="black" />
                                                <path d="M56.5146 24.7022L59.4023 22.5078H56.5908L56.5146 24.7022Z" fill="black" />
                                                <path d="M40.9388 30.8731L47.0039 20.3125L39.0035 24.2251L40.9388 30.8731Z" fill="black" />
                                                <path d="M9.20117 15.6L4.27518 16.983L0.000652313 14.4609L9.20117 15.6Z" fill="black" />
                                                <path d="M68.3273 13.9049L63.1041 12.1791L62.3688 7.68359L69.6035 13.3449L68.3273 13.9049Z" fill="black" />
                                                <path d="M49.123 32.5846L44.037 26.3518L46.2467 22.5078H49.123V32.5846Z" fill="black" />
                                                <path d="M46.9121 19.8798L38.6107 23.9372L30.0464 17.5749L38.5955 8.04297L44.8663 10.4507L46.9121 19.8798Z" fill="black" />
                                                <path d="M72.709 6.05341L64.2285 8.59069L62.3084 7.08584L61.4169 1.83984H70.5527L72.709 6.05341Z" fill="black" />
                                                <path d="M60.9818 1.83984L62.6543 12.0994L59.4198 16.8273L53.7052 11.2842L56.2578 6.38104L60.9818 1.83984Z" fill="black" />
                                                <path d="M53.85 22.0729H46.4934L47.4611 20.389L45.3429 10.6094L53.0309 11.2266L59.1074 17.1202L53.85 22.0729Z" fill="black" />
                                                <path d="M66.0576 1.40579H68.1758L67.0633 0L66.0576 1.40579Z" fill="black" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4973_132">
                                                    <rect width="72.7087" height="35" fill="white" transform="matrix(-1 0 0 1 72.709 0)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Analytics
                                    </span>

                                    <div className={Styles.page_details_container} >
                                        <label>Weekly Roundup for <b>{(accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.name}</b></label>
                                        <span>{getStartDateAndReturnDateAndMonth(instagramContext.selectedReportDate)} - {getDateAndReturnDateAndMonth(instagramContext.selectedReportDate)}</span>
                                    </div>
                                </div>

                                <div className={Styles.overview_card_container} >

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 14V14C13 11.2386 15.2386 9 18 9V9C20.7614 9 23 11.2386 23 14V14.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <label>{getPeopleReach()}</label>
                                        <h4>People Reached</h4>
                                    </div>

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M3 13C6.6 5 17.4 5 21 13" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 17C10.3431 17 9 15.6569 9 14C9 12.3431 10.3431 11 12 11C13.6569 11 15 12.3431 15 14C15 15.6569 13.6569 17 12 17Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <label>{getPeopleImpression()}</label>
                                        <h4>Impression</h4>
                                    </div>

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <label>{getPeopleProfileView()}</label>
                                        <h4>Profile</h4>
                                    </div>

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M16.4724 20H4.1C3.76863 20 3.5 19.7314 3.5 19.4V9.6C3.5 9.26863 3.76863 9 4.1 9H6.86762C7.57015 9 8.22116 8.6314 8.5826 8.02899L11.293 3.51161C11.8779 2.53688 13.2554 2.44422 13.9655 3.33186C14.3002 3.75025 14.4081 4.30635 14.2541 4.81956L13.2317 8.22759C13.1162 8.61256 13.4045 9 13.8064 9H18.3815C19.7002 9 20.658 10.254 20.311 11.5262L18.4019 18.5262C18.1646 19.3964 17.3743 20 16.4724 20Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M7 20L7 9" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <label>{getPeopleLikes()}</label>
                                        <h4>Likes</h4>
                                    </div>

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M3 20.2895V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V15C21 16.1046 20.1046 17 19 17H7.96125C7.35368 17 6.77906 17.2762 6.39951 17.7506L4.06852 20.6643C3.71421 21.1072 3 20.8567 3 20.2895Z" stroke="#000000" stroke-width="1.5"></path></svg>
                                        <label>{getPeopleComments()}</label>
                                        <h4>Comments</h4>
                                    </div>

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 14V14C13 11.2386 15.2386 9 18 9V9C20.7614 9 23 11.2386 23 14V14.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <label>{getPeopleWebsiteClicks()}</label>
                                        <h4>Website Clicks</h4>
                                    </div>

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 14V14C13 11.2386 15.2386 9 18 9V9C20.7614 9 23 11.2386 23 14V14.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <label>{getFollowerChange()}</label>
                                        <h4>Follower Change</h4>
                                    </div>

                                    <div className={Styles.single_overview_card}  >
                                        <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M13 14V14C13 11.2386 15.2386 9 18 9V9C20.7614 9 23 11.2386 23 14V14.5" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <label>{instagramContext.totalFollower}</label>
                                        <h4>Total Follower</h4>
                                    </div>
                                </div>

                                <div className={Styles.see_more_analytics_container} >
                                    <button onClick={() => navigation('overview')} >
                                        See More Analytics
                                    </button>
                                </div>


                                {getPostByHightEngagement()?.length !== 0 ?
                                    <>
                                        <div className={Styles.most_engaged_post_container} >

                                            <div className={Styles.post_media_container} >
                                                <img src={getPostByHightEngagement()?.media_url} alt="postmedia" />
                                            </div>
                                            <div className={Styles.post_content_analysis_container} >
                                                <div className={Styles.single_post_analyisi_container} >
                                                    <label>Likes</label>
                                                    <p>{getPostByHightEngagement()?.like_count}</p>
                                                </div>
                                                <div className={Styles.single_post_analyisi_container} >
                                                    <label>Comments</label>
                                                    <p>{getPostByHightEngagement()?.comments_count}</p>
                                                </div>
                                                <div className={Styles.single_post_analyisi_container} >
                                                    <label>Eng rate</label>
                                                    <p>{(isFinite((getPostByHightEngagement()?.res?.data?.filter(data => data.name === "total_interactions")[0]?.values[0]?.value / getPostByHightEngagement()?.res?.data?.filter(data => data.name === "reach")[0]?.values[0]?.value).toFixed(1)) ? (getPostByHightEngagement()?.res?.data?.filter(data => data.name === "total_interactions")[0]?.values[0]?.value / getPostByHightEngagement()?.res?.data?.filter(data => data.name === "reach")[0].values[0].value)?.toFixed(1) : 0)}%</p>
                                                </div>
                                                <div className={Styles.single_post_analyisi_container} >
                                                    <label>Saves</label>
                                                    <p>{(isFinite(getPostByHightEngagement()?.res?.data?.filter(data => data.name === "saved")[0]?.values[0]?.value) ? getPostByHightEngagement()?.res?.data?.filter(data => data.name === "saved")[0]?.values[0]?.value : 0)}</p>
                                                </div>
                                                <div className={Styles.single_post_analyisi_container} >
                                                    <label>Impressions</label>
                                                    <p>{(isFinite(getPostByHightEngagement()?.res?.data?.filter(data => data.name === "impressions")[0]?.values[0]?.value) ? getPostByHightEngagement()?.res?.data?.filter(data => data.name === "impressions")[0]?.values[0]?.value : 0)}</p>
                                                </div>
                                                <div className={Styles.single_post_analyisi_container} >
                                                    <label>Reach</label>
                                                    <p>{(isFinite(getPostByHightEngagement()?.res?.data?.filter(data => data.name === "reach")[0]?.values[0]?.value) ? getPostByHightEngagement()?.res?.data?.filter(data => data.name === "reach")[0]?.values[0]?.value : 0)}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={Styles.see_more_analytics_container} >
                                            <button onClick={() => navigation('content')} >
                                                See Other Post
                                            </button>
                                        </div>
                                    </>
                                    : <div className={Styles.no_data_header} >You didn’t make a post this week.</div>
                                }


                                <LineChartCustomComponent style={{ width: '100%' }} onHoverOut={() => null} onHoverIn={() => null} type="bar" header="Overview" contentArray={[{ "header": "Profile View", value: getPeopleProfileView(instagramContext.selectedReportDate) }, { "header": "Website Clicks", value: getPeopleWebsiteClicks(instagramContext.selectedReportDate) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: 5,
                                    scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true,
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={barChartData} />

                                <div className={Styles.see_more_analytics_container} >
                                    <button onClick={() => navigation('overview')} >
                                        See Insights
                                    </button>
                                </div>


                                <LineChartCustomComponent style={{ width: '100%' }} onHoverOut={() => sethoverFollower(false)} onHoverIn={() => sethoverFollower(true)} type="line" header="Followers" subheader="" contentArray={[{ "header": "Total Followers", value: formatNumber(instagramContext.totalFollower || 0) }, {
                                    "header": "Followers Change", type: "solid", indicator: "#0078FF", value: formatNumber(instagramContext?.follower[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph)?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0) || 0)
                                }, {
                                    "header": "Growth Rate", value: ` ${isFinite(((instagramContext?.follower[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph)?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0) / instagramContext.totalFollower) * 100).toFixed(2)) ? ((instagramContext?.follower[0]?.values?.filter(data => new Date(data.end_time) <= startDateGraph && new Date(data.end_time) >= enddateGraph)?.reduce((totalFollowerCount, data) => { return totalFollowerCount += data.value; }, 0) / instagramContext.totalFollower) * 100).toFixed(2) : 0}%`
                                }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverFollower ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },


                                }} chartData={data} />

                                <div className={Styles.see_more_analytics_container} >
                                    <button onClick={() => navigation('overview')} >
                                        See Growth
                                    </button>
                                </div>


                            </div>
                        </div>


                    </div>


                </div>

            </div>
        </div>
    )
}

export default ShareReport