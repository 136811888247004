import { jwtDecode } from "jwt-decode";

const getCookie = (name) => {
    if (process.env.NODE_ENV === 'production') {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    } else {
        return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWl0TGlzdE51bWJlciI6MzI4MTAsImlzQWRtaW4iOmZhbHNlLCJpc0FmZmlsaWF0ZSI6dHJ1ZSwidXNlck5hbWUiOiJ2aXNoYWxwdXJhbmUiLCJ0aW1lWm9uZSI6IkFzaWEvQ2FsY3V0dGEiLCJpZCI6IjYxZjNjMGE3NmRjOTUyYjVhNTRiZmQyMCIsInBsYW5JZCI6IjYyNDcwODUxMzdiNTUzMDc0ZmI2YjI1YSIsIm9uYm9hcmRpbmdfc3RhdHVzIjp0cnVlLCJpYXQiOjE3MTU5MjIxMDUsImV4cCI6MTcxNzEzMTcwNX0.Vv5HMMDGw3SBSLgaB6RcDbNKjR26Bfzw-KyrmWqUC00"
    }
}

const distructureCookie = () => {
    let value = getCookie('Xh7ERL0G');
    if (value === undefined) return window.open('https://www.creatosaurus.io?app=analytics', "_self")
    localStorage.setItem('token', value)
}



const ProtectedRoutes = ({ children }) => {
    distructureCookie()
    const token = localStorage.getItem('token')

    // decode the jwt token and check the expiration time
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000;

    // if token is not there redirect to login or token expired
    if (token === null || Date.now() >= expirationTime) return window.open('https://www.creatosaurus.io?app=analytics', '_self');
    return children
}

export default ProtectedRoutes