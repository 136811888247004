import Styles from './FacebookContentTab.module.css'
const FacebookContentTab = ({ activeTab, onchangeTab,totalPost,loading }) => {
    return (
        <div className={Styles.facebook_content_tab_container} >
            <div className={Styles.tab_main_container} >
                <div onClick={() => onchangeTab('feed')} className={Styles.tabs_container}>
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.125 5.25V13.25C13.125 13.3495 13.0855 13.4448 13.0152 13.5152C12.9448 13.5855 12.8495 13.625 12.75 13.625H4.75C4.65054 13.625 4.55516 13.5855 4.48483 13.5152C4.41451 13.4448 4.375 13.3495 4.375 13.25V5.25C4.375 5.15054 4.41451 5.05516 4.48483 4.98483C4.55516 4.91451 4.65054 4.875 4.75 4.875H12.75C12.8495 4.875 12.9448 4.91451 13.0152 4.98483C13.0855 5.05516 13.125 5.15054 13.125 5.25Z" stroke={activeTab === 'feed' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.25 3H2.875C2.77554 3 2.68016 3.03951 2.60983 3.10983C2.53951 3.18016 2.5 3.27554 2.5 3.375V11.75M4.375 11L7.7775 9.875L13.125 11.75M10.3125 8.625C10.0639 8.625 9.8254 8.52623 9.64959 8.35041C9.47377 8.1746 9.375 7.93614 9.375 7.6875C9.375 7.43886 9.47377 7.2004 9.64959 7.02459C9.8254 6.84877 10.0639 6.75 10.3125 6.75C10.5611 6.75 10.7996 6.84877 10.9754 7.02459C11.1512 7.2004 11.25 7.43886 11.25 7.6875C11.25 7.93614 11.1512 8.1746 10.9754 8.35041C10.7996 8.52623 10.5611 8.625 10.3125 8.625Z" stroke={activeTab === 'feed' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <span style={activeTab === 'feed' ? { color: '#0078FF' } : { color: '#333333' }} >Feed</span>

                </div>

                <div onClick={() => onchangeTab('video')} className={Styles.tabs_container}>
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 1.75C4.04813 1.75 1.25 4.54813 1.25 8C1.25 11.4519 4.04813 14.25 7.5 14.25C10.9519 14.25 13.75 11.4519 13.75 8C13.75 4.54813 10.9519 1.75 7.5 1.75Z" stroke={activeTab === 'video' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.66895 11.9662C2.66895 11.9662 4.06207 10.1875 7.49957 10.1875C10.9371 10.1875 12.3308 11.9662 12.3308 11.9662M7.49957 8C7.99685 8 8.47377 7.80246 8.8254 7.45083C9.17703 7.09919 9.37457 6.62228 9.37457 6.125C9.37457 5.62772 9.17703 5.15081 8.8254 4.79917C8.47377 4.44754 7.99685 4.25 7.49957 4.25C7.00229 4.25 6.52538 4.44754 6.17374 4.79917C5.82211 5.15081 5.62457 5.62772 5.62457 6.125C5.62457 6.62228 5.82211 7.09919 6.17374 7.45083C6.52538 7.80246 7.00229 8 7.49957 8Z" stroke={activeTab === 'video' ? "#0078FF" : "#333333"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>


                    <span style={activeTab === 'video' ? { color: '#0078FF' } : { color: '#333333' }} >Video</span>

                </div>
            </div>
            {loading ? <div className={`${Styles.total_post_header_skeleton} ${Styles.skeleton}`} ></div>:  <div className={Styles.total_post_header} >Total ({totalPost.post} Posts & {totalPost.video} Videos)</div>

            }
           


        </div>
    )
}

export default FacebookContentTab