import React, { useEffect, useContext } from 'react'
import Styles from './Pinterest.module.css'
import { createNavigationContext } from '../../store/navigation'
import PinterestOverview from '../PinterestOverview/PinterestOverview'
import { CreatePinterestAnalyticContext } from "../../store/pinterestAnalytics";
import PinterestContent from '../PinterestContent/PinterestContent';
import { UserNavigationContext } from '../../store/NavigationProvider';
import Report from '../PinterestReport/Report';


const Pinterest = () => {
    const contextOfNavbar = useContext(createNavigationContext)
    const pinterestContext = useContext(CreatePinterestAnalyticContext)
    const userNavigation = useContext(UserNavigationContext)

    useEffect(() => {
        contextOfNavbar.getInfo()
        //pinterestContext.getPinterestAnalyticsData()
        pinterestContext.getPinterestOverviewPageAnalyticaData()
        pinterestContext.getPinterestContentPageAnalyticaData()
        // eslint-disable-next-line
    }, [userNavigation.activeSocialMediaName])



    return (
        <div className={Styles.pinterest_overview_container} >
            {contextOfNavbar.active === 'overview' && <PinterestOverview />}
            {contextOfNavbar.active === 'content' && <PinterestContent />}
            {contextOfNavbar.active === 'reports' && <Report />}
        </div>
    )
}

export default Pinterest