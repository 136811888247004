import React, { useContext, useState } from 'react'
import Styles from './EditKeyword.module.css'
import { CreateInstagramAnalyticContext } from '../../store/InstagramAnalytics'
import { toast } from 'react-toastify'
import axios from 'axios'
import { url } from '../../Util/constant'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import { UserNavigationContext } from '../../store/NavigationProvider'
const EditKeyword = ({ close }) => {
    const instagramContext = useContext(CreateInstagramAnalyticContext)
    const userContext = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [keyword, setkeyword] = useState('')
    const [isButtonEnable, setisButtonEnable] = useState(false)

    const onClickSave = async () => {
        try {
            const inUser = userContext.accounts.filter(data => data.accountType === "instagram" && data.name === userNavigation.activeSocialMediaName);
            const socialId = inUser[0]?.socialId
            const workspaceId = localStorage.getItem('organizationId')

            const body = {
                keyword: instagramContext?.keywordAdded,
                socialId: socialId,
                workspaceId: workspaceId
            }

            const res = await axios.post(`${url}analytics/v2/keyword/insert`, body)
            instagramContext.onChangeconfirmKeywordAdded(res.data.data.keyword)
            if (res.status === 201) {
                toast.success('keyword save successfully!')
            } else {
                toast.success('keywords already saved!')
            }
            close()
        } catch (error) {
            toast.error('something went wrong!')
        }
    }
    const onClickAdd = () => {
        if (keyword === '') {
            toast.error('please add some keyword');
            return
        }

        if (instagramContext?.keywordAdded?.includes(keyword)) {
            return
        } else {
            instagramContext.onChangeKeywordAdded(keyword)
            setkeyword('')
            setisButtonEnable(true)
        }
    }

    const onClickDelete = async (keyword) => {
        const filterKeywords = instagramContext?.keywordAdded?.filter(data => data !== keyword)
        instagramContext.deleteKeywordAdded(filterKeywords)
        setisButtonEnable(true)
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Call your new function here
            onClickAdd()
        }
    };

    const onClickCancel = () => {
        if (instagramContext?.keywordAdded.length > instagramContext.confirmKeywordAdded.length) {
            const recentlyAddedKeyword = instagramContext.confirmKeywordAdded
            const recentKeyword = instagramContext.keywordAdded.map(data=>(
                recentlyAddedKeyword.includes(data) ? data : null
            ))
            // const newAddedKeyword = instagramContext?.keywordAdded?.filter(data => !instagramContext.confirmKeywordAdded.includes(data))
            // const filterNewlyAddedKeyword = instagramContext?.keywordAdded?.filter(data => !newAddedKeyword.includes(data))
            instagramContext.onChangeAllKeywordAdded(recentKeyword?.filter(data => data !== null))

        } else {
            const recenDeleted = instagramContext.keywordAdded
            const afterDeletedFilter = instagramContext.confirmKeywordAdded.map(data=>(
                !recenDeleted.includes(data) ? data : null
            ))
            const combineDeleteKeyword = [...instagramContext?.keywordAdded, ...afterDeletedFilter.filter(data => data !== null)]
            instagramContext.onChangeAllKeywordAdded(combineDeleteKeyword)
        }
        close()
    }

    return (
        <div className={Styles.edit_keyword_main_container} >

            <div className={Styles.keyword_container} >

                <div className={Styles.top_header} >
                    <h4>Keyword List</h4>

                    <svg onClick={() => onClickCancel()} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.41309 15.0874L11.0007 10.4997L15.5883 15.0874M15.5883 5.91211L10.9998 10.4997L6.41309 5.91211" stroke="#808080" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>

                <div className={Styles.paragraph} >Edit your Keyword list to benchmark your Page’s performance.</div>

                <div className={Styles.remaining_competitor_count_header} >{10 - instagramContext.confirmKeywordAdded.length} Pages left to add</div>

                <div className={Styles.search_bar_container} >
                    <input onKeyPress={handleEnterKeyPress} value={keyword} onChange={(e) => setkeyword(e.target.value)} type="text" placeholder='Keyword' />
                    <svg style={{ cursor: 'pointer' }} onClick={() => onClickAdd()} width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M8 12H12M16 12H12M12 12V8M12 12V16" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </div>

                <div style={isButtonEnable ? {} : { height: 200 }} className={Styles.competitor_list_item_container} >
                    {instagramContext?.keywordAdded?.map(data => {
                        return (
                            <div className={Styles.competitor_list_item} >
                                <div className={Styles.keyword_name} >{data}</div>
                                <div onClick={() => onClickDelete(data)} className={Styles.delete_icon_container} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M3.5 10.5C3.225 10.5 2.98958 10.4021 2.79375 10.2063C2.59792 10.0104 2.5 9.775 2.5 9.5V3H2V2H4.5V1.5H7.5V2H10V3H9.5V9.5C9.5 9.775 9.40208 10.0104 9.20625 10.2063C9.01042 10.4021 8.775 10.5 8.5 10.5H3.5ZM8.5 3H3.5V9.5H8.5V3ZM4.5 8.5H5.5V4H4.5V8.5ZM6.5 8.5H7.5V4H6.5V8.5Z" fill="#C4C4C4" />
                                    </svg>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                
                {isButtonEnable ?
                    <div className={Styles.bottom_button_container} >
                        <button onClick={() => onClickCancel()} >Cancel</button>
                        <button onClick={() => onClickSave()} >Save</button>
                    </div> :
                    <></>

                }
            </div>
        </div>
    )
}

export default EditKeyword