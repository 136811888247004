import { createContext, useContext, useState } from 'react'
import { UserConnectedAccountContext } from './UserConnectedAccount'
import axios from 'axios'
import { UserNavigationContext } from './NavigationProvider'
import { url } from '../Util/constant'
import variables from '../Util/variables'
import { CommonSocialMediaContext } from './CommonSocialMediaStore'
import { toast } from 'react-toastify'
import APICall from '../services/api.service'
import ColorThief from "colorthief";

export const CreateFacebookAnalyticContext = createContext({
    selectedPeriod: '',
    loading: '',
    overviewStats: null,
    allPosts: [],
    dateArray: [],
    onlineFans: [],
    onlineFansLastWeek: [],
    onlineFansObject: null,
    onlineFansResponse: '',
    impressions: '',
    pageView: '',
    websiteClicks: '',
    allVideos: '',
    audienceStats: [],
    filterPeriod: '',
    overviewPageLoading: true,
    contentPageLoading: true,
    accountPageLoading: true,
    latestPostDetails: '',
    overviewPageFilterLoading: false,
    contentPageFilterLoading: false,
    competitorsData: '',
    lineChartData: [],
    getCompetitorsData: () => { },
    competitors: '',
    handleCompetitor: () => { },
    changeCompetitors: () => { },
    addPageToCompetitors: () => { },
    deleteCompetitors: () => { },
    saveInstagramCompetitors: () => { },
    search: '',
    setsearch: () => { },
    addCompetitorsLoader: '',
    setaddCompetitorsLoader: () => { },
    showSearchList: '',
    setshowSearchList: () => { },
    searchList: '',
    setsearchList: () => { },
    searchCompetitors: () => { },

    handleSelectedPeriod: () => { },
    handleLoading: () => { },
    getFacebookAnalyticsData: () => { },
    handleAllPosts: () => { },
    handleDateArray: () => { },
    getFacebookAnalyticsDataAsPerPeriod: () => { },
    setfilterPeriod: () => { },
    changeLoadingState: () => { },
    getFacebookOverviewPageAnalyticaData: () => { },
    getFacebookContentPageAnalyticaData: () => { },
    getFacebookAccountPageAnalyticaData: () => { },
    getFacebookCompetitorPageAnalyticaData: () => { },
    onChangeoverviewPageFilterLoading: () => { },
    onChangecontentPageFilterLoading: () => { },
    searchCompetitorLoading: '',
    saveCompetitorsLoading: '',
    competitorPageLoading: '',
    selectedReportDate: '',
    onChangesetselectedReportDate: () => { },
    reportWeekArray: [],
    borderColorArray: [],
    stackedDataCompetitor: [],
    hideCompetitors: () => { },
    stackedDataContent: '',
    clearState:()=>{ }
})


export const CreateFacebookAnalyticProvider = ({ children }) => {
    const commonSocialMediaContext = useContext(CommonSocialMediaContext)
    const userContext = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [selectedPeriod, setSelectedPeriod] = useState(31);
    const [loading, setLoading] = useState(true);
    const [dateArray, setDateArray] = useState([])
    const [overviewStats, setOverviewStats] = useState(null)
    const [allPosts, setAllPosts] = useState([])
    const [allVideos, setallVideos] = useState([])

    const [onlineFans, setOnlineFans] = useState([])
    const [onlineFansLastWeek, setOnlineFansLastWeek] = useState([])
    const [onlineFansObject, setOnlineFansObject] = useState(null)
    const [impressions, setimpressions] = useState([])
    const [pageView, setpageView] = useState([])
    const [websiteClicks, setwebsiteClicks] = useState([])
    const [audienceStats, setAudeienceStats] = useState([])
    const [filterPeriod, setfilterPeriod] = useState(false)
    const [latestPostDetails, setlatestPostDetails] = useState('')
    const [onlineFansResponse, setonlineFansResponse] = useState('')

    const [overviewPageLoading, setoverviewPageLoading] = useState(true)
    const [contentPageLoading, setcontentPageLoading] = useState(true)
    const [accountPageLoading, setaccountPageLoading] = useState(true)

    const [overviewPageFilterLoading, setoverviewPageFilterLoading] = useState(false)
    const [contentPageFilterLoading, setcontentPageFilterLoading] = useState(false)
    const [competitorsData, setcompetitorsData] = useState([])
    const [competitors, setcompetitors] = useState([])
    const [addCompetitorsLoader, setaddCompetitorsLoader] = useState(false)
    const [saveCompetitorsLoading, setsaveCompetitorsLoading] = useState(false)
    const [showSearchList, setshowSearchList] = useState(false)
    const [searchList, setsearchList] = useState([])
    const [searchCompetitorLoading, setsearchCompetitorLoading] = useState(false)
    // eslint-disable-next-line
    const [competitorPageLoading, setcompetitorPageLoading] = useState(false)
    const [search, setsearch] = useState('')
    const [reportWeekArray, setreportWeekArray] = useState([])
    const [selectedReportDate, setselectedReportDate] = useState('')
    const [lineChartData, setlineChartData] = useState([])
    const [borderColorArray, setborderColorArray] = useState([])
    const [stackedDataCompetitor, setstackedDataCompetitor] = useState([])
    const [stackedDataContent, setstackedDataContent] = useState([])

    const clearState=()=>{
        setOverviewStats(null)
        setAllPosts([])
        setallVideos([])
        setOnlineFans([])
        setOnlineFansLastWeek([])
        setOnlineFansObject(null)
        setimpressions([])
        setpageView([])
        setwebsiteClicks([])
        setAudeienceStats([])
        setonlineFansResponse('')
        setcompetitorsData([])
        setcompetitors([])
        setreportWeekArray([])
        setlineChartData([])
        setborderColorArray([])
        setstackedDataCompetitor([])
        setstackedDataContent([])
    }
    const componentToHex = (c) => {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    const rgbToHex = (r, g, b) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    const getCompetitorsData = async (competitorData) => {
        const { workspaceId, token, socialId } = getCurrentAndPreviousDate()
        try {

            const currentDate = new Date()
            const startDate = new Date(currentDate);
            startDate.setDate(currentDate.getDate() - 30);
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
            const competitorPromise = competitorData?.map(async (data) => {
                try {
                    if (competitorsData.filter(alreadyCompetitorData => alreadyCompetitorData.id === data.id)[0]?.about !== undefined) {

                        return competitorsData.filter(alreadyCompetitorData => alreadyCompetitorData.id === data.id)[0]
                    } else {
                        const startDateMilliseconds = startDate.getTime();
                        const endDateMilliSeconds = currentDate.getTime();

                        const singleCompetitorsDataFromCacheBackend = await axios.get(`${url}analytics/v2/facebook/getCompetitor/${workspaceId}/${socialId}/${data.id}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, config)

                        return singleCompetitorsDataFromCacheBackend.data.singleCompetitorsData
                    }


                } catch (error) {
                    // Add failed competitor's username to the array

                    // Explicitly return null or similar to indicate failure
                    return null;
                }

            })




            const allcompetitorData = await Promise.all(competitorPromise)
            const lineChartData = allcompetitorData.filter(data => data !== null)?.map(data => {
                return {
                    name: data?.id,

                    allPost: (selectedPeriod !== 30 ? dateArray.slice(-29) : dateArray)?.map(date => {
                        const matchingPost = data?.posts?.data?.filter(post => `${new Date(post.created_time).toLocaleString('default', { month: 'short' })}-${String(new Date(post.created_time).getDate()).padStart(2, '0')}` === date);

                        return matchingPost?.length
                    }).filter(data => data !== undefined && data !== null),
                    allPostLike: (selectedPeriod !== 30 ? dateArray.slice(-29) : dateArray)?.map(date => {
                        const matchingPost = data?.posts?.data?.filter(post => `${new Date(post.created_time).toLocaleString('default', { month: 'short' })}-${String(new Date(post.created_time).getDate()).padStart(2, '0')}` === date).map(data => data.likes.summary.total_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),
                    allPostComment: (selectedPeriod !== 30 ? dateArray.slice(-29) : dateArray)?.map(date => {
                        const matchingPost = data?.posts?.data?.filter(post => `${new Date(post.created_time).toLocaleString('default', { month: 'short' })}-${String(new Date(post.created_time).getDate()).padStart(2, '0')}` === date).map(data => data.comments.summary.total_count);

                        return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                    }).filter(data => data !== undefined && data !== null),

                }
            })
            setlineChartData(lineChartData)

            // Inside your async function
            const borderColorPromises = allcompetitorData
                .filter(data => data !== null)
                .map(data => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.crossOrigin = "anonymous";
                        img.src = data.picture.data.url;
                        img.onload = () => {
                            const colorThief = new ColorThief();
                            const result = colorThief.getColor(img, 25);
                            resolve({ name: data.id, color: rgbToHex(result[0], result[1], result[2]) });
                        };
                        img.onerror = (error) => {
                            reject(error); // Handle image loading errors
                        };
                    });
                });

            // Wait for all promises to resolve
            const borderColorArray = await Promise.all(borderColorPromises);
            setborderColorArray(borderColorArray)
            return { allcompetitorData: allcompetitorData }
        } catch (error) {
            toast.error(error)
            return error
        }
    }

    const addPageToCompetitors = async (value) => {
        try {
            const competitorObj = {
                ...value,
                hideView: false
            }
            setcompetitors(prev => [competitorObj, ...prev])

            toast('competitor added successfully.')
            setsearch('')
            setshowSearchList(false)

        } catch (error) {
            if (error?.response?.data?.error?.message === "Invalid user id") {
                toast(error?.response?.data?.error?.message === "Invalid user id" ? "competitor not found" : error?.response?.data?.error?.message)
            }
            setsearch('')
            toast.error(error)
        }
    }

    const changeCompetitorsLoading = (value) => {
        setaddCompetitorsLoader(value)
    }

    const changeCompetitors = (value) => {
        setcompetitors((prev) => [...prev, value])
    }

    const searchCompetitors = async (value) => {
        try {
            const { socialId, workspaceId, token } = getCurrentAndPreviousDate()
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
            setsearchCompetitorLoading(true)
            const res = await axios.get(`${url}analytics/v2/facebook/searchCompetitor/${workspaceId}/${socialId}/${value}/facebookpage`, config)
            setsearchList(res.data.pageDetails)
            setshowSearchList(true)
            setsearchCompetitorLoading(false)
        } catch (error) {
            setsearchCompetitorLoading(false)
            setshowSearchList(false)
            toast(error?.response?.data?.error?.message === "Invalid user id" ? "competitor not found" : error?.response?.data?.error?.message)
            setsearch('')

        }
    }

    const saveInstagramCompetitors = async (competiorAdded) => {
        const { socialId } = getCurrentAndPreviousDate()
        try {
            setsaveCompetitorsLoading(true)
            await APICall(`https://api.cache.creatosaurus.io/cache/analytics/instagram/insertInstagramCompetitors`, {
                competitorsData: competitors.map(data => { return { name: data?.name, profile_picture_url: data?.picture?.data?.url !== undefined ? data?.picture?.data?.url : data?.profile_picture_url, id: data?.id, hideView: data?.hideView, is_verified: data.is_verified } }),
                socialMediaAccessToken: '',
                workspaceId: userContext?.userData?.activeWorkspace?._id,
                socialMediaId: socialId
            }, 2, false)
            const competitorDataFromRes = await getCompetitorsData(competitors)
            setcompetitorsData(competitorDataFromRes.allcompetitorData)
            if (competitors?.length > competitorsData?.length) {
                const { startDateMilliseconds, endDateMilliSeconds, token, workspaceId } = getCurrentAndPreviousDate()
                let allCompetitorPostData = []
                const addedPostId = stackedDataCompetitor.map(data => data.postId)

                competitorDataFromRes.allcompetitorData.forEach(data => {
                    data?.posts?.data?.forEach(media => {
                        const postObj = { caption: media?.message, id: media?.id, timestamp: media?.created_time, socialId: data?.id }
                        allCompetitorPostData.push(postObj)
                    })
                })

                const requestBody = {
                    workspaceId: workspaceId,
                    token: token,
                    media: allCompetitorPostData.filter(data => !addedPostId.includes(data.id)),
                    startDateMilliseconds: startDateMilliseconds,
                    endDateMilliSeconds: endDateMilliSeconds
                }


                const resOfCategory = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
                const addNewCompetitorCategory = stackedDataCompetitor.concat(...resOfCategory.data.data)

                setstackedDataCompetitor(addNewCompetitorCategory)
            } else {
                const removedCompetitors = competitors.map(data => data.id)

                setstackedDataCompetitor(stackedDataCompetitor.filter(data => removedCompetitors.includes(data.socialId)))
            }
            setsaveCompetitorsLoading(false)
        } catch (error) {
            console.log(error)
            setsaveCompetitorsLoading(false)
            toast.error(error)
            return error
        }
    }

    const handleCompetitor = (value) => {
        setcompetitors(value)
    }


    const deleteCompetitors = (competitor) => {
        const filterCompetitors = competitors.filter(data => data.name !== competitor)
        setcompetitors(filterCompetitors)
    }

    const changesearch = (value) => {
        setsearch(value)
    }


    const onChangeoverviewPageFilterLoading = (value) => {
        setoverviewPageFilterLoading(value)
    }

    const onChangecontentPageFilterLoading = (value) => {
        setcontentPageFilterLoading(value)
    }

    const changeLoadingState = (value) => {
        setLoading(value)
    }

    const changefilterPeriod = (value) => {
        setfilterPeriod(value)
    }


    const getFacebookAnalyticsData = async () => {
        try {
            if (loading === false)
                return

            const fbUser = userContext.accounts.filter(data => data.accountType === "facebookpage" && data.name === userNavigation.activeSocialMediaName);


            const isAccountExits = userContext.accountsData.filter(data => data?.id === fbUser[0].socialId && data.selectedPeriod?.toString() === selectedPeriod.toString())

            if (isAccountExits.length) {
                setSelectedPeriod(isAccountExits[0].selectedPeriod)
                setDateArray(isAccountExits[0].dateArray)
                setlatestPostDetails(isAccountExits[0].latestPostDetails)
                setAllPosts(isAccountExits[0].postData)
                setallVideos(isAccountExits[0].videoData)
                setpageView(isAccountExits[0].pageViewsResponse)
                setwebsiteClicks(isAccountExits[0].clicksResponse)
                setimpressions(isAccountExits[0].impressionsResponse)
                setOverviewStats(isAccountExits[0].overviewStats)
                setAudeienceStats(isAccountExits[0].audienceResponse?.data)
                setOnlineFansLastWeek(isAccountExits[0].online_follower_last_week)
                setOnlineFansObject(isAccountExits[0].online_fans_object)
                setOnlineFans(isAccountExits[0].onlineFans)
                setonlineFansResponse(isAccountExits[0].onlineFansResponse)
                setLoading(false)
            } else {

                const currentDate = new Date();
                const startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - selectedPeriod);

                const startDateMilliseconds = startDate.getTime();

                const endDateMilliSeconds = currentDate.getTime();

                const startDateOfOnlineFans = new Date(currentDate);
                startDateOfOnlineFans.setDate(currentDate.getDate() - 14);

                const startDateForOnlineFansMiliSeconds = startDateOfOnlineFans.getTime();

                const previousDate = new Date(startDate);
                previousDate.setDate(startDate.getDate() - selectedPeriod);
                const previousDateStartDateMiliSec = previousDate.getTime();

                const dateArray = [];
                const filterDateArray = [];

                for (let i = 0; i < (selectedPeriod); i++) {
                    const newDate = new Date(startDate);
                    newDate.setDate(startDate.getDate() + i);
                    const monthShortName = newDate.toLocaleString('default', { month: 'short' });
                    const day = String(newDate.getDate()).padStart(2, '0');
                    dateArray.push(`${monthShortName}-${day}`);
                    const dateInMilliseconds = newDate.setHours(0, 0, 0, 0);
                    filterDateArray.push(dateInMilliseconds);
                }

                setDateArray(dateArray)
                const workspaceId = localStorage.getItem('organizationId')
                const getFacebookAnalyticsDataFromBackend = await axios.post(`${url}analytics/facebook/getFacebookAnalyticsData`, {
                    "startDateMilliseconds": startDateMilliseconds.toString().slice(0, 10),
                    "endDateMilliSeconds": endDateMilliSeconds.toString().slice(0, 10),
                    "previousDateStartDateMiliSec": previousDateStartDateMiliSec.toString().slice(0, 10),
                    "startDate": startDateForOnlineFansMiliSeconds.toString().slice(0, 10),
                    "endDate": endDateMilliSeconds.toString().slice(0, 10),
                    "accountType": "facebookpage",
                    "socialId": fbUser[0].socialId,
                    "workspaceId": workspaceId
                })



                if (variables.leftSideBarState === fbUser[0].socialId) {

                    setLoading(true)
                    setlatestPostDetails(getFacebookAnalyticsDataFromBackend.data.latestPostDetails)


                    setAllPosts(getFacebookAnalyticsDataFromBackend.data.postData)


                    setallVideos(getFacebookAnalyticsDataFromBackend.data.videoData)




                    const clicksResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0]?.values || 0
                    const prevClicksResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0]?.values || 0
                    const likesResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_fans")[0]?.values || 0

                    const likesPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_fans")[0]?.values || 0
                    const pageViewsResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_views_total")[0]?.values || 0
                    const pageViewsPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_views_total")[0]?.values || 0
                    const impressionsResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_impressions")[0]?.values || 0
                    const impressionsPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_impressions")[0]?.values || 0
                    const onlineFansResponse = (getFacebookAnalyticsDataFromBackend.data.onlineFansResponse?.data[0]?.values.filter(data => Object.values(data.value)?.length)?.length > 7 ? getFacebookAnalyticsDataFromBackend.data.onlineFansResponse.data[0]?.values : [])


                    const calculateStats = (data) => data.reduce((value, entry) => value + entry.value, 0);

                    const previousDateWebsiteClicksSum = calculateStats(prevClicksResponse);
                    const currentDateWebsiteClicks = calculateStats(clicksResponse);
                    const previousPageViewsSum = calculateStats(pageViewsPrevResponse);
                    const currentPageViewsSum = calculateStats(pageViewsResponse);
                    const previousImpressionsSum = calculateStats(impressionsPrevResponse);
                    const currentImpressionsSum = calculateStats(impressionsResponse);

                    setpageView(pageViewsResponse)
                    setwebsiteClicks(clicksResponse)
                    setimpressions(impressionsResponse)

                    setOverviewStats({
                        websiteClicks: clicksResponse.reduce((totalClicks, data) => { return totalClicks += data.value }, 0) || 0,
                        impressions: impressionsResponse.reduce((totalImpression, data) => { return totalImpression += data.value }, 0) || 0,
                        likes: likesResponse[likesResponse?.length - 1]?.value || 0,
                        pageViews: pageViewsResponse.reduce((totalPageView, data) => { return totalPageView += data.value }, 0) || 0,
                        newPageLikes: likesResponse[likesResponse?.length - 1]?.value || 0 - likesPrevResponse[likesPrevResponse?.length - 1]?.value || 0,
                        websiteClicksPercentage: (((currentDateWebsiteClicks - previousDateWebsiteClicksSum) / (previousDateWebsiteClicksSum || 1)) * 100).toFixed(1),
                        likesPercentage: ((likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value) / (likesPrevResponse[likesPrevResponse?.length - 1]?.value || 1) * 100).toFixed(1),
                        pageViewsPercentage: (((currentPageViewsSum - previousPageViewsSum) / (previousPageViewsSum || 1)) * 100).toFixed(1),
                        impressionsPercentage: (((currentImpressionsSum - previousImpressionsSum) / (previousImpressionsSum || 1)) * 100).toFixed(1),
                        likesData: likesResponse
                    });
                    setAudeienceStats(getFacebookAnalyticsDataFromBackend.data.audienceResponse.data)


                    let maxObjectForWeek = null;
                    const online_follower_last_week = []

                    setonlineFansResponse(onlineFansResponse)
                    onlineFansResponse.slice(6, (onlineFansResponse?.length - 1)).forEach(data => {
                        if (Object.keys(data.value)?.length > 0) {
                            maxObjectForWeek = data

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = Object.keys(values);
                                const timeRange = timeKeys.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        } else {
                            const filterDate = new Date(data.end_time)
                            const startDateOfOnlineFollower = new Date(filterDate)
                            startDateOfOnlineFollower.setDate(filterDate.getDate() - 7)
                            const replaceDateForStartDate = onlineFansResponse.filter(onlineFolloweData => new Date(onlineFolloweData.end_time).setHours(0, 0, 0, 0) === startDateOfOnlineFollower.setHours(0, 0, 0, 0))[0]

                            maxObjectForWeek = replaceDateForStartDate

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = Object.keys(values);
                                const timeRange = timeKeys.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        }



                    })

                    setOnlineFansLastWeek(online_follower_last_week)




                    let maxObject = null;
                    let online_follower = []

                    if (onlineFansResponse?.length) {

                        maxObject = onlineFansResponse[6]
                        const timeRanges = [
                            { startTime: 0, endTime: 2 },
                            { startTime: 2, endTime: 4 },
                            { startTime: 4, endTime: 6 },
                            { startTime: 6, endTime: 8 },
                            { startTime: 8, endTime: 10 },
                            { startTime: 10, endTime: 12 },
                            { startTime: 12, endTime: 14 },
                            { startTime: 14, endTime: 16 },
                            { startTime: 16, endTime: 18 },
                            { startTime: 18, endTime: 20 },
                            { startTime: 20, endTime: 22 },
                            { startTime: 22, endTime: 24 },
                        ];

                        function displayValuesInTimeRange(startTime, endTime) {
                            const values = maxObject?.value;
                            const timeKeys = Object.keys(values);
                            const timeRange = timeKeys.filter((key) => {
                                const hour = parseInt(key, 10);
                                return hour >= startTime && hour < endTime;
                            });


                            const timeRangeValues = timeRange.map((key) => values[key]);
                            const obj = {
                                time: '',
                                online_follower: '',
                                date: ''
                            }
                            obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            obj.online_follower = timeRangeValues.reduce((totalActive, data) => { return totalActive += data }, 0)
                            obj.date = maxObject.end_time
                            online_follower.push(obj)

                        }

                        timeRanges.forEach((timeRange) => {
                            displayValuesInTimeRange(timeRange.startTime, timeRange.endTime);
                        });

                    }





                    setOnlineFansObject(maxObject)
                    setOnlineFans(online_follower)


                    const obj = {
                        id: fbUser[0].socialId,
                        selectedPeriod: selectedPeriod.toString(),
                        dateArray: dateArray,
                        latestPostDetails: getFacebookAnalyticsDataFromBackend.data.latestPostDetails,
                        postData: getFacebookAnalyticsDataFromBackend.data.postData,
                        videoData: getFacebookAnalyticsDataFromBackend.data.videoData,
                        pageViewsResponse: pageViewsResponse,
                        clicksResponse: clicksResponse,
                        impressionsResponse: impressionsResponse,
                        audienceResponse: getFacebookAnalyticsDataFromBackend.data.audienceResponse,
                        overviewStats: {
                            websiteClicks: clicksResponse.reduce((totalClicks, data) => { return totalClicks += data.value }, 0) || 0,
                            impressions: impressionsResponse.reduce((totalImpression, data) => { return totalImpression += data.value }, 0) || 0,
                            likes: likesResponse[likesResponse?.length - 1]?.value || 0,
                            pageViews: pageViewsResponse.reduce((totalPageView, data) => { return totalPageView += data.value }, 0) || 0,
                            newPageLikes: likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value || 0,
                            websiteClicksPercentage: (((currentDateWebsiteClicks - previousDateWebsiteClicksSum) / (previousDateWebsiteClicksSum || 1)) * 100).toFixed(1),
                            likesPercentage: ((likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value) / (likesPrevResponse[likesPrevResponse?.length - 1]?.value || 1) * 100).toFixed(1),
                            pageViewsPercentage: (((currentPageViewsSum - previousPageViewsSum) / (previousPageViewsSum || 1)) * 100).toFixed(1),
                            impressionsPercentage: (((currentImpressionsSum - previousImpressionsSum) / (previousImpressionsSum || 1)) * 100).toFixed(1),
                            likesData: likesResponse
                        },
                        online_follower_last_week: online_follower_last_week,
                        online_fans_object: maxObject,
                        onlineFans: online_follower,
                        onlineFansResponse: onlineFansResponse

                    }


                    userContext.handleaccountsData(obj)

                    setLoading(false)

                } else {



                    const clicksResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0]?.values
                    const prevClicksResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0]?.values
                    const likesResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_fans")[0]?.values

                    const likesPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_fans")[0]?.values
                    const pageViewsResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_views_total")[0]?.values
                    const pageViewsPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_views_total")[0]?.values
                    const impressionsResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_impressions")[0]?.values
                    const impressionsPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_impressions")[0]?.values
                    const onlineFansResponse = (getFacebookAnalyticsDataFromBackend.data.onlineFansResponse?.data[0]?.values.filter(data => Object.values(data.value)?.length)?.length > 7 ? getFacebookAnalyticsDataFromBackend.data.onlineFansResponse.data[0]?.values : [])


                    const calculateStats = (data) => data.reduce((value, entry) => value + entry.value, 0);

                    const previousDateWebsiteClicksSum = calculateStats(prevClicksResponse);
                    const currentDateWebsiteClicks = calculateStats(clicksResponse);
                    const previousPageViewsSum = calculateStats(pageViewsPrevResponse);
                    const currentPageViewsSum = calculateStats(pageViewsResponse);
                    const previousImpressionsSum = calculateStats(impressionsPrevResponse);
                    const currentImpressionsSum = calculateStats(impressionsResponse);

                    let maxObjectForWeek = null;
                    const online_follower_last_week = []


                    onlineFansResponse.slice(6, (onlineFansResponse?.length - 1)).forEach(data => {
                        if (Object.keys(data.value)?.length > 0) {
                            maxObjectForWeek = data

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = Object.keys(values);
                                const timeRange = timeKeys.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        } else {
                            const filterDate = new Date(data.end_time)
                            const startDateOfOnlineFollower = new Date(filterDate)
                            startDateOfOnlineFollower.setDate(filterDate.getDate() - 7)
                            const replaceDateForStartDate = onlineFansResponse.filter(onlineFolloweData => new Date(onlineFolloweData.end_time).setHours(0, 0, 0, 0) === startDateOfOnlineFollower.setHours(0, 0, 0, 0))[0]

                            maxObjectForWeek = replaceDateForStartDate

                            const objForLastWeek = {
                                date: '',
                                value: []
                            }
                            const timeRangesLastWeek = [
                                { startTime: 0, endTime: 2 },
                                { startTime: 2, endTime: 4 },
                                { startTime: 4, endTime: 6 },
                                { startTime: 6, endTime: 8 },
                                { startTime: 8, endTime: 10 },
                                { startTime: 10, endTime: 12 },
                                { startTime: 12, endTime: 14 },
                                { startTime: 14, endTime: 16 },
                                { startTime: 16, endTime: 18 },
                                { startTime: 18, endTime: 20 },
                                { startTime: 20, endTime: 22 },
                                { startTime: 22, endTime: 24 },
                            ];

                            function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                                const values = maxObjectForWeek?.value;
                                const timeKeys = Object.keys(values);
                                const timeRange = timeKeys.filter((key) => {
                                    const hour = parseInt(key, 10);
                                    return hour >= startTime && hour < endTime;
                                });


                                const timeRangeValues = timeRange?.map((key) => values[key]);
                                const obj = {
                                    time: '',
                                    online_follower: '',
                                    date: ''
                                }
                                obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                                obj.date = maxObjectForWeek.end_time

                                let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                                objForLastWeek.date = maxObjectForWeek.end_time
                                objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                            }

                            timeRangesLastWeek.forEach((timeRange) => {
                                displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                            });
                            online_follower_last_week.push(objForLastWeek)
                        }



                    })





                    let maxObject = null;
                    let online_follower = []

                    if (onlineFansResponse?.length) {

                        maxObject = onlineFansResponse[6]
                        const timeRanges = [
                            { startTime: 0, endTime: 2 },
                            { startTime: 2, endTime: 4 },
                            { startTime: 4, endTime: 6 },
                            { startTime: 6, endTime: 8 },
                            { startTime: 8, endTime: 10 },
                            { startTime: 10, endTime: 12 },
                            { startTime: 12, endTime: 14 },
                            { startTime: 14, endTime: 16 },
                            { startTime: 16, endTime: 18 },
                            { startTime: 18, endTime: 20 },
                            { startTime: 20, endTime: 22 },
                            { startTime: 22, endTime: 24 },
                        ];

                        function displayValuesInTimeRange(startTime, endTime) {
                            const values = maxObject?.value;
                            const timeKeys = Object.keys(values);
                            const timeRange = timeKeys.filter((key) => {
                                const hour = parseInt(key, 10);
                                return hour >= startTime && hour < endTime;
                            });


                            const timeRangeValues = timeRange.map((key) => values[key]);
                            const obj = {
                                time: '',
                                online_follower: '',
                                date: ''
                            }
                            obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            obj.online_follower = timeRangeValues.reduce((totalActive, data) => { return totalActive += data }, 0)
                            obj.date = maxObject.end_time
                            online_follower.push(obj)

                        }

                        timeRanges.forEach((timeRange) => {
                            displayValuesInTimeRange(timeRange.startTime, timeRange.endTime);
                        });

                    }

                    const obj = {
                        id: fbUser[0].socialId,
                        selectedPeriod: selectedPeriod.toString(),
                        dateArray: dateArray,
                        latestPostDetails: getFacebookAnalyticsDataFromBackend.data.latestPostDetails,
                        postData: getFacebookAnalyticsDataFromBackend.data.postData,
                        videoData: getFacebookAnalyticsDataFromBackend.data.videoData,
                        pageViewsResponse: pageViewsResponse,
                        clicksResponse: clicksResponse,
                        impressionsResponse: impressionsResponse,
                        audienceResponse: getFacebookAnalyticsDataFromBackend.data.audienceResponse,
                        overviewStats: {
                            websiteClicks: clicksResponse.reduce((totalClicks, data) => { return totalClicks += data.value }, 0) || 0,
                            impressions: impressionsResponse.reduce((totalImpression, data) => { return totalImpression += data.value }, 0) || 0,
                            likes: likesResponse[likesResponse?.length - 1]?.value || 0,
                            pageViews: pageViewsResponse.reduce((totalPageView, data) => { return totalPageView += data.value }, 0) || 0,
                            newPageLikes: likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value || 0,
                            websiteClicksPercentage: (((currentDateWebsiteClicks - previousDateWebsiteClicksSum) / (previousDateWebsiteClicksSum || 1)) * 100).toFixed(1),
                            likesPercentage: ((likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value) / (likesPrevResponse[likesPrevResponse?.length - 1]?.value || 1) * 100).toFixed(1),
                            pageViewsPercentage: (((currentPageViewsSum - previousPageViewsSum) / (previousPageViewsSum || 1)) * 100).toFixed(1),
                            impressionsPercentage: (((currentImpressionsSum - previousImpressionsSum) / (previousImpressionsSum || 1)) * 100).toFixed(1),
                            likesData: likesResponse
                        },
                        online_follower_last_week: online_follower_last_week,
                        online_fans_object: maxObject,
                        onlineFans: online_follower,
                        onlineFansResponse: onlineFansResponse

                    }


                    userContext.handleaccountsData(obj)




                }
            }

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const getFacebookAnalyticsDataAsPerPeriod = async () => {
        try {
            if (filterPeriod === false) return

            setLoading(true)
            const fbUser = userContext.accounts.filter(data => data.accountType === "facebookpage" && data.name === userNavigation.activeSocialMediaName);
            const isAccountsExits = userContext.accountsData.filter(data => data?.id === fbUser[0].socialId && data.selectedPeriod?.toString() === selectedPeriod.toString())

            if (isAccountsExits.length) {

                setSelectedPeriod(isAccountsExits[0].selectedPeriod)
                setDateArray(isAccountsExits[0].dateArray)
                setlatestPostDetails(isAccountsExits[0].latestPostDetails)
                setAllPosts(isAccountsExits[0].postData)
                setallVideos(isAccountsExits[0].videoData)
                setpageView(isAccountsExits[0].pageViewsResponse)
                setwebsiteClicks(isAccountsExits[0].clicksResponse)
                setimpressions(isAccountsExits[0].impressionsResponse)
                setOverviewStats(isAccountsExits[0].overviewStats)
                setAudeienceStats(isAccountsExits[0].audienceResponse?.data)
                setOnlineFansLastWeek(isAccountsExits[0].online_follower_last_week)
                setOnlineFansObject(isAccountsExits[0].online_fans_object)
                setOnlineFans(isAccountsExits[0].onlineFans)
                setonlineFansResponse(isAccountsExits[0].onlineFansResponse)


                setLoading(false)
            } else {
                const currentDate = new Date();
                const startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - selectedPeriod);


                const startDateMilliseconds = startDate.getTime();
                const endDateMilliSeconds = currentDate.getTime();

                const startDateOfOnlineFans = new Date(currentDate);
                startDateOfOnlineFans.setDate(currentDate.getDate() - 14);

                const startDateForOnlineFansMiliSeconds = startDateOfOnlineFans.getTime();

                const previousDate = new Date(startDate);
                previousDate.setDate(startDate.getDate() - selectedPeriod);
                const previousDateStartDateMiliSec = previousDate.getTime();

                const dateArray = [];
                const filterDateArray = [];

                for (let i = 0; i < (selectedPeriod); i++) {
                    const newDate = new Date(startDate);
                    newDate.setDate(startDate.getDate() + i);
                    const monthShortName = newDate.toLocaleString('default', { month: 'short' });
                    const day = String(newDate.getDate()).padStart(2, '0');
                    dateArray.push(`${monthShortName}-${day}`);
                    const dateInMilliseconds = newDate.setHours(0, 0, 0, 0);
                    filterDateArray.push(dateInMilliseconds);
                }

                setDateArray(dateArray)
                const workspaceId = localStorage.getItem('organizationId')
                const getFacebookAnalyticsDataFromBackend = await axios.post(`${url}analytics/facebook/getFacebookAnalyticsData`, {
                    "startDateMilliseconds": startDateMilliseconds.toString().slice(0, 10),
                    "endDateMilliSeconds": endDateMilliSeconds.toString().slice(0, 10),
                    "previousDateStartDateMiliSec": previousDateStartDateMiliSec.toString().slice(0, 10),
                    "startDate": startDateForOnlineFansMiliSeconds.toString().slice(0, 10),
                    "endDate": endDateMilliSeconds.toString().slice(0, 10),
                    "accountType": "facebookpage",
                    "socialId": fbUser[0].socialId,
                    "workspaceId": workspaceId
                })




                setAllPosts(getFacebookAnalyticsDataFromBackend.data.postData)


                setallVideos(getFacebookAnalyticsDataFromBackend.data.videoData)





                const clicksResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0]?.values || 0
                const prevClicksResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0]?.values || 0
                const likesResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_fans")[0]?.values || 0
                const likesPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_fans")[0]?.values || 0
                const pageViewsResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_views_total")[0]?.values || 0
                const pageViewsPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_views_total")[0]?.values || 0
                const impressionsResponse = getFacebookAnalyticsDataFromBackend.data.facebookCurrentMetricsResponse.data.filter(data => data.name === "page_impressions")[0]?.values || 0
                const impressionsPrevResponse = getFacebookAnalyticsDataFromBackend.data.facebookPreviousMetricsResponse.data.filter(data => data.name === "page_impressions")[0]?.values || 0
                const onlineFansResponse = (getFacebookAnalyticsDataFromBackend.data.onlineFansResponse?.data[0]?.values.filter(data => Object.values(data.value).length).length > 7 ? getFacebookAnalyticsDataFromBackend.data.onlineFansResponse.data[0]?.values : [])
                const calculateStats = (data) => data.reduce((value, entry) => value + entry.value, 0);

                const previousDateWebsiteClicksSum = calculateStats(prevClicksResponse);
                const currentDateWebsiteClicks = calculateStats(clicksResponse);
                const previousPageViewsSum = calculateStats(pageViewsPrevResponse);
                const currentPageViewsSum = calculateStats(pageViewsResponse);
                const previousImpressionsSum = calculateStats(impressionsPrevResponse);
                const currentImpressionsSum = calculateStats(impressionsResponse);

                setpageView(pageViewsResponse)
                setwebsiteClicks(clicksResponse)
                setimpressions(impressionsResponse)

                setOverviewStats({
                    websiteClicks: clicksResponse.reduce((totalClicks, data) => { return totalClicks += data.value }, 0) || 0,
                    impressions: impressionsResponse.reduce((totalImpression, data) => { return totalImpression += data.value }, 0) || 0,
                    likes: likesResponse[likesResponse?.length - 1]?.value || 0,
                    pageViews: pageViewsResponse.reduce((totalPageView, data) => { return totalPageView += data.value }, 0) || 0,
                    newPageLikes: likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value || 0,
                    websiteClicksPercentage: (((currentDateWebsiteClicks - previousDateWebsiteClicksSum) / (previousDateWebsiteClicksSum || 1)) * 100).toFixed(1),
                    likesPercentage: ((likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value) / (likesPrevResponse[likesPrevResponse?.length - 1]?.value || 1) * 100).toFixed(1),
                    pageViewsPercentage: (((currentPageViewsSum - previousPageViewsSum) / (previousPageViewsSum || 1)) * 100).toFixed(1),
                    impressionsPercentage: (((currentImpressionsSum - previousImpressionsSum) / (previousImpressionsSum || 1)) * 100).toFixed(1),
                    likesData: likesResponse
                });

                setAudeienceStats(getFacebookAnalyticsDataFromBackend.data.audienceResponse.data)


                let maxObjectForWeek = null;
                const online_follower_last_week = []
                setonlineFansResponse(onlineFansResponse)
                onlineFansResponse.slice(7, (onlineFansResponse?.length)).forEach(data => {
                    if (Object.keys(data.value)?.length > 0) {
                        maxObjectForWeek = data

                        const objForLastWeek = {
                            date: '',
                            value: []
                        }
                        const timeRangesLastWeek = [
                            { startTime: 0, endTime: 2 },
                            { startTime: 2, endTime: 4 },
                            { startTime: 4, endTime: 6 },
                            { startTime: 6, endTime: 8 },
                            { startTime: 8, endTime: 10 },
                            { startTime: 10, endTime: 12 },
                            { startTime: 12, endTime: 14 },
                            { startTime: 14, endTime: 16 },
                            { startTime: 16, endTime: 18 },
                            { startTime: 18, endTime: 20 },
                            { startTime: 20, endTime: 22 },
                            { startTime: 22, endTime: 24 },
                        ];

                        function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                            const values = maxObjectForWeek.value;
                            const timeKeys = Object.keys(values);
                            const timeRange = timeKeys.filter((key) => {
                                const hour = parseInt(key, 10);
                                return hour >= startTime && hour < endTime;
                            });


                            const timeRangeValues = timeRange?.map((key) => values[key]);
                            const obj = {
                                time: '',
                                online_follower: '',
                                date: ''
                            }
                            obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                            obj.date = maxObjectForWeek.end_time

                            let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            objForLastWeek.date = maxObjectForWeek.end_time
                            objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                        }

                        timeRangesLastWeek.forEach((timeRange) => {
                            displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                        });
                        online_follower_last_week.push(objForLastWeek)
                    } else {
                        const filterDate = new Date(data.end_time)
                        const startDateOfOnlineFollower = new Date(filterDate)
                        startDateOfOnlineFollower.setDate(filterDate.getDate() - 7)
                        const replaceDateForStartDate = onlineFansResponse.filter(onlineFolloweData => new Date(onlineFolloweData.end_time).setHours(0, 0, 0, 0) === startDateOfOnlineFollower.setHours(0, 0, 0, 0))[0]

                        maxObjectForWeek = replaceDateForStartDate

                        const objForLastWeek = {
                            date: '',
                            value: []
                        }
                        const timeRangesLastWeek = [
                            { startTime: 0, endTime: 2 },
                            { startTime: 2, endTime: 4 },
                            { startTime: 4, endTime: 6 },
                            { startTime: 6, endTime: 8 },
                            { startTime: 8, endTime: 10 },
                            { startTime: 10, endTime: 12 },
                            { startTime: 12, endTime: 14 },
                            { startTime: 14, endTime: 16 },
                            { startTime: 16, endTime: 18 },
                            { startTime: 18, endTime: 20 },
                            { startTime: 20, endTime: 22 },
                            { startTime: 22, endTime: 24 },
                        ];

                        function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                            const values = maxObjectForWeek.value;
                            const timeKeys = Object.keys(values);
                            const timeRange = timeKeys.filter((key) => {
                                const hour = parseInt(key, 10);
                                return hour >= startTime && hour < endTime;
                            });


                            const timeRangeValues = timeRange?.map((key) => values[key]);
                            const obj = {
                                time: '',
                                online_follower: '',
                                date: ''
                            }
                            obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                            obj.date = maxObjectForWeek.end_time

                            let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            objForLastWeek.date = maxObjectForWeek.end_time
                            objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                        }

                        timeRangesLastWeek.forEach((timeRange) => {
                            displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                        });
                        online_follower_last_week.push(objForLastWeek)
                    }



                })

                setOnlineFansLastWeek(online_follower_last_week)




                let maxObject = null;
                let online_follower = []
                if (onlineFansResponse?.length) {
                    maxObject = onlineFansResponse[6]


                    const timeRanges = [
                        { startTime: 0, endTime: 2 },
                        { startTime: 2, endTime: 4 },
                        { startTime: 4, endTime: 6 },
                        { startTime: 6, endTime: 8 },
                        { startTime: 8, endTime: 10 },
                        { startTime: 10, endTime: 12 },
                        { startTime: 12, endTime: 14 },
                        { startTime: 14, endTime: 16 },
                        { startTime: 16, endTime: 18 },
                        { startTime: 18, endTime: 20 },
                        { startTime: 20, endTime: 22 },
                        { startTime: 22, endTime: 24 },
                    ];

                    function displayValuesInTimeRange(startTime, endTime) {
                        const values = maxObject.value;
                        const timeKeys = Object.keys(values);
                        const timeRange = timeKeys.filter((key) => {
                            const hour = parseInt(key, 10);
                            return hour >= startTime && hour < endTime;
                        });


                        const timeRangeValues = timeRange.map((key) => values[key]);
                        const obj = {
                            time: '',
                            online_follower: '',
                            date: ''
                        }
                        obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        obj.online_follower = timeRangeValues.reduce((totalActive, data) => { return totalActive += data }, 0)
                        obj.date = maxObject.end_time
                        online_follower.push(obj)

                    }

                    timeRanges.forEach((timeRange) => {
                        displayValuesInTimeRange(timeRange.startTime, timeRange.endTime);
                    });
                }



                setOnlineFansObject(maxObject)
                setOnlineFans(online_follower)




                const obj = {
                    id: fbUser[0].socialId,
                    selectedPeriod: selectedPeriod.toString(),
                    dateArray: dateArray,
                    latestPostDetails: getFacebookAnalyticsDataFromBackend.data.latestPostDetails,
                    postData: getFacebookAnalyticsDataFromBackend.data.postData,
                    videoData: getFacebookAnalyticsDataFromBackend.data.videoData,
                    pageViewsResponse: pageViewsResponse,
                    clicksResponse: clicksResponse,
                    impressionsResponse: impressionsResponse,
                    overviewStats: {
                        websiteClicks: clicksResponse.reduce((totalClicks, data) => { return totalClicks += data.value }, 0) || 0,
                        impressions: impressionsResponse.reduce((totalImpression, data) => { return totalImpression += data.value }, 0) || 0,
                        likes: likesResponse[likesResponse?.length - 1]?.value || 0,
                        pageViews: pageViewsResponse.reduce((totalPageView, data) => { return totalPageView += data.value }, 0) || 0,
                        newPageLikes: likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value || 0,
                        websiteClicksPercentage: (((currentDateWebsiteClicks - previousDateWebsiteClicksSum) / (previousDateWebsiteClicksSum || 1)) * 100).toFixed(1),
                        likesPercentage: ((likesResponse[likesResponse?.length - 1]?.value - likesPrevResponse[likesPrevResponse?.length - 1]?.value) / (likesPrevResponse[likesPrevResponse?.length - 1]?.value || 1) * 100).toFixed(1),
                        pageViewsPercentage: (((currentPageViewsSum - previousPageViewsSum) / (previousPageViewsSum || 1)) * 100).toFixed(1),
                        impressionsPercentage: (((currentImpressionsSum - previousImpressionsSum) / (previousImpressionsSum || 1)) * 100).toFixed(1),
                        likesData: likesResponse
                    },
                    audienceResponse: getFacebookAnalyticsDataFromBackend.data.audienceResponse,
                    online_follower_last_week: online_follower_last_week,
                    online_fans_object: maxObject,
                    onlineFans: online_follower,
                    onlineFansResponse: onlineFansResponse
                }
                userContext.handleaccountsData(obj)
            }

            setLoading(false);
            setfilterPeriod(false)
        } catch (error) {
            console.error(error);
            setLoading(false);
            setfilterPeriod(false)
        }
    };

    const getCurrentAndPreviousDate = () => {
        const token = localStorage.getItem('token')
        const inUser = userContext.accounts.filter(data => data.accountType === "facebookpage" && data.name === userNavigation.activeSocialMediaName);
        const socialId = inUser[0]?.socialId
        const workspaceId = localStorage.getItem('organizationId')
        const currentDate = new Date();
        const startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - selectedPeriod);
        const startDateMilliseconds = startDate.getTime();
        const endDateMilliSeconds = currentDate.getTime();
        const startDateOfOnlineFollower = new Date(currentDate)
        startDateOfOnlineFollower.setDate(currentDate.getDate() - 14)
        const startDateForOnlineFollowerMiliSeconds = startDateOfOnlineFollower.getTime()
        const previousDate = new Date(startDate)
        previousDate.setDate(startDate.getDate() - selectedPeriod)
        const previousDateStartDateMiliSec = previousDate.getTime()
        const dateArray = [];
        const filterDateArray = [];
        for (let i = 0; i < (selectedPeriod); i++) {
            const newDate = new Date(startDate);
            newDate.setDate(startDate.getDate() + i); // Adjust the increment as needed
            const monthShortName = newDate.toLocaleString('default', { month: 'short' });
            const day = String(newDate.getDate()).padStart(2, '0');
            dateArray.push(`${monthShortName}-${day}`);
            const dateInMilliseconds = newDate.setHours(0, 0, 0, 0)
            filterDateArray.push(dateInMilliseconds)
        }

        const reportWeeks = [];

        currentDate.setDate(currentDate.getDate() - selectedPeriod); // Minus selectedPeriod from currentDate

        for (let i = 0; i < selectedPeriod; i += 7) { // Increment by 7 to represent weeks
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + i); // Adjust the increment as needed

            const weekStartDate = new Date(newDate);
            weekStartDate.setDate(newDate.getDate() - 6); // Get the start date of the week

            reportWeeks.push(newDate);
        }

        setselectedReportDate(reportWeeks[reportWeeks?.length - 1]); // Set selected report date to the first week

        return { token: token, dateArray: dateArray, filterDateArray: filterDateArray, previousDateStartDateMiliSec: previousDateStartDateMiliSec, startDateForOnlineFollowerMiliSeconds: startDateForOnlineFollowerMiliSeconds, endDateMilliSeconds: endDateMilliSeconds, startDateMilliseconds: startDateMilliseconds, socialId: socialId, workspaceId: workspaceId, reportWeeks: reportWeeks }

    }

    const onChangeselectedReportDate = (value) => {
        setselectedReportDate(value)
    }

    const calculatePercentage = (currentDateMetrics, previousDateMetrics) => {
        return (((currentDateMetrics - previousDateMetrics) / previousDateMetrics) * 100).toFixed(1)
    }

    const getFacebookOverviewPageAnalyticaData = async () => {
        const { dateArray, previousDateStartDateMiliSec, startDateForOnlineFollowerMiliSeconds, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token, reportWeeks } = getCurrentAndPreviousDate()
        setDateArray(dateArray)
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {

                setoverviewPageLoading(true)
                const overviewPageData = await axios.get(`${url}analytics/v2/facebook/overview/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}&prevDateSince=${previousDateStartDateMiliSec.toString().slice(0, 10)}&prevDateUntil=${startDateMilliseconds.toString().slice(0, 10)}&startDateForOnlineFollowerMiliSeconds=${startDateForOnlineFollowerMiliSeconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })

                const websiteClicksResponse = overviewPageData.data.lastMonthInsights.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0].values
                const pageViewResponse = overviewPageData.data.lastMonthInsights.data.filter(data => data.name === "page_views_total")[0].values
                const impressionsResponse = overviewPageData.data.lastMonthInsights.data.filter(data => data.name === "page_impressions")[0].values
                const likesResponse = overviewPageData.data.lastMonthInsights.data.filter(data => data.name === "page_fans")[0].values
                const onlineFansResponse = overviewPageData.data.lastMonthInsights.data.filter(data => data.name === "page_fans_online")[0].values.length > 7 ? overviewPageData.data.lastMonthInsights.data.filter(data => data.name === "page_fans_online")[0].values.slice(-14) : []

                const prevwebsiteClicks = overviewPageData.data.prevMonthInsights.data.filter(data => data.name === "page_website_clicks_logged_in_unique")[0].values
                const prevpageView = overviewPageData.data.prevMonthInsights.data.filter(data => data.name === "page_views_total")[0].values
                const previmpressions = overviewPageData.data.prevMonthInsights.data.filter(data => data.name === "page_impressions")[0].values
                const prevlikesResponse = overviewPageData.data.prevMonthInsights.data.filter(data => data.name === "page_fans")[0].values


                const currentPageViewSum = pageViewResponse.reduce((totalPageView, data) => { return totalPageView += data.value }, 0)
                const prevPageViewSum = prevpageView.reduce((totalPageView, data) => { return totalPageView += data.value }, 0)

                const currentWebsiteClicks = websiteClicksResponse.reduce((totalWebsiteClicks, data) => { return totalWebsiteClicks += data.value }, 0)
                const prevWebsiteClicks = prevwebsiteClicks.reduce((totalWebsiteClicks, data) => { return totalWebsiteClicks += data.value }, 0)

                const currentImpressions = impressionsResponse.reduce((totalImpression, data) => { return totalImpression += data.value }, 0)
                const prevImpressions = previmpressions.reduce((totalImpression, data) => { return totalImpression += data.value }, 0)

                const currentLikeResponse = likesResponse[likesResponse?.length - 1].value
                const previouslikesResponse = prevlikesResponse[prevlikesResponse?.length - 1].value


                setlatestPostDetails(overviewPageData.data.latestMediaMatrix)
                setpageView(pageViewResponse)
                setwebsiteClicks(websiteClicksResponse)
                setimpressions(impressionsResponse)

                setOverviewStats({
                    websiteClicks: websiteClicksResponse.reduce((totalClicks, data) => { return totalClicks += data.value }, 0) || 0,
                    impressions: impressionsResponse.reduce((totalClicks, data) => { return totalClicks += data.value }, 0) || 0,
                    likes: likesResponse[likesResponse?.length - 1]?.value || 0,
                    pageViews: pageViewResponse.reduce((totalPageView, data) => { return totalPageView += data.value }, 0) || 0,
                    newPageLikes: parseInt(currentLikeResponse || 0) - parseInt(previouslikesResponse || 0),
                    websiteClicksPercentage: calculatePercentage(currentWebsiteClicks, prevWebsiteClicks),
                    likesPercentage: calculatePercentage(currentLikeResponse, previouslikesResponse),
                    pageViewsPercentage: calculatePercentage(currentPageViewSum, prevPageViewSum),
                    impressionsPercentage: calculatePercentage(currentImpressions, prevImpressions),
                    likesData: likesResponse
                });


                setonlineFansResponse(onlineFansResponse)

                let maxObject = null;
                let online_follower = []

                if (onlineFansResponse?.length) {

                    maxObject = onlineFansResponse[6]
                    const timeRanges = [
                        { startTime: 0, endTime: 2 },
                        { startTime: 2, endTime: 4 },
                        { startTime: 4, endTime: 6 },
                        { startTime: 6, endTime: 8 },
                        { startTime: 8, endTime: 10 },
                        { startTime: 10, endTime: 12 },
                        { startTime: 12, endTime: 14 },
                        { startTime: 14, endTime: 16 },
                        { startTime: 16, endTime: 18 },
                        { startTime: 18, endTime: 20 },
                        { startTime: 20, endTime: 22 },
                        { startTime: 22, endTime: 24 },
                    ];

                    function displayValuesInTimeRange(startTime, endTime) {
                        const values = maxObject?.value;
                        const timeKeys = Object.keys(values);
                        const timeRange = timeKeys.filter((key) => {
                            const hour = parseInt(key, 10);
                            return hour >= startTime && hour < endTime;
                        });


                        const timeRangeValues = timeRange.map((key) => values[key]);
                        const obj = {
                            time: '',
                            online_follower: '',
                            date: ''
                        }
                        obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                        obj.online_follower = timeRangeValues.reduce((totalActive, data) => { return totalActive += data }, 0)
                        obj.date = maxObject.end_time
                        online_follower.push(obj)

                    }

                    timeRanges.forEach((timeRange) => {
                        displayValuesInTimeRange(timeRange.startTime, timeRange.endTime);
                    });

                }





                setOnlineFansObject(maxObject)
                setOnlineFans(online_follower)
                setoverviewPageLoading(false)
                setoverviewPageFilterLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: null,
                                    overviewPageData: overviewPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: null,
                            overviewPageData: overviewPageData.data
                        }
                    })
                }
            } catch (error) {
                toast.error(error !== "Internal server error" ? "Token is expired!" : "Internal server error!")
                setoverviewPageLoading(false)
                setoverviewPageFilterLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: error,
                                    overviewPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: error,
                            overviewPageData: null
                        }
                    })
                }
            }
        }
    }

    const getFacebookContentPageAnalyticaData = async () => {
        const { dateArray, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token, reportWeeks } = getCurrentAndPreviousDate()
        setDateArray(dateArray)
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                setcontentPageLoading(true)
                const contentPageData = await axios.get(`${url}analytics/v2/facebook/content/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })
                setAllPosts(contentPageData.data.posts)
                setallVideos(contentPageData.data.videos)
                let allCompetitorPostData = []
                contentPageData.data.posts.concat(contentPageData.data.videos).forEach(data => {
                    const postObj = { caption: data?.message === undefined ? 'This post has no caption' : data?.message, id: data.id, timestamp: data.created_time, socialId: socialId }
                    allCompetitorPostData.push(postObj)
                })

                const requestBody = {
                    workspaceId: workspaceId,
                    token: token,
                    media: allCompetitorPostData,
                    startDateMilliseconds: startDateMilliseconds,
                    endDateMilliSeconds: endDateMilliSeconds
                }
                const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
                const groupedData = res.data.data.reduce((acc, obj) => {
                    if (!acc[obj.contentCategory]) {
                        acc[obj.contentCategory] = { contentCategory: obj.contentCategory, socialId: obj.socialId, postId: [] };
                    }
                    acc[obj.contentCategory].postId.push(obj.postId);
                    return acc;
                }, {});

                const result = Object.values(groupedData);

                setstackedDataContent(result)
                setcontentPageLoading(false)
                setcontentPageFilterLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                contentPageData: {
                                    loading: false,
                                    error: null,
                                    contentPageData: contentPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        contentPageData: {
                            loading: false,
                            error: null,
                            contentPageData: contentPageData.data
                        }
                    })
                }
            } catch (error) {
                setcontentPageLoading(false)
                setcontentPageFilterLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                contentPageData: {
                                    loading: false,
                                    error: error,
                                    contentPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        contentPageData: {
                            loading: false,
                            error: error,
                            contentPageData: null
                        }
                    })
                }
            }
        }
    }

    const getFacebookAccountPageAnalyticaData = async () => {
        const { dateArray, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token, reportWeeks } = getCurrentAndPreviousDate()
        setDateArray(dateArray)
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                const accountPageData = await axios.get(`${url}analytics/v2/facebook/account/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, { headers: { "Authorization": `Bearer ${token}` } })
                const onlineFansResponse = accountPageData.data.accountDetails.data.filter(data => data.name === "page_fans_online")[0].values.length > 7 ? accountPageData.data.accountDetails.data.filter(data => data.name === "page_fans_online")[0].values.slice(-14) : []
                setAudeienceStats(accountPageData.data.accountDetails.data.filter(data => data.name === "page_fans_locale" || data.name === "page_fans_city" || data.name === "page_fans_country"))
                let maxObjectForWeek = null;
                const online_follower_last_week = []

                setonlineFansResponse(onlineFansResponse)

                onlineFansResponse.slice(6, (onlineFansResponse?.length - 1)).forEach(data => {
                    if (Object.keys(data.value)?.length > 0) {
                        maxObjectForWeek = data

                        const objForLastWeek = {
                            date: '',
                            value: []
                        }
                        const timeRangesLastWeek = [
                            { startTime: 0, endTime: 2 },
                            { startTime: 2, endTime: 4 },
                            { startTime: 4, endTime: 6 },
                            { startTime: 6, endTime: 8 },
                            { startTime: 8, endTime: 10 },
                            { startTime: 10, endTime: 12 },
                            { startTime: 12, endTime: 14 },
                            { startTime: 14, endTime: 16 },
                            { startTime: 16, endTime: 18 },
                            { startTime: 18, endTime: 20 },
                            { startTime: 20, endTime: 22 },
                            { startTime: 22, endTime: 24 },
                        ];

                        function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                            const values = maxObjectForWeek?.value;
                            const timeKeys = Object.keys(values);
                            const timeRange = timeKeys.filter((key) => {
                                const hour = parseInt(key, 10);
                                return hour >= startTime && hour < endTime;
                            });


                            const timeRangeValues = timeRange?.map((key) => values[key]);
                            const obj = {
                                time: '',
                                online_follower: '',
                                date: ''
                            }
                            obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                            obj.date = maxObjectForWeek.end_time

                            let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            objForLastWeek.date = maxObjectForWeek.end_time
                            objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                        }

                        timeRangesLastWeek.forEach((timeRange) => {
                            displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                        });
                        online_follower_last_week.push(objForLastWeek)
                    } else {
                        const filterDate = new Date(data.end_time)
                        const startDateOfOnlineFollower = new Date(filterDate)
                        startDateOfOnlineFollower.setDate(filterDate.getDate() - 7)
                        const replaceDateForStartDate = onlineFansResponse.filter(onlineFolloweData => new Date(onlineFolloweData.end_time).setHours(0, 0, 0, 0) === startDateOfOnlineFollower.setHours(0, 0, 0, 0))[0]

                        maxObjectForWeek = replaceDateForStartDate

                        const objForLastWeek = {
                            date: '',
                            value: []
                        }
                        const timeRangesLastWeek = [
                            { startTime: 0, endTime: 2 },
                            { startTime: 2, endTime: 4 },
                            { startTime: 4, endTime: 6 },
                            { startTime: 6, endTime: 8 },
                            { startTime: 8, endTime: 10 },
                            { startTime: 10, endTime: 12 },
                            { startTime: 12, endTime: 14 },
                            { startTime: 14, endTime: 16 },
                            { startTime: 16, endTime: 18 },
                            { startTime: 18, endTime: 20 },
                            { startTime: 20, endTime: 22 },
                            { startTime: 22, endTime: 24 },
                        ];

                        function displayValuesInTimeRangeLatWeek(startTime, endTime) {
                            const values = maxObjectForWeek?.value;
                            const timeKeys = Object.keys(values);
                            const timeRange = timeKeys.filter((key) => {
                                const hour = parseInt(key, 10);
                                return hour >= startTime && hour < endTime;
                            });


                            const timeRangeValues = timeRange?.map((key) => values[key]);
                            const obj = {
                                time: '',
                                online_follower: '',
                                date: ''
                            }
                            obj.time = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            obj.online_follower = timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0)
                            obj.date = maxObjectForWeek.end_time

                            let timeRangeForLastWeek = `${startTime === 0 ? 12 : startTime}-${endTime}`
                            objForLastWeek.date = maxObjectForWeek.end_time
                            objForLastWeek.value.push({ [timeRangeForLastWeek]: isFinite((timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length)) ? (timeRangeValues?.reduce((totalActive, data) => { return totalActive += data }, 0) / timeRangeValues?.length) : 0 })

                        }

                        timeRangesLastWeek.forEach((timeRange) => {
                            displayValuesInTimeRangeLatWeek(timeRange.startTime, timeRange.endTime);
                        });
                        online_follower_last_week.push(objForLastWeek)
                    }



                })

                setOnlineFansLastWeek(online_follower_last_week)

                setaccountPageLoading(false)

                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                accountPageData: {
                                    loading: false,
                                    error: null,
                                    accountPageData: accountPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        accountPageData: {
                            loading: false,
                            error: null,
                            accountPageData: accountPageData.data
                        }
                    })
                }
            } catch (error) {
                setaccountPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                accountPageData: {
                                    loading: false,
                                    error: error,
                                    accountPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        accountPageData: {
                            loading: false,
                            error: error,
                            accountPageData: null
                        }
                    })
                }
            }
        }
    }

    const getFacebookCompetitorPageAnalyticaData = async () => {
        const { dateArray, endDateMilliSeconds, startDateMilliseconds, socialId, workspaceId, token, reportWeeks } = getCurrentAndPreviousDate()
        setDateArray(dateArray)
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === socialId && data.selectedPeriod === selectedPeriod)
        setcompetitorPageLoading(true)
        try {
            if (socialMediaAnalyticDataisExits) {

            } else {
                const config = {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }

                const competitorPageData = await axios.get(`${url}analytics/v2/facebook/competitor/${workspaceId}/${socialId}?since=${startDateMilliseconds.toString().slice(0, 10)}&until=${endDateMilliSeconds.toString().slice(0, 10)}`, config)
                const lineChartData = competitorPageData.data.allCompetitorData.filter(data => data !== null)?.map(data => {
                    return {
                        name: data?.id,

                        allPost: dateArray?.map(date => {
                            const matchingPost = data?.posts?.data?.filter(post => `${new Date(post.created_time).toLocaleString('default', { month: 'short' })}-${String(new Date(post.created_time).getDate()).padStart(2, '0')}` === date);

                            return matchingPost?.length
                        }).filter(data => data !== undefined && data !== null),
                        allPostLike: dateArray?.map(date => {
                            const matchingPost = data?.posts?.data?.filter(post => `${new Date(post.created_time).toLocaleString('default', { month: 'short' })}-${String(new Date(post.created_time).getDate()).padStart(2, '0')}` === date).map(data => data.likes.summary.total_count);

                            return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                        }).filter(data => data !== undefined && data !== null),
                        allPostComment: dateArray?.map(date => {
                            const matchingPost = data?.posts?.data?.filter(post => `${new Date(post.created_time).toLocaleString('default', { month: 'short' })}-${String(new Date(post.created_time).getDate()).padStart(2, '0')}` === date).map(data => data.comments.summary.total_count);

                            return matchingPost?.reduce((totalLikes, data) => { return totalLikes += data }, 0)
                        }).filter(data => data !== undefined && data !== null),

                    }
                })
                setlineChartData(lineChartData)

                const borderColorPromises = competitorPageData.data.allCompetitorData
                    .filter(data => data !== null)
                    .map(data => {
                        return new Promise((resolve, reject) => {
                            const img = new Image();
                            img.crossOrigin = "anonymous";
                            img.src = data.picture.data.url;
                            img.onload = () => {
                                const colorThief = new ColorThief();
                                const result = colorThief.getColor(img, 25);
                                resolve({ name: data.id, color: rgbToHex(result[0], result[1], result[2]) });
                            };
                            img.onerror = (error) => {
                                reject(error); // Handle image loading errors
                            };
                        });
                    });

                // Wait for all promises to resolve
                const borderColorArray = await Promise.all(borderColorPromises);
                setborderColorArray(borderColorArray)
                setcompetitorsData(competitorPageData.data.allCompetitorData)
                setcompetitors(competitorPageData.data.competitors.competitorsData)

                let allCompetitorPostData = []
                competitorPageData.data.allCompetitorData.forEach(data => {
                    data?.posts?.data?.forEach(media => {
                        const postObj = { caption: media?.message, id: media.id, timestamp: media.created_time, socialId: data.id }
                        allCompetitorPostData.push(postObj)
                    })
                })

                const requestBody = {
                    workspaceId: workspaceId,
                    token: token,
                    media: allCompetitorPostData,
                    startDateMilliseconds: startDateMilliseconds,
                    endDateMilliSeconds: endDateMilliSeconds
                }


                const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
                setstackedDataCompetitor(res.data.data)

                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === socialId) {
                            return {
                                ...data,
                                competitorPageData: {
                                    loading: false,
                                    error: null,
                                    competitorPageData: competitorPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: socialId,
                        selectedPeriod: selectedPeriod,
                        competitorPageData: {
                            loading: false,
                            error: null,
                            competitorPageData: competitorPageData.data
                        }
                    })
                }
                setcompetitorPageLoading(false)
            }
        } catch (error) {
            console.log(error)
            setcompetitorPageLoading(false)
            if (socialMediaAnalyticDataisExits) {
                const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                    if (data.socialId === socialId) {
                        return {
                            ...data,
                            competitorPageData: {
                                loading: false,
                                error: error,
                                competitorPageData: null
                            }
                        }
                    }
                    return data
                })
                commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
            } else {
                commonSocialMediaContext.concateSocialMediaAnalyticsData({
                    socialId: socialId,
                    selectedPeriod: selectedPeriod,
                    competitorPageData: {
                        loading: false,
                        error: error,
                        competitorPageData: null
                    }
                })
            }
        }

    }



    const handleSelectedPeriod = (value) => {
        setSelectedPeriod(value)
    }

    const handleshowSearchList = (value) => {
        setshowSearchList(value)
    }

    const handleSearchList = (value) => {
        setsearchList(value)
    }

    const hideCompetitors = async (competitor, status) => {
        competitors.forEach(data => {
            if (data.id === competitor) {
                data.hideView = status
            }
        })
        setcompetitors(competitors)



        competitorsData.forEach(data => {
            if (data.id === competitor) {
                data.hideView = status
            }
        })

        setcompetitorsData(competitorsData)


    }

    const context = {
        selectedPeriod: selectedPeriod,
        loading: loading,
        overviewStats: overviewStats,
        allPosts: allPosts,
        dateArray: dateArray,
        onlineFans: onlineFans,
        onlineFansLastWeek: onlineFansLastWeek,
        onlineFansObject: onlineFansObject,
        impressions: impressions,
        pageView: pageView,
        websiteClicks: websiteClicks,
        allVideos: allVideos,
        audienceStats: audienceStats,
        filterPeriod: filterPeriod,
        onlineFansResponse: onlineFansResponse,
        latestPostDetails: latestPostDetails,
        overviewPageLoading: overviewPageLoading,
        contentPageLoading: contentPageLoading,
        accountPageLoading: accountPageLoading,
        overviewPageFilterLoading: overviewPageFilterLoading,
        contentPageFilterLoading: contentPageFilterLoading,
        competitors: competitors,
        competitorsData: competitorsData,
        search: search,
        setsearch: changesearch,
        showSearchList: showSearchList,
        setshowSearchList: handleshowSearchList,
        searchList: searchList,
        addCompetitorsLoader: addCompetitorsLoader,
        setaddCompetitorsLoader: changeCompetitorsLoading,
        setsearchList: handleSearchList,
        getFacebookAnalyticsData: getFacebookAnalyticsData,
        handleSelectedPeriod: handleSelectedPeriod,
        setfilterPeriod: changefilterPeriod,
        getFacebookAnalyticsDataAsPerPeriod: getFacebookAnalyticsDataAsPerPeriod,
        changeLoadingState: changeLoadingState,
        getFacebookOverviewPageAnalyticaData: getFacebookOverviewPageAnalyticaData,
        getFacebookContentPageAnalyticaData: getFacebookContentPageAnalyticaData,
        getFacebookAccountPageAnalyticaData: getFacebookAccountPageAnalyticaData,
        getFacebookCompetitorPageAnalyticaData: getFacebookCompetitorPageAnalyticaData,
        onChangeoverviewPageFilterLoading: onChangeoverviewPageFilterLoading,
        onChangecontentPageFilterLoading: onChangecontentPageFilterLoading,
        searchCompetitors: searchCompetitors,
        addPageToCompetitors: addPageToCompetitors,
        saveInstagramCompetitors: saveInstagramCompetitors,
        deleteCompetitors: deleteCompetitors,
        handleCompetitor: handleCompetitor,
        changeCompetitors: changeCompetitors,
        searchCompetitorLoading: searchCompetitorLoading,
        saveCompetitorsLoading: saveCompetitorsLoading,
        competitorPageLoading: competitorPageLoading,
        selectedReportDate: selectedReportDate,
        onChangesetselectedReportDate: onChangeselectedReportDate,
        reportWeekArray: reportWeekArray,
        lineChartData: lineChartData,
        borderColorArray: borderColorArray,
        stackedDataCompetitor: stackedDataCompetitor,
        hideCompetitors: hideCompetitors,
        stackedDataContent: stackedDataContent,
        clearState: clearState
    }


    return (
        <CreateFacebookAnalyticContext.Provider value={context}>{children}</CreateFacebookAnalyticContext.Provider>
    )
}