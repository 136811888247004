
import Styles from './PostDetailsByDate.module.css'
import { captionTruncate } from '../../Util/helper'
const PostDetailsByDate = (props) => {
    return (
        <div className={Styles.post_details_list_container} >

            <div className={Styles.container} >
                <div className={Styles.header_container} >
                    <span>Post</span>
                    {/* Corss SVG Icon */}
                    <svg style={{ cursor: 'pointer' }} onClick={() => props.onClickClose()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg>
                </div>
                {props.type === "instagram" ?
                    <>
                        {props.posts.length ? <>
                            {props.posts.map((data, index) => {
                                return (
                                    <div className={Styles.post_details_container} >
                                        <label>{index + 1}</label>
                                        <img src={data?.thumbnail_url ? data?.thumbnail_url : data?.media_url} alt="url" />
                                        <div className={Styles.caption_date_container} >
                                            <span>{captionTruncate(data.caption, 40)}</span>
                                            <p>{data.like_count} Likes {data.comments_count} Comments</p>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </> : <label className={Styles.no_data_found_label} >No post avaialble</label>}
                    </> : <></>

                }

                {props.type === "facebook" ?
                    <>
                        {props.posts.length ? <>
                            {props.posts.map((data, index) => {
                                return (
                                    <div className={Styles.post_details_container} >
                                        <label>{index + 1}</label>
                                        <img src={data?.full_picture} alt="url" />
                                        <div className={Styles.caption_date_container} >
                                            <span>{captionTruncate(data?.message === undefined ? "-" : data?.message, 40)}</span>
                                            <p>{data?.likes.summary.total_count} Likes {data?.comments.summary.total_count} Comments</p>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </> : <label className={Styles.no_data_found_label} >No post avaialble</label>}
                    </> : <></>

                }
            </div>
        </div>
    )
}

export default PostDetailsByDate