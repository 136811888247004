import { createContext, useState } from 'react'
export const UserNavigationContext = createContext({
    
    activeLeftSideSocialMedia:1,
    activeRightSideSocialMedia:'',
    activeSocialMediaName:'',
    setactiveLeftSideSocialMedia: () => {},
    setactiveRightSideSocialMedia:() => {},
    changeSocialMediaName :() =>{ },
    isShowConnectAccountPopup:'',
    setisShowConnectAccountPopup: () =>{ },
    isConnectAccountPopup:'',
    setisConnectAccountPopup:() =>{ }
})

export const UserNavigationProvider = ({ children }) => {
    const [activeLeftSideSocialMedia, setactiveLeftSideSocialMedia] = useState(1);
    const [activeRightSideSocialMedia, setactiveRightSideSocialMedia] = useState('dashboard');
    const [activeSocialMediaName,setactiveSocialMediaName]=useState('')
    const [isShowConnectAccountPopup,setisShowConnectAccountPopup]=useState(false)
    const [isConnectAccountPopup,setisConnectAccountPopup]=useState(false)

    const changeisConnectAccountPopup=(value)=>{
        setisConnectAccountPopup(value)
    }

    const changeisShowConnectAccountPopup=(value)=>{
        setisShowConnectAccountPopup(value)
    }

    const changeSocialMediaName=(value)=>{
        
        setactiveSocialMediaName(value)
    }

    const changeLeftSideSocialMedia = (value) => {
        setactiveLeftSideSocialMedia(value);
    };

    const changeRightSideSocialMedia = (value) => {
        setactiveRightSideSocialMedia(value);
    };

    
    const context = {
        activeLeftSideSocialMedia: activeLeftSideSocialMedia,
        activeRightSideSocialMedia: activeRightSideSocialMedia,
        setactiveLeftSideSocialMedia: changeLeftSideSocialMedia,
        setactiveRightSideSocialMedia: changeRightSideSocialMedia,
        activeSocialMediaName: activeSocialMediaName,
        changeSocialMediaName: changeSocialMediaName,
        isShowConnectAccountPopup: isShowConnectAccountPopup,
        setisShowConnectAccountPopup: changeisShowConnectAccountPopup,
        isConnectAccountPopup:isConnectAccountPopup,
        setisConnectAccountPopup: changeisConnectAccountPopup
    }

    return (
        <UserNavigationContext.Provider value={context} >{children}</UserNavigationContext.Provider>
    )

}


