import React from 'react'
import Styles from './AdsFilterPopup.module.css'

const AddFilterPopup = ({ close, filters, setFilters, getAds }) => {
    const countries = [
        { code: 'BR', name: 'Brazil' },
        { code: 'IN', name: 'India' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'US', name: 'United States' },
        { code: 'CA', name: 'Canada' },
        { code: 'AR', name: 'Argentina' },
        { code: 'AU', name: 'Australia' },
        { code: 'AT', name: 'Austria' },
        { code: 'BE', name: 'Belgium' },
        { code: 'CL', name: 'Chile' },
        { code: 'CN', name: 'China' },
        { code: 'CO', name: 'Colombia' },
        { code: 'HR', name: 'Croatia' },
        { code: 'DK', name: 'Denmark' },
        { code: 'DO', name: 'Dominican Republic' },
        { code: 'EG', name: 'Egypt' },
        { code: 'FI', name: 'Finland' },
        { code: 'FR', name: 'France' },
        { code: 'DE', name: 'Germany' },
        { code: 'GR', name: 'Greece' },
        { code: 'HK', name: 'Hong Kong' },
        { code: 'ID', name: 'Indonesia' },
        { code: 'IE', name: 'Ireland' },
        { code: 'IL', name: 'Israel' },
        { code: 'IT', name: 'Italy' },
        { code: 'JP', name: 'Japan' },
        { code: 'JO', name: 'Jordan' },
        { code: 'KW', name: 'Kuwait' },
        { code: 'LB', name: 'Lebanon' },
        { code: 'MY', name: 'Malaysia' },
        { code: 'MX', name: 'Mexico' },
        { code: 'NL', name: 'Netherlands' },
        { code: 'NZ', name: 'New Zealand' },
        { code: 'NG', name: 'Nigeria' },
        { code: 'NO', name: 'Norway' },
        { code: 'PK', name: 'Pakistan' },
        { code: 'PA', name: 'Panama' },
        { code: 'PE', name: 'Peru' },
        { code: 'PH', name: 'Philippines' },
        { code: 'PL', name: 'Poland' },
        { code: 'RU', name: 'Russia' },
        { code: 'SA', name: 'Saudi Arabia' },
        { code: 'RS', name: 'Serbia' },
        { code: 'SG', name: 'Singapore' },
        { code: 'ZA', name: 'South Africa' },
        { code: 'KR', name: 'South Korea' },
        { code: 'ES', name: 'Spain' },
        { code: 'SE', name: 'Sweden' },
        { code: 'CH', name: 'Switzerland' },
        { code: 'TW', name: 'Taiwan' },
        { code: 'TH', name: 'Thailand' },
        { code: 'TR', name: 'Turkey' },
        { code: 'AE', name: 'United Arab Emirates' },
        { code: 'VE', name: 'Venezuela' },
        { code: 'PT', name: 'Portugal' },
        { code: 'LU', name: 'Luxembourg' },
        { code: 'BG', name: 'Bulgaria' },
        { code: 'CZ', name: 'Czech Republic' },
        { code: 'SI', name: 'Slovenia' },
        { code: 'IS', name: 'Iceland' },
        { code: 'SK', name: 'Slovakia' },
        { code: 'LT', name: 'Lithuania' },
        { code: 'TT', name: 'Trinidad and Tobago' },
        { code: 'BD', name: 'Bangladesh' },
        { code: 'LK', name: 'Sri Lanka' },
        { code: 'KE', name: 'Kenya' },
        { code: 'HU', name: 'Hungary' },
        { code: 'MA', name: 'Morocco' },
        { code: 'CY', name: 'Cyprus' },
        { code: 'JM', name: 'Jamaica' },
        { code: 'EC', name: 'Ecuador' },
        { code: 'RO', name: 'Romania' },
        { code: 'BO', name: 'Bolivia' },
        { code: 'GT', name: 'Guatemala' },
        { code: 'CR', name: 'Costa Rica' },
        { code: 'QA', name: 'Qatar' },
        { code: 'SV', name: 'El Salvador' },
        { code: 'HN', name: 'Honduras' },
        { code: 'NI', name: 'Nicaragua' },
        { code: 'PY', name: 'Paraguay' },
        { code: 'UY', name: 'Uruguay' },
        { code: 'PR', name: 'Puerto Rico' },
        { code: 'BA', name: 'Bosnia and Herzegovina' },
        { code: 'PS', name: 'Palestine' },
        { code: 'TN', name: 'Tunisia' },
        { code: 'BH', name: 'Bahrain' },
        { code: 'VN', name: 'Vietnam' },
        { code: 'GH', name: 'Ghana' },
        { code: 'MU', name: 'Mauritius' },
        { code: 'UA', name: 'Ukraine' },
        { code: 'MT', name: 'Malta' },
        { code: 'BS', name: 'Bahamas' },
        { code: 'MV', name: 'Maldives' },
        { code: 'OM', name: 'Oman' },
        { code: 'MK', name: 'North Macedonia' },
        { code: 'LV', name: 'Latvia' },
        { code: 'EE', name: 'Estonia' },
        { code: 'IQ', name: 'Iraq' },
        { code: 'DZ', name: 'Algeria' },
        { code: 'AL', name: 'Albania' },
        { code: 'NP', name: 'Nepal' },
        { code: 'MO', name: 'Macao' },
        { code: 'ME', name: 'Montenegro' },
        { code: 'SN', name: 'Senegal' },
        { code: 'GE', name: 'Georgia' },
        { code: 'BN', name: 'Brunei' },
        { code: 'UG', name: 'Uganda' },
        { code: 'GP', name: 'Guadeloupe' },
        { code: 'BB', name: 'Barbados' },
        { code: 'AZ', name: 'Azerbaijan' },
        { code: 'TZ', name: 'Tanzania' },
        { code: 'LY', name: 'Libya' },
        { code: 'MQ', name: 'Martinique' },
        { code: 'CM', name: 'Cameroon' },
        { code: 'BW', name: 'Botswana' },
        { code: 'ET', name: 'Ethiopia' },
        { code: 'KZ', name: 'Kazakhstan' },
        { code: 'NA', name: 'Namibia' },
        { code: 'MG', name: 'Madagascar' },
        { code: 'NC', name: 'New Caledonia' },
        { code: 'MD', name: 'Moldova' },
        { code: 'FJ', name: 'Fiji' },
        { code: 'BY', name: 'Belarus' },
        { code: 'JE', name: 'Jersey' },
        { code: 'GU', name: 'Guam' },
        { code: 'YE', name: 'Yemen' },
        { code: 'ZM', name: 'Zambia' },
        { code: 'IM', name: 'Isle of Man' },
        { code: 'HT', name: 'Haiti' },
        { code: 'KH', name: 'Cambodia' },
        { code: 'AW', name: 'Aruba' },
        { code: 'PF', name: 'French Polynesia' },
        { code: 'AF', name: 'Afghanistan' },
        { code: 'BM', name: 'Bermuda' },
        { code: 'GY', name: 'Guyana' },
        { code: 'AM', name: 'Armenia' },
        { code: 'MW', name: 'Malawi' },
        { code: 'AG', name: 'Antigua and Barbuda' },
        { code: 'RW', name: 'Rwanda' },
        { code: 'GG', name: 'Guernsey' },
        { code: 'GM', name: 'Gambia' },
        { code: 'FO', name: 'Faroe Islands' },
        { code: 'LC', name: 'Saint Lucia' },
        { code: 'KY', name: 'Cayman Islands' },
        { code: 'BJ', name: 'Benin' },
        { code: 'AD', name: 'Andorra' },
        { code: 'GD', name: 'Grenada' },
        { code: 'VI', name: 'U.S. Virgin Islands' },
        { code: 'BZ', name: 'Belize' },
        { code: 'VC', name: 'Saint Vincent and the Grenadines' },
        { code: 'MN', name: 'Mongolia' },
        { code: 'MZ', name: 'Mozambique' },
        { code: 'ML', name: 'Mali' },
        { code: 'AO', name: 'Angola' },
        { code: 'GF', name: 'French Guiana' },
        { code: 'UZ', name: 'Uzbekistan' },
        { code: 'DJ', name: 'Djibouti' },
        { code: 'BF', name: 'Burkina Faso' },
        { code: 'MC', name: 'Monaco' },
        { code: 'TG', name: 'Togo' },
        { code: 'GL', name: 'Greenland' },
        { code: 'GA', name: 'Gabon' },
        { code: 'GI', name: 'Gibraltar' },
        { code: 'CD', name: 'Democratic Republic of the Congo' },
        { code: 'KG', name: 'Kyrgyzstan' },
        { code: 'PG', name: 'Papua New Guinea' },
        { code: 'BT', name: 'Bhutan' },
        { code: 'KN', name: 'Saint Kitts and Nevis' },
        { code: 'SZ', name: 'Eswatini' },
        { code: 'LS', name: 'Lesotho' },
        { code: 'LA', name: 'Laos' },
        { code: 'LI', name: 'Liechtenstein' },
        { code: 'MP', name: 'Northern Mariana Islands' },
        { code: 'SR', name: 'Suriname' },
        { code: 'SC', name: 'Seychelles' },
        { code: 'VG', name: 'British Virgin Islands' },
        { code: 'TC', name: 'Turks and Caicos Islands' },
        { code: 'DM', name: 'Dominica' },
        { code: 'MR', name: 'Mauritania' },
        { code: 'AX', name: 'Åland Islands' },
        { code: 'SM', name: 'San Marino' },
        { code: 'SL', name: 'Sierra Leone' },
        { code: 'NE', name: 'Niger' },
        { code: 'CG', name: 'Republic of the Congo' },
        { code: 'AI', name: 'Anguilla' },
        { code: 'YT', name: 'Mayotte' },
        { code: 'CV', name: 'Cape Verde' },
        { code: 'GN', name: 'Guinea' },
        { code: 'TM', name: 'Turkmenistan' },
        { code: 'BI', name: 'Burundi' },
        { code: 'TJ', name: 'Tajikistan' },
        { code: 'VU', name: 'Vanuatu' },
        { code: 'SB', name: 'Solomon Islands' },
        { code: 'ER', name: 'Eritrea' },
        { code: 'WS', name: 'Samoa' },
        { code: 'AS', name: 'American Samoa' },
        { code: 'FK', name: 'Falkland Islands' },
        { code: 'GQ', name: 'Equatorial Guinea' },
        { code: 'TO', name: 'Tonga' },
        { code: 'KM', name: 'Comoros' },
        { code: 'PW', name: 'Palau' },
        { code: 'FM', name: 'Micronesia' },
        { code: 'CF', name: 'Central African Republic' },
        { code: 'SO', name: 'Somalia' },
        { code: 'MH', name: 'Marshall Islands' },
        { code: 'VA', name: 'Vatican City' },
        { code: 'TD', name: 'Chad' },
        { code: 'KI', name: 'Kiribati' },
        { code: 'ST', name: 'São Tomé and Príncipe' },
        { code: 'TV', name: 'Tuvalu' },
        { code: 'NR', name: 'Nauru' },
        { code: 'RE', name: 'Réunion' },
        { code: 'LR', name: 'Liberia' },
        { code: 'ZW', name: 'Zimbabwe' },
        { code: 'CI', name: 'Ivory Coast' },
        { code: 'MM', name: 'Myanmar' },
        { code: 'AN', name: 'Netherlands Antilles' },
        { code: 'AQ', name: 'Antarctica' },
        { code: 'BQ', name: 'Caribbean Netherlands' },
        { code: 'BV', name: 'Bouvet Island' },
        { code: 'IO', name: 'British Indian Ocean Territory' },
        { code: 'CX', name: 'Christmas Island' },
        { code: 'CC', name: 'Cocos Islands' },
        { code: 'CK', name: 'Cook Islands' },
        { code: 'CW', name: 'Curaçao' },
        { code: 'TF', name: 'French Southern and Antarctic Lands' },
        { code: 'GW', name: 'Guinea-Bissau' },
        { code: 'HM', name: 'Heard Island and McDonald Islands' },
        { code: 'XK', name: 'Kosovo' },
        { code: 'MS', name: 'Montserrat' },
        { code: 'NU', name: 'Niue' },
        { code: 'NF', name: 'Norfolk Island' },
        { code: 'PN', name: 'Pitcairn Islands' },
        { code: 'BL', name: 'Saint Barthélemy' },
        { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
        { code: 'MF', name: 'Saint Martin' },
        { code: 'PM', name: 'Saint Pierre and Miquelon' },
        { code: 'SX', name: 'Sint Maarten' },
        { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
        { code: 'SS', name: 'South Sudan' },
        { code: 'SJ', name: 'Svalbard and Jan Mayen' },
        { code: 'TL', name: 'East Timor' },
        { code: 'TK', name: 'Tokelau' },
        { code: 'UM', name: 'United States Minor Outlying Islands' },
        { code: 'WF', name: 'Wallis and Futuna' },
        { code: 'EH', name: 'Western Sahara' }
    ];


    const languages = [
        { code: "en", name: "English" },
        { code: "aa", name: "Afar" },
        { code: "ab", name: "Abkhazian" },
        { code: "ae", name: "Avestan" },
        { code: "af", name: "Afrikaans" },
        { code: "ak", name: "Akan" },
        { code: "am", name: "Amharic" },
        { code: "an", name: "Aragonese" },
        { code: "ar", name: "Arabic" },
        { code: "as", name: "Assamese" },
        { code: "av", name: "Avaric" },
        { code: "ay", name: "Aymara" },
        { code: "ba", name: "Bashkir" },
        { code: "be", name: "Belarusian" },
        { code: "bg", name: "Bulgarian" },
        { code: "bh", name: "Bihari" },
        { code: "bi", name: "Bislama" },
        { code: "bm", name: "Bambara" },
        { code: "bn", name: "Bengali" },
        { code: "bo", name: "Tibetan" },
        { code: "br", name: "Breton" },
        { code: "bs", name: "Bosnian" },
        { code: "ca", name: "Catalan" },
        { code: "ce", name: "Chechen" },
        { code: "ch", name: "Chamorro" },
        { code: "co", name: "Corsican" },
        { code: "cr", name: "Cree" },
        { code: "cs", name: "Czech" },
        { code: "cu", name: "Church Slavic" },
        { code: "cv", name: "Chuvash" },
        { code: "cy", name: "Welsh" },
        { code: "da", name: "Danish" },
        { code: "de", name: "German" },
        { code: "dv", name: "Divehi" },
        { code: "dz", name: "Dzongkha" },
        { code: "ee", name: "Ewe" },
        { code: "el", name: "Greek" },
        { code: "eo", name: "Esperanto" },
        { code: "es", name: "Spanish" },
        { code: "et", name: "Estonian" },
        { code: "eu", name: "Basque" },
        { code: "fa", name: "Persian" },
        { code: "ff", name: "Fulah" },
        { code: "fi", name: "Finnish" },
        { code: "fj", name: "Fijian" },
        { code: "fo", name: "Faroese" },
        { code: "fr", name: "French" },
        { code: "az", name: "Azerbaijani" },
        { code: "fy", name: "Western Frisian" },
        { code: "ga", name: "Irish" },
        { code: "gd", name: "Scottish Gaelic" },
        { code: "gl", name: "Galician" },
        { code: "gn", name: "Guarani" },
        { code: "gu", name: "Gujarati" },
        { code: "gv", name: "Manx" },
        { code: "ha", name: "Hausa" },
        { code: "he", name: "Hebrew" },
        { code: "hi", name: "Hindi" },
        { code: "ho", name: "Hiri Motu" },
        { code: "hr", name: "Croatian" },
        { code: "ht", name: "Haitian" },
        { code: "hu", name: "Hungarian" },
        { code: "hy", name: "Armenian" },
        { code: "hz", name: "Herero" },
        { code: "ia", name: "Interlingua" },
        { code: "id", name: "Indonesian" },
        { code: "ie", name: "Interlingue" },
        { code: "ig", name: "Igbo" },
        { code: "ii", name: "Sichuan Yi" },
        { code: "ik", name: "Inupiaq" },
        { code: "io", name: "Ido" },
        { code: "is", name: "Icelandic" },
        { code: "it", name: "Italian" },
        { code: "iu", name: "Inuktitut" },
        { code: "ja", name: "Japanese" },
        { code: "jv", name: "Javanese" },
        { code: "ka", name: "Georgian" },
        { code: "kg", name: "Kongo" },
        { code: "ki", name: "Kikuyu" },
        { code: "kj", name: "Kuanyama" },
        { code: "kk", name: "Kazakh" },
        { code: "kl", name: "Kalaallisut" },
        { code: "km", name: "Khmer" },
        { code: "kn", name: "Kannada" },
        { code: "ko", name: "Korean" },
        { code: "kr", name: "Kanuri" },
        { code: "ks", name: "Kashmiri" },
        { code: "ku", name: "Kurdish" },
        { code: "kv", name: "Komi" },
        { code: "kw", name: "Cornish" },
        { code: "ky", name: "Kirghiz" },
        { code: "la", name: "Latin" },
        { code: "lb", name: "Luxembourgish" },
        { code: "lg", name: "Ganda" },
        { code: "li", name: "Limburgish" },
        { code: "ln", name: "Lingala" },
        { code: "lo", name: "Lao" },
        { code: "lt", name: "Lithuanian" },
        { code: "lu", name: "Luba-Katanga" },
        { code: "lv", name: "Latvian" },
        { code: "mg", name: "Malagasy" },
        { code: "mh", name: "Marshallese" },
        { code: "mi", name: "Maori" },
        { code: "mk", name: "Macedonian" },
        { code: "ml", name: "Malayalam" },
        { code: "mn", name: "Mongolian" },
        { code: "mr", name: "Marathi" },
        { code: "ms", name: "Malay" },
        { code: "mt", name: "Maltese" },
        { code: "my", name: "Burmese" },
        { code: "na", name: "Nauru" },
        { code: "nb", name: "Norwegian Bokmål" },
        { code: "nd", name: "North Ndebele" },
        { code: "ne", name: "Nepali" },
        { code: "ng", name: "Ndonga" },
        { code: "nl", name: "Dutch" },
        { code: "nn", name: "Norwegian Nynorsk" },
        { code: "no", name: "Norwegian" },
        { code: "nr", name: "South Ndebele" },
        { code: "nv", name: "Navajo" },
        { code: "ny", name: "Chichewa" },
        { code: "oc", name: "Occitan" },
        { code: "oj", name: "Ojibwa" },
        { code: "om", name: "Oromo" },
        { code: "or", name: "Oriya" },
        { code: "os", name: "Ossetian" },
        { code: "pa", name: "Panjabi" },
        { code: "pi", name: "Pali" },
        { code: "pl", name: "Polish" },
        { code: "ps", name: "Pashto" },
        { code: "pt", name: "Portuguese" },
        { code: "qu", name: "Quechua" },
        { code: "rm", name: "Romansh" },
        { code: "rn", name: "Kirundi" },
        { code: "ro", name: "Romanian" },
        { code: "ru", name: "Russian" },
        { code: "rw", name: "Kinyarwanda" },
        { code: "sa", name: "Sanskrit" },
        { code: "sc", name: "Sardinian" },
        { code: "sd", name: "Sindhi" },
        { code: "se", name: "Northern Sami" },
        { code: "sg", name: "Sango" },
        { code: "si", name: "Sinhala" },
        { code: "sk", name: "Slovak" },
        { code: "sl", name: "Slovenian" },
        { code: "sm", name: "Samoan" },
        { code: "sn", name: "Shona" },
        { code: "so", name: "Somali" },
        { code: "sq", name: "Albanian" },
        { code: "sr", name: "Serbian" },
        { code: "ss", name: "Swati" },
        { code: "st", name: "Southern Sotho" },
        { code: "su", name: "Sundanese" },
        { code: "sv", name: "Swedish" },
        { code: "sw", name: "Swahili" },
        { code: "ta", name: "Tamil" },
        { code: "te", name: "Telugu" },
        { code: "tg", name: "Tajik" },
        { code: "th", name: "Thai" },
        { code: "ti", name: "Tigrinya" },
        { code: "tk", name: "Turkmen" },
        { code: "tl", name: "Tagalog" },
        { code: "tn", name: "Tswana" },
        { code: "to", name: "Tonga" },
        { code: "tr", name: "Turkish" },
        { code: "ts", name: "Tsonga" },
        { code: "tt", name: "Tatar" },
        { code: "tw", name: "Twi" },
        { code: "ty", name: "Tahitian" },
        { code: "ug", name: "Uighur" },
        { code: "uk", name: "Ukrainian" },
        { code: "ur", name: "Urdu" },
        { code: "uz", name: "Uzbek" },
        { code: "ve", name: "Venda" },
        { code: "vi", name: "Vietnamese" },
        { code: "vo", name: "Volapük" },
        { code: "wa", name: "Walloon" },
        { code: "wo", name: "Wolof" },
        { code: "xh", name: "Xhosa" },
        { code: "yi", name: "Yiddish" },
        { code: "yo", name: "Yoruba" },
        { code: "za", name: "Zhuang" },
        { code: "zh", name: "Chinese" },
        { code: "zu", name: "Zulu" },
        { code: "cmn", name: "Mandarin Chinese" },
        { code: "yue", name: "Cantonese" }
    ];



    const handleFilterChange = (filter, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filter]: value
        }));
    };

    const applyFilters = () => {
        getAds()
        close()
    }

    return (
        <div className={Styles.filter_popup_main_container}>
            <div className={Styles.container}>
                <div className={Styles.header_container}>
                    <span>Filters</span>
                    <svg width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000" onClick={close}><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </div>

                <div className={Styles.filters}>
                    <div className={Styles.filter}>
                        <h1>Country</h1>
                        <select value={filters.country} onChange={e => handleFilterChange('country', e.target.value)}>
                            {countries.map((data, index) => {
                                return <option value={data.code} key={index}>{data.name}</option>
                            })}
                        </select>
                    </div>
                    <div className={Styles.filter}>
                        <h1>Platform</h1>
                        <select value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)}>
                            <option value="INSTAGRAM">Instagram</option>
                            <option value="FACEBOOK">Facebook</option>
                            <option value="MESSENGER">Messenger</option>
                            <option value="WHATSAPP">Whatsapp</option>
                            <option value="AUDIENCE_NETWORK">Audience Network</option>
                            <option value="OCULUS">Oculus</option>
                        </select>
                    </div>
                    <div className={Styles.filter}>
                        <h1>Status</h1>
                        <select value={filters.status} onChange={e => handleFilterChange('status', e.target.value)}>
                            <option value="INACTIVE">Inactive</option>
                        </select>
                    </div>
                    <div className={Styles.filter}>
                        <h1>Languages</h1>
                        <select value={filters.language} onChange={e => handleFilterChange('language', e.target.value)}>
                            {languages.map((data, index) => {
                                return <option value={data.code} key={index}>{data.name}</option>
                            })}
                        </select>
                    </div>

                    <div className={Styles.filter}>
                        <h1>Media Type</h1>
                        <select value={filters.mediaType} onChange={e => handleFilterChange('mediaType', e.target.value)}>
                            <option value="ALL">All</option>
                            <option value="IMAGE">Image</option>
                            <option value="VIDEO">Video</option>
                            <option value="MEME">Meme</option>
                            <option value="NONE">None</option>
                        </select>
                    </div>
                </div>

                <button onClick={applyFilters}>Apply Filters</button>
            </div>
        </div>
    )
}

export default AddFilterPopup