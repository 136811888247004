import Styles from './LeftSideSkeleton.module.css'
const LeftSideSkeleton = () => {
    return (
        <div className={Styles.left_side_panel_skeleton_container} >

            {[1, 2, 3, 4].map((index) => {

                return (
                    <div key={index} className={Styles.account_loader_container}>
                    <div className={Styles.column}>
                        <div className={`${Styles.skeleton} ${Styles.circle}`} />
                        <p className={`${Styles.skeleton}`} />
                    </div>
                </div>
        
                )
                
            })}
        </div>
    )
}

export default LeftSideSkeleton