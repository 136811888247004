import React, { useContext } from "react";
import Styles from "./FacebookNavbar.module.css";
import { createNavigationContext } from '../../store/navigation'


const FacebookNavbar = (props) => {
    const context = useContext(createNavigationContext)


    const navigation = (value) => {
        context.setActive(value)
    }
    return (
        <div className={Styles.facebook_navbar_container} >
            <div className={Styles.nav_item_container} >
                <div onClick={() => { navigation('overview') }} className={Styles.single_nav_item} >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 13.4996V11.2496C7.5 10.8518 7.65804 10.4703 7.93934 10.189C8.22064 9.90765 8.60217 9.74962 9 9.74962C9.39782 9.74962 9.77935 9.90765 10.0607 10.189C10.342 10.4703 10.5 10.8518 10.5 11.2496V13.4996M1.5 5.99962L8.799 2.35012C8.86142 2.31896 8.93023 2.30273 9 2.30273C9.06977 2.30273 9.13858 2.31896 9.201 2.35012L16.5 5.99962" stroke={props.active === "overview" ? "#0078FF" : "#333333"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15 8.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75H4.5C4.10218 15.75 3.72064 15.592 3.43934 15.3107C3.15804 15.0294 3 14.6478 3 14.25V8.25" stroke={props.active === "overview" ? "#0078FF" : "#333333"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span style={props.active === "overview" ? { color: '#0078FF' } : {}} >Overview</span>
                </div>

                <div onClick={() => { navigation('content') }} className={Styles.single_nav_item} >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 5.7V15.3C15.75 15.4193 15.7026 15.5338 15.6182 15.6182C15.5338 15.7026 15.4193 15.75 15.3 15.75H5.7C5.58065 15.75 5.46619 15.7026 5.3818 15.6182C5.29741 15.5338 5.25 15.4193 5.25 15.3V5.7C5.25 5.58065 5.29741 5.46619 5.3818 5.3818C5.46619 5.29741 5.58065 5.25 5.7 5.25H15.3C15.4193 5.25 15.5338 5.29741 15.6182 5.3818C15.7026 5.46619 15.75 5.58065 15.75 5.7Z" stroke={props.active === "content" ? "#0078FF" : "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.5 3H3.45C3.33065 3 3.21619 3.04741 3.1318 3.1318C3.04741 3.21619 3 3.33065 3 3.45V13.5M5.25 12.6L9.333 11.25L15.75 13.5M12.375 9.75C12.0766 9.75 11.7905 9.63147 11.5795 9.4205C11.3685 9.20952 11.25 8.92337 11.25 8.625C11.25 8.32663 11.3685 8.04048 11.5795 7.82951C11.7905 7.61853 12.0766 7.5 12.375 7.5C12.6734 7.5 12.9595 7.61853 13.1705 7.82951C13.3815 8.04048 13.5 8.32663 13.5 8.625C13.5 8.92337 13.3815 9.20952 13.1705 9.4205C12.9595 9.63147 12.6734 9.75 12.375 9.75Z" stroke={props.active === "content" ? "#0078FF" : "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <span style={props.active === "content" ? { color: '#0078FF' } : {}} >Content</span>
                </div>

                <div onClick={() => { navigation('account') }} className={Styles.single_nav_item} >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_9291_1059)">
                            <path d="M9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1422 4.85775 16.5 9 16.5C13.1422 16.5 16.5 13.1422 16.5 9C16.5 4.85775 13.1422 1.5 9 1.5Z" stroke={props.active === "account" ? "#0078FF" : "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.20312 13.7595C3.20312 13.7595 4.87488 11.625 8.99988 11.625C13.1249 11.625 14.7974 13.7595 14.7974 13.7595M8.99988 9C9.59661 9 10.1689 8.76295 10.5909 8.34099C11.0128 7.91903 11.2499 7.34674 11.2499 6.75C11.2499 6.15326 11.0128 5.58097 10.5909 5.15901C10.1689 4.73705 9.59661 4.5 8.99988 4.5C8.40314 4.5 7.83084 4.73705 7.40888 5.15901C6.98693 5.58097 6.74988 6.15326 6.74988 6.75C6.74988 7.34674 6.98693 7.91903 7.40888 8.34099C7.83084 8.76295 8.40314 9 8.99988 9Z" stroke={props.active === "account" ? "#0078FF" : "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_9291_1059">
                                <rect width="18" height="18" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span style={props.active === "account" ? { color: '#0078FF' } : {}} >Account</span>
                </div>


                <div onClick={() => { navigation('ads') }} className={Styles.single_nav_item} >
                    <svg width="18px" height="18px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
                        <path d="M14 14V6M14 14L20.1023 17.487C20.5023 17.7156 21 17.4268 21 16.9661V3.03391C21 2.57321 20.5023 2.28439 20.1023 2.51296L14 6M14 14H7C4.79086 14 3 12.2091 3 10V10C3 7.79086 4.79086 6 7 6H14" stroke={props.active === 'ads' ? "#0078FF" : "#333333"} strokeWidth="1.5">
                        </path><path d="M7.75716 19.3001L7 14H11L11.6772 18.7401C11.8476 19.9329 10.922 21 9.71716 21C8.73186 21 7.8965 20.2755 7.75716 19.3001Z" stroke={props.active === 'ads' ? "#0078FF" : "#333333"} strokeWidth="1.5">
                        </path>
                    </svg>
                    <span style={props.active === "ads" ? { color: '#0078FF' } : {}} >Ads</span>
                </div>

                <div onClick={() => { navigation('competition') }} className={Styles.single_nav_item} >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.75 15.75H11.25M6.75 15.75V12M6.75 15.75H2.7C2.58065 15.75 2.46619 15.7026 2.3818 15.6182C2.29741 15.5338 2.25 15.4193 2.25 15.3V12.45C2.25 12.3307 2.29741 12.2162 2.3818 12.1318C2.46619 12.0474 2.58065 12 2.7 12H6.75M11.25 15.75V6.75M11.25 15.75H15.3C15.4193 15.75 15.5338 15.7026 15.6182 15.6182C15.7026 15.5338 15.75 15.4193 15.75 15.3V2.7C15.75 2.58065 15.7026 2.46619 15.6182 2.3818C15.5338 2.29741 15.4193 2.25 15.3 2.25H11.7C11.5807 2.25 11.4662 2.29741 11.3818 2.3818C11.2974 2.46619 11.25 2.58065 11.25 2.7V6.75M6.75 12V7.2C6.75 7.08065 6.79741 6.96619 6.8818 6.8818C6.96619 6.79741 7.08065 6.75 7.2 6.75H11.25" stroke={props.active === 'competition' ? "#0078FF" : "#333333"} strokeWidth="1.5" />
                    </svg>


                    <span style={props.active === "competition" ? { color: '#0078FF' } : {}} >Competition</span>
                </div>

                <div onClick={() => { navigation('reports') }} className={Styles.single_nav_item} >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.75 15.75H11.25M6.75 15.75V12M6.75 15.75H2.7C2.58065 15.75 2.46619 15.7026 2.3818 15.6182C2.29741 15.5338 2.25 15.4193 2.25 15.3V12.45C2.25 12.3307 2.29741 12.2162 2.3818 12.1318C2.46619 12.0474 2.58065 12 2.7 12H6.75M11.25 15.75V6.75M11.25 15.75H15.3C15.4193 15.75 15.5338 15.7026 15.6182 15.6182C15.7026 15.5338 15.75 15.4193 15.75 15.3V2.7C15.75 2.58065 15.7026 2.46619 15.6182 2.3818C15.5338 2.29741 15.4193 2.25 15.3 2.25H11.7C11.5807 2.25 11.4662 2.29741 11.3818 2.3818C11.2974 2.46619 11.25 2.58065 11.25 2.7V6.75M6.75 12V7.2C6.75 7.08065 6.79741 6.96619 6.8818 6.8818C6.96619 6.79741 7.08065 6.75 7.2 6.75H11.25" stroke={props.active === 'reports' ? "#0078FF" : "#333333"} strokeWidth="1.5" />
                    </svg>


                    <span style={props.active === "reports" ? { color: '#0078FF' } : {}} >Reports</span>
                </div>

            </div>


        </div>
    )
}

export default FacebookNavbar