import { useContext } from "react"
import Linkedin from "../../Page/Linkedin/linkedin"
import { UserNavigationContext } from "../../store/NavigationProvider"
import Instagram from "../../Page/Instagram/Instagram"
import Styles from './RightSidePanel.module.css'
import Facebook from "../../Page/Facebook/Facebook"
import { Dashboard } from "../../Page/Dashboard/Dashboard"
import Pinterest from "../../Page/Pinterest/Pinterest"

const RightSidePanel = () => {
    const activeSocialMedia = useContext(UserNavigationContext)

    return (
        <div className={Styles.right_side_container} >
            {activeSocialMedia.activeRightSideSocialMedia === "dashboard" ? <Dashboard /> : <></>}
            {activeSocialMedia.activeRightSideSocialMedia === "linkedinpage" ? <Linkedin /> : <></>}
            {activeSocialMedia.activeRightSideSocialMedia === "instagram" ? <Instagram /> : <></>}
            {activeSocialMedia.activeRightSideSocialMedia === "facebookpage" ? <Facebook /> : <></>}
            {activeSocialMedia.activeRightSideSocialMedia === "pinterest" ? <Pinterest /> : null}
        </div>
    )
}

export default RightSidePanel