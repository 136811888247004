import React from 'react'
import Styles from './PinterestBoardDetails.module.css'
import { formatNumber } from '../../Util/helper';


const PinterestBoardDetails = (props) => {

    const captionTruncate = (str) => {
        return str.length > 50 ? str.substring(0, 150) + "..." : str;
    }
    const convertToSlug = (inputString) => {
        const slug = inputString
            .toLowerCase()
            .replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');

        return slug;
    }

    return (
        <div className={Styles.pinterest_board_details_model_container} >
            <div className={Styles.container} >
                <div className={Styles.header_container} >
                    <span>Board</span>
                    {/* Corss SVG Icon */}
                    <svg style={{ cursor: 'pointer' }} onClick={() => props.close()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg>
                </div>
                <div className={Styles.chart_container} >
                    <div className={Styles.post_content_container} >
                        <div className={Styles.image_container} >
                            <img alt="" src={props?.board?.media?.image_cover_url} />
                        </div>
                        <div className={Styles.content_container} >
                            <div className={Styles.header_value_container} >
                                <span>Board Name</span>
                                <span>{props?.board?.name}</span>
                            </div>
                            <div className={Styles.header_value_container} >
                                <span>Engagement</span>
                                <span>{formatNumber( props?.calculateBoardStats(props?.board?.pins)?.engagement || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Outbound Clicks</span>
                                <span>{formatNumber( props?.calculateBoardStats(props?.board?.pins)?.outboundClicks || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Impressions</span>
                                <span>{formatNumber( props.calculateBoardStats(props.board.pins).impression || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Pin Clicks</span>
                                <span>{formatNumber( props.calculateBoardStats(props.board.pins).pinClicks || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Save</span>
                                <span>{formatNumber( props.calculateBoardStats(props.board.pins).save || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Follower Count</span>
                                <span>{formatNumber( props.board.follower_count || 0)}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>
                            <div className={Styles.header_value_container} >
                                <span>Created Date</span>
                                <span>{new Date(props.board.created_at).getFullYear() + "-" + (new Date(props.board.created_at).getMonth() + 1) + "-" + new Date(props.board.created_at).getDate()} {new Date(props.board.created_at).toLocaleTimeString('en-US')}</span>
                            </div>
                            <div className={Styles.border_of_post} ></div>

                        </div>



                    </div>
                    <div className={Styles.commentary_container} style={{ whiteSpace: 'break-spaces' }}  >
                        {props.board.description !== undefined ? props.board.description  : "This board has no description"}
                    </div>


                    <div className={Styles.pins_container}>
                        <div className={Styles.header}>
                            <h1>Board Top Pins</h1>
                            <span onClick={() => {
                                window.open(`https://in.pinterest.com/${props.board.owner.username}/${convertToSlug(props.board.name)}`)
                            }}>See all</span>
                        </div>
                        {props.board.pins.slice(0, 5).map((data, index) => {
                            return (
                                <div className={Styles.pin} key={index}>
                                    {data.media.media_type === "image" ? (
                                        <img alt="" src={data.media.images["1200x"].url} />
                                    ) : (
                                        <img alt="" src={data.media.cover_image_url} />
                                    )}
                                    <div className={Styles.info}>
                                        <span>{captionTruncate(data.description)}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                </div>


            </div>
        </div>
    )
}

export default PinterestBoardDetails