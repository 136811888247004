export const staff =[
    {
        "$URN": "SIZE_2_TO_10",
        "name":  "2 - 10 Employees"
        
    },
    {
        "$URN": "SIZE_11_TO_50",
        "name": "11 - 50 Employees"
            
    },
    {
        "$URN": "SIZE_51_TO_200",
        "name": "51 - 200 Employees"
        
    },
    {
        "$URN": "SIZE_201_TO_500",
        "name":  "201 - 500 Employees"
    },
    {
        "$URN": "SIZE_501_TO_1000",
        "name":  "501 - 1000 Employees"
    },
    {
        "$URN": "SIZE_1001_TO_5000",
        "name":  "1001 - 5000 Employees"
        
    },
    {
        "$URN": "SIZE_5001_TO_10000",
        "name":  "5001 - 10000 Employees"
    },
    {
        "$URN": "SIZE_10001_OR_MORE",
        "name":  "10000+ Employees"
        
    },
    {
        "$URN": "SIZE_1",
        "name":  "1 Employees"
        
    }
    ]
