import {useEffect, useState,useContext} from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../Util/constant';
import Style from './ReauthorizeComponent.module.css'
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import { UserNavigationContext } from '../../store/NavigationProvider'

const ReauthorizeComponent=()=>{
    const location = useLocation();

    const [loading, setloading] = useState(false)
    const accountContext = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    
    const reauthorize = async (accountType, socialId) => {
        let workspaceId = localStorage.getItem('organizationId')
        if (accountType === "instagram") {
            login('instagram', 'reauthorize', socialId);
        } else if (accountType === "facebookpage") {
            login('facebookpage', 'reauthorize', socialId);
        } else if (accountType === "linkedinpage") {
            window.open(`${url}linkedin/reauthorize/${workspaceId}/linkedinpage/analytics`, '_self')
        } else if (accountType === "pinterest") {
            window.open(`${url}pinterest/reauthorize/${workspaceId}/analytics`, '_self')
        }
    }

    const login = async (value, connectionType, socialId) => {
        try {

            if (parseInt(accountContext.userData.featureFactoryData.socialAccounts) === 0) return toast.error("Your account connect limit has been reached, please upgrade to connect more accounts.")
            setloading(true)
            if (value === "pinterest") {


                let workspaceId = localStorage.getItem('organizationId')
                window.open(`${url}pinterest/login/${workspaceId}/analytics`, '_self')
            } else if (value === "linkedinpage") {


                let workspaceId = localStorage.getItem('organizationId')
                window.open(`${url}linkedin/login/${workspaceId}/page/analytics`, '_self')
            } else {


                window.FB.getLoginStatus(response => {

                    if (response.status === 'connected') {

                        window.FB.logout(() => {
                            callLogin(value, connectionType, socialId)
                        })
                    } else {
                        callLogin(value, connectionType, socialId)
                    }
                })
            }
        } catch (error) {
            toast.error(error.response.data.error)
        }
    }

    const callLogin = (value, connectionType, socialId) => {

        let scopes = [{
            connectionType: "instagram",
            scope: ['public_profile', 'pages_show_list', 'pages_read_engagement', 'instagram_content_publish', 'instagram_basic', 'instagram_manage_messages', 'pages_manage_metadata', 'instagram_manage_comments', 'read_insights', 'instagram_manage_insights', 'pages_messaging', 'business_management']
        }, {
            connectionType: "facebookpage",
            scope: ['public_profile', 'pages_show_list', 'pages_read_engagement', 'pages_manage_posts', 'pages_messaging', 'pages_manage_metadata', 'pages_read_user_content', 'pages_manage_engagement', 'read_insights', 'business_management']
        }]

        let data = scopes.filter(data => data.connectionType === value)

        window.FB.login((response) => {
            const facebookUser = response.authResponse;

            getTheLoneLivedAccessToken(facebookUser, value, connectionType, socialId);
        }, {
            scope: data[0].scope,
        })
    }

    const getTheLoneLivedAccessToken = async (facebookUser, value, connectionType, socialId) => {
        try {
            let user = facebookUser;
            let res = await axios.get(`https://graph.facebook.com/v17.0/oauth/access_token?  
                    grant_type=fb_exchange_token&          
                    client_id=957071665735231&
                    client_secret=769f5b546f0d0698f17d861bfa19d3a4&
                    fb_exchange_token=${user.accessToken}`);

            user.accessToken = res.data.access_token;

            // check which type of data we have to show to user
            if (value === 'facebookpage') {

                connectToPages(user, connectionType, socialId);
            } else if (value === 'instagram') {
                connectToInstagram(user, connectionType, socialId);
            }
        } catch (error) {
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
        }
    }

    const connectToPages = async (facebookUser, connectionType, socialId) => {
        try {

            const res = await axios.get(`https://graph.facebook.com/${facebookUser.userID}/accounts?fields=name,access_token,picture,is_owned&limit=100&access_token=${facebookUser.accessToken}`)
            const pages = res.data.data

            const filterPages = pages.map((data) => {
                let obj = {
                    accountType: "facebookpage",
                    facebookUserId: facebookUser.userID,
                    socialId: data.id,
                    profileURL: data.picture.data.url,
                    name: data.name,
                    accessToken: data.access_token,
                    admin: data.is_owned,
                    refreshToken: null,
                    active: false,
                    accessTokenExpiresIn: 5184000,
                    refreshTokenExpiresIn: null,
                    accessTokenCreatedAt: Date.now() / 1000,
                    isAccountTokensExpired: false,
                };
                return obj;
            });

            const userCanPostAccounts = filterPages.filter((data) => data.admin === true);
            if (connectionType === "reauthorize") {
                let token = localStorage.getItem("token")
                const config = { headers: { Authorization: `Bearer ${token}` } };
                let workspaceId = localStorage.getItem("organizationId")

                const reauthorizeAccount = userCanPostAccounts.find((data) => data.socialId === socialId)
                const response = await axios.post(`${url}accounts/update/info`, {
                    workspaceId: workspaceId,
                    account: reauthorizeAccount
                }, config)

                userNavigation.setisConnectAccountPopup(false)
                accountContext.getAccounts()
                toast.success(response.data.message)


            }
            setloading(false)
        } catch (error) {
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
        }
    }

    const connectToInstagram = async (facebookUser, connectionType, socialId) => {
        try {

            const res = await axios.get(`https://graph.facebook.com/${facebookUser.userID}/accounts?fields=name,access_token,picture,is_owned&limit=100&access_token=${facebookUser.accessToken}`);

            const instagramPages = res.data.data;

            for (const data of instagramPages) {
                const response = await axios.get(`https://graph.facebook.com/v17.0/${data.id}?fields=instagram_business_account&access_token=${data.access_token}`);
                if (response.data.instagram_business_account !== undefined) {
                    data.instagram_id = response.data.instagram_business_account.id;
                }
            }

            const filterThePagesHasInstagramId = instagramPages.filter((data) => data.instagram_id !== undefined);

            let filterInstagram = filterThePagesHasInstagramId.map(async (data) => {
                let res = await axios.get(`https://graph.facebook.com/v17.0/${data.instagram_id}?fields=profile_picture_url,username&access_token=${data.access_token}`);

                let errorMsg = '';

                if (errorMsg === '(#10) The user is not an Instagram Business') {
                    let obj = {
                        accountType: "instagram",
                        facebookUserId: facebookUser.userID,
                        socialId: data.instagram_id,
                        profileURL: res.data.profile_picture_url,
                        name: res.data.username,
                        accessToken: data.access_token,
                        refreshToken: null,
                        active: false,
                        refreshTokenExpiresIn: null,
                        accessTokenCreatedAt: Date.now() / 1000,
                        accessTokenExpiresIn: 5184000,
                        isAccountTokensExpired: false,
                        instagramAccountType: 'creator',
                        facebookPageId: data.id
                    }
                    return obj;
                } else {
                    let obj = {
                        accountType: "instagram",
                        facebookUserId: facebookUser.userID,
                        socialId: data.instagram_id,
                        profileURL: res.data.profile_picture_url,
                        name: res.data.username,
                        accessToken: data.access_token,
                        refreshToken: null,
                        active: false,
                        refreshTokenExpiresIn: null,
                        accessTokenCreatedAt: Date.now() / 1000,
                        accessTokenExpiresIn: 5184000,
                        isAccountTokensExpired: false,
                        instagramAccountType: 'business',
                        facebookPageId: data.id
                    }

                    return obj;
                }
            });

            if (connectionType === "reauthorize") {
                filterInstagram = await Promise.all(filterInstagram);
                const filterInstaArray = filterInstagram.filter((value) => Object.keys(value).length !== 0);
                const reauthorizeAccount = filterInstaArray.find((data) => data.socialId === socialId)
                let token = localStorage.getItem("token")
                const config = { headers: { Authorization: `Bearer ${token}` } };
                let workspaceId = localStorage.getItem("organizationId")
                const response = await axios.post(`${url}accounts/update/info`, {
                    workspaceId: workspaceId,
                    account: reauthorizeAccount
                }, config)
                userNavigation.setisConnectAccountPopup(false)
                accountContext.getAccounts()
                toast.success(response.data.message)

            }
            setloading(false)
        } catch (error) {
           // toast.error('Something went to wrong')
        }
    };

    useEffect(() => {

        const { type, code } = queryString.parse(location.search);
        if (type === "linkedinpage") {
            getSocialAccountFromBackend(code, type);

        }
        if (type === "pinterest") {
            getSocialAccountFromBackend(code, type);

        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getSocialAccountFromBackend = async (id, type) => {
        try {
            setloading(true)
            let workspaceId = localStorage.getItem('organizationId')
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.get(`${url}accounts/details/${id}/${workspaceId}/${type}`, config)


            let resOfAccount = await axios.get(url + `accounts/${workspaceId}`, config)
            resOfAccount.data = resOfAccount.data.filter(data => data.accountType === "linkedinpage" || data.accountType === "instagram" || data.accountType === "facebookpage" || data.accountType === "pinterest")
            accountContext.getAccounts()
            setloading(false)
            //setAccountsToConnect(userConnectedAccount)

            accountContext.handleAccountConnectionLoading(false)
            userNavigation.setisConnectAccountPopup(false)
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error(error !== "internal server error" ? "Token is expired!" : "Internal server error!")
        }
    }

    return(
        <div className={Style.reauthorize_social_popup} >
            
            <button onClick={()=>reauthorize((accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].accountType, (accountContext.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].socialId)} >{loading ? "Loading..." :"Reauthorize"}</button>
            
        </div>
    )
}

export default ReauthorizeComponent