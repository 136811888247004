import { useContext, useEffect, useState } from 'react'
import OverviewCard from '../../Component/OverviewCard/overviewcard'
import Styles from './FacebookOverview.module.css'
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics'
import InstagramOverviewSkeleton from '../../Component/InstagramOverviewSkeleton/InstagramOverviewSkeleton'
import FacebookNavbar from '../../Component/FacebookNavbar/FacebookNavbar'
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent'
import { formatNumber } from '../../Util/helper'
import Next from '../../assets/nextArrow.svg'
import Back from '../../assets/backArrow.svg'
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup'
import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup'
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount'
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount'
import BlogListCard from '../../Component/BlogListCard/BlogListCard'
import { UserNavigationContext } from '../../store/NavigationProvider'
import { createNavigationContext } from '../../store/navigation'
import AudienceAcivityNoData from '../../Component/AudienceAcivityNoData/AudienceAcivityNoData'
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent'


const FacebookOverview = () => {
    const facebookContext = useContext(CreateFacebookAnalyticContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const navigation = useContext(createNavigationContext)

    const [hoverPageLike, sethoverPageLike] = useState(false)
    const [hoverImpression, sethoverImpression] = useState(false)
    const [hoverPageActivity, sethoverPageActivity] = useState(false)

    const [activeAlbumImage, setActiveAlbumImage] = useState(0)


    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const previousImage = () => {
        if (activeAlbumImage > 0) {
            setActiveAlbumImage(activeAlbumImage - 1)
        }
    }

    const nextImage = () => {
        if (facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.subattachments?.data?.length !== activeAlbumImage + 1) {
            setActiveAlbumImage(activeAlbumImage + 1)
        }

    }

    const captionTruncate = (str) => {
        return str?.length > 335 ? str?.substring(0, 333) + "..." : str;
    }

    const data = {
        labels: facebookContext?.overviewStats?.likesData?.map(data => {
            const newDate = new Date(data.end_time);
            const monthShortName = newDate.toLocaleString('default', { month: 'short' });
            const day = String(newDate.getDate()).padStart(2, '0');
            return `${monthShortName}-${day}`
        })?.slice(0, -1),
        datasets: [{
            label: 'Page Likes',
            data: facebookContext.overviewStats?.likesData.map(data => data.value),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverPageLike ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverPageLike ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    };


    useEffect(() => {
        if (facebookContext.overviewPageFilterLoading) {
            facebookContext.getFacebookOverviewPageAnalyticaData()
            facebookContext.getFacebookContentPageAnalyticaData()
        }
        // eslint-disable-next-line
    }, [facebookContext.selectedPeriod])



    function getDayName(dateStr, locale) {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: 'long' });
    }



    const getBackGroundColor = (online_follower_percentage) => {
        if (online_follower_percentage >= 0 && online_follower_percentage <= 15)
            return '#e6f2ff'

        if (online_follower_percentage >= 16 && online_follower_percentage <= 30)
            return '#99c9ff';

        if (online_follower_percentage >= 31 && online_follower_percentage <= 45)
            return '#4da1ff'

        if (online_follower_percentage >= 46 && online_follower_percentage <= 60)
            return '#0078ff'

        if (online_follower_percentage >= 61 && online_follower_percentage <= 75)
            return '#0060cc';

        if (online_follower_percentage >= 76 && online_follower_percentage <= 90)
            return '#004899'

        if (online_follower_percentage >= 91 && online_follower_percentage <= 100)
            return '#003066'

    }

    const impressionChartData = {
        labels: facebookContext.dateArray,
        datasets: [
            {
                label: 'Impression count',
                data: facebookContext.impressions.map(data => data.value),
                lineTension: 0.5,
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: "rgba(0,120,255,0.25)"
                },
                borderColor: '#0078FF',

                pointBorderColor: hoverImpression ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
                pointBackgroundColor: hoverImpression ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
                pointHoverBackgroundColor: '#ff43ca',
                pointHoverBorderColor: '#ff43ca'
            }
        ]
    }

    const profileActivityChartData = {
        labels: facebookContext.dateArray,
        datasets: [{
            label: 'Page View',
            data: facebookContext.pageView.map(data => data.value),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            pointBorderColor: hoverPageActivity ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverPageActivity ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        },
        {
            label: 'Website Clicks',
            data: facebookContext.websiteClicks.map(data => data.value),
            lineTension: 0.5,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(255, 117, 37,0.25)"
            },
            borderColor: '#ff8a25',
            borderDash: [4, 3],
            pointBorderColor: hoverPageActivity ? 'rgba(255, 117, 37, 1)' : 'rgba(255, 117, 37, 0)',
            pointBackgroundColor: hoverPageActivity ? 'rgba(255, 117, 37, 0.5)' : 'rgba(255, 117, 37, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }
        ]
    }

    function getRangeValues(input, range, color) {

        const { value } = input;
        const [startHour, endHour] = range?.split('-').map(Number);

        const output = [];
        for (let i = startHour; i < endHour; i++) {
            const key = `${i}-${i + 1}`;
            const val = value[i];
            output.push({ [key]: val });
        }

        return output.map((data, index) => {

            return (
                <span key={index} style={{ display: 'flex', alignItems: 'center', gap: 5 }}><span style={{ backgroundColor: color }} className={Styles.circle} ></span> {Object.keys(data)[0] === "0-1" ? "12-1" : Object.keys(data)}: {Object.values(data)[0] || 0} followers <br /></span>
            )
        });
    }

    const navigate = (value) => {
        navigation.setActive(value)
    }

    return (
        <div className={Styles.facebook_overview_container} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {facebookContext.overviewPageLoading ? <InstagramOverviewSkeleton name="facebook" /> :
                <div className={Styles.overview_container} >

                    <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.overview_content_main_container} >
                        <ActiveSocialAccount page="facebookpage" disableFilter={true} loading={facebookContext.overviewPageLoading} selected={facebookContext.selectedPeriod === 30 ? (facebookContext.selectedPeriod + 1) : facebookContext.selectedPeriod} onChangePeriod={(value) => { facebookContext.handleSelectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "facebookpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); facebookContext.onChangeoverviewPageFilterLoading(true) }} />

                        <FacebookNavbar active="overview" />

                        <div className={Styles.overview_content_container} >

                            {facebookContext.overviewStats?.likes < 100 ? <BlogListCard type="facebook" /> : <></>}
                            <div className={Styles.card_container} >
                                <OverviewCard toolTip="Users who follow and engage with your Facebook page, showing interest in your content and brand." data={true} count={formatNumber(facebookContext.overviewStats?.likes || 0)} percentage={facebookContext.overviewStats?.likesPercentage} header="Page Likes" />
                                <OverviewCard toolTip="Facebook Impressions: Total views of your content on Facebook, showing reach and visibility." people={true} count={formatNumber(facebookContext.overviewStats?.impressions || 0)} percentage={facebookContext.overviewStats?.impressionsPercentage} header="Impressions" />
                                <OverviewCard toolTip="Total times your page has been viewed. Helps track audience interest and measure social media presence." profile={true} count={formatNumber(facebookContext.overviewStats?.pageViews || 0)} percentage={facebookContext.overviewStats?.pageViewsPercentage} header="Page Views" />
                                <OverviewCard toolTip="Drive targeted traffic to your site! Increase exposure and engagement with Website Clicks ads on Facebook. Perfect for boosting online presence." website={true} count={formatNumber(facebookContext.overviewStats?.websiteClicks || 0)} percentage={facebookContext.overviewStats?.websiteClicksPercentage} header="Website Clicks" />
                            </div>
                            <div className={Styles.latest_pin_and_followers_container} >
                                <div>
                                    <div className={Styles.header_container} >
                                        <label> Latest Post</label>
                                        <span onClick={() => navigate('content')} style={{ cursor: 'pointer' }} >Show All <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                            <path d="M6.125 11.25L9.875 7.5L6.125 3.75" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></span>
                                    </div>
                                    {facebookContext?.latestPostDetails?.length ? <>
                                        <div className={Styles.latest_post_container} >
                                            <div className={Styles.img_container} >


                                                {facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.type === "video_autoplay" || facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.type === "video_inline" ? <img alt="" src={facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.media?.image.src} /> : null}

                                                {facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.type === "cover_photo" || facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.type === "profile_media" ? <img alt="" src={facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.media?.image?.src} /> : null}

                                                {facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.type === "album" ? <img alt="" src={facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.subattachments?.data[activeAlbumImage]?.media?.image?.src} /> : null}

                                                {facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.type === "share" ? <img alt="" src={facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.media?.image?.src} /> : null}

                                                {facebookContext?.latestPostDetails[0]?.attachments?.data[0]?.type === "album" ?
                                                    <div className={Styles.button_container} >
                                                        <button onClick={previousImage}> <img src={Back} alt="back" /> </button>
                                                        <button onClick={nextImage}><img src={Next} alt="next" /> </button>
                                                    </div> : <></>
                                                }

                                            </div>
                                            <section className={Styles.content_container_overview_facebook} >
                                                <div className={Styles.header_value_container_facebook} >
                                                    <label>Likes</label>
                                                    <p className={Styles.value} >{formatNumber(facebookContext?.latestPostDetails[0]?.likes?.summary?.total_count)}</p>
                                                </div>

                                                <div className={Styles.header_value_container_facebook} >
                                                    <label>Comments</label>
                                                    <p>{formatNumber(facebookContext?.latestPostDetails[0]?.comments?.summary?.total_count)}</p>
                                                </div>

                                                <div className={Styles.header_value_container_facebook} >
                                                    <label>Engagement</label>
                                                    <p>{formatNumber(facebookContext?.latestPostDetails[0]?.statistics?.filter(data => data.name === "post_engaged_users")[0]?.values[0].value)}</p>
                                                </div>

                                                <div className={Styles.header_value_container_facebook} >
                                                    <label>Post Clicks</label>
                                                    <p>{formatNumber(facebookContext?.latestPostDetails[0]?.statistics?.filter(data => data.name === "post_engaged_users")[0]?.values[0].value)}</p>
                                                </div>


                                                <div className={Styles.header_value_container_facebook} >
                                                    <label>Impressions</label>
                                                    <p>{formatNumber(facebookContext?.latestPostDetails[0]?.statistics?.filter(data => data.name === "post_impressions")[0]?.values[0].value)}</p>
                                                </div>

                                                <div className={Styles.header_value_container_facebook} >
                                                    <label>Created At</label>
                                                    <p>{new Date(facebookContext?.latestPostDetails[0].created_time).getDate() + "/" + (new Date(facebookContext?.latestPostDetails[0].created_time).getMonth() + 1) + "/" + new Date(facebookContext?.latestPostDetails[0].created_time).getFullYear()} {new Date(facebookContext?.latestPostDetails[0]?.created_time).toLocaleTimeString('en-US')}</p>
                                                </div>

                                            </section>

                                        </div>
                                        {facebookContext?.latestPostDetails[0]?.message ? <p>{captionTruncate(facebookContext?.latestPostDetails[0]?.message)}</p> : <p>This content has no caption</p>}

                                    </> : <NoDataAvailablePopup cardName="latestpost" />

                                    }

                                </div>

                                <LineChartCustomComponent onHoverOut={() => sethoverPageLike(false)} onHoverIn={() => sethoverPageLike(true)} type="line" header="Page Likes" subheader="" contentArray={[{ "header": "Total Likes", value: formatNumber(facebookContext.overviewStats?.likes || 0) }, { "header": "New Likes Added", value: formatNumber(facebookContext.overviewStats?.newPageLikes || 0) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverPageLike ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={data} />
                            </div>

                            <div className={Styles.audience_activity_container} >
                                <div className={Styles.header_container} >
                                    <label>Audience Activity</label>
                                    <button>See all</button>
                                </div>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>
                                    <div className={Styles.date_and_activity_container} >
                                        {facebookContext?.onlineFans[0]?.date !== undefined && facebookContext?.onlineFans?.some(data => data.online_follower !== 0) ? <>
                                            <label>{new Date(facebookContext?.onlineFans[0]?.date)?.getDate()} {monthNames[new Date(facebookContext?.onlineFans[0]?.date).getMonth()]}, {getDayName(facebookContext?.onlineFans[0]?.date)} -<br />Based on last week's activity, the best time to post today is <b>between {facebookContext?.onlineFans?.filter(data => data.online_follower === Math.max(...facebookContext.onlineFans.map(data => data.online_follower)))?.map(data => data.time)[0]}{facebookContext?.onlineFans?.filter(data => data.online_follower === Math.max(...facebookContext.onlineFans.map(data => data.online_follower)))?.map(data => data.time).length > 1 ? `, ${facebookContext?.onlineFans?.filter(data => data.online_follower === Math.max(...facebookContext.onlineFans.map(data => data.online_follower)))?.map(data => data.time)[1]}` : ''}.</b>
                                            </label>
                                            <div className={Styles.activity_container} >
                                                {facebookContext.onlineFans.map((data, index) => {

                                                    return (
                                                        <div key={index} className={Styles.activity_item} >
                                                            <div style={{ backgroundColor: getBackGroundColor(parseFloat((data.online_follower / facebookContext.overviewStats?.likes) * 100).toFixed(0)), border: `${Math.max(...facebookContext.onlineFans.map(data => data.online_follower)) === data.online_follower ? '3px solid #ff43ca' : 'none'}` }} className={Styles.activity} >
                                                                <div className={Styles.hover_card_container} >
                                                                    <label>Total: <b>{(data.online_follower || 0)}</b> followers</label>
                                                                    <div className={Styles.hover_sub_container} >

                                                                        <p>{getRangeValues(facebookContext.onlineFansResponse.filter(item => item.end_time === data.date)[0], data.time === '12-2' ? '0-2' : data.time, getBackGroundColor(parseFloat((data.online_follower / facebookContext.overviewStats?.likes) * 100).toFixed(0)))}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <svg width="1" height="5" viewBox="0 0 1 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="0.25" y1="0.5" x2="0.25" y2="4.5" stroke="#808080" strokeWidth="0.5" />
                                                            </svg>
                                                            <label>{data.time}</label>

                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </> : <AudienceAcivityNoData />}

                                    </div>
                                </> : <PremiumPopup cardName="audience" />

                                }

                            </div>

                            <div className={Styles.reach_and_profile_activity} >
                                <LineChartCustomComponent onHoverOut={() => sethoverImpression(false)} onHoverIn={() => sethoverImpression(true)} type="line" header="Impressions" subheader="" contentArray={[{ "header": "Impressions", type: "solid", indicator: "#0078FF", value: formatNumber(facebookContext.impressions.reduce((totalImpression, data) => { return totalImpression += data.value }, 0)) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverImpression ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={impressionChartData} />

                                <LineChartCustomComponent onHoverOut={() => sethoverPageActivity(false)} onHoverIn={() => sethoverPageActivity(true)} type="line" header="Page Activity" subheader="" contentArray={[{ "header": "Page Views", type: "solid", indicator: "#0078FF", value: formatNumber(facebookContext.pageView.reduce((totalView, data) => { return totalView += data.value }, 0)) }, {
                                    "header": "Website Clicks", type: "dashed", indicator: "#ff8a25", value: formatNumber(facebookContext.websiteClicks.reduce((totalClicks, data) => { return totalClicks += data.value }, 0))
                                }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverPageActivity ? 5 : 5,
                                    scales: {
                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    },
                                }} chartData={profileActivityChartData} />
                            </div>








                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

export default FacebookOverview