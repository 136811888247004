import GraphSkeleton from '../GraphSkeleton/GraphSkeleton'
import Styles from './ContentPageSkeleton.module.css'
export const ContentPageSkeleton = ({ activeTab }) => {

    return (
        <div className={Styles.instagram_content_container} >
            {activeTab !== 'keyword' && activeTab !== "hashtag" && activeTab !== "mentions" ?
                <div className={Styles.card_container} >
                    {[1, 2, 3, 4].map(() => {
                        return <div  >
                            <div className={Styles.card_content} >
                                <div className={`${Styles.svg_container} ${Styles.skeleton}`} ></div>
                                <div className={Styles.card_content_header} >
                                    <div className={Styles.skeleton} ></div>
                                    <div className={Styles.skeleton} ></div>
                                </div>

                                <div className={`${Styles.percentage_container} ${Styles.skeleton}`} >

                                </div>

                            </div>


                        </div>
                    })

                    }
                </div> : <></>
            }
            {activeTab === 'feed' || activeTab === 'reel' || activeTab === "video" || activeTab === 'mentions' || activeTab === 'story' ?
                <div className={Styles.graph_skeleton_container} >
                    <GraphSkeleton />
                    <GraphSkeleton />
                </div> :
                <></>
            }

            {(activeTab === 'feed') &&
                <div className={Styles.post_main_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.button_container} >

                            <button className={Styles.skeleton} ></button>
                        </div>
                    </div>
                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} > </span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                            </div>
                        </div>

                        {[1, 2, 3].map(() => {

                            return (
                                <div className={Styles.post_item_container} >
                                    <div className={Styles.post_content_container} >

                                        <div className={Styles.post_content_img} >
                                            <img alt="" className={Styles.skeleton} />
                                        </div>

                                        <div className={Styles.post_content} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>


                                    </div>
                                    <div className={Styles.postOtherDetails} >
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} > </div>
                                    </div>
                                </div>
                            )
                        })

                        }

                    </div>
                </div>
            }

            {activeTab === 'feed' || activeTab === 'reel' || activeTab === "video" ?
                <div className={Styles.post_main_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.button_container} >

                            <button className={Styles.skeleton} ></button>
                        </div>
                    </div>
                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} > </span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                            </div>
                        </div>

                        {[1, 2, 3].map(() => {

                            return (
                                <div className={Styles.post_item_container} >
                                    <div className={Styles.post_content_container} >

                                        <div className={Styles.post_content_img} >
                                            <img alt="" className={Styles.skeleton} />
                                        </div>

                                        <div className={Styles.post_content} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>


                                    </div>
                                    <div className={Styles.postOtherDetails} >
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} > </div>
                                    </div>
                                </div>
                            )
                        })

                        }

                    </div>
                </div> : <></>}
            {activeTab === 'feed' || activeTab === 'reel' || activeTab === "video" ?
                <div className={Styles.graph_skeleton_container} >
                    <GraphSkeleton />
                    <div className={Styles.post_main_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.button_container} >


                            </div>
                        </div>
                        <div className={Styles.post_container} >
                            <div className={Styles.header_container} >
                                <span className={Styles.skeleton} ></span>
                                <div className={Styles.postHeaderContainer} >
                                    <span className={Styles.skeleton} ></span>
                                </div>
                            </div>

                            {[1, 2, 3].map(() => {

                                return (
                                    <div className={Styles.post_item_container} >
                                        <div className={Styles.post_content_container} >
                                            <div className={Styles.post_content_img} >
                                                <img alt="" className={Styles.skeleton} />
                                            </div>
                                            <div className={Styles.post_content} >
                                                <span className={Styles.skeleton} ></span>
                                                <p className={Styles.skeleton} ></p>
                                            </div>
                                        </div>
                                        <div className={Styles.postOtherDetails} >
                                            <div className={Styles.skeleton} ></div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div> : <></>
            }



            {(activeTab === 'reel' || activeTab === "video") ?
                <div className={Styles.post_main_container} >
                    <div className={Styles.header_container} >
                        <span className={Styles.skeleton} ></span>
                        <div className={Styles.button_container} >

                            <button className={Styles.skeleton} ></button>
                        </div>
                    </div>
                    <div className={Styles.post_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.postHeaderContainer} >
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} > </span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                                <span className={Styles.skeleton} ></span>
                            </div>
                        </div>
                        {[1, 2, 3].map(() => {

                            return (
                                <div className={Styles.post_item_container} >
                                    <div className={Styles.post_content_container} >

                                        <div className={Styles.post_content_img} >
                                            <img alt="" className={Styles.skeleton} />
                                        </div>

                                        <div className={Styles.post_content} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>


                                    </div>
                                    <div className={Styles.postOtherDetails} >
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>

                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} ></div>
                                        <div className={Styles.skeleton} > </div>
                                    </div>
                                </div>
                            )
                        })

                        }

                    </div>
                </div> : <></>
            }

            {activeTab === 'story' &&
                <div style={{ marginBottom: 160 }} className={Styles.story_container} >
                    {[1, 2, 3].map((data, index) => {

                        return (
                            <div key={index} >

                                <div className={Styles.story_container_cards} >
                                    <div className={Styles.story_date_container} >
                                        <div className={Styles.header_value_container} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>
                                        <div className={Styles.header_value_container} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>
                                        <div className={Styles.header_value_container} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>

                                        <div className={Styles.header_value_container} >
                                            <span className={Styles.skeleton} ></span>
                                            <p className={Styles.skeleton} ></p>
                                        </div>
                                    </div>
                                    <div className={Styles.story_content_container} >
                                        {[1, 2, 3]?.map(() => {

                                            return (
                                                <div className={Styles.story_content} >
                                                    <div className={Styles.story_preview} >
                                                        <img alt="" className={Styles.skeleton} />
                                                    </div>

                                                    <div className={Styles.story_header_value_container} >
                                                        <span className={Styles.skeleton} ></span>
                                                        <p className={Styles.skeleton} ></p>

                                                    </div>


                                                    <div className={Styles.story_header_value_container} >
                                                        <span className={Styles.skeleton} > </span>
                                                        <p></p>

                                                    </div>


                                                    <div className={Styles.story_header_value_container} >
                                                        <span> </span>
                                                        <p></p>

                                                    </div>

                                                    <div className={Styles.story_header_value_container} >
                                                        <span> </span>
                                                        <p></p>

                                                    </div>

                                                    <div className={Styles.story_header_value_container} >
                                                        <span> </span>
                                                        <p></p>

                                                    </div>
                                                </div>

                                            )
                                        })

                                        }
                                    </div>
                                </div>
                            </div>

                        )
                    })

                    }



                </div>
            }

            {(activeTab === 'keyword') &&
                <>
                    <div style={{ marginBottom: 0 }} className={Styles.post_main_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.button_container} >

                                <button className={Styles.skeleton} ></button>
                            </div>
                        </div>
                        <div className={Styles.post_container} >
                            <div className={Styles.header_container} >
                                <span className={Styles.skeleton} ></span>
                                <div className={Styles.postHeaderContainer} >
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} > </span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                </div>
                            </div>

                            {[1, 2, 3].map(() => {

                                return (
                                    <div className={Styles.post_item_container} >
                                        <div className={Styles.post_content_container} >

                                            <div className={Styles.post_content_img} >
                                                <img alt="" className={Styles.skeleton} />
                                            </div>

                                            <div className={Styles.post_content} >
                                                <span className={Styles.skeleton} ></span>
                                                <p className={Styles.skeleton} ></p>
                                            </div>


                                        </div>
                                        <div className={Styles.postOtherDetails} >
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>

                                            <div className={Styles.skeleton} ></div>

                                            <div className={Styles.skeleton} ></div>

                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} > </div>
                                        </div>
                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>

                    <div className={Styles.graph_skeleton_container} >
                        <GraphSkeleton />
                        <GraphSkeleton />
                    </div>
                </>
            }

            {(activeTab === 'hashtag') &&
                <>
                    <div style={{ marginBottom: 0 }} className={Styles.post_main_container} >
                        <div className={Styles.header_container} >
                            <span className={Styles.skeleton} ></span>
                            <div className={Styles.button_container} >

                                <button className={Styles.skeleton} ></button>
                            </div>
                        </div>
                        <div className={Styles.post_container} >
                            <div className={Styles.header_container} >
                                <span className={Styles.skeleton} ></span>
                                <div className={Styles.postHeaderContainer} >
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} > </span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                    <span className={Styles.skeleton} ></span>
                                </div>
                            </div>

                            {[1, 2, 3].map(() => {

                                return (
                                    <div className={Styles.post_item_container} >
                                        <div className={Styles.post_content_container} >

                                            <div className={Styles.post_content_img} >
                                                <img alt="" className={Styles.skeleton} />
                                            </div>

                                            <div className={Styles.post_content} >
                                                <span className={Styles.skeleton} ></span>
                                                <p className={Styles.skeleton} ></p>
                                            </div>


                                        </div>
                                        <div className={Styles.postOtherDetails} >
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>

                                            <div className={Styles.skeleton} ></div>

                                            <div className={Styles.skeleton} ></div>

                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} ></div>
                                            <div className={Styles.skeleton} > </div>
                                        </div>
                                    </div>
                                )
                            })

                            }

                        </div>
                    </div>
                </>
            }

            {(activeTab === 'mentions') &&
                <>
                    <div className={Styles.graph_skeleton_container} >
                        <GraphSkeleton />
                        <GraphSkeleton />
                    </div>
                </>
            }

            {activeTab === 'feed' || activeTab === 'reel' || activeTab === "video" ?
                <div style={{ marginBottom: 160 }} className={Styles.graph_skeleton_container} >
                    <GraphSkeleton />
                    <GraphSkeleton />
                </div> : <></>
            }

        </div>
    )
}