import Styles from './LinkedinPostSkeleton.module.css'
const LinkedinPostSkeleton = () => {
    return (
        <div className={Styles.post_container} >
            {[1, 2, 3,4,5]?.map(() => {

                return (
                    <>
                    <div className={Styles.post_item_container} >
                        <div className={Styles.post_content_container} >
                            <div className={`${Styles.post_content_img} ${Styles.skeleton}`} >


                            </div>

                            <div className={Styles.post_content} >
                                <span className={Styles.skeleton} ></span>
                                <p className={Styles.skeleton} ></p>
                            </div>
                        </div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>

                    </div>
                    <div className={Styles.border} ></div>
                    </>

                )
            })

            }





        </div>
    )
}

export default LinkedinPostSkeleton