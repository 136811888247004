import GraphSkeleton from '../GraphSkeleton/GraphSkeleton'
import Styles from './InstagramAccountSkeleton.module.css'
export const InstagramAccountSkeleton = () => {
    return (
        
        <div className={Styles.account_content_card_container} >
            <div className={Styles.top_bar_card_container} >
                {[1, 2, 3, 4].map(() => {
                    return <div  >
                        <div className={Styles.card_content} >
                            <div className={`${Styles.svg_container} ${Styles.skeleton}`} ></div>
                            <div className={Styles.card_content_header} >
                                <div className={Styles.skeleton} ></div>
                                <div className={Styles.skeleton} ></div>
                            </div>

                            <div className={`${Styles.percentage_container} ${Styles.skeleton}`} >

                            </div>

                        </div>


                    </div>
                })

                }
            </div>
            <div className={Styles.audience_activity} >
                <label className={Styles.skeleton} ></label>
                <span className={Styles.skeleton} ></span>
                <div className={Styles.audience_activity_card_container} >
                    {[1, 2, 3,4,5,6,7].map((index) => {

                        return (
                            <div className={Styles.audience_activity_card} >
                                <div className={Styles.day_name_container} >
                                    <span className={Styles.skeleton} ></span>

                                    <div className={Styles.skeleton} ></div>
                                </div>

                                <div className={Styles.activity_value_container} >
                                    {[1, 2, 3,4,5,6,7,8].map(() => {

                                        return <div className={Styles.value_header_container} >
                                            <div className={`${Styles.value_container} ${Styles.skeleton}`} >

                                            </div>
                                            

                                        </div>
                                    })

                                    }

                                </div>
                            </div>
                        )
                    })

                    }
                </div>

            </div>

            <div className={Styles.follower_location_and_top_cities_container} >
                {/*graph skeleton */}
                <GraphSkeleton />
                {/* progress bar skeleton */}
                <div>
                    <div className={`${Styles.header_container}`} >
                        <span className={Styles.skeleton} ></span>
                        <button className={Styles.skeleton} ></button>
                    </div>

                    {[1, 2, 3, 4, 5, 6, 7,8].map(() => {
                        return (
                            <div className={`${Styles.meter_bar_container} ${Styles.skeleton}`} >
                            </div>
                        )
                    })
                    }

                </div>
            </div>

            <div className={Styles.follower_top_language_and_age_container} >
                {/* progress bar skeleton */}
                <div>
                    <div className={`${Styles.header_container} ${Styles.skeleton}`} >
                        <div className={Styles.skeleton} ></div>
                        <div className={Styles.skeleton} ></div>
                    </div>

                    {[1, 2, 3, 4, 5, 6, 7,8,9].map(() => {
                        return (
                            <div className={`${Styles.meter_bar_container} ${Styles.skeleton}`} >
                            </div>
                        )
                    })
                    }

                </div>
                {/*graph skeleton */}
                <GraphSkeleton />
            </div>

            <div className={Styles.gender_pie_chart_container} >
                {/* graph skeleton */}
                <GraphSkeleton />
                <div  style={{ backgroundColor: '#f8f8f8' }} ></div>
            </div>
        </div>
        
    )
}