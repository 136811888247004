import React, { useEffect, useContext } from 'react'
import Styles from './Facebook.module.css'
import { createNavigationContext } from '../../store/navigation'
import { CreateFacebookAnalyticContext } from '../../store/FacebookAnalytics'
import FacebookOverview from '../FacebookOverview/FacebookOverview'
import { FacebookContent } from '../FacebookContent/FacebookContent'
import FacebookAccount from '../FacebookAccount/FacebookAccount'
import { UserNavigationContext } from '../../store/NavigationProvider'
import AdsLibrary from '../AdsLibrary/AdsLibrary'
import FacebookCompetitorList from '../../Component/FacebookCompetitorList/FacebookCompetitorList'
import { FacebookCompetitor } from '../FacebookCompetitor/FacebookCompetitor'
import Report from '../FacebookReport/Report'


const Facebook = () => {
    const contextOfNavbar = useContext(createNavigationContext)
    const facebookContext = useContext(CreateFacebookAnalyticContext)
    const userNavigation = useContext(UserNavigationContext)
    useEffect(() => {
        contextOfNavbar.getInfo()
        // facebookContext.getFacebookAnalyticsData()
        facebookContext.getFacebookOverviewPageAnalyticaData()
        facebookContext.getFacebookContentPageAnalyticaData()
        facebookContext.getFacebookAccountPageAnalyticaData()
        facebookContext.getFacebookCompetitorPageAnalyticaData()
        // eslint-disable-next-line
    }, [userNavigation.activeSocialMediaName])

    return (
        <div className={Styles.facebook_main_container} >
            {contextOfNavbar.active === 'overview' && <FacebookOverview />}
            {contextOfNavbar.active === 'content' && <FacebookContent />}
            {contextOfNavbar.active === "account" && <FacebookAccount />}
            {contextOfNavbar.active === 'ads' && <AdsLibrary page='facebookpage' />}
            {contextOfNavbar.active === "competition" && <FacebookCompetitor/> }
            {contextOfNavbar.active === "reports" && <Report/> }
        </div>
    )
}

export default Facebook