import OverviewCard from '../../Component/OverviewCard/overviewcard'
import Styles from './LinkedinContent.module.css'
import { useContext, useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    registerables
} from 'chart.js'
import Navbar from '../../Component/Navbar/navbar';
import LinkedinContentSkeleton from '../../Component/LinkedinContentSkeleton/LinkedinContentSkeleton';
import LineChartCustomComponent from '../../Component/LineChartCustomComponent/LineChartCustomComponent';
import { CreateLinkedinAnalyticContext } from '../../store/linkedinAnalytic';
import LinkedinPostDetails from '../../Component/LinkedinPostDetails/LinkedinPostDetails';
import LinkedinPostSkeleton from '../../Component/LinkedinPostSkeleton/LinkedinPostSkeleton';
import { formatNumber } from '../../Util/helper';
import NoDataAvailablePopup from '../../Component/NoDataAvailablePopup/NoDataAvailablePopup';
import PremiumPopup from '../../Component/PremiumPopup/PremiumPopup';
import { UserConnectedAccountContext } from '../../store/UserConnectedAccount';
import ActiveSocialAccount from '../../Component/ActiveSocialAccount/ActiveSocialAccount';
import BlogListCard from '../../Component/BlogListCard/BlogListCard';
import { UserNavigationContext } from '../../store/NavigationProvider';
import { generateDistinctColors } from '../../Util/constant';
import ReauthorizeComponent from '../../Component/ReauthorizeComponent/ReauthorizeComponent';
ChartJS.register(
    ...registerables
)

const LinkedinContent = () => {
    const linkedinContext = useContext(CreateLinkedinAnalyticContext)
    const userData = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const [hoverImpressions, sethoverImpressions] = useState(false)
    const [hoverEngagementRate, sethoverEngagementRate] = useState(false)
    const [hoverLikeGraph, sethoverLikeGraph] = useState(false)
    const [hoverCommentGraph, sethoverCommentGraph] = useState(false)

    const [showPostDetails, setshowPostDetails] = useState(false)
    const [selectedPostDetails, setselectedPostDetails] = useState('')
    const [selectedCategory, setselectedCategory] = useState('')
    const impressionChartData = {
        labels: linkedinContext.followerDateArray,
        datasets: [{
            label: 'Impression',
            data: linkedinContext.impressionData,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            lineTension: 0.5,
            pointBorderColor: hoverImpressions ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverImpressions ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }
    const [page, setpage] = useState(1)
    const limit = 5


    const likeChartData = {
        labels: linkedinContext.followerDateArray,
        datasets: [{
            label: 'Likes',
            data: linkedinContext.followerDateArray.map(date => {
                const post = linkedinContext.postDetails?.elements?.filter(data => {
                    const postDate = new Date(data.createdAt);
                    const monthShortName = postDate.toLocaleString('default', { month: 'short' });
                    const day = String(postDate.getDate()).padStart(2, '0');
                    return `${monthShortName}-${day}` === date;
                });
                if (post?.length) {
                    return post?.reduce((totalLikes, likes) => { return totalLikes += likes.likeCount !== undefined ? likes.likeCount : 0 }, 0)
                } else {
                    return 0; // Fill with 0 for days with no posts
                }
            }),
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            lineTension: 0.5,
            pointBorderColor: hoverLikeGraph ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverLikeGraph ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    const allNullLike = likeChartData.datasets[0].data.every(value => value === null);

    if (allNullLike) {
        likeChartData.datasets[0].data = [];
    }

    const commentChartData = {
        labels: linkedinContext.followerDateArray,
        datasets: [{
            label: 'Comments',
            data: linkedinContext.followerDateArray.map(date => {
                const post = linkedinContext.postDetails?.elements?.filter(data => {
                    const postDate = new Date(data.createdAt);
                    const monthShortName = postDate.toLocaleString('default', { month: 'short' });
                    const day = String(postDate.getDate()).padStart(2, '0');
                    return `${monthShortName}-${day}` === date;
                });
                if (post?.length) {
                    return post?.reduce((totalComments, comments) => { return totalComments += comments.commentCount !== undefined ? comments.commentCount : 0 }, 0)
                } else {
                    return 0; // Fill with 0 for days with no posts
                }
            }),
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            lineTension: 0.5,
            pointBorderColor: hoverCommentGraph ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverCommentGraph ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }

    const allNullComments = commentChartData.datasets[0].data.every(value => value === null);

    if (allNullComments) {
        commentChartData.datasets[0].data = [];
    }

    const engagementChartData = {
        labels: linkedinContext.followerDateArray,
        datasets: [{
            label: 'Engagement',
            data: linkedinContext.engagementData,
            fill: {
                target: "origin", // 3. Set the fill options
                above: "rgba(0,120,255,0.25)"
            },
            borderColor: '#0078FF',
            lineTension: 0.5,
            pointBorderColor: hoverEngagementRate ? 'rgba(0,120,255, 1)' : 'rgba(0,120,255, 0)',
            pointBackgroundColor: hoverEngagementRate ? 'rgba(0,120,255, 0.5)' : 'rgba(0,120,255, 0)',
            pointHoverBackgroundColor: '#ff43ca',
            pointHoverBorderColor: '#ff43ca'
        }]
    }


    useEffect(() => {


        if (linkedinContext.contentPageFilterLoading) {
            linkedinContext.getLinkedinOverviewPageAnalyticaData()
        }
        // eslint-disable-next-line 
    }, [linkedinContext.selectedPeriod])











    const calculateImpressionCount = () => {
        return linkedinContext?.organizationalEntityShareStatisticsFilter?.elements?.reduce((impressionCount, data) => {
            impressionCount += data.totalShareStatistics.impressionCount
            return impressionCount
        }, 0)
    }

    const calculateEngagementCount = () => {

        return isNaN(linkedinContext?.organizationalEntityShareStatisticsFilter?.elements?.reduce((engagementCount, data) => {
            engagementCount += parseFloat(data.totalShareStatistics.engagement)
            return engagementCount
        }, 0) / linkedinContext.postDetails?.paging?.total) ? 0 + "%" : linkedinContext?.organizationalEntityShareStatisticsFilter?.elements?.reduce((engagementCount, data) => {
            engagementCount += parseFloat(data.totalShareStatistics.engagement)
            return engagementCount
        }, 0) / linkedinContext.postDetails?.paging?.total
    }

    const captionTruncate = (str) => {
        return str?.length > 15 ? str?.substring(0, 12) + "..." : str;
    }

    const calculateTotalFollowers = () => {
        const totaleasymcqquestion = linkedinContext.OrganizationalEntityFollowerStatstics?.map(followers => followers.followerCounts)?.reduce((easymcqquestion, question) => {

            easymcqquestion += parseInt(question.organicFollowerCount + question.paidFollowerCount)

            return easymcqquestion
        }, 0)


        return totaleasymcqquestion
    }

    const pieChartDataAge = {
        // eslint-disable-next-line
        labels: [].concat(...linkedinContext.stackedDataContent.map(data => {
            const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
            // eslint-disable-next-line
            const commonElements = postId.map(item => {
                if (data.postId?.includes(item)) {
                    return data
                }
            }).filter(item => item !== undefined);
            return commonElements
        }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => data.contentCategory),
        datasets: [{
            label: ' Engagement',
            data: [].concat(...linkedinContext.stackedDataContent.map(data => {
                const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {

                const likeCount = linkedinContext?.postDetails?.elements.filter(postDetails => data.postId.includes(postDetails.id)).map(data => data?.likeCount)
                const commentCount = linkedinContext?.postDetails?.elements.filter(postDetails => data.postId.includes(postDetails.id)).map(data => data?.commentCount)
                return (isFinite(likeCount.reduce((totalLike, like) => { return totalLike += like }, 0)) ? (likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0) + commentCount.reduce((totalComment, comment) => { return totalComment += parseInt(comment) }, 0) / data.postId.length) : 0)
            }),
            fill: false,
            backgroundColor: [].concat(...linkedinContext.stackedDataContent.map(data => {
                const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                return generateDistinctColors(1)[0]
            }),
        }
        ]
    };

    const pieChartDataLike = {
        labels: [].concat(...linkedinContext.stackedDataContent.map(data => {
            const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
            // eslint-disable-next-line
            const commonElements = postId.map(item => {
                if (data.postId.includes(item)) {
                    return data
                }
            }).filter(item => item !== undefined);
            return commonElements
        }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => data.contentCategory),
        datasets: [{
            label: 'Engagement',
            data: [].concat(...linkedinContext.stackedDataContent.map(data => {
                const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                const likeCount = linkedinContext?.postDetails?.elements?.filter(postDetails => data.postId.includes(postDetails.id)).map(data => data?.likeCount)

                return (isFinite(likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)) ? (likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)) : 0)
            }),
            fill: false,
            backgroundColor: [].concat(...linkedinContext.stackedDataContent.map(data => {
                const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
                // eslint-disable-next-line
                const commonElements = postId.map(item => {
                    if (data.postId.includes(item)) {
                        return data
                    }
                }).filter(item => item !== undefined);
                return commonElements
            }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                return generateDistinctColors(1)[0]
            }),
        }
        ]
    };


    const socialIds = linkedinContext.stackedDataContent?.reduce((ids, item) => {
        if (!ids.includes(item.socialId)) {
            ids.push(item.socialId);
        }
        return ids;
    }, []);


    const categories = linkedinContext.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.reduce((uniqueCategories, item) => {
        if (!uniqueCategories.includes(item.contentCategory)) {
            uniqueCategories.push(item.contentCategory);
        }
        return uniqueCategories;
    }, []);

    const datasets = socialIds.map((socialId) => {
        const color = generateDistinctColors(1)[0]

        return {
            label: (userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name,
            data: categories.map(category => {

                return linkedinContext.stackedDataContent?.filter(item => item.socialId === socialId && item.contentCategory === category)?.length;
            }),
            backgroundColor: color,
        };
    });



    const dataDonghnut = {
        labels: linkedinContext.stackedDataContent?.filter(data => socialIds.includes(data.socialId))?.filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i)?.map(item => item.contentCategory),
        datasets: datasets,
    }

    function getTopCategoryCounts(data) {
        const categoryCounts = {};

        // Iterate over each array
        data.forEach(array => {
            // Iterate over each object in the array
            array.forEach(item => {
                // Get the socialId and contentCategory
                const socialId = item.socialId;
                const category = item.contentCategory;

                // If the socialId doesn't exist in categoryCounts, initialize it
                if (!categoryCounts[socialId]) {
                    categoryCounts[socialId] = {};
                }

                // Increment the count for the category under the socialId
                if (!categoryCounts[socialId][category]) {
                    categoryCounts[socialId][category] = 1;
                } else {
                    categoryCounts[socialId][category]++;
                }
            });
        });

        // Convert the object to an array of objects
        const result = Object.entries(categoryCounts).map(([socialId, categories]) => {
            // Find the category with the highest count
            const topCategory = Object.entries(categories).reduce((acc, [category, count]) => {
                return count > acc.count ? { category, count } : acc;
            }, { category: null, count: -1 });

            return { socialId, topCategory };
        });

        return result;
    }

    const prevPage = () => {
        if (page > 1) {
            setpage(prev => prev - 1)
        }
    }

    const nextPage = () => {
        if (page < Math.ceil(Math.ceil(linkedinContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? linkedinContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
            const postDetails = linkedinContext.postDetails.elements.filter(postDetails => data.postId.includes(postDetails.id))
            return postDetails.length
        })[0] / limit))) {
            setpage(prev => prev + 1)
        }
    }

    return (
        <div className={Styles.linkedin_visitors_page_container} >
            {(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? <ReauthorizeComponent /> : <></>}
            {showPostDetails ? <LinkedinPostDetails data={selectedPostDetails} onClickClose={() => setshowPostDetails(false)} /> : <></>}
            {linkedinContext.overviewPageLoading ? <LinkedinContentSkeleton /> :
                <div className={Styles.visitors_container} >

                    <div style={(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].isAccountTokensExpired ? { filter: 'blur(3px)' } : {}} className={Styles.overview_content_main_container} >
                        <ActiveSocialAccount page="linkedinpage" disableFilter={true} loading={linkedinContext.overviewPageLoading} selected={linkedinContext.selectedPeriod} onChangePeriod={(value) => { linkedinContext.setSelectedPeriod(value); userData.getSocialAccountPostCount(userData.accounts.filter(data => data.accountType === "linkedinpage" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0], value); linkedinContext.onChangeContentFilterLoading(true) }} />

                        <Navbar active="content" />
                        <div className={Styles.overview_content_container} >
                            {calculateTotalFollowers() < 100 ? <BlogListCard type="linkedin" /> : <></>}
                            <div className={Styles.card_container} >
                                <OverviewCard toolTip="Total Posts on LinkedIn refers to the total number of posts that a user has shared on the platform. This metric gives insight into the user's level of activity and engagement on LinkedIn, showcasing their willingness to share information, insights, and updates with their professional network." data={true} count={formatNumber(linkedinContext.postDetails?.paging?.total || 0)} percentage={0} header="Total Lifetime Posts " />
                                <OverviewCard toolTip="Reaction LinkedIn is a feature on the LinkedIn platform that allows users to engage with content by reacting to it with various emoticons such as like, celebrate, love, insightful, and curious." people={true} count={formatNumber(Math.max(...linkedinContext.impressionData).toFixed(0) || 0)} percentage="NA" header="Highest impressions" />
                                <OverviewCard toolTip="LinkedIn Impressions: The total number of times your content, such as posts, articles, or updates, has been viewed by other LinkedIn users." profile={true} count={formatNumber(calculateImpressionCount() || 0)} percentage={isFinite(linkedinContext.impressionPercentage) ? linkedinContext.impressionPercentage : 0} header="Total Impressions" />
                                <OverviewCard toolTip="Engagement Rate on LinkedIn measures the level of interaction and involvement your content receives from your audience. It's calculated by dividing the total number of engagements (such as likes, comments, shares, and clicks) by the total number of impressions, then multiplying by 100 to get a percentage." website={true} count={parseFloat(calculateEngagementCount()).toFixed(2) + "%"} percentage={linkedinContext.engagementPercentage || 0} header="Engagement Rate" />
                            </div>

                            <div className={Styles.page_views_and_unique_visitors_container} >
                                <LineChartCustomComponent onHoverOut={() => sethoverImpressions(false)} onHoverIn={() => sethoverImpressions(true)} type="line" header="Impressions" subheader="" contentArray={[{ "header": "Total Impression", value: formatNumber(linkedinContext.impressionData?.reduce((totalImpression, data) => { return totalImpression += data }, 0) || 0) }, { "header": "Highest Impression", value: formatNumber(Math.max(...linkedinContext.impressionData).toFixed(0) || 0) }, { "header": "Lowest Impression", value: formatNumber(Math.min(...linkedinContext.impressionData).toFixed(0) || 0) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverImpressions ? 5 : 5,
                                    scales: {

                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },

                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },

                                    },
                                }} chartData={impressionChartData} />

                                <LineChartCustomComponent onHoverOut={() => sethoverEngagementRate(false)} onHoverIn={() => sethoverEngagementRate(true)} type="line" header="Engagement Rate" subheader="" contentArray={[{ "header": "Average Engagement Rate", value: (isFinite((parseFloat(linkedinContext?.engagementData?.reduce((totalEngagement, data) => { return totalEngagement += parseFloat(data) }, 0)) / linkedinContext.engagementData.length).toFixed(2)) ? ((parseFloat(linkedinContext?.engagementData?.reduce((totalEngagement, data) => { return totalEngagement += parseFloat(data) }, 0)) / linkedinContext.engagementData.length).toFixed(2)) : 0) + "%" }, { "header": "Highest Engagement", value: formatNumber(Math.max(...linkedinContext.engagementData).toFixed(0) || 0) }, { "header": "Lowest Engagement", value: formatNumber(Math.min(...linkedinContext.engagementData).toFixed(0) || 0) }]} options={{
                                    maintainAspectRatio: false,
                                    interaction: {
                                        intersect: false,
                                        mode: "x"
                                    },
                                    radius: hoverEngagementRate ? 5 : 5,
                                    scales: {

                                        x: {
                                            type: 'category',
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: false
                                            }
                                        },

                                    },
                                    plugins: {
                                        legend: {
                                            display: false, // Hide dataset label
                                        },

                                    },
                                }} chartData={engagementChartData} />


                            </div>

                            <div className={Styles.page_views_and_unique_visitors_container} >
                                <LineChartCustomComponent noOfPost={linkedinContext.postDetails?.elements?.length} selectedPeriod={
                                    // eslint-disable-next-line 
                                    linkedinContext.selectedPeriod == 31 ? 30 : linkedinContext.selectedPeriod} onHoverOut={() => sethoverLikeGraph(false)} onHoverIn={() => sethoverLikeGraph(true)} type="line" header="Like Graph" subheader="" contentArray={[{ "header": "Total Likes", type: 'solid', indicator: '#0078FF', value: formatNumber(linkedinContext.postDetails?.elements?.reduce((totalComments, data) => { return totalComments += data.likeCount !== undefined ? data.likeCount : 0 }, 0) || 0) }]} options={{
                                        maintainAspectRatio: false,
                                        interaction: {
                                            intersect: false,
                                            mode: "x"
                                        },
                                        radius: hoverLikeGraph ? 5 : 5,
                                        scales: {

                                            x: {
                                                type: 'category',
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                grid: {
                                                    display: false
                                                }
                                            },

                                        },
                                        plugins: {
                                            legend: {
                                                display: false, // Hide dataset label
                                            },

                                        },
                                    }} chartData={likeChartData} />

                                <LineChartCustomComponent noOfPost={linkedinContext.postDetails?.elements?.length} selectedPeriod={
                                    // eslint-disable-next-line 
                                    linkedinContext.selectedPeriod == 31 ? 30 : linkedinContext.selectedPeriod} onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(true)} type="line" header="Comment Graph" subheader="" contentArray={[{ "header": "Total Comments", type: 'solid', indicator: '#0078FF', value: formatNumber(linkedinContext.postDetails?.elements?.reduce((totalComments, data) => { return totalComments += data.commentCount !== undefined ? data.commentCount : 0 }, 0) || 0) }]} options={{
                                        maintainAspectRatio: false,
                                        interaction: {
                                            intersect: false,
                                            mode: "x"
                                        },
                                        radius: hoverCommentGraph ? 5 : 5,
                                        scales: {

                                            x: {
                                                type: 'category',
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                grid: {
                                                    display: false
                                                }
                                            },

                                        },
                                        plugins: {
                                            legend: {
                                                display: false, // Hide dataset label
                                            },

                                        },
                                    }} chartData={commentChartData} />


                            </div>





                            <div className={Styles.post_main_container} >
                                <div className={Styles.header_container} >
                                    <span>Latest Post Activity</span>
                                    <div className={Styles.button_container} >
                                        {linkedinContext.stopPagination ? <></> : <button onClick={() => linkedinContext.getLinkedinContentPageAnalyticaData()} >Load more</button>}
                                    </div>
                                </div>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ? <>

                                    {linkedinContext?.postDetails?.elements?.length ?
                                        <>
                                            <div className={Styles.post_container} >
                                                <div className={Styles.header_container} >
                                                    <span>Post</span>
                                                    <div className={Styles.postHeaderContainer} >
                                                        <span>Created At</span>
                                                        <span>Type</span>

                                                        <span>Impressions</span>

                                                        <span>Clicks</span>

                                                        <span>Likes</span>
                                                        <span>Comments</span>
                                                        <span>Shares</span>
                                                        <span>Eng Rate</span>
                                                        <span>View</span>
                                                    </div>

                                                </div>
                                                <div className={Styles.border} ></div>
                                                {linkedinContext.postDetails?.elements?.map((data) => {

                                                    return (
                                                        <>
                                                            <div style={{ cursor: 'pointer' }} onClick={() => { setshowPostDetails(true); setselectedPostDetails(data) }} className={Styles.post_item_container} >
                                                                <div className={Styles.post_content_container} >
                                                                    <div className={Styles.post_content_img} >
                                                                        {data?.content?.media?.id?.includes('video') ?
                                                                            <img src={data?.content?.media?.mediaUrl} alt="" /> : <></>
                                                                        }
                                                                        {data?.content?.media?.id?.includes('image') ? <img alt="" src={data?.content?.media?.mediaUrl} /> : <></>
                                                                        }
                                                                        {data?.content?.media?.id?.includes('document') ? <object data={data?.content?.media?.mediaUrl} type="application/pdf" width="100%" height="100%">
                                                                            <p>error to load <a href={data?.content?.media?.mediaUrl}>to the PDF!</a></p>
                                                                        </object> : <></>
                                                                        }
                                                                    </div>

                                                                    <div className={Styles.post_content} >
                                                                        <span>{captionTruncate(data.commentary || "")}</span>
                                                                        <p>by {data.authorName || "-"}</p>
                                                                    </div>
                                                                </div>
                                                                <div className={Styles.postOtherDetails} >
                                                                    <div>{new Date(data.createdAt).getFullYear() + "-" + (new Date(data.createdAt).getMonth() + 1) + "-" + new Date(data.createdAt).getDate()} {new Date(data?.createdAt).toLocaleTimeString('en-US')}</div>
                                                                    <div>{data?.content?.media !== undefined ? data?.content?.media?.id?.includes('document') ? 'Document' : data?.content?.media?.id?.includes('image') ? 'Image' : data?.content?.media?.id?.includes('video') ? "Video" : null : "Text"}</div>

                                                                    <div>{formatNumber(data.impressionCount || 0)}</div>

                                                                    <div>{formatNumber(data.clickCount || 0)}</div>

                                                                    <div>{formatNumber(data.likeCount || 0)}</div>
                                                                    <div>{formatNumber(data.commentCount || 0)}</div>
                                                                    <div>{formatNumber(data.shareCount || 0)}</div>

                                                                    <div>{isFinite((parseFloat(data.engagement).toFixed(2))) ? (parseFloat(data.engagement).toFixed(2)) : 0}%</div>
                                                                    <div style={{ cursor: 'pointer' }} ><svg onClick={(e) => { e.stopPropagation(); window.open(`https://www.linkedin.com/feed/update/${data.id}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clipPath="url(#clip0_2171_2043)">
                                                                            <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_2171_2043">
                                                                                <rect width="22" height="22" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <section className={Styles.linkedin_border_of_post} ></section>
                                                        </>
                                                    )
                                                })

                                                }

                                                {linkedinContext.contentPageLoading === true ?
                                                    <>
                                                        <LinkedinPostSkeleton />
                                                    </> : <></>}





                                            </div>
                                        </> :

                                        <NoDataAvailablePopup noOfPost={linkedinContext.postDetails?.elements?.length} selectedPeriod={
                                            // eslint-disable-next-line 
                                            linkedinContext.selectedPeriod == 31 ? 30 : linkedinContext.selectedPeriod} cardName="postlist" />

                                    }
                                </> : <PremiumPopup cardName="postlist" />

                                }



                            </div>

                            <>
                                {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                    <div className={Styles.content_category_container} >
                                        <h4>Category</h4>
                                        {linkedinContext.postDetails?.elements?.length ?
                                            <>

                                                <div className={Styles.header_container} >
                                                    <span>category</span>
                                                    <span>likes</span>
                                                    <span>comments</span>
                                                </div>

                                                {[].concat(...linkedinContext.stackedDataContent.map(data => {
                                                    const postId = linkedinContext.postDetails?.elements?.map(postData => postData.id)
                                                    // eslint-disable-next-line
                                                    const commonElements = postId.map(item => {
                                                        if (data?.postId?.includes(item)) {
                                                            return data
                                                        }
                                                    }).filter(item => item !== undefined);
                                                    return commonElements
                                                }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map(data => {
                                                    const likeCount = linkedinContext.postDetails?.elements.filter(postDetails => data.postId?.includes(postDetails.id)).map(data => data?.likeCount || 0)
                                                    const commentCount = linkedinContext.postDetails?.elements.filter(postDetails => data.postId?.includes(postDetails.id)).map(data => data?.commentCount || 0)

                                                    return (
                                                        <div className={Styles.category_content} >
                                                            <span>{data.contentCategory}</span>
                                                            <span>{likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)}</span>
                                                            <span>{commentCount.reduce((totalComment, comments) => { return totalComment += comments }, 0)}</span>
                                                        </div>
                                                    )
                                                })

                                                }

                                            </> :

                                            <NoDataAvailablePopup noOfPost={linkedinContext.postDetails?.elements?.length} selectedPeriod={
                                                // eslint-fae-next-line
                                                linkedinContext.selectedPeriod == 31 ? 30 : linkedinContext.selectedPeriod} cardName="postlist" />


                                        }
                                    </div> : <PremiumPopup cardName="postlist" />

                                }

                            </>

                            {userData.userData.workspace.filter(workspace => { let workspaceId = localStorage.getItem('organizationId'); return workspace._id === workspaceId })[0].planData.planId.planName !== "free" ?
                                <div className={Styles.competitor_category_content_container} >
                                    <LineChartCustomComponent componentName="category" style={{ width: '48%' }} header={`Category concentric graph`} contentArray={
                                        // const name = instagramContext.competitors.find(competitor => competitor.id === data.socialId).name
                                        getTopCategoryCounts([linkedinContext.stackedDataContent]).map((data, index) => {
                                            return {
                                                "header": `${(userData.accounts).filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0].name} ( ${data.topCategory.category} )`, indicator: dataDonghnut?.datasets[index]?.backgroundColor, type: 'solid', "value": data.topCategory.count
                                            }
                                        })

                                    } onHoverOut={() => sethoverCommentGraph(false)} onHoverIn={() => sethoverCommentGraph(false)} type="pie" options={{
                                        maintainAspectRatio: false,
                                        onClick: function (e, i) {
                                            setpage(1)
                                            setselectedCategory(dataDonghnut.labels[i[0].index])
                                        },
                                        scales: {
                                            x: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                            y: {
                                                display: false,
                                                grid: {
                                                    display: false
                                                }
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                                position: 'nearest',
                                                // Additional tooltip configuration options
                                            },
                                        }
                                    }} chartData={dataDonghnut} />

                                    {linkedinContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? linkedinContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                        const postDetails = linkedinContext.postDetails.elements.filter(postDetails => data.postId.includes(postDetails.id))
                                        return postDetails.length
                                    })[0] > 0 ?
                                        <div className={Styles.category_content_container} >
                                            <div>
                                                <label>{(userData.accounts)?.filter(accountData => accountData.name === userNavigation.activeSocialMediaName)[0]?.name} - {selectedCategory === '' ? linkedinContext?.stackedDataContent[0]?.contentCategory : selectedCategory} Post</label>
                                                <div className={Styles.single_content_container} >
                                                    <span>Post</span>
                                                    <span>Visit</span>
                                                </div>

                                                {linkedinContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? linkedinContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {

                                                    const postDetails = linkedinContext.postDetails.elements.filter(postDetails => data.postId.includes(postDetails.id))

                                                    const renderPost = postDetails.slice((page - 1) * 5, (page - 1) * 5 + 5).map((postData, index) => {
                                                        return (
                                                            <div className={Styles.single_content_container} >
                                                                <div className={Styles.postDetailsContainer} >
                                                                    <p className={Styles.index_header} >{index + 1}</p>
                                                                    {postData?.content?.media?.id?.includes('video') ?
                                                                        <img src={postData?.content?.media?.mediaUrl} alt="" /> : <></>
                                                                    }
                                                                    {postData?.content?.media?.id?.includes('image') ? <img alt="" src={postData?.content?.media?.mediaUrl} /> : <></>
                                                                    }
                                                                    {postData?.content?.media?.id?.includes('document') ? <object data={postData?.content?.media?.mediaUrl} type="application/pdf" width="100%" height="100%">
                                                                        <p>error to load <a href={postData?.content?.media?.mediaUrl}>to the PDF!</a></p>
                                                                    </object> : <></>
                                                                    }
                                                                    <div className={Styles.post_content_container} >
                                                                        <h5>{captionTruncate(postData?.commentary === undefined ? "-" : postData?.commentary, 15)}</h5>
                                                                        <p>{postData.likeCount} Likes {postData.commentCount} Comments</p>
                                                                    </div>
                                                                </div>
                                                                <svg style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.open(`${postData.permalink}`, '_blank') }} width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_2171_2043)">
                                                                        <path d="M0.916016 11.0003C0.916016 11.0003 4.58268 3.66699 10.9993 3.66699C17.416 3.66699 21.0827 11.0003 21.0827 11.0003C21.0827 11.0003 17.416 18.3337 10.9993 18.3337C4.58268 18.3337 0.916016 11.0003 0.916016 11.0003Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_2171_2043">
                                                                            <rect width="22" height="22" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                        )
                                                    })
                                                    return renderPost
                                                })
                                                }

                                            </div>
                                            <div className={Styles.post_pagination_container} >
                                                <svg onClick={prevPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.375 3.75L5.625 7.5L9.375 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Showing {page}-{Math.ceil(linkedinContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? linkedinContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                                    const postDetails = linkedinContext.postDetails.elements.filter(postDetails => data.postId.includes(postDetails.id))
                                                    return postDetails.length
                                                })[0] / limit)} posts</span>
                                                <svg onClick={nextPage} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.625 3.75L9.375 7.5L5.625 11.25" stroke="#C4C4C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </div>

                                        </div> : <NoDataAvailablePopup noOfPost={linkedinContext.stackedDataContent.filter(data => data.contentCategory === (selectedCategory === '' ? linkedinContext?.stackedDataContent[0]?.contentCategory : selectedCategory)).map(data => {
                                            const postDetails = linkedinContext.postDetails.elements.filter(postDetails => data.postId.includes(postDetails.id))
                                            return postDetails.length
                                        })[0]} selectedPeriod={
                                            // eslint-fae-next-line
                                            linkedinContext.selectedPeriod == 31 ? 30 : linkedinContext.selectedPeriod} cardName="postlist" />
                                    }

                                </div> : <PremiumPopup cardName="postlist" />
                            }


                            <div className={Styles.category_pie_chart_container} >
                                <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Category Like" contentArray={[].concat(...linkedinContext.stackedDataContent.map(data => {
                                    const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
                                    // eslint-disable-next-line
                                    const commonElements = postId.map(item => {
                                        if (data.postId?.includes(item)) {
                                            return data
                                        }
                                    }).filter(item => item !== undefined);
                                    return commonElements
                                }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map((data, index) => {

                                    const likeCount = linkedinContext?.postDetails?.elements?.filter(postDetails => data.postId?.includes(postDetails.id))?.map(data => data?.likeCount || 0)

                                    return { "header": data.contentCategory, type: "solid", indicator: pieChartDataLike.datasets[0].backgroundColor[index], value: `${(isFinite(likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)) ? ((likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0))) : 0)}` }
                                })} options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            display: false,
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            display: false,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={pieChartDataLike} />

                                <LineChartCustomComponent onHoverOut={() => null} onHoverIn={() => null} type="pie" header="Category Engagement Rate" contentArray={[].concat(...linkedinContext.stackedDataContent.map(data => {
                                    const postId = linkedinContext?.postDetails?.elements?.map(postData => postData.id)
                                    // eslint-disable-next-line
                                    const commonElements = postId.map(item => {
                                        if (data.postId?.includes(item)) {
                                            return data
                                        }
                                    }).filter(item => item !== undefined);
                                    return commonElements
                                }).filter(arr => arr.length > 0)).filter((v, i, a) => a.findIndex(v2 => (v2.contentCategory === v.contentCategory)) === i).map((data, index) => {
                                    const likeCount = linkedinContext?.postDetails?.elements.filter(postDetails => data.postId?.includes(postDetails.id)).map(data => data?.likeCount || 0)
                                    const commentCount = linkedinContext?.postDetails?.elements.filter(postDetails => data.postId?.includes(postDetails.id)).map(data => data?.commentCount || 0)
                                    return { "header": data.contentCategory, type: "solid", indicator: pieChartDataAge.datasets[0].backgroundColor[index], value: `${(isFinite(likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0)) ? (((likeCount.reduce((totalLike, like) => { return totalLike += parseInt(like) }, 0) + commentCount.reduce((totalComment, comment) => { return totalComment += parseInt(comment) }, 0)))) : 0)}` }
                                })} options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            display: false,
                                            grid: {
                                                display: false
                                            }
                                        },
                                        y: {
                                            display: false,
                                            grid: {
                                                display: false
                                            }
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: { // Note the change here from 'tooltips' to 'tooltip'
                                            position: 'nearest',
                                            // Additional tooltip configuration options
                                        },
                                    }
                                }} chartData={pieChartDataAge} />
                            </div>


                        </div>
                    </div>



                </div>
            }
        </div>
    )
}

export default LinkedinContent