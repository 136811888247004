export const seniorities = [
    {
        "$URN": "urn:li:seniority:1",
        "name":  "Unpaid"
            
    },
    {
        "$URN": "urn:li:seniority:2",
        "name":  "Training"
            
    },
    {
        "$URN": "urn:li:seniority:3",
        "name":  "Entry"
            
    },
    {
        "$URN": "urn:li:seniority:4",
        "name":  "Senior"
            
    },
    {
        "$URN": "urn:li:seniority:5",
        "name":  "Manager"
            
    },
    {
        "$URN": "urn:li:seniority:6",
        "name":  "Director"
            
    },
    {
        "$URN": "urn:li:seniority:7",
        "name":  "VP"
            
    },
    {
        "$URN": "urn:li:seniority:8",
        "name":  "CXO"
            
    },
    {
        "$URN": "urn:li:seniority:9",
        "name":  "Partner"
            
    },
    {
        "$URN": "urn:li:seniority:10",
        "name":  "Owner"
            
    }
]