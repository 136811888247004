import { createContext, useContext, useState } from 'react'
import APICall from '../services/api.service'
import { UserConnectedAccountContext } from './UserConnectedAccount'
import variables from '../Util/variables'
import { toast } from 'react-toastify'
import { UserNavigationContext } from './NavigationProvider'
import { CommonSocialMediaContext } from './CommonSocialMediaStore'
import axios from 'axios'
import { url } from '../Util/constant'

export const CreatePinterestAnalyticContext = createContext({
    selectedPeriod: '',
    loading: '',
    accountInfo: null,
    allPins: null,
    impressions: {},
    totalStats: {},
    engagement: {},
    outboundClicks: {},
    engagementRate: {},
    pinClick: {},
    save: {},
    clickThrough: {},
    paginationLoading: false,
    topPins: [],
    topPinsLoading: false,
    topVideoPins: [],
    topVideoPinsLoading: false,
    boards: [],
    boardsLoading: false,
    socialId: '',
    filterLoading: '',
    dailyAnalytics: [],


    getPinterestAnalyticsData: () => { },
    setloading: () => { },
    handleSelectedPeriod: () => { },
    loadMorePins: () => { },
    getTopPins: () => { },
    getTopVideoPins: () => { },
    loadMoreBoards: () => { },
    changeLoadingState: () => { },
    getPinterestAnalyticsDataFilter: () => { },
    handleFilterLoading: () => { },
    getPinterestOverviewPageAnalyticaData: () => { },
    getPinterestContentPageAnalyticaData: () => { },
    overviewPageLoading: true,
    contentPageLoading: true,
    onChangeoverviewPageFilterLoading: () => { },
    onChangecontentPageFilterLoading: () => { },
    overviewPageFilterLoading: false,
    contentPageFilterLoading: false,
    reportWeekArray: [],
    selectedReportDate: '',
    onChangesetselectedReportDate: () => { },
    stackedDataContent: [],
    clearState:()=>{ }
})

export const CreatePinterestAnalyticProvider = ({ children }) => {
    const userContext = useContext(UserConnectedAccountContext)
    const userNavigation = useContext(UserNavigationContext)
    const commonSocialMediaContext = useContext(CommonSocialMediaContext)
    const [loading, setLoading] = useState(true)
    const [selectedPeriod, setSelectedPeriod] = useState('31')
    const [accountInfo, setAccountInfo] = useState(null)
    const [allPins, setAllPins] = useState([])
    const [impressions, setImpressions] = useState({
        date: [],
        points: []
    })
    const [totalStats, setTotalStats] = useState({})
    const [engagement, setEngagement] = useState({
        date: [],
        points: []
    })
    const [outboundClicks, setOutboundClicks] = useState({
        date: [],
        points: []
    })
    const [engagementRate, setEngagementRate] = useState({
        date: [],
        points: []
    })
    const [pinClick, setPinClick] = useState({
        date: [],
        points: []
    })
    const [save, setSave] = useState({
        date: [],
        points: []
    })
    const [clickThrough, setClickThrough] = useState({
        date: [],
        points: []
    })
    const [bookmark, setBookmark] = useState("")
    const [paginationLoading, setPaginationLoading] = useState(false)
    const [topPins, setTopPins] = useState([])
    const [topPinsLoading, setTopPinsLoading] = useState(false)
    const [topVideoPins, setTopVideoPins] = useState([])
    const [topVideoPinsLoading, setTopVideoPinsLoading] = useState(false)
    const [boards, setBoards] = useState([])
    const [boardsLoading, setBoardsLoading] = useState(false)
    const [boardBookmark, setBoardBookmark] = useState("")
    const [overviewPageLoading, setoverviewPageLoading] = useState(true)
    const [contentPageLoading, setcontentPageLoading] = useState(true)

    const [filterLoading, setfilterLoading] = useState(false)
    const [overviewPageFilterLoading, setoverviewPageFilterLoading] = useState(false)
    const [contentPageFilterLoading, setcontentPageFilterLoading] = useState(false)
    const [reportWeekArray, setreportWeekArray] = useState([])
    const [selectedReportDate, setselectedReportDate] = useState('')
    const [dailyAnalytics, setdailyAnalytics] = useState([])
    const [stackedDataContent, setstackedDataContent] = useState([])

    const clearState=()=>{
        setstackedDataContent([])
        setdailyAnalytics([])
        setreportWeekArray([])
        setTopVideoPins([])
        setBoards([])
        setTopVideoPins([])
        setTopPins([])
        setBookmark("")
        setClickThrough({
            date: [],
            points: [] 
        })
        setSave({
            date: [],
            points: [] 
        })
        setPinClick({
            date: [],
            points: [] 
        })
        setEngagementRate({
            date: [],
            points: [] 
        })
        setOutboundClicks({
            date:[],
            points:[]
        })
        setEngagement({
            date:[],
            points:[]
        })
        setTotalStats({})
        setImpressions({})
        setAllPins([])
        setAccountInfo(null)

    }

    const onChangeoverviewPageFilterLoading = (value) => {
        setoverviewPageFilterLoading(value)
    }

    const onChangecontentPageFilterLoading = (value) => {
        setcontentPageFilterLoading(value)
    }

    // NOTE: Calculate date for api 
    const calculateDate = () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        const startDate = new Date(currentDate);

        if (selectedPeriod === '31') {
            startDate.setDate(currentDate.getDate() - 30);
        } else if (selectedPeriod === '60') {
            startDate.setDate(currentDate.getDate() - 59);
        } else if (selectedPeriod === '90') {
            startDate.setDate(currentDate.getDate() - 89);
        } else {
            throw new Error('Invalid time period provided. Please use "30days", "60days", or "90days".');
        }

        // Format the start and end dates in 'YYYY-MM-DD' format
        const startDateFormatted = startDate.toISOString().slice(0, 10);
        const endDateFormatted = endDate.toISOString().slice(0, 10);

        const reportWeeks = [];

        currentDate.setDate(currentDate.getDate() - selectedPeriod); // Minus selectedPeriod from currentDate

        for (let i = 0; i < (selectedPeriod === '31' ? 30 : (selectedPeriod === '60' ? 59 : 89)); i += 7) { // Increment by 7 to represent weeks
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + i); // Adjust the increment as needed

            const weekStartDate = new Date(newDate);
            weekStartDate.setDate(newDate.getDate() - 6); // Get the start date of the week

            reportWeeks.push(newDate);
        }

        setselectedReportDate(reportWeeks[reportWeeks?.length - 1]); // Set selected report date to the first week

        return { startDate: startDateFormatted, endDate: endDateFormatted, reportWeeks: reportWeeks };
    }

    const changeLoadingState = (value) => {
        setLoading(value)
    }

    // NOTE: Format date to show in graph
    const formatDate = (inputDate) => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const dateParts = inputDate.split("-");
        const month = months[parseInt(dateParts[1], 10) - 1];
        const day = dateParts[2];

        const formattedDate = `${month}-${day}`;
        return formattedDate;
    }


    const handleFilterLoading = (value) => {
        setfilterLoading(value)
    }

    // NOTE: Get initial pinterest analytics
    const getPinterestAnalyticsData = async () => {
        const pinterestToken = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
        const pinterestID = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]
        const isAccountExits = userContext.accountsData.filter(data => data?.id === pinterestID && data.selectedPeriod === selectedPeriod)

        if (isAccountExits?.length) {
            setBoards(isAccountExits[0]?.boards)
            setBoardBookmark(isAccountExits[0]?.boardBookmark)
            setAllPins(isAccountExits[0]?.allPins)
            setBookmark(isAccountExits[0]?.bookmark)
            setTopPins(isAccountExits[0]?.topPins)
            setTopVideoPins(isAccountExits[0]?.topVideoPins)
            setTotalStats(isAccountExits[0]?.totalStats)
            setImpressions(isAccountExits[0]?.impressions)
            setEngagement(isAccountExits[0]?.engagement)
            setOutboundClicks(isAccountExits[0]?.outboundClicks)
            setPinClick(isAccountExits[0]?.pinClick)
            setEngagementRate(isAccountExits[0]?.engagementRate)
            setSave(isAccountExits[0]?.save)
            setClickThrough(isAccountExits[0]?.clickThrough)
            setAccountInfo(isAccountExits[0]?.accountInfo)
            setLoading(false)
        } else {
            const accountInfoResponse = await APICall('https://api.cache.creatosaurus.io/cache/analytics/pinterest/account/info', {}, 1, true, pinterestToken)



            setLoading(true)

            const userInfoResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/user/${calculateDate().startDate}/${calculateDate().endDate}`, {}, 1, true, pinterestToken)
            const pinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins`, {}, 1, true, pinterestToken)
            const topPinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/top_pins?start_date=${calculateDate().startDate}&end_date=${calculateDate().endDate}&sort_by=ENGAGEMENT`, {}, 1, true, pinterestToken)
            const topVideoPinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/top_video_pins?start_date=${calculateDate().startDate}&end_date=${calculateDate().endDate}&sort_by=IMPRESSION`, {}, 1, true, pinterestToken)
            const boardsResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/boards`, {}, 1, true, pinterestToken)
            setBoards(boardsResponse.data.items)
            setBoardBookmark(boardsResponse.data.bookmark)
            setAllPins(pinResponse.data.items)
            setBookmark(pinResponse.data.bookmark)

            let topPinArray = []
            let topVideoPinArray = []

            topPinResponse.data.pins.map(async (data) => {
                const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins/${data.pin_id}`, {}, 1, true, pinterestToken)
                let pin = {
                    ...response.data,
                    pin_metrics: data.metrics
                }
                topPinArray.push(pin)
            })

            topVideoPinResponse.data.pins.map(async (data) => {
                const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins/${data.pin_id}`, {}, 1, true, pinterestToken)
                let pin = {
                    ...response.data,
                    pin_metrics: data.metrics
                }
                topVideoPinArray.push(pin)
            })
            if (variables.leftSideBarState === pinterestID) {
                setTopPins(topPinArray)
                setTopVideoPins(topVideoPinArray)
                let impressionArray = []
                let dateArray = []
                let engagementArray = []
                let outboundArray = []
                let engagementRateArray = []
                let pinClickArray = []
                let saveArray = []
                let clickThroughArray = []

                userInfoResponse.data.all.daily_metrics.forEach((data) => {
                    if (data.data_status !== "PROCESSING") {
                        impressionArray.push(data.metrics.IMPRESSION)
                        engagementArray.push(data.metrics.ENGAGEMENT)
                        outboundArray.push(data.metrics.OUTBOUND_CLICK)
                        engagementRateArray.push(data.metrics.ENGAGEMENT_RATE)
                        pinClickArray.push(data.metrics.PIN_CLICK)
                        saveArray.push(data.metrics.SAVE)
                        clickThroughArray.push(data.metrics.CLICKTHROUGH)
                        dateArray.push(formatDate(data.date))
                    }

                })



                setTotalStats(userInfoResponse.data.all.summary_metrics)

                setImpressions({
                    date: dateArray,
                    points: impressionArray
                })

                setEngagement({
                    date: dateArray,
                    points: engagementArray
                })

                setOutboundClicks({
                    date: dateArray,
                    points: outboundArray
                })

                setPinClick({
                    date: dateArray,
                    points: pinClickArray
                })

                setEngagementRate({
                    date: dateArray,
                    points: engagementRateArray
                })

                setSave({
                    date: dateArray,
                    points: saveArray
                })

                setClickThrough({
                    date: dateArray,
                    points: clickThroughArray
                })

                const data = {
                    monthly_views: accountInfoResponse.data.monthly_views,
                    follower_count: accountInfoResponse.data.follower_count,
                    pin_click_rate: userInfoResponse.data.all.summary_metrics.PIN_CLICK_RATE,
                    save_rate: userInfoResponse.data.all.summary_metrics.SAVE_RATE
                }
                setAccountInfo(data)
                setLoading(false)

                const obj = {
                    selectedPeriod: selectedPeriod,
                    id: accountInfoResponse.data.id,
                    boards: boardsResponse.data.items,
                    boardBookmark: boardsResponse.data.bookmark,
                    allPins: pinResponse.data.items,
                    bookmark: pinResponse.data.bookmark,
                    topPins: topPinArray,
                    topVideoPins: topVideoPinArray,
                    totalStats: userInfoResponse.data.all.summary_metrics,
                    impressions: {
                        date: dateArray,
                        points: impressionArray
                    },
                    engagement: {
                        date: dateArray,
                        points: engagementArray
                    },
                    outboundClicks: {
                        date: dateArray,
                        points: outboundArray
                    },
                    pinClick: {
                        date: dateArray,
                        points: pinClickArray
                    },
                    engagementRate: {
                        date: dateArray,
                        points: engagementRateArray
                    },
                    save: {
                        date: dateArray,
                        points: saveArray
                    },
                    clickThrough: {
                        date: dateArray,
                        points: clickThroughArray
                    },
                    accountInfo: {
                        monthly_views: accountInfoResponse.data.monthly_views,
                        follower_count: accountInfoResponse.data.follower_count,
                        pin_click_rate: userInfoResponse.data.all.summary_metrics.PIN_CLICK_RATE,
                        save_rate: userInfoResponse.data.all.summary_metrics.SAVE_RATE
                    },
                    socialId: pinterestID
                }



                userContext.handleaccountsData(obj)


            } else {


                let impressionArray = []
                let dateArray = []
                let engagementArray = []
                let outboundArray = []
                let engagementRateArray = []
                let pinClickArray = []
                let saveArray = []
                let clickThroughArray = []

                userInfoResponse.data.all.daily_metrics.forEach((data) => {
                    if (data.data_status !== "PROCESSING") {
                        impressionArray.push(data.metrics.IMPRESSION)
                        engagementArray.push(data.metrics.ENGAGEMENT)
                        outboundArray.push(data.metrics.OUTBOUND_CLICK)
                        engagementRateArray.push(data.metrics.ENGAGEMENT_RATE)
                        pinClickArray.push(data.metrics.PIN_CLICK)
                        saveArray.push(data.metrics.SAVE)
                        clickThroughArray.push(data.metrics.CLICKTHROUGH)
                        dateArray.push(formatDate(data.date))
                    }

                })








                const obj = {
                    selectedPeriod: selectedPeriod,
                    id: pinterestID,
                    boards: boardsResponse.data.items,
                    boardBookmark: boardsResponse.data.bookmark,
                    allPins: pinResponse.data.items,
                    bookmark: pinResponse.data.bookmark,
                    topPins: topPinArray,
                    topVideoPins: topVideoPinArray,
                    totalStats: userInfoResponse.data.all.summary_metrics,
                    impressions: {
                        date: dateArray,
                        points: impressionArray
                    },
                    engagement: {
                        date: dateArray,
                        points: engagementArray
                    },
                    outboundClicks: {
                        date: dateArray,
                        points: outboundArray
                    },
                    pinClick: {
                        date: dateArray,
                        points: pinClickArray
                    },
                    engagementRate: {
                        date: dateArray,
                        points: engagementRateArray
                    },
                    save: {
                        date: dateArray,
                        points: saveArray
                    },
                    clickThrough: {
                        date: dateArray,
                        points: clickThroughArray
                    },
                    accountInfo: {
                        monthly_views: accountInfoResponse.data.monthly_views,
                        follower_count: accountInfoResponse.data.follower_count,
                        pin_click_rate: userInfoResponse.data.all.summary_metrics.PIN_CLICK_RATE,
                        save_rate: userInfoResponse.data.all.summary_metrics.SAVE_RATE
                    },
                    socialId: pinterestID
                }



                userContext.handleaccountsData(obj)


            }


        }
    }

    const getPinterestAnalyticsDataFilter = async () => {

        const pinterestToken = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]

        const pinterestID = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]
        const isAccountExits = userContext.accountsData.filter(data => data?.id === pinterestID && data.selectedPeriod === selectedPeriod)

        if (isAccountExits?.length) {
            setBoards(isAccountExits[0]?.boards)
            setBoardBookmark(isAccountExits[0]?.boardBookmark)
            setAllPins(isAccountExits[0]?.allPins)
            setBookmark(isAccountExits[0]?.bookmark)
            setTopPins(isAccountExits[0]?.topPins)
            setTopVideoPins(isAccountExits[0]?.topVideoPins)
            setTotalStats(isAccountExits[0]?.totalStats)
            setImpressions(isAccountExits[0]?.impressions)
            setEngagement(isAccountExits[0]?.engagement)
            setOutboundClicks(isAccountExits[0]?.outboundClicks)
            setPinClick(isAccountExits[0]?.pinClick)
            setEngagementRate(isAccountExits[0]?.engagementRate)
            setSave(isAccountExits[0]?.save)
            setClickThrough(isAccountExits[0]?.clickThrough)
            setAccountInfo(isAccountExits[0]?.accountInfo)
            setLoading(false)
        } else {
            const accountInfoResponse = await APICall('https://api.cache.creatosaurus.io/cache/analytics/pinterest/account/info', {}, 1, true, pinterestToken)



            setLoading(true)

            const userInfoResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/user/${calculateDate().startDate}/${calculateDate().endDate}`, {}, 1, true, pinterestToken)
            const pinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins`, {}, 1, true, pinterestToken)
            const topPinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/top_pins?start_date=${calculateDate().startDate}&end_date=${calculateDate().endDate}&sort_by=ENGAGEMENT`, {}, 1, true, pinterestToken)
            const topVideoPinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/top_video_pins?start_date=${calculateDate().startDate}&end_date=${calculateDate().endDate}&sort_by=IMPRESSION`, {}, 1, true, pinterestToken)
            const boardsResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/boards`, {}, 1, true, pinterestToken)
            setBoards(boardsResponse.data.items)
            setBoardBookmark(boardsResponse.data.bookmark)
            setAllPins(pinResponse.data.items)
            setBookmark(pinResponse.data.bookmark)

            let topPinArray = []
            let topVideoPinArray = []

            topPinResponse.data.pins.map(async (data) => {
                const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins/${data.pin_id}`, {}, 1, true, pinterestToken)
                let pin = {
                    ...response.data,
                    pin_metrics: data.metrics
                }
                topPinArray.push(pin)
            })

            topVideoPinResponse.data.pins.map(async (data) => {
                const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins/${data.pin_id}`, {}, 1, true, pinterestToken)
                let pin = {
                    ...response.data,
                    pin_metrics: data.metrics
                }
                topVideoPinArray.push(pin)
            })



            setTopPins(topPinArray)
            setTopVideoPins(topVideoPinArray)
            let impressionArray = []
            let dateArray = []
            let engagementArray = []
            let outboundArray = []
            let engagementRateArray = []
            let pinClickArray = []
            let saveArray = []
            let clickThroughArray = []

            userInfoResponse.data.all.daily_metrics.forEach((data) => {
                if (data.data_status !== "PROCESSING") {
                    impressionArray.push(data.metrics.IMPRESSION)
                    engagementArray.push(data.metrics.ENGAGEMENT)
                    outboundArray.push(data.metrics.OUTBOUND_CLICK)
                    engagementRateArray.push(data.metrics.ENGAGEMENT_RATE)
                    pinClickArray.push(data.metrics.PIN_CLICK)
                    saveArray.push(data.metrics.SAVE)
                    clickThroughArray.push(data.metrics.CLICKTHROUGH)
                    dateArray.push(formatDate(data.date))
                }

            })



            setTotalStats(userInfoResponse.data.all.summary_metrics)

            setImpressions({
                date: dateArray,
                points: impressionArray
            })

            setEngagement({
                date: dateArray,
                points: engagementArray
            })

            setOutboundClicks({
                date: dateArray,
                points: outboundArray
            })

            setPinClick({
                date: dateArray,
                points: pinClickArray
            })

            setEngagementRate({
                date: dateArray,
                points: engagementRateArray
            })

            setSave({
                date: dateArray,
                points: saveArray
            })

            setClickThrough({
                date: dateArray,
                points: clickThroughArray
            })

            const data = {
                monthly_views: accountInfoResponse.data.monthly_views,
                follower_count: accountInfoResponse.data.follower_count,
                pin_click_rate: userInfoResponse.data.all.summary_metrics.PIN_CLICK_RATE,
                save_rate: userInfoResponse.data.all.summary_metrics.SAVE_RATE
            }
            setAccountInfo(data)

            const obj = {
                selectedPeriod: selectedPeriod,
                id: pinterestID,
                boards: boardsResponse.data.items,
                boardBookmark: boardsResponse.data.bookmark,
                allPins: pinResponse.data.items,
                bookmark: pinResponse.data.bookmark,
                topPins: topPinArray,
                topVideoPins: topVideoPinArray,
                totalStats: userInfoResponse.data.all.summary_metrics,
                impressions: {
                    date: dateArray,
                    points: impressionArray
                },
                engagement: {
                    date: dateArray,
                    points: engagementArray
                },
                outboundClicks: {
                    date: dateArray,
                    points: outboundArray
                },
                pinClick: {
                    date: dateArray,
                    points: pinClickArray
                },
                engagementRate: {
                    date: dateArray,
                    points: engagementRateArray
                },
                save: {
                    date: dateArray,
                    points: saveArray
                },
                clickThrough: {
                    date: dateArray,
                    points: clickThroughArray
                },
                accountInfo: {
                    monthly_views: accountInfoResponse.data.monthly_views,
                    follower_count: accountInfoResponse.data.follower_count,
                    pin_click_rate: userInfoResponse.data.all.summary_metrics.PIN_CLICK_RATE,
                    save_rate: userInfoResponse.data.all.summary_metrics.SAVE_RATE
                },
                socialId: pinterestID
            }
            userContext.handleaccountsData(obj)

            setLoading(false)
        }
    }


    // NOTE: Load more pins
    const loadMorePins = async () => {
        const {startDate,endDate,workspaceId} = getCurrentDateAndSocialId()
        try {
            setPaginationLoading(true)
            const pinterestToken = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
            const pinterestID = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]
            const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins?bookmark=${bookmark}`, {}, 1, true, pinterestToken)
            const isAccountExits = userContext.accountsData.filter(data => data?.id === pinterestID)
            if (isAccountExits?.length) {
                const paginationData = userContext.accountsData.map(data => {
                    if (data.id === pinterestID) {
                        return {
                            ...data,
                            allPins: [...data.allPins, ...response.data.items],
                            bookmark: response.data.bookmark
                        }
                    } else {
                        return data
                    }
                })

                userContext.changeaccountsData(paginationData)


            }
            setAllPins([...allPins, ...response.data.items])
            setBookmark(response.data.bookmark)
            let allCompetitorPostData = []
            allPins.concat(response.data.items).forEach(data => {
                const postObj = { caption: data?.description === undefined ? 'This post has no caption' : data?.description, id: data.id, timestamp: data.created_at, socialId: pinterestID }
                allCompetitorPostData.push(postObj)
            })

            const requestBody = {
                workspaceId: workspaceId,
                token: pinterestID,
                media: allCompetitorPostData,
                startDateMilliseconds: startDate,
                endDateMilliSeconds: endDate,
            }
            const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
            const groupedData = res.data.data.reduce((acc, obj) => {
                if (!acc[obj.contentCategory]) {
                    acc[obj.contentCategory] = { contentCategory: obj.contentCategory, socialId: obj.socialId, postId: [] };
                }
                acc[obj.contentCategory].postId.push(obj.postId);
                return acc;
            }, {});

            const result = Object.values(groupedData);

            setstackedDataContent(result)
            setPaginationLoading(false)
        } catch (error) {
            setPaginationLoading(false)
            toast.error('Something went to wrong')
        }
    }

    // NOTE: Load more boards and its analytics
    const loadMoreBoards = async () => {
        try {
            setBoardsLoading(true)
            const pinterestToken = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
            const pinterestID = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]
            const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/boards?bookmark=${boardBookmark}`, {}, 1, true, pinterestToken)
            const isAccountExits = userContext.accountsData.filter(data => data?.id === pinterestID)
            if (isAccountExits?.length) {
                const paginationData = userContext.accountsData.map(data => {
                    if (data.id === pinterestID) {
                        return {
                            ...data,
                            boards: [...data?.boards, ...response.data.items],
                            boardBookmark: response.data.bookmark
                        }
                    } else {
                        return data
                    }
                })

                userContext.changeaccountsData(paginationData)


            }
            setBoards([...boards, ...response.data.items])
            setBoardBookmark(response.data.bookmark)
            setBoardsLoading(false)
        } catch (error) {
            setBoardsLoading(false)
            toast.error('Something went to wrong')
        }
    }

    const handleSelectedPeriod = (value) => {
        setSelectedPeriod(value)
    }

    // NOTE: Get all top pins
    const getTopPins = async (value) => {
        try {
            setTopPinsLoading(true)
            const pinterestToken = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
            const pinterestID = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]
            const topPinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/top_pins?start_date=${calculateDate().startDate}&end_date=${calculateDate().endDate}&sort_by=${value}`, {}, 1, true, pinterestToken)


            const topPinsPromise = topPinResponse.data.pins.map(async (data) => {
                const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins/${data.pin_id}`, {}, 1, true, pinterestToken)
                return {
                    ...response.data,
                    pin_metrics: data.metrics
                }

            })




            const resolvedWebhookData = await Promise.all(topPinsPromise);



            const isAccountExits = userContext.accountsData.filter(data => data?.id === pinterestID)
            if (isAccountExits?.length) {
                const paginationData = userContext.accountsData.map(data => {
                    if (data.id === pinterestID) {
                        return {
                            ...data,
                            topPins: resolvedWebhookData,

                        }
                    } else {
                        return data
                    }
                })

                userContext.changeaccountsData(paginationData)


            }

            setTopPins(resolvedWebhookData)
            setTopPinsLoading(false)
        } catch (error) {
            setTopPinsLoading(false)
            toast.error('Something went to wrong')
        }
    }

    // NOTE: Get all top video pins
    const getTopVideoPins = async (value) => {
        try {
            setTopVideoPinsLoading(true)
            const pinterestToken = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
            const pinterestID = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]
            const topPinResponse = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/top_video_pins?start_date=${calculateDate().startDate}&end_date=${calculateDate().endDate}&sort_by=${value}`, {}, 1, true, pinterestToken)

            const topPinsPromise = topPinResponse.data.pins.map(async (data) => {
                const response = await APICall(`https://api.cache.creatosaurus.io/cache/analytics/pinterest/pins/${data.pin_id}`, {}, 1, true, pinterestToken)
                return {
                    ...response.data,
                    pin_metrics: data.metrics
                }

            })




            const resolvedWebhookData = await Promise.all(topPinsPromise);


            const isAccountExits = userContext.accountsData.filter(data => data?.id === pinterestID)
            if (isAccountExits?.length) {
                const paginationData = userContext.accountsData.map(data => {
                    if (data.id === pinterestID) {
                        return {
                            ...data,
                            topVideoPins: resolvedWebhookData,

                        }
                    } else {
                        return data
                    }
                })

                userContext.changeaccountsData(paginationData)


            }
            setTopVideoPins(resolvedWebhookData)
            setTopVideoPinsLoading(false)
        } catch (error) {
            setTopVideoPinsLoading(false)
            toast.error('Something went to wrong')
        }
    }

    const getCurrentDateAndSocialId = () => {
        const pinterestToken = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.accessToken)[0]
        const pinterestID = userContext.accounts.filter(data => data.accountType === "pinterest" && data.name === userNavigation.activeSocialMediaName).map(data => data.socialId)[0]
        const workspaceId = localStorage.getItem('organizationId')
        return { startDate: calculateDate().startDate, endDate: calculateDate().endDate, pinterestToken: pinterestToken, pinterestID: pinterestID, workspaceId: workspaceId, reportWeeks: calculateDate().reportWeeks }
    }

    const getPinterestOverviewPageAnalyticaData = async () => {
        const { startDate, endDate, pinterestID, pinterestToken, workspaceId, reportWeeks } = getCurrentDateAndSocialId()
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === pinterestID && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                setLoading(true)
                setoverviewPageLoading(true)
                const overviewPageData = await axios.get(`${url}analytics/v2/pinterest/overview/${workspaceId}/${pinterestID}?startDate=${startDate}&endDate=${endDate}`, { headers: { "Authorization": `Bearer ${pinterestToken}` } })
                setAllPins(overviewPageData.data.latestPost.items)
                setBookmark(overviewPageData.data.latestPost.bookmark)
                let impressionArray = []
                let dateArray = []
                let engagementArray = []
                let outboundArray = []
                let engagementRateArray = []
                let pinClickArray = []
                let saveArray = []
                let clickThroughArray = []
                setdailyAnalytics(overviewPageData.data.analytics.all.daily_metrics)
                overviewPageData.data.analytics.all.daily_metrics.forEach((data) => {
                    if (data.data_status !== "PROCESSING") {
                        impressionArray.push(data.metrics.IMPRESSION)
                        engagementArray.push(data.metrics.ENGAGEMENT)
                        outboundArray.push(data.metrics.OUTBOUND_CLICK)
                        engagementRateArray.push(data.metrics.ENGAGEMENT_RATE)
                        pinClickArray.push(data.metrics.PIN_CLICK)
                        saveArray.push(data.metrics.SAVE)
                        clickThroughArray.push(data.metrics.CLICKTHROUGH)
                        dateArray.push(formatDate(data.date))
                    }

                })

                setTotalStats(overviewPageData.data.analytics.all.summary_metrics)

                setImpressions({
                    date: dateArray,
                    points: impressionArray
                })

                setEngagement({
                    date: dateArray,
                    points: engagementArray
                })

                setOutboundClicks({
                    date: dateArray,
                    points: outboundArray
                })

                setPinClick({
                    date: dateArray,
                    points: pinClickArray
                })

                setEngagementRate({
                    date: dateArray,
                    points: engagementRateArray
                })

                setSave({
                    date: dateArray,
                    points: saveArray
                })

                setClickThrough({
                    date: dateArray,
                    points: clickThroughArray
                })

                const data = {
                    monthly_views: overviewPageData.data.accountInfo.monthly_views,
                    follower_count: overviewPageData.data.accountInfo.follower_count,
                    pin_click_rate: overviewPageData.data.analytics.all.summary_metrics.PIN_CLICK_RATE,
                    save_rate: overviewPageData.data.analytics.all.summary_metrics.SAVE_RATE
                }
                setAccountInfo(data)
                let allCompetitorPostData = []
                overviewPageData.data.latestPost.items.forEach(data => {
                    const postObj = { caption: data?.description === undefined ? 'This post has no caption' : data?.description, id: data.id, timestamp: data.created_at, socialId: pinterestID }
                    allCompetitorPostData.push(postObj)
                })

                const requestBody = {
                    workspaceId: workspaceId,
                    token: pinterestID,
                    media: allCompetitorPostData,
                    startDateMilliseconds: startDate,
                    endDateMilliSeconds: endDate,
                }
                const res = await axios.post(`${url}analytics/v2/category/insert`, requestBody)
                const groupedData = res.data.data.reduce((acc, obj) => {
                    if (!acc[obj.contentCategory]) {
                        acc[obj.contentCategory] = { contentCategory: obj.contentCategory, socialId: obj.socialId, postId: [] };
                    }
                    acc[obj.contentCategory].postId.push(obj.postId);
                    return acc;
                }, {});

                const result = Object.values(groupedData);

                setstackedDataContent(result)
                setoverviewPageLoading(false)
                setfilterLoading(false)
                setLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === pinterestID) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: null,
                                    overviewPageData: overviewPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: pinterestID,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: null,
                            overviewPageData: overviewPageData.data
                        }
                    })
                }
            } catch (error) {
                setoverviewPageLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === pinterestID) {
                            return {
                                ...data,
                                overviewPageData: {
                                    loading: false,
                                    error: error,
                                    overviewPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: pinterestID,
                        selectedPeriod: selectedPeriod,
                        overviewPageData: {
                            loading: false,
                            error: error,
                            overviewPageData: null
                        }
                    })
                }
            }
        }
    }

    const getPinterestContentPageAnalyticaData = async () => {
        const { startDate, endDate, pinterestID, pinterestToken, workspaceId, reportWeeks } = getCurrentDateAndSocialId()
        setreportWeekArray(reportWeeks)
        const socialMediaAnalyticDataisExits = commonSocialMediaContext.socialMediaAnalyticsData.find(data => data.socialId === pinterestID && data.selectedPeriod === selectedPeriod)
        if (socialMediaAnalyticDataisExits) {

        } else {
            try {
                setfilterLoading(false)
                setLoading(true)
                setcontentPageLoading(true)
                const contentPageData = await axios.get(`${url}analytics/v2/pinterest/content/${workspaceId}/${pinterestID}?start_date=${startDate}&end_date=${endDate}&sort_by1=ENGAGEMENT&sort_by2=IMPRESSION`, { headers: { "Authorization": `Bearer ${pinterestToken}` } })
                setBoards(contentPageData.data.boards.boardItems)
                setBoardBookmark(contentPageData.data.boards.boardBookmark)

                setTopPins(contentPageData.data.topVideoPinsPromise)
                setTopVideoPins(contentPageData.data.topVideoPinsPromise)
                setcontentPageLoading(false)
                setfilterLoading(false)
                setLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === pinterestID) {
                            return {
                                ...data,
                                contentPageData: {
                                    loading: false,
                                    error: null,
                                    contentPageData: contentPageData.data
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: pinterestID,
                        selectedPeriod: selectedPeriod,
                        contentPageData: {
                            loading: false,
                            error: null,
                            contentPageData: contentPageData.data
                        }
                    })
                }
            } catch (error) {
                setfilterLoading(false)
                setcontentPageLoading(false)
                setLoading(false)
                if (socialMediaAnalyticDataisExits) {
                    const updatedSocialMediaData = commonSocialMediaContext.socialMediaAnalyticsData.map(data => {
                        if (data.socialId === pinterestID) {
                            return {
                                ...data,
                                contentPageData: {
                                    loading: false,
                                    error: true,
                                    contentPageData: null
                                }
                            }
                        }
                        return data
                    })
                    commonSocialMediaContext.changeSocialMediaAnalyticsData(updatedSocialMediaData)
                } else {
                    commonSocialMediaContext.concateSocialMediaAnalyticsData({
                        socialId: pinterestID,
                        selectedPeriod: selectedPeriod,
                        contentPageData: {
                            loading: false,
                            error: true,
                            contentPageData: null
                        }
                    })
                }
            }
        }
    }

    const onChangeselectedReportDate = (value) => {
        setselectedReportDate(value)
    }


    const context = {
        loading: loading,
        selectedPeriod: selectedPeriod,
        accountInfo: accountInfo,
        allPins: allPins,
        impressions: impressions,
        totalStats: totalStats,
        engagement: engagement,
        outboundClicks: outboundClicks,
        engagementRate: engagementRate,
        pinClick: pinClick,
        save: save,
        clickThrough: clickThrough,
        paginationLoading: paginationLoading,
        topPins: topPins,
        topPinsLoading: topPinsLoading,
        topVideoPins: topVideoPins,
        topVideoPinsLoading: topVideoPinsLoading,
        boardBookmark: boardBookmark,
        boards: boards,
        boardsLoading: boardsLoading,
        filterLoading,
        overviewPageLoading: overviewPageLoading,
        contentPageLoading: contentPageLoading,
        overviewPageFilterLoading: overviewPageFilterLoading,
        contentPageFilterLoading: contentPageFilterLoading,

        getPinterestAnalyticsData: getPinterestAnalyticsData,
        handleSelectedPeriod: handleSelectedPeriod,
        loadMorePins: loadMorePins,
        getTopPins: getTopPins,
        getTopVideoPins: getTopVideoPins,
        loadMoreBoards: loadMoreBoards,
        changeLoadingState: changeLoadingState,
        getPinterestAnalyticsDataFilter: getPinterestAnalyticsDataFilter,
        handleFilterLoading: handleFilterLoading,
        onChangeoverviewPageFilterLoading: onChangeoverviewPageFilterLoading,
        onChangecontentPageFilterLoading: onChangecontentPageFilterLoading,
        getPinterestOverviewPageAnalyticaData: getPinterestOverviewPageAnalyticaData,
        getPinterestContentPageAnalyticaData: getPinterestContentPageAnalyticaData,
        reportWeekArray: reportWeekArray,
        selectedReportDate: selectedReportDate,
        onChangesetselectedReportDate: onChangeselectedReportDate,
        dailyAnalytics: dailyAnalytics,
        stackedDataContent: stackedDataContent,
        clearState: clearState
    }



    return (
        <CreatePinterestAnalyticContext.Provider value={context} >{children}</CreatePinterestAnalyticContext.Provider>
    )
}