
export const jobFunction = [
    {
        "$URN": "urn:li:function:1",
        "name": "Accounting"

    },
    {
        "$URN": "urn:li:function:2",
        "name": "Administrative"



    },
    {
        "$URN": "urn:li:function:3",
        "name": "Arts and Design"
    },
    {
        "$URN": "urn:li:function:4",
        "name": "Business Development"


    },
    {
        "$URN": "urn:li:function:5",
        "name": "Community and Social Services"



    },
    {
        "$URN": "urn:li:function:6",
        "name": "Consulting"


    },
    {
        "$URN": "urn:li:function:7",
        "name": "Education"



    },
    {
        "$URN": "urn:li:function:8",
        "name": "Engineering"



    },
    {
        "$URN": "urn:li:function:9",
        "name": "Entrepreneurship"

    },
    {
        "$URN": "urn:li:function:10",
        "name": "Finance"

    },
    {
        "$URN": "urn:li:function:11",
        "name": "Healthcare Services"
    },
    {
        "$URN": "urn:li:function:12",
        "name": "Human Resources"
    },
    {
        "$URN": "urn:li:function:13",
        "name": "Information Technology"


    },
    {
        "$URN": "urn:li:function:14",
        "name": "Legal"



    },
    {
        "$URN": "urn:li:function:15",
        "name": "Marketing"

    },
    {
        "$URN": "urn:li:function:16",
        "name": "Media and Communication"



    },
    {
        "$URN": "urn:li:function:17",
        "name": "Military and Protective Services"



    },
    {
        "$URN": "urn:li:function:18",
        "name": "Operations"



    },
    {
        "$URN": "urn:li:function:19",
        "name": "Product Management"

    },
    {
        "$URN": "urn:li:function:20",
        "name": "Program and Project Management"



    },
    {
        "$URN": "urn:li:function:21",
        "name": "Purchasing"

    },
    {
        "$URN": "urn:li:function:22",
        "name": "Quality Assurance"

    },
    {
        "$URN": "urn:li:function:23",
        "name": "Real Estate"
    },
    {
        "$URN": "urn:li:function:24",
        "name": "Research"
    },
    {
        "$URN": "urn:li:function:25",
        "name": "Sales"
    },
    {
        "$URN": "urn:li:function:26",
        "name": "Customer Success and Support"



    }
]
